import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { useLocation } from "react-router-dom";
import { Spin, Button } from "antd";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

const OutGoingDataMap = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [outGoingDataMap, setOutGoingDataMap] = useState({});
  const [validation, setValidation] = useState([]);

  const [skuPrefix, setSkuPrefix] = useState("");

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerID) {
        handleGetMegentoMappingForXML(location.state.dealerID);
      }
    }
  }, []);
  const handleGetMegentoMappingForXML = (id) => {
    let inputData = {
      dealerId: id | 0,
    };
    try {
      setLoading(true);
      AdminToolsDataService.GetMegentoMappingForXML(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            setOutGoingDataMap(responseData);
            // if (responseData.skuPrefix == null) {
            //   setSkuPrefix("*");
            // }
          } else {
            setOutGoingDataMap({});
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value, checked } = e.target;
    const tempData = outGoingDataMap;
    if (name == "enableSync") {
      tempData[name] = checked;
    } else {
      tempData[name] = value;

      // if (!outGoingDataMap.skuPrefix.trim()) {
      //   setSkuPrefix("*");
      // } else {
      //   setSkuPrefix("");
      // }
    }
    setOutGoingDataMap((prevState) => ({
      ...prevState,
      ...tempData,
    }));
  };

  // const handleOnChange1 = (e) => {
  //   const { name, value, checked } = e.target;
  //   const tempData = outGoingDataMap;
  //   if (name == "enableSync") {
  //     tempData[name] = checked;
  //   } else {
  //     tempData[name] = value;

  //     if (!outGoingDataMap.skuPrefix.trim()) {
  //       setSkuPrefix("*");
  //     } else {
  //       setSkuPrefix("");
  //     }
  //   }
  //   setOutGoingDataMap((prevState) => ({
  //     ...prevState,
  //     ...tempData,
  //   }));
  // };

  // const handleMappingChange = (id, type, e,attributeName) => {
  //   const { name, value } = e.target;
  //   const tempData = outGoingDataMap;
  //   if (type === "Category") {
  //     tempData.megentoMappingCategoryMappings.filter(
  //       (x) => x.jewelryCategoryID == id
  //     )[0].mappingValue = value;
  //   }

  //   if (type === "Atrribute") {
  //     tempData.megentoMappingAttrubuteMappings.filter(
  //       (x) => x.atrributesId == id
  //     )[0].mappingValue = value;
  //   }

  //   if (type === "Designers") {
  //     tempData.megentoMappingDesignersMappings.filter(
  //       (x) => x.desginerId == id
  //     )[0].mappingValue = value;
  //   }

  //   setOutGoingDataMap((prevState) => ({
  //     ...prevState,
  //     ...tempData,
  //   }));
  // };

  const scroll = () => {
    const section = document.querySelector(".error-color-red");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleValidation = (id, attributeName) => {
    if (attributeName) {
      setValidation((validation) => [...validation, attributeName]);
    } else {
      setValidation((validation) => [...validation, id]);
    }
  };
  const handleValidationFalse = (id, attributeName) => {
    if (attributeName) {
      let data = validation;
      data = data.filter((e) => e !== attributeName);
      setValidation(data);
    } else {
      let data = validation;
      data = data.filter((e) => e !== id);
      setValidation(data);
    }
  };
  const handleMappingChange = (id, type, e, attributeName) => {
    const { name, value } = e.target;
    const tempData = outGoingDataMap;
    if (type === "Category") {
      tempData.megentoMappingCategoryMappings.filter(
        (x) => x.jewelryCategoryID == id
      )[0].mappingValue = value;

      var temVar = tempData.megentoMappingCategoryMappings.filter(
        (x) => x.jewelryCategoryID == id
      );
      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id);
        } else {
          handleValidationFalse(id);
        }
      });
    }
    if (type === "Atrribute") {
      tempData.megentoMappingAttrubuteMappings.filter(
        (x) => x.atrributesId == id
      )[0].mappingValue = value;

      var temVar = tempData.megentoMappingAttrubuteMappings.filter(
        (x) => x.atrributesId == id
      );

      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id, attributeName);
        } else {
          handleValidationFalse(id, attributeName);
        }
      });
    }

    if (type === "Designers") {
      tempData.megentoMappingDesignersMappings.filter(
        (x) => x.desginerId == id
      )[0].mappingValue = value;

      var temVar = tempData.megentoMappingDesignersMappings.filter(
        (x) => x.desginerId == id
      );

      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id);
        } else {
          handleValidationFalse(id);
        }
      });
    }

    setOutGoingDataMap((prevState) => ({
      ...prevState,
      ...tempData,
    }));
  };

  const handleMappingChangeCheck = (id, type, e, attributeName) => {
    const { name, checked } = e.target;
    const tempData = outGoingDataMap;

    if (type === "Category") {
      tempData.megentoMappingCategoryMappings.filter(
        (x) => x.jewelryCategoryID == id
      )[0].isEnabled = checked;

      var temVar = tempData.megentoMappingCategoryMappings.filter(
        (x) => x.jewelryCategoryID == id
      );

      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id);
        } else {
          handleValidationFalse(id);
        }
      });
    }
    if (type === "Atrribute") {
      tempData.megentoMappingAttrubuteMappings.filter(
        (x) => x.atrributesId == id
      )[0].isEnabled = checked;
      var temVar = tempData.megentoMappingAttrubuteMappings.filter(
        (x) => x.atrributesId == id
      );
      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id, attributeName);
        } else {
          handleValidationFalse(id, attributeName);
        }
      });
    }
    if (type === "Designers") {
      tempData.megentoMappingDesignersMappings.filter(
        (x) => x.desginerId == id
      )[0].isEnabled = checked;

      var temVar = tempData.megentoMappingDesignersMappings.filter(
        (x) => x.desginerId == id
      );
      temVar.find((x) => {
        if (x.isEnabled == true && x.mappingValue == "") {
          handleValidation(id);
        } else {
          handleValidationFalse(id);
        }
      });
    }
    setOutGoingDataMap((prevState) => ({
      ...prevState,
      ...tempData,
    }));
  };

  const handleSaveMegentoMappingForXML = () => {
    if (skuPrefix != "") {
      scroll();
      return false;
    }
    if (validation.length != 0) {
      scroll();
    }
    if (validation.length != 0) {
      return false;
    }
    let inputData = outGoingDataMap;
    try {
      setSubmitLoading(true);
      AdminToolsDataService.SaveMegentoMappingForXML(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success(t("XLM Created Successfully."));
          } else {
            NotificationManager.error(t("XLM Not Created."));
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <React.Fragment>
        <div className="form__fields mh-auto">
          <div className="row">
            <div className="col-md-12">
              <h3 className="subheading"> {t("Out Going Data Map")} </h3>
            </div>
            <div className="col-md-4">
              <div className="input__block">
                <label> {t("API Url")} </label>
                <input
                  type="text"
                  name="apiURL"
                  value={outGoingDataMap.apiURL}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Port No")}: </label>
                <input
                  type="text"
                  name="portNo"
                  value={outGoingDataMap.portNo}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Username")} </label>
                <input
                  type="text"
                  name="userName"
                  value={outGoingDataMap.userName}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Password")} </label>
                <input
                  type="text"
                  name="password"
                  autoComplete="new-password"
                  value={outGoingDataMap.password}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("SKU Prefix")} </label>
                {skuPrefix && (
                  <span className="mandatory inputstar__outbound error-color-red">
                    *
                  </span>
                )}
                <input
                  type="text"
                  name="skuPrefix"
                  value={outGoingDataMap.skuPrefix}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Jewelry Category")} </label>
                <input
                  type="text"
                  name="jewelryCategory"
                  value={outGoingDataMap.jewelryCategory}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Designer Category")} </label>
                <input
                  type="text"
                  name="designerCategory"
                  value={outGoingDataMap.designerCategory}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Bridal Category")} </label>
                <input
                  type="text"
                  name="bridalCategory"
                  value={outGoingDataMap.bridalCategory}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="radio__block mt-4">
                <input
                  type="checkbox"
                  name="enableSync"
                  checked={outGoingDataMap.enableSync}
                  onChange={handleOnChange}
                />
                <label> {t("Enable Sync")} </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form__fields outgoing__mapping mh-auto mt-4">
          <div className="row">
            <div className="col-md-12">
              <h3 className="subheading"> {t("Category Mapping")} </h3>
            </div>
          </div>
          <div className="row mapping__accord">
            {outGoingDataMap.megentoMappingCategoryMappings
              ? outGoingDataMap.megentoMappingCategoryMappings
                  .filter((x) => x.parentCategoryID == 0)
                  .map((item, i) => {
                    return (
                      <div className="col-md-6">
                        <Accordion>
                          <Accordion.Item eventKey={item.jewelryCategoryID}>
                            <React.Fragment>
                              <div className="accordian__top">
                                <div className="accordian__head col-6 col-lg-4">
                                  <input
                                    type="checkbox"
                                    id={item.jewelryCategoryID}
                                    checked={item.isEnabled}
                                    onChange={(e) => {
                                      handleMappingChangeCheck(
                                        item.jewelryCategoryID,
                                        "Category",
                                        e
                                      );
                                    }}
                                  />
                                  <label htmlFor={item.jewelryCategoryID}>
                                    {item.jewelryCategory}{" "}
                                  </label>
                                </div>{" "}
                                <div className="col-6 col-lg-6">
                                  <div className="input__block mb-0 ml-2 mr-2">
                                    {validation.find(
                                      (id) => id == item.jewelryCategoryID
                                    ) && (
                                      <span className="error-color-red mandatory inputstar">
                                        *
                                      </span>
                                    )}

                                    <input
                                      type="text"
                                      value={item.mappingValue}
                                      onChange={(e) => {
                                        handleMappingChange(
                                          item.jewelryCategoryID,
                                          "Category",
                                          e
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                            <Accordion.Header></Accordion.Header>
                            <Accordion.Body>
                              <React.Fragment>
                                <div className="col-md-12">
                                  <div className="accordian_chkbxdiv">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="input__block">
                                          <b>
                                            {" "}
                                            {t("GemFind Category")}{" "}
                                          </b>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="input__block">
                                          <b>
                                            {" "}
                                            {t("Website Category")}{" "}
                                          </b>
                                        </div>
                                      </div>
                                    </div>
                                    {outGoingDataMap.megentoMappingCategoryMappings
                                      .filter(
                                        (x) =>
                                          x.parentCategoryID ==
                                          item.jewelryCategoryID
                                      )
                                      .map((citem, k) => {
                                        return (
                                          <React.Fragment>
                                            {outGoingDataMap.megentoMappingCategoryMappings.filter(
                                              (x) =>
                                                x.parentCategoryID ==
                                                citem.jewelryCategoryID
                                            ).length > 0 ? (
                                              <React.Fragment>
                                                <Accordion className="mb-4">
                                                  <Accordion.Item
                                                    eventKey={
                                                      citem.jewelryCategoryID
                                                    }
                                                  >
                                                    <React.Fragment>
                                                      <div className="accordian__top">
                                                        <div className="accordian__head">
                                                          <input
                                                            type="checkbox"
                                                            id={
                                                              citem.jewelryCategoryID
                                                            }
                                                            checked={
                                                              citem.isEnabled
                                                            }
                                                            onChange={(e) => {
                                                              handleMappingChangeCheck(
                                                                citem.jewelryCategoryID,
                                                                "Category",
                                                                e
                                                              );
                                                            }}
                                                          />
                                                          <label
                                                            htmlFor={
                                                              citem.jewelryCategoryID
                                                            }
                                                          >
                                                            {
                                                              citem.jewelryCategory
                                                            }{" "}
                                                          </label>
                                                        </div>
                                                        <div className="input__block mb-0 ml-2">
                                                          <input
                                                            type="text"
                                                            value={
                                                              citem.mappingValue
                                                            }
                                                            name={
                                                              citem.jewelryCategory
                                                            }
                                                            onChange={(e) =>
                                                              handleMappingChange(
                                                                citem.jewelryCategoryID,
                                                                "Category",
                                                                e
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                    <Accordion.Header></Accordion.Header>
                                                    <Accordion.Body>
                                                      {outGoingDataMap.megentoMappingCategoryMappings
                                                        .filter(
                                                          (x) =>
                                                            x.parentCategoryID ==
                                                            citem.jewelryCategoryID
                                                        )
                                                        .map((ncItem, l) => {
                                                          return (
                                                            <React.Fragment>
                                                              <div className="row">
                                                                <div className="col-md-6">
                                                                  <input
                                                                    type="checkbox"
                                                                    id={
                                                                      ncItem.jewelryCategoryID
                                                                    }
                                                                    checked={
                                                                      ncItem.isEnabled
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      handleMappingChangeCheck(
                                                                        ncItem.jewelryCategoryID,
                                                                        "Category",
                                                                        e
                                                                      );
                                                                    }}
                                                                  />
                                                                  <label
                                                                    htmlFor={
                                                                      ncItem.jewelryCategoryID
                                                                    }
                                                                  >
                                                                    {
                                                                      ncItem.jewelryCategory
                                                                    }
                                                                  </label>
                                                                </div>

                                                                <div className="col-md-6">
                                                                  <div className="input__block">
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        ncItem.mappingValue
                                                                      }
                                                                      name={
                                                                        ncItem.jewelryCategory
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleMappingChange(
                                                                          ncItem.jewelryCategoryID,
                                                                          "Category",
                                                                          e
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </React.Fragment>
                                                          );
                                                        })}
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                </Accordion>
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <input
                                                      type="checkbox"
                                                      id={
                                                        citem.jewelryCategoryID
                                                      }
                                                      checked={citem.isEnabled}
                                                      onChange={(e) => {
                                                        handleMappingChangeCheck(
                                                          citem.jewelryCategoryID,
                                                          "Category",
                                                          e
                                                        );
                                                      }}
                                                    />
                                                    <label
                                                      htmlFor={
                                                        citem.jewelryCategoryID
                                                      }
                                                    >
                                                      {citem.jewelryCategory}
                                                    </label>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="input__block">
                                                      <input
                                                        type="text"
                                                        value={
                                                          citem.mappingValue
                                                        }
                                                        name={
                                                          citem.jewelryCategory
                                                        }
                                                        onChange={(e) =>
                                                          handleMappingChange(
                                                            citem.jewelryCategoryID,
                                                            "Category",
                                                            e
                                                          )
                                                        }
                                                      />
                                                      {validation.find(
                                                        (id) =>
                                                          id ==
                                                          citem.jewelryCategoryID
                                                      ) && (
                                                        <span className="error-color-red mandatory inputstar">
                                                          *
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                              </React.Fragment>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  })
              : null}
          </div>
        </div>

        <div className="form__fields outgoing__mapping mh-auto mt-4">
          <div className="row">
            <div className="col-md-12">
              <h3 className="subheading"> {t("Attrubute Mapping")} </h3>
            </div>

            <div className="col-md-12">
              <div className="mapping_chkbxdiv">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input__block">
                      <b> {t("GemFind Category")} </b>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <b> {t("Website Category")} </b>
                    </div>
                  </div>
                </div>
                {outGoingDataMap.megentoMappingAttrubuteMappings
                  ? outGoingDataMap.megentoMappingAttrubuteMappings.map(
                      (item, i) => {
                        return (
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  id={item.atrributesId}
                                  type="checkbox"
                                  checked={item.isEnabled}
                                  onChange={(e) =>
                                    handleMappingChangeCheck(
                                      item.atrributesId,
                                      "Atrribute",
                                      e,
                                      item.attributeName
                                    )
                                  }
                                />
                                <label htmlFor={item.atrributesId}>
                                  {" "}
                                  {item.attributeName}
                                </label>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  {validation.find(
                                    (id) => id == item.attributeName
                                  ) && (
                                    <span className="mandatory inputstar error-color-red">
                                      *
                                    </span>
                                  )}

                                  <input
                                    type="text"
                                    value={item.mappingValue}
                                    onChange={(e) =>
                                      handleMappingChange(
                                        item.atrributesId,
                                        "Atrribute",
                                        e,
                                        item.attributeName
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div className="form__fields outgoing__mapping mh-auto mt-4">
          <div className="row">
            <div className="col-md-12">
              <h3 className="subheading"> {t("Designers Mapping")} </h3>
            </div>

            <div className="col-md-12">
              <div className="mapping_chkbxdiv">
                {outGoingDataMap.megentoMappingDesignersMappings ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <b> {t("GemFind Category")} </b>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input__block">
                        <b> {t("Website Category")} </b>
                      </div>
                    </div>
                  </div>
                ) : null}

                {outGoingDataMap.megentoMappingDesignersMappings
                  ? outGoingDataMap.megentoMappingDesignersMappings.map(
                      (item, i) => {
                        return (
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  id={item.desginerId}
                                  type="checkbox"
                                  checked={item.isEnabled}
                                  onChange={(e) =>
                                    handleMappingChangeCheck(
                                      item.desginerId,
                                      "Designers",
                                      e
                                    )
                                  }
                                />
                                <label htmlFor={item.desginerId}>
                                  {" "}
                                  {item.designerName}
                                </label>
                              </div>

                              <div className="col-md-6">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={item.mappingValue}
                                    onChange={(e) =>
                                      handleMappingChange(
                                        item.desginerId,
                                        "Designers",
                                        e
                                      )
                                    }
                                  />
                                  {validation.find(
                                    (id) => id == item.desginerId
                                  ) && (
                                    <span className="mandatory inputstar error-color-red">
                                      *
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    )
                  : null}
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <Button
                className="primary-btn mt-md-3"
                loading={submitLoading}
                onClick={handleSaveMegentoMappingForXML}
              >
                {t("Create XML")}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Spin>
  );
};

export default OutGoingDataMap;
