import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
	setChecklinkHeaderMenuData,
	setOpenKeyHeaderMenuData,
	setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";

const UpdateInventoryImport = () => {
	const { t } = useTranslation();
	const [loginType, setLoginType] = useState("");
	const loginDetials = useSelector((state) => state.loginReducer);
	const [dealerId, setDealerId] = useState(0);

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData) {
					setLoginType(
						loginDetials.loginDetials.responseData.dealerMemberTypeName
					);
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
				}
			}
		}
	}, []);

	let navigate = useNavigate();
	const uploadDiamondFile = () => {
		dispatch(setChecklinkHeaderMenuData("My Catalog"));
		var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["uploadDiamondFile"]));
		navigate("/uploadDiamondFile");
	};

	const dispatch = useDispatch();
	const uploadProductFile = () => {
		dispatch(setChecklinkHeaderMenuData("My Catalog"));
		var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["uploadProductFile"]));
		navigate("/uploadProductFile");
	};

	const uploadHistory = () => {
		dispatch(setChecklinkHeaderMenuData("My Catalog"));
		var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["uploadHistory"]));
		navigate("/uploadHistory");
	};
	const DiamondUploadHistory = () => {
		dispatch(setChecklinkHeaderMenuData("My Catalog"));
		var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["diamonduploadHistory"]));
		navigate("/diamonduploadHistory");
	};
	return (
		<React.Fragment>
			<div className="import-data-section-sp mt-4">
				<div className="main-heading-center-sp">
					<div className="col-md-12">
						<h4 className="workarea__heading mt-0 mb-2">
							{t("Import Your Data")}
						</h4>
						<h5> {t("Upload Your Jewelry & Diamonds")} </h5>
					</div>
				</div>
				<div className="diamonds-jewelry-card mt-5">
					{loginType == "VendorDiamond" && (
						<div className="col-md-5 text-center">
							<h4 className="workarea__heading mt-0">{t("Import Diamonds")}</h4>
							{/* <a href="#"> {t("View The Training Video")} </a> */}
							<div>
								<button
									className="primary-btn mt-3"
									onClick={uploadDiamondFile}>
									{/* {t("Upload Diamond")} */}
									{t("Upload Diamond Data")}
								</button>
							</div>
							{/* <div>
                <button
                  className="primary-btn mt-3"
                  onClick={uploadProductFile}
                >
                 
                  Import Jewelry Data
                </button>
              </div> */}
							<div>
								<button
									className="primary-btn mt-3"
									onClick={DiamondUploadHistory}>
									{/* {t("Upload Diamond")} */}
								{t("Upload History")}
								</button>
							</div>
						</div>
					)}
					{loginType == "Vendor" && (
						<div className="col-md-5 text-center">
							<h4 className="workarea__heading mt-0">{t("Import Jewelry")}</h4>
							{/* <a href="#"> {t("View The Training Video")} </a> */}
							<div>
								<button
									className="primary-btn mt-3"
									onClick={uploadProductFile}>
									{/* {t("Upload Jewelry")} */}
									{t("Upload Jewelry Data")}
								</button>
							</div>
							<div>
								<button className="primary-btn mt-3" onClick={uploadHistory}>
									{/* {t("Upload Jewelry")} */}
									{t("Upload History")}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default UpdateInventoryImport;
