import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const UpdatePurchaseOrder = props => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t("Retailer Name"),
            dataIndex: "retailerName",
            width: 150
        },
        {
            title: t("Product"),
            dataIndex: "product",
        },
        {
            title: t("Quantity"),
            dataIndex: "quantity",
        },
        {
            title: t("Order Date"),
            dataIndex: "orderDate",
        },
        {
            title: t("Action"),
            dataIndex: "action",
        }
        
    ];

    const data = [
        {
            key: "1",
            retailerName: "Retailer 1",
            product: "Product 1",
            quantity: "1",
            orderDate: "10/10/2019",
            action:
                <button class="primary-btn"> {t("Confirm")} </button>
        },
        {
            key: "1",
            retailerName: "Retailer 1",
            product: "Product 1",
            quantity: "1",
            orderDate: "10/10/2019",
            action:
                <button class="primary-btn"> {t("Confirm")} </button>
        }
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0"> {t("Update Purchase Order")} </h4>
                    </div>
                </div>
                <div className="divForm">
                    <Table columns={columns} dataSource={data} scroll={{ x: 600,y: 800 }} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePurchaseOrder);
