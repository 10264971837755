import { http } from "../helper/http-common";

class SCAMasterService {
  GetSCAList() {
    return http.post("/Setting/GetAllSCAMasters");
  }

  AddUpdateSCA(inputData) {
    return http.post("/Setting/SaveorUpdateSCA", inputData);
  }

  DeleteSCARecord(inputData) {
    return http.post("/Setting/DeleteSCA", inputData);
  }
}

export default new SCAMasterService();
