import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";

import prodImg from "../../../assets/images/prod__img.jpg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut, Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const GlobalDiamondAddToCartReport = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  function onSearch(val) {}

  function onChange(e) {}

  const columns = [
    // {
    //   title: t("Product Image"),
    //   dataIndex: "ProductImage",
    //   width: 120
    // },
    {
      title: t("User IP Address"),
      dataIndex: "UserIPAddress",
      width: 120,
    },
    {
      title: t("Vendor"),
      dataIndex: "Vendor",
      width: 120,
    },
    {
      title: t("Retailer"),
      dataIndex: "Retailer",
      width: 120,
    },
    {
      title: t("Collection"),
      dataIndex: "Collection",
      width: 120,
    },
    {
      title: t("Category"),
      dataIndex: "Category",
      width: 120,
    },
    {
      title: t("Date"),
      dataIndex: "Date",
      width: 120,
    },
    {
      title: t("Style Number"),
      dataIndex: "StyleNumber",
      width: 120,
    },
    {
      title: t("Metal Type"),
      dataIndex: "MetalType",
      width: 120,
    },
    {
      title: t("Metal Color"),
      dataIndex: "MetalColor",
      width: 120,
    },
    {
      title: t("Price"),
      dataIndex: "Price",
      width: 120,
    },
  ];

  const data = [
    {
      key: "1",
      ProductImage: (
        <span>
          <img src={prodImg} className="product__img" />
        </span>
      ),
      UserIPAddress: <span>175.100.179.26</span>,
      Vendor: <span>RB</span>,
      Retailer: <span>GemFind</span>,
      Collection: <span>Solitaire</span>,
      Category: <span>Engagement Ring - Semi Mount</span>,
      Date: <span>01/01/2022</span>,
      StyleNumber: <span>F1626-6.5X4.5-18KW-1</span>,
      MetalType: <span>18K Gold</span>,
      MetalColor: <span>White</span>,
      Price: <span>Call For Price</span>,
    },
    {
      key: "2",
      ProductImage: (
        <span>
          <img src={prodImg} className="product__img" />
        </span>
      ),
      UserIPAddress: <span>175.100.179.26</span>,
      Vendor: <span>RB</span>,
      Retailer: <span>GemFind</span>,
      Collection: <span>Solitaire</span>,
      Category: <span>Engagement Ring - Semi Mount</span>,
      Date: <span>01/01/2022</span>,
      StyleNumber: <span>F1626-6.5X4.5-18KW-1</span>,
      MetalType: <span>18K Gold</span>,
      MetalColor: <span>White</span>,
      Price: <span>Call For Price</span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">{t("Jewelry Category Report")}</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Add to Cart Report")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {t("Download CSV")}
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {t("From Date")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {t("To Date")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {t("Select Diamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder={t("All")}
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {t("All")} </Option>
                <Option value="lucy"> {t("Mined Diamond")} </Option>
                <Option value="lucy">{t("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-3 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-2">
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b> {t("Total Jewelry Clicks")} : 32</b>
                <Tooltip
                  placement="right"
                  title={t(
                    "Displays the total number of jewelry clicks through the currently specified date range above"
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="chart__section">
              <Bar
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange",
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalDiamondAddToCartReport;
