import React, { useState, useEffect } from "react";
import { Select, Switch, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

const RingbuilderLegacy = () => {
  const { t } = useTranslation();
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dealerId, setDealerId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [ViewLegacyData, setViewLegacyData] = useState({
    PageAlignment: null,
    SocialSharingButtons: true,
    ShowViewInStore: true,
    PopUpOnDiamondSearch: true,
    DiaSearchResultPageSize: null,
    RingSearchResultPageSize: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
  });

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetRingBuilderLagacyDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  //   const nocontent = <span> {t("No content.")} </span>;

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      setViewLegacyData({
        ...ViewLegacyData,
        [name]: e,
      });
    } else {
      setViewLegacyData({
        ...ViewLegacyData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGetRingBuilderLagacyDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      AppsRingBuilderService.GetRingBuilderLagacyDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setViewLegacyData((prevState) => ({
                ...prevState,
                PageAlignment: responseData.gfLinkAlignment,
                SocialSharingButtons: responseData.showSocialSharing,
                ShowViewInStore: responseData.showViewInStore,
                PopUpOnDiamondSearch:
                  responseData.showPopupOnDiamondInventoryPage,
                DiaSearchResultPageSize: responseData.diamondSearchResultPage,
                RingSearchResultPageSize: responseData.ringSearchResult,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
              }));
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        gfLinkAlignment: ViewLegacyData.PageAlignment,
        showSocialSharing: ViewLegacyData.SocialSharingButtons,
        showViewInStore: ViewLegacyData.ShowViewInStore,
        showPopupOnDiamondInventoryPage: ViewLegacyData.PopUpOnDiamondSearch,
        diamondSearchResultPage: ViewLegacyData.DiaSearchResultPageSize,
        ringSearchResult: ViewLegacyData.RingSearchResultPageSize,
        ringInitiallySort1: ViewLegacyData.InitiallySortbox1,
        ringInitiallySortDesc: ViewLegacyData.InitiallySortbox2,
      };
      setLoading(true);
      AppsRingBuilderService.UpdateRingBuilderLagacyDetails(inputData)
        .then((response) => {
          var message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully."));
            handleGetRingBuilderLagacyDetails(dealerId);
          } else {
            NotificationManager.success(t("Data not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  const PageAlignmentList = [
    {
      value: "Center",
      index: "Center",
    },
    {
      value: "Left",
      index: "Left",
    },
    {
      value: "Right",
      index: "Right",
    },
  ];

  const InitialSortList = [
    {
      value: "Price",
      index: "Price",
    },
    {
      value: "Metal Type",
      index: "Metal Type",
    },
    {
      value: "Setting Number",
      index: "Setting Number",
    },
  ];

  const InitialSortList1 = [
    {
      value: "ASC",
      index: "Ascending",
    },
    {
      value: "DESC",
      index: "Descending",
    },
  ];
  return (
    <React.Fragment>
      <div className="navigation__section dlegacy__section">
        <div className="form__fields">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0 mb-0"> {t("Legacy")} </h4>
          </div>
          <Spin spinning={loading}>
            <div className="col-lg-6 mt-4">
              <div className="form__section">
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Page Alignment")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt "
                        showSearch
                        placeholder={t("Choose Any")}
                        name="PageAlignment"
                        value={
                          ViewLegacyData.PageAlignment
                            ? ViewLegacyData.PageAlignment
                            : ""
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "PageAlignment")
                        }
                      >
                        {PageAlignmentList.map((item, i) => {
                          return (
                            <Option value={item.value}>{item.index}</Option>
                          );
                        })}
                      </Select>
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to display tool alignment as Center, Left, or Right position."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {t("Show Social Sharing Buttons")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        name="SocialSharingButtons"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewLegacyData.SocialSharingButtons}
                        // onChange={handleSwitch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "SocialSharingButtons")
                        }
                      />
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to display the share button (FaceBook, Twitter, etc.) on the diamond details page."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Show View In Store")} </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        name="ShowViewInStore"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewLegacyData.ShowViewInStore}
                        // onChange={handleSwitch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "ShowViewInStore")
                        }
                      />
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to show In Store diamond on the diamond inventory page."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {t("Show Pop Up On Diamond Search")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Switch
                        name="PopUpOnDiamondSearch"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewLegacyData.PopUpOnDiamondSearch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "PopUpOnDiamondSearch")
                        }
                      />
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to use the popup of diamond details on the diamond inventory page."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {t("Diamond Search Result Page Size")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt "
                        showSearch
                        placeholder={t("Choose Any")}
                        name="DiaSearchResultPageSize"
                        value={
                          ViewLegacyData.DiaSearchResultPageSize
                            ? ViewLegacyData.DiaSearchResultPageSize
                            : ""
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "DiaSearchResultPageSize")
                        }
                      >
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="75">75</Option>
                        <Option value="100">100</Option>
                      </Select>
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {t("Rings - Search Result Page Size")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt "
                        showSearch
                        placeholder={t("Choose Any")}
                        name="RingSearchResultPageSize"
                        value={
                          ViewLegacyData.RingSearchResultPageSize
                            ? ViewLegacyData.RingSearchResultPageSize
                            : ""
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(
                            e,
                            "select",
                            "RingSearchResultPageSize"
                          )
                        }
                      >
                        <Option value="12">12</Option>
                        <Option value="24">24</Option>
                        <Option value="36">36</Option>
                        <Option value="48">48</Option>
                      </Select>
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose the number of setting displayed on each page of the search results. For values other than shown, you must contact GemFind directly."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className="">
                      {" "}
                      {t("Rings - Initially Sort Result On")}{" "}
                    </span>
                  </div>

                  <div className="inpt__div">
                    <div className="input__block">
                      <Select
                        className="border__grey selct__inpt "
                        showSearch
                        placeholder={t("Choose Any")}
                        name="InitiallySortbox1"
                        value={
                          ViewLegacyData.InitiallySortbox1
                            ? ViewLegacyData.InitiallySortbox1
                            : ""
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "InitiallySortbox1")
                        }
                      >
                        {InitialSortList.map((item, i) => {
                          return (
                            <Option value={item.value}>{item.index}</Option>
                          );
                        })}
                      </Select>
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to display settings based on the sort order of Price, Metal Type, or Style Number."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                    <div className="input__block mt-3">
                      <Select
                        className="border__grey selct__inpt "
                        showSearch
                        placeholder={t("Choose Any")}
                        name="InitiallySortbox2"
                        value={
                          ViewLegacyData.InitiallySortbox2
                            ? ViewLegacyData.InitiallySortbox2
                            : ""
                        }
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "InitiallySortbox2")
                        }
                      >
                        {InitialSortList1.map((item, i) => {
                          return (
                            <Option value={item.value}>{item.index}</Option>
                          );
                        })}
                      </Select>
                      <Tooltip
                        className="ml-1"
                        placement="right"
                        title={t(
                          "Choose whether to display sorting results in Ascending or Descending order."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <button
                    className={disableBtn ? "disabled-btn" : "primary-btn"}
                    disabled={disableBtn}
                    loading={submitLoading}
                    onClick={handleSubmit}
                  >
                    {t("Update Settings")}{" "}
                  </button>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RingbuilderLegacy;
