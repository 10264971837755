import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import AdminReportDataService from "../../../services/admin-report.service";

const JewelryLogReports = () => {
  const { t } = useTranslation();
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [JewelryLogReportsGridDataList, setJewelryLogReportsGridDataList] =
    useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const [sortColumnName, setSortColumnName] = useState("DID");
  const [sortType, setSortType] = useState("asc");

  useEffect(() => {
    handleGetJewelryLogReports(pageNo, pageSize, sortColumnName, sortType);
  }, []);

  /*Sorting API Starts*/
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "DID";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetJewelryLogReports(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*Sorting API Ends*/

  /*API Binding Starts*/
  const handleGetJewelryLogReports = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "DID",
      sortOrder: sortOrder || "ASC",
    };
    setTableLoading(true);
    AdminReportDataService.GetJewelryLogReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var getJewelryLogReportsrespdatalis =
          responseData.getJewelryLogReportsrespdatalis;
        if (message === "Success") {
          if (responseData) {
            var temptotalRecords = responseData.totalRecord;
            setTotalCount(temptotalRecords);
            setJewelryLogReportsGridDataList(getJewelryLogReportsrespdatalis);
          }
        } else {
          setJewelryLogReportsGridDataList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        setJewelryLogReportsGridDataList([]);
        console.log(error);
      })
      .finally(() => setTableLoading(false));
  };

  const handleJwellarylogDownload = () => {
    setDownloadDataloading(true);
    AdminReportDataService.jwellarylogDownload()
      .then((response) => {
        openInNewTab(response.data.responseData);
      })
      .catch((error) => {
        setDownloadDataloading(false);
        console.log(error);
      })
      .finally(() => setDownloadDataloading(false));
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Jewelry Log Reports")}
              </h4>
            </div>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-12 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleJwellarylogDownload()}
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {t("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              loading={tableLoading}
              columns={[
                {
                  title: t("Dealer ID"),
                  dataIndex: "did",
                  width: 100,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Dealer Company"),
                  dataIndex: "dCompany",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Count"),
                  dataIndex: "count",
                  sorter: (a, b) => {},
                  width: 100,
                },
                {
                  title: t("Last Upload Date"),
                  dataIndex: "modifiedDate",
                  sorter: (a, b) => {},
                  render: (index, record) =>
                    record.modifiedDate &&
                    record.modifiedDate.replace(
                      /(<([^>]+)>|12:00:00 AM|&nbsp;)/gi,
                      ""
                    ),
                },
              ]}
              dataSource={JewelryLogReportsGridDataList}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryLogReports;
