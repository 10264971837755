import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Spin } from "antd";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ReportsDiamondService from "../../services/reports-Diamond.service";
import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const DiamondCutIndComp = () => {
  const { t } = useTranslation();
  const DiamondCutChartRef = useRef();
  /*Initial State for Binding Starts*/
  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  /*Initial State for Binding Ends*/

  /*React Binding Starts*/
  const [TableForAllList, setTableForAllList] = useState([]);
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [tableKey, setTableKey] = useState(true);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [TableForAllLoading, setTableForAllLoading] = useState(false);
  const [TotalDiamondClicks, setTotalDiamondClicks] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutGradeSearchesLabels, setdiamondCutGradeSearchesLabels] =
    useState([]);
  const [DiamondCutGradeSearchesList, setDiamondCutGradeSearchesList] =
    useState([]);
  const [DiamondCutGradeColorData, setDiamondCutGradeColorData] = useState([]);
  const [DiamondCutGradeYAxisList, setDiamondCutGradeYAxisList] = useState([]);
  const [TableShapeValue, setTableShapeValue] = useState("");
  const [TableClarityValue, setTableClarityValue] = useState("");
  const [TableCutGradeValue, setTableCutGradeValue] = useState("");
  const [TableColorValue, setTableColorValue] = useState("");
  const [TableSizeValue, setTableSizeValue] = useState("");
  const [TableCertificateValue, setTableCertificateValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortType, setSortType] = useState("desc");
  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (TableCutGradeValue) {
      setTableKey(!tableKey);
      setPageSize(10);
      handleGetDiamondReportForTableChartAll(dealerId, forDealer);
    }
  }, [TableCutGradeValue]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id,
    };
    GlobalDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleLoadChartReports(id, forDealer);
          handleGetDiamondReportForTableChartAll(
            id,
            forDealer,
            pageNo,
            pageSize,
            sortColumnName,
            sortType
          );
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        setForDealer("");
      });
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      type: "",
      reportName: "CutGrade",
    };
    setLoadChartReportsLoading(true);

    GlobalDiamondReportService.LoadChartReports(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var DiamondCutGradeSearchesList = responseData.DiamondCutGradeSearches;
        var TotalDiamondClicks =
          responseData.TotalDiamondClicks[0].TotalClickCnt;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setTotalDiamondClicks(TotalDiamondClicks);
            setDiamondCutGradeSearchesList(DiamondCutGradeSearchesList);
            var DiamondCutGradeSearchesLabelArr =
              DiamondCutGradeSearchesList.map((item) => {
                return item.CutGrade;
              });
            setdiamondCutGradeSearchesLabels(DiamondCutGradeSearchesLabelArr);
            var DiamondCutGradeYAxisList = DiamondCutGradeSearchesList.map(
              (item) => {
                return item.CNT;
              }
            );
            var DiamondCutGradeColorList = DiamondCutGradeSearchesList.map(
              (item) => {
                return item.CutGrade;
              }
            );
            setDiamondCutGradeYAxisList(DiamondCutGradeYAxisList);
            diamondCutGradeColorHandler(DiamondCutGradeColorList);
            setKey(!key);
          }
        } else {
          setTotalDiamondClicks(0);
          setDiamondCutGradeSearchesList([]);
          setDiamondCutGradeYAxisList([]);
        }
      })
      .catch((error) => {
        setTotalDiamondClicks(0);
        setDiamondCutGradeSearchesList([]);
        setDiamondCutGradeYAxisList([]);
        setdiamondCutGradeSearchesLabels([]);
      })
      .finally(() => setLoadChartReportsLoading(false));
  };
  const handleGetDiamondReportForTableChartAll = (
    dealerId,
    forDealer,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    cutGradeName
  ) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom
        ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: globalDiamonds.dataRangeTo
        ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
        : "2022-09-15",
      clarity: TableClarityValue,
      cutGrade: cutGradeName ? cutGradeName : TableCutGradeValue,
      shape: TableShapeValue,
      color: TableColorValue,
      size: TableSizeValue,
      certificate: TableCertificateValue,
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      pageNumber: pageNo ? pageNo : 1,
      pageSize: pageSize ? pageSize : 10,
      sortOrderCol: sortColumnName || "GT.MODIFIED",
      sortOrderType: sortOrder || "DESC",
      reporttype: "Global",
      reportName: "CutGrade",
    };
    setTableForAllLoading(true);

    ReportsDiamondService.GetDiamondReportForTableChartAll(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var TableForAllList = responseData.modelTableChartAllList;
        var TotalEventsCount = responseData.totalCount;
        if (message === "Success") {
          if (
            responseData?.modelTableChartAllList &&
            responseData?.modelTableChartAllList !== null
          ) {
            if (Object.keys(responseData).length !== 0) {
              setTotalCount(TotalEventsCount);
              setTableForAllList(TableForAllList);
            }
          } else {
            setTotalCount(0);
            setTableForAllList([]);
          }
        } else {
          setTotalCount(0);
          setTableForAllList([]);
        }
      })
      .catch((error) => {
        setTotalCount(0);
        setTableForAllList([]);
        setTableForAllLoading(false);
      })
      .finally(() => setTableForAllLoading(false));
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "DESC";
      column = "GT.MODIFIED";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*API Binding Ends*/
  const diamondCutGradeColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setDiamondCutGradeColorData(color);
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    }
  };
  /*DatePicker Binding Ends*/

  const handleOnChange = (e, isSelect, name) => {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  };

  const handleApplyFilter = () => {
    setTableKey(!tableKey);
    setPageSize(10);
    setTableCutGradeValue("");
    handleLoadChartReports(dealerId, forDealer);
    handleGetDiamondReportForTableChartAll(
      dealerId,
      forDealer,
      1,
      10,
      "GT.MODIFIED",
      "DESC",
      ""
    );
  };

  return (
    <React.Fragment>
      <div className="form__fields magento__mapping">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Diamond CutGrade Searches")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {t("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {t("Select Diamond")} </label>
              <Select
                className="border__grey"
                placeholder={t("All")}
                showSearch
                name="SelectedDiamondValue"
                optionFilterProp="children"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All">{t("All")}</Option>
                <Option value="Mined">{t("Mined Diamond")}</Option>
                <Option value="Lab">{t("Lab Grown Diamond")}</Option>
              </Select>
            </div>
          </div>

          {TableForAllList &&
            TableForAllList.length > 0 &&
            DiamondCutGradeSearchesList.length > 0 && (
              <div className="col-lg-12 col-md-6 mt-4">
                <div className="input__block">
                  <label>
                    <Spin spinning={LoadChartReportsLoading} size="small">
                      <b>
                        {" "}
                        {t("Total Clicks")} :{" "}
                        {TotalDiamondClicks ? TotalDiamondClicks : 0}
                      </b>
                    </Spin>
                  </label>
                </div>
              </div>
            )}

          <div className="col-md-12 mt-2">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="chart__section">
            <Spin spinning={LoadChartReportsLoading} size="large">
              {TableForAllList && TableForAllList.length > 0 ? (
                <Bar
                  key={key}
                  onClick={(event) => {
                    const element = getElementAtEvent(
                      DiamondCutChartRef.current,
                      event
                    );
                    if (!element.length) return;

                    const { index } = element[0];
                    setTableCutGradeValue(diamondCutGradeSearchesLabels[index]);
                  }}
                  ref={DiamondCutChartRef}
                  data={{
                    labels: diamondCutGradeSearchesLabels,
                    datasets: [
                      {
                        label: "Count",
                        data: DiamondCutGradeYAxisList,
                        backgroundColor: DiamondCutGradeColorData,
                        borderColor: DiamondCutGradeColorData,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={300}
                  height={300}
                  options={{
                    scales: {
                      x: {
                        title: {
                          color: "rgb(66, 66, 66)",
                          display: false,
                          text: "Certificate",
                          font: {
                            size: 16,
                          },
                        },
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              ) : null}
            </Spin>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              key={tableKey}
              className="pendjewelreq__tbl"
              loading={TableForAllLoading}
              columns={[
                {
                  title: t("UserIPAddress"),
                  dataIndex: "usersIPAddress",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Date"),
                  sorter: (a, b) => {},
                  render: (item, row) => {
                    return moment(row.date).format("MM-DD-YYYY, hh:mm:ss A");
                  },
                },
                {
                  title: t("Shape"),
                  dataIndex: "shape",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Size"),
                  dataIndex: "size",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Color"),
                  dataIndex: "color",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Cut"),
                  dataIndex: "cutGrade",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Clarity"),
                  dataIndex: "clarity",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    if (item.clarity) {
                      return (
                        <React.Fragment>
                          <span>{item.clarity}</span>
                        </React.Fragment>
                      );
                    }
                  },
                },
                {
                  title: t("Certificate"),
                  dataIndex: "certificate",
                  sorter: (a, b) => {},
                },
                {
                  title: t("RetailerPrice"),
                  dataIndex: "retailPrice",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    return (
                      <React.Fragment>
                        <span>
                          {item.retailPrice ? (
                            <span>
                              {/* {loginDetials.currency ? "" : "$"} */}
                              {item.retailPrice}
                            </span>
                          ) : (
                            "Call For Price"
                          )}
                        </span>
                      </React.Fragment>
                    );
                  },
                },
                {
                  title: t("Cost"),
                  dataIndex: "cost",
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    return (
                      <React.Fragment>
                        <span>
                          {item.cost ? (
                            <span>
                              {/* {loginDetials.currency ? "" : "$"} */}
                              {item.cost}
                            </span>
                          ) : (
                            "Call For Price"
                          )}
                        </span>
                      </React.Fragment>
                    );
                  },
                },
              ]}
              dataSource={TableForAllList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondCutIndComp;
