import { useEffect, useState, useRef } from "react";
import { Doughnut, Pie, getElementAtEvent } from "react-chartjs-2";
import "./Doughnut.css";

const DoughnutChart = (props) => {
  const [chartCount, setChartCount] = useState(props.data);
  const [labeData, setLabelData] = useState(props.label);
  const [colorData, setColorData] = useState([]);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const dChart = useRef();

  useEffect(() => {
    chartDataHandler();
  }, []);

  function chartDataHandler() {
    const color = [];
    for (let i = 0; i < chartCount.length; i++) {
      color.push("#" + Math.floor(Math.random() * 16777215).toString(16));
    }
    setColorData(color);
  }

  return (
    <div className="chart-data">
      <Doughnut
        key={props.key}
        onClick={(event) => {
          const element = getElementAtEvent(dChart.current, event);
          const { index } = element[0];
          props.typeGetter(labeData[index], props.section);
        }}
        ref={dChart}
        data={{
          labels: labeData, //["Engagement Ring - Semi Mount","Rings","Earrings","Necklaces","Engagement Ring - Complete","Bracelets","Watches","Pendants","Charms","Estate Jewelry"],
          datasets: [
            {
              label: labeData, //["Engagement Ring - Semi Mount","Rings","Earrings","Necklaces","Engagement Ring - Complete","Bracelets","Watches","Pendants","Charms","Estate Jewelry"],
              data: chartCount, //[421842,240894,181591,175750,127002,113523,107855,96959,52631,13080],
              backgroundColor: colorData,
              borderColor: colorData,
              borderWidth: 1,
            },
          ],
        }}
        width={300}
        height={300}
        options={{
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: true,
              align: "center",
              color: "rgb(255, 255, 255)",
              font: {
                size: 10,
              },
              formatter: (value, context) => {
                const totalValue = props.totalValuePercentage;
                const percentage = ((value / totalValue) * 100).toFixed(2);
                const dataValues = context.chart.data.datasets[0].data;

                const topIndices = [...dataValues]
                  .map((val, idx) => ({ val, idx }))
                  .sort((a, b) => b.val - a.val)
                  .slice(0, 6)
                  .map((item) => item.idx);

                if (topIndices.includes(context.dataIndex)) {
                  return `${percentage}%`;
                }

                return "";
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  let label = context.label || "";
                  let formattedValue = context.formattedValue || "";

                  if (label) {
                    label =
                      label +
                      " " +
                      formattedValue +
                      " (" +
                      TooltipPercentage +
                      "%" +
                      ")";
                  }
                  return label;
                },
              },
            },
            legend: {
              position: "left",
              // maxHeight: 1000
            },
          },
        }}
      />
    </div>
  );
};

export default DoughnutChart;
