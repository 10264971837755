import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidemenu from "./sidemenu";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { setLoginToken } from "../../actions/login/login";
import { useNavigate, Link } from "react-router-dom";
import config from "../../helper/config";

export const Layout = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [checkLink, setCheckLink] = useState("");
  const [mobileMenuToggle, setMobileMenuToggle] = useState(true);
  const [FirstTier, setFirstTier] = useState([]);
  const [loadingTier, setLoadingTier] = useState(false);

  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const headerDetials = useSelector((state) => state.headerMenuReducer);

  // WhiteLabeling

  const WhiteLabeling = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, [loginDetials]);

  useEffect(() => {
    if (headerDetials) {
      if (headerDetials.checkLink) {
        setCheckLink(headerDetials.checkLink);
      }
    }
  }, [headerDetials]);

  const showTopNav = () => {
    setShow(!show);
  };

  const toggleMobileMenuCollapsed = () => {
    setMobileMenuToggle(!mobileMenuToggle);
  };

  const closeMobileToggle = () => {
    setMobileMenuToggle(true);
  };

  const toggleCollapsed = () => {
    setToggle(!toggle);
  };

  const onAction = (e) => {};

  const onActive = (e) => {};

  const onIdle = (e) => {
    handleLoginValueFalse();
  };

  const handleLoginValueFalse = async () => {
    dispatch(setLoginToken(null));
    navigate("/");
  };

  const {} = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: config.IdleTimer,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  const handleOpenLink = (link) => {
    window.open(link, "__blank");
  };

  const handleOnChange = () => {
    setMobileMenuToggle(true);
    setShow(false);
  };
  return (
    <React.Fragment>
      <div
        className={
          mobileMenuToggle
            ? "main__section hideMobileMenu"
            : "main__section showMobileMenu"
        }
      >
        <div
          className={
            toggle ? "sidenav__section partialwidth" : "sidenav__section"
          }
        >
          <Sidemenu
            toggle={toggle}
            toggleMobileMenuCollapsed={toggleMobileMenuCollapsed}
            checkLink={checkLink}
            mobileMenuToggle={mobileMenuToggle}
            closeMobileToggle={closeMobileToggle}
          />
          <button
            onClick={() => setMobileMenuToggle(true)}
            className="sidebar__close"
          >
            x
          </button>
        </div>

        <div
          className={toggle ? "right__section partialwidth" : "right__section"}
        >
          <div className="header__section">
            <Header
              checkLink={checkLink}
              toggle={toggle}
              toggleCollapsed={toggleCollapsed}
              toggleMobileMenuCollapsed={toggleMobileMenuCollapsed}
              mobileMenuToggle={mobileMenuToggle}
              showTopNav={showTopNav}
              show={show}
              FirstTier={FirstTier}
              loadingTier={loadingTier}
            />
          </div>
          {/* <div  className="working__area">
            <div className="catalog__list d-none"> */}
          <div className="working__area">
            <div className="catalog__list d-none">
              <div className="catalog__topnav">
                <ul>
                  <li>
                    {" "}
                    <a href="#">
                      {t("Conversations")} <span>27</span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      {t("Conversations")} <span>27</span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                      {t("Conversations")} <span>27</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              // onClick={() => setMobileMenuToggle(true)
              //   }
              onClick={() => handleOnChange()}
              className="content__area"
            >
              {props.children}
            </div>
          </div>
          <div className="footer__section">
            <div className="left__block">
              <div className="footer__logo">
                <img
                  className="imageHover"
                  src={WhiteLabeling?.whiteLebelData?.[0]?.footerLogo || ""}
                  alt=""
                  onClick={() =>
                    handleOpenLink(
                      WhiteLabeling?.whiteLebelData?.[0]?.footerLogoLink || ""
                    )
                  }
                />
              </div>
              <div className="footer__menu">
                <ul>
                  <li>
                    {/* <a href="#">{t("Terms of Service")}</a> */}
                    <Link to={"/termsOfService"}>
                      {t("Terms of Service")}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/privacyStatement"}>
                      {t("Privacy Statement")}
                    </Link>
                  </li>
                  <li>
                    <span>
                      Copyright {new Date().getFullYear()}{" "}
                      {WhiteLabeling?.whiteLebelData?.[0]?.name || ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right__block">
              <ul>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo1} alt="footerCompanyLogo1" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo2} alt="footerCompanyLogo2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo3} alt="footerCompanyLogo3" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo4} alt="footerCompanyLogo4" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo5} alt="footerCompanyLogo5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
