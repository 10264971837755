import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import scrollToTopIcon from "../../assets/images/scrollToTop.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrivacyStatement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mystyle = {
    color: "red",
    fontWeight: "bold",
  };
  const [showButton, setShowButton] = useState(false);

  const WhiteLabeling = useSelector((state) => state.loginReducer);
  console.log(WhiteLabeling, "-----------------");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  const handleOnTop = () => {
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  function handleRedirect() {
    navigate("/");
  }
  const handleOpenLink = (link) => {
    window.open(link, "__blank");
  };
  return (
    <React.Fragment>
      <div className="login__section register__section container-fluid h-100">
        <div className="form__fields">
          <div className="row">
            <div className="col-md-12">
              <div className="login__header">
                <div className="logo__section">
                  <img
                    src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""}
                    onClick={() => handleRedirect()}
                    // style={{ width: "30px", marginBottom: "10px" }}
                  />
                </div>
                <div className="right__text">
                  <p>Social Product Network for the Jewelry Industry</p>
                </div> 
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form__section">
                <div className="login__block w-100 terms0fservice">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="jewelcloud_tos_text">

                      <div dangerouslySetInnerHTML={{ __html: WhiteLabeling?.whiteLebelData?.[0]?.privacy_Statement }} />
                        {/* //{WhiteLabeling?.whiteLebelData?.[0]?.privacy_Statement || ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 p-0">
              <div className="footer__section login__footer position-relative">
                <div className="left__block">
                  <div className="footer__logo">
                    <img
                      className="imageHover"
                      src={WhiteLabeling?.whiteLebelData?.[0]?.footerLogo || ""}
                      alt=""
                      onClick={() =>
                        handleOpenLink(
                          WhiteLabeling?.whiteLebelData?.[0]?.footerLogoLink ||
                            ""
                        )
                      }
                    />
                  </div>
                  <div className="footer__menu">
                    <ul>
                      <li>
                        <Link to={"/termsOfService"} onClick={handleOnTop}>
                          {t("Terms of Service")}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/privacyStatement"} onClick={handleOnTop}>
                          {t("Privacy Statement")}
                        </Link>
                      </li>
                      <li>
                        <a href="#">
                          Copyright {new Date().getFullYear()}{" "}
                          {WhiteLabeling?.whiteLebelData?.[0]?.name || ""}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="right__block">
                  <ul>
                    <li>
                      <a href="#">
                        <img src={footerCompanyLogo1} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={footerCompanyLogo2} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={footerCompanyLogo3} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={footerCompanyLogo4} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={footerCompanyLogo5} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showButton && (
        // <button onClick={scrollToTop} className="back-to-top">
        //     &#8679;
        // </button>
        <div className="scrollToTop-section">
          <img
            src={scrollToTopIcon}
            onClick={scrollToTop}
            alt="scrollToTopBtn"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default PrivacyStatement;
