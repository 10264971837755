import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import RetailerDataService from "../../services/retailer-retailer.service";
import Create_icon from "../../assets/images/create_icon.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";
import { NotificationManager } from "react-notifications";
import { Select, Button, Spin } from "antd";
import moment from "moment";
import AdminSettingsService from "../../services/admin-settings.service";

const AppointmentsDashboard = ({ pageNameType }) => {
	const { t } = useTranslation();
	const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
	const [dealerId, setDealerId] = useState(0);
	const dispatch = useDispatch();
	const { Option } = Select;

	const [tradeShowList, setTradeShowList] = useState([]);
	const [salesRepList1, setSalesRepList1] = useState([]);
	const [appointmentList, setAppointmentList] = useState([]);
	const [appointmentLoading, setAppointmentLoading] = useState(false);
	const [datesList, setDatesList] = useState([]);
	const [appointmentData, setAppointmentData] = useState({
		filterByTradeShow: "",
		filterBySalesRep: "",
		fromDate: "",
		toDate: "",
	});
	const [updateSalesRepData, setUpdateSalesRepData] = useState({
		newDealerShowId: "",
		vendorId: "",
		retailerID: "",
		msgID: "",
		id: "",
	});
	const [rightSideData, setRightSideData] = useState([]);
	const [rightSideLoading, setRightSideLoading] = useState(false);
	const [initialCount, setInitialCount] = useState(0);
	const [tradeShowLoading, setTradeShowLoading] = useState(false);
	const [tradeShowData, setTradeShowData] = useState([]);

	const [columnName, setColumnName] = useState("");
	const [sortName, setSortName] = useState("");

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.responseData) {
				handleGetRetailerAppointmentData(loginDetials.responseData.dealerId);
				handleGetDropDownFilterData(loginDetials.responseData.dealerId);
				handleGetTradeShowDropdown(loginDetials.responseData.dealerId);
				setDealerId(loginDetials.responseData.dealerId);
			}
		}
	}, []);

	const handleHeaderMenuClickForSideProfileApp = (type) => {
		if (type == "Conversation") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Conversation",
					id: updateSalesRepData.retailerID,
				},
			});
		} else if (type == "Profile") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Profile",
					id: updateSalesRepData.retailerID,
				},
			});
		} else if (type == "Appointments") {
			navigate("/makeAppoitments", {
				state: {
					key: "Appointments",
					id: updateSalesRepData.retailerID,
				},
			});
		} else if (type == "Notes") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Notes",
					id: updateSalesRepData.retailerID,
				},
			});
		}
	};

	const handleOnChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setAppointmentData({
				...appointmentData,
				[name]: e,
			});

			if (name == "filterByTradeShow") {
				handleGetRetailerAppointmentData(
					loginDetials.responseData.dealerId,
					appointmentData.filterBySalesRep,
					e,
					appointmentData.fromDate,
					appointmentData.toDate
				);
			} else if (name == "filterBySalesRep") {
				handleGetRetailerAppointmentData(
					loginDetials.responseData.dealerId,
					e,
					appointmentData.filterByTradeShow,
					appointmentData.fromDate,
					appointmentData.toDate
				);
			}
		} else {
			setAppointmentData({
				...appointmentData,
				[e.target.name]: e.target.value,
			});

			if (name == "fromDate") {
				handleGetRetailerAppointmentData(
					loginDetials.responseData.dealerId,
					appointmentData.filterBySalesRep,
					appointmentData.filterByTradeShow,
					e.target.value,
					appointmentData.toDate
				);
			} else {
				handleGetRetailerAppointmentData(
					loginDetials.responseData.dealerId,
					appointmentData.filterBySalesRep,
					appointmentData.filterByTradeShow,
					appointmentData.fromDate,
					e.target.value
				);
			}
		}
	};

	const handleOnChangeSalesRep = (e) => {
		handleAddSalesRepsFromAppointment(e);
	};

	const openInNewTab = (websiteAddress) => {
		if (websiteAddress.includes("https")) {
			window.open(websiteAddress, "_blank");
		} else if (websiteAddress.includes("http")) {
			window.open(websiteAddress, "_blank");
		} else {
			window.open("https://" + websiteAddress, "_blank");
		}
	};

	const OnRowClick = (item) => {
		handleGetShowsDetailsByID(item.masterShowID);
		handleGetRightSideForAppointment(
			item.retailerId,
			item.vendorId,
			"1",
			item.newDealerShowId
		);
		setUpdateSalesRepData({
			newDealerShowId: item.newDealerShowId,
			vendorId: item.vendorId,
			retailerID: item.retailerId,
			msgID: item.messageId,
			id: item.id,
		});
	};
	/* Appointment API Binding Start */
	const handleGetShowsDetailsByID = async (showId) => {
		try {
			let inputData = {
				id: showId,
			};

			setTradeShowLoading(true);
			await AdminSettingsService.GetShowsDetailsByID(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							var tempData = responseData[0];
							setTradeShowLoading(false);
							setTradeShowData(tempData);
						}
					} else {
						setTradeShowLoading(false);
						setTradeShowData([]);
					}
				})
				.catch((error) => {
					console.log(error);
					setTradeShowLoading(false);
					setTradeShowData([]);
				});
		} catch (error) {
			console.log(error);
			setTradeShowLoading(false);
			setTradeShowData([]);
		}
	};

	// Table API

	const handleGetRetailerAppointmentData = async (
		id,
		salesRep,
		tradeShowId,
		fromDate,
		toDate,
		orderBy
	) => {
		try {
			let inputData = {
				dealerId: id.toString(),
				salesRep: salesRep ? salesRep : "",
				tradeShowId: tradeShowId ? tradeShowId : "",
				fromDate: fromDate ? fromDate : "",
				toDate: toDate ? toDate : "",
				orderBy: orderBy ? orderBy.toString() : "",
			};
			setAppointmentLoading(true);
			await AdminDashboardDataService.GetRetailerAppointmentData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var initialRecord = responseData[0];
					if (message === "Success") {
						if (responseData.length > 0) {
							setAppointmentList(responseData);
							setAppointmentLoading(false);
							var tempDates = [
								...new Set(
									responseData.map(({ appSearchDate }) => appSearchDate)
								),
							];
							setDatesList(tempDates);

							if (initialCount == 0) {
								handleGetRightSideForAppointment(
									initialRecord.retailerId,
									initialRecord.vendorId,
									"1",
									initialRecord.newDealerShowId
								);
								setUpdateSalesRepData({
									newDealerShowId: initialRecord.newDealerShowId,
									vendorId: initialRecord.vendorId,
									retailerID: initialRecord.retailerId,
									msgID: initialRecord.messageId,
									id: initialRecord.id,
								});
								setInitialCount(1);
							} else {
								handleGetRightSideForAppointment(
									updateSalesRepData.retailerID,
									updateSalesRepData.vendorId,
									"1",
									updateSalesRepData.newDealerShowId
								);
							}
						} else {
							setAppointmentList([]);
							setAppointmentLoading(false);
							setDatesList([]);
						}
					} else {
						setAppointmentList([]);
						setAppointmentLoading(false);
						setDatesList([]);
					}
				})
				.catch((error) => {
					console.log(error);
					setAppointmentLoading(false);
				});
		} catch (error) {
			console.log(error);
			setAppointmentLoading(false);
		}
	};

	// DropDown API

	const handleGetTradeShowDropdown = async (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			await RetailerDataService.GetDealerSelectedShowforappointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setTradeShowList(responseData);
						} else {
							setTradeShowList([]);
						}
					} else {
						setTradeShowList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetDropDownFilterData = async (id) => {
		try {
			let inputData = {
				dealerId: id.toString(),
			};
			await AdminDashboardDataService.GetDropDownFilterData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setTradeShowList(responseData.lstTradShow);
							setSalesRepList1(responseData.lstSalesRep);
						} else {
							setTradeShowList([]);
							setSalesRepList1([]);
						}
					} else {
						setTradeShowList([]);
						setSalesRepList1([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	// Sales rep Update API
	const handleAddSalesRepsFromAppointment = async (salesrep) => {
		try {
			let inputData = {
				vendorId: updateSalesRepData.vendorId.toString(),
				retailerID: updateSalesRepData.retailerID.toString(),
				salesRepId: salesrep.toString(),
				msgID: updateSalesRepData.newDealerShowId.toString(),
			};
			setAppointmentLoading(true);
			await AdminDashboardDataService.AddSalesRepsFromAppointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							NotificationManager.success(t("Sales Rep Updated Successfully."));
							setAppointmentLoading(false);
							handleGetRetailerAppointmentData(dealerId, "", "", "", "");
						} else {
							NotificationManager.error(t("Sales Rep Not Updated."));
							setAppointmentLoading(false);
						}
					} else {
						NotificationManager.success(t("Sales Rep Not Updated."));
						setAppointmentLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setAppointmentLoading(false);
				});
		} catch (error) {
			console.log(error);
			setAppointmentLoading(false);
		}
	};

	// RightSide Data API

	const handleGetRightSideForAppointment = async (
		retailerID,
		vendorId,
		typeId,
		msgId
	) => {
		try {
			let inputData = {
				dealerID: vendorId,
				retailerID: retailerID,
				typeId: 1,
				msgID: msgId,
			};
			setRightSideLoading(true);
			await AdminDashboardDataService.GetRightSideForAppointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setRightSideData(responseData);
							setRightSideLoading(false);
							setInitialCount(1);
						} else {
							setRightSideData([]);
							setRightSideLoading(false);
						}
					} else {
						setRightSideData([]);
						setRightSideLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setRightSideLoading(false);
				});
		} catch (error) {
			console.log(error);
			setRightSideLoading(false);
		}
	};

	// For Print Page

	const handlePrint = () => {
		var disp_setting =
			"toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
		disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
		var docprint = window.open("", "", disp_setting);
		docprint.document.open();
		var htmlContent = document.getElementById("printsection").innerHTML;

		htmlContent +=
			'<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
		htmlContent +=
			'<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

		docprint.document.write(htmlContent);
		docprint.document.close();

		setTimeout(() => {
			docprint.print();
		}, 500);
		docprint.focus();
	};

	// For Navigate Button Schedule New
	let navigate = useNavigate();

	const handleNavigate = () => {
		navigate("/makeAppoitments", {
			state: { key: "Appointments", id: updateSalesRepData.retailerID },
		});
	};

	// Sorting
	const handleThClick = (ColumnName) => {
		let sortType = "";
		setColumnName(ColumnName);
		if (ColumnName == columnName) {
			sortType = "Desc";
			setColumnName("");
		} else {
			sortType = "Asc";
		}
		handleTableCascade(ColumnName, sortType);
	};

	const handleTableCascade = (ColumnName, sortType) => {
		let sortBy = ColumnName + " " + sortType;
		setSortName(ColumnName + " " + sortType);
		handleGetRetailerAppointmentData(
			dealerId,
			appointmentData.filterBySalesRep,
			appointmentData.filterByTradeShow,
			appointmentData.fromDate,
			appointmentData.toDate,
			sortBy
		);
	};

	return (
		<React.Fragment>
			<div className="appointment-render-section">
				<div className="col-md-12 form__fields border-0 mt-5">
					<div className="prod__maindiv prodmarkup__secion permission__section appointment-page-section">
						<div className="row">
							<div>
								<img src="" />
							</div>
							<div className="col-md-12 magento__mapping">
								<div className="heading__block mb-3">
									<h4 class="workarea__heading mt-0 mb-0">
										{t("Appointment")}
									</h4>
									<div className="d-flex w-40 justify-content-end mobile-justify-content-start">
										<Button className="primary-btn" onClick={handleNavigate}>
											<img src={Create_icon} alt="" /> {t("Schedule New")}
										</Button>
										<Button onClick={handlePrint} className="primary-btn ml-2">
											<i class="fa fa-print" aria-hidden="true"></i>
											{t("Print Current List")}
										</Button>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="input__block">
									<label>{t("Filter By Trade Show")}</label>
									<Select
										className="border__grey"
										placeholder={t("Filter By Trade Show")}
										name="productType"
										optionFilterProp="children"
										value={appointmentData.filterByTradeShow}
										onChange={(e) =>
											handleOnChange(e, "select", "filterByTradeShow")
										}>
										<Option value="">{t("Filter By Trade Show")}</Option>
										{tradeShowList &&
											tradeShowList.map((item, i) => {
												return <Option value={item.id}>{item.showName}</Option>;
											})}
									</Select>
								</div>
							</div>

							<div className="col-md-3">
								<div className="input__block">
									<label>{t("Filter By Sales Rep")}</label>
									<Select
										className="border__grey"
										placeholder={t("Filter By Sales Rep")}
										name="productType"
										optionFilterProp="children"
										value={appointmentData.filterBySalesRep}
										onChange={(e) =>
											handleOnChange(e, "select", "filterBySalesRep")
										}>
										<Option value="">{t("Filter By Sales Rep")}</Option>
										{salesRepList1 &&
											salesRepList1.map((item, i) => {
												return (
													<Option
														key={`${item.userId}_${i}`}
														value={item.userId}>
														{item.name}
													</Option>
												);
											})}
									</Select>
								</div>
							</div>

							<div className="col-md-3">
								<div className="input__block">
									<label>{t("From Date")}</label>
									<input
										type="date"
										name="fromDate"
										value={appointmentData.fromDate}
										onChange={(e) => handleOnChange(e, "", "fromDate")}
									/>
								</div>
							</div>

							<div className="col-md-3">
								<div className="input__block">
									<label>{t("To Date")}</label>
									<input
										type="date"
										name="toDate"
										value={appointmentData.toDate}
										onChange={(e) => handleOnChange(e, "", "toDate")}
									/>
								</div>
							</div>
							<Spin spinning={tradeShowLoading}>
								<div className="row">
									<div className="col-md-2">
										<div className="trade__img">
											<img
												src={
													tradeShowData.showsImagePath
														? tradeShowData.showsImagePath
														: ""
												}
												className="img-fluid mb-2"
												alt=""
											/>
										</div>
									</div>
									<div className="col-md-10">
										<div className="trade__dtls">
											<h5 className="subheading">{tradeShowData.showName}</h5>
											<p className="mb-0">
												{tradeShowData.showStartDate} -
												{tradeShowData.showEndDate}
											</p>
											<p className="mb-0">{tradeShowData.state}</p>
											<span
												className="linkText"
												onClick={() => openInNewTab(tradeShowData.website)}>
												{tradeShowData.website}
											</span>
										</div>
									</div>
								</div>
							</Spin>

							<div className="col-lg-9 col-md-9">
								<div className="prodesc__div ">
									<div className="tab__contentdiv">
										<div className="col-lg-12">
											<div className="desc__maindiv">
												<div className="col-lg-12">
													<Spin spinning={appointmentLoading}>
														<div className="custom__table mobile-height-auto">
															<table className="table table-striped">
																<thead>
																	<tr>
																		<th
																			onClick={() =>
																				handleThClick("AppointmentTime")
																			}
																			style={{ width: "160px" }}>
																			{t("Time")}
																			<div className="date__sort">
																				<div className="date__child">
																					<div
																						className={
																							sortName == "AppointmentTime Asc"
																								? "up-arrow-active"
																								: "up-arrow"
																						}></div>
																					<div
																						className={
																							sortName == "AppointmentTime Desc"
																								? "down-arrow-active"
																								: "down-arrow"
																						}></div>
																				</div>
																			</div>
																		</th>
																		<th
																			onClick={() =>
																				handleThClick("DealerCompany")
																			}
																			style={{ width: "140px" }}>
																			{t("Store Name")}
																			<div className="date__sort">
																				<div className="date__child">
																					<div
																						className={
																							sortName == "DealerCompany Asc"
																								? "up-arrow-active"
																								: "up-arrow"
																						}></div>
																					<div
																						className={
																							sortName == "DealerCompany Desc"
																								? "down-arrow-active"
																								: "down-arrow"
																						}></div>
																				</div>
																			</div>
																		</th>
																		<th
																			onClick={() => handleThClick("BuyerName")}
																			style={{ width: "155px" }}>
																			{t("Buyer Name")}
																			<div className="date__sort">
																				<div className="date__child">
																					<div
																						className={
																							sortName == "BuyerName Asc"
																								? "up-arrow-active"
																								: "up-arrow"
																						}></div>
																					<div
																						className={
																							sortName == "BuyerName Desc"
																								? "down-arrow-active"
																								: "down-arrow"
																						}></div>
																				</div>
																			</div>
																		</th>
																		<th
																			onClick={() => handleThClick("Location")}
																			style={{ width: "140px" }}>
																			{t("Location")}
																			<div className="date__sort">
																				<div className="date__child">
																					<div
																						className={
																							sortName == "Location Asc"
																								? "up-arrow-active"
																								: "up-arrow"
																						}></div>
																					<div
																						className={
																							sortName == "Location Desc"
																								? "down-arrow-active"
																								: "down-arrow"
																						}></div>
																				</div>
																			</div>
																		</th>
																		<th
																			onClick={() => handleThClick("SalesReps")}
																			style={{ width: "140px" }}>
																			{t("Sales Rep")}
																			<div className="date__sort">
																				<div className="date__child">
																					<div
																						className={
																							sortName == "SalesReps Asc"
																								? "up-arrow-active"
																								: "up-arrow"
																						}></div>
																					<div
																						className={
																							sortName == "SalesReps Desc"
																								? "down-arrow-active"
																								: "down-arrow"
																						}></div>
																				</div>
																			</div>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{appointmentList.length === 0 && (
																		<tr>
																			<td colSpan="8">
																				<p className="table-no-data">
																					{t("No Data Found")}
																				</p>
																			</td>
																		</tr>
																	)}
																	{datesList.length > 0 &&
																		datesList.map((header, index) => {
																			return (
																				<React.Fragment
																					key={`${header.toString}+${index}`}>
																					<tr className="header__row">
																						<td
																							colSpan="5"
																							className="header__block">
																							<p className="date__format">
																								{moment(header).format(
																									"MMMM DD, YYYY "
																								)}
																							</p>
																						</td>
																					</tr>
																					{appointmentList &&
																						appointmentList
																							.filter(
																								(x) => x.appSearchDate == header
																							)
																							.map((item, i) => {
																								return (
																									<tr
																										key={i}
																										onClick={() =>
																											OnRowClick(item)
																										}>
																										<td>
																											{item.appointmentTime}
																										</td>
																										<td>
																											{item.dealerCompany}
																										</td>
																										<td>{item.buyerName}</td>
																										<td>{item.location}</td>
																										<td>{item.salesReps}</td>
																									</tr>
																								);
																							})}
																				</React.Fragment>
																			);
																		})}
																</tbody>
															</table>
														</div>
													</Spin>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-3">
								<Spin spinning={rightSideLoading}>
									{appointmentList.length > 0 ? (
										<div className="right__section">
											<div className="profile__block">
												<a href="#" className="retailer__name">
													{rightSideData.dealerCompany}
												</a>
												<div className="logoImg__block p-0">
													<img src={rightSideData.dealersImage} />
												</div>
												<p className="activeFrom">
													{t("Active")} | {rightSideData.activity}
												</p>
											</div>
											<hr />

											<div className="links__block">
												<ul>
													<li>
														<span
															onClick={() =>
																handleHeaderMenuClickForSideProfileApp(
																	"Profile"
																)
															}
															className="linkText">
															<i
																class="fa fa-address-book-o"
																aria-hidden="true"></i>{" "}
															{t("View Profile")}
														</span>
													</li>

													<li>
														<span
															onClick={() =>
																handleHeaderMenuClickForSideProfileApp(
																	"Conversation"
																)
															}
															className="linkText">
															<i
																class="fa fa-comments-o"
																aria-hidden="true"></i>{" "}
															{t("Conversation")}
														</span>
													</li>

													<li>
														<span
															onClick={() =>
																handleHeaderMenuClickForSideProfileApp(
																	"Appointments"
																)
															}
															className="linkText">
															<i
																class="fa fa-calendar-check-o"
																aria-hidden="true"></i>{" "}
															{t("Make Appointment")}
														</span>
													</li>

													<li>
														<span
															onClick={() =>
																handleHeaderMenuClickForSideProfileApp("Notes")
															}
															className="linkText">
															<i
																class="fa fa-pencil-square-o"
																aria-hidden="true"></i>{" "}
															{t("Store Notes")}
														</span>
													</li>
												</ul>
												<hr />
											</div>

											<div className="bottom__block">
												<div className="input__block">
													<label>{t("Sales Rep")}</label>
													<Select
														className="border__grey"
														placeholder={t("Sales Rep")}
														name="productType"
														optionFilterProp="children"
														value={
															rightSideData.salesRep
																? rightSideData.salesRep
																: ""
														}
														onChange={(e) => handleOnChangeSalesRep(e)}>
														<Option value="">Sales Rep</Option>
														{salesRepList1 &&
															salesRepList1.map((item, i) => {
																return (
																	<Option
																		key={`${item.userId}=${i}`}
																		value={item.userId}>
																		{item.name}
																	</Option>
																);
															})}
													</Select>
												</div>

												<div className="retailer__dtls mb-4">
													<p>{rightSideData.location}</p>
													<a href={`mailto:${rightSideData.dealerEmail}`}>
														{rightSideData.dealerEmail}
													</a>
													<p className="mt-2">{rightSideData.dealerPhone}</p>

													<hr />
												</div>
											</div>
										</div>
									) : (
										<div className="right__section">
											<p className="no_data_found">{t("No Data Found")}</p>
										</div>
									)}
								</Spin>
							</div>
						</div>
					</div>
				</div>
				<div id="printsection" style={{ padding: "10px", display: "none" }}>
					<div style={{ padding: "20px" }}>
						<table className="table table-bordered table-striped">
							<thead>
								<tr>
									<th>{t("Time")}</th>
									<th>{t("Store Name")}</th>
									<th>{t("Buyer Name")}</th>
									<th>{t("Location")}</th>
									<th>{t("Sales Rep")}</th>
								</tr>
							</thead>
							<tbody>
								{datesList &&
									datesList.map((header, index) => {
										return (
											<React.Fragment key={index}>
												<tr className="header__row">
													<td colSpan="5" className="header__block">
														{header}
													</td>
												</tr>
												{appointmentList &&
													appointmentList
														.filter((x) => x.appSearchDate == header)
														.map((item, i) => {
															return (
																<tr key={i} onClick={() => OnRowClick(item)}>
																	<td></td>
																	<td>{item.dealerCompany}</td>
																	<td>{item.buyerName}</td>
																	<td>{item.location}</td>
																	<td>{item.salesReps}</td>
																</tr>
															);
														})}
											</React.Fragment>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{/* )} */}
		</React.Fragment>
	);
};

export default AppointmentsDashboard;
