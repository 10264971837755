import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Modal,
  Spin,
} from "antd";

import RetailerLogo from "../../assets/images/retailer-logo.jpeg";

import { Tabs, Tab } from "react-bootstrap";
import userIcon from "../../assets/images/user-icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const RetailerProfileNew = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const [visible, setVisibleTag] = useState(false);
  const [visibleNote, setOpen] = useState(false);

  const handleCancel = () => {
    setVisibleTag(false);
  };

  const handleOk = () => {
    setVisibleTag(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">Retailer Profile</h4>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                    >
                      <Tab eventKey="Profile" title="Profile">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="input__block">
                              <div class="image_block p-0 border-0">
                                <div className="divimage_block">
                                  <label
                                    for="file-upload"
                                    class="primary-btn choose_file_upload"
                                  >
                                    Choose Image
                                  </label>
                                  <input id="file-upload" type="file" />
                                  <span className="mb-4">No File Choosen</span>
                                </div>

                                <div className="preview__img choose_file_divimg d-none">
                                  <img
                                    src={userIcon}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                  <span style={{ right: "10px", top: "10px" }}>
                                    x
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input__block">
                              <p className="font__bold mb-1">testgem</p>
                              <p className="linkText mb-1">Testgem@gmail.com</p>
                              <p className="mb-1">9876543210</p>
                              <p className="mb-1">
                                <span className="linkText">0</span> Favorites
                              </p>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <button className="primary-btn">Add Details</button>
                          </div>
                          <div className="col-md-12">
                            <hr />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="input__block">
                              <Select
                                className="border__grey"
                                placeholder={t("Select Details")}
                                name="productType"
                                optionFilterProp="children"
                              >
                                <Option value="Option 1"> Birthday </Option>
                                <Option value="Option 2"> Anniversary </Option>
                                <Option value="Option 3"> Gender </Option>
                                <Option value="Option 4">
                                  {" "}
                                  LH Middle Finger{" "}
                                </Option>
                                <Option value="Option 5"> LH Thumb </Option>
                                <Option value="Option 6">
                                  {" "}
                                  RH Index Finger{" "}
                                </Option>
                                <Option value="Option 7">
                                  {" "}
                                  RH Middle Finger{" "}
                                </Option>
                                <Option value="Option 8">
                                  {" "}
                                  Ring Hand Thumb Size{" "}
                                </Option>
                                <Option value="Option 9">
                                  {" "}
                                  Left Hand Pinky{" "}
                                </Option>
                                <Option value="Option 10">
                                  {" "}
                                  Left Hand Pointer{" "}
                                </Option>
                                <Option value="Option 11">
                                  {" "}
                                  Right Hand Pinky{" "}
                                </Option>
                                <Option value="Option 12">
                                  {" "}
                                  Right Hand Pointer{" "}
                                </Option>
                                <Option value="Option 13">
                                  {" "}
                                  Home Address{" "}
                                </Option>
                                <Option value="Option 14">
                                  {" "}
                                  Work Address{" "}
                                </Option>
                                <Option value="Option 15">
                                  {" "}
                                  Vacation / Holiday Address{" "}
                                </Option>
                                <Option value="Option 16">
                                  {" "}
                                  Partner Address{" "}
                                </Option>
                                <Option value="Option 17">
                                  {" "}
                                  Office Address{" "}
                                </Option>
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="input__block">
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button className="primary-btn mr-2">
                                  Update
                                </button>
                                <button className="primary-btn">Cancel</button>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="input__block">
                                  <Select
                                    className="border__grey"
                                    placeholder={t("Select Gender")}
                                    name="productType"
                                    optionFilterProp="children"
                                  >
                                    <Option value="Option 1"> Male </Option>
                                    <Option value="Option 2"> Female </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <button className="primary-btn mr-2">
                                  Update
                                </button>
                                <button className="primary-btn">Cancel</button>
                              </div>
                            </div>

                            {/* address block */}

                            <div className="row">
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>Street</label>
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>Suite</label>
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>City</label>
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>State</label>
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>Zip Code</label>
                                  <input type="text" />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input__block">
                                  <button className="primary-btn mr-2">
                                    Update
                                  </button>
                                  <button className="primary-btn">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <hr />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="custom__table">
                              <table className="table table-striped">
                                <tbody>
                                  <tr>
                                    <td className="w-30">
                                      <p>Billing Address</p>
                                    </td>
                                    <td>
                                      <p>
                                        test street test Suite test city test
                                        state 400000000
                                      </p>
                                      <div className="d-none">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Street</label>
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Suite</label>
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>City</label>
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>State</label>
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Zip Code</label>
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="input__block">
                                              <button className="primary-btn mr-2">
                                                Update
                                              </button>
                                              <button className="primary-btn">
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="action__btns">
                                        <div className="image__block">
                                          <img src={Edit_icon} alt="" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="w-30">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          placeholder={t("Select")}
                                          name="productType"
                                          optionFilterProp="children"
                                        >
                                          <Option value="Option 2">
                                            {" "}
                                            Home{" "}
                                          </Option>
                                          <Option value="Option 1">
                                            {" "}
                                            Mobile{" "}
                                          </Option>
                                          <Option value="Option 2">
                                            {" "}
                                            Office{" "}
                                          </Option>
                                          <Option value="Option 2">
                                            {" "}
                                            Fax{" "}
                                          </Option>
                                        </Select>
                                      </div>
                                    </td>
                                    <td>
                                      <p>9876543210</p>
                                      <div className="d-none">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <button className="primary-btn mr-2">
                                              Update
                                            </button>
                                            <button className="primary-btn">
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="action__btns">
                                        <div className="image__block">
                                          <img src={Edit_icon} alt="" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w-30">
                                      <p>E-Mail</p>
                                    </td>
                                    <td>
                                      <p>Testgem@gmail.com</p>
                                      <div className="d-none">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <input type="text" />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <button className="primary-btn mr-2">
                                              Update
                                            </button>
                                            <button className="primary-btn">
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="action__btns">
                                        <div className="image__block">
                                          <img src={Edit_icon} alt="" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w-30">
                                      <p>Favorite Metal</p>
                                    </td>
                                    <td>
                                      <p>10K Gold</p>
                                      <div className="d-none">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <Select
                                                className="border__grey"
                                                placeholder={t("Select")}
                                                name="productType"
                                                optionFilterProp="children"
                                              >
                                                <Option value="Option 2">
                                                  {" "}
                                                  10K Gold
                                                </Option>
                                                <Option value="Option 1">
                                                  {" "}
                                                  14K Gold
                                                </Option>
                                                <Option value="Option 2">
                                                  {" "}
                                                  18K Gold
                                                </Option>
                                              </Select>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <button className="primary-btn mr-2">
                                              Update
                                            </button>
                                            <button className="primary-btn">
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="action__btns">
                                        <div className="image__block">
                                          <img src={Edit_icon} alt="" />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Conversation"
                        title={t("Conversation")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="editor__section">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <div className="text-right">
                                  <span className="linkText mr-2">
                                    Add Favorites
                                  </span>
                                  <Button className="primary-btn">
                                    {t("Post")}
                                  </Button>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>
                                        4 Days ago - Alex Fetanat @{" "}
                                        <span className="linkText">
                                          GemFind
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <p className="linkText">Delete</p>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>
                                        4 Days ago - Alex Fetanat @{" "}
                                        <span className="linkText">
                                          GemFind
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <p className="linkText">Delete</p>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>
                                        4 Days ago - Alex Fetanat @{" "}
                                        <span className="linkText">
                                          GemFind
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <p className="linkText">Delete</p>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Notes" title={t("Notes")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-6 mt-2">
                                <div className="note__headdiv">
                                  <span className="note__txt">2 Notes</span>
                                  <span className="line__txt">|</span>
                                  <span
                                    className="createnote__txt"
                                    onClick={() => setOpen(true)}
                                  >
                                    Create A New Note
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-5">
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>test - Thursday, April 21</span>
                                    </div>
                                    <div>
                                      <span
                                        className="edit__txt linkText"
                                        onClick={setOpen}
                                      >
                                        Edit
                                      </span>
                                      <span className="line__txt">|</span>
                                      <span className="del__txt linkText">
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>test - Thursday, April 21</span>
                                    </div>
                                    <div>
                                      <span
                                        className="edit__txt linkText"
                                        onClick={setOpen}
                                      >
                                        Edit
                                      </span>
                                      <span className="line__txt">|</span>
                                      <span className="del__txt linkText">
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <Modal
                  title="Modal 1000px width"
                  centered
                  visible={visibleNote}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={onOk}>
                      {t("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={onCancel}>
                      {t("Submit")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input__block">
                                <input type="text" placeholder={t("Note Name")} />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="editor__section">
                                <CKEditor
                                  editor={ClassicEditor}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <div className="col-lg-3 col-md-3">
                  <div className="right__section">
                    <div className="profile__block">
                      <a href="#" className="retailer__name">
                        John bill
                      </a>
                      <div className="logoImg__block p-0">
                        <img src={RetailerLogo} />
                      </div>
                      <div className="retailer__dtls mb-4">
                        <p>0 Messages</p>
                        <p>0 Favorites</p>
                        <p>0 Notes</p>
                        <p className="activeFrom">
                          Active | 10/08/2020 @ 12:42PM
                        </p>
                        <hr />
                      </div>
                      <div className="input__block">
                        <label>Verified</label>
                        <Switch
                          checkedChildren={t("Yes")}
                          unCheckedChildren={t("No")}
                          defaultChecked
                        />
                        <hr />
                      </div>
                      <div className="input__block">
                        <p>Johnbill@gmail.com</p>
                        <p>Registered: 08/08/2022</p>
                        <hr />
                      </div>

                      <div className="input__block">
                        <div className="d-flex">
                          <p>
                            <span className="mr-2">Contact Tags</span>{" "}
                            <span
                              className="linkText"
                              onClick={() => setVisibleTag(true)}
                            >
                              + Add Tag
                            </span>
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex">
                          <span className="linkText mr-1">Test</span>
                          <span className="linkText mr-1">Test2</span>
                        </div>
                      </div>

                      <div className="input__block">
                        <label>Favorites</label>
                        <div className="d-flex">
                          <input type="text" className="w-70 mr-1" />
                          <button className="primary-btn w-20">Add</button>
                        </div>
                        <div>
                          <p className="mt-2 mb-0">Test</p>
                          <p className="mt-2 mb-0">Test</p>
                        </div>
                      </div>
                    </div>

                    <div className="bottom__block">
                      <div className="input__block">
                        <label>Important Customer Note</label>
                        <textarea></textarea>
                        <div className="text-right">
                          <button className="primary-btn w-20 mt-2">
                            Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal
                  title="Contact Tags"
                  centered
                  visible={visible}
                  onOk={() => setVisibleTag(false)}
                  onCancel={() => setVisibleTag(false)}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      Submit
                    </Button>,
                  ]}
                  width={400}
                >
                  <div className="form__fields p-0 border-0">
                    <div className="input__block">
                      <label>Enter Tag Name</label>
                      <input type="text" />
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RetailerProfileNew;
