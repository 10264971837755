import React, { useState, useEffect } from "react";
import { Button, Switch, Modal, Table, Tabs, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import UserImg_icon from "../../assets/images/UserImage.png";
import { NotificationManager } from "react-notifications";
import Earth_icon from "../../assets/images/earth_icon.png";
import {
  setContactConversationCount,
  setContactAppointmentCount,
  setContactCustomerCount,
  setContactPageNameType,
} from "../../actions/Contact/Contact";

const Customer = (props) => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [visible, setVisibleTag] = useState(false);
  const [custmerList, setCustmerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dealerId, setDealerId] = useState(loginDetials.loginDetials.responseData.dealerId??0);
  const [rightSideLoading, setRightSideLoading] = useState(false);
  const [rightSideData, setRightSideData] = useState([]);
  const [rightSideDetails, setRightSideDetails] = useState({
    cust_id: "",
  });
  const [noteData, setNoteData] = useState({
    note: "",
  });

  const [favouriteData, setFavouriteData] = useState({
    favourite: "",
  });

  const [favouriteList, setFavouriteList] = useState([]);
  const [tagData, setTagData] = useState({
    tag: "",
  });
  const [validations2, setValidations2] = useState({
    tagVal: "",
  });
  const [tagList, setTagList] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [leftSideData, setLeftSideData] = useState({});
  const [leftSideLoading, setLeftSideLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");

  // Count States and Others

  const [conservationCount, setConservationCount] = useState(0);
  const [appointmentcount, setAppointmentCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const ContactDetails = useSelector((state) => state.contactReducer);

  useEffect(() => {
    if (ContactDetails) {
      setConservationCount(ContactDetails.contactConversationCount);
      setAppointmentCount(ContactDetails.contactAppointmentCount);
      setCustomerCount(ContactDetails.contactCustomerCount);
    }
  }, [ContactDetails]);

  const handleSendCount = (val1, val2, val3) => {
    dispatch(setContactConversationCount(val1));
    dispatch(setContactAppointmentCount(val2));
    dispatch(setContactCustomerCount(val3));
  };

  const handleGetCustomerCount = async (id, condition, sortby) => {
    let inputData = {
      dealerId: id.toString(),
      condition:
        "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30",
      sortBy: sortby ? sortby : "",
    };

    try {
      AdminDashboardDataService.GetCustomerCount(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            handleSendCount(
              responseData.conservationCount,
              responseData.appointmentcount,
              responseData.customerCount
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetCustomerContactDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
          setTimeout(() => {
            handleGetLeftSideMenuDetails(
              loginDetials.loginDetials.responseData.dealerId
            );
          }, 200);

          if (ContactDetails) {
            setConservationCount(ContactDetails.contactConversationCount);
            setAppointmentCount(ContactDetails.contactAppointmentCount);
            setCustomerCount(ContactDetails.contactCustomerCount);
            setTimeout(() => {
              handleGetCustomerCount(
                loginDetials.loginDetials.responseData.dealerId
              );
            }, 100);
          }
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisibleTag(false);
    setTagData({
      tag: "",
    });
    setValidations2({
      tagVal: "",
    });
  };

  // Customer Grid Table
  const handleGetCustomerContactDetails = async (
    id,
    searchText,
    tags,
    sortBy,
    searchType
  ) => {
    let inputData = {
      dealerID: id,
      searchText: searchText ? searchText.toString() : "",
      tags: tags ? tags.toString() : "",
      sortBy: sortBy ? sortBy.toString() : "",
      searchType: searchType ? searchType.toString() : "",
    };
    setLoading(true);
    try {
      AdminDashboardDataService.GetCustomerContactDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setCustmerList(responseData);
              handleGetCustomerContactDetailsById(responseData[0].cust_id)
              setRightSideDetails({
                cust_id: responseData[0].cust_id,
              });
              setLoading(false);
            } else {
              setCustmerList([]);
              setRightSideData([]);
              setLoading(false);
            }
          } else {
            setCustmerList([]);
            setRightSideData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Right Side Data API
  const handleGetCustomerContactDetailsById = async (id) => {
    let inputData = {
      dealerID: dealerId,
      cust_Id: id,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.GetCustomerContactDetailsById(inputData)
        .then((response) => {
          let message = response.data.message;
          let rightSideResData = response.data.responseData.customerReponse[0];
          if (message === "Success") {
            if (rightSideResData) {
              setRightSideData(rightSideResData);
              handleGetFavoriteListDetails(id);
              handleGetTagsListDetails(id);
              setRightSideLoading(false);
            } else {
              setRightSideData([]);
              handleGetFavoriteListDetails(id);
              handleGetTagsListDetails(id);
              setRightSideLoading(false);
            }
          } else {
            setRightSideData([]);
            setRightSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  // Get FavouriteList
  const handleGetFavoriteListDetails = async (id) => {
    let inputData = {
      dealerID: dealerId,
      cust_Id: id,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.GetFavoriteListDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            setFavouriteList(responseData);
          } else {
            setFavouriteList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setTagData({
        ...tagData,
        [name]: e,
      });
    } else {
      setTagData({
        ...tagData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "tag") {
        setValidations2((prevdata) => ({
          ...prevdata,
          tagVal: "",
        }));
      }
    }
  };

  const handleValidationTag = () => {
    const { tag } = tagData;

    const validations2 = {
      tagVal: "",
    };
    let isValid = true;

    if (!tag.trim()) {
      isValid = false;
      validations2.tagVal = t("Tag Cannot Be Blank.");
    } else {
      validations2.tagVal = "";
    }

    setValidations2(validations2);
    return isValid;
  };

  // Add Tag

  const handleInsertTagsList = () => {
    let isValid = handleValidationTag();

    if (!isValid) {
      return false;
    }
    let inputData = {
      customerId: rightSideDetails.cust_id,
      tagName: tagData.tag ? tagData.tag : "",
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.InsertTagsList(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(t("Tag Added Successfully."));
            handleCancel();
            handleGetCustomerContactDetailsById(rightSideDetails.cust_id);
            setRightSideLoading(false);
            setTagData({
              tag: "",
            });
          } else {
            NotificationManager.error(t("Tag Not Added."));
            handleCancel();
            setRightSideLoading(false);
            setTagData({
              tag: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  const handleGetTagsListDetails = async (id) => {
    let inputData = {
      cust_Id: id,
      dealerID: dealerId,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.GetTagsListDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            setTagList(responseData);
            setRightSideLoading(false);
          } else {
            setTagList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let Sortby = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column =
        sorter.field == "fullName"
          ? "FullName"
          : sorter.field == "activity"
          ? "Activity"
          : sorter.field == "status"
          ? "Status"
          : sorter.field == "appointment"
          ? "Appointment"
          : "";
      Sortby = column + " " + type;
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column =
        sorter.field == "fullName"
          ? "FullName"
          : sorter.field == "activity"
          ? "Activity"
          : sorter.field == "status"
          ? "Status"
          : sorter.field == "appointment"
          ? "Appointment"
          : "";
      Sortby = column + " " + type;
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortType(type);
    }

    handleGetCustomerContactDetails(dealerId, "", "", Sortby, "");
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  const columns = [
    {
      title: t("Contact"),
      dataIndex: "fullName",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <span
            onClick={() => handleRedirection("Profile", row.cust_id)}
            className="linkText"
          >
            {row.fullName}
          </span>
        );
      },
    },
    {
      title: t("Activity"),
      dataIndex: "activity",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return <span className="my-hover-pointer">{row.activity}</span>;
      },
    },

    {
      title: t("Conversation"),
      dataIndex: "status",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <span
            onClick={() => handleRedirection("Conversation", row.cust_id)}
            className="linkText"
          >
            {row.status}
          </span>
        );
      },
    },
    {
      title: t("Appointment"),
      dataIndex: "appointment",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return (
          <span className="linkText"
          onClick={(row.appointment == "" && props?.onClickSchedule)?props.onClickSchedule:undefined}
          >
            {row.appointment == "" ? "Schedule" : row.appointment}
          </span>
        );
      },
    },
  ];

  const handleRedirection = (type, id) => {
    if (type == "Conversation") {
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: id || rightSideDetails.cust_id,
        },
      });
    } else if (type == "Profile") {
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id || rightSideDetails.cust_id,
        },
      });
    }
  };

  const handleOnAppointmentClick = () => {
    navigate("/contact", {
      state: {
        key: "AppointmentsOnly",
      },
    });
    dispatch(setContactPageNameType("AppointmentsOnly"));
  };

  const handleOnSwitchChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRightSideData({
        ...rightSideData,
        [name]: e,
      });

      if (name == "isVerified") {
        handleUpdateUserSwitchDetails(e);
      }
    } else {
      setRightSideData({
        ...rightSideData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleUpdateUserSwitchDetails = (e) => {
    let inputData = {
      cust_id: rightSideDetails.cust_id ? rightSideDetails.cust_id : 0,
      switchValue: e,
    };

    try {
      AdminDashboardDataService.UpdateUserSwitchDetails(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            if (e) {
              NotificationManager.success("Verified Successfully.", "", 1000);
              handleGetCustomerContactDetailsById(rightSideDetails.cust_id);
            } else {
              NotificationManager.success(
                t("Verified Revoked Successfully."),
                "",
                1000
              );
              handleGetCustomerContactDetailsById(rightSideDetails.cust_id);
            }
          } else {
            NotificationManager.error(t("Not Verified."), "", 1000);
            handleGetCustomerContactDetailsById(rightSideDetails.cust_id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetLeftSideMenuDetails = (id) => {
    let inputData = {
      dealerID: id ? id.toString() : "",
    };
    setLeftSideLoading(true);
    try {
      AdminDashboardDataService.GetLeftSideMenuDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message === "Success") {
            setLeftSideData(responseData);
            setLeftSideLoading(false);
          } else {
            setLeftSideData({});
            setLeftSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLeftSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLeftSideLoading(false);
    }
  };

  const handleOnRedirect = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleRefresh = () => {
    handleGetCustomerContactDetails(dealerId);
  };
  return (
    <div className="pendjewelreq__section form__fields">
      <div className="row  magento__mapping permission__section">
        <div className="col-md-12">
          {/* <h4 className="workarea__heading">Jewelry Category Report</h4> */}
          <div className="heading__block">
            <h4 className="workarea__heading mt-0 mb-0">
              {t("Customers")}
            </h4>
          </div>
        </div>

        <div className="col-lg-9 col-md-9">
          <div className="refresh__btn">
            <Button
              type="primary"
              onClick={() => {
                handleRefresh();
              }}
            >
              {t("Refresh")}
            </Button>
          </div>
          <Spin spinning={loading}>
            <Table
              className="pendjewelreq__tbl my-hover-pointer"
              columns={columns}
              dataSource={custmerList}
              onChange={handleTableASCDES}
              pagination={{
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              scroll={{ x: 600, y: 800 }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  setFavouriteData({
                    favourite: "",
                  });
                  setNoteData({
                    note: "",
                  });
                  handleGetCustomerContactDetailsById(record.cust_id);
                  setRightSideDetails({
                    cust_id: record.cust_id,
                  });
                },
              })}
              rowClassName={(record) =>
                record.cust_id == rightSideDetails.cust_id
                  ? "row-active-highlight"
                  : ""
              }
            />
          </Spin>
        </div>

        <div className="col-lg-3 col-md-3">
          <Spin spinning={rightSideLoading}>
            {rightSideDetails.cust_id != "" && (
              <div className="right__section">
                <Tabs>
                  <Tabs.TabPane tab={t("Customer Info")} key="item-1">
                    <div className="profile__block">
                      <a
                        onClick={() => handleRedirection("Profile")}
                        className="retailer__name"
                      >
                        {rightSideData.fullName ? rightSideData.fullName : ""}
                      </a>
                      <div className="logoImg__block p-0">
                        <img
                          src={
                            rightSideData.avatarValue
                              ? rightSideData.avatarValue
                              : UserImg_icon
                          }
                        />
                      </div>
                      <div className="retailer__dtls mb-4">
                        <p
                          className="linkText"
                          onClick={() => handleRedirection("Conversation")}
                        >
                          {rightSideData.totalMessages} {t("Messages")}
                        </p>
                        <div className="d-flex align-items-center">
                          <div>
                            <img src={Earth_icon} />
                          </div>

                          <p className="activeFrom mb-0 ml-1">
                            {t("Active")} | {rightSideData.activity}
                          </p>
                        </div>

                        <hr />
                      </div>
                      <div className="input__block">
                        <label>{t("Verified")}</label>
                        <Switch
                          name="switch"
                          checkedChildren={t("Yes")}
                          unCheckedChildren={t("No")}
                          checked={
                            rightSideData.verified == 1 ||
                            rightSideData.verified == true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleOnSwitchChange(e, "select", "isVerified")
                          }
                        />
                        <hr />
                      </div>
                      <div className="input__block">
                        <a href={`mailto:${rightSideData.cEmail}`}>
                          {rightSideData.cEmail}
                        </a>
                        <p>{rightSideData.phoneNumber}</p>
                        <p>
                          {t("Registered")}:{" "}
                          {rightSideData.dateformated
                            ? rightSideData.dateformated
                            : rightSideData.createdDate}
                        </p>
                        <hr />
                      </div>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t("Other Info")} key="item-2">
                    <div className="profile__block">
                      <a
                        className="retailer__name"
                        onClick={() => handleOnRedirect()}
                      >
                        {customerCount ? customerCount : 0}{" "}
                        {t("Contacts")}
                      </a>
                    </div>
                    <div className="input__block">
                      <label>{t("Most Active")}</label>
                      {leftSideData.mostActive &&
                      leftSideData.mostActive.length > 0 ? (
                        leftSideData.mostActive.map((item, i) => {
                          return (
                            <p>
                              <a
                                onClick={() =>
                                  handleRedirection("Profile", item.cust_id)
                                }
                                className="linkText"
                              >
                                {item.fullName}
                              </a>
                            </p>
                          );
                        })
                      ) : (
                        <span className="linkText">
                          {t("No Data To Display")}
                        </span>
                      )}
                      <hr />
                    </div>
                    <div className="input__block">
                      <label>{t("Recent Conversations")}</label>
                      {leftSideData.recentConversations &&
                      leftSideData.recentConversations.length > 0 ? (
                        leftSideData.recentConversations.map((item, i) => {
                          return (
                            <p>
                              <a
                                onClick={() =>
                                  handleRedirection("Profile", item.cust_id)
                                }
                                className="linkText"
                              >
                                {item.fullName}
                              </a>
                            </p>
                          );
                        })
                      ) : (
                        <span className="linkText">
                          {t("No Data To Display")}
                        </span>
                      )}
                      <hr />
                    </div>
                    <div className="input__block">
                      <label>{t("Appointments")}</label>
                      {leftSideData.appointments &&
                      leftSideData.appointments.length > 0 ? (
                        leftSideData.appointments.map((item, i) => {
                          return (
                            <p>
                              <a
                                onClick={() => handleOnAppointmentClick()}
                                className="linkText"
                              >
                                {item.dayDate}
                                {" - "}
                                {item.appointmentTime}
                                {" - "}
                                {item.fullName}
                              </a>
                            </p>
                          );
                        })
                      ) : (
                        <span className="linkText">
                          {t("No Data To Display")}
                        </span>
                      )}
                      <hr />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            )}
          </Spin>
        </div>

        <Modal
          title={t("Contact Tags")}
          centered
          visible={visible}
          onOk={() => setVisibleTag(false)}
          onCancel={() => setVisibleTag(false)}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleInsertTagsList}>
              {t("Submit")}
            </Button>,
          ]}
          width={400}
        >
          <div className="form__fields p-0 border-0">
            <div className="input__block">
              <label>{t("Enter Tag Name")}</label>
              <input
                type="text"
                name="tag"
                value={tagData.tag}
                onChange={handleTagChange}
                className={validations2.tagVal && "border__red"}
              />
              <div>
                {validations2.tagVal && (
                  <p className="error-color-red">{validations2.tagVal}</p>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Customer;
