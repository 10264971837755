import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const PurchaseOrderHistory = props => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t("Retailer Name"),
            dataIndex: "retailerName",
        },
        {
            title: t("Product"),
            dataIndex: "product",
        },
        {
            title: t("Purchase order date"),
            dataIndex: "purchaseOrderDate",
        },
        {
            title: t("Order Confirm Date"),
            dataIndex: "orderConfirmDate",
        },
        {
            title: t("Status"),
            dataIndex: "status",
        },
        {
            title: t("Sale Per"),
            dataIndex: "salePer",
        }
    ];

    const data = [
        {
            key: "1",
            retailerName: "Retailer 1",
            product: "Product1232",
            purchaseOrderDate: "10/10/2019",
            orderConfirmDate: "10/20/2019",
            status: "Confirmed",
            salePer: "User1",
        },
        {
            key: "2",
            retailerName: "Retailer 1",
            product: "Product1232",
            purchaseOrderDate: "10/10/2019",
            orderConfirmDate: "10/20/2019",
            status: "Confirmed",
            salePer: "User1",
        },
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0"> {t("Purchase Order History")} </h4>
                    </div>
                </div>
                <div className="divForm">
                    <Table columns={columns} dataSource={data} scroll={{ x: 600,y: 800 }} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrderHistory);
