import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	Input,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Switch,
	Tooltip,
	Table,
	Modal,
	Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import VendorbgImg1 from "../../assets/images/Rectangle1.png";
import { useSelector, useDispatch } from "react-redux";
import { isEditable } from "@testing-library/user-event/dist/utils";
import {
	setReduxMessageId,
	setReduxRetailerId,
	setReduxTabName,
	setReduxDefaultMessage,
} from "../../actions/Retailer/retailer";
import parse from "html-react-parser";

const RetailerProfile = () => {
	const { t } = useTranslation();
	const { Option } = Select;

	const [visible, setVisible] = useState(false);
	const [profileData, setProfileData] = useState({});
	const [images, setImages] = useState([]);
	const [contactData, setContactData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingprofile, setLoadingProfile] = useState(false);
	const [loadingdetails, setLoadingDetails] = useState(false);
	const [loadingConversation, setLoadingConversation] = useState(false);
	const [loadingSaveConversation, setLoadingSaveConversation] = useState(false);
	const [loadingNotes, setLoadingNotes] = useState(false);
	const [loadingSaveNotes, setLoadingSaveNotes] = useState(false);
	const [loadingAppointment, setLoadingAppointment] = useState(false);
	const [loadingAccess, setLoadingAccess] = useState(false);
	const [loadingContact, setLoadingContact] = useState(false);
	const [retailerId, setRetailerId] = useState("0");
	const [messageId, setMessageId] = useState("");
	const [defaultAppointmentTxt, setDefaultAppointmentTxt] = useState("");
	const [accessData, setAccessData] = useState([]);
	const [loadAppointmentDataList, setLoadAppointmentDataList] = useState([]);
	const [notes, setNotes] = useState({
		id: 0,
		title: "",
		description: "",
	});
	const [validations, setValidations] = useState({
		conversationVal: "",
		notesTitleVal: "",
		notesDescriptionVal: "",
		appointmentVal: "",
	});
	const [notesData, setNotesData] = useState([]);
	const [isedit, setIsEdit] = useState(false);
	const [Test, setTest] = useState(true);
	const [ConversationTest, setConversationTest] = useState(true);

	const [conversation, setConversation] = useState("");
	const [conversationData, setConversationData] = useState([]);
	const [appointment, setAppointment] = useState("");
	const [appointmentData, setAppointmentData] = useState("");
	const [AppID, setAppID] = useState("");
	const [NoteID, setNoteID] = useState("");
	const [selectedTab, setSelectedTab] = useState("Profile");
	const [switchCase, setSwitchCase] = useState(false);
	const [SalesRep, setSalesRep] = useState([]);
	const [SalesRepId, setSalesRepId] = useState("");
	const [dealerId, setDealerId] = useState();
	const [pageTab, setPageTab] = useState("ShowAccess");

	const options = {
		margin: 20,
		loop: true,
		responsiveClass: true,
		nav: false,
		dots: true,
		autoplay: true,
		autoplayTimeout: 2000,
		autoplayHoverPause: true,
		responsive: {
			0: {
				items: 1,
			},
			1200: {
				items: 1,
			},
		},
	};
	const loginDetials = useSelector((state) => state.loginReducer);
	const location = useLocation();
	const dispatch = useDispatch();
	const RetailerDetails = useSelector((state) => state.RetailerReducer);

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
					GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);

					if (location.state) {
						setRetailerId(location.state.id);
						dispatch(setReduxRetailerId(location.state.id));
						setMessageId(location.state.messageId);
						dispatch(setReduxMessageId(location.state.messageId));

						setPageTab(location.state.page);

						setAppointment(location.state.appointmentDefaultMsg);
						dispatch(
							setReduxDefaultMessage(
								location.state.appointmentDefaultMsg
									? location.state.appointmentDefaultMsg
									: ""
							)
						);
						if (location.state.key) {
							if (location.state.key == "Profile" && location.state.id) {
								setSelectedTab("Profile");
								dispatch(setReduxTabName("Profile"));
								handleGetRetailerProfile(
									location.state.id,
									loginDetials.loginDetials.responseData.dealerId
								);
							} else if (
								location.state.key == "Conversation" &&
								location.state.id
							) {
								setSelectedTab("Conversation");
								dispatch(setReduxTabName("Conversation"));
								handleLoadRetailerMsg(
									loginDetials.loginDetials.responseData.dealerId,
									location.state.id,
									location.state.messageId
								);
							} else if (
								location.state.key == "Appointments" &&
								location.state.id
							) {
								setSelectedTab("Appointments");
								dispatch(setReduxTabName("Appointments"));
								handleLoadRetAppData(
									loginDetials.loginDetials.responseData.dealerId,
									location.state.id
								);
								handleLoadRetailerMsg(
									loginDetials.loginDetials.responseData.dealerId,
									location.state.id,
									location.state.messageId
								);
							} else if (location.state.key == "Notes" && location.state.id) {
								setSelectedTab("Notes");
								dispatch(setReduxTabName("Notes"));
								handleLoadDataNotesList(
									loginDetials.loginDetials.responseData.dealerId,
									location.state.id
								);
							}
						}
						if (location.state.key == "Profile") {
						} else {
							handleGetRetailerProfile(
								location.state.id,
								loginDetials.loginDetials.responseData.dealerId
							);
						}

						handleRetailerContactDetail(
							loginDetials.loginDetials.responseData.dealerId,
							location.state.id
						);
						handleGetRetailerCollectionAccess(
							loginDetials.loginDetials.responseData.dealerId,
							location.state.id
						);
					}
					// Redux Value
					else {
						setRetailerId(RetailerDetails.retailerId);
						setMessageId(RetailerDetails.messageId);
						setAppointment(RetailerDetails.appointmentDefaultMsg);
						if (RetailerDetails.tabName) {
							if (
								RetailerDetails.tabName == "Profile" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Profile");
								handleGetRetailerProfile(
									RetailerDetails.retailerId,
									loginDetials.loginDetials.responseData.dealerId
								);
							} else if (
								RetailerDetails.tabName == "Conversation" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Conversation");
								handleLoadRetailerMsg(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId,
									location.state.messageId
								);
							} else if (
								RetailerDetails.tabName == "Appointments" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Appointments");
								handleLoadRetAppData(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId
								);
								handleLoadRetailerMsg(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId,
									location.state.messageId
								);
							} else if (
								RetailerDetails.tabName == "Notes" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Notes");
								handleLoadDataNotesList(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId
								);
							} else if (
								RetailerDetails.tabName == "Details" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Details");
							} else if (
								RetailerDetails.tabName == "Contact" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Contact");
								handleRetailerContactDetail(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId
								);
							} else if (
								RetailerDetails.tabName == "Access" &&
								RetailerDetails.retailerId
							) {
								setSelectedTab("Access");
								handleGetRetailerCollectionAccess(
									loginDetials.loginDetials.responseData.dealerId,
									RetailerDetails.retailerId
								);
							}
						}
						if (RetailerDetails.tabName == "Profile") {
						} else {
							handleGetRetailerProfile(
								RetailerDetails.retailerId,
								loginDetials.loginDetials.responseData.dealerId
							);
						}

						handleRetailerContactDetail(
							loginDetials.loginDetials.responseData.dealerId,
							RetailerDetails.retailerId
						);
						handleGetRetailerCollectionAccess(
							loginDetials.loginDetials.responseData.dealerId,
							RetailerDetails.retailerId
						);
					}
				}
			}
		}
	}, []);

	// const location = useLocation();
	// useEffect(() => {
	//   ;
	//   if (location.state) {
	//     setRetailerId(location.state.id);
	//     if (location.state.key) {
	//       if (location.state.key == "Profile" && location.state.id) {
	//         setSelectedTab("Profile");
	//         handleGetRetailerProfile(location.state.id);
	//       } else if (location.state.key == "Conversation" && location.state.id) {
	//         setSelectedTab("Conversation");
	//         handleLoadRetailerMsg(location.state.id);
	//       } else if (location.state.key == "Appointments" && location.state.id) {
	//         setSelectedTab("Appointments");
	//         handleLoadRetAppData(location.state.id);
	//       } else if (location.state.key == "Notes" && location.state.id) {
	//         setSelectedTab("Notes");
	//         handleLoadDataNotesList(location.state.id);
	//       }
	//     }
	//     handleGetRetailerProfile(location.state.id);
	//     handleRetailerContactDetail(location.state.id);
	//     handleGetRetailerCollectionAccess(location.state.id);
	//   }
	// }, []);

	const handleLoadappoinmentdata = (dealerId) => {
		try {
			// let inputData = {
			//   dealerID: dealerId,
			//   salesRep: "",
			//   tradeShowId: "",
			//   fromDate: "",
			//   toDate: ""
			// }
			let inputData = {
				dealerID: dealerId.toString(),
				salesRep: "",
				tradeShowId: "",
				fromDate: "",
				toDate: "",
			};
			setLoading(true);
			RetailerDataService.Loadappoinmentdata(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						setLoading(false);
						setLoadAppointmentDataList(responseData);
					} else {
						setLoadAppointmentDataList([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const handleUpdateCollection = (saveRow) => {
		const arr = [];
		var newobj = saveRow;
		delete newobj["modifiedDate"];
		arr.push(newobj);
		try {
			// let inputData = {
			//   dealerID: dealerId,
			//   retailerID: retailerId,
			//   isSelected: saveRow.isassigned.toString(),
			//   collectionID: saveRow.collectionid.toString(),
			// };
			let inputData = {
				retailer_ID: retailerId,
				collectionID: arr,
				isSelected: saveRow.isassigned.toString(),
				dealerID: dealerId.toString(),
			};
			setLoading(true);
			RetailerDataService.UpdateCollection(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoading(false);

						handleGetRetailerCollectionAccess(dealerId, retailerId);
						NotificationManager.success(t("Access Updated Successfully"));
					} else {
						setLoading(false);
						NotificationManager.error(t("Access Not Updated Successfully"));
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleValidation = () => {
		const validations = {
			conversationVal: "",
			notesTitleVal: "",
			notesDescriptionVal: "",
			appointmentVal: "",
		};

		let isValid = true;
		if (selectedTab == "Conversation") {
			if (!conversation.trim()) {
				isValid = false;
				validations.retailerIdVal = t("Conversation data is compulsory");
			}
		}

		if (selectedTab == "Notes") {
			if (!notes.title) {
				isValid = false;
				validations.notesTitleVal = t("Note Title is compulsory");
			}
			if (!notes.description) {
				isValid = false;
				validations.notesDescriptionVal = t("Note Description is compulsory");
			}
		}

		if (selectedTab == "Appointments") {
			if (!appointment) {
				isValid = false;
				validations.appointmentVal = t("Appointment data is compulsory");
			}
		}

		if (!isValid) {
			setValidations(validations);
		}

		return isValid;
	};

	const handleGetRetailerCollectionAccess = (dealerId, id) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerID: id.toString(),
			};
			setLoadingAccess(true);
			RetailerDataService.GetRetailerCollectionAccess(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setLoadingAccess(false);
							setAccessData(responseData);
						} else {
							setLoadingAccess(false);
							setAccessData([]);
						}
					} else {
						setLoadingAccess(false);
						setAccessData([]);
					}
				})
				.catch((error) => {
					setLoadingAccess(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingAccess(false);
			console.log(error);
		}
	};

	const handleRetailerContactDetail = (dealerId, id) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerID: id.toString(),
			};
			setLoading(true);
			RetailerDataService.RetailerContactDetail(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setLoadingContact(false);
							setContactData(responseData);
						} else {
							setLoadingContact(false);
							setContactData([]);
						}
					} else {
						setLoadingContact(false);
						setContactData([]);
					}
				})
				.catch((error) => {
					setLoadingContact(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingContact(false);
			console.log(error);
		}
	};

	const handleGetRetailerProfile = (id, dealerId) => {
		try {
			let inputData = {
				retailerID: id,
				dealerId: dealerId,
			};
			setLoadingProfile(true);
			RetailerDataService.GetRetailerProfile(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData[0];
					var imagesData = response.data.responseData[0].images;
					if (message === "Success") {
						if (responseData) {
							setProfileData(responseData);
							setImages(imagesData);
							setLoadingProfile(false);
							GetSalesRepsList(dealerId);
						} else {
							setLoadingProfile(false);
							setProfileData({});
						}
					} else {
						setLoadingProfile(false);
						setProfileData({});
					}
				})
				.catch((error) => {
					setLoadingProfile(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingProfile(false);
			console.log(error);
		}
	};

	const handleSaveNote = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		try {
			let inputData = {
				noteName: notes.title,
				noteContent: notes.description,
				vendorID: dealerId,
				retailerID: retailerId,
				productIds: "",
				fileIds: "",
			};
			setLoadingSaveNotes(true);
			RetailerDataService.AddNewNote(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoadingSaveNotes(false);
						handleLoadDataNotesList(dealerId, retailerId);
						NotificationManager.success(t("Note Created Successfully"));
					} else {
						setLoadingSaveNotes(false);
						NotificationManager.error(t("Note not Created."));
					}
				})
				.catch((error) => {
					setLoadingSaveNotes(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingSaveNotes(false);
			console.log(error);
		}
		setVisible(false);
		setIsEdit(false);
		handleCancel();
	};

	const handleEditSaveNote = () => {
		setTest(false);
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		try {
			let inputData = {
				noteName: notes.title,
				noteDesc: notes.description,
				id: notes.id.toString(),
				productIds: "",
				fileIds: "",
			};
			setLoadingSaveNotes(true);
			RetailerDataService.SaveEditedNote(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoadingSaveNotes(false);
						handleLoadDataNotesList(dealerId, retailerId);
						NotificationManager.success(t("Note Updated Successfully."));
						setTimeout(() => {
							setTest(true);
						}, 3000);
					} else {
						setLoadingSaveNotes(false);
						NotificationManager.error(t("Note not Updated."));
						setTimeout(() => {
							setTest(true);
						}, 3000);
					}
				})
				.catch((error) => {
					setLoadingSaveNotes(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingSaveNotes(false);
			console.log(error);
		}
		setVisible(false);
		setIsEdit(false);
		handleCancel();
	};

	const handleLoadDataNotesList = (dealerId, id) => {
		try {
			let inputData = {
				retailerID: id.toString(),
				dealerID: dealerId.toString(),
			};
			setLoadingNotes(true);
			RetailerDataService.LoadDataNotesList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData.length > 0) {
							setNotesData(responseData);
							setLoadingNotes(false);
						} else {
							setNotesData([]);
							setLoadingNotes(false);
						}
					} else {
						setLoadingNotes(false);
						setNotesData([]);
					}
				})
				.catch((error) => {
					setLoadingNotes(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingNotes(false);
			console.log(error);
		}
	};

	const handleDeleteNote = (id) => {
		try {
			let inputData = {
				id: id,
			};
			setLoading(true);
			RetailerDataService.DeleteNote(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoading(false);
						handleLoadDataNotesList(dealerId, retailerId);
						NotificationManager.success(t("Note Deleted Successfully."));
					} else {
						setLoading(false);
						NotificationManager.error(t("Note not Deleted."));
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleCancel = () => {
		setVisible(false);
		setNotes({
			id: 0,
			title: "",
			description: "",
		});
		setValidations({
			notesTitleVal: "",
			notesDescriptionVal: "",
		});
	};

	const columns = [
		{
			title: t("Date"),
			dataIndex: "appFormatDate",
			width: 200,
		},
		{
			title: t("Time"),
			dataIndex: "appointmentTime",
			width: 200,
		},
		{
			title: t("Buyer Name"),
			dataIndex: "buyerName",
		},
		{
			title: t("Location"),
			dataIndex: "location",
			width: 200,
		},
	];

	const columns1 = [
		{
			title: t("Collection"),
			dataIndex: "collection",
		},
		{
			title: t("Items"),
			dataIndex: "total",
			render: (item, row) => {
				return <span>{row.isassigned == "1" ? row.total : 0}</span>;
			},
		},
		{
			title: t("Active"),
			dataIndex: "total",
			render: (item, row) => {
				return <span>{row.isassigned == "1" ? row.total : 0}</span>;
			},
		},
		{
			title: t("Updated"),
			dataIndex: "modifiedDate",
		},
		{
			title: t("Access"),
			dataIndex: "isassigned",
			width: 150,
			render: (item, row) => {
				return (
					<div className="action__btns">
						<Switch
							name="isassigned"
							checkedChildren={t("On")}
							unCheckedChildren={t("Off")}
							checked={row.isassigned == "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "isassigned")}
						/>
					</div>
				);
			},
		},
	];

	const handleOnChange = (row, e, name) => {
		if (name === "isassigned") {
			const temp = accessData.map((x) => {
				if (x.collectionid === row.collectionid) {
					return { ...x, isassigned: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter(
				(obj) => obj.collectionid == row.collectionid
			)[0];
			setAccessData(temp);

			handleUpdateCollection(saveRow);
		}
	};

	const columns2 = [
		{
			title: t("Avatar"),
			dataIndex: "collectionImage",
			render: (index, row) => (
				<div className="action__btns">
					<div className="image__block">
						<img src={row.collectionImage} />
					</div>
				</div>
			),
		},
		{
			title: t("Name"),
			dataIndex: "dealerName",
		},
		{
			title: t("Job Title"),
			dataIndex: "jobTitle",
		},
		{
			title: t("E-Mail"),
			dataIndex: "dealerEmail",
		},
		{
			title: t("Phone No."),
			dataIndex: "dealerPhone",
			width: 150,
		},
	];

	/*Change tabs starts */

	const handleTabsChange = (e) => {
		setSelectedTab(e);
		dispatch(setReduxTabName(e));
		if (e == "Profile") {
			handleGetRetailerProfile(retailerId, dealerId);
		}
		if (e == "Conversation") {
			handleLoadRetailerMsg(dealerId, retailerId);
		}
		if (e == "Appointments") {
			handleLoadRetAppData(dealerId, retailerId);
			// handleLoadappoinmentdata(dealerId);
		}
		if (e == "Notes") {
			handleLoadDataNotesList(dealerId, retailerId);
		}
		if (e == "Contact") {
			handleRetailerContactDetail(dealerId, retailerId);
		}
		if (e == "Access") {
			handleGetRetailerCollectionAccess(dealerId, retailerId);
		}
	};

	const handleConversationSave = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}

		try {
			let inputData = {
				msgID: messageId,
				vendorID: dealerId,
				retailerID: retailerId,
				msg: conversation,
				productID: "",
				fileID: "",
				rowid: messageId ? messageId : 0,
				//rowid: messageId,
			};
			setLoadingSaveConversation(true);
			RetailerDataService.SendMsg(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoadingSaveConversation(false);
						NotificationManager.success(t("Your Message Has Been Sent"));
						handleLoadRetailerMsg(dealerId, retailerId);
					} else {
						setLoadingSaveConversation(false);
						NotificationManager.error(t("Your Message Has Not Been Sent"));
					}
				})
				.catch((error) => {
					setLoadingSaveConversation(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingSaveConversation(false);
			console.log(error);
		}
		setConversation("");
		setValidations({
			conversationVal: "",
		});
	};

	const handleAppointmentSave = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}

		try {
			// let inputData = {
			//   retailerID: retailerId,
			//   dealerID: dealerId,
			//   appId: "undefind",
			//   productIds: "",
			//   fileIds: "",
			//   noteId: "0",
			//   noteDesc: appointment,
			//   rowid: "/",
			// };
			let inputData = {
				retailerID: retailerId.toString(),
				dealerID: dealerId.toString(),
				appId: AppID ? AppID.toString() : "",
				productIds: "",
				fileIds: "",
				noteId: messageId ? messageId.toString() : "",
				noteDesc: appointment,
				rowid: messageId ? messageId.toString() : "",
			};
			setLoading(true);

			RetailerDataService.SaveUpdateAppointmentNote(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						setLoading(false);
						// handleLoadRetAppData(dealerId, retailerId);
						NotificationManager.success(t("Appointement added Successfully."));
						handleLoadRetAppData(dealerId, retailerId);
						handleLoadRetailerMsg(dealerId, retailerId);
						setAppointment("");
					} else {
						setLoading(false);
						NotificationManager.error(t("Appointement not Added."));
						setAppointment("");
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					setAppointment("");
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
			setAppointment("");
		}
		setAppointment("");
		setValidations({
			appointmentVal: "",
		});
	};

	const handleLoadRetailerMsg = (dealerId, id, messageId) => {
		setConversationTest(false);
		try {
			let inputData = {
				retailerID: id.toString(),
				dealerID: dealerId.toString(),
				productIds: "",
				pageNo: "1",
				recordOnPage: "50",
				rowid: messageId ? messageId.toString() : "0",
			};
			setLoadingConversation(true);
			RetailerDataService.LoadRetailerMsg(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setLoadingConversation(false);
							setConversationData(responseData);
							setTimeout(() => {
								setConversationTest(true);
							}, 3000);
						} else {
							setLoading(false);
							setConversationData([]);
							setTimeout(() => {
								setConversationTest(true);
							}, 3000);
						}
					} else {
						setLoadingConversation(false);
					}
				})
				.catch((error) => {
					setLoadingConversation(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingConversation(false);
			console.log(error);
		}
	};

	const handleLoadRetAppData = (dealerId, id) => {
		try {
			let inputData = {
				retailerID: id.toString(),
				dealerID: dealerId.toString(),
			};
			setLoadingAppointment(true);
			RetailerDataService.LoadRetAppData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData.appointmentList;
					if (message === "Success") {
						if (responseData) {
							setLoadingAppointment(false);
							// setAppointmentData(responseData);
							setLoadAppointmentDataList(responseData);
						} else {
							setLoadingAppointment(false);
							setLoadAppointmentDataList([]);
							// setAppointment([]);
						}
					} else {
						setLoadingAppointment(false);
					}
				})
				.catch((error) => {
					setLoadingAppointment(false);
					console.log(error);
				});
		} catch (error) {
			setLoadingAppointment(false);
			console.log(error);
		}
	};
	const GetAppNoteData = (id) => {
		try {
			let inputData = {
				noteId: id ? id : 0,
			};
			RetailerDataService.GetAppNoteData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					// var noteDesc = response.data.responseData.result[0].noteDesc;
					if (message === "Success") {
						if (responseData.length > 0) {
							// setAppointment(responseData.result[0].noteDesc);
							// setAppointment(
							//   responseData[0].messages.replaceAll(
							//     /(<([^>]+)>|Appointment -|&nbsp;)/gi,
							//     ""
							//   )
							// );
							setAppointment(
								responseData[0]?.messages?.replace("Appointment -", "")
							);

							// handleLoadRetAppData(dealerId, retailerId);
						} else {
							setAppointment("");
						}
					} else {
						setAppointment("");
					}
				})
				.catch((error) => {
					setAppointment("");
					console.log(error);
				});
		} catch (error) {
			setAppointment("");
			console.log(error);
		}
	};
	const handleNotesChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setNotes({
				...notes,
				[name]: e,
			});
		} else {
			setValidations((prevState) => ({
				...prevState,
				notesTitleVal: "",
			}));
			setNotes({
				...notes,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleEditNote = (id) => {
		setIsEdit(true);
		let saveRow = notesData.filter((obj) => obj.id == id)[0];

		setNotes({
			id: saveRow.id,
			title: saveRow.noteName,
			description: saveRow.noteDesc,
		});
		setVisible(true);
	};

	const GetSalesRepsList = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			RetailerDataService.GetSalesRepsList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setSalesRep(responseData);
						} else {
							setSalesRep([]);
						}
					} else {
						setSalesRep([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnSelectChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			// setSalesRepId({
			//   ...SalesRepId,
			//   [name]: e,
			// });
			setProfileData({
				...profileData,
				[name]: e,
			});
			handleSaveSalesReps(e);
		}
	};

	const handleSaveSalesReps = (id) => {
		try {
			let inputData = {
				dealerID: dealerId,
				retailerID: retailerId,
				salesRepId: id,
			};
			RetailerDataService.SaveSalesReps(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Sales Rep Added Successfully"));
						handleGetRetailerProfile(retailerId, dealerId);
					} else {
						NotificationManager.success(t("Sales Rep Not Added"));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const openInNewTab = () => {
		var url = profileData.websiteAddress;
		if (profileData.websiteAddress.includes("https")) {
			window.open(url, "_blank");
		} else if (profileData.websiteAddress.includes("http")) {
			window.open(url, "_blank");
		} else {
			window.open("https://" + url, "_blank");
		}
	};

	// useEffect(() => {
	//   handleNotePrevent();
	// }, []);

	// const handleNotePrevent = (e) => {
	//   var noteId = document.querySelector("#noteID");
	//   if (noteId) {
	//     noteId.enableReadOnlyMode();
	//   }
	// };

	const handlePrevent = () => {
		return false;
	};

	return (
		<React.Fragment>
			<div className="prodetail__section">
				<div className="form__fields">
					<div className="col-md-12">
						<div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
							<div className="row">
								<div className="col-md-12">
									<h4 className="workarea__heading mt-0">
										{" "}
										{t("Retailer Profile")}{" "}
									</h4>
								</div>
								<div className="col-lg-9 col-md-9">
									<div className="prodesc__div">
										<div className="tabs__arrow">
											<button class="left-button disable">
												<i
													class="fa fa-chevron-circle-left"
													aria-hidden="true"></i>
											</button>

											<button class="right-button">
												<i
													class="fa fa-chevron-circle-right"
													aria-hidden="true"></i>
											</button>
										</div>
										<Tabs
											defaultActiveKey="Profile"
											id="uncontrolled-tab-example"
											className="tab__div mb-3"
											onSelect={(e) => {
												handleTabsChange(e);
											}}
											activeKey={selectedTab}>
											<Tab eventKey="Profile" title={t("Profile")}>
												<Spin spinning={loadingprofile}>
													<div className="tab__contentdiv">
														<div className="col-lg-12">
															<div className="desc__maindiv">
																<div className="col-lg-12 p-3">
																	<div className="col-lg-12">
																		<h4 className="workarea__heading mt-0 mb-2">
																			{profileData.dealerCompany
																				? profileData.dealerCompany
																				: null}
																		</h4>
																	</div>
																	<div className="col-lg-12">
																		<p className="m-0">
																			{profileData.dealerPhone
																				? profileData.dealerPhone
																				: null}
																		</p>
																		<p
																			className="linkText"
																			onClick={() => openInNewTab()}>
																			{profileData.websiteAddress
																				? profileData.websiteAddress
																				: null}
																		</p>
																	</div>
																	{/* <div className="col-lg-12 mt-4">
                                    <h4 className="subheading mb-0">Tags</h4>
                                    <p>
                                      Guild Store, American Gem Society, Amber
                                      Jewelry, Buyers Intelligence Group
                                    </p>
                                  </div> */}
																	<div className="col-lg-12 mt-4">
																		<h4 className="subheading mb-0">
																			{" "}
																			{t("Brands")}{" "}
																		</h4>
																		<p>
																			{" "}
																			{profileData.brandNames
																				? profileData.brandNames
																				: null}
																		</p>
																	</div>
																	<div className="col-lg-12 mt-4">
																		<div className="row">
																			<div className="col-md-4">
																				<div className="location__slider">
																					<OwlCarousel
																						className="owl-theme"
																						{...options}>
																						{images &&
																							images.map((item, i) => {
																								return item.images ? (
																									<div className="item" key={i}>
																										<div className="image__block">
																											<img src={item.images} />
																										</div>
																									</div>
																								) : null;
																							})}
																					</OwlCarousel>
																				</div>
																			</div>
																		</div>

																		<p className="subheading mb-0">
																			{profileData.fullAddress ? "1" : "0"}{" "}
																			{t("Locations")}
																		</p>
																		<p
																			className="linkText"
																			onClick={() => setSelectedTab("Details")}>
																			{profileData.location
																				? profileData.location[0]
																				: null}
																		</p>
																	</div>
																	<div className="col-lg-12 mt-4">
																		<p className="subheading mb-0">
																			{" "}
																			{t("Store Bio")}{" "}
																		</p>
																		{profileData.companyAboutUs ? (
																			<div className="editor__ck">
																				<CKEditor
																					editor={ClassicEditor}
																					data={
																						profileData?.companyAboutUs || ""
																					}
																					config={{
																						toolbar: [],
																					}}
																					onChange={(e, editor) => {
																						e.preventDefault();
																					}}
																				/>
																			</div>
																		) : null}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Spin>
											</Tab>
											<Tab eventKey="Details" title={t("Details")}>
												<div className="tab__contentdiv">
													<div className="col-lg-12">
														<div className="desc__maindiv">
															<div className="col-lg-12 p-3">
																<div className="col-lg-12">
																	<h4 className="workarea__heading mt-0 mb-0">
																		{profileData.dealerCompany
																			? profileData.dealerCompany
																			: null}
																	</h4>
																</div>
																<div className="col-lg-12 mt-4">
																	<div className="row">
																		<div className="col-md-4">
																			<div className="location__slider">
																				<OwlCarousel
																					className="owl-theme"
																					{...options}>
																					{images &&
																						images.map((item, i) => {
																							return item.images ? (
																								<div className="item" key={i}>
																									<div className="image__block">
																										<img src={item.images} />
																									</div>
																								</div>
																							) : null;
																						})}
																				</OwlCarousel>
																			</div>
																		</div>
																	</div>
																	<p className="subheading mb-0">
																		{profileData.fullAddress ? "1" : "0"}{" "}
																		{t("Locations")}
																	</p>
																	<p
																		className="linkText"
																		onClick={() => setSelectedTab("Details")}>
																		{profileData.fullAddress
																			? profileData.fullAddress
																			: null}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="Conversation" title={t("Conversation")}>
												<div className="tab__contentdiv">
													<div className="desc__maindiv">
														<div className="col-lg-12 p-3">
															<div className="col-lg-12 mt-2">
																<div>
																	{validations.conversationVal && (
																		<p className="error-color-red">
																			{validations.conversationVal}
																		</p>
																	)}
																</div>
																<div className="editor__section">
																	<CKEditor
																		editor={ClassicEditor}
																		data={conversation ? conversation : ""}
																		// config={editorConfiguration}
																		config={{
																			toolbar: {
																				items: [
																					"bold",
																					"italic",
																					"link",
																					"|",
																					"fontColor",
																				],
																			},
																		}}
																		onChange={(event, editor) => {
																			const data = editor.getData();
																			setConversation(data);
																		}}
																	/>
																</div>
															</div>
															<div className="col-lg-12 mt-4">
																<Button
																	className="primary-btn"
																	onClick={handleConversationSave}
																	loading={loadingSaveConversation}>
																	{t("Send")}
																</Button>
															</div>
															<Spin spinning={loadingConversation}>
																<div className="col-lg-12">
																	{conversationData.map((item, key) => {
																		return (
																			<div
																				className="notedesc__maindiv"
																				key={item.id}>
																				<div className="date__headdiv">
																					<div>
																						<span>
																							{item.msgTitle.replace(
																								/(<([^>]+)>)/gi,
																								""
																							)}
																							<b>
																								{" @"}
																								{item.sendFrom}
																							</b>
																						</span>
																					</div>
																				</div>
																				<div className="editor__ck">
																					{item?.message && (
																						<CKEditor
																							editor={ClassicEditor}
																							data={item?.message || ""}
																							config={{
																								toolbar: [],
																							}}
																							onChange={(e, editor) => {
																								if (ConversationTest == true) {
																									e.preventDefault();
																								}
																							}}
																						/>
																					)}
																				</div>
																			</div>
																		);
																	})}
																</div>
															</Spin>
														</div>
													</div>
												</div>
											</Tab>
											<Tab eventKey="Notes" title={t("Notes")}>
												<div className="tab__contentdiv">
													<div className="desc__maindiv">
														<Spin spinning={loadingNotes}>
															<div className="col-lg-12 p-3">
																<div className="col-lg-6 mt-2">
																	<div className="note__headdiv">
																		<span className="note__txt">
																			{notesData.length} {t("Notes")}
																		</span>
																		<span className="line__txt">|</span>
																		<span
																			className="createnote__txt"
																			onClick={setVisible}>
																			{t("Create A New Note")}
																		</span>
																	</div>
																</div>

																<div className="col-lg-12 mt-2">
																	{notesData.length > 0 ? (
																		notesData &&
																		notesData.map((item, key) => {
																			return (
																				<div className="notedesc__maindiv">
																					<div className="date__headdiv">
																						<div>
																							<span>
																								{item.noteName} -{" "}
																								{moment(
																									item.createdDate
																								).format("dddd,MMMM D")}
																							</span>
																						</div>
																						<div>
																							<span
																								className="edit__txt"
																								onClick={() =>
																									handleEditNote(item.id)
																								}>
																								{t("Edit")}
																							</span>
																							<span className="line__txt">
																								|
																							</span>
																							<span
																								className="del__txt"
																								onClick={() =>
																									handleDeleteNote(item.id)
																								}>
																								{t("Delete")}
																							</span>
																						</div>
																					</div>
																					<div className="editor__ck">
																						<label className="mt-3 ml-2">
																							{(item?.noteDesc &&
																								parse(item?.noteDesc)) ||
																								""}
																						</label>

																						{/* <CKEditor
                                              editor={ClassicEditor}
                                              data={item?.noteDesc || ""}
                                              config={{
                                                toolbar: [],
                                                contenteditable: false,
                                              }}
                                              onChange={(e, editor) => {
                                                if (Test == true) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            /> */}
																					</div>
																				</div>
																			);
																		})
																	) : (
																		<span>{t("No notes added")}</span>
																	)}
																</div>
															</div>
														</Spin>
													</div>
												</div>
											</Tab>
											<Tab eventKey="Appointments" title={t("Appointments")}>
												<div className="tab__contentdiv">
													<div className="desc__maindiv">
														<div className="col-lg-12 p-3">
															<div className="col-lg-12 col-md-12">
																<Spin spinning={loadingAppointment}>
																	<Table
																		className="appointment__tbl"
																		// columns={columns}
																		// dataSource={appointmentData}
																		onRow={(record) => {
																			return {
																				onClick: () => {
																					setAppID(record.id);
																					setMessageId(record.messageId);
																					GetAppNoteData(record.messageId);
																				},
																			};
																		}}
																		columns={[
																			{
																				title: t("Date"),
																				dataIndex: "appFormatDate",
																				width: 200,
																				render: (row, item) => {
																					return (
																						<span>
																							{moment(
																								item.appFormatDate
																							).format("MM/DD/YYYY ")}
																						</span>
																					);
																				},
																			},
																			{
																				title: t("Time"),
																				dataIndex: "appointmentTime",
																				width: 200,
																			},
																			{
																				title: t("Buyer Name"),
																				dataIndex: "buyerName",
																			},
																			{
																				title: t("Location"),
																				dataIndex: "location",
																				width: 200,
																			},
																		]}
																		dataSource={loadAppointmentDataList}
																		// dataSource={selectedTab == "Appointments" ? appointmentData : loadAppointmentDataList}
																		scroll={{ x: 600, y: 800 }}
																	/>
																</Spin>
															</div>
															<div className="col-lg-12 mt-4">
																<div>
																	{validations.appointmentVal && (
																		<p className="error-color-red">
																			{validations.appointmentVal}
																		</p>
																	)}
																</div>
																<div className="editor__section">
																	<CKEditor
																		editor={ClassicEditor}
																		// data={appointment ? appointment : defaultAppointmentTxt}
																		data={
																			appointment
																				? appointment
																				: appointment == ""
																				? ""
																				: location?.state
																				? location?.state?.appointmentDefaultMsg
																				: ""
																		}
																		// config={editorConfiguration}
																		onReady={(editor) => {}}
																		onChange={(event, editor) => {
																			const data = editor.getData();
																			setAppointment(data ? data : "");
																		}}
																	/>
																</div>
																<div className="col-lg-12 mt-4">
																	<Button
																		className="primary-btn"
																		onClick={handleAppointmentSave}>
																		{t("Save")}
																	</Button>
																</div>
															</div>
														</div>
														{/* <Spin spinning={loadingConversation}>
                              <div className="col-lg-12">
                                {conversationData.map((item, key) => {
                                  return (
                                    <div
                                      className="notedesc__maindiv"
                                      key={item.id}
                                    >
                                      <div className="date__headdiv">
                                        <div>
                                          <span>
                                            {item.msgTitle.replace(
                                              /<[^>]+>/g,
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="editor__ck">
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={
                                            item.message ? item.message : ""
                                          }
                                          config={{
                                            toolbar: [],
                                          }}
                                          onChange={(e, editor) => {
                                            if (ConversationTest == true) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                      </div>
                                      
                                    </div>
                                  );
                                })}
                              </div>
                            </Spin> */}
													</div>
												</div>
											</Tab>

											<Tab
												eventKey="Access"
												tabClassName={
													pageTab == "DonotshowAccess" ||
													loginDetials.loginDetials.responseData
														.dealerMemberTypeName == "VendorDiamond"
														? "tabdisplaynone"
														: ""
												}
												title={t("Access")}>
												<div className="tab__contentdiv">
													<div className="desc__maindiv">
														<div className="col-lg-12 p-3">
															<div className="col-lg-12 col-md-12">
																<Spin spinning={loadingAccess}>
																	<Table
																		className="appointment__tbl"
																		columns={columns1}
																		dataSource={accessData}
																		scroll={{ x: 600, y: 800 }}
																		pagination={{
																			showSizeChanger: true,
																			responsive: true,
																		}}
																	/>
																</Spin>
															</div>
														</div>
													</div>
												</div>
											</Tab>

											<Tab eventKey="Contact" title={t("Contacts")}>
												<div className="tab__contentdiv">
													<div className="desc__maindiv">
														<div className="col-lg-12 p-3">
															<div className="col-lg-12 col-md-12">
																<Spin spinning={loadingContact}>
																	<Table
																		className="contact__tbl"
																		columns={columns2}
																		dataSource={contactData}
																		scroll={{ x: 600, y: 800 }}
																	/>
																</Spin>
															</div>
														</div>
													</div>
												</div>
											</Tab>
										</Tabs>
									</div>
								</div>
								<div className="col-lg-3 col-md-3">
									<Spin spinning={loadingprofile}>
										<div className="right__section">
											<div className="profile__block">
												<span href="#" className="retailer__name">
													{profileData.dealerCompany
														? profileData.dealerCompany
														: null}
												</span>
												<Link
													to={"/retailerProfile"}
													state={{
														key: "Profile",
														id: retailerId,
													}}>
													<div className="logoImg__block p-0">
														<img src={profileData.companylogo} />
													</div>
												</Link>
												<p className="activeFrom">
													{t("Active")} |{" "}
													{profileData.activity ? profileData.activity : null}
												</p>
											</div>
											<div className="links__block">
												<ul>
													<li
														onClick={() => setSelectedTab("Profile")}
														className="linkText">
														<i
															class="fa fa-address-book-o"
															aria-hidden="true"></i>{" "}
														{t("View Profile")}
													</li>
													<li
														onClick={() => setSelectedTab("Conversation")}
														className="linkText">
														<i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
														{t("Conversation")}
													</li>
													<li>
														<Link
															to={"/makeAppoitments"}
															state={{
																key: "Appointments",
																id: retailerId,
															}}>
															<i
																class="fa fa-calendar-check-o"
																aria-hidden="true"></i>{" "}
															{t("Make Appointment")}
														</Link>
													</li>
													<li
														onClick={() => setSelectedTab("Notes")}
														className="linkText">
														<i
															class="fa fa-pencil-square-o"
															aria-hidden="true"></i>{" "}
														{t("Store Notes")}
													</li>
												</ul>
												<hr />
											</div>

											<div className="bottom__block">
												<div className="input__block">
													<label> {t("Sales Rep")} </label>
													<Select
														placeholder={t("Select Sales Rep")}
														// value={SalesRepId.userId ? SalesRepId.userId : null}
														value={profileData?.salesUserId || ""}
														name="userId"
														showSearch
														optionFilterProp="children"
														onChange={(e) =>
															handleOnSelectChange(e, "select", "salesUserId")
														}
														filterOption={(input, option) =>
															option.children
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														}>
														<Option value="">Sales Rep</Option>
														{SalesRep.map((item) => {
															return (
																<Option value={item.userId}>
																	{item.name}{" "}
																</Option>
															);
														})}
													</Select>
												</div>
											</div>
											<div className="retailer__dtls mb-4">
												<p>
													{profileData.dealerName
														? profileData.dealerName
														: null}
												</p>
												<a href={`mailto:${profileData.dealerEmail}`}>
													{profileData.dealerEmail
														? profileData.dealerEmail
														: null}
												</a>
												<p className="mt-2">
													{profileData.dealerPhone
														? profileData.dealerPhone
														: null}
												</p>

												{/* <span
                          onClick={() =>
                            window.open(
                              profileData.websiteAddress.toString(),
                              "_blank"
                            )
                          }
                        >
                          {" "}
                          {profileData.websiteAddress}
                        </span> */}

												{/* <a
                          style={{ display: "table-cell" }}
                          href={profileData.websiteAddress}
                          target="_blank"
                        >
                          {profileData.websiteAddress}
                        </a> */}
												{/* <Link
                          target="_blank"
                          to={`//${profileData.websiteAddress}`}
                        >
                          {" "}
                          {profileData.websiteAddress
                            ? profileData.websiteAddress
                            : null}
                        </Link> */}
												<p className="linkText" onClick={() => openInNewTab()}>
													{profileData.websiteAddress
														? profileData.websiteAddress
														: null}
												</p>

												<hr />
											</div>
										</div>
									</Spin>
								</div>
								<div className="col-lg-12">
									<Modal
										title={isedit ? t("Edit Note") : t("Create A New Note")}
										className="modalretailerprof__section"
										centered
										visible={visible}
										onOk={handleCancel}
										onCancel={handleCancel}
										footer={[
											<Button key="back" onClick={handleCancel}>
												{t("Cancel")}
											</Button>,
											<Button
												loading={loadingSaveNotes}
												key="submit"
												type="primary"
												onClick={isedit ? handleEditSaveNote : handleSaveNote}>
												{/* {isedit ? t("Submit") : t("Submit")} */}
												{t("Save")}
											</Button>,
										]}>
										<div className="col-lg-12">
											<div className="modal__body">
												<div className="col-lg-12">
													<div className="form__fields border-0 p-0">
														<div className="row">
															<div className="col-lg-12">
																<div className="input__block">
																	<input
																		type="text"
																		name="title"
																		placeholder={t("Note Name")}
																		value={notes.title}
																		onChange={handleNotesChange}
																	/>
																	<div>
																		{validations.notesTitleVal && (
																			<p className="error-color-red">
																				{validations.notesTitleVal}
																			</p>
																		)}
																	</div>
																</div>
																<div className="editor__section">
																	<CKEditor
																		editor={ClassicEditor}
																		data={notes?.description || ""}
																		config={{
																			toolbar: {
																				items: [
																					"bold",
																					"italic",
																					"link",
																					"|",
																					"fontColor",
																				],
																			},
																			placeholder: t("Note Description..."),
																		}}
																		onChange={(event, editor) => {
																			const data = editor.getData();
																			setNotes((prevState) => ({
																				...prevState,
																				description: data,
																			}));
																			setValidations((prevState) => ({
																				...prevState,
																				notesDescriptionVal: "",
																			}));
																		}}
																	/>
																</div>
																<div>
																	{validations.notesDescriptionVal && (
																		<p className="error-color-red">
																			{validations.notesDescriptionVal}
																		</p>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default RetailerProfile;
