import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Spin,
} from "antd";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

const ManageLanguage = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetSelectlanguageForDelar(loginDetials.responseData.dealerId);
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
    handleGetSeleCtlanguageDropdowen();
  }, []);
  const { Option } = Select;

  const [LanguageSelect, setLanguageSelect] = useState({
    Language: null,
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerId, setDealerId] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const [saveLaoding, setSaveLoading] = useState(false);

  const handleSubmit = async (e) => {};

  const handleCancel = async (e) => {
    setDisableBtn(true);
    setLanguageSelect({
      Language: null,
    });
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);

    if (isSelect === "select") {
      setLanguageSelect({
        ...LanguageSelect,
        [name]: e,
      });
    } else {
      setLanguageSelect({
        ...LanguageSelect,
        [e.target.name]: e.target.value,
      });
    }
  };

  function onSearch(val) {}
  const handleLanguageUpdate = () => {
    handleUpdateLanguage(loginDetials.responseData.dealerId);
  };
  /*API binding starts*/
  const [seleCtlanguageDropdowenList, setSeleCtlanguageDropdowenList] =
    useState([]);
  const [selectlanguageForDelarList, setSelectlanguageForDelarList] = useState(
    {}
  );
  const handleGetSeleCtlanguageDropdowen = () => {
    try {
      AdminManageLanguageService.GetSeleCtlanguageDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSeleCtlanguageDropdowenList(responseData);
            } else {
              setSeleCtlanguageDropdowenList([]);
            }
          } else {
            setSeleCtlanguageDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetSelectlanguageForDelar = (id) => {
    try {
      let inputData = {
        delarId: id,
      };
      setGetLoading(true);
      AdminManageLanguageService.GetSelectlanguageForDelar(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setGetLoading(false);
              setSelectlanguageForDelarList(responseData);
              setDisableBtn(true);
            } else {
              setGetLoading(false);
              setSelectlanguageForDelarList({});
            }
          } else {
            setGetLoading(false);
            setSelectlanguageForDelarList({});
          }
        })
        .catch((error) => {
          setGetLoading(false);
          console.log(error);
        });
    } catch (error) {
      setGetLoading(false);
      console.log(error);
    }
  };
  const handleUpdateLanguage = (id) => {
    try {
      let inputData = {
        delarId: id,
        languageId: LanguageSelect.Language,
      };
      setSaveLoading(true);
      AdminManageLanguageService.UpdateLanguage(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSaveLoading(false);
              NotificationManager.success(t("Language Updated Successfully"));
              handleGetSelectlanguageForDelar(dealerId);
            } else {
              setSaveLoading(false);
              NotificationManager.error(t("Language Update failed"));
            }
          } else {
            setSaveLoading(false);
            NotificationManager.error(t("Language Update failed"));
          }
        })
        .catch((error) => {
          setSaveLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="col-md-12">
          <Spin spinning={getLoading}>
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {t("Language Selection for Platform and Tools")}
                </h4>
                <p class="mt-3">
                  {t("Default Language Is")}{" "}
                  {selectlanguageForDelarList.language}
                </p>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-6 mt-0">
                        <div className="input__block mb-3">
                          <label> {t("Select Language")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Choose Any")}
                            name="Language"
                            value={
                              LanguageSelect.Language
                                ? LanguageSelect.Language
                                : []
                            }
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleOnChange(e, "select", "Language")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option Value="">
                              {" "}
                              {t("Select Language")}{" "}
                            </Option>
                            {seleCtlanguageDropdowenList.map((item) => {
                              return (
                                <React.Fragment>
                                  <Option value={item.languageId}>
                                    {item.language}
                                  </Option>
                                </React.Fragment>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <Button
                      loading={saveLaoding}
                      className={
                        disableBtn == true
                          ? "disabled-btn me-4"
                          : "primary-btn me-4"
                      }
                      onClick={handleLanguageUpdate}
                      disabled={disableBtn == true ? true : false}
                    >
                      {t("Apply")}
                    </Button>
                    <button
                      className={disableBtn ? "disabled-btn" : "primary-btn"}
                      onClick={handleCancel}
                      disabled={disableBtn == true ? true : false}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageLanguage;
