import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Radio,
  Tooltip,
  Space,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import UserSettingsService from "../../services/user-settings.service";

const { Option } = Select;

export const SocialIntegrations = (props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dealerID, setDealerID] = useState(0);
  const [socialIntegrationData, setSocialIntegrationData] = useState({
    WebsiteAppId: "",
    MasterLinkAppId: "",
    MasterLinkAppUrl: "",
    RingBuilderAppId: "",
    RingBuilderAppUrl: "",
    StudBuilderAppId: "",
    StudBuilderAppUrl: "",
    PendantBuilderAppId: "",
    PendantBuilderAppUrl: "",
    DiamondLinkAppId: "",
    DiamondLinkAppUrl: "",
  });
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      //
      setDealerID(loginDetials.responseData.dealerId);
      //   handleGetCompanyProfileSettingDetails(loginDetials.responseData.dealerId);
      handleGetCompanyProfileSettingDetails(loginDetials.responseData.dealerId);
    }
  }, []);

  const handleOnChange = (e) => {
    setSocialIntegrationData({
      ...socialIntegrationData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveSocialIntegration = (name) => {
    try {
      const formData = new FormData();
      formData.append("DealerId", dealerID);
      formData.append("WebsiteAppId", socialIntegrationData.WebsiteAppId);
      formData.append("MasterLinkAppId", socialIntegrationData.MasterLinkAppId);
      formData.append(
        "MasterLinkAppUrl",
        socialIntegrationData.MasterLinkAppUrl
      );
      formData.append(
        "RingBuilderAppId",
        socialIntegrationData.RingBuilderAppId
      );
      formData.append(
        "RingBuilderAppUrl",
        socialIntegrationData.RingBuilderAppUrl
      );
      formData.append(
        "StudBuilderAppId",
        socialIntegrationData.StudBuilderAppId
      );
      formData.append(
        "StudBuilderAppUrl",
        socialIntegrationData.StudBuilderAppUrl
      );
      formData.append(
        "PendantBuilderAppId",
        socialIntegrationData.PendantBuilderAppId
      );
      formData.append(
        "PendantBuilderAppUrl",
        socialIntegrationData.PendantBuilderAppUrl
      );
      formData.append(
        "DiamondLinkAppId",
        socialIntegrationData.DiamondLinkAppId
      );
      formData.append(
        "DiamondLinkAppUrl",
        socialIntegrationData.DiamondLinkAppUrl
      );

      UserSettingsService.UpdateSocialIntegration(formData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (responseData.status == true) {
            NotificationManager.success(t("Record Updated Successfully."));
          } else {
            NotificationManager.error(t("Record Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      // }
    }
    // }
  };

  const handleGetCompanyProfileSettingDetails = (dealerId) => {
    try {
      //   setLoading(true);

      const formData = new FormData();
      formData.append("DealerId", dealerId);

      UserSettingsService.GetSocialIntegrationSetting(formData)
        .then((response) => {
          //   setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          //   var companyProfileSettingDetailsList = responseData;
          if (message === "Success") {
            if (responseData) {
              // setCompanyProfileSettingDetailsList(companyProfileSettingDetailsList);
              setSocialIntegrationData({
                WebsiteAppId: responseData[0].websiteAppId,
                MasterLinkAppId: responseData[0].masterLinkCanvasAppId,
                MasterLinkAppUrl: responseData[0].masterLinkCanvasAppUrl,
                RingBuilderAppId: responseData[0].ringBuilderCanvasAppID,
                RingBuilderAppUrl: responseData[0].ringBuilderCanvasAppUrl,
                StudBuilderAppId: responseData[0].studBuilderCanvasAppID,
                StudBuilderAppUrl: responseData[0].studBuilderCanvasAppUrl,
                PendantBuilderAppId: responseData[0].pendantBuilderCanvasAppID,
                PendantBuilderAppUrl:
                  responseData[0].pendantBuilderCanvasAppUrl,
                DiamondLinkAppId: responseData[0].diamondLinkCanvasAppID,
                DiamondLinkAppUrl: responseData[0].diamondLinlkCanvasAppUrl,
              });
            } else {
              setSocialIntegrationData({
                WebsiteAppId: "",
                MasterLinkAppId: "",
                MasterLinkAppUrl: "",
                RingBuilderAppId: "",
                RingBuilderAppUrl: "",
                StudBuilderAppId: "",
                StudBuilderAppUrl: "",
                PendantBuilderAppId: "",
                PendantBuilderAppUrl: "",
                DiamondLinkAppId: "",
                DiamondLinkAppUrl: "",
              });
            }
          } else {
            setSocialIntegrationData({
              WebsiteAppId: "",
              MasterLinkAppId: "",
              MasterLinkAppUrl: "",
              RingBuilderAppId: "",
              RingBuilderAppUrl: "",
              StudBuilderAppId: "",
              StudBuilderAppUrl: "",
              PendantBuilderAppId: "",
              PendantBuilderAppUrl: "",
              DiamondLinkAppId: "",
              DiamondLinkAppUrl: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          //   setLoading(false);
        });
    } catch (error) {
      console.log(error);
      //   setLoading(false);
    }
  };

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-6">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-3">
              {" "}
              {t("Social Integrations Settings")}{" "}
            </h4>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_button">
            <Button
              onClick={handleSaveSocialIntegration}
              // loading={btnloading}
              className="primary-btn"
            >
              {t("Update")}
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <label>
                      {" "}
                      {t("Website's Facebook App ID Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>{t("Facebook App ID")}</label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="WebsiteAppId"
                          value={socialIntegrationData.WebsiteAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <label for="h1">
                      {" "}
                      {t("Masterlink Facebook App Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("MasterLink Facebook canvas App ID")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="MasterLinkAppId"
                          value={socialIntegrationData.MasterLinkAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("MasterLink Facebook canvas App URL")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="MasterLinkAppUrl"
                          value={socialIntegrationData.MasterLinkAppUrl}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div>
                    <label>
                      {" "}
                      {t("RingBuilder Facebook App Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("RingBuilder Facebook canvas App ID")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="RingBuilderAppId"
                          value={socialIntegrationData.RingBuilderAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("RingBuilder Facebook canvas App URL")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="RingBuilderAppUrl"
                          value={socialIntegrationData.RingBuilderAppUrl}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div>
                    <label>
                      {" "}
                      {t("StudBuilder Facebook App Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("StudBuilder Facebook canvas App ID")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="StudBuilderAppId"
                          value={socialIntegrationData.StudBuilderAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("StudBuilder Facebook canvas App URL")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="StudBuilderAppUrl"
                          value={socialIntegrationData.StudBuilderAppUrl}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div>
                    <label>
                      {" "}
                      {t("PendantBuilder Facebook App Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("PendantBuilder Facebook canvas App ID")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="PendantBuilderAppId"
                          value={socialIntegrationData.PendantBuilderAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("PendantBuilder Facebook canvas App URL")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="PendantBuilderAppUrl"
                          value={socialIntegrationData.PendantBuilderAppUrl}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <div>
                    <label>
                      {" "}
                      {t("Diamondlink Facebook App Setup")}{" "}
                    </label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("DiamondLink Facebook canvas App ID")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="DiamondLinkAppId"
                          value={socialIntegrationData.DiamondLinkAppId}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {t("DiamondLink Facebook canvas App URL")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <input
                          type="text"
                          name="DiamondLinkAppUrl"
                          value={socialIntegrationData.DiamondLinkAppUrl}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SocialIntegrations);
