import React, { useState, useRef, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Select,
  Button,
  Table,
  Modal,
  Slider,
  Empty,
  Input,
  Space,
  Spin,
  Checkbox,
} from "antd";

import Roundimg from "../../../assets/images/Round_65x65.jpg";
import RadiantImg from "../../../assets/images/Radiant.jpg";
import PrincessImg from "../../../assets/images/Princess.jpg";
import PearImg from "../../../assets/images/Pear.jpg";
import OvalImg from "../../../assets/images/Oval.jpg";
import MarquiseImg from "../../../assets/images/Marquise.jpg";
import HeartImg from "../../../assets/images/Heart.jpg";
import EmeraldImg from "../../../assets/images/emerald.jpg";
import CushionImg from "../../../assets/images/Cushion.jpg";
import AsscherImg from "../../../assets/images/Asscher.jpg";
import Blue from "../../../assets/images/Blue.png";
import Pink from "../../../assets/images/Pink.png";
import Yellow from "../../../assets/images/Yellow.png";
import Brown from "../../../assets/images/Brown.png";
import Green from "../../../assets/images/Green.png";
import Gray from "../../../assets/images/Gray.png";
import Black from "../../../assets/images/Black.png";
import Red from "../../../assets/images/Red.png";
import Purple from "../../../assets/images/Purple.png";
import Chameleon from "../../../assets/images/Chameleon.png";
import Violet from "../../../assets/images/Violet.png";
import Filter_icon from "../../../assets/images/filter_icon.png";
import space__icon from "../../../assets/images/space__icon.png";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import logoImg from "../../../assets/images/JewelCloud_Logo_Black.jpg";
import footerlogoImg from "../../../assets/images/GemFind-footer.jpg";
import videoIcon from "../../../assets/images/video_icon.png";
import DealerProfileService from "../../../services/dealer-profile.service";
import {
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../../actions/headerMenu/headerMenu";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";

const initialViewMyColorData = {
  colors: [],
  colorIsSelected: {
    Blue: false,
    Pink: false,
    Yellow: false,
    Brown: false,
    Green: false,
    Gray: false,
    Black: false,
    Red: false,
    Purple: false,
    Chameleon: false,
    Violet: false,
    All: false,
  },
  Shapes: [],
  ShapeIsSelected: {
    Round: false,
    Radiant: false,
    Princess: false,
    Pear: false,
    Oval: false,
    Marquise: false,
    Heart: false,
    Emerald: false,
    Cushion: false,
    Asscher: false,
    All: false,
  },
  permission: null,
  minCarat: 0,
  maxCarat: 20,
  fancyIntensity: null,
  overtone1: [],
  overtone2: [],
  clarityFrom: null,
  clarityTo: null,
  polishTo: null,
  polishFrom: null,
  symmetryFrom: null,
  symmetryTo: null,
  cutFrom: null,
  cutTo: null,
  minOverallPrice: 0,
  maxOverallPrice: 100000,
  minPriceperCaretRange: 0,
  maxPriceperCaretRange: 100000,
  minLWRatioMeasurements: 0,
  maxLWRatioMeasurements: 10,
  location: null,
  eyeClean: [],
  fluorescenceIntensityFrom: null,
  fluorescenceIntensityTo: null,
  fluorescenceColor: [],
  dealers: [],
  certificates: [],
  lengthFrom: null,
  lengthTo: null,
  widthFrom: null,
  widthTo: null,
  heightFrom: null,
  heightTo: null,

  Radio: "",
  shade: [],
  centralInclusionRange: [],
  blackInclusionRange: [],
  milkyInclusionRange: [],
  girdleRangeFrom: null,
  girdleRangeTo: null,
  crownAngleRangeFrom: 0,
  crownAngleRangeTo: 100,
  pavilionAngleRangeFrom: 0,
  pavilionAngleRangeTo: 100,
  keytoSymbols: [],
  culetRangeFrom: null,
  culetRangeTo: null,
  culetConditionRangeFrom: null,
  culetConditionRangeTo: null,
  treatment: [],
  depthFrom: 0,
  depthTo: 100,
  tableFrom: 0,
  tableTo: 100,
  matchedPairs: [],
  hasVideo: "",
};

const ViewMyColorDiamond = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  /*API INITIAL DATA STARTS*/
  let printTable = useRef();
  const [loading, setLoading] = useState(false);
  const [fancyDiamondList, setFancyDiamondList] = useState([]);
  const [
    permissionsForDiamondCatalogList,
    setPermissionsForDiamondCatalogList,
  ] = useState([]);
  const [dealersForDiamondCatalogList, setDealersForDiamondCatalogList] =
    useState([]);
  const [
    certificatesForDiamondCatalogList,
    setCertificatesForDiamondCatalogList,
  ] = useState([]);
  const [locationsForDiamondCatalogList, setLocationsForDiamondCatalogList] =
    useState([]);
  const [overToneForDiamondCatalogList, setOverToneForDiamondCatalogList] =
    useState([]);
  const [fancyIntensityList, setFancyIntensityList] = useState([]);
  const [diamondPolishList, setDiamondPolishList] = useState([]);
  const [diamondSymmetryList, setDiamondSymmetryList] = useState([]);
  const [diamondCutGradeList, setDiamondCutGradeList] = useState([]);
  const [diamondClarityList, setClarityList] = useState([]);
  const [diamondFluorescenceColorList, setDiamondFluorescenceColorList] =
    useState([]);
  /*API INITIAL DATA ENDS*/
  const { Option } = Select;
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [viewRow, setViewRow] = useState({});
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [filterBtnVisble, setFilterBtnVisble] = useState(true);
  const [sumbitLoding, setSumbitLoding] = useState(false);
  const [emailCertificat, setEmailCertificat] = useState({});
  const [errorEmailCertificat, setErrorEmailCertificat] = useState({});
  const [caratSlideValue, setCaratSlideValue] = useState([0, 20]);
  const [overallPriceValue, setOverallPriceValue] = useState([0, 100000]);
  const [pricePerCaratValue, setPricePerCaratValue] = useState([0, 100000]);
  const [lWRatioMeasurementsValue, setLRatioMeasurementsValue] = useState([
    0, 10,
  ]);
  const [tableResetKey, setTableResetKey] = useState(false);
  const [ViewMyColorData, setViewMyColorData] = useState(
    initialViewMyColorData
  );
  const [msgData, setMsgData] = useState("");
  const [msgLoading, setMsgLoading] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [isVideo, setIsVideo] = useState(false);
  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);

  const [isEmail, setIsEmail] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sendDetails, setSendDetails] = useState({});
  const [errorSendDetails, setErrorSendDetails] = useState({});
  const [EmailLoading, setEmailLoading] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [showSelected, setShowSelected] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumnName, setSortColumnName] = useState("tmpColumn Asc");
  const [sortType, setSortType] = useState("asc");
  const [filter, setFilter] = useState("");
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [visible5, setVisible5] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [companyLogoLoading, setcompanyLogoLoading] = useState(false);
  const [showMoreLess, setShowMoreLess] = useState(false);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency?.substring(currency?.lastIndexOf("-") + 1);
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  const [bordered, setBordered] = useState(true);
  const [validation, setValidation] = useState({
    depthFrom: "",
    depthTo: "",
    tableFrom: "",
    tableTo: "",
  });
  // Certificate changes
  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });
  const [certInfoDataValidation, setcertInfoDataValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVAl: "",
    commentsVal: "",
  });
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  /*API BINDING STARTS*/
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState(0);
  //Table Rows Checkbox Logic
  const [selectedRows, setSelectedRows] = useState([]);
  const [currencySign, setCurrencySign] = useState("$");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleSendFriendemail = () => {
    let inputData = {
      name: emailCertificat.Name,
      email: emailCertificat.Email,
      friendsName: emailCertificat.FriendsName,
      friendsEmail: emailCertificat.FriendsEmail,
      comments: emailCertificat.Comments,
      dealerID: parseInt(dealerId),
      dInventoryID: parseInt(selectedRowDetails.intDiamondId),
    };
    setEmailLoading(true);
    CatalogDiamondDataService.SendFriendemail(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          setVisible3(false);
          NotificationManager.success(t("Email has been sent successfully."));
        } else {
          NotificationManager.error(t("Email has not been sent."));
        }
      })
      .catch((error) => {
        setEmailLoading(false);
        console.log(error);
      })
      .finally(() => {
        setEmailLoading(false);
      });
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };

  const handleOpenIsEmail = () => {
    setIsEmail(true);
  };
  const handleCloseIsEmail = () => {
    setIsEmail(false);
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };
  const handleEmailCertificatChange = (e) => {
    const { name, value } = e.target;
    emailCertificat[name] = value;
    errorEmailCertificat[name] = null;
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...errorEmailCertificat,
    }));
  };
  const handleValidEmailCertificat = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let isValid = true;
    let tempEmailCertificat = emailCertificat;
    let tempErrorEmailCertificat = errorEmailCertificat;
    if (!tempEmailCertificat["Name"]) {
      tempErrorEmailCertificat["Name"] = t("Enter Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["Name"] = null;
    }

    if (!tempEmailCertificat["Email"]) {
      tempErrorEmailCertificat["Email"] = t("Enter Email Address.");
      isValid = false;
    } else if (!tempEmailCertificat["Email"].trim()) {
      tempErrorEmailCertificat["Email"] = t("Enter Email Address.");
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["Email"] = t("Email is not valid.");
      isValid = false;
    }

    if (!tempEmailCertificat["FriendsName"]) {
      tempErrorEmailCertificat["FriendsName"] = t("Enter Friend's Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["FriendsName"] = null;
    }

    if (!tempEmailCertificat["FriendsEmail"]) {
      tempErrorEmailCertificat["FriendsEmail"] = t(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (!tempEmailCertificat["FriendsEmail"].trim()) {
      tempErrorEmailCertificat["FriendsEmail"] = t(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["FriendsEmail"] = t("Email is not valid.");
      isValid = false;
    }

    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...tempErrorEmailCertificat,
    }));
    return isValid;
  };

  const handleCertificateEmailSend = () => {
    if (handleValidEmailCertificat()) {
      handleSendFriendemail();
    }
  };

  const handleSendMsg = () => {
    let inputData = {
      dealrId: selectedRowDetails.dealerID,
      msg: msgData,
      intDiamondId: selectedRowDetails.intDiamondId.toString(),
    };
    setMsgLoading(true);
    CatalogDiamondDataService.SendMsg(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          setVisible1(false);
          NotificationManager.success(t("Mail sent successfully."));
        } else {
          NotificationManager.error(t("Mail not sent."));
        }
      })
      .catch((error) => {
        setMsgLoading(false);
        console.log(error);
      })
      .finally(() => {
        setMsgLoading(false);
      });
  };

  const handleMsgValidation = () => {
    if (msgData) {
      if (msgData.trim() === "") {
        setMsgError(t("Please enter message."));
      } else {
        handleSendMsg();
      }
    } else {
      setMsgError(t("Please enter message."));
    }
  };

  const handleCancel1 = () => {
    setVisible1(false);
    setMsgData("");
    setMsgError("");
    setSelectedDealer("");
  };
  const handleOk1 = (item) => {
    setSelectedRowDetails(item);
    handleGetCompanyProfileDetails(item.dealerID);
    setMsgData("");
    setMsgError("");
    setVisible1(true);
  };

  const handleGetCompanyProfileDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setcompanyLogoLoading(true);
    DealerProfileService.GetCompanyProfileDetails(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setSelectedDealer(responseData.dealerCompany);
          }
        } else {
          setSelectedDealer("");
        }
      })
      .catch((error) => {
        setcompanyLogoLoading(false);
        console.log(error);
      })
      .finally(() => {
        setcompanyLogoLoading(false);
      });
  };

  const showModalFullscreen = (row) => {
    setSelectedRowDetails(row);
    setIsModalVisibleFullscreen(true);
  };
  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };
  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
    let videoElement = document.getElementById("product_video");
    if (videoElement) {
      videoElement.pause();
    }
  };

  const handleOnChangeRadio = (event) => {
    setViewMyColorData({
      ...ViewMyColorData,
      Radio: event.target.name,
    });
  };

  const handleOnChangeRadioHasVideo = (event) => {
    setViewMyColorData({
      ...ViewMyColorData,
      hasVideo: event.target.name,
    });
    setFilterBtnVisble(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setViewMyColorData({
        ...ViewMyColorData,
        [name]: e,
      });
    } else {
      setViewMyColorData({
        ...ViewMyColorData,
        [e.target.name]: e.target.value,
      });
    }
    setFilterBtnVisble(false);
  };

  const onNumberChange = (e, name) => {
    let tempState = { ...ViewMyColorData };
    tempState[name] = e.target.value;

    let error = "Incorrect Range";

    if (e.target.value > 100) return;

    if (
      name == "depthFrom" ||
      name == "depthTo" ||
      name == "tableFrom" ||
      name == "tableTo"
    ) {
      if (e.target.value > 100 || e.target.value < 0) {
        setValidation((prevState) => ({
          ...prevState,
          [name]: error,
        }));
      } else {
        setValidation((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
    }

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setFilterBtnVisble(false);
  };

  const handleViewModal = (row) => {
    setViewRow(row);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancel2 = () => {
    setVisible2(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      if (fancyDiamondList.length === selectedRows.length) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        handleGetDealersForDiamondCatalogList(
          loginDetials.responseData.dealerId
        );
        setDealerMemberTypeName(loginDetials.responseData.dealerMemberTypeName);
      }
    }
  }, []);

  useEffect(() => {
    handleGetPermissionsForDiamondCatalogList();
    handleGetCertificatesForDiamondCatalogList();
    handleGetOverToneForDiamondCatalogList();
    handleGetLocationsForDiamondCatalogList();
    handleGetAllFancyDropDownList();
  }, []);

  useEffect(() => {
    const currentPageDiamondIds = fancyDiamondList
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .map((diamond) => diamond.intDiamondId);

    if (currentPageDiamondIds.every((id) => checkBoxValue.includes(id))) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [checkBoxValue, fancyDiamondList, currentPage, pageSize]);

  const handleDeleteDiamondByIds = () => {
    let inputData = {
      dealerId: dealerId,
      diamondIds: isSelectAll
        ? "All"
        : selectedRows.map((x) => x.intDiamondId).join(","),
    };
    setSumbitLoding(true);
    CatalogDiamondDataService.DeleteDiamondByIds(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData[0].message == "Not Authorize") {
            setVisible5(true);
          } else {
            NotificationManager.success(t("Records Deleted Successfully."));
            handleVisible6();
            setTableResetKey(!tableResetKey);
            handleGetFancyDiamondList(dealerId);
            setSelectedRows([]);
            setSelectedRowKeys([]);
            setCheckBoxValue([]);
          }
        } else {
          NotificationManager.error(t("Records Not Deleted."));
        }
      })
      .catch((error) => {
        setSumbitLoding(false);
        console.log(error);
      })
      .finally(() => {
        setSumbitLoding(false);
      });
  };

  const handleSendInviteMailForDiamonds = () => {
    var mapping = [];
    for (var i = 0; i < selectedRows.length; i++) {
      var obj = {};

      obj["isLabCreated"] = true;
      obj["inventoryOnly"] = true;
      obj["publicViewing"] = true;
      obj["outonMemo"] = "";
      obj["sold"] = true;
      obj["inventoryRegion"] = "";
      obj["dealerID"] = selectedRows[i].dealerID
        ? parseInt(selectedRows[i].dealerID)
        : 0;
      obj["DInventoryID"] = selectedRows[i].intDiamondId;
      obj["intDiamondId"] = selectedRows[i].intDiamondId;
      obj["comments"] = selectedRows[i].comments;
      obj["dealerInventoryNo"] = selectedRows[i].dealerInventoryNo;
      obj["employeeName"] = "";
      obj["employeeID"] = "";
      obj["cut"] = selectedRows[i].txtShape ? selectedRows[i].txtShape : "";
      obj["measurements"] = selectedRows[i].txtMeasurements;
      obj["parcel"] = "";
      obj["tableMeasure"] = selectedRows[i].fltTable
        ? selectedRows[i].fltTable
        : 0;
      obj["depth"] = selectedRows[i].fltDepth ? selectedRows[i].fltDepth : 0;
      obj["color"] = selectedRows[i].txtFancyColorMainBody;
      obj["clarityID"] =
        selectedRows[i].intClarityPriority == "None"
          ? 0
          : parseInt(selectedRows[i].intClarityPriority);
      obj["size"] = selectedRows[i].fltCarat ? selectedRows[i].fltCarat : 0;
      obj["polish"] = selectedRows[i].txtPolish;
      obj["symmetry"] = selectedRows[i].txtSymmetry;
      obj["flouresence"] = selectedRows[i].txtFluorescence;
      obj["girdle"] = selectedRows[i].txtgridle;
      obj["crown"] = selectedRows[i].fltCrown;
      obj["culet"] = selectedRows[i].txtCulet;
      obj["pavillion"] = selectedRows[i].txtPavillion;
      obj["certificateNo"] = selectedRows[i].certificateNo;
      obj["stones"] = selectedRows[i].stones
        ? parseInt(selectedRows[i].stones)
        : 0;
      obj["costperCarat"] = selectedRows[i].fltPrice
        ? selectedRows[i].fltPrice
        : 0;
      obj["sellingPrice"] = selectedRows[i].fltPrice
        ? selectedRows[i].fltPrice
        : 0;
      obj["certificate"] = selectedRows[i].txtCertificate;
      obj["pairSeparable"] = "";
      obj["pairStockNumber"] = selectedRows[i].txtStockNo;
      obj["cutGrade"] = selectedRows[i].txtCutGrade;
      obj["culetCondition"] = "";
      obj["crownPercentage"] = "";
      obj["pavilionPercentage"] = "";
      obj["isActive"] = "";
      obj["city"] = "";
      obj["state"] = "";
      obj["country"] = "";
      mapping.push(obj);
    }
    setEmailSendLoading(true);
    let inputData = {
      dealerId: dealerId,
      diamondDetailList: isSelectAll ? "All" : mapping,
      name: sendDetails.Name,
      eMail: sendDetails.Email,
      markUp: Number(sendDetails.Markup),
      message: sendDetails.Message,
      mailType: "",
    };
    CatalogDiamondDataService.SendInviteMailForDiamonds(inputData)
      .then((response) => {
        let message = response.data.message;
        setSumbitLoding(false);
        if (message === "Success") {
          NotificationManager.success(t("Email Send Successfully."));
          handleCancel4();
          handleResetAfterEmailSend();
        } else {
          NotificationManager.error(t("Send Email Error."));
          handleCancel4();
          handleResetAfterEmailSend();
        }
      })
      .catch((error) => {
        console.log(error);
        setEmailSendLoading(false);
      })
      .finally(() => {
        setEmailSendLoading(false);
      });
  };

  const handleGetFancyDiamondList = (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortType,
    filter,
    dealerIds,
    reset
  ) => {
    setLoading(true);

    let strSelectedDealers = [];
    if (dealerIds) {
      for (let i = 0; i < dealerIds.length; i++) {
        strSelectedDealers.push(dealerIds[i].dealerID);
      }
    }

    let inputData = {
      dealerID: id,
      shapes: reset ? "" : shapesData === "," ? "" : shapesData,
      colorMin: "-1",
      colorMax: "-1",
      clarityMin: reset
        ? "-1"
        : ViewMyColorData.clarityFrom == null &&
          ViewMyColorData.clarityTo == null
        ? "-1"
        : ViewMyColorData.clarityFrom == null
        ? ViewMyColorData.clarityTo.toString()
        : ViewMyColorData.clarityFrom.toString(),

      clarityMax: reset
        ? "-1"
        : ViewMyColorData.clarityFrom == null &&
          ViewMyColorData.clarityTo == null
        ? "-1"
        : ViewMyColorData.clarityTo == null
        ? ViewMyColorData.clarityFrom.toString()
        : ViewMyColorData.clarityTo.toString(),
      caratMin: reset ? "0" : caratSlideValue[0].toString(),
      caratMax: reset ? "20" : caratSlideValue[1].toString(),
      priceMin: reset ? "0" : overallPriceValue[0].toString(),
      priceMax: reset ? "100000" : overallPriceValue[1].toString(),
      certificate: reset ? "" : ViewMyColorData.certificates.toString(),
      cutGradeMin: reset
        ? "-1"
        : ViewMyColorData.cutFrom == null && ViewMyColorData.cutTo == null
        ? "-1"
        : ViewMyColorData.cutFrom == null
        ? ViewMyColorData.cutTo.toString()
        : ViewMyColorData.cutFrom.toString(),

      cutGradeMax: reset
        ? "-1"
        : ViewMyColorData.cutFrom == null && ViewMyColorData.cutTo == null
        ? "-1"
        : ViewMyColorData.cutTo == null
        ? ViewMyColorData.cutFrom.toString()
        : ViewMyColorData.cutTo.toString(),

      symmetryMin: reset
        ? "-1"
        : ViewMyColorData.symmetryFrom == null &&
          ViewMyColorData.symmetryTo == null
        ? "-1"
        : ViewMyColorData.symmetryFrom == null
        ? ViewMyColorData.symmetryTo.toString()
        : ViewMyColorData.symmetryFrom.toString(),
      symmetryMax: reset
        ? "-1"
        : ViewMyColorData.symmetryFrom == null &&
          ViewMyColorData.symmetryTo == null
        ? "-1"
        : ViewMyColorData.symmetryTo == null
        ? ViewMyColorData.symmetryFrom.toString()
        : ViewMyColorData.symmetryTo.toString(),

      polishMin: reset
        ? "-1"
        : ViewMyColorData.polishFrom == null && ViewMyColorData.polishTo == null
        ? "-1"
        : ViewMyColorData.polishFrom == null
        ? ViewMyColorData.polishTo.toString()
        : ViewMyColorData.polishFrom.toString(),

      polishMax: reset
        ? "-1"
        : ViewMyColorData.polishFrom == null && ViewMyColorData.polishTo == null
        ? "-1"
        : ViewMyColorData.polishTo == null
        ? ViewMyColorData.polishFrom.toString()
        : ViewMyColorData.polishTo.toString(),

      depthMin: reset
        ? "0"
        : ViewMyColorData.depthFrom
        ? ViewMyColorData.depthFrom.toString()
        : "-1",
      depthMax: reset
        ? "100"
        : ViewMyColorData.depthTo
        ? ViewMyColorData.depthTo.toString()
        : "-1",
      tableMin: reset
        ? "0"
        : ViewMyColorData.tableFrom
        ? ViewMyColorData.tableFrom.toString()
        : "-1",
      tableMax: reset
        ? "100"
        : ViewMyColorData.tableTo
        ? ViewMyColorData.tableTo.toString()
        : "-1",
      fluorescenceMin: reset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom === null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom.toString(),
      fluorescenceMax: reset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityTo === null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityTo.toString(),

      fluorescenceMin: reset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom == null &&
          ViewMyColorData.fluorescenceIntensityTo == null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom == null
        ? ViewMyColorData.fluorescenceIntensityTo.toString()
        : ViewMyColorData.fluorescenceIntensityFrom.toString(),

      fluorescenceMax: reset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom == null &&
          ViewMyColorData.fluorescenceIntensityTo == null
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityTo == null
        ? ViewMyColorData.fluorescenceIntensityFrom.toString()
        : ViewMyColorData.fluorescenceIntensityTo.toString(),
      pairCode: "",
      orderBy:
        sortColumnName == "txtStockNo"
          ? "DealerInventoryNo"
          : sortColumnName == "txtCertificate"
          ? "Certificate"
          : sortColumnName == "txtSymmetry"
          ? "Symmetry"
          : sortColumnName == "txtShape"
          ? "Cut"
          : sortColumnName == "fltCarat"
          ? "Size"
          : sortColumnName == "txtFancyColorMainBody"
          ? "Color"
          : sortColumnName == "txtClarity"
          ? "Clarity"
          : sortColumnName == "txtPolish"
          ? "Polish"
          : sortColumnName == "fltPrice"
          ? "FltPrice"
          : sortColumnName == "fltDepth"
          ? "Depth"
          : sortColumnName == "fltTable"
          ? "TableMeasure"
          : sortColumnName == "location"
          ? "Country"
          : sortColumnName == "txtFluorescence"
          ? ""
          : sortColumnName == "txtMeasurements"
          ? "Measurements"
          : sortColumnName == "seller"
          ? "DealerID"
          : sortColumnName == "fltRapPercent"
          ? "OffRapaport"
          : "tmpColumn Asc",
      orderType: sortType ? sortType : "ASC",
      pageNumber: pageNo ? pageNo.toString() : "1",
      pageSize: pageSize ? pageSize.toString() : "10",
      invenID: "",
      strDetailLinkURL: "dealerlink",
      did: "",
      caratPriceMin: reset
        ? "0"
        : pricePerCaratValue[0]
        ? pricePerCaratValue[0].toString()
        : "0",
      caratPriceMax: reset
        ? "100000"
        : pricePerCaratValue[1]
        ? pricePerCaratValue[1].toString()
        : "0",
      ratioMin: reset ? "0" : lWRatioMeasurementsValue[0].toString(),
      ratioMax: reset ? "10" : lWRatioMeasurementsValue[1].toString(),
      polishList: reset
        ? "-1"
        : ViewMyColorData.polishFrom == null && ViewMyColorData.polishTo == null
        ? "-1"
        : "",
      symmetryList: reset
        ? "-1"
        : ViewMyColorData.symmetryFrom == null &&
          ViewMyColorData.symmetryTo == null
        ? "-1"
        : "",
      fluorescenceList: reset
        ? "-1"
        : ViewMyColorData.fluorescenceIntensityFrom == null &&
          ViewMyColorData.fluorescenceIntensityTo == null
        ? "-1"
        : "",
      cutGradeList: reset
        ? "-1"
        : ViewMyColorData.cutFrom == null && ViewMyColorData.cutTo == null
        ? "-1"
        : "",
      colorList: "-1",
      clarityList:
        ViewMyColorData.clarityFrom == null && ViewMyColorData.clarityTo == null
          ? "-1"
          : "",
      sOrigin: "",
      intOptimize: 0,
      intCulet1: reset
        ? "0"
        : ViewMyColorData.culetRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.culetRangeFrom),
      intCulet2: reset
        ? "20"
        : ViewMyColorData.culetRangeTo === null
        ? 20
        : parseInt(ViewMyColorData.culetRangeTo),
      intCuletCondition1: reset
        ? "0"
        : ViewMyColorData.culetConditionRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.culetConditionRangeFrom),
      intCuletCondition2: reset
        ? "20"
        : ViewMyColorData.culetConditionRangeTo === null
        ? 20
        : parseInt(ViewMyColorData.culetConditionRangeTo),
      fltCrownAngle1: reset
        ? "0"
        : ViewMyColorData.crownAngleRangeFrom === ""
        ? 0
        : parseInt(ViewMyColorData.crownAngleRangeFrom),
      fltCrownAngle2: reset
        ? "100"
        : ViewMyColorData.crownAngleRangeTo === ""
        ? 100
        : parseInt(ViewMyColorData.crownAngleRangeTo),
      fltPavilionAngle1: reset
        ? "0"
        : ViewMyColorData.pavilionAngleRangeFrom === ""
        ? 0
        : parseInt(ViewMyColorData.pavilionAngleRangeFrom),
      fltPavilionAngle2: reset
        ? "100"
        : ViewMyColorData.pavilionAngleRangeTo === ""
        ? 100
        : parseInt(ViewMyColorData.pavilionAngleRangeTo),
      intGirdle1: reset
        ? "0"
        : ViewMyColorData.girdleRangeFrom === null
        ? 0
        : parseInt(ViewMyColorData.girdleRangeFrom),
      intGirdle2: reset
        ? "100"
        : ViewMyColorData.girdleRangeTo === null
        ? 100
        : parseInt(ViewMyColorData.girdleRangeTo),
      intCutGrade: 0,
      uploadDateMin: "",
      uploadDateMax: "",
      strSelectedDealers:
        ViewMyColorData.dealers.length > 0
          ? ViewMyColorData.dealers.map((x) => x).join(",")
          : strSelectedDealers + "",
      selectedDealerConunt: 100,
      fancyColor: reset ? "" : colorData === "," ? "" : colorData,
      intIntensity: reset
        ? "-1"
        : ViewMyColorData.fancyIntensity === null
        ? -1
        : parseInt(ViewMyColorData.fancyIntensity),
      overtone: reset ? "" : ViewMyColorData.overtone1.toString(),
      overtone2: reset ? "" : ViewMyColorData.overtone2.toString(),
      strMatchedPairs: ViewMyColorData.matchedPairs.toString(),
      fltWidth1: reset
        ? "-1"
        : ViewMyColorData.widthFrom === null
        ? "-1"
        : ViewMyColorData.widthFrom.toString(),
      fltWidth2: reset
        ? "-1"
        : ViewMyColorData.widthTo === null
        ? "-1"
        : ViewMyColorData.widthTo.toString(),
      fltHeight1: reset
        ? "-1"
        : ViewMyColorData.heightFrom === null
        ? "-1"
        : ViewMyColorData.heightFrom.toString(),
      fltHeight2: reset
        ? "-1"
        : ViewMyColorData.heightTo === null
        ? "-1"
        : ViewMyColorData.heightTo.toString(),
      fltLength1: reset
        ? "-1"
        : ViewMyColorData.lengthFrom === null
        ? "-1"
        : ViewMyColorData.lengthFrom.toString(),
      fltLength2: reset
        ? "-1"
        : ViewMyColorData.lengthTo === null
        ? "-1"
        : ViewMyColorData.lengthTo.toString(),
      strSelectedInvID: "",
      flagDD: "0",
      checkInventory: "Catalog",
      country: reset
        ? ""
        : ViewMyColorData.location === null
        ? ""
        : ViewMyColorData.location.toString(),
      shade: ViewMyColorData.shade.toString(),
      treatment: reset ? "" : ViewMyColorData.treatment.toString(),
      keytosymbol: ViewMyColorData.keytoSymbols.toString(),
      fluorescenceColor: reset
        ? ""
        : ViewMyColorData.fluorescenceColor.toString(),
      blackInclusion: ViewMyColorData.blackInclusionRange.toString(),
      centralInclusion: ViewMyColorData.centralInclusionRange.toString(),
      milkyInclusion: ViewMyColorData.milkyInclusionRange.toString(),
      eyeclean: reset ? "" : ViewMyColorData.eyeClean.toString(),
      ratioType: reset ? "" : ViewMyColorData.Radio.toString(),
      permission: reset
        ? ""
        : ViewMyColorData.permission === null
        ? ""
        : ViewMyColorData.permission.toString(),
      video: reset
        ? ""
        : ViewMyColorData.hasVideo === ""
        ? ""
        : ViewMyColorData.hasVideo === "true"
        ? "1"
        : "0",
      strDInventoryId: filter ? filter : "",
    };

    CatalogDiamondDataService.GetFancyDiamondList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setFancyDiamondList(responseData);
            setTotalCount(Number(responseData[0].intTotalRecords));
            setFilter("");
          } else {
            setFancyDiamondList([]);
            setTotalCount(0);
          }
        } else {
          setFancyDiamondList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setTotalCount(0);
        setFancyDiamondList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetPermissionsForDiamondCatalogList = () => {
    let inputData = {
      type: "",
    };
    CatalogDiamondDataService.GetPermissionsForDiamondCatalogList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setPermissionsForDiamondCatalogList(responseData);
          } else {
            setPermissionsForDiamondCatalogList([]);
          }
        } else {
          setPermissionsForDiamondCatalogList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetDealersForDiamondCatalogList = (id) => {
    let inputData = {
      type: "Fancy",
      dealerId: id,
    };
    setLoading(true);
    CatalogDiamondDataService.GetDealersForDiamondCatalogList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setCurrencySign(responseData?.currencySign || "$");
            setDealersForDiamondCatalogList(responseData);
            handleGetFancyDiamondList(
              loginDetials.responseData.dealerId,
              pageNo,
              pageSize,
              "tmpColumn Asc",
              "Asc",
              filter,
              responseData
            );
          } else {
            setDealersForDiamondCatalogList([]);
            handleGetFancyDiamondList(
              loginDetials.responseData.dealerId,
              pageNo,
              pageSize,
              "tmpColumn Asc",
              "Asc",
              filter,
              ""
            );
          }
        } else {
          setDealersForDiamondCatalogList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetCertificatesForDiamondCatalogList = () => {
    let inputData = {
      type: "Fancy",
    };
    CatalogDiamondDataService.GetCertificatesForDiamondCatalogList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setCertificatesForDiamondCatalogList(
            responseData ? responseData : []
          );
        } else {
          setCertificatesForDiamondCatalogList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetOverToneForDiamondCatalogList = () => {
    let inputData = {
      type: "Fancy",
    };
    CatalogDiamondDataService.GetOverToneForDiamondCatalogList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setOverToneForDiamondCatalogList(responseData);
          } else {
            setOverToneForDiamondCatalogList([]);
          }
        } else {
          setOverToneForDiamondCatalogList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetAllFancyDropDownList = () => {
    CatalogDiamondDataService.GetAllFancyDropDownList()
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        let fancyIntensity = response.data.responseData.diamondIntensity;
        let diamondPolish = response.data.responseData.diamondPolish;
        let diamondSymmetry = response.data.responseData.diamondSymmetry;
        let diamondCutGrade = response.data.responseData.diamondCutGrade;
        let clarity = response.data.responseData.clarity;
        let diamondFluorescenceColor =
          response.data.responseData.diamondFluorescenceColor;
        if (message === "Success") {
          if (responseData) {
            setFancyIntensityList(fancyIntensity);
            setDiamondPolishList(diamondPolish);
            setDiamondSymmetryList(diamondSymmetry);
            setDiamondCutGradeList(diamondCutGrade);
            setClarityList(clarity);
            setDiamondFluorescenceColorList(diamondFluorescenceColor);
          } else {
            setFancyIntensityList([]);
            setDiamondPolishList([]);
          }
        } else {
          setFancyIntensityList([]);
          setDiamondPolishList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationsForDiamondCatalogList = () => {
    let inputData = {
      type: "",
    };
    CatalogDiamondDataService.GetLocationsForDiamondCatalogList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setLocationsForDiamondCatalogList(responseData);
          } else {
            setLocationsForDiamondCatalogList([]);
          }
        } else {
          setLocationsForDiamondCatalogList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*API BINDING ENDS*/

  //Apply Filters
  const applyFilters = () => {
    setHideShowFilter(true);
    handleGetFancyDiamondList(
      dealerId,
      1, // pageNo,
      pageSize, // pageSize,
      sortColumnName,
      sortType,
      filter,
      []
    );
    setPageNo(1);
    setTableResetKey(!tableResetKey);
  };

  //Reset Filters
  const resetFilters = () => {
    setTableResetKey(!tableResetKey);
    setViewMyColorData(initialViewMyColorData);
    setCaratSlideValue([ViewMyColorData.minCarat, ViewMyColorData.maxCarat]);
    setOverallPriceValue([
      ViewMyColorData.minOverallPrice,
      ViewMyColorData.maxOverallPrice,
    ]);
    setPricePerCaratValue([
      ViewMyColorData.minPriceperCaretRange,
      ViewMyColorData.maxPriceperCaretRange,
    ]);
    setLRatioMeasurementsValue([
      ViewMyColorData.minLWRatioMeasurements,
      ViewMyColorData.maxLWRatioMeasurements,
    ]);
    let tempState = initialViewMyColorData;
    initialViewMyColorData.ShapeIsSelected = {
      Round: false,
      Radiant: false,
      Princess: false,
      Pear: false,
      Oval: false,
      Marquise: false,
      Heart: false,
      Emerald: false,
      Cushion: false,
      Asscher: false,
      All: false,
    };
    initialViewMyColorData.colors = [];
    initialViewMyColorData.Shapes = [];
    setViewMyColorData((prevState) => ({
      ...prevState,
      ...tempState,
      colorIsSelected: {
        Blue: false,
        Pink: false,
        Yellow: false,
        Brown: false,
        Green: false,
        Gray: false,
        Black: false,
        Red: false,
        Purple: false,
        Chameleon: false,
        Violet: false,
        All: false,
      },
    }));
    setValidation({
      depthFrom: "",
      depthTo: "",
      tableFrom: "",
      tableTo: "",
    });
    setFilterBtnVisble(false);
    handleGetFancyDiamondList(
      dealerId,
      pageNo,
      pageSize,
      "tmpColumn Asc",
      "Asc",
      filter,
      [],
      true
    );
  };

  const handleResetAfterEmailSend = () => {
    setTableResetKey(!tableResetKey);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setCheckBoxValue([]);
  };

  const handleReset = () => {
    setTableResetKey(!tableResetKey);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setCheckBoxValue([]);
    setPageNo(1);
    setPageSize(10);
    resetFilters();
    setSortColumnName("tmpColumn Asc");
    setSortType("Asc");
    setShowSelected(false);
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };
  /*Routing On Table Buttons Starts*/

  const handleCreateRedirect = () => {
    let MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["addSingleDiamond"]));
    navigate("/addSingleDiamond");
  };

  const handleUploadRedirect = () => {
    let MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["uploadDiamondFile"]));
    navigate("/uploadDiamondFile");
  };

  /*Routing On Table Buttons Ends*/

  /*Select Box Starts*/
  const onShapeClick = (shape) => {
    if (shape === "All") {
      ViewMyColorData.ShapeIsSelected.All =
        !ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Round =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Radiant =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Princess =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Pear =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Oval =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Marquise =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Heart =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Emerald =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Cushion =
        ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Asscher =
        ViewMyColorData.ShapeIsSelected.All;
    } else {
      ViewMyColorData.ShapeIsSelected.All = false;
      ViewMyColorData.ShapeIsSelected[shape] =
        !ViewMyColorData.ShapeIsSelected[shape];
    }

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
    setFilterBtnVisble(false);
  };
  const onColorClick = (color) => {
    if (color === "All") {
      ViewMyColorData.colorIsSelected.All =
        !ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Blue =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Pink =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Yellow =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Brown =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Green =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Gray =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Black =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Red = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Purple =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Chameleon =
        ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Violet =
        ViewMyColorData.colorIsSelected.All;
    } else {
      ViewMyColorData.colorIsSelected.All = false;
      ViewMyColorData.colorIsSelected[color] =
        !ViewMyColorData.colorIsSelected[color];
    }
    setFilterBtnVisble(false);

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
  };
  /*Converting into String Format starts*/
  let shapesObj = ViewMyColorData.ShapeIsSelected;
  let shapesKeys = Object.keys(shapesObj);
  let filteredShape = shapesKeys.filter(function (key) {
    return shapesObj[key];
  });
  let shapesData = filteredShape + ",";

  let colorObj = ViewMyColorData.colorIsSelected;
  let colorKeys = Object.keys(colorObj);
  let filteredColor = colorKeys.filter(function (key) {
    return colorObj[key];
  });
  let colorData = filteredColor + ",";

  const handleSendModal = () => {
    if (selectedRows.length > 0 || isSelectAll) {
      setVisible4(true);
    } else {
      NotificationManager.error(
        t("Please select diamonds from list to send via email.")
      );
    }
  };

  const handleValidateSendDetails = () => {
    let isValid = true;
    let tempSendDetails = sendDetails;
    let tempErrorSendDetails = errorSendDetails;

    if (!tempSendDetails.Name) {
      tempErrorSendDetails["Name"] = t("Please Enter Name.");
      isValid = false;
    } else {
      tempErrorSendDetails["Name"] = "";
    }
    if (!tempSendDetails.Email) {
      tempErrorSendDetails["Email"] = t("Please enter email.");
      isValid = false;
    } else {
      tempErrorSendDetails["Email"] = "";
    }
    if (!tempSendDetails.Markup) {
      tempErrorSendDetails["Markup"] = t("Please Enter Markup.");
      isValid = false;
    } else {
      tempErrorSendDetails["Markup"] = "";
    }
    if (!tempSendDetails.Message) {
      tempErrorSendDetails["Message"] = t("Please Enter Message.");
      isValid = false;
    } else {
      tempErrorSendDetails["Message"] = "";
    }
    setErrorSendDetails({ ...tempErrorSendDetails });
    return isValid;
  };
  const handleSendDetailsChange = (e) => {
    const { name, value } = e.target;
    let tempSendDetails = sendDetails;
    let tempErrorSendDetails = errorSendDetails;
    if (name == "Markup") {
      if (!isNaN(value) && value) {
        tempSendDetails[name] = value;
      }
    } else {
      tempSendDetails[name] = value;
    }
    tempErrorSendDetails[name] = "";
    setSendDetails({ ...tempSendDetails });
    setErrorSendDetails({ ...tempErrorSendDetails });
  };
  const handleSendDetailsModal = () => {
    if (handleValidateSendDetails()) {
      handleSendInviteMailForDiamonds();
    }
  };
  const handleCancel4 = () => {
    setVisible4(false);
    let tempSendDetails = {};
    setSendDetails(tempSendDetails);
    let tempErrorSendDetails = {};
    setErrorSendDetails(tempErrorSendDetails);
  };
  const handleDeleteModal = () => {
    if (selectedRows.length > 0) {
      setDeleteModal(true);
    } else {
      NotificationManager.error(
        t("Please select diamonds from list to delete.")
      );
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  // API Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let filter = "";
    let obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "tmpColumn Asc";
      setSortColumnName(column);
      setSortType(type);
    }

    if (filters.txtStockNo) {
      obj = {
        columnName: "txtStockNo",
        columnValue: filters.txtStockNo[0],
        columnType: "Input",
      };
      filter = obj.columnValue;
    } else {
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    if (showSelected) return;
    handleGetFancyDiamondList(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      filter,
      []
    );
  };

  // For Search

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={t("Search..")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("Search")}
            </Button>
            <Button
              onClick={() => handleResetSearch(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {t("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleResetSearch(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }
  function handlePrintDiamondList() {
    let disp_setting =
      "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    let docprint = window.open("", "", disp_setting);
    docprint.document.open();
    let htmlContent = document.getElementById("printsection").innerHTML;

    htmlContent +=
      '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    htmlContent +=
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

    docprint.document.write(htmlContent);
    docprint.document.close();

    setTimeout(() => {
      docprint.print();
    }, 500);
    docprint.focus();
  }

  const handleDeleteConfirmation = () => {
    setDeleteModal(false);
    handleDeleteDiamondByIds();
  };

  const handleCancel5 = () => {
    setVisible5(false);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstsetCaratSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...caratSlideValue];
    let StrValue = e.target.value?.replace("$", "");
    let Value = StrValue.replace(/,/g, "");
    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      ViewMyColorData.maxCarat
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          let dottedamount = Value.split(".");
          let amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[0] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          let dottedamount = Value.split(".");
          let amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[1] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    }
  };

  function formatMoney(amount) {
    try {
      if (amount?.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  const firstsetOverAllSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...overallPriceValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");
    if (Value.replace(/,/g, "") > ViewMyColorData.maxOverallPrice) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setOverallPriceValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setOverallPriceValue(newArr);
        } else {
          newArr[0] = Value;
          setOverallPriceValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setOverallPriceValue(newArr);
      } else {
        if (newArr[1] == 0) {
          newArr[1] = Value;
          setOverallPriceValue(newArr);
        } else {
          newArr[1] = Value;
          setOverallPriceValue(newArr);
        }
      }
    }
  };

  const firstsetPricePerCaratValueSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...pricePerCaratValue];

    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");

    if (
      Number(parseFloat(Value.replace(/,/g, ""))) >
      ViewMyColorData.maxPriceperCaretRange
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPricePerCaratValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPricePerCaratValue(newArr);
        } else {
          newArr[0] = Value;
          setPricePerCaratValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPricePerCaratValue(newArr);
      } else {
        if (newArr[1] == 0) {
          newArr[1] = Value;
          setPricePerCaratValue(newArr);
        } else {
          newArr[1] = Value;
          setPricePerCaratValue(newArr);
        }
      }
    }
  };

  const firstsetlWRatioMeasurementsValueSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...lWRatioMeasurementsValue];

    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      ViewMyColorData.maxLWRatioMeasurements
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setLRatioMeasurementsValue(newArr);
      } else {
        newArr[0] = e.target.value.replace(/,0/g, "").replace(0, "");
        setLRatioMeasurementsValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setLRatioMeasurementsValue(newArr);
      } else {
        newArr[1] = e.target.value.replace(/,0/g, "").replace(0, "");
        setLRatioMeasurementsValue(newArr);
      }
    }
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVAl: "",
      }));
    }
    if (name == "comments") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const [requestInfoDetails, setRequestInfoDetails] = useState({
    intDiamondId: "",
    dealerID: "",
  });
  const handleCertificateOpen = (item) => {
    if (item.certlink == "") {
      showCertInfoModal();
      setRequestInfoDetails({
        intDiamondId: item.intDiamondId,
        dealerID: item.dealerID,
      });
    } else {
      window.open(
        item.certlink,
        "CERTVIEW",
        "scrollbars=yes,resizable=no,width=650,height=630"
      );
    }
  };
  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVAl: "",
      commentsVal: "",
    });
  };
  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
  };

  const handleValidation = () => {
    const { name, email, phoneNumber, comments } = certInfoData;

    const certInfoDataValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVAl: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      certInfoDataValidation.nameVal = t("Name is compulsory");
    } else {
      certInfoDataValidation.nameVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      certInfoDataValidation.emailVal = t("Email is compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoDataValidation.emailVal = t("Email is Invalid");
    } else {
      certInfoDataValidation.emailVal = "";
    }
    if (!phoneNumber.trim()) {
      isValid = false;
      certInfoDataValidation.phoneNumberVAl = t("Phone Number is compulsory");
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoDataValidation.phoneNumberVAl = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoDataValidation.phoneNumberVAl = t("Phone number is invalid");
    } else {
      certInfoDataValidation.phoneNumberVAl = "";
    }
    if (!comments.trim()) {
      isValid = false;
      certInfoDataValidation.commentsVal = t("Comments is compulsory");
    } else {
      certInfoDataValidation.commentsVal = "";
    }

    setcertInfoDataValidation(certInfoDataValidation);
    return isValid;
  };
  const handleCancelCert = () => {
    setRequestMoreCertModal(true);
  };

  const handleSaveRequestCertificate = async () => {
    let isValid = handleValidation();

    if (!isValid) {
      handleCancelCert();
      return false;
    }

    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: requestInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: requestInfoDetails.dealerID,
      };

      setRequestMoreCertModalLoading(true);
      await AppsRingBuilderService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(
              t(
                "Your request has been sent to GemFind. A representative will be contacting you shortly."
              )
            );
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVAl: "",
              commentsVal: "",
            });
          } else {
            NotificationManager.success(
              t(
                "Your request has been sent to GemFind. A representative will be contacting you shortly."
              )
            );
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVAl: "",
              commentsVal: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreCertModalLoading(false);
          setRequestMoreCertModal(false);
          setcertInfoData({
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
          });
          setcertInfoDataValidation({
            nameVal: "",
            emailVal: "",
            phoneNumberVAl: "",
            commentsVal: "",
          });
        });
    } catch (error) {
      console.log(error);
      setRequestMoreCertModalLoading(false);
      setRequestMoreCertModal(false);
      setcertInfoData({
        name: "",
        email: "",
        phoneNumber: "",
        comments: "",
      });
      setcertInfoDataValidation({
        nameVal: "",
        emailVal: "",
        phoneNumberVAl: "",
        commentsVal: "",
      });
    }
  };

  const handleViewDiamond = () => {
    if (selectedRows.length > 0) {
      setFancyDiamondList(selectedRows);
      setTotalCount(selectedRows.length);
      setPageNo(1);
      setShowSelected(true);
    } else {
      NotificationManager.error(t("Please select diamonds from list to view."));
    }
  };
  const handleOnOk = () => {
    setVisible6(false);
  };

  const handleVisible6 = () => {
    setVisible6(true);
  };
  const handleSelectChange = (e, row) => {
    let tempArray = [...checkBoxValue];
    let tempSelectedRow = [...selectedRows];

    if (e.target.checked) {
      tempArray.push(row.intDiamondId);
      tempSelectedRow.push(row);
    } else {
      let index = tempArray.indexOf(row.intDiamondId);
      tempSelectedRow = tempSelectedRow.filter(
        (selectedRow) => selectedRow.intDiamondId !== row.intDiamondId
      );

      tempArray.splice(index, 1);
    }
    setCheckBoxValue(tempArray);
    setSelectedRows(tempSelectedRow);
  };

  const handleSelectAllChange = (e) => {
    const currentPageDiamonds = fancyDiamondList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    if (e.target.checked) {
      const newIds = currentPageDiamonds
        .filter((diamond) => !checkBoxValue.includes(diamond.intDiamondId))
        .map((diamond) => diamond.intDiamondId);
      setCheckBoxValue([...checkBoxValue, ...newIds]);
      setSelectedRows([
        ...selectedRows,
        ...currentPageDiamonds.filter((diamond) =>
          newIds.includes(diamond.intDiamondId)
        ),
      ]);
    } else {
      const currentPageDiamondIds = currentPageDiamonds.map(
        (diamond) => diamond.intDiamondId
      );
      setCheckBoxValue(
        checkBoxValue.filter((id) => !currentPageDiamondIds.includes(id))
      );
      setSelectedRows(
        selectedRows.filter(
          (row) => !currentPageDiamondIds.includes(row.intDiamondId)
        )
      );
    }
    setIsSelectAll(e.target.checked);
  };

  return (
    <React.Fragment>
      {vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <>
          <div className="vdiamond_section magento__mapping">
            <div className="form__fields">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="heading__block">
                          <h4 className="workarea__heading mt-0 mb-0">
                            {t("View My Color Diamond")}
                          </h4>

                          <button
                            className="primary-btn"
                            onClick={handleHideShowFilter}
                          >
                            <img src={Filter_icon} alt="" width="14px" />
                            {hideShowFilter ? (
                              <span>
                                {t("Show Filter")}{" "}
                                <i className="fa fa-angle-down"></i>
                              </span>
                            ) : (
                              <span>
                                {t("Hide Filter")}{" "}
                                <i className="fa fa-angle-up"></i>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="filter_section"
                      style={{ position: "none" }}
                    >
                      <div
                        className={
                          hideShowFilter
                            ? "form__fields filter_inpsection hide"
                            : "form__fields filter_inpsection"
                        }
                        style={{ position: "unset", marginBottom: "1rem" }}
                      >
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="input__block">
                                <label> {t("Colors")} </label>
                                <div className="imgselct__div">
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Blue
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Blue")}
                                  >
                                    <img src={Blue} alt="" />
                                    <span> {t("Blue")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Pink
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Pink")}
                                  >
                                    <img src={Pink} alt="" />
                                    <span> {t("Pink")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Yellow
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Yellow")}
                                  >
                                    <img src={Yellow} alt="" />
                                    <span> {t("Yellow")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Brown
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Brown")}
                                  >
                                    <img src={Brown} alt="" />
                                    <span> {t("Brown")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Green
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Green")}
                                  >
                                    <img src={Green} alt="" />
                                    <span> {t("Green")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Gray
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Gray")}
                                  >
                                    <img src={Gray} alt="" />
                                    <span> {t("Gray")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Black
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Black")}
                                  >
                                    <img src={Black} alt="" />
                                    <span> {t("Black")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Red
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Red")}
                                  >
                                    <img src={Red} alt="" />
                                    <span> {t("Red")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Purple
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Purple")}
                                  >
                                    <img src={Purple} alt="" />
                                    <span> {t("Purple")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Chameleon
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Chameleon")}
                                  >
                                    <img src={Chameleon} alt="" />
                                    <span> {t("Chameleon")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.Violet
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onColorClick("Violet")}
                                  >
                                    <img src={Violet} alt="" />
                                    <span> {"Violet"} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.colorIsSelected.All
                                        ? "selected all__div"
                                        : "all__div"
                                    }
                                    onClick={() => onColorClick("All")}
                                  >
                                    <span> {t("All")} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="input__block">
                                <label> {t("Shapes")} </label>
                                <div className="imgselct__div">
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Round
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Round")}
                                  >
                                    <img src={Roundimg} alt="" />
                                    <span> {t("Round")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Radiant
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Radiant")}
                                  >
                                    <img src={RadiantImg} alt="" />
                                    <span> {t("Radiant")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Princess
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Princess")}
                                  >
                                    <img src={PrincessImg} alt="" />
                                    <span> {t("Princess")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Pear
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Pear")}
                                  >
                                    <img src={PearImg} alt="" />
                                    <span> {t("Pear")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Oval
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Oval")}
                                  >
                                    <img src={OvalImg} alt="" />
                                    <span> {t("Oval")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Marquise
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Marquise")}
                                  >
                                    <img src={MarquiseImg} alt="" />
                                    <span> {t("Marquise")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Heart
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Heart")}
                                  >
                                    <img src={HeartImg} alt="" />
                                    <span> {t("Heart")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Emerald
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Emerald")}
                                  >
                                    <img src={EmeraldImg} alt="" />
                                    <span> {t("Emerald")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Cushion
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Cushion")}
                                  >
                                    <img src={CushionImg} alt="" />
                                    <span> {t("Cushion")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.Asscher
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Asscher")}
                                  >
                                    <img src={AsscherImg} alt="" />
                                    <span> {t("Asscher")} </span>
                                  </div>
                                  <div
                                    className={
                                      ViewMyColorData.ShapeIsSelected.All
                                        ? "selected all__div"
                                        : "all__div"
                                    }
                                    onClick={() => onShapeClick("All")}
                                  >
                                    <span> {t("All")} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="input__block">
                                <label> {t("Permission")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  showArrow={true}
                                  placeholder={t("Any")}
                                  name="permission"
                                  value={
                                    ViewMyColorData.permission
                                      ? ViewMyColorData.permission
                                      : null
                                  }
                                  defaultvalue=""
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "permission")
                                  }
                                >
                                  {permissionsForDiamondCatalogList.map(
                                    (item) => {
                                      return (
                                        <React.Fragment>
                                          <Option value={item.value}>
                                            {item.text}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label> {t("Carats")} </label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={caratSlideValue}
                                    min={ViewMyColorData.minCarat}
                                    max={ViewMyColorData.maxCarat}
                                    onChange={(e) => {
                                      setCaratSlideValue(e);
                                      setFilterBtnVisble(false);
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={caratSlideValue[0].toLocaleString(
                                          "en-US"
                                        )}
                                        onChange={(e) => {
                                          firstsetCaratSlideValue(e, 0);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={caratSlideValue[1].toLocaleString(
                                          "en-US"
                                        )}
                                        onChange={(e) => {
                                          firstsetCaratSlideValue(e, 1);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Fancy Intensity")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  showArrow={true}
                                  placeholder={t("Any")}
                                  name="fancyIntensity"
                                  value={
                                    ViewMyColorData.fancyIntensity
                                      ? ViewMyColorData.fancyIntensity
                                      : null
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fancyIntensity"
                                    )
                                  }
                                >
                                  <Option value="0"> {t("Any")} </Option>
                                  {fancyIntensityList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.intensityId}
                                          key={item.intensityId}
                                        >
                                          {item.intensityValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Overtone 1")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  mode="multiple"
                                  placeholder={t("Select Overtone 1")}
                                  value={
                                    ViewMyColorData.overtone1
                                      ? ViewMyColorData.overtone1
                                      : []
                                  }
                                  name="overtone1"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "overtone1")
                                  }
                                >
                                  <Option value=""> {t("Any")} </Option>
                                  {overToneForDiamondCatalogList &&
                                    overToneForDiamondCatalogList.map(
                                      (item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.overtoneValue}
                                              key={item.overtoneID}
                                            >
                                              {item.overtoneValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Overtone 2")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder={t("Select Overtone 2")}
                                  name="overtone2"
                                  mode="multiple"
                                  value={
                                    ViewMyColorData.overtone2
                                      ? ViewMyColorData.overtone2
                                      : []
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "overtone2")
                                  }
                                >
                                  <Option value=""> {t("Any")} </Option>
                                  {overToneForDiamondCatalogList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.overtoneValue}
                                          key={item.overtoneID}
                                        >
                                          {item.overtoneValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {t("Clarity")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="clarityFrom"
                                      value={
                                        ViewMyColorData.clarityFrom
                                          ? ViewMyColorData.clarityFrom
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "clarityFrom"
                                        )
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondClarityList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.clarityID}
                                              key={item.clarityID}
                                            >
                                              {item.claritydetails}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="clarityTo"
                                      value={
                                        ViewMyColorData.clarityTo
                                          ? ViewMyColorData.clarityTo
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(e, "select", "clarityTo")
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondClarityList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.clarityID}
                                              key={item.clarityID}
                                            >
                                              {item.claritydetails}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {t("Polish")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="polishFrom"
                                      value={
                                        ViewMyColorData.polishFrom
                                          ? ViewMyColorData.polishFrom
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "polishFrom"
                                        )
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondPolishList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.polishID}
                                              key={item.polishID}
                                            >
                                              {item.polishValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="polishTo"
                                      value={
                                        ViewMyColorData.polishTo
                                          ? ViewMyColorData.polishTo
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(e, "select", "polishTo")
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondPolishList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.polishID}
                                              key={item.polishID}
                                            >
                                              {item.polishValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {t("Symmetry")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="symmetryFrom"
                                      value={
                                        ViewMyColorData.symmetryFrom
                                          ? ViewMyColorData.symmetryFrom
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "symmetryFrom"
                                        )
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondSymmetryList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.symmetryID}
                                              key={item.symmetryID}
                                            >
                                              {item.symmetryValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="symmetryTo"
                                      value={
                                        ViewMyColorData.symmetryTo
                                          ? ViewMyColorData.symmetryTo
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "symmetryTo"
                                        )
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondSymmetryList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.symmetryID}
                                              key={item.symmetryID}
                                            >
                                              {item.symmetryValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {t("Cut")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="cutFrom"
                                      value={
                                        ViewMyColorData.cutFrom
                                          ? ViewMyColorData.cutFrom
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(e, "select", "cutFrom")
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondCutGradeList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.cutGradeId}
                                              key={item.cutGradeId}
                                            >
                                              {item.cutGradeValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="cutTo"
                                      value={
                                        ViewMyColorData.cutTo
                                          ? ViewMyColorData.cutTo
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(e, "select", "cutTo")
                                      }
                                    >
                                      <Option> {t("Any")} </Option>
                                      {diamondCutGradeList.map((item) => {
                                        return (
                                          <React.Fragment>
                                            <Option
                                              value={item.cutGradeId}
                                              key={item.cutGradeId}
                                            >
                                              {item.cutGradeValue}
                                            </Option>
                                          </React.Fragment>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label> {t("Overall Price")} </label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={overallPriceValue}
                                    min={ViewMyColorData.minOverallPrice}
                                    max={ViewMyColorData.maxOverallPrice}
                                    onChange={(e) => {
                                      setOverallPriceValue(e);
                                      setFilterBtnVisble(false);
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(overallPriceValue[0])
                                        }
                                        onChange={(e) => {
                                          firstsetOverAllSlideValue(e, 0);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(overallPriceValue[1])
                                        }
                                        onChange={(e) => {
                                          firstsetOverAllSlideValue(e, 1);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label>{t("Price Per Carat Range")}</label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={pricePerCaratValue}
                                    min={ViewMyColorData.minPriceperCaretRange}
                                    max={ViewMyColorData.maxPriceperCaretRange}
                                    onChange={(e) => {
                                      setPricePerCaratValue(e);
                                      setFilterBtnVisble(false);
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(pricePerCaratValue[0])
                                        }
                                        onChange={(e) => {
                                          firstsetPricePerCaratValueSlideValue(
                                            e,
                                            0
                                          );
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(pricePerCaratValue[1])
                                        }
                                        onChange={(e) => {
                                          firstsetPricePerCaratValueSlideValue(
                                            e,
                                            1
                                          );
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Location")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder={t("Select Location")}
                                  name="location"
                                  optionFilterProp="children"
                                  value={
                                    ViewMyColorData.location
                                      ? ViewMyColorData.location
                                      : null
                                  }
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "location")
                                  }
                                >
                                  <Option value="0"> {t("Any")} </Option>
                                  {locationsForDiamondCatalogList.map(
                                    (item) => {
                                      return (
                                        <React.Fragment>
                                          <Option
                                            value={item.name}
                                            key={item.countryID}
                                          >
                                            {item.name}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Eye Clean")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder={t("Select Eye Clean Inclusion")}
                                  name="eyeClean"
                                  mode="multiple"
                                  value={
                                    ViewMyColorData.eyeClean
                                      ? ViewMyColorData.eyeClean
                                      : []
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "eyeClean")
                                  }
                                >
                                  <Option value="Any">{t("Any")} </Option>
                                  <Option value={t("Yes")}>{t("Yes")} </Option>
                                  <Option value="BorderLine">
                                    {t("Borderline")}{" "}
                                  </Option>
                                  <Option value="No"> {t("No")} </Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {t("Fluorescence Intensity")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="fluorescenceIntensityFrom"
                                      value={
                                        ViewMyColorData.fluorescenceIntensityFrom
                                          ? ViewMyColorData.fluorescenceIntensityFrom
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "fluorescenceIntensityFrom"
                                        )
                                      }
                                    >
                                      <Option value="0">{t("Any")} </Option>
                                      <Option value="1">{t("None")} </Option>
                                      <Option value="2">
                                        {t("Very Slight")}{" "}
                                      </Option>
                                      <Option value="3">{t("Slight")} </Option>
                                      <Option value="4">{t("Faint")} </Option>
                                      <Option value="5">{t("Medium")} </Option>
                                      <Option value="6">{t("Strong")} </Option>
                                      <Option value="7">
                                        {t("Very Strong")}{" "}
                                      </Option>
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      name="fluorescenceIntensityTo"
                                      value={
                                        ViewMyColorData.fluorescenceIntensityTo
                                          ? ViewMyColorData.fluorescenceIntensityTo
                                          : null
                                      }
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleOnChange(
                                          e,
                                          "select",
                                          "fluorescenceIntensityTo"
                                        )
                                      }
                                    >
                                      <Option value="0">{t("Any")} </Option>
                                      <Option value="1">{t("None")} </Option>
                                      <Option value="2">
                                        {t("Very Slight")}{" "}
                                      </Option>
                                      <Option value="3">{t("Slight")} </Option>
                                      <Option value="4">{t("Faint")} </Option>
                                      <Option value="5">{t("Medium")} </Option>
                                      <Option value="6">{t("Strong")} </Option>
                                      <Option value="7">
                                        {t("Very Strong")}{" "}
                                      </Option>
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label>{t("Fluorescence Color")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder={t("Select Fluorescence Color")}
                                  name="fluorescenceColor"
                                  mode="multiple"
                                  value={
                                    ViewMyColorData.fluorescenceColor
                                      ? ViewMyColorData.fluorescenceColor
                                      : []
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fluorescenceColor"
                                    )
                                  }
                                >
                                  <Option> {t("Any")} </Option>
                                  {diamondFluorescenceColorList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.fluorescenceValue}
                                          key={item.fluorescenceValue}
                                        >
                                          {item.fluorescenceValue}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Dealers")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  showArrow={true}
                                  mode="multiple"
                                  placeholder={t("Select Dealers")}
                                  name="dealers"
                                  value={
                                    ViewMyColorData.dealers
                                      ? ViewMyColorData.dealers
                                      : null
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "dealers")
                                  }
                                >
                                  <Option value="0">{t("Search all")} </Option>
                                  {dealersForDiamondCatalogList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option
                                          value={item.dealerID}
                                          key={item.dealerID}
                                        >
                                          {item.dealerCompany}
                                        </Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Certificates")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  showArrow={true}
                                  mode="multiple"
                                  placeholder={t("Select Certificate")}
                                  name="certificates"
                                  value={
                                    ViewMyColorData.certificates
                                      ? ViewMyColorData.certificates
                                      : []
                                  }
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "certificates")
                                  }
                                >
                                  {certificatesForDiamondCatalogList.map(
                                    (item) => {
                                      return (
                                        <React.Fragment>
                                          <Option
                                            value={item.certificateValue}
                                            key={item.certificateValue}
                                          >
                                            {item.certificateValue}
                                          </Option>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showMoreLess == false ? null : (
                          <div className="col-md-12 mt-2">
                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Length(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="lengthFrom"
                                        value={
                                          ViewMyColorData.lengthFrom
                                            ? ViewMyColorData.lengthFrom
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "lengthFrom"
                                          )
                                        }
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="lengthTo"
                                        value={
                                          ViewMyColorData.lengthTo
                                            ? ViewMyColorData.lengthTo
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "lengthTo"
                                          )
                                        }
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Width(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="widthFrom"
                                        value={
                                          ViewMyColorData.widthFrom
                                            ? ViewMyColorData.widthFrom
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "widthFrom"
                                          )
                                        }
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="widthTo"
                                        value={
                                          ViewMyColorData.widthTo
                                            ? ViewMyColorData.widthTo
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(e, "select", "widthTo")
                                        }
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Height(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="heightFrom"
                                        value={
                                          ViewMyColorData.heightFrom
                                            ? ViewMyColorData.heightFrom
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "heightFrom"
                                          )
                                        }
                                      >
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="heightTo"
                                        value={
                                          ViewMyColorData.heightTo
                                            ? ViewMyColorData.heightTo
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "heightTo"
                                          )
                                        }
                                      >
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="sliderrange__maindiv">
                                  <label>{t("L/W Ratio Measurements")} </label>
                                  <div className="col-md-12 mt-3">
                                    <Slider
                                      range
                                      value={lWRatioMeasurementsValue}
                                      min={
                                        ViewMyColorData.minLWRatioMeasurements
                                      }
                                      max={
                                        ViewMyColorData.maxLWRatioMeasurements
                                      }
                                      onChange={(e) => {
                                        setLRatioMeasurementsValue(e);
                                        setFilterBtnVisble(false);
                                      }}
                                    />
                                    <div className="rangeSlider__values">
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={lWRatioMeasurementsValue[0].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetlWRatioMeasurementsValueSlideValue(
                                              e,
                                              0
                                            );
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                      <div>
                                        <img
                                          src={space__icon}
                                          className="img-fluid space__icon"
                                        />
                                      </div>
                                      <div className="input__block">
                                        <input
                                          type="text"
                                          value={lWRatioMeasurementsValue[1].toLocaleString(
                                            "en-US"
                                          )}
                                          onChange={(e) => {
                                            firstsetlWRatioMeasurementsValueSlideValue(
                                              e,
                                              1
                                            );
                                          }}
                                          onKeyPress={onKeyPressEvent1}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("LW Ratio")} </label>
                                  <div className="radio__div">
                                    <div className="radio__block mb-0">
                                      <input
                                        type="radio"
                                        id="lwRatioAny"
                                        name="Any"
                                        value={ViewMyColorData.Radio}
                                        checked={
                                          ViewMyColorData.Radio === "Any"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadio}
                                      />
                                      <label for="any">{t("Any")} </label>
                                    </div>
                                    <div className="radio__block mb-0">
                                      <input
                                        type="radio"
                                        id="lwRatioSquare"
                                        name="Square"
                                        value={ViewMyColorData.Radio}
                                        checked={
                                          ViewMyColorData.Radio === "Square"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadio}
                                      />
                                      <label for="sqr">{t("Square")} </label>
                                    </div>
                                    <div className="radio__block mb-0">
                                      <input
                                        type="radio"
                                        id="lwRatioRec"
                                        name="Rectangle"
                                        value={ViewMyColorData.Radio}
                                        checked={
                                          ViewMyColorData.Radio === "Rectangle"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadio}
                                      />
                                      <label for="rec">{t("Rectangle")} </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Shade")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    mode="multiple"
                                    name="shade"
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "shade")
                                    }
                                    value={
                                      ViewMyColorData.shade
                                        ? ViewMyColorData.shade
                                        : []
                                    }
                                  >
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="None">{t("None")} </Option>
                                    <Option value="No Yellow">
                                      {t("No Yellow")}{" "}
                                    </Option>
                                    <Option value="No Green">
                                      {t("No Green")}{" "}
                                    </Option>
                                    <Option value="No Grey">
                                      {t("No Grey")}{" "}
                                    </Option>
                                    <Option value="No Black">
                                      {t("No Black")}{" "}
                                    </Option>
                                    <Option value="No Pink">
                                      {t("No Pink")}{" "}
                                    </Option>
                                    <Option value="No Blue">
                                      {t("No Blue")}{" "}
                                    </Option>
                                    <Option value="No Brown">
                                      {t("No Brown")}{" "}
                                    </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label>{t("Central Inclusion Range")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    name="centralInclusionRange"
                                    mode="multiple"
                                    value={
                                      ViewMyColorData.centralInclusionRange
                                        ? ViewMyColorData.centralInclusionRange
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(
                                        e,
                                        "select",
                                        "centralInclusionRange"
                                      )
                                    }
                                  >
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="None">{t("None")} </Option>
                                    <Option value="Light">{t("Light")} </Option>
                                    <Option value="Medium">
                                      {t("Medium")}{" "}
                                    </Option>
                                    <Option value="Heavy">{t("Heavy")} </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label>{t("Black Inclusion Range")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    name="blackInclusionRange"
                                    mode="multiple"
                                    value={
                                      ViewMyColorData.blackInclusionRange
                                        ? ViewMyColorData.blackInclusionRange
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(
                                        e,
                                        "select",
                                        "blackInclusionRange"
                                      )
                                    }
                                  >
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="None">{t("None")} </Option>
                                    <Option value="Light">{t("Light")} </Option>
                                    <Option value="Medium">
                                      {t("Medium")}{" "}
                                    </Option>
                                    <Option value="Heavy">{t("Heavy")} </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label>{t("Milky Inclusion Range")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    name="milkyInclusionRange"
                                    mode="multiple"
                                    value={
                                      ViewMyColorData.milkyInclusionRange
                                        ? ViewMyColorData.milkyInclusionRange
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(
                                        e,
                                        "select",
                                        "milkyInclusionRange"
                                      )
                                    }
                                  >
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="None">{t("None")} </Option>
                                    <Option value="Light">{t("Light")} </Option>
                                    <Option value="Medium">
                                      {t("Medium")}{" "}
                                    </Option>
                                    <Option value="Heavy">{t("Heavy")} </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Girdle Range")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="girdleRangeFrom"
                                        value={
                                          ViewMyColorData.girdleRangeFrom
                                            ? ViewMyColorData.girdleRangeFrom
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "girdleRangeFrom"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">
                                          {t("Extremely Thin")}{" "}
                                        </Option>
                                        <Option value="2">
                                          {t("Very Thin")}{" "}
                                        </Option>
                                        <Option value="3">{t("Thin")} </Option>
                                        <Option value="4">
                                          {t("Slightly Thin")}{" "}
                                        </Option>
                                        <Option value="5">
                                          {t("Medium")}{" "}
                                        </Option>
                                        <Option value="6">
                                          {t("Slightly Thick")}{" "}
                                        </Option>
                                        <Option value="7">{t("Thick")} </Option>
                                        <Option value="8">
                                          {t("Very Thick")}{" "}
                                        </Option>
                                        <Option value="9">
                                          {t("Extremely Thick")}{" "}
                                        </Option>
                                        <Option value="10">
                                          {t("Extra Thin")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="girdleRangeTo"
                                        value={
                                          ViewMyColorData.girdleRangeTo
                                            ? ViewMyColorData.girdleRangeTo
                                            : []
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "girdleRangeTo"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">
                                          {t("Extremely Thin")}{" "}
                                        </Option>
                                        <Option value="2">
                                          {t("Very Thin")}{" "}
                                        </Option>
                                        <Option value="3">{t("Thin")} </Option>
                                        <Option value="4">
                                          {t("Slightly Thin")}{" "}
                                        </Option>
                                        <Option value="5">
                                          {t("Medium")}{" "}
                                        </Option>
                                        <Option value="6">
                                          {t("Slightly Thick")}{" "}
                                        </Option>
                                        <Option value="7">{t("Thick")} </Option>
                                        <Option value="8">
                                          {t("Very Thick")}{" "}
                                        </Option>
                                        <Option value="9">
                                          {t("Extremely Thick")}{" "}
                                        </Option>
                                        <Option value="10">
                                          {t("Extra Thin")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Crown Angle % Range")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        onKeyPress={onKeyPressEvent}
                                        min="0"
                                        max="100"
                                        name="crownAngleRangeFrom"
                                        value={
                                          ViewMyColorData.crownAngleRangeFrom
                                        }
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            3
                                          );
                                          onNumberChange(
                                            e,
                                            "crownAngleRangeFrom"
                                          );
                                        }}
                                      />
                                      <span className="ampcent__txt">%</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        onKeyPress={onKeyPressEvent}
                                        name="crownAngleRangeTo"
                                        value={
                                          ViewMyColorData.crownAngleRangeTo
                                        }
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            3
                                          );
                                          onNumberChange(
                                            e,
                                            "crownAngleRangeTo"
                                          );
                                        }}
                                      />
                                      <span className="ampcent__txt">%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Pavilion Angle % Range")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        onKeyPress={onKeyPressEvent}
                                        name="pavilionAngleRangeFrom"
                                        value={
                                          ViewMyColorData.pavilionAngleRangeFrom
                                        }
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            3
                                          );
                                          onNumberChange(
                                            e,
                                            "pavilionAngleRangeFrom"
                                          );
                                        }}
                                      />
                                      <span className="ampcent__txt">%</span>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        onKeyPress={onKeyPressEvent}
                                        name="pavilionAngleRangeTo"
                                        value={
                                          ViewMyColorData.pavilionAngleRangeTo
                                        }
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(
                                            0,
                                            3
                                          );
                                          onNumberChange(
                                            e,
                                            "pavilionAngleRangeTo"
                                          );
                                        }}
                                      />
                                      <span className="ampcent__txt">%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Key to Symbols")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    name="keytoSymbols"
                                    mode="multiple"
                                    value={
                                      ViewMyColorData.keytoSymbols
                                        ? ViewMyColorData.keytoSymbols
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(
                                        e,
                                        "select",
                                        "keytoSymbols"
                                      )
                                    }
                                  >
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="Bearding">
                                      {t("Bearding")}{" "}
                                    </Option>
                                    <Option value="Brown Patch of Color">
                                      {t("Brown Patch of Color")}{" "}
                                    </Option>
                                    <Option value="Bruise">
                                      {t("Bruise")}{" "}
                                    </Option>
                                    <Option value="Cavity">
                                      {t("Cavity")}{" "}
                                    </Option>
                                    <Option value="Chip">{t("Chip")} </Option>
                                    <Option value="Cleavage">
                                      {t("Cleavage")}{" "}
                                    </Option>
                                    <Option value="Cloud">{t("Cloud")} </Option>
                                    <Option value="Crystal Surface">
                                      {t("Crystal Surface")}{" "}
                                    </Option>
                                    <Option value="Etch Channel">
                                      {t("Etch Channel")}{" "}
                                    </Option>
                                    <Option value="Extra Facet">
                                      {t("Extra Facet")}{" "}
                                    </Option>
                                    <Option value="Feather">
                                      {t("Feather")}{" "}
                                    </Option>
                                    <Option value="Flux Remnant">
                                      {t("Flux Remnant")}{" "}
                                    </Option>
                                    <Option value="Indented Natural">
                                      {t("Indented Natural")}{" "}
                                    </Option>
                                    <Option value="Internal Graining">
                                      {t("Internal Graining")}{" "}
                                    </Option>
                                    <Option value="Internal Inscription">
                                      {t("Internal Inscription")}{" "}
                                    </Option>
                                    <Option value="Internal Laser Drilling">
                                      {t("Internal Laser Drilling")}{" "}
                                    </Option>
                                    <Option value="Knot">{t("Knot")} </Option>
                                    <Option value="Laser Drill Hole">
                                      {t("Laser Drill Hole")}{" "}
                                    </Option>
                                    <Option value="Manufacturing Remnant">
                                      {t("Manufacturing Remnant")}{" "}
                                    </Option>
                                    <Option value="Minor Details of Polish">
                                      {t("Minor Details of Polish")}{" "}
                                    </Option>
                                    <Option value="Natural">
                                      {t("Natural")}{" "}
                                    </Option>
                                    <Option value="Needly">
                                      {t("Needly")}{" "}
                                    </Option>
                                    <Option value="No Inclusion">
                                      {t("No Inclusion")}{" "}
                                    </Option>
                                    <Option value="Pinpoint">
                                      {t("Pinpoint")}{" "}
                                    </Option>
                                    <Option value="Reflecting Surface Graining">
                                      {t("Reflecting Surface Graining")}{" "}
                                    </Option>
                                    <Option value="Surface Graining">
                                      {t("Surface Graining")}{" "}
                                    </Option>
                                    <Option value="Twinning Wisp">
                                      {t("Twinning Wisp")}{" "}
                                    </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Culet Range")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="culetRangeFrom"
                                        value={
                                          ViewMyColorData.culetRangeFrom
                                            ? ViewMyColorData.culetRangeFrom
                                            : null
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "culetRangeFrom"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">{t("None")} </Option>
                                        <Option value="2">{t("Small")} </Option>
                                        <Option value="3">
                                          {t("Very Small")}{" "}
                                        </Option>
                                        <Option value="4">
                                          {t("Medium")}{" "}
                                        </Option>
                                        <Option value="5">{t("Large")} </Option>
                                        <Option value="6">
                                          {t("Very Large")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="culetRangeTo"
                                        value={
                                          ViewMyColorData.culetRangeTo
                                            ? ViewMyColorData.culetRangeTo
                                            : null
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "culetRangeTo"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">{t("None")} </Option>
                                        <Option value="2">{t("Small")} </Option>
                                        <Option value="3">
                                          {t("Very Small")}{" "}
                                        </Option>
                                        <Option value="4">
                                          {t("Medium")}{" "}
                                        </Option>
                                        <Option value="5">{t("Large")} </Option>
                                        <Option value="6">
                                          {t("Very Large")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Culet Condition Range")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="culetConditionRangeFrom"
                                        value={
                                          ViewMyColorData.culetConditionRangeFrom
                                            ? ViewMyColorData.culetConditionRangeFrom
                                            : null
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "culetConditionRangeFrom"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">{t("None")} </Option>
                                        <Option value="2">
                                          {t("Abraded")}{" "}
                                        </Option>
                                        <Option value="3">
                                          {t("Chipped")}{" "}
                                        </Option>
                                        <Option value="4">
                                          {t("Pointed")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        name="culetConditionRangeTo"
                                        value={
                                          ViewMyColorData.culetConditionRangeTo
                                            ? ViewMyColorData.culetConditionRangeTo
                                            : null
                                        }
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          handleOnChange(
                                            e,
                                            "select",
                                            "culetConditionRangeTo"
                                          )
                                        }
                                      >
                                        <Option value="0">{t("Any")} </Option>
                                        <Option value="1">{t("None")} </Option>
                                        <Option value="2">
                                          {t("Abraded")}{" "}
                                        </Option>
                                        <Option value="3">
                                          {t("Chipped")}{" "}
                                        </Option>
                                        <Option value="4">
                                          {t("Pointed")}{" "}
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Treatment")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Any")}
                                    name="treatment"
                                    mode="multiple"
                                    value={
                                      ViewMyColorData.treatment
                                        ? ViewMyColorData.treatment
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(e, "select", "treatment")
                                    }
                                  >
                                    <Option value="Laser Drilled">
                                      {t("Laser Drilled")}{" "}
                                    </Option>
                                    <Option value="Clarity Enhanced">
                                      {t("Clarity Enhanced")}{" "}
                                    </Option>
                                    <Option value="Color Enhanced">
                                      {t("Color Enhanced")}{" "}
                                    </Option>
                                    <Option value="Irradiated">
                                      {t("Irradiated")}{" "}
                                    </Option>
                                    <Option value="HPHT">{t("HPHT")} </Option>
                                    <Option value="Other">{t("Other")} </Option>
                                    <Option value="Any">{t("Any")} </Option>
                                    <Option value="None">{t("None")} </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Depth")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        onKeyPress={onKeyPressEvent}
                                        name="depthFrom"
                                        value={ViewMyColorData.depthFrom}
                                        onChange={(e) => {
                                          onNumberChange(e, "depthFrom");
                                        }}
                                        max={100}
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.depthFrom && (
                                        <span className="error-color-red">
                                          {validation.depthFrom}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        onKeyPress={onKeyPressEvent}
                                        name="depthTo"
                                        value={ViewMyColorData.depthTo}
                                        onChange={(e) => {
                                          onNumberChange(e, "depthTo");
                                        }}
                                        max={100}
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.depthTo && (
                                        <span className="error-color-red">
                                          {validation.depthTo}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {t("Table")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        onKeyPress={onKeyPressEvent}
                                        name="tableFrom"
                                        value={ViewMyColorData.tableFrom}
                                        onChange={(e) => {
                                          onNumberChange(e, "tableFrom");
                                        }}
                                        max={100}
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.tableFrom && (
                                        <span className="error-color-red">
                                          {validation.tableFrom}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        onKeyPress={onKeyPressEvent}
                                        name="tableTo"
                                        value={ViewMyColorData.tableTo}
                                        onChange={(e) => {
                                          onNumberChange(e, "tableTo");
                                        }}
                                        max={100}
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.tableTo && (
                                        <span className="error-color-red">
                                          {validation.tableTo}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Matched Pairs")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    showArrow={true}
                                    mode="multiple"
                                    placeholder={t("Search All Stones")}
                                    name="matchedPairs"
                                    value={
                                      ViewMyColorData.matchedPairs
                                        ? ViewMyColorData.matchedPairs
                                        : []
                                    }
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      handleOnChange(
                                        e,
                                        "select",
                                        "matchedPairs"
                                      )
                                    }
                                  >
                                    <Option value="0">
                                      {t("Search All Stones")}{" "}
                                    </Option>
                                    <Option value={t("Yes")}>
                                      {t("Search Single Stone")}{" "}
                                    </Option>
                                    <Option value="No">
                                      {t("Search Match Pairs")}{" "}
                                    </Option>
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Has Video")} </label>
                                  <div className="radio__block row">
                                    <div className="radio__btn w-20 d-flex">
                                      <input
                                        type="radio"
                                        value={ViewMyColorData.hasVideo}
                                        name="true"
                                        checked={
                                          ViewMyColorData.hasVideo === "true"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadioHasVideo}
                                      />

                                      <label>{t("Yes")}</label>
                                    </div>
                                    <div className="radio__btn w-20 d-flex">
                                      <input
                                        type="radio"
                                        name="false"
                                        value={ViewMyColorData.hasVideo}
                                        checked={
                                          ViewMyColorData.hasVideo === "false"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadioHasVideo}
                                      />

                                      <label>{t("No")}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 filtbtn_div">
                              <Button
                                className="primary-btn mr-2"
                                onClick={resetFilters}
                              >
                                {t("Reset Filters")}
                              </Button>
                              <Button
                                className="primary-btn mr-2"
                                onClick={() => setShowMoreLess(!showMoreLess)}
                              >
                                {showMoreLess == false
                                  ? t("Show More Filters")
                                  : t("Show Less Filters")}
                              </Button>
                              <Button
                                className={
                                  filterBtnVisble == true
                                    ? "disabled-btn"
                                    : "primary-btn"
                                }
                                disabled={filterBtnVisble}
                                onClick={() =>
                                  applyFilters(dealerId, pageNo, pageSize)
                                }
                                loading={loading}
                              >
                                {t("Apply Filters")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="options__div">
                      <div className="row">
                        <div className="col-lg-6 add__div">
                          <span className="diamnd__txt">
                            {t("You Have")}{" "}
                            <span className="txt__no">{totalCount}</span>{" "}
                            {t("Diamonds")}
                          </span>
                          <button
                            className="primary-btn"
                            onClick={() => handleCreateRedirect()}
                          >
                            <i className="fa fa-plus-circle"></i> {t("Add")}
                          </button>
                          <button
                            className="primary-btn"
                            onClick={() => handleUploadRedirect()}
                          >
                            <i className="fa fa-cloud-upload"></i> {t("Upload")}
                          </button>
                          <button
                            className="primary-btn"
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            <i className="fa fa-rotate-left"></i> {t("Reset")}
                          </button>
                        </div>
                        <div className="col-lg-6 print__div">
                          <span className="select__txt">
                            <i class="fa fa-list-ul"></i>{" "}
                            {isSelectAll ? "All" : selectedRows.length}
                            <span className="ml-1">{t("Selected")}</span>
                          </span>
                          <Button
                            onClick={() => handleViewDiamond()}
                            className="primary-btn"
                            disabled={isSelectAll}
                          >
                            <i className="fa fa-eye"></i> {t("View")}
                          </Button>

                          <Button
                            className="primary-btn"
                            // disabled={isSelectAll}
                            onClick={() => {
                              handlePrintDiamondList();
                            }}
                          >
                            <i className="fa fa-print"></i> {t("Print")}
                          </Button>

                          <Button
                            className="primary-btn"
                            // onClick={handleSendMailModal}
                            onClick={handleSendModal}
                          >
                            <i className="fa fa-envelope"></i> {t("Send")}
                          </Button>
                          <Button
                            className="primary-btn"
                            onClick={() => handleDeleteModal()}
                            disabled={isSelectAll}
                          >
                            <i className="fa fa-trash-o"></i> {t("Delete")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/*there is a search filter in react for table*/}
                    <Table
                      key={tableResetKey}
                      className="vdiamond__table mobile_tbl"
                      bordered={bordered}
                      ref={(ref) => {
                        printTable = ref;
                      }}
                      columns={[
                        {
                          title: (
                            <Checkbox
                              checked={isSelectAll}
                              onChange={handleSelectAllChange}
                            />
                          ),
                          dataIndex: "view",
                          width: window.innerWidth > 768 ? 30 : 8,
                          render: (item, row) => {
                            return (
                              <React.Fragment>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={checkBoxValue.includes(
                                      row.intDiamondId
                                    )}
                                    onChange={(e) => handleSelectChange(e, row)}
                                  />
                                  <div
                                    className="infoicon__div"
                                    onClick={() => handleViewModal(row)}
                                  >
                                    <i className="fa fa-info-circle"></i>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          },
                        },
                        {
                          title: t("Stock #"),
                          dataIndex: "txtStockNo",
                          width: 50,
                          responsive: ["lg"],
                          ...getColumnSearchProps("txtStockNo"),
                          key: "txtStockNo",
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          ellipsis: true,
                        },
                        {
                          title: t("Shape"),
                          dataIndex: "txtShape",
                          width: window.innerWidth > 768 ? 50 : 8,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return (
                              <div className="shape__img">
                                <div className="shape__icon">
                                  <img src={row.imageSmallShape} />
                                </div>
                                {window.innerWidth > 768 && (
                                  <span>{row.txtShape}</span>
                                )}
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Size"),
                          dataIndex: "fltCarat",
                          width: window.innerWidth > 768 ? 20 : 6,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Color"),
                          dataIndex: "txtFancyColorMainBody",
                          width: window.innerWidth > 768 ? 30 : 8,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Clarity"),
                          dataIndex: "txtClarity",
                          width: window.innerWidth > 768 ? 30 : 8,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Off %"),
                          dataIndex: "fltRapPercent",
                          width: 40,
                          responsive: ["lg"],
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                          render: (item, row) => {
                            return (
                              <>
                                {row.fltRapPercent ? (
                                  <span>
                                    {row.fltRapPercent.includes("-")
                                      ? row.fltRapPercent
                                      : "-" + row.fltRapPercent}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: t("Price(Cost)"),
                          dataIndex: "fltPrice",
                          // width: 50,
                          width: window.innerWidth > 768 ? 50 : 10,
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                          render: (item, row) => {
                            return (
                              <>
                                {row?.currencySign || "$"}
                                {row.fltPrice
                                  ? parseFloat(row.fltPrice).toFixed(2)
                                  : ""}
                              </>
                            );
                          },
                        },
                        {
                          title: t("Fluor."),
                          dataIndex: "txtFluorescence",
                          width: 30,
                          responsive: ["lg"],
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                          render: (item, row) => {
                            return row.txtFluorescence;
                          },
                        },
                        {
                          title: t("Dep."),
                          dataIndex: "fltDepth",
                          width: 25,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return row.fltDepth + "%";
                          },
                        },
                        {
                          title: t("Table"),
                          dataIndex: "fltTable",
                          width: 25,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return row.fltTable + "%";
                          },
                        },
                        {
                          title: "L*D*W",
                          dataIndex: "txtMeasurements",
                          width: 30,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) =>
                            a.txtMeasurements.length - b.txtMeasurements.length,
                          render: (item, row) => {
                            return row.txtMeasurements;
                          },
                        },
                        {
                          title: t("Cert."),
                          dataIndex: "txtCertificate",
                          width: window.innerWidth > 768 ? 40 : 12,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (row, item) => {
                            return (
                              <div className="infoicon__div">
                                <span
                                  className="linkText"
                                  onClick={() => handleCertificateOpen(item)}
                                >
                                  {item.txtCertificate}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Polish"),
                          dataIndex: "txtPolish",
                          width: 30,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Sym."),
                          dataIndex: "txtSymmetry",
                          width: 30,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Seller"),
                          dataIndex: "seller",
                          width: 35,
                          responsive: ["lg"],
                          key: "seller",
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (row, item) => {
                            return (
                              <div className="infoicon__div">
                                <span onClick={() => handleOk1(item)}>
                                  <i
                                    className="fa fa-comments"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.dealerID}{" "}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Location"),
                          dataIndex: "location",
                          width: 25,
                          responsive: ["lg"],
                          key: "location",
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                        },
                        {
                          title: t("Video"),
                          width: 30,
                          responsive: ["lg"],
                          render: (item, row) => {
                            return row.videoFileName ? (
                              <div className="infoicon__div">
                                <i
                                  onClick={() => {
                                    handleOpenVideoImageModal(row, true);
                                  }}
                                  className="fa fa-video-camera"
                                ></i>
                              </div>
                            ) : row.imageFileName ? (
                              <div className="infoicon__div">
                                <i
                                  className="fa fa-image linkText"
                                  onClick={() => {
                                    handleOpenVideoImageModal(row, false);
                                  }}
                                ></i>
                              </div>
                            ) : null;
                          },
                        },
                      ]}
                      loading={{ spinning: loading, size: "large" }}
                      onChange={handleTableASCDES}
                      dataSource={fancyDiamondList}
                      // rowSelection={rowSelection}
                      pagination={{
                        total: totalCount,
                        pageNo: pageNo,
                        current: pageNo,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        responsive: true,
                        showSizeChanger: true,
                      }}
                      scroll={{ x: 1200, y: 800 }}
                      rowKey={(record) => record.intDiamondId}
                    />
                  </div>
                  <div className="col-lg-12">
                    <Modal
                      title={t("View Details")}
                      className="modalvdiamond__section"
                      centered
                      visible={visible}
                      onOk={() => setVisible(false)}
                      onCancel={() => setVisible(false)}
                      width={1000}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => handleOk1(viewRow)}
                        >
                          {t("Contact Dealer")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-2 mt-4 text-center">
                                  <div className="viewdtls__left">
                                    <div>
                                      <img
                                        src={viewRow.imageBigShape}
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div>
                                      {viewRow.videoFileName ? (
                                        <i
                                          className="fa fa-video-camera mt-2 linkText"
                                          onClick={() => {
                                            handleOpenVideoImageModal(
                                              viewRow,
                                              true
                                            );
                                          }}
                                        ></i>
                                      ) : null}

                                      {viewRow.imageBigShape ? (
                                        <i
                                          class="fa fa-arrows-alt mt-6 linkText ml-2"
                                          aria-hidden="true"
                                          onClick={() => {
                                            showModalFullscreen(viewRow);
                                          }}
                                        ></i>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-10 mt-4">
                                  <div className="info__div">
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {t("GemFind")}#:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.intDiamondId}
                                        </span>
                                      </p>
                                      {dealerMemberTypeName !== "Vendor" &&
                                        dealerMemberTypeName !==
                                          "VendorDiamond" && (
                                          <p>
                                            <span className="head__txt">
                                              {t("Dealer Price")}
                                            </span>
                                            <span className="val__txt">
                                              {/* {selectedRowDetails.currencySign} */}
                                              {"$"}
                                              {viewRow.realPrice}
                                            </span>
                                          </p>
                                        )}
                                      <p>
                                        <span className="head__txt">
                                          {t("Stock No")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtStockNo}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Shape")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtShape}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Size")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.size}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Color")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtFancyColorMainBody}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Clarity")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtClarity}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Polish")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtPolish}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Symmetry")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtSymmetry}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Depth")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.fltDepth}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Table")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.fltTable}
                                        </span>
                                      </p>

                                      <p>
                                        <span className="head__txt">
                                          {t("Length*Width*Depth")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtMeasurements}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Treatment")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.treatment}
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {t("Lab")}:
                                        </span>{" "}
                                        <span
                                          className="val__txt linkText"
                                          onClick={() =>
                                            handleCertificateOpen(viewRow)
                                          }
                                        >
                                          {viewRow.txtCertificate}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Report Comment")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.reportComment}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Key to Symbol")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.keyToSymbol}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Girdle")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtgridle}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Ratio")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.ratio}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Location")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.location}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Retail Price")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow?.currencySign || "$"}{" "}
                                          {viewRow.fltPrice
                                            ? parseInt(viewRow.fltPrice)
                                            : ""}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Member Comment")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.memberComment}
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {t("Seller")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.dealerCompany}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Stock")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.txtStockNo}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Phone")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.dealerPhone}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Location")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.dealerLocation}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {t("Updated")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {viewRow.modified}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* Modal Request Certifiacate  */}

                    <Modal
                      title={t("Request Diamond Certificate")}
                      visible={requestCertModal}
                      style={{ top: 70 }}
                      className="request_info"
                      onCancel={closeCertInfoModal}
                      footer={[
                        <Button key="back" onClick={closeCertInfoModal}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          loading={requestCertModalLoading}
                          type="primary"
                          onClick={handleSubmitRequestCert}
                        >
                          {t("Request")}
                        </Button>,
                      ]}
                    >
                      <div className="row">
                        <div class="col-md-12">
                          <p className="mb-1">
                            {t(
                              "The report for this diamond is not viewable online."
                            )}
                          </p>
                          <p>
                            {t(
                              "Please contact us by telephone or the form below if you are interested in receiving more information about this diamond."
                            )}
                          </p>
                          <div className="table__block">
                            <div className="row p-0 border-0 form__fields mh-auto">
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("Name")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.name}
                                    className={
                                      certInfoDataValidation.nameVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(e, "name", "input")
                                    }
                                  />
                                  {certInfoDataValidation.nameVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.nameVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("E-Mail Address")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.email}
                                    className={
                                      certInfoDataValidation.emailVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(e, "email", "input")
                                    }
                                  />
                                  {certInfoDataValidation.emailVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.emailVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("Phone Number")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.phoneNumber}
                                    className={
                                      certInfoDataValidation.phoneNumberVAl &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(
                                        e,
                                        "phoneNumber",
                                        "input"
                                      )
                                    }
                                  />
                                  {certInfoDataValidation.phoneNumberVAl && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.phoneNumberVAl}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="input__block">
                                  <label>
                                    {t("Comments")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <textarea
                                    value={certInfoData.comments}
                                    className={
                                      certInfoDataValidation.commentsVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(
                                        e,
                                        "comments",
                                        "input"
                                      )
                                    }
                                  ></textarea>
                                  {certInfoDataValidation.commentsVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.commentsVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* End */}

                    <Modal
                      title={
                        <React.Fragment>
                          <h4 className="d-flex mb-0">
                            {t("Message to")}
                            <h4 className="linkText mx-2 mb-0">
                              {selectedDealer ? selectedDealer : ""}
                            </h4>
                          </h4>
                        </React.Fragment>
                      }
                      className="modalvdiamond__section"
                      centered
                      visible={visible1}
                      onOk={() => setVisible1(false)}
                      onCancel={() => setVisible1(false)}
                      width={600}
                      footer={[
                        <Button key="back" onClick={handleCancel1}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleMsgValidation}
                          loading={msgLoading}
                        >
                          {t("Send")}
                        </Button>,
                      ]}
                    >
                      <Spin spinning={companyLogoLoading}>
                        <div className="col-lg-12">
                          <div className="modal__body">
                            <div className="col-lg-12">
                              <div className="form__fields border-0 p-0">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <p>
                                      {t("Contact the dealer regarding")}.{" "}
                                      <a href="#">
                                        {selectedRowDetails.txtStockNo} .
                                      </a>
                                    </p>
                                  </div>
                                  <div className="col-lg-12">
                                    <div class="input__block">
                                      <textarea
                                        id=""
                                        name=""
                                        rows="6"
                                        cols="50"
                                        placeholder={t(
                                          "Write your message here..."
                                        )}
                                        class=""
                                        value={msgData}
                                        onChange={(e) => {
                                          setMsgData(e.target.value);
                                          setMsgError("");
                                        }}
                                      ></textarea>

                                      {msgError ? (
                                        <div className="text-danger">
                                          {msgError}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </Modal>
                    <Modal
                      title={t("Video")}
                      className="modalvdiamond__section"
                      centered
                      visible={visible2}
                      onOk={() => setVisible2(false)}
                      onCancel={() => setVisible2(false)}
                      width={600}
                      footer={[
                        <Button key="back" onClick={handleCancel2}>
                          {t("Close")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 mt-4">
                                  <iframe
                                    id="VideoIframesrc"
                                    src="https://certimage.s3-accelerate.amazonaws.com/V360_viewers/v4.0/Vision360.html?surl=https://certimage.s3-accelerate.amazonaws.com/V360/_v4.0/&amp;d=YG-2493&amp;sv=0,1,2,3,4,5"
                                    class="VideoIframe"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* View DiamondImage Modal */}

                    <Modal
                      title=""
                      visible={isModalVisibleFullscreen}
                      className="fullscreen__modal"
                      onCancel={handleCancelFullscreen}
                      footer={false}
                      width={500}
                      //style={{ top: 30 }}
                    >
                      <div className="thimage__slider text-center">
                        <img
                          src={
                            selectedRowDetails.imageBigShape
                              ? selectedRowDetails.imageBigShape
                              : ""
                          }
                          alt=""
                          className="img-fluid"
                          height={"400px"}
                          width="300"
                        />
                      </div>
                    </Modal>
                    {/* End */}

                    {/* Video and Image View Modal */}
                    <Modal
                      className="modalconsupld__section"
                      centered
                      title={t("Video")}
                      width={500}
                      visible={videoImageModal}
                      onCancel={() => handleCloseVideoImageModal()}
                      footer={[
                        <Button onClick={() => handleCloseVideoImageModal()}>
                          {t("Cancel")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                {isVideo ? (
                                  selectedRowDetails.videoFileName ? (
                                    selectedRowDetails.videoFileName.includes(
                                      "html"
                                    ) ? (
                                      <iframe
                                        src={selectedRowDetails.videoFileName}
                                        height={"400px"}
                                        width="300"
                                        title="diamond-video"
                                      ></iframe>
                                    ) : (
                                      <video
                                        title="diamond-video"
                                        className="VideoIframe"
                                        src={selectedRowDetails.videoFileName}
                                        controls
                                        id="product_video"
                                      ></video>
                                    )
                                  ) : (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  )
                                ) : selectedRowDetails.imageFileName ? (
                                  <img
                                    src={selectedRowDetails.imageFileName}
                                    alt="img-diamond"
                                  />
                                ) : (
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* Image zoom modal */}
                    <Modal
                      title={isEmail ? "Email Diamond Certificate" : "Image"}
                      className="modalvdiamond__section image__zoommodal"
                      centered
                      visible={visible3}
                      onOk={() => setVisible3(false)}
                      onCancel={() => setVisible3(false)}
                      width={600}
                      footer={
                        isEmail
                          ? null
                          : [
                              <Button key="back"> {t("Print")} </Button>,
                              <Button
                                key="submit"
                                type="primary"
                                onClick={handleOpenIsEmail}
                              >
                                {t("Email")}
                              </Button>,
                            ]
                      }
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              {isEmail ? (
                                <React.Fragment>
                                  <div className="form__fields">
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label> {t("Name")}: </label>
                                          <input
                                            type={"text"}
                                            name="Name"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.Name}
                                          />
                                          {errorEmailCertificat.Name ? (
                                            <div className="text-danger">
                                              {errorEmailCertificat["Name"]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label> {t("Email")}: </label>
                                          <input
                                            type={"text"}
                                            name="Email"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.Email}
                                          />
                                          {errorEmailCertificat.Email ? (
                                            <div className="text-danger">
                                              {errorEmailCertificat["Email"]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="input__block">
                                          <label>{t("Friend's Name")}: </label>
                                          <input
                                            type={"text"}
                                            name="FriendsName"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.FriendsName}
                                          />
                                          {errorEmailCertificat.FriendsName ? (
                                            <div className="text-danger">
                                              {
                                                errorEmailCertificat[
                                                  "FriendsName"
                                                ]
                                              }
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label>{t("Friend's Email")}: </label>
                                          <input
                                            type={"text"}
                                            name="FriendsEmail"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.FriendsEmail}
                                          />
                                          {errorEmailCertificat.FriendsEmail ? (
                                            <div className="text-danger">
                                              {
                                                errorEmailCertificat[
                                                  "FriendsEmail"
                                                ]
                                              }
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label>{t("Comments")}: </label>

                                          <textarea
                                            name="Comments"
                                            value={emailCertificat.Comments}
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            rows="6"
                                            cols="50"
                                            placeholder={t("Enter Comments")}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-lg-12 ">
                                      <Button
                                        type={"primary"}
                                        loading={EmailLoading}
                                        onClick={() =>
                                          handleCertificateEmailSend()
                                        }
                                      >
                                        {t("Submit")}
                                      </Button>
                                      <Button
                                        onClick={handleCloseIsEmail}
                                        className="ml-2"
                                      >
                                        {t("Cancel")}
                                      </Button>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-12 mt-4">
                                    <div className="image__zoom">
                                      <img
                                        src={selectedRowDetails.imageFileName}
                                        alt="cer-img"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Send Modal */}
                    <Modal
                      className="modalconsupld__section"
                      centered
                      visible={visible4}
                      onOk={() => setVisible4(false)}
                      onCancel={() => setVisible4(false)}
                      width={450}
                      footer={[
                        <Button key="back" onClick={handleCancel4}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={emailSendLoading}
                          onClick={() => handleSendDetailsModal()}
                        >
                          {t("Send")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 className="d-flex mb-0">
                                    {t("Send")}
                                    <h4 className="linkText mx-2 mb-0">
                                      {isSelectAll
                                        ? "All"
                                        : selectedRows.length}
                                    </h4>
                                    {t("Diamonds")}
                                  </h4>
                                  <p>
                                    {t(
                                      "Your recipient will recieve the details on your selected diamonds"
                                    )}
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Name")} </label>
                                    <input
                                      type="text"
                                      placeholder={t("Enter Name")}
                                      name="Name"
                                      value={sendDetails.Name || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    />
                                    {errorSendDetails.Name ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Name"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Email")} </label>
                                    <input
                                      type="email"
                                      placeholder={t("Enter Email")}
                                      name="Email"
                                      value={sendDetails.Email || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    />
                                    {errorSendDetails.Email ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Email"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Markup")} </label>
                                    <div className="row">
                                      <div className="col-12">
                                        <input
                                          type="text"
                                          placeholder={t("Enter Markup")}
                                          name="Markup"
                                          value={sendDetails.Markup || ""}
                                          onChange={(e) =>
                                            handleSendDetailsChange(e)
                                          }
                                        />
                                        <span className="markup__symbol">
                                          %
                                        </span>
                                        {errorSendDetails.Markup ? (
                                          <div className="text-danger">
                                            {errorSendDetails["Markup"]}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Message")} </label>
                                    <textarea
                                      placeholder={t("Enter Message")}
                                      name="Message"
                                      value={sendDetails.Message || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    ></textarea>
                                    {errorSendDetails.Message ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Message"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Delete Conformation Modal */}
                    <Modal
                      title={t("Delete")}
                      className="modalconsupld__section"
                      centered
                      visible={deleteModal}
                      onOk={() => handleDeleteConfirmation()}
                      onCancel={() => handleDeleteModalClose()}
                      width={400}
                      footer={[
                        <Button key="back" onClick={handleDeleteModalClose}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          loading={sumbitLoding}
                          key="submit"
                          type="primary"
                          onClick={() => {
                            handleDeleteConfirmation();
                          }}
                        >
                          {t("Delete")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <p>
                                    {t("Are you sure to delete this diamond")}{" "}
                                  </p>
                                  <h5>{t("THIS CAN NOT BE UNDONE")} </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* After Delete Modal PopUp Message */}

                    <Modal
                      title={" "}
                      className="modalconsupld__section"
                      centered
                      visible={visible6}
                      width={400}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => handleOnOk()}
                        >
                          {t("OK")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <p>
                                    {t(
                                      "Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed."
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      title={t("Not Authorized")}
                      className="modalconsupld__section"
                      centered
                      visible={visible5}
                      onCancel={() => setVisible5(false)}
                      width={400}
                      footer={[
                        <Button
                          type="primary"
                          key="back"
                          onClick={handleCancel5}
                        >
                          {t("Ok")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <h5>
                                    #
                                    {selectedRows
                                      .map((x) => x.intDiamondId)
                                      .join(",")}{" "}
                                    {t(
                                      "Not deleted as those are not your diamonds."
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="printsection" style={{ padding: "10px", display: "none" }}>
            <div className="print__section">
              <div className="row">
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="header__logo">
                      <img src={logoImg} style={{ width: "160px" }} />
                    </div>
                    <div className="header__text">
                      <h3 className="subheading">Diamond List</h3>
                    </div>
                    <div className="header__date">
                      <p>
                        {new Date().getMonth() +
                          1 +
                          "/" +
                          new Date().getDate() +
                          "/" +
                          new Date().getFullYear()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 mt-4">
                  <div className="table__block">
                    <div className="table__scroll">
                      <table
                        class="table table-bordered table-striped"
                        cellSpacing={0}
                        cellPadding={0}
                        border={"1px solid black"}
                      >
                        <thead
                          class="thead-light"
                          style={{ backgroundColor: "rgb(222, 222, 222)" }}
                        >
                          <tr>
                            <th style={{ padding: "2px" }} width={"15%"}>
                              Stock #
                            </th>
                            <th style={{ padding: "2px" }} width={"5%"}>
                              Shape
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Size
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Color
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Clarity
                            </th>

                            <th style={{ padding: "2px" }} width={"15%"}>
                              Cost
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Fluor.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Dep.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Table
                            </th>
                            <th style={{ padding: "2px" }} width={"15%"}>
                              L * D * W
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Cert.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Polish
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Sym.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Dealer
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Video
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fancyDiamondList.map((item, i) => {
                            return (
                              <React.Fragment>
                                <tr
                                  style={{
                                    backgroundColor:
                                      i % 2 == 0 ? "#efefef" : "white",
                                  }}
                                >
                                  <td cells>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtStockNo}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtShape}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltCarat}
                                    </p>
                                  </td>

                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtFancyColorMainBody}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtClarity}
                                    </p>
                                  </td>

                                  <td>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {currencySymbol + parseInt(item.fltPrice)}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtFluorescence}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltDepth + "%"}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltTable + "%"}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtMeasurements}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtCertificate}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtPolish}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtSymmetry}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.dealerID}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.videoFileName ? (
                                        <div className="infoicon__div">
                                          <img
                                            src={videoIcon}
                                            style={{
                                              width: "20px",
                                              padding: "5px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className="bottom__footer"
                    style={{
                      background: "#e9e8ea",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <img src={footerlogoImg} style={{ width: "160px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ViewMyColorDiamond;
