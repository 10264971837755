import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  Checkbox,
  Table,
  Menu,
  Dropdown,
  Button,
  Modal,
  Pagination,
  Spin,
  Slider,
  Empty,
  TreeSelect,
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import Upload_icon from "../../assets/images/upload_icon.png";
import Ellipsis_icon from "../../assets/images/ellipsis_icon.png";
import Filter_icon from "../../assets/images/filter_icon.png";
import Info_icon from "../../assets/images/info_icon.png";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import Eye_icon from "../../assets/images/icons/eye_icon.svg";
import Close from "../../assets/images/close.png";
import { Link, useNavigate } from "react-router-dom";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import space__icon from "../../assets/images/space__icon.png";
import { useTranslation } from "react-i18next";
import {
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
  setEditTierMenu,
  setSelectedVendor,
} from "../../actions/headerMenu/headerMenu";
import {
  setManageInventoryVendorId,
  setManageTabName,
  setVendorProfilePageName,
} from "../../actions/Community/ManageInventory";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import AdminManageCurrencyService from "../../services/admin-manageCurrency.service";
import { setCurrencySign } from "../../actions/login/login";

const { TreeNode } = TreeSelect;
const { Option, OptGroup } = Select;

const Allproducts = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [viewItem, setViewItem] = useState("grid");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const initialState = {
    Catagory: [],
    MaterialColor: [],
    MaterialType: [],
    Gender: [],
    Collection: [],
    Vendor: [],
    PriceMin: 0,
    PriceMax: 9999999,
    Image: "",
    PageNumber: 1,
    NoOfRow: 10,
    disabled: {},
    productChange: {},
    gridCheckList: {},
  };
  const [state, setState] = useState(initialState);
  const [deleteMessageModal, setDeleteMessageModal] = useState(false);
  const [minVal, setMinVal] = useState("");
  const [maxVal, setMaxVal] = useState("");
  const [DealerId, setDealerId] = useState(null);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency
    ? currency?.substring(currency.lastIndexOf("-") + 1)
    : "";
  const currencySign = useSelector((state) => state.loginReducer.currencySign);
  const HeaderDetails = useSelector((state) => state.headerMenuReducer);

  const [ViewProductList, setViewProductList] = useState([]);
  const [CategotyList, setCategotyList] = useState([]);
  const [MaterialColor, setMaterialColor] = useState([]);
  const [MaterialType, setMaterialType] = useState([]);
  const [GenderList, setGenderList] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [VendorList, setVendorList] = useState([]);
  const [ImageList, setImageList] = useState([]);
  const [applyLoading, setApplyLoading] = useState(false);
  const resetRange = useRef(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [newCheck, setNewCheck] = useState(false);
  const [btnValue, setBtnValue] = useState(1);
  const [featureCheck, setFeatureCheck] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [selectActionRow, setSelectActionRow] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [selectAll, setSelectAll] = useState(null);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [videoInfo, setVideoInfo] = useState("");
  const [videoInfoLoading, setVideoInfoLoading] = useState(false);
  const [searchValue, SetSearchValue] = useState("");
  const [sortColumnName, setSortColumnName] = useState("GFInventoryID");
  const [sortType, setSortType] = useState("ASC");
  const [AttributesSets, setAttributesSets] = useState([]);
  const [dealerMembershipType, setDealerMembershiptype] = useState("");
  const [applyFilterLoading, setApplyFilterLoading] = useState(false);
  const [toggleFilters, setToggleFilter] = useState(false);
  const { Search } = Input;
  const [filterBtnVisble, setFilterBtnVisble] = useState(true);
  const [selectDrp, setSelectDrp] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filteredMaterialColor, setFilteredMaterialColor] = useState([]);
  const [filteredMaterialType, setFilteredMaterialType] = useState([]);
  const [filteredGender, setFilteredGender] = useState([]);
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [filteredVendor, setFilteredVendor] = useState([]);
  const [FourTierPages, setFourTierPages] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState([]);

  // Add to WorkSheet

  const [workSheetModal, setWorkSheetModal] = useState(false);
  const [workSheetData, setWorksheetData] = useState({
    workSheetName: "",
  });
  const [vendorData, setVendorData] = useState([]);
  const [workSheetLoading, setWorkSheetLoading] = useState(false);
  const [workSheetNamedata, SetWorkSheetNamedata] = useState({
    workSheetName: "",
  });
  const [switchCount, setSwitchCount] = useState(0);
  const [poEnebledList, setPoEnebledList] = useState([]);
  const [poEnebledListData, setPoEnebledListData] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [workSheetKey, setWorkSheetKey] = useState("");
  const [workSheetKeyId, setWorkSheetKeyId] = useState("");
  const [linkCount, setLinkCount] = useState(0);
  const [priceValueChange, setPriceValueChange] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const handleOnChange = (e, isSelect, name, key) => {
    if (isSelect === "select") {
      setWorksheetData({
        ...workSheetData,
        [name]: e,
      });
      setWorkSheetKey(key.key);
    } else {
      setWorksheetData({
        ...workSheetData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleWorksheetOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      SetWorkSheetNamedata({
        ...workSheetNamedata,
        [name]: e,
      });
    } else {
      SetWorkSheetNamedata({
        ...workSheetNamedata,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCancelWorkSheetModal = () => {
    setWorkSheetModal(false);
    setWorksheetData({
      workSheetName: "",
    });
    SetWorkSheetNamedata({
      workSheetName: "",
    });
  };

  const handelWorkSheetModal = () => {
    if (poEnebledListData.length > 0) {
      setWorkSheetModal(true);
      handleGetWorksheetDetails();
    } else {
      NotificationManager.error(
        t("Please select jewelry from list to create PO")
      );
    }
  };

  const handleRedirection = (id, name) => {
    navigate("/workSheetDetails", {
      state: {
        id: id ? id : 0,
        name: name ? name : "",
      },
    });
  };

  // API Add product

  const handleCreateNewWorksheerdetils = () => {
    if (switchCount == 1 && workSheetNamedata?.workSheetName?.trim() == "") {
      NotificationManager.error(t("WorkSheet should not be empty"));
      return false;
    } else if (switchCount != 1 && workSheetKey == "") {
      NotificationManager.error(t("WorkSheet should not be empty"));
      return false;
    }
    let inputData = {
      dealerId: DealerId,
      workSheetId:
        workSheetNamedata.workSheetName != ""
          ? 0
          : workSheetData.workSheetName
          ? workSheetData.workSheetName
          : 0,
      worksheetname: workSheetNamedata.workSheetName
        ? workSheetNamedata.workSheetName
        : "",
      products: poEnebledListData.toString() + ",",
      vendorName: filteredVendor.toString(),
    };
    setUpdateLoading(true);
    try {
      PurchaseOrderServices.CreateNewWorksheerdetils(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.returnMessage == "not added Already Exist") {
              setUpdateLoading(false);
              NotificationManager.error(t("Not Added to WorkSheet."));
              setLinkCount(0);
              setWorkSheetModal(false);
              SetWorkSheetNamedata({
                workSheetName: "",
              });
            } else if (responseData.returnMessage == "Already Exist") {
              setUpdateLoading(false);
              NotificationManager.error(t("WorkSheet Already Exist."));
              setLinkCount(0);
              SetWorkSheetNamedata({
                workSheetName: "",
              });
            } else {
              setUpdateLoading(false);
              NotificationManager.success(
                t("Product are Successfully Added to the WorkSheet, Not a PO.")
              );
              setWorkSheetKeyId(responseData.workSheetIdMain);
              setWorkSheetKey(responseData.workSheetNameById);
              setLinkCount(1);
              SetWorkSheetNamedata({
                workSheetName: "",
              });
              setWorkSheetModal(false);
            }
          } else {
            setUpdateLoading(false);
            NotificationManager.error(t("Not Added to WorkSheet."));
            setLinkCount(0);
            setWorkSheetModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setUpdateLoading(false);
          setWorkSheetModal(false);
        });
    } catch (error) {
      console.log(error);
      setUpdateLoading(false);
      setWorkSheetModal(false);
    }
  };

  const handleSwitchInputs = () => {
    setSwitchCount(1);
  };

  const handleOnOk = () => {
    setDeleteMessageModal(false);
  };

  const handleCloseWorkSheetModal = () => {
    setWorkSheetModal(false);
    setSwitchCount(0);
  };
  useEffect(() => {
    // (async () => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        if (loginDetials.responseData.logInSucceeded) {
          handleGetSelectCurrencyForDelar(loginDetials.responseData.dealerId);
          setDealerId(loginDetials.responseData.dealerId);
          setDealerMembershiptype(
            loginDetials.responseData.dealerMemberTypeName
          );
          handleGetJewelryDataList(
            loginDetials.responseData.dealerId,
            "loadTrue",
            null,
            "setImage"
          );
        }
      }
    }
    // })();
  }, []);

  useEffect(() => {
    if (HeaderDetails) {
      if (HeaderDetails.sideMenuTierFourDetails) {
        setFourTierPages(HeaderDetails.sideMenuTierFourDetails);
      }
      if (HeaderDetails.vendorSelected) {
        setState((prevState) => ({
          ...prevState,
          Vendor: Array.isArray(HeaderDetails.vendorSelected)
            ? HeaderDetails.vendorSelected
            : [HeaderDetails.vendorSelected],
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (toggleFilters) {
      handleViewProduct(
        loginDetials.responseData.dealerId,
        searchValue,
        pageNo,
        pageSize,
        sortColumnName,
        sortType
      );
    }
  }, [toggleFilters]);

  const onSearch = (value) => {
    SetSearchValue(value);
    handleViewProduct(
      DealerId,
      value,
      pageNo,
      pageSize,
      sortColumnName,
      sortType
    );
  };

  const handleOnCheckGridAll = (e) => {
    setSelectAll(e.target.checked);
    var tempViewProductList = ViewProductList;
    tempViewProductList.forEach((element) => {
      element.isChecked = e.target.checked;
    });
    setViewProductList([...tempViewProductList]);
  };

  function formatMoney(amount) {
    try {
      if (amount?.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
        // && Number(amount).toLocaleString("en-US")
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  const handleOnCheckGrid = (e, id) => {
    var tempViewProductList = [];
    let tempArray = [...checkBoxValue];

    if (e.target.checked) {
      tempArray.push(id);
    } else {
      let index = tempArray.indexOf(id);
      tempArray.splice(index, 1);
    }
    setCheckBoxValue(tempArray);

    tempViewProductList = ViewProductList.map((x) => {
      if (x.gfInventoryID === id) {
        return { ...x, isChecked: e.target.checked };
      } else return x;
    });
    setViewProductList(tempViewProductList);
    const testBoolean = tempViewProductList.every((m) => {
      if (m.isChecked == 0) {
        return false;
      }
      return true;
    });
    setSelectAll(testBoolean);

    var tempPoEnebledList = [];

    tempPoEnebledList = tempViewProductList.map((x) => {
      if (x.isChecked === true) {
        return x.gfInventoryID + "#" + "$" + x.retailPrice;
      } else {
        return;
      }
    });
    setPoEnebledList(tempPoEnebledList);

    let results = tempPoEnebledList.filter(
      (element) => element !== null && element !== undefined
    );
    const combinedList = [...new Set([...poEnebledListData, ...results])];

    setPoEnebledListData(combinedList);
  };
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }

    handleViewProduct(
      DealerId,
      searchValue,
      pageNo,
      pageSize,
      column,
      type,
      true
    );
  };

  const onChangeDrop = (e, name) => {
    setSelectDrp(name);
    setFilterBtnVisble(false);
    let tempState = state;
    tempState[name] = e;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));

    handleGetJewelryDataList(DealerId, "", name, "");
  };

  const onChangeMultiDrop = (e, name) => {
    let isRemove = false;
    if (e.length > state[name].length) {
      isRemove = false;
    } else {
      isRemove = true;
    }

    setSelectDrp(name);
    setFilterBtnVisble(false);
    let tempState = state;
    if (e.includes("")) {
      tempState.disabled[name] = true;
      tempState[name] = [];
      tempState[name].push("");
    } else {
      tempState.disabled[name] = false;
      tempState[name] = e;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleGetJewelryDataList(DealerId, "", name, "", isRemove);
  };

  const onResetFilter = () => {
    SetSearchValue("");
    let tempState = state;
    setMinVal(0);
    setMaxVal(10000);
    tempState["Catagory"] = [];
    tempState["MaterialColor"] = [];
    tempState["MaterialType"] = [];
    tempState["Gender"] = [];
    tempState["Collection"] = [];
    tempState["Vendor"] = [];
    tempState["Image"] = "";
    tempState.disabled["Catagory"] = false;
    tempState.disabled["MaterialColor"] = false;
    tempState.disabled["MaterialType"] = false;
    tempState.disabled["Gender"] = false;
    tempState.disabled["Collection"] = false;
    tempState.disabled["Vendor"] = false;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setFilteredCategory([]);
    setFilteredMaterialColor([]);
    setFilteredMaterialType([]);
    setFilteredCollection([]);
    setFilteredGender([]);
    setFilteredCollection([]);
    setFilteredVendor([]);
    var obj = priceValue;
    obj[0] = state.PriceMin;
    obj[1] = state.PriceMax;
    setPriceValue([state.PriceMin, state.PriceMax]);
    handleGetJewelryDataList(DealerId, "loadTrue", null, "setImage");
  };

  const handleGetJewelryDataList = (id, val, name, Imageset, isRemove) => {
    let inputData = {
      dealerID: id.toString(),
      orderby: "GFInventoryID",
      order: "ASC",
      category: state.Catagory.length > 0 ? state.Catagory.toString() : "",
      vendor: HeaderDetails.vendorSelected
        ? HeaderDetails.vendorSelected
        : state.Vendor.length > 0
        ? state.Vendor.toString()
        : "",
      materialType:
        state.MaterialType.length > 0 ? state.MaterialType.toString() : "",
      gender: state.Gender.length > 0 ? state.Gender.toString() : "",
      collection:
        state.Collection.length > 0 ? state.Collection.toString() : "",
      collectionID: "",
      materialColor:
        state.MaterialColor.length > 0 ? state.MaterialColor.toString() : "",
      minRange:
        priceValueChange == false
          ? ""
          : priceValue[0]
          ? priceValue[0].toString()
          : "",
      maxRange:
        priceValueChange == false
          ? ""
          : priceValue[1]
          ? priceValue[1].toString()
          : "9999999",
      styleNumber: "",
      dealerType:
        props.type == "MergeProduct"
          ? "MergeProduct"
          : props.type == "AllProduct"
          ? "All"
          : "MyProduct",
      noOfRows: "50",
      offset: "0",
      gemstoneType: "",
      FlgComm_VR: "",
      strSelectedInvID: "",
      image: name === "Image" && state.Image ? state.Image + "" : "",
    };
    setApplyFilterLoading(true);
    try {
      CatalogJewelryDataService.GetJewelryDataList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.result;
          if (message === "Success") {
            if (name == "Catagory") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(CategotyList);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }
            if (name == "MaterialColor") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(MaterialColor);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }

            if (name == "MaterialType") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(MaterialType);
              setVendorList(responseData.vendorBrand);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }

            if (name == "Gender") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(GenderList);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }

            if (name == "Collection") {
              var tempAttributesSets = [
                ...new Set(
                  CollectionList.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(CollectionList);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }

            if (name == "Vendor") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(VendorList);
              if (Imageset == "setImage") {
                if (responseData.jewelryHasImages.length > 0) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: "",
                  }));
                }
              }
            }

            if (name == "Image") {
              var tempAttributesSets = [
                ...new Set(
                  responseData.jewelryCollections.map(
                    ({ collectionLabelValues }) => collectionLabelValues
                  )
                ),
              ];
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(ImageList);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
            }

            if (name == null) {
              if (responseData.jewelryCollections.length > 0) {
                var tempAttributesSets = [
                  ...new Set(
                    responseData.jewelryCollections.map(
                      ({ collectionLabelValues }) => collectionLabelValues
                    )
                  ),
                ];
              }
              setAttributesSets(tempAttributesSets);
              setCategotyList(responseData.jewelryCategory);
              setCollectionList(responseData.jewelryCollections);
              setGenderList(responseData.jewelryGenders);
              setImageList(responseData.jewelryHasImages);
              setMaterialColor(responseData.jewelryMetalColors);
              setMaterialType(responseData.jewelryMetalTypes);
              setVendorList(responseData.vendorBrand);
              if (responseData.jewelryHasImages.length > 0) {
                let indexImage = responseData.jewelryHasImages.findIndex(
                  (x) =>
                    x.hasImageValues
                      .replace(/\d+/g, "")
                      .replace(/\(\s*\)/, "")
                      .trim() === "Has Valid Image"
                );
                if (indexImage !== -1) {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[indexImage].hasImage,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    Image: responseData.jewelryHasImages[0].hasImage,
                  }));
                }
              } else {
                setState((prevState) => ({
                  ...prevState,
                  Image: "",
                }));
              }
            }

            if (val == "loadTrue") {
              setToggleFilter(true);
            }
            // }
            setApplyFilterLoading(false);
          } else {
            setCategotyList([]);
            setCollectionList([]);
            setGenderList([]);
            setImageList([]);
            setMaterialColor([]);
            setMaterialType([]);
            setVendorList([]);
            setApplyFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setApplyFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setApplyFilterLoading(false);
    }
  };

  const handleViewMore = (id) => {
    dispatch(setManageTabName("Manage"));
    dispatch(setManageInventoryVendorId(id));
    dispatch(setVendorProfilePageName("Profile"));
    window.open("/vendorProfile", "_blank");
  };

  const handleViewProduct = (
    id,
    value,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    sortingOnly
  ) => {
    if (sortingOnly) {
      setTableLoading(true);
    } else {
      setApplyLoading(true);
    }
    let inputData = {
      dealerID: id,
      orderby: sortColumnName || "GFInventoryID",
      pageNumber: pageNo,
      order: sortOrder || "ASC",
      category: state.Catagory.join(",") ? state.Catagory.join(",") : "",
      vendor: HeaderDetails.vendorSelected
        ? HeaderDetails.vendorSelected
        : Array.isArray(state.Vendor)
        ? state.Vendor.join(",")
        : "",
      materialType: state.MaterialType.join(",")
        ? state.MaterialType.join(",")
        : "",
      gender: state.Gender.join(",") ? state.Gender.join(",") : "",
      collection: state.Collection.join(",") ? state.Collection.join(",") : "",
      collectionIDs: "",
      materialColor: state.MaterialColor.join(",")
        ? state.MaterialColor.join(",")
        : "",
      minRange: priceValueChange == false ? "" : priceValue[0].toString(),
      maxRange: priceValueChange == false ? "" : priceValue[1].toString(),
      styleNumber: value + "",
      dealerType:
        props.type == "MergeProduct"
          ? "MergeProduct"
          : props.type == "AllProduct"
          ? "All"
          : "MyProduct",
      noOfRows: pageSize + "",
      offset: "0",
      strSelectedInvID: "",
      hasImage: state.Image ? state.Image + "" : "",
      viewBy: "2",
    };

    try {
      CatalogJewelryDataService.ViewProduct(inputData, props.type)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          setTableLoading(false);
          if (message === "Success") {
            if (responseData && responseData?.length > 0) {
              dispatch(setSelectedVendor(""));
              const temp = responseData.map((x) => {
                return { ...x, isChecked: false };
              });
              setViewProductList(temp);
              if (props.type == "MergeProduct") {
                setTotalRowCount(responseData[0].totalJewelryCount);
              } else {
                setTotalRowCount(responseData[0].jewelryTotalRecords);
              }

              setSelectAll(false);
              setToggleFilter(false);
              setFilterBtnVisble(true);
            } else {
              setSelectAll(false);
              setTotalRowCount(0);
              setViewProductList([]);
              setFilterBtnVisble(true);
              dispatch(setSelectedVendor(""));
            }
          } else {
            setViewProductList([]);
            setFilterBtnVisble(true);
            dispatch(setSelectedVendor(""));
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          setTableLoading(false);
          dispatch(setSelectedVendor(""));
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      setTableLoading(false);
      dispatch(setSelectedVendor(""));
      console.log(error);
    }
  };

  const handleUpdateProductFlag = (
    id,
    ProductId,
    flag,
    Value,
    VendorId,
    type
  ) => {
    let inputData = {
      dealerID: id + "" || DealerId + "",
      productID: ProductId + "",
      flagValue: flag,
      value: Value,
      vendorId: VendorId + "",
      type: type ? type.toString() : "",
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.UpdateProductFlag(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully."));
          } else {
            NotificationManager.error(t("Data Not Updated."));
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      console.log(error);
    }
  };

  const handleAllNewFeature = (e, name) => {
    let value = "0";
    let arrIds = [];
    for (let i = 0; i < ViewProductList.length; i++) {
      arrIds.push(ViewProductList[i].gfInventoryID);
    }
    let IDs = arrIds.join(",");

    if (e.target.checked) {
      value = "1";
    }
    if (name === "NW") {
      setNewCheck(e.target.checked);
      const temp = ViewProductList.map((x) => {
        return { ...x, isNew: e.target.checked };
      });
      setViewProductList(temp);
    }
    if (name === "FE") {
      setFeatureCheck(e.target.checked);
      const temp = ViewProductList.map((x) => {
        return { ...x, isFeatured: e.target.checked };
      });
      setViewProductList(temp);
    }
    handleUpdateProductFlag(DealerId, IDs, name, value, "0", "All");
  };

  const handleOnEditChange = (row, e, name) => {
    let value = "0";
    if (e.target.checked) {
      value = "1";
    }

    if (name == "NW") {
      const temp = ViewProductList.map((x) => {
        if (x.gfInventoryID == row.gfInventoryID) {
          return { ...x, isNew: e.target.checked };
        } else return x;
      });
      setViewProductList(temp);
    }
    if (name == "FE") {
      const temp = ViewProductList.map((x) => {
        if (x.gfInventoryID == row.gfInventoryID) {
          return { ...x, isFeatured: e.target.checked };
        } else return x;
      });
      setViewProductList(temp);
    }

    handleUpdateProductFlag(DealerId, row.gfInventoryID, name, value, DealerId);
  };

  const handleDeleteJewelry = (id) => {
    if (deleteType === "Multi") {
      var dataLength = ViewProductList.filter(
        (x) => x.isChecked === true
      ).length;
      var tempdeleteProductStr = ViewProductList.filter(
        (x) => x.isChecked === true
      )
        .map((x) => x.gfInventoryID)
        .join(",");
    } else if (deleteType === "Single") {
      var dataLength = 1;
      var tempdeleteProductStr = deleteId;
    }
    let inputData = {
      dealerID: id + "" || DealerId + "",
      statusChangeArray: "",
      deleteProduct: selectAll ? "All" : tempdeleteProductStr,
      status: "",
      jewelryIdList: tempdeleteProductStr,
      statusUncheckedArray: "",
      statusUncheckedArraySpecific: "",
      objFilters: {
        category: state.Catagory.join(",") ? state.Catagory.join(",") : "",
        materialType: state.MaterialType.join(",")
          ? state.MaterialType.join(",")
          : "",
        gender: state.Gender.join(",") ? state.Gender.join(",") : "",
        collectionIDs: state.Collection.join(",")
          ? state.Collection.join(",")
          : "",
        materialColor: state.MaterialColor.join(",")
          ? state.MaterialColor.join(",")
          : "",
        minRange: priceValue[0]
          ? priceValue[0] == "0"
            ? ""
            : priceValue[0].toString()
          : "0",
        maxRange: priceValue[1]
          ? priceValue[1] == "9999999"
            ? ""
            : priceValue[1].toString()
          : "9999999",
        styleNumber: searchValue.toString(),
        hasImage: state.Image ? state.Image.toString() : "",
      },
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.UpdateJewelryStatusOrDeleteJewelry(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Product Deleted Successfully."));
            setVisible3(false);
            setDeleteMessageModal(true);
            handleGetJewelryDataList(
              loginDetials.responseData.dealerId,
              "",
              null,
              "setImage"
            );
            handleViewProduct(
              DealerId,
              searchValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            NotificationManager.error(t("Product Not Deleted."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setVisible3(false);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const setDeleteData = (type, item) => {
    if (type === "All") {
      setDeleteType("Multi");
      var dataLength = ViewProductList.filter(
        (x) => x.isChecked === true
      ).length;
      if (dataLength > 0) {
        setVisible3(true);
      } else {
        NotificationManager.error(t("Kindly Select atleast One Record First"));
      }
    } else if (type === "Single") {
      setDeleteType("Single");

      setDeleteId(item.gfInventoryID + "");
      setVisible3(true);
    }
  };

  const setNewData = () => {
    var dataLength = ViewProductList.filter((x) => x.isChecked == true).length;
    if (dataLength > 0) {
      if (selectAll) {
      } else {
      }
    } else {
      NotificationManager.error(t("Kindly Select atleast One Record First"));
    }
  };

  const setFeaturedData = () => {
    var dataLength = ViewProductList.filter((x) => x.isChecked == true).length;
    if (dataLength > 0) {
      // setVisible3(true);
    } else {
      NotificationManager.error(t("Kindly Select atleast One Record First"));
    }
  };

  const handleChangeJewelryDisplayOrder = (id, ProductId, Order) => {
    let inputData = {
      dealerID: id + "" || DealerId + "",
      productID: ProductId + "",
      displayOrder: Order + "",
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.ChangeJewelryDisplayOrder(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          if (message === "Success") {
            NotificationManager.success(t("Updated Successfully."));
            handleViewProduct(
              DealerId,
              searchValue,
              pageNo,
              pageSize,
              sortColumnName,
              sortType
            );
          } else {
            NotificationManager.error(t("Data Not Updated."));
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      console.log(error);
    }
  };

  const menu = (
    <Menu className="drpdwn__moreaction">
      <Menu.Item onClick={() => setDeleteData("All", "")}>
        {" "}
        {t("Delete")}{" "}
      </Menu.Item>
    </Menu>
  );

  const { Option } = Select;
  function onChange(value) {}

  function onChange(e) {}
  const handleProductChangeOpen = (gfInventoryID) => {
    //setProductChange(false);
    state.productChange = {};
    state.productChange[gfInventoryID] = true;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleProductChangeClose = (gfInventoryID) => {
    //setProductChange(true);
    state.productChange = {};
    state.productChange[gfInventoryID] = false;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleVideoModal = (row) => {
    setVideoImageModal(true);
    // handleGetViewProductVedioUrl(row);
    setVideoInfo(row.videoUrl);
  };

  const handleGetViewProductVedioUrl = (row) => {
    var inputData = {
      gfInventoryID: row.gfInventoryID,
    };
    setVideoInfoLoading(true);
    try {
      CatalogJewelryDataService.GetViewProductVedioUrl(inputData)
        .then((response) => {
          var responseData = response.data.responseData[0].videoURL;
          var message = response.data.message;
          if (message === "Success") {
            setVideoInfo(responseData);
            setVideoInfoLoading(false);
          } else {
            setVideoInfo("");
            setVideoInfoLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setVideoInfoLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVideoInfoLoading(false);
    }
  };

  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      setPageSize(pageSize);
      handleViewProduct(
        DealerId,
        searchValue,
        page,
        pageSize,
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleViewProduct(
        DealerId,
        searchValue,
        current,
        pageNewSize,
        sortColumnName,
        sortType
      );
    }
  }
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    setPriceValueChange(true);

    setFilterBtnVisble(false);
    let newArr = [...priceValue];
    var Value = e.target.value && e.target.value.replace(currencySymbol, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "" || Value == " ") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        var NewValue = Value.replace(" ", "");
        newArr[0] = Number(parseFloat(NewValue.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (Value == "" || Value == " ") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        var NewValue = Value.replace(" ", "");
        newArr[1] = Number(parseFloat(NewValue.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };

  const handleRedirect = (id) => {
    dispatch(setEditTierMenu("true"));
    var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["editProducts"]));
    navigate("/editProducts", { state: { key: id } });
  };

  const handleGetCategory = () => {
    let categoryArray = [];
    if (CategotyList.length > 0) {
      for (let i = 0; i < state.Catagory.length; i++) {
        for (let j = 0; j < CategotyList.length; j++) {
          if (state.Catagory[i] == CategotyList[j].jewelryCategoryID) {
            categoryArray.push(state.Catagory[i]);
          }
        }
      }
      setFilteredCategory(categoryArray);
    }
  };

  const handleGetMaterialColor = () => {
    let MaterialColorArray = [];
    if (MaterialColor.length > 0) {
      for (let i = 0; i < state.MaterialColor.length; i++) {
        for (let j = 0; j < MaterialColor.length; j++) {
          if (state.MaterialColor[i] == MaterialColor[j].metalColorID) {
            MaterialColorArray.push(state.MaterialColor[i]);
          }
        }
      }
      setFilteredMaterialColor(MaterialColorArray);
    }
  };

  const handleGetMaterialType = () => {
    let MaterialTypeArray = [];
    if (MaterialType.length > 0) {
      for (let i = 0; i < state.MaterialType.length; i++) {
        for (let j = 0; j < MaterialType.length; j++) {
          if (state.MaterialType[i] == MaterialType[j].metalTypeID) {
            MaterialTypeArray.push(state.MaterialType[i]);
          }
        }
      }
      setFilteredMaterialType(MaterialTypeArray);
    }
  };
  const handleGenderList = () => {
    let GenderArray = [];
    if (GenderList.length > 0) {
      for (let i = 0; i < state.Gender.length; i++) {
        for (let j = 0; j < GenderList.length; j++) {
          if (state.Gender[i] == GenderList[j].genderID) {
            GenderArray.push(state.Gender[i]);
          }
        }
      }
    }
    setFilteredGender(GenderArray);
  };

  const handleCollectionList = () => {
    let CollectionArray = [];
    if (CollectionList.length > 0) {
      for (let i = 0; i < state.Collection.length; i++) {
        for (let j = 0; j < CollectionList.length; j++) {
          if (state.Collection[i] == CollectionList[j].payload) {
            CollectionArray.push(state.Collection[i]);
          }
        }
      }
      setFilteredCollection(CollectionArray);
    }
  };

  const handleVendorList = () => {
    let VendorArray = [];
    if (VendorList.length > 0) {
      for (let i = 0; i < state.Vendor.length; i++) {
        for (let j = 0; j < VendorList.length; j++) {
          if (state.Vendor[i] == VendorList[j].vendorNameID) {
            VendorArray.push(state.Vendor[i]);
          }
        }
      }
    }

    setFilteredVendor(VendorArray);
  };

  useEffect(() => {
    handleGetCategory();
    handleGetMaterialColor();
    handleGetMaterialType();
    handleGenderList();
    handleCollectionList();
    handleVendorList();
    // handleImageList();
  }, [state]);

  const handleCreateRedirect = () => {
    var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["addProducts"]));
    navigate("/addProducts");
  };

  const handleUploadRedirect = () => {
    var MenuData = [`${"Import"}`, `${"ImportJewelry"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["uploadProductFile"]));
    navigate("/uploadProductFile");
  };

  const handleApplyFilter = () => {
    setPageNo(1);
    handleViewProduct(
      DealerId,
      searchValue,
      1,
      pageSize,
      sortColumnName,
      sortType
    );
  };

  // WorkSheet

  const handleGetWorksheetDetails = () => {
    let inputData = {
      delarId: DealerId ? DealerId : 0,
    };
    setWorkSheetLoading(true);
    try {
      PurchaseOrderServices.GetWorksheetDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setVendorData(responseData);
              setWorksheetData({
                workSheetName: responseData[0].poWorkSheetID,
              });
              setWorkSheetKey(responseData[0].workSheetName);
              setWorkSheetLoading(false);
            } else {
              setVendorData([]);
              setWorksheetData({
                workSheetName: "",
              });
              setWorkSheetLoading(false);
            }
          } else {
            setVendorData([]);
            setWorksheetData({
              workSheetName: "",
            });
            setWorkSheetLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setWorkSheetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWorkSheetLoading(false);
    }
  };

  const handleGetSelectCurrencyForDelar = async (id) => {
    try {
      let inputData = {
        delarId: id,
      };
      await AdminManageCurrencyService.GetSelectlanguageForDelar(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              dispatch(setCurrencySign(responseData.currencysign));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="allprod_section">
      {vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <Spin spinning={applyLoading}>
          <div className="row">
            <div className="col-md-12">
              <div className="btn_div">
                <Button
                  onClick={() => handelWorkSheetModal()}
                  className={
                    props.type == "MergeProduct"
                      ? "display-block primary-btn"
                      : "d-none  primary-btn"
                  }
                >
                  <img src={Create_icon} alt="" />
                  {t("Add to Worksheet")}
                </Button>

                <button
                  onClick={() => handleCreateRedirect()}
                  className="primary-btn"
                >
                  <img src={Create_icon} alt="" />
                  {t("Create Product")}
                </button>

                <button
                  onClick={() => handleUploadRedirect()}
                  className="primary-btn"
                >
                  <img src={Upload_icon} alt="" />
                  {t("Upload File")}
                </button>

                <Dropdown
                  className={
                    props.type == "MergeProduct"
                      ? "d-none primary-btn"
                      : props.type == "AllProduct"
                      ? "d-none primary-btn"
                      : "display-block primary-btn"
                  }
                  overlay={menu}
                  placement="bottomCenter"
                >
                  <Button>
                    {" "}
                    <img src={Create_icon} alt="" />
                    {t("More Actions")}
                  </Button>
                </Dropdown>
              </div>
            </div>

            <div className="col-md-12">
              <div className="filter_section form__fields">
                <div className="col-md-12">
                  <div className="search__bar">
                    <Search
                      placeholder={t("Keyword Or SKU")}
                      allowClear
                      onSearch={onSearch}
                      style={{
                        width: 220,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 desktop__view">
                  <div className="filt_optndiv">
                    <div className="gview__div">
                      <span
                        className={
                          viewItem === "grid"
                            ? "fa fa-th grid_icon active mobile__bottomspace"
                            : "fa fa-th grid_icon"
                        }
                        onClick={() => handleViewChange("grid")}
                      ></span>
                      <span
                        className={
                          viewItem === "list"
                            ? "fa fa-th-list list_icon active"
                            : "fa fa-th-list list_icon mobile__bottomspace"
                        }
                        onClick={() => handleViewChange("list")}
                      ></span>

                      <div className="ml-2 mobile-m-0">
                        <Pagination
                          current={pageNo}
                          pageSize={pageSize}
                          total={TotalRowCount}
                          onChange={onChange}
                          onShowSizeChange={onShowSizeChange}
                          showSizeChanger="true"
                        />
                      </div>
                    </div>
                    <div className="drpdwn__div text-left-mobile">
                      <span className="ml-2 mobile-m-0">
                        <b>
                          {" "}
                          {t("Total")} {TotalRowCount} {t("Product")}{" "}
                        </b>
                      </span>
                    </div>
                    <div className="filtbtn__div mobile-m-0 display__block text-left-mobile">
                      <span
                        className={
                          props.type == "MergeProduct"
                            ? "d-none select__all"
                            : props.type == "AllProduct"
                            ? "d-none select__all"
                            : "display-block select__all"
                        }
                      >
                        <input
                          type="checkbox"
                          id="sel"
                          onClick={(e) => handleOnCheckGridAll(e)}
                          checked={selectAll}
                        />
                        <label htmlFor="sel"> {t("Select All")} </label>
                      </span>
                      <button
                        className="primary-btn ml-2 mobile-m-0 mobile__topspace"
                        onClick={handleHideShowFilter}
                      >
                        <img src={Filter_icon} alt="" width="14px" />
                        {hideShowFilter ? (
                          <span>
                            {t("Hide Filter")}{" "}
                            <i className="fa fa-angle-up"></i>
                          </span>
                        ) : (
                          <span>
                            {t("Show Filter")}{" "}
                            <i className="fa fa-angle-down"></i>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mobile__view">
                  <div className="filt_optndiv">
                    <div className="gview__div mobile__bottomspace">
                      <p className="m-0">
                        <span
                          className={
                            viewItem === "grid"
                              ? "fa fa-th grid_icon active"
                              : "fa fa-th grid_icon"
                          }
                          onClick={() => handleViewChange("grid")}
                        ></span>
                        <span
                          className={
                            viewItem === "list"
                              ? "fa fa-th-list list_icon active"
                              : "fa fa-th-list list_icon"
                          }
                          onClick={() => handleViewChange("list")}
                        ></span>
                      </p>

                      <span className="ml-2 mobile-m-0">
                        <b>
                          {" "}
                          {t("Total")} {TotalRowCount} {t("Product")}{" "}
                        </b>
                      </span>
                      <span
                        className={
                          props.type == "MergeProduct"
                            ? "d-none select__all"
                            : props.type == "AllProduct"
                            ? "d-none select__all"
                            : "display-block select__all"
                        }
                      >
                        <input
                          type="checkbox"
                          id="sel"
                          onClick={(e) => handleOnCheckGridAll(e)}
                          checked={selectAll}
                        />
                        <label htmlFor="sel"> {t("Select All")} </label>
                      </span>
                    </div>
                    <div className="ml-2 mobile-m-0 w-100">
                      <Pagination
                        current={pageNo}
                        pageSize={pageSize}
                        total={TotalRowCount}
                        onChange={onChange}
                        onShowSizeChange={onShowSizeChange}
                        showSizeChanger="true"
                        responsive="true"
                      />
                      <button
                        className="primary-btn ml-2 mobile-m-0 mobile__topspace"
                        onClick={handleHideShowFilter}
                      >
                        <img src={Filter_icon} alt="" width="14px" />
                        {hideShowFilter ? (
                          <span>
                            {t("Hide Filter")}{" "}
                            <i className="fa fa-angle-up"></i>
                          </span>
                        ) : (
                          <span>
                            {t("Show Filter")}{" "}
                            <i className="fa fa-angle-down"></i>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <Spin spinning={applyFilterLoading}>
                    <div
                      className={
                        hideShowFilter
                          ? "form__fields filter_inpsection"
                          : "form__fields filter_inpsection hide"
                      }
                    >
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Category")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                mode="multiple"
                                showArrow
                                placeholder={t("Select Category")}
                                optionFilterProp="children"
                                // value={filteredCategory}
                                value={state.Catagory}
                                onChange={(e) =>
                                  onChangeMultiDrop(e, "Catagory")
                                }
                              >
                                {CategotyList.length > 0 ? (
                                  <Option value={""}> {t("All")} </Option>
                                ) : null}

                                {CategotyList.map((item, i) => {
                                  return (
                                    <Option
                                      disabled={state.disabled.Catagory}
                                      value={item.jewelryCategoryID}
                                    >
                                      {item.jewelryCategoryValues}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Material Color")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                mode="multiple"
                                showArrow
                                placeholder={t("Select Material Color")}
                                optionFilterProp="children"
                                // value={filteredMaterialColor}
                                value={state.MaterialColor}
                                onChange={(e) =>
                                  onChangeMultiDrop(e, "MaterialColor")
                                }
                              >
                                {MaterialColor.length > 0 ? (
                                  <Option value={""}> {t("All")} </Option>
                                ) : null}
                                {MaterialColor.map((item, i) => {
                                  return (
                                    <Option
                                      disabled={state.disabled.MaterialColor}
                                      value={item.metalColorID}
                                    >
                                      {item.metalColorValues}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Material Type")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                mode="multiple"
                                showArrow
                                placeholder={t("Select Material Type")}
                                optionFilterProp="children"
                                value={state.MaterialType}
                                // value={filteredMaterialType}
                                onChange={(e) =>
                                  onChangeMultiDrop(e, "MaterialType")
                                }
                              >
                                {MaterialType.length > 0 ? (
                                  <Option value={""}> {t("All")} </Option>
                                ) : null}
                                {MaterialType.map((item, i) => {
                                  return (
                                    <Option
                                      disabled={state.disabled.MaterialType}
                                      value={item.metalTypeID}
                                    >
                                      {item.metalTypeValues}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Gender")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                mode="multiple"
                                showArrow
                                placeholder={t("Select Gender")}
                                optionFilterProp="children"
                                // value={filteredGender}
                                value={state.Gender}
                                onChange={(e) => onChangeMultiDrop(e, "Gender")}
                              >
                                {GenderList.length > 0 ? (
                                  <Option value={""}> {t("All")} </Option>
                                ) : null}
                                {GenderList.map((item, i) => {
                                  return (
                                    <Option
                                      disabled={state.disabled.Gender}
                                      value={item.genderID}
                                    >
                                      {item.genderValues}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Collection")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                showArrow
                                mode="multiple"
                                placeholder={t("Select Collection")}
                                optionFilterProp="children"
                                value={state.Collection}
                                // value={filteredCollection}
                                onChange={(e) =>
                                  onChangeMultiDrop(e, "Collection")
                                }
                                allowClear
                              >
                                {CollectionList?.length > 0 ? (
                                  <Option value={""}> {t("All")} </Option>
                                ) : null}
                                {AttributesSets?.map((item, i) => {
                                  return (
                                    <OptGroup label={item}>
                                      {CollectionList?.filter(
                                        (x) => x.collectionLabelValues == item
                                      ).map((item, i) => {
                                        return (
                                          <Option
                                            value={item.payload}
                                            disabled={state.disabled.Collection}
                                          >
                                            {item.collectionNamesValues}
                                          </Option>
                                        );
                                      })}
                                    </OptGroup>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          {props.type == "MergeProduct" ||
                          props.type == "AllProduct" ? (
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Vendor")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  mode="multiple"
                                  showArrow
                                  placeholder={t("Select Vendor")}
                                  optionFilterProp="children"
                                  // value={filteredVendor}
                                  value={state.Vendor}
                                  onChange={(e) =>
                                    onChangeMultiDrop(e, "Vendor")
                                  }
                                >
                                  {VendorList.length > 0 ? (
                                    <Option value={""}> {t("All")} </Option>
                                  ) : null}

                                  {VendorList?.map((item, i) => {
                                    return (
                                      <Option
                                        disabled={state.disabled.Vendor}
                                        value={item.vendorNameID}
                                      >
                                        {item.vendorNameValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-lg-3 col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {t("Price")} </label>
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={priceValue}
                                  min={state.PriceMin}
                                  max={state.PriceMax}
                                  onChange={(e) => {
                                    setPriceValueChange(true);
                                    setPriceValue(e);

                                    {
                                      btnValue == 1
                                        ? setFilterBtnVisble(false)
                                        : setFilterBtnVisble(true);
                                    }
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={
                                        currencySymbol +
                                        formatMoney(priceValue[0])
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 0);
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={
                                        currencySymbol +
                                        formatMoney(priceValue[1])
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 1);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Images")} </label>
                              <Select
                                className="border__grey"
                                showSearch
                                placeholder={t("Select Image")}
                                optionFilterProp="children"
                                value={state.Image}
                                onChange={(e) => onChangeDrop(e, "Image")}
                              >
                                {ImageList?.map((item, i) => {
                                  return (
                                    <Option value={item.hasImage}>
                                      {item.hasImageValues}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 filtbtn_div">
                            <button
                              className="primary-btn mr-2"
                              onClick={onResetFilter}
                            >
                              {" "}
                              {t("Reset Filters")}
                            </button>
                            <Button
                              className={
                                filterBtnVisble == true
                                  ? "disabled-btn"
                                  : "primary-btn"
                              }
                              disabled={filterBtnVisble}
                              onClick={() => handleApplyFilter()}
                            >
                              {" "}
                              {t("Apply Filters")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
                {viewItem === "grid" && (
                  <div className="col-md-12">
                    <hr className="mt-4 mb-4" />
                    <div className="prod__section">
                      <div className="row">
                        {ViewProductList?.length > 0 ? (
                          ViewProductList?.map((item, i) => {
                            return (
                              <div className="col-lg-3 col-md-6 mb-3">
                                <div
                                  className={
                                    props.type == "AllProduct" ||
                                    props.type == "MergeProduct"
                                      ? "prod__maindiv mh-auto"
                                      : "prod__maindiv"
                                  }
                                >
                                  <div
                                    className={
                                      props.type == "MergeProduct"
                                        ? "display-block chkbx__div"
                                        : props.type == "AllProduct"
                                        ? "d-none chkbx__div"
                                        : "display-block chkbx__div"
                                    }
                                  >
                                    <Checkbox
                                      disabled={
                                        props.type == "MergeProduct"
                                          ? item.poEnebled == true
                                            ? false
                                            : true
                                          : false
                                      }
                                      checked={item.isChecked}
                                      // checked={checkBoxValue?.includes(
                                      //   item.gfInventoryID
                                      // )}
                                      onChange={(e) =>
                                        handleOnCheckGrid(e, item.gfInventoryID)
                                      }
                                    />
                                  </div>
                                  <div className="prodimg__div">
                                    {/* <img src={Rectangle} alt="" /> */}
                                    <img src={item.imagepath} alt="" />
                                  </div>
                                  <div className="details__div">
                                    <span className="style__txt">
                                      {t("Style No")}: {item.styleNumber}
                                    </span>
                                    <span className="price__txt">
                                      <span>
                                        {item.msrp == "0" ||
                                        item.msrp == "Call for Price" ? (
                                          " Call For Price"
                                        ) : (
                                          <span>
                                            {item.msrp == "-"
                                              ? ""
                                              : `${item.currency}`}
                                            {item.msrp
                                              // .toFixed()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      props.type == "MergeProduct"
                                        ? "d-none editdel__div"
                                        : props.type == "AllProduct"
                                        ? "d-none editdel__div"
                                        : "display-block editdel__div"
                                    }
                                  >
                                    {item.hasVideo && (
                                      <div
                                        onClick={() => handleVideoModal(item)}
                                      >
                                        <img src={Video_icon} alt="" />
                                      </div>
                                    )}

                                    <div
                                      onClick={() =>
                                        handleRedirect(item.gfInventoryID)
                                      }
                                    >
                                      <img src={Edit_icon} alt="" />
                                      {/* </Link> */}
                                    </div>

                                    <div
                                      onClick={() =>
                                        setDeleteData("Single", item)
                                      }
                                    >
                                      <img src={Delete_iconbl} alt="" />
                                    </div>
                                  </div>
                                  {!state.productChange[item.gfInventoryID] ? (
                                    <div
                                      className="info__div"
                                      onClick={() =>
                                        handleProductChangeOpen(
                                          item.gfInventoryID
                                        )
                                      }
                                    >
                                      <img src={Info_icon} alt="" />
                                    </div>
                                  ) : (
                                    <div className="descr__div">
                                      <div className="overlay__block">
                                        <p>
                                          <span> {t("Style No")} </span>
                                          <span>:</span>
                                          <span>{item.styleNumber}</span>
                                        </p>
                                        <p>
                                          <span> {t("GFinventory No")} </span>
                                          <span>:</span>
                                          <span>{item.gfInventoryID}</span>
                                        </p>
                                        <p>
                                          <span> {t("Product Name")} </span>
                                          <span>:</span>
                                          <span>{item.productName}</span>
                                        </p>
                                        <p>
                                          <span> {t("Category")} </span>
                                          <span>:</span>
                                          <span>
                                            {item?.categoryName &&
                                              item.categoryName
                                                .replaceAll(", ", ",")
                                                .replaceAll(",", ", ")}
                                            {/* {item.categoryName} */}
                                          </span>
                                        </p>
                                        <p>
                                          <span> {t("Collection")} </span>
                                          <span>:</span>
                                          <span>{item.collectionName}</span>
                                        </p>
                                        {(props.type == "AllProduct" ||
                                          props.type == "MergeProduct") && (
                                          <p>
                                            <span> {t("Vendor Name")} </span>
                                            <span>:</span>
                                            <span>{item.vendorName}</span>
                                          </p>
                                        )}

                                        <p>
                                          <span> {t("Wholesale Price")} </span>
                                          <span>:</span>

                                          <span>
                                            {item.wholesaleprice == "0" ||
                                            item.wholesaleprice ==
                                              "Call for Price" ? (
                                              " Call For Price"
                                            ) : (
                                              <span>
                                                {item.wholesaleprice == "-"
                                                  ? ""
                                                  : `${currencySign} ${
                                                      item.currency
                                                    }${" "}`}
                                                {item.wholesaleprice
                                                  // .toFixed()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </span>
                                            )}
                                          </span>
                                        </p>
                                        <p>
                                          <span> {t("MSRP")} </span>
                                          <span>:</span>

                                          <span>
                                            {item.msrp == "0" ||
                                            item.msrp == "Call for Price" ? (
                                              " Call For Price"
                                            ) : (
                                              <span>
                                                {item.msrp == "-"
                                                  ? ""
                                                  : `${currencySign} ${
                                                      item.currency
                                                    }${" "}`}
                                                {item.msrp
                                                  // .toFixed()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </span>
                                            )}
                                          </span>
                                        </p>
                                        <p>
                                          <span> {t("Retail Price")} </span>
                                          <span>:</span>
                                          <span>
                                            {item.retailPrice == "0" ||
                                            item.retailPrice ==
                                              "Call for Price" ? (
                                              " Call For Price"
                                            ) : (
                                              <span>
                                                {item.retailPrice == "-"
                                                  ? ""
                                                  : `${currencySign} ${
                                                      item.currency
                                                    }${" "}`}
                                                {item.retailPrice.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              </span>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        className="close__div"
                                        onClick={() =>
                                          handleProductChangeClose(
                                            item.gfInventoryID
                                          )
                                        }
                                      >
                                        <img src={Close} alt="" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* </Link> */}
                              </div>
                            );
                          })
                        ) : (
                          <span className="mr-2">
                            {t("No items found")}{" "}
                            <span
                              className="linkText"
                              onClick={() => setHideShowFilter(true)}
                            >
                              {t("reset")}
                            </span>{" "}
                            {t("your filters")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {viewItem === "list" && (
                  <div className="col-md-12 mt-4">
                    <div className="lviewprod__tbl">
                      <Table
                        const
                        columns={[
                          ...(props.type == "MergeProduct"
                            ? [
                                {
                                  title: "",
                                  dataIndex: "isChecked",
                                  width: 50,
                                  render: (row, item) => {
                                    return (
                                      <Checkbox
                                        disabled={
                                          props.type == "MergeProduct"
                                            ? item.poEnebled == true
                                              ? false
                                              : true
                                            : false
                                        }
                                        checked={item.isChecked}
                                        // checked={checkBoxValue?.includes(
                                        //   item.gfInventoryID
                                        // )}
                                        onChange={(e) =>
                                          handleOnCheckGrid(
                                            e,
                                            item.gfInventoryID
                                          )
                                        }
                                      />
                                    );
                                  },
                                },
                              ]
                            : props.type == "AllProduct"
                            ? []
                            : [
                                {
                                  title: "",
                                  dataIndex: "isChecked",
                                  width: 50,
                                  render: (row, item) => {
                                    return (
                                      <Checkbox
                                        disabled={
                                          props.type == "MergeProduct"
                                            ? item.poEnebled == true
                                              ? false
                                              : true
                                            : false
                                        }
                                        checked={item.isChecked}
                                        // checked={checkBoxValue?.includes(
                                        //   item.gfInventoryID
                                        // )}
                                        onChange={(e) =>
                                          handleOnCheckGrid(
                                            e,
                                            item.gfInventoryID
                                          )
                                        }
                                      />
                                    );
                                  },
                                },
                              ]),
                          {
                            title: "",
                            dataIndex: "image",
                            width: 100,
                            render: (row, item) => {
                              return (
                                <img width="50" src={item.imagepath} alt="" />
                              );
                            },
                          },
                          {
                            title: t("Product"),
                            dataIndex: "productName",
                            width: 200,
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <>
                                  <span>{item.productName}</span>
                                  <br></br>
                                  {item.productType == 1 && (
                                    <span className="danger_text">
                                      Configurable
                                    </span>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            title: t("Retail Stock #"),
                            width: 120,
                            dataIndex: "styleNumber",
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <span className="linkText">
                                  {item.styleNumber}
                                </span>
                              );
                            },
                          },
                          {
                            title: "Gemfind #",
                            dataIndex: "gfInventoryID",
                            width: 120,
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <span className="linkText">
                                  {item.gfInventoryID}
                                </span>
                              );
                            },
                          },
                          {
                            title: t("Category"),
                            width: 120,
                            dataIndex: "categoryName",
                            showSorterTooltip: false,
                          },
                          {
                            title: t("Collection"),
                            dataIndex: "collectionName",
                            width: 120,
                            showSorterTooltip: false,
                            render: (row, item) => {
                              return (
                                <span className="linkText">
                                  {item.collectionName}
                                </span>
                              );
                            },
                          },

                          ...(props.type == "AllProduct" ||
                          props.type == "MergeProduct"
                            ? [
                                {
                                  title: t("Vendor Name"),
                                  dataIndex: "vendorName",
                                  width: 120,
                                  showSorterTooltip: false,
                                  sorter: (a, b) => {},
                                  render: (row, item) => {
                                    return (
                                      <span
                                        className="linkText"
                                        onClick={() =>
                                          handleViewMore(item.dealerID)
                                        }
                                      >
                                        {item.vendorName}
                                      </span>
                                    );
                                  },
                                },
                              ]
                            : []),

                          {
                            title: (
                              <>
                                {currencySymbol} {t("MSRP")}
                              </>
                            ),
                            dataIndex: "msrp",
                            width: 120,
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <span>
                                  {item.msrp == "0" ||
                                  item.msrp == "Call for Price" ? (
                                    " Call For Price"
                                  ) : (
                                    <span>
                                      {item.msrp == "-"
                                        ? ""
                                        : `${currencySign} ${
                                            item.currency
                                          }${" "}`}
                                      {item.msrp
                                        // .toFixed()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  )}
                                </span>
                              );
                            },
                          },
                          {
                            title: (
                              <>
                                {currencySymbol} {t("Cost")}
                              </>
                            ),
                            dataIndex: "wholesaleprice",
                            width: 120,
                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <span>
                                  {item.wholesaleprice == "0" ||
                                  item.wholesaleprice == "Call for Price" ? (
                                    " Call For Price"
                                  ) : (
                                    <span>
                                      {item.wholesaleprice == "-"
                                        ? ""
                                        : `${currencySign} ${
                                            item.currency
                                          }${" "}`}
                                      {item.wholesaleprice
                                        // .toFixed()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  )}
                                </span>
                              );
                            },
                          },
                          {
                            title: (
                              <>
                                {currencySymbol} {t("Retail")}
                              </>
                            ),
                            dataIndex: "retail",
                            width: 120,

                            showSorterTooltip: false,
                            sorter: (a, b) => {},
                            render: (row, item) => {
                              return (
                                <span>
                                  {item.retailPrice == "0" ||
                                  item.retailPrice == "Call for Price" ? (
                                    " Call For Price"
                                  ) : (
                                    <span>
                                      {item.retailPrice == "-"
                                        ? ""
                                        : `${currencySign} ${
                                            item.currency
                                          }${" "}`}
                                      {item.retailPrice
                                        // .toFixed()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  )}
                                </span>
                              );
                            },
                          },

                          {
                            title: "",
                            dataIndex: "actions",
                            width: 30,
                            render: (row, item) => {
                              return (
                                <Dropdown
                                  className=""
                                  overlay={
                                    <Menu className="drpdwn__icons">
                                      {item.hasVideo && (
                                        <Menu.Item
                                          className={
                                            props.type == "MergeProduct"
                                              ? "d-none"
                                              : props.type == "AllProduct"
                                              ? "d-none"
                                              : "display-block"
                                          }
                                          onClick={() => handleVideoModal(row)}
                                        >
                                          <img src={Video_icon} alt="" />
                                        </Menu.Item>
                                      )}
                                      <Menu.Item>
                                        <Link
                                          to={"/editProducts"}
                                          state={{
                                            key: item.gfInventoryID,
                                          }}
                                          className={
                                            props.type == "MergeProduct"
                                              ? "d-none"
                                              : props.type == "AllProduct"
                                              ? "d-none"
                                              : "display-block"
                                          }
                                        >
                                          <div>
                                            <img src={Edit_icon} alt="" />
                                          </div>
                                        </Link>
                                      </Menu.Item>
                                      <Menu.Item
                                        onClick={() =>
                                          setDeleteData("Single", item)
                                        }
                                        className={
                                          props.type == "MergeProduct"
                                            ? "d-none"
                                            : props.type == "AllProduct"
                                            ? "d-none"
                                            : "display-block"
                                        }
                                      >
                                        <img src={Delete_iconbl} alt="" />
                                      </Menu.Item>
                                      <Menu.Item>
                                        <Link
                                          to={"/productDetails"}
                                          state={{ productData: item }}
                                        >
                                          <div>
                                            <img src={Eye_icon} alt="" />
                                          </div>
                                        </Link>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  onClick={() => setSelectActionRow(item)}
                                  placement="bottomRight"
                                >
                                  <img
                                    src={Ellipsis_icon}
                                    className="ellipicon__img"
                                    alt=""
                                  />
                                </Dropdown>
                              );
                            },
                            width: 30,
                          },
                        ]}
                        onChange={handleTableASCDES}
                        pagination={false}
                        loading={tableLoading ? tableLoading : applyLoading}
                        dataSource={ViewProductList}
                        scroll={{ x: 600, y: 800 }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <Modal
                title={t("Delete")}
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel3}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleDeleteJewelry(DealerId)}
                  >
                    {t("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>
                              {t(
                                "Are you sure you want to delete this Product(s)?"
                              )}
                            </p>
                            <h5> {t("THIS CAN NOT BE UNDONE")} </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={t("Video")}
                className="modalvdiamond__section"
                centered
                visible={videoImageModal}
                onOk={() => handleCloseVideoImageModal()}
                onCancel={() => handleCloseVideoImageModal()}
                width={600}
                footer={[
                  <Button
                    key="back"
                    onClick={() => handleCloseVideoImageModal()}
                  >
                    {t("Close")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <Spin spinning={videoInfoLoading}>
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              {videoInfo ? (
                                <iframe
                                  id="VideoIframesrc"
                                  src={videoInfo}
                                  class="VideoIframe"
                                ></iframe>
                              ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </Modal>

              {/* Modal Start */}

              <Modal
                title={t("Add To WorkSheet")}
                maskClosable={false}
                className="modalconsupld__section"
                centered
                visible={workSheetModal}
                // onOk={}
                onCancel={handleCancelWorkSheetModal}
                width={400}
                footer={[
                  <Button
                    key="back"
                    onClick={
                      switchCount == 0
                        ? () => handleSwitchInputs()
                        : () => handleCloseWorkSheetModal()
                    }
                    type="primary"
                  >
                    {switchCount == 0 ? "New WorkSheet" : "Cancel"}
                  </Button>,
                  <Button
                    loading={updateLoading}
                    key="submit"
                    type="primary"
                    onClick={handleCreateNewWorksheerdetils}
                  >
                    {t("Add Products")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <Spin spinning={workSheetLoading}>
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="col-md-12">
                            {switchCount == 0 && (
                              <div className="input__block">
                                <label>{t("WorkSheet Name")} </label>
                                <Select
                                  name="workSheetName"
                                  className="border__grey"
                                  showSearch
                                  // placeholder="Select Start Time"
                                  optionFilterProp="children"
                                  value={workSheetData.workSheetName}
                                  onChange={(e, key) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "workSheetName",
                                      key
                                    )
                                  }
                                >
                                  {vendorData &&
                                    vendorData.map((item, i) => {
                                      return (
                                        <Option
                                          value={item.poWorkSheetID}
                                          key={item.workSheetName}
                                        >
                                          {item.workSheetName}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            )}
                            {switchCount == 1 && (
                              <div className="input__block">
                                <label>{t("WorkSheet Name")} </label>
                                <input
                                  type="text"
                                  placeholder={t("Enter WorkSheet Name")}
                                  name="workSheetName"
                                  value={workSheetNamedata.workSheetName}
                                  onChange={(e) => handleWorksheetOnChange(e)}
                                />
                              </div>
                            )}
                            {linkCount == 1 && (
                              <div className="input__block">
                                <a
                                  className="linkText"
                                  onClick={() =>
                                    handleRedirection(
                                      workSheetKeyId,
                                      workSheetKey
                                    )
                                  }
                                >
                                  {t("View WorkSheet")}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </Modal>

              {/* After Delete Modal PopUp Message */}

              <Modal
                title={" "}
                className="modalconsupld__section"
                centered
                visible={deleteMessageModal}
                width={400}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleOnOk()}
                  >
                    {t("OK")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>
                              {t(
                                "Your jewellery(s) are deleted.However it will take some moments to remove them from cache.Deleted jewellery(s) will be removed from this page as soon cache gets refreshed."
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              {/* End */}
            </div>
          </div>
        </Spin>
      )}
    </div>
  );
};

export default Allproducts;
