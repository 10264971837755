import React, { useState, useRef } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import MultiRangeSlider from "../../contacts/MultiRangeSlider"


import roundDiamond from "../../../assets/images/Round.jpg";
import colorDiamond from "../../../assets/images/yellow-diamond.jpg";

import yourDiamond from "../../../assets/images/your_diamonds.png";
import yourEarrings from "../../../assets/images/your_earrrings.png";
import yourMounting from "../../../assets/images/your_mounting.png";

import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const StudBuilderApplicationPreview = () => {
    const { t } = useTranslation();

    const columns = [
        {
            title: '',
            dataIndex: 'checkbox',
            width: 50,
        },
        {
            title: t("Shape"),
            dataIndex: 'shape',
            
        },
        {
            title: t("Carats"),
            dataIndex: 'carat',
            width: 100,
        },
        {
            title: t("Color"),
            dataIndex: 'color',
            width: 100,
        },
        {
            title: t("Clarity"),
            dataIndex: 'clarity',
            width: 100,
        },
        {
            title: t("Cut"),
            dataIndex: 'cut',
            width: 100,
        },
        {
            title: t("InHouse"),
            dataIndex: 'inhouse',
            width: 100,
        },
        {
            title: t("Depth"),
            dataIndex: 'depth',
            width: 100,
        },
        {
            title: t("Table"),
            dataIndex: 'table',
            width: 100,
        },
        {
            title: t("Polish"),
            dataIndex: 'polish',
            width: 100,
        },
        {
            title: t("Measurement"),
            dataIndex: 'measurement',
            width: 150,
        },
        {
            title: t("Cert"),
            dataIndex: 'cert',
            width: 100,
        },
        {
            title: t("Price"),
            dataIndex: 'price',
            width: 100,
        },
        {
            title: t("View"),
            dataIndex: 'view',
            width: 100,
        },
    ];

    const data = [
        {
            key: '1',
            checkbox: <input type="checkbox" />,
            shape:<div className="d-flex"><img src={roundDiamond} width="20" /><span>Round</span></div>,
            carat: <span>0.31</span>,
            color: <span>J</span>,
            clarity: <span>IF</span>,
            cut: <span>Excellent</span>,
            inhouse: <span>No</span>,
            depth: <span>60</span>,
            table: <span>50</span>,
            polish: <span>Good</span>,
            measurement: <span>4.86X4.79X2.86</span>,
            cert: <span>GIA</span>,
            price: <span>US$1,358</span>,
            view:<i class="fa fa-eye" aria-hidden="true"></i>,
        },
        {
            key: '1',
            checkbox: <input type="checkbox" />,
            shape:<div><img src={roundDiamond} width="20" /><span>Round</span></div>,
            sku: <span>177782295</span>,
            carat: <span>0.31</span>,
            color: <span>J</span>,
            clarity: <span>IF</span>,
            cut: <span>Excellent</span>,
            inhouse: <span>No</span>,
            depth: <span>60</span>,
            table: <span>50</span>,
            polish: <span>Good</span>,
            measurement: <span>4.86X4.79X2.86</span>,
            cert: <span>GIA</span>,
            price: <span>US$1,358</span>,
            view:<i class="fa fa-eye" aria-hidden="true"></i>,
        },
    ];


    // for image upload

    const columns2 = [
        {
            title: t("Date"),
            dataIndex: 'date',
        },
        {
            title: t("Time"),
            dataIndex: 'time',
        },
        {
            title: t("File"),
            dataIndex: 'file',
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        // },
        {
            title: t("Status"),
            dataIndex: 'status',
        },
        {
            title: t("Records"),
            dataIndex: 'records',
        },
        {
            title: t("Report"),
            dataIndex: 'report',
        }
    ];

    const data2 = [
        {
            key: '1',
            date: <span>07/30/2019</span>,
            time: <span>11:50 AM</span>,
            file: <span>720_07302019110702.zip</span>,
            // type: <span>Add & Update</span>,
            status: <span>Completed</span>,
            records: <span>15 of 15</span>,
            report: <a id="Download" href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720">Download</a>,
        },
    ];

    // for daimond

    const columns3 = [
        {
            title: t("Date"),
            dataIndex: 'date',
        },
        {
            title: t("Time"),
            dataIndex: 'time',
        },
        {
            title: t("File"),
            dataIndex: 'file',
        },
        {
            title: t("Type"),
            dataIndex: 'type',
        },
        {
            title: t("Status"),
            dataIndex: 'status',
        },
        {
            title: t("Records"),
            dataIndex: 'records',
        },
        {
            title: t("Report"),
            dataIndex: 'report',
        }
    ];

    const data3 = [
        {
            key: '1',
            date: <span>10/06/2020</span>,
            time: <span>12:30 PM</span>,
            file: <span>JsonTestFile2-ItemList.json</span>,
            type: <span>Add & Update</span>,
            status: <span>Completed</span>,
            records: <span>9 of 22</span>,
            report: <a id="Download" href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720">Download</a>,
        },
    ];

    const resetRange = useRef(null);
    return (
        <React.Fragment>
            <div className="prodetail__section">
                <div className="form__fields">

                    <div className="col-md-12">
                        <div className="prod__maindiv app__preview p-0 border-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="workarea__heading mt-0"> {t("Application Preview")} </h4>
                                </div>
                            </div>

                            <div className="row steps__section">
                                <div className="col-md-12">
                                     <h4 className="workarea__heading"> {t("Create Your Own Diamond Studs in 3 easy steps with Stud Builder")} </h4>
                                </div>
                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4> 1. {t("Select Your Diamonds")} </h4>
                                        <img src={yourDiamond} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4> 2. {t("Choose Your Mounting")} </h4>
                                        <img src={yourMounting} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4> 3. {t("Complete Your Earrings")} </h4>
                                        <img src={yourEarrings} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                
                                <div className="col-lg-12 col-md-12">
                                    <div className="prodesc__div">
                                        <Tabs
                                            defaultActiveKey="standard"
                                            id="uncontrolled-tab-example"
                                            className="tab__div mb-3"
                                        >
                                            <Tab
                                                eventKey="standard"
                                                title={t("Standard")}
                                            >
                                                <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                        <div className="img__block selected">
                                                                            <img src={roundDiamond} />
                                                                            <p> {t("Round")} </p>
                                                                        </div>
                                                                       
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p> {t("Princess")} </p>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Carats")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Price")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Cut")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Ideal")} </p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Excellent")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Very Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Fair")} </p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                    <label> {t("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {t("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {t("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("M")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("N")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("O")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("P")} </p>
                                      </div>
                                    </div>
                                  </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Clarity")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <h3> {t("Diamond Preview")} </h3>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p> {t("Stock Number")}: 78442524</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p> {t("Stock Number")}: 78442524</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <h4>US$789</h4>
                                                                    <a href="#"> {t("Add To Stud")} </a>
                                                                    <button className="primary-btn"> {t("View Details")} </button>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                    <h5>2103 {t("SIMILAR DIAMONDS")} </h5>
                                                                     <Table columns={columns} dataSource={data} scroll={{ y: 800}} />
                                                                </div>
                                                                <div className="compare__btn">
                                                                    <button className="primary-btn"> {t("Compare")} </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>

                                             <Tab
                                                eventKey="Advanced"
                                                title={t("Advanced")}
                                            >
                                               <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                        <div className="img__block selected">
                                                                            <img src={roundDiamond} />
                                                                            <p> {t("Round")} </p>
                                                                        </div>
                                                                       
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p> {t("Princess")} </p>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Carats")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Price")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Cut")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Ideal")} </p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Excellent")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Very Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Fair")} </p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                    <label> {t("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {t("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {t("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("M")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("N")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("O")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("P")} </p>
                                      </div>
                                    </div>
                                  </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Clarity")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label> {t("Polish")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Excellent")} </p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Very Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Good")} </p>
                                                                            </div>                                             
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label> {t("Symmetry")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Excellent")} </p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Very Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Good")} </p>
                                                                            </div>                                             
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label> {t("Fluorescence")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>N</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>FNT</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>MED</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>ST</p>
                                                                            </div> 

                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 mt-4">
                                                                        <div className="input__block">
                                                                            <label> {t("Depth")} </label> 
                                                                            <input type="number" />                          
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 mt-4">
                                                                        <div className="input__block">
                                                                            <label> {t("Table")} </label> 
                                                                            <input type="number" />                          
                                                                        </div>
                                                                    </div>

                                                                    

                                                                        <div className="col-md-6 mt-4">
                                                                        <div className="input__block">
                                                                            <label> {t("Certificates")} </label>
                                                                            <Select
                                                                                className="border__grey"
                                                                                placeholder= {t("Certificates")}
                                                                                name="productType"
                                                                                optionFilterProp="children"
                                                                            >
                                                                                <Option value="Option 1"> {t("Option 1")} </Option>
                                                                                <Option value="Option 2"> {t("Option 2")} </Option>
                                                                            </Select>                        
                                                                            </div>
                                                                        </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <h3> {t("Diamond Preview")} </h3>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p> {t("Stock Number")}: 78442524</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p> {t("Stock Number")}: 78442524</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <h4>US$789</h4>
                                                                    <a href="#"> {t("Add To Stud")} </a>
                                                                    <button className="primary-btn"> {t("View Details")} </button>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                    <h5> 2103 {t("SIMILAR DIAMONDS")} </h5>
                                                                     <Table columns={columns} dataSource={data} scroll={{ y: 800}} />
                                                                </div>
                                                                <div className="compare__btn">
                                                                    <button className="primary-btn"> {t("Compare")} </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>
                                        
                                
                                            <Tab
                                                eventKey="Request"
                                                title={t("Request")}
                                            >
                                                <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                    <div className="img__block selected">
                                      <img src={roundDiamond} />
                                      <p> {t("Round")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Radiant")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Princess")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Pear")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Oval")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Marquise")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Heart")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Princess")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Pear")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Oval")} </p>
                                    </div>
                                    <div className="img__block">
                                      <img src={roundDiamond} />
                                      <p> {t("Princess")} </p>
                                    </div>
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Carats")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> {t("Price")} </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Cut")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Ideal")} </p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p> {t("Excellent")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Very Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Good")} </p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p> {t("Fair")} </p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                    <label> {t("Color")} </label>
                                    <div className="selection__block row">
                                      <div className="selection__box selected col">
                                        <p> {t("D")} </p>
                                      </div>
                                      <div className="selection__box selected col">
                                        <p> {t("E")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("F")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("G")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("I")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("J")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("K")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("L")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("M")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("N")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("O")} </p>
                                      </div>
                                      <div className="selection__box col">
                                        <p> {t("P")} </p>
                                      </div>                                        
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label> {t("Clarity")} </label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <p className="mt-5">{t(
                                      "Please enter the details of the type of diamond you are looking for and your contact information."
                                    )}</p>
                                                                    <p>{t(
                                      "Your information will be submitted to one of our diamond specialists that will contact you."
                                    )}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                     <div className="row p-0 border-0 form__fields mh-auto">
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label> {t("First Name")} </label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label> {t("Last Name")} </label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label> {t("Phone Number")} </label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label> {t("Email Address")} </label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label> {t("Add Comments Here")} </label>
                                                                                <textarea></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="save__form">
                                                                                <button className="primary-btn mr-2"> {t("Reset")} </button>
                                                                                <button className="primary-btn"> {t("Request")} </button>
                                                                            </div>
                                                                        </div>
                                                                     </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>
                                           
                                        </Tabs>

                                        {/* <div class="col-md-6 mt-3">
                                        <button class="primary-btn">Save Details</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StudBuilderApplicationPreview;
