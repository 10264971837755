import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { useSelector } from "react-redux";
import moment from "moment";

const DiamondReportsCutComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer
}) => {
  const { t } = useTranslation();
  /*React Binding Starts*/

  const loginDetials = useSelector((state) => state.loginReducer);
  const [diamondCutSearchesLabels, setDiamondCutSearchesLabels] = useState([]);
  const [DiamondCutSearchesLoading, setDiamondCutSearchesLoading] =
    useState(false);
  const [IdealDataList, setIdealDataList] = useState([]);
  const [VeryGoodDataList, setVeryGoodDataList] = useState([]);
  const [ExcellentDataList, setExcellentDataList] = useState([]);
  const [GoodDataList, setGoodDataList] = useState([]);
  const [FairDataList, setFairDataList] = useState([]);
  const [IdealColorData, setIdealColorData] = useState([]);
  const [VeryGoodColorData, setVeryGoodColorData] = useState([]);
  const [ExcellentColorData, setExcellentColorData] = useState([]);
  const [GoodColorData, setGoodColorData] = useState([]);
  const [FairColorData, setFairColorData] = useState([]);
  const [key, setKey] = useState(true);
  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleDiamondChartForCutGrade(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/

  const handleDiamondChartForCutGrade = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        retailerIds: "",
        vendorIds: "",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt"
      };
      setDiamondCutSearchesLoading(true);

      ReportsDiamondService.DiamondChartForCutGrade(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;
          setDiamondCutSearchesLoading(false);
          if (message === "Success") {
            if (responseData.length > 0) {
              var carateRangeArr = responseData.map((item) => {
                return item.carateRange;
              });
              setDiamondCutSearchesLabels(carateRangeArr);
              var IdealDataList = responseData.map((item) => {
                return item.Ideal;
              });
              setIdealDataList(IdealDataList);

              var VeryGoodDataList = responseData.map((item) => {
                return item.VeryGood;
              });
              setVeryGoodDataList(VeryGoodDataList);
              var ExcellentDataList = responseData.map((item) => {
                return item.Excellent;
              });
              setExcellentDataList(ExcellentDataList);
              var GoodDataList = responseData.map((item) => {
                return item.Good;
              });
              setGoodDataList(GoodDataList);
              var FairDataList = responseData.map((item) => {
                return item.Fair;
              });
              setFairDataList(FairDataList);
              IdealColorChartDataHandler(IdealDataList);
              VeryGoodColorChartDataHandler(VeryGoodDataList);
              ExcellentChartDataHandler(ExcellentDataList);
              GoodChartDataHandler(GoodDataList);
              FairChartDataHandler(FairDataList);
              setKey(!key);
            } else {
              setDiamondCutSearchesLabels([]);
              setIdealDataList([]);
              setVeryGoodDataList([]);
              setExcellentDataList([]);
              setGoodDataList([]);
              setFairDataList([]);
            }
          } else {
            setDiamondCutSearchesLabels([]);
            setIdealDataList([]);
            setVeryGoodDataList([]);
            setExcellentDataList([]);
            setGoodDataList([]);
            setFairDataList([]);
          }
        })
        .catch((error) => {
          setDiamondCutSearchesLoading(false);
          setIdealDataList([]);
          setVeryGoodDataList([]);
          setExcellentDataList([]);
          setGoodDataList([]);
          setFairDataList([]);
        });
    } catch (error) {
      setDiamondCutSearchesLoading(false);
      setIdealDataList([]);
      setVeryGoodDataList([]);
      setExcellentDataList([]);
      setGoodDataList([]);
      setFairDataList([]);
    }
  };
  /*API Binding Ends*/

  const IdealColorChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setIdealColorData(color);
  };
  const VeryGoodColorChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(219, 68, 55)");
    }
    setVeryGoodColorData(color);
  };
  const ExcellentChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(244, 180, 0)");
    }
    setExcellentColorData(color);
  };
  const GoodChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(15, 157, 88)");
    }
    setGoodColorData(color);
  };
  const FairChartDataHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(171, 71, 188)");
    }
    setFairColorData(color);
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {t("Diamond Cut Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={DiamondCutSearchesLoading} size="large">
            <Bar
              key={key}
              data={{
                labels: diamondCutSearchesLabels,
                datasets: [
                  {
                    label: "Ideal",
                    data: IdealDataList,
                    backgroundColor: IdealColorData,
                    borderColor: IdealColorData,
                    borderWidth: 1
                  },
                  {
                    label: "Very Good",
                    data: VeryGoodDataList,
                    backgroundColor: VeryGoodColorData,
                    borderColor: VeryGoodColorData,
                    borderWidth: 1
                  },
                  {
                    label: "Excellent",
                    data: ExcellentDataList,
                    backgroundColor: ExcellentColorData,
                    borderColor: ExcellentColorData,
                    borderWidth: 1
                  },
                  {
                    label: "Good",
                    data: GoodDataList,
                    backgroundColor: GoodColorData,
                    borderColor: GoodColorData,
                    borderWidth: 1
                  },
                  {
                    label: "Fair",
                    data: FairDataList,
                    backgroundColor: FairColorData,
                    borderColor: FairColorData,
                    borderWidth: 1
                  }
                ]
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Carat Size",
                      font: {
                        size: 16
                      }
                    }
                  },
                  y: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: true,
                      text: "Percentage Of Searches",
                      font: {
                        size: 16
                      }
                    }
                  }
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  datalabels: {
                    display: false
                  },
                  tooltip: {
                    enabled: true,
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";
                        let formattedValue = context.formattedValue || "";

                        if (label) {
                          label =
                            label +
                            " " +
                            "(" +
                            parseFloat(formattedValue).toFixed() +
                            "%)";
                        }
                        return label;
                      }
                    }
                  }
                }
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsCutComp;
