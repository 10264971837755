import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Pagination,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import { useTranslation } from "react-i18next";
import StudBuilderService from "../../../services/apps-studbuilder.service";

const { Option } = Select;

export const StudBuilderManageVendors = (props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [dealerId, setDealerId] = useState();

  const columns = [
    {
      title: t("Vendor"),
      dataIndex: "vendor",
    },
    {
      title: t("Status"),
      dataIndex: "status",
    },
    {
      title: t("Data Rating"),
      dataIndex: "dataRating",
    },
    {
      title: t("Items"),
      dataIndex: "items",
    },
    {
      title: t("Updated"),
      dataIndex: "updated",
    },
    {
      title: t("Action"),
      dataIndex: "action",
    },
  ];

  const data = [
    {
      key: "1",
      vendor: "Overnight Mountings Ring Builder",
      status: "Connected",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      items: "38101",
      updated: "05/05/2019",
      action: "Connected",
    },
    {
      key: "1",
      vendor: "Overnight Mountings Ring Builder",
      status: "Connected",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      items: "38101",
      updated: "05/05/2019",
      action: "Request Access",
    },
  ];

  const loginDetails = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.loginDetials) {
        if (loginDetails.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetails.loginDetials.responseData.dealerId);
          getBindStudBuilderVendor(
            loginDetails.loginDetials.responseData.dealerId,
            pageNumber,
            pageSize
          );
        }
      }
    }
  }, []);
  const getBindStudBuilderVendor = async (id, pageNo, pageSize) => {
    let inputData = {
      dealerID: id,
      filerType: "00",
      currentPage: pageNo,
      recordOnPage: pageSize,
      sortBy: "",
      tagFilter: 0,
      searchKeyChar: "",
      searchType: "",
    };
    try {
      await StudBuilderService.BindDataStudBuilderVendor(inputData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //   const onShowSizeChange = (current, pageSize) => {
  //     setPageNumber(current);
  //     setPageSize(pageSize);
  //     setTimeout(() => {
  //       getBindStudBuilderVendor(current, pageSize);
  //     }, 1000);
  //   };

  function handlePageNoChange(page, pageSize) {
    if (page !== pageNumber) {
      setPageNumber(page);
      getBindStudBuilderVendor(dealerId, page, pageSize);
    }
  }
  function handlePageSizeChange(current, size) {
    if (size !== pageSize) {
      setPageSize(size);
      getBindStudBuilderVendor(dealerId, current, size);
    }
  }

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {t("Manage Vendors")}{" "}
            </h4>
          </div>
        </div>
        <div className="divForm vendors-section">
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 600, y: 800 }}
            pagination={{
              total: totalCount,
              pageNo: pageNumber,
              pageSize: pageSize,
              pageSizeOptions: [10, 20, 50, 100],
              responsive: true,
              showSizeChanger: true,
              onChange: handlePageNoChange,
              onShowSizeChange: handlePageSizeChange,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StudBuilderManageVendors;
