import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import { useNavigate, useLocation } from "react-router-dom";
import adminToolsService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const [Passwordobj, setPasswordObj] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validations, setValidations] = useState({
    passwordVal: "",
    confirmPasswordVal: "",
    CheckSameVal: "",
  });
  const [visible, setVisible] = useState(false);
  const search = useLocation().search;
  const [token, setToken] = useState("");
  const [email, setemail] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const WhiteLabeling = useSelector((state) => state.loginReducer);
  const { Option } = Select;
  let navigate = useNavigate();
  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    const email = new URLSearchParams(search).get("email");
    setToken(token);
    setemail(email);
    if (token && email) {
      try {
        let inputData = {
          userEmail: email,
          resetToken: token,
        };
        adminToolsService
          .ForgotPasswordTokenVerify(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            var isSucceed = response.data.responseData.isSucceed;
            if (message === "Success") {
              if (isSucceed) {
                NotificationManager.Success(t("Token Verification Successful"));
                navigate("/");
              } else {
                navigate("/");
              }
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/");
    }
  }, []);

  const handleOnChange = (e) => {
    setPasswordObj({
      ...Passwordobj,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidation = () => {
    const validations = {
      confirmPasswordVal: "",
      passwordVal: "",
      CheckSameVal: "",
    };
    let isValid = true;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!Passwordobj.password) {
      isValid = false;
      validations.passwordVal = "Password is compulsory";
    } else if (!Passwordobj.password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal =
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character";
    } else {
      validations.passwordVal = "";
    }
    if (!Passwordobj.confirmPassword) {
      isValid = false;
      validations.confirmPasswordVal = "Confirm Password is compulsory";
    } else if (!Passwordobj.confirmPassword.match(strongRegex)) {
      isValid = false;
      validations.confirmPasswordVal =
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character";
    } else {
      validations.confirmPasswordVal = "";
    }
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        isValid = false;
        validations.CheckSameVal = "Both the entered password should match";
      } else {
        validations.CheckSameVal = "";
      }
    }

    setValidations(validations);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        userEmail: email,
        resetToken: token,
        password: Passwordobj.password,
      };
      setLoading(true);
      adminToolsService
        .ForgotPasswordChangeNewPassword(inputData)
        .then((response) => {
          var message = response.data.message;
          var isSucceed = response.data.responseData.isSucceed;

          if (message == "True") {
            if (isSucceed) {
              NotificationManager.success(t("Password Changed Successfully"));
              navigate("/");
              setLoading(false);
            } else {
              NotificationManager.error(t("Password Changed Fail"));
              navigate("/");
              setLoading(false);
            }
          } else {
            NotificationManager.error(t("Password Changed Fail"));
            navigate("/");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setPasswordObj({
      password: "",
      confirmPassword: "",
    });
    setValidations({
      passwordVal: "",
      confirmPasswordVal: "",
    });
  };

  const handlePasswordModal = () => {
    setUpdatePasswordModal(true);
  };

  const handleCancelModal = () => {
    setUpdatePasswordModal(false);
  };

  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };

  const handleConfirmPassShowHide = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };
  
  const { password, confirmPassword } = Passwordobj;
  const { passwordVal, confirmPasswordVal, CheckSameVal } = validations;
  return (
    <div className="login__section register__section container-fluid">
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="login__header">
              <div className="logo__section">
                <img src={logoImg} />
              </div>

              <div className="right__text">
                <p>Social Product Network for the Jewelry Industry</p>
              </div>
            </div>
          </div>
          <div className="col-lg-9 mx-auto">
            <div className="form__section">
              <div className="login__block">
                <h3>Reset Password</h3>
                <div>
                  {CheckSameVal && (
                    <p className="error-color-red">{CheckSameVal}</p>
                  )}
                  
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>New Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="password"
                        value={password}
                        onChange={handleOnChange}
                        className={passwordVal && "border__red"}
                      />
                      <span class="p-viewer" onClick={handlePassShowHide}>
                        <i
                          class={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div>
                      {passwordVal && (
                        <p className="error-color-red">{passwordVal}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Confirm Password</label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleOnChange}
                        className={confirmPasswordVal && "border__red"}
                      />
                      <span class="p-viewer" onClick={handleConfirmPassShowHide}>
                        <i
                          class={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div>
                      {confirmPasswordVal && (
                        <p className="error-color-red">{confirmPasswordVal}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 mx-auto mt-4">
                    <div className="input__block">
                      <Button
                        // onClick={handleSubmit}
                        onClick={handlePasswordModal}
                        type="primary"
                        htmlType="button"
                        className="primary-btn w-100"
                        loading={loading}
                      >
                        Update Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <div className="footer__section login__footer">
              <div className="left__block">
                <div className="footer__logo">
                  <img src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""} />
                </div>
                <div className="footer__menu">
                  <ul>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Privacy Statement</a>
                    </li>
                    <li>
                      <a href="#">Copyright 2021 GemFind</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right__block">
                <ul>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo1} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo2} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo3} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo4} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo5} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Password Confirmation Modal */}

          <Modal
            className="delete_conv_modal"
            centered
            visible={updatePasswordModal}
            onOk={handleSubmit}
            onCancel={handleCancelModal}
            width={400}
            footer={[
              <Button key="back" onClick={handleCancelModal}>
                {t("Cancel")}
              </Button>,
              <Button key="submit" type="primary" onClick={handleSubmit}>
                {t("Update")}
              </Button>,
            ]}
          >
            <div className="col-lg-12">
              <div className="modal__body">
                <div className="col-lg-12">
                  <div className="form__fields border-0 p-0">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <p className="subheading reset-pass-conf-text">
                          {t(
                            "Are You Sure You Want To Update The Password?"
                          )}
                        </p>
                        <h5 className="reset-pass-conf-heading">
                          {t("THIS CANNOT BE UNDONE")}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* End Modal */}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
