import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Input,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Switch,
	Tooltip,
	Table,
	Spin,
} from "antd";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import RetailerLogo from "../../assets/images/retailer-logo.jpeg";
import RetailerDataService from "../../services/retailer-retailer.service";
import { useSelector, useDispatch } from "react-redux";
import { setJewelryTab } from "../../actions/Contact/Contact";
import dealerProfileService from "../../services/dealer-profile.service";
import adminCampaignsService from "../../services/admin-campaigns.service";

const MakeAppoitments = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [dealerId, setDealerId] = useState();
	const { Option } = Select;
	const [bookAppointmentData, setBookAppointmentData] = useState({
		companyName: "",
		location: "",
		tradeShow: "",
		tradeDay: "",
		tradeTime: "",
		buyerName: "",
		description: "",
	});
	const [validations, setValidations] = useState({
		buyerNameVal: "",
		tradeShowVal: "",
	});
	const [disableBtn, setDisableBtn] = useState(true);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [retailerId, setRetailerId] = useState("");
	const [dealerData, setDealerData] = useState([]);
	const [locationData, setLocationData] = useState([]);
	const [tradeShowData, setTradeShowData] = useState([]);
	const [tradeDayData, setTradeDayData] = useState([]);
	const [tradeTimeData, setTradeTimeData] = useState([]);
	const [Loading, setLoading] = useState(false);
	const [SalesRep, setSalesRep] = useState([]);

	const [rowDetails, setRowDetails] = useState({});
	const location = useLocation();
	const [state, setState] = useState({});
	const [loadingprofileData, setLoadingProfileData] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [SalesRepId, setSalesRepId] = useState("");
	const loginDetials = useSelector((state) => state.loginReducer);
	useEffect(() => {
		if (location.state) {
			setRetailerId(location.state.id);
			handleGetRetailerProfile(
				location.state.id,
				loginDetials.loginDetials.responseData.dealerId
			);
			// setBookAppointmentData((prevState) => ({
			//   ...prevState,
			//   companyName: Number(location.state.id),
			// }));
		}
		handleGetDealersDropdown();
		handleGetLocationDropdown();
		handleGetLocationList(loginDetials.loginDetials.responseData.dealerId);
	}, []);
	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					handleGetTradeShowDropdown(
						loginDetials.loginDetials.responseData.dealerId
					);
					GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
				}
			}
		}
	}, []);
	const handleGetDealersDropdown = () => {
		try {
			setLoading(true);
			RetailerDataService.GetDealersDropdown()
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setDealerData(responseData);
							if (location?.state?.id) {
								var id = responseData.find(
									(item) => item.dealerId.toString() === location.state.id
								);
								if (id) {
									setBookAppointmentData((prevState) => ({
										...prevState,
										companyName: Number(location.state.id),
									}));
								}
							}
						} else {
							setDealerData([]);
						}
					} else {
						setLoading(false);
						setDealerData([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleGetLocationDropdown = () => {
		try {
			setLoading(true);
			RetailerDataService.GetLocationDropdown()
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setLocationData(responseData);
						} else {
							setLocationData([]);
						}
					} else {
						setLoading(false);
						setLocationData([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleGetLocationList = (id) => {
		let inputData = {
			dealerID: id.toString(),
		};
		try {
			setLoading(true);
			adminCampaignsService
				.LocationLoadDetails(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData.Table;

					if (message === "Success") {
						if (responseData?.length > 0) {
							setLocationList(responseData);
						} else {
							setLocationList([]);
						}
					} else {
						setLoading(false);
						setLocationList([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const handleGetTradeShowDropdown = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			setLoading(true);
			RetailerDataService.GetDealerSelectedShowforappointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setTradeShowData(responseData);
						} else {
							setTradeShowData([]);
						}
					} else {
						setLoading(false);
						setTradeShowData([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleGetTradeDayDropdown = (id) => {
		try {
			let inputData = {
				id: id,
			};
			setLoading(true);
			RetailerDataService.GetDealerSelectedTradShowDaysforappointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData.dayList;

					if (message === "Success") {
						if (responseData) {
							setTradeDayData(responseData);
						} else {
							setTradeDayData([]);
						}
					} else {
						setLoading(false);
						setTradeDayData([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleGetTradeTimeDropdown = (id) => {
		try {
			let inputData = {
				sTime: "8 AM",
				eTime: "12 PM",
				dealerID: dealerId,
				dealerShowID: bookAppointmentData.tradeShow,
				date: id,
			};
			setLoading(true);
			RetailerDataService.BindDropDownForTimeTradShowappointment(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							setTradeTimeData(responseData);
						} else {
							setTradeTimeData([]);
						}
					} else {
						setLoading(false);
						setTradeTimeData([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	function onSearch(val) {}

	const handleOnChange = (e, isSelect, name) => {
		setDisableBtn(false);
		if (isSelect === "select") {
			setBookAppointmentData({
				...bookAppointmentData,
				[name]: e,
			});
			if (name == "companyName") {
				handleGetRetailerProfile(e, dealerId);
				setRetailerId(e);
			}
		} else {
			setBookAppointmentData({
				...bookAppointmentData,
				[e.target.name]: e.target.value,
			});
			if (e.target.name === "buyerName") {
				setValidations((prevdata) => ({
					...prevdata,
					buyerNameVal: "",
				}));
			}
		}
		if (name === "tradeShow") {
			setValidations((prevdata) => ({
				...prevdata,
				tradeShowVal: "",
			}));
			handleGetTradeDayDropdown(e);
		}
		if (name === "tradeDay") {
			handleGetTradeTimeDropdown(e);
		}
	};

	const handleValidation = () => {
		const { buyerName, tradeShow } = bookAppointmentData;
		const validations = { buyerNameVal: "", tradeShowVal: "" };

		let isValid = true;

		if (!buyerName.trim()) {
			isValid = false;
			validations.buyerNameVal = t("Buyer Name is compulsory");
		}

		if (!tradeShow) {
			isValid = false;
			validations.tradeShowVal = t("Please select trade show.");
		}

		if (!isValid) {
			setValidations(validations);
		}

		return isValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();

		var Currentdate = mm + "/" + dd + "/" + yyyy;
		var today = new Date(),
			// Currenttime = today.getHours() + ":" + today.getMinutes();
			Currenttime =
				today.getHours() +
				":" +
				(today.getMinutes() < 10 ? "0" : "") +
				today.getMinutes();
		try {
			let inputData = {
				dealerId: dealerId,
				retailerID: retailerId,
				tradeShowId: bookAppointmentData.tradeShow,
				appintmentDate: bookAppointmentData.tradeDay
					? bookAppointmentData.tradeDay
					: Currentdate.toString(),
				productIds: "",
				filesId: "",
				appointmentTime: bookAppointmentData.tradeTime
					? bookAppointmentData.tradeTime
					: Currenttime.toString(),
				location: bookAppointmentData.location.toString(),
				buyerName: bookAppointmentData.buyerName,
				// message: bookAppointmentData.description.replace(/(<([^>]+)>)/gi, "")
				message: bookAppointmentData.description.toString(),
			};
			setLoading(true);
			RetailerDataService.RetailerSaveAppointmentdata(inputData)
				.then((response) => {
					var message = response.data.message;
					setLoading(false);
					if (message === "Success") {
						NotificationManager.success(t("Appointment Added Successfully."));
						handleRedirect();
					} else {
						NotificationManager.error(t("Appointment Not Added."));
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
		setBookAppointmentData({
			companyName: "",
			location: "",
			buyerName: "",
			description: "",
		});
		setValidations({
			buyerNameVal: "",
			tradeShowVal: "",
		});
	};

	let navigate = useNavigate();
	function handleRedirect() {
		dispatch(setJewelryTab("AppointmentsOnly"));
		navigate("/jewelryRetailerPermission", {
			state: { key: "AppointmentsOnly", id: retailerId },
		});
	}

	const handleGetRetailerProfile = (id, dealerID) => {
		try {
			let inputData = {
				retailerID: id,
				dealerId: dealerID,
				showBtn: true,
			};
			setLoadingProfileData(true);
			RetailerDataService.GetRetailerProfile(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData[0];
					if (message === "Success") {
						if (responseData) {
							setSalesRepId(
								responseData.salesUserId ? responseData.salesUserId : ""
							);
							setRowDetails(responseData);

							setLoadingProfileData(false);
						} else {
							setRowDetails({});
							setLoadingProfileData(false);
						}
					} else {
						setRowDetails({});
						setLoadingProfileData(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoadingProfileData(false);
				});
		} catch (error) {
			console.log(error);
			setLoadingProfileData(false);
		}
	};

	const GetSalesRepsList = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			RetailerDataService.GetSalesRepsList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setSalesRep(responseData);
						} else {
							setSalesRep([]);
						}
					} else {
						setSalesRep([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnSelectChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setSalesRepId(e);
			// setSalesRepId({
			//   ...SalesRepId,
			//   [name]: e
			// });
			// if (e == null || e == "") {
			// } else {
			//   handleSaveSalesReps(e);
			// }
		}
	};

	const handleSaveSalesReps = (id) => {
		try {
			let inputData = {
				// dealerID: 2704,
				dealerID: dealerId,
				retailerID: retailerId,
				salesRepId: id,
			};
			RetailerDataService.SaveSalesReps(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Sales Rep Added Successfully"));
						handleGetRetailerProfile(retailerId, dealerId);
					} else {
						NotificationManager.success(t("Sales Rep Not Added"));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<React.Fragment>
			<div className="prodetail__section permission__section">
				<div className="form__fields">
					<div className="row">
						<div className="col-md-12">
							<h4 className="workarea__heading mt-0">
								{" "}
								{t("Book Appointment")}{" "}
							</h4>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-9">
							<div className="row">
								<div className="col-md-4">
									<div className="input__block">
										<label> {t("Company Name")} </label>
										<Select
											className="border__grey"
											placeholder={t("Company Name")}
											name="companyName"
											value={bookAppointmentData.companyName}
											showSearch
											optionFilterProp="children"
											onChange={(e) =>
												handleOnChange(e, "select", "companyName")
											}
											onSearch={onSearch}
											filterOption={(input, option) =>
												option.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}>
											<Option value=""> {t("Company Name")} </Option>
											{dealerData &&
												dealerData.map((item) => {
													return (
														<Option value={item.dealerId}>
															{item.dealerCompany}
														</Option>
													);
												})}
										</Select>
									</div>
								</div>

								{/* Select trade show start */}

								<div className="col-md-4">
									<div className="input__block">
										<label> {t("Select trade show")} </label>
										<Select
											className={
												validations.tradeShowVal
													? "border__red"
													: "border__grey"
											}
											// className="border__grey"
											placeholder={t("Select trade show")}
											name="tradeShow"
											value={
												bookAppointmentData.tradeShow
													? bookAppointmentData.tradeShow
													: null
											}
											showSearch
											optionFilterProp="children"
											onChange={(e) => handleOnChange(e, "select", "tradeShow")}
											onSearch={onSearch}
											filterOption={(input, option) =>
												option.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}>
											<Option value="">{t("Select trade show")}</Option>
											{tradeShowData &&
												tradeShowData.map((item) => {
													return (
														// <Option value={item.countryID}>{item.name}</Option>
														<Option value={item.id}>{item.showName}</Option>
													);
												})}
										</Select>
										<div>
											{validations.tradeShowVal && (
												<p className="error-color-red">
													{validations.tradeShowVal}
												</p>
											)}
										</div>
									</div>
								</div>

								{/* select trade show end */}

								{/* Select Day start */}
								{bookAppointmentData.tradeShow ? (
									<div className="col-md-4">
										<div className="input__block">
											<label> {t("Select Day")} </label>
											<Select
												className="border__grey"
												placeholder= {t("Select Day")}
												name="tradeDay"
												value={
													bookAppointmentData.tradeDay
														? bookAppointmentData.tradeDay
														: null
												}
												showSearch
												optionFilterProp="children"
												onChange={(e) =>
													handleOnChange(e, "select", "tradeDay")
												}
												onSearch={onSearch}
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}>
												<Option value="">Select Day</Option>
												{tradeDayData &&
													tradeDayData.map((item) => {
														return (
															// <Option value={item.countryID}>{item.name}</Option>
															<Option value={item.date}>{item.dayName}</Option>
														);
													})}
											</Select>
										</div>
									</div>
								) : (
									""
								)}

								{/* select Day end */}

								{/* Select Time start */}
								{bookAppointmentData.tradeDay ? (
									<div className="col-md-4">
										<div className="input__block">
											<label> {t("Select Time")} </label>
											<Select
												className="border__grey"
												placeholder={t("Select Time")}
												name="tradeTime"
												value={
													bookAppointmentData.tradeTime
														? bookAppointmentData.tradeTime
														: null
												}
												showSearch
												optionFilterProp="children"
												onChange={(e) =>
													handleOnChange(e, "select", "tradeTime")
												}
												onSearch={onSearch}
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}>
												<Option value="">{t("Select Time")}</Option>
												{tradeTimeData &&
													tradeTimeData.map((item) => {
														return (
															// <Option value={item.countryID}>{item.name}</Option>
															<Option value={item.value}>{item.name}</Option>
														);
													})}
											</Select>
										</div>
									</div>
								) : (
									""
								)}

								{/* select Time end */}

								<div className="col-md-4">
									<div className="input__block">
										<label> {t("Location")} </label>
										<Select
											className="border__grey"
											placeholder={t("Location")}
											name="productType"
											value={
												bookAppointmentData.location
													? bookAppointmentData.location
													: null
											}
											showSearch
											optionFilterProp="children"
											onChange={(e) => handleOnChange(e, "select", "location")}
											onSearch={onSearch}
											filterOption={(input, option) =>
												option.children
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}>
											{/* <Option value="">Location</Option> */}
											{/* {locationData &&
                        locationData.map((item) => {
                          return (
                            
                            <Option value={item.name}>{item.name}</Option>
                          );
                        })} */}
											{locationList &&
												locationList?.map((item) => {
													return (
														<Option value={item.LocationName}>
															{item.LocationName}
														</Option>
													);
												})}
										</Select>
									</div>
								</div>
								<div className="col-md-4">
									<div className="input__block">
										<label> {t("Buyer Name")} </label>
										<input
											type="text"
											placeholder={t("Enter Buyer Name")}
											name="buyerName"
											value={
												bookAppointmentData.buyerName
													? bookAppointmentData.buyerName
													: ""
											}
											onChange={handleOnChange}
											className={validations.buyerNameVal && "border__red"}
										/>
										<div>
											{validations.buyerNameVal && (
												<p className="error-color-red">
													{validations.buyerNameVal}
												</p>
											)}
										</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="input__block">
										<label> {t("Appointment Description")} </label>
										<CKEditor
											editor={ClassicEditor}
											data={bookAppointmentData.description}
											onChange={(event, editor) => {
												const data = editor.getData();
												setBookAppointmentData((prevState) => ({
													...prevState,
													description: data,
												}));
											}}
										/>
									</div>
								</div>

								<div className="col-lg-3 mt-4">
									<div className="retailer__btndiv">
										<Button
											onClick={handleSubmit}
											className={disableBtn ? "disabled-btn" : "primary-btn"}
											disabled={disableBtn}>
											{/* <img src={Create_icon} alt="" />  */}
											{t("Book Appointment")}
										</Button>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-3 col-md-3">
							<Spin spinning={loadingprofileData}>
								{bookAppointmentData.companyName > 0 ? (
									<div className="right__section">
										<div className="profile__block">
											<span href="#" className="retailer__name">
												{rowDetails.dealerCompany
													? rowDetails.dealerCompany
													: null}
											</span>
											<Link
												to={"/retailerProfile"}
												state={{
													key: "Profile",
													id: retailerId,
												}}>
												<div className="logoImg__block p-0">
													<img src={rowDetails?.companylogo} />
													{/* <img src={rowDetails?.dealerImages} />   */}
												</div>
											</Link>
											<p className="activeFrom">
												{t("Active")} |{" "}
												{rowDetails.activity ? rowDetails.activity : null}
											</p>
										</div>

										<div className="retailer__dtls mb-4">
											<p>
												{rowDetails.dealerName ? rowDetails.dealerName : null}
											</p>
											<a href={`mailto:${rowDetails.dealerEmail}`}>
												{rowDetails.dealerEmail ? rowDetails.dealerEmail : null}
											</a>
											<p>
												{rowDetails.dealerPhone ? rowDetails.dealerPhone : null}
											</p>
											<a href={rowDetails.websiteAddress}>
												{rowDetails.websiteAddress
													? rowDetails.websiteAddress
													: null}
											</a>
											<hr />
										</div>

										<div className="links__block">
											<ul>
												<li>
													<Link
														to={"/retailerProfile"}
														state={{
															key: "Profile",
															id: retailerId,
															page:
																rowDetails.retailerStatusID == 0
																	? "ShowAccess"
																	: "DonotshowAccess",
														}}>
														<i
															class="fa fa-address-book-o"
															aria-hidden="true"></i>{" "}
														{t("View Profile")}
													</Link>
												</li>
												<li>
													<Link
														to={"/retailerProfile"}
														state={{
															key: "Conversation",
															id: retailerId,
															page:
																rowDetails.retailerStatusID == 0
																	? "ShowAccess"
																	: "DonotshowAccess",
														}}>
														<i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
														{t("Conversation")}
													</Link>
												</li>
												<li>
													<Link
														to={"/makeAppoitments"}
														state={{
															key: "Appointments",
															id: retailerId,
														}}>
														<i
															class="fa fa-calendar-check-o"
															aria-hidden="true"></i>{" "}
														{t("Make Appointment")}
													</Link>
												</li>
												<li>
													<Link
														to={"/retailerProfile"}
														state={{
															key: "Notes",
															id: retailerId,
															page:
																rowDetails.retailerStatusID == 0
																	? "ShowAccess"
																	: "DonotshowAccess",
														}}>
														<i
															class="fa fa-pencil-square-o"
															aria-hidden="true"></i>{" "}
														{t("Store Notes")}
													</Link>
												</li>
											</ul>
											<hr />
										</div>

										<div className="bottom__block">
											<div className="input__block">
												<label> {t("Sales Rep")} </label>
												<Select
													placeholder={t("Select Sales Rep")}
													value={SalesRepId ? SalesRepId : null}
													name="userId"
													showSearch
													optionFilterProp="children"
													onChange={(e) =>
														handleOnSelectChange(e, "select", "userId")
													}
													onSearch={onSearch}
													filterOption={(input, option) =>
														option.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}>
													<Option>Sales Rep</Option>
													{SalesRep.map((item) => {
														return (
															<Option value={item.userId}>{item.name} </Option>
														);
													})}
												</Select>
											</div>
										</div>
									</div>
								) : (
									<div className="right__section">
										<p className="no_data_found">{t("No Data Found")}</p>
									</div>
								)}
							</Spin>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default MakeAppoitments;
