import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Spin } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import DiamondLinksEmailTemplateService from "../../../services/diamondLinks-emailTemplate.service";

export const DiamondLinksEmailTemplate = (props) => {
  const { t } = useTranslation();
  const [typeName, setTypeName] = useState(null);
  const [visible, setVisible] = useState(false);
  /*API Binding Starts*/

  //Dropdown List Starts
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState(0);
  const [relaod, setReload] = useState(false);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);
  //Dropdown List Ends

  const handleEmailUpdate = () => {
    handleUpdateDiamondLinkEmailTemplateDetails(
      loginDetials.responseData.dealerId
    );
  };

  const refreshPage = () => {
    setVisible(false);
    setReload(!relaod);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleGetDiamondEmailTemplatesDetails = (name, id) => {
    try {
      let inputData = {
        dealerID: dealerId || id,
        typeName: name,
      };
      setContent("");
      setVisible(true);
      setTypeName(name);
      setLoading(true);
      DiamondLinksEmailTemplateService.GetDiamondEmailTemplatesDetails(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let diamondEmailTemplatesDetailsList =
            response.data.responseData[0].emailContent;
          if (message === "Success") {
            if (responseData) {
              setContent(responseData[0].emailContent);
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleUpdateDiamondLinkEmailTemplateDetails = (id) => {
    try {
      let inputData = {
        dealerID: dealerId || id,
        typeName: typeName,
        emailContent: content,
      };
      DiamondLinksEmailTemplateService.UpdateDiamondLinkEmailTemplateDetails(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(
              t("Email template has been saved successfully.")
            );
          } else {
            NotificationManager.error(
              t("Email template has not been updated.")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*API Binding Ends*/

  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0">
            {" "}
            {t("Diamond Link Emails")}{" "}
          </h4>
        </div>
      </div>

      <div className="emailTemplate">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Request More Info")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DSToRetailerInquires"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DSToSenderInquires"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Sender")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Email To Friend")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("DSToFriendEmailF")
                    }
                  >
                    {" "}
                    {t("Send Mail to Friend")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DSToRetailerEmailF"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("DSToSenderEmailF")
                    }
                  >
                    {" "}
                    {t("Send Mail to Sender")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Drop A Hint")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DSToRecipientDropAHint"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Recipient")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DSToRetailerDropAHint"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Schedule View")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DLToScheduleViewDiamondDetail"
                      )
                    }
                  >
                    {" "}
                    {t("Diamond Detail")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0">
            {" "}
            {t("Fancy Color Diamond Link Emails")}{" "}
          </h4>
        </div>
      </div>

      <div className="emailTemplate">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Request More Info")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "FCToRetailerInquires"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "FCToSenderInquires"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Sender")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Email To Friend")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("FCToFriendEmailF")
                    }
                  >
                    {" "}
                    {t("Send Mail to Friend")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "FCToRetailerEmailF"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails("FCToSenderEmailF")
                    }
                  >
                    {" "}
                    {t("Send Mail to Sender")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Drop A Hint")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "FCToRecipientDropAHint"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Recipient")}{" "}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "FCToRetailerDropAHint"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Retailer")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Schedule View")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DLToScheduleViewColorDiamondDetail"
                      )
                    }
                  >
                    {" "}
                    {t("Color Diamond Detail")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0">
            {" "}
            {t("Request A Diamond Email")}{" "}
          </h4>
        </div>
      </div>

      <div className="emailTemplate">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {t("Request A Diamond")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetDiamondEmailTemplatesDetails(
                        "DLToRequestADiamond"
                      )
                    }
                  >
                    {" "}
                    {t("Send Mail to Request A Diamond")}{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="emailTemplateEditorModal"
        title={t("Send Email")}
        centered
        visible={visible}
        onOk={() => {
          setVisible(false);
          handleEmailUpdate();
        }}
        onCancel={refreshPage}
        width={800}
        hieght={600}
        footer={[
          <Button key="back" onClick={refreshPage}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              handleEmailUpdate();
            }}
          >
            {t("Save")}
          </Button>,
        ]}
      >
        <div className="editor__section">
          <Spin spinning={loading}>
            <CKEditor
              key={relaod}
              editor={ClassicEditor}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              data={content ? content : ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default DiamondLinksEmailTemplate;
