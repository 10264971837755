import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RingBuilder from "../../assets/images/JC_Icon_RingBuilder_small.png";
import DiamondLink from "../../assets/images/JC_Icon_DiamondLink_small.png";
import PendantBuilder from "../../assets/images/JC_Icon_PendantBuilder_small.png";
import StudBuilder from "../../assets/images/JC_Icon_StudBuilder_small.png";
import Watchcustomizer from "../../assets/images/JC_Icon_WatchCustomizer_small.png";
import Data from "../../assets/images/Data.png";
import WebsiteSync from "../../assets/images/Website-Sync.png";
import { useTranslation } from "react-i18next";
import {
	setChecklinkHeaderMenuData,
	setOpenKeyHeaderMenuData,
	setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";
import MasterLink from "../../assets/images/JC_Icon_MasterLink1_small.png";

const AllApps = () => {
	const { t } = useTranslation();
	const [applicationPermisstionResponse, setApplicationPermisstionResponse] =
		useState({});
	const headerDetials = useSelector((state) => state.headerMenuReducer);
	const dispatch = useDispatch();
	useEffect(() => {
		if (headerDetials) {
			if (headerDetials.applicationPermisstionResponse) {
				setApplicationPermisstionResponse(
					headerDetials.applicationPermisstionResponse
				);
			}
		}
	}, []);
	const handleOpenSideMenu = (Menu) => {
		var MenuData = [`${Menu}`];

		dispatch(setOpenKeyHeaderMenuData(MenuData));
		if (Menu == "Diamond Link") {
			dispatch(setSelectSideMenu(["applicationPreviewDiamondLink"]));
		}
		if (Menu == "Ring Builder") {
			dispatch(setSelectSideMenu(["applicationPreviewRingBuilder"]));
		}
		if (Menu == "Stud Builder") {
			dispatch(setSelectSideMenu(["applicationPreviewStudBuilder"]));
		}
		if (Menu == "Pendant Builder") {
			dispatch(setSelectSideMenu(["applicationPreviewPedantBuilder"]));
		}
		if (Menu == "Watch Customizer") {
			dispatch(setSelectSideMenu(["applicationPreviewWatchCustomizer"]));
		}
		if ((Menu = "Website Sync")) {
			dispatch(setChecklinkHeaderMenuData("My Catalog"));
			dispatch(setSelectSideMenu(["SyncHistory"]));
		}
	};
	return (
		<React.Fragment>
			<div className="form__fields magento__mapping allapps__section">
				<div className="row">
					<div className="col-md-12">
						<div className="heading__block">
							<h4 class="workarea__heading mt-0 mb-0"> Apps</h4>
						</div>
					</div>

					<div className={"col-md-3 "}>
						<NavLink
							 
							to={
								applicationPermisstionResponse.diamondLink == false
									? "#"
									: "/applicationPreviewDiamondLink"
							}
							onClick={
								applicationPermisstionResponse.diamondLink == false
									? null
									: () => handleOpenSideMenu("Diamond Link")
							}>
							<div
								className={
									applicationPermisstionResponse.diamondLink == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={DiamondLink} width="80" />
								<p className="subheading linkText">{t("Diamond Link")}</p>
							</div>
						</NavLink>
					</div>

					<div className={"col-md-3 "}>
						<NavLink
							 
							title={
								applicationPermisstionResponse.ringBuilder == false
									? "Ring Builder Not Access"
									: "Ring Builder"
							}
							to={
								applicationPermisstionResponse.ringBuilder == false
									? "#"
									: "/applicationPreviewRingBuilder"
							}
							// onClick={() => handleOpenSideMenu("Ring Builder")}
							onClick={
								applicationPermisstionResponse.ringBuilder == false
									? null
									: () => handleOpenSideMenu("Ring Builder")
							}>
							<div
								className={
									applicationPermisstionResponse.ringBuilder == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={RingBuilder} width="80" />
								<p className="subheading linkText">{t("Ring Builder")}</p>
							</div>
						</NavLink>
					</div>

					<div className={"col-md-3 "}>
						<NavLink
							 
							title={
								applicationPermisstionResponse.pendantBuilder == false
									? "Pendant Builder Not Access"
									: "Pendant Builder"
							}
							to={
								applicationPermisstionResponse.pendantBuilder == false
									? "#"
									: "/applicationPreviewPedantBuilder"
							}
							// onClick={() => handleOpenSideMenu("Pendant Builder")}
							onClick={
								applicationPermisstionResponse.pendantBuilder == false
									? null
									: () => handleOpenSideMenu("Pendant Builder")
							}>
							<div
								className={
									applicationPermisstionResponse.pendantBuilder == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={PendantBuilder} width="80" />
								<p className="subheading linkText">{t("Pendant Builder")}</p>
							</div>
						</NavLink>
					</div>

					<div className={"col-md-3 "}>
						<NavLink
							 
							onClick={
								applicationPermisstionResponse.studBuilder == false
									? null
									: () => handleOpenSideMenu("Stud Builder")
							}
							to={
								applicationPermisstionResponse.studBuilder == false
									? "#"
									: "/applicationPreviewStudBuilder"
							}>
							<div
								className={
									applicationPermisstionResponse.studBuilder == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={StudBuilder} width="80" />
								<p className="subheading linkText">{t("Stud Builder")}</p>
							</div>
						</NavLink>
					</div>

					{/* <div className="col-md-3">
          <NavLink
              onClick={ () => handleOpenSideMenu("Website Sync") }
              to={ "/SyncHistory" }
            >
            <div className="apps__block">
              <img src={WebsiteSync} width="100" />
              <p className="subheading linkText">{t("Website Sync")}</p>
            </div>
            </NavLink>
          </div> */}

					<div className={"col-md-3 "}>
						<NavLink
							 
							// onClick={() => handleOpenSideMenu("Watch Customizer")}
							onClick={
								applicationPermisstionResponse.watchCustomizer == false
									? null
									: () => handleOpenSideMenu("Watch Customizer")
							}
							to={
								applicationPermisstionResponse.watchCustomizer == false
									? "#"
									: "/applicationPreviewWatchCustomizer"
							}
							// to="/watchCustomizer"
						>
							<div
								className={
									applicationPermisstionResponse.watchCustomizer == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={Watchcustomizer} width="80" />
								<p className="subheading linkText">{t("Watch Customizer")}</p>
							</div>
						</NavLink>
					</div>

					{/* <div className="col-md-3">
            <div className="apps__block">
              <img src={Data} width="100" />
              <p className="subheading linkText">{t("Data")}</p>
            </div>
          </div> */}

					<div className={"col-md-3 "}>
						<NavLink
							 
							title={
								applicationPermisstionResponse.masterLink == false
									? "Master Link Not Access"
									: "Master Link"
							}
							to={
								applicationPermisstionResponse.masterLink == false
									? "#"
									: "/MasterLinkApplicationPreview"
							}
							// onClick={() => handleOpenSideMenu("Pendant Builder")}
							onClick={
								applicationPermisstionResponse.masterLink == false
									? null
									: () => handleOpenSideMenu("Master Link")
							}>
							<div
								className={
									applicationPermisstionResponse.masterLink == false
										? "apps__block any-disable"
										: "apps__block"
								}>
								<img src={MasterLink} width="80" />
								<p className="subheading linkText">{t("Master Link")}</p>
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AllApps;
