import React, { useState, useEffect } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import Noun_arrow from "../../assets/images/noun_arrow.png";
import Rectangle from "../../assets/images/Rectangle.png";
import { Accordion } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import catalogJewelryService from "../../services/catalog-jewelry.service";

const Catlog = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const location = useLocation();
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const [DealerId, setDealerId] = useState(null);
  const [ProductDetails, setProductDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Infoloading, setInfoLoading] = useState(false);
  const [productDetails, setProductDetail] = useState({});
  const [dealerInfo, setDealerInfo] = useState({});

  useEffect(() => {
    if (location.state) {
      if (location.state.productData) {
        handleGetProductDetailsByID(
          location.state.productData.dealerID,
          location.state.productData.gfInventoryID
        );
        if (loginDetials) {
          if (loginDetials.responseData) {
            if (loginDetials.responseData.logInSucceeded) {
              setDealerId(loginDetials.responseData.dealerId);
            }
          }
        }
      }
    } else {
    }
  }, []);

  const handleGetProductDetailsByID = async (id, gfid) => {
    var inputData = {
      productDealerId: id,
      gfInventoryID: gfid,
      Retailerid: loginDetials?.responseData?.dealerId?.toString() ?? "",
    };
    try {
      setLoading(true);
      await catalogJewelryService
        .GetInfo(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setProductDetail(responseData);
            setProductDetails(responseData.viewProduct[0]);
            handleGetDealerInfo(location.state.productData.dealerID);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDealerInfo = async (id) => {
    var inputData = {
      productDealerId: id,
    };
    try {
      setInfoLoading(true);
      await catalogJewelryService
        .GetDealerInfo(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDealerInfo(responseData.result[0]);
            setInfoLoading(false);
          } else {
            setInfoLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setInfoLoading(false);
        });
    } catch (error) {
      console.log(error);
      setInfoLoading(false);
    }
  };

  const handleChangeJewelryPricingType = (id, Status) => {
    let inputData = {
      dealerID: id + "" || DealerId + "",
      productID: ProductDetails.gfInventoryID + "",
      status: Status,
      currentLoggedIn: id + "" || DealerId + "",
    };

    try {
      CatalogJewelryDataService.ChangeJewelryPricingType(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(t("Updated Successfully."));
          } else {
            NotificationManager.error(t("Data Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = (e) => {
    let value = "0";
    if (e) {
      value = "1";
    }
    setProductDetails((prevState) => ({
      ...prevState,
      showPricing: e,
    }));

    //setswitchValue(!switchValue);
    handleChangeJewelryPricingType(DealerId, value);
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="col-md-12">
          <div className="prod__headdiv">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <h3 className="subheading">
                  {" "}
                  <span onClick={() => navigate(-1)}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={Noun_arrow}
                      alt=""
                    />
                  </span>{" "}
                  {t("Product Details")}{" "}
                </h3>
              </div>
              <div className="col-lg-6 col-md-6 prod__switchdiv">
                <span className="price__txt"> {t("Pricing")} </span>
                <Switch
                  checkedChildren={t("On")}
                  unCheckedChildren={t("Off")}
                  checked={ProductDetails.showPricing == true ? true : false}
                  onChange={(e) => handleSwitch(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="prod__maindiv">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="prodimg__div">
                  <img
                    src={
                      ProductDetails && ProductDetails.imagepath
                        ? ProductDetails.imagepath
                        : null
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="prodesc__div">
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="tab__div mb-3"
                  >
                    <Tab eventKey="home" title={t("Items Details")}>
                      <Spin spinning={loading}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {t("Jewelery Characteristics")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Inventory #")}{" "}
                              </span>
                              <span className="desc__txt">
                                {ProductDetails && ProductDetails.gfInventoryID
                                  ? ProductDetails.gfInventoryID
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("SKU #")} </span>
                              <span className="desc__txt">
                                {productDetails.lblSKU
                                  ? productDetails.lblSKU
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Category")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblCategories
                                  ? productDetails.lblCategories
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("Style")} </span>
                              <span className="desc__txt">
                                {ProductDetails.styleNumber
                                  ? ProductDetails.styleNumber
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Product Name")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblProductName
                                  ? productDetails.lblProductName
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Collection")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblCollection
                                  ? productDetails.lblCollection
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("Gender")} </span>
                              <span className="desc__txt">
                                {productDetails.lblGender2
                                  ? productDetails.lblGender2
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Setting")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblSetting
                                  ? productDetails.lblSetting
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Metal Type")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblMetalType
                                  ? productDetails.lblMetalType
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Metal Color")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblMetalColor
                                  ? productDetails.lblMetalColor
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Cost ($)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblPrice
                                  ? ProductDetails.currency +
                                    productDetails.lblPrice
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Retail Price")}{" "}
                              </span>
                              <span className="desc__txt">
                                {ProductDetails.retailPrice == "0" ||
                                ProductDetails.retailPrice == null
                                  ? "Call For Price"
                                  : ProductDetails.currency +
                                    ProductDetails.retailPrice}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("MSRP ($)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {ProductDetails.msrp == "0" ||
                                ProductDetails.msrp == null
                                  ? "Call For Price"
                                  : ProductDetails.currency +
                                    ProductDetails.msrp}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Width (mm)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblWidth
                                  ? productDetails.lblWidth
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Thickness (mm)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblThickness
                                  ? productDetails.lblThickness
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Length (in)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblLength
                                  ? productDetails.lblLength
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Weight (gm)")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblWeight
                                  ? productDetails.lblWeight
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Last Updated On")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblLastUpdatedDate
                                  ? productDetails.lblLastUpdatedDate
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Description")}{" "}
                              </span>
                              <span className="desc__txt">
                                {productDetails.lblProductDesc
                                  ? productDetails.lblProductDesc
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="profile" title={t("Dealer Info")}>
                      <Spin spinning={Infoloading}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {t("Dealer Information")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Dealer ID")}{" "}
                              </span>
                              <span className="desc__txt">
                                {dealerInfo.dealerid
                                  ? dealerInfo.dealerid
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Company")}{" "}
                              </span>
                              <span className="desc__txt">
                                {dealerInfo.dealercompany
                                  ? dealerInfo.dealercompany
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Contact Person")}{" "}
                              </span>
                              <span className="desc__txt">
                                {dealerInfo.dealername
                                  ? dealerInfo.dealername
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Address")}{" "}
                              </span>
                              <span className="desc__txt">
                                {dealerInfo.dealeraddress
                                  ? dealerInfo.dealeraddress
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("City")} </span>
                              <span className="desc__txt">
                                {dealerInfo.dealercity
                                  ? dealerInfo.dealercity
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("State")} </span>
                              <span className="desc__txt">
                                {dealerInfo.dealerstate
                                  ? dealerInfo.dealerstate
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt">
                                {" "}
                                {t("Country")}{" "}
                              </span>
                              <span className="desc__txt">
                                {dealerInfo.dealercountry
                                  ? dealerInfo.dealercountry
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("Phone")} </span>
                              <span className="desc__txt">
                                {dealerInfo.dealerphone
                                  ? dealerInfo.dealerphone
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("Fax")} </span>
                              <span className="desc__txt">
                                {dealerInfo.dealerfax
                                  ? dealerInfo.dealerfax
                                  : "-"}
                              </span>
                            </div>
                            <div className="desc__div">
                              <span className="head__txt"> {t("E-mail")} </span>
                              <span className="desc__txt">
                                {dealerInfo.dealeremail
                                  ? dealerInfo.dealeremail
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Catlog;
