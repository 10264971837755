import React, { useState, useEffect } from "react";
import { Input, Select, Button, Table, Modal, Tooltip, Spin } from "antd";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import Create_icon from "../../../assets/images/create_icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import MappingHelpDocs from "../../../assets/images/maping_help.png";
import noun_upload_import from "../../../assets/images/icons/noun_upload_import.svg";
import noun_upload_export from "../../../assets/images/icons/noun_upload_export.svg";
import close from "../../../assets/images/icons/close.svg";
import Excel_icon from "../../../assets/images/Excel_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import { useSelector } from "react-redux";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import config from "../../../helper/config";

const { Option } = Select;
const { Search } = Input;

const intialImportMappingFile = {
  ImagefileName: null,
  Imagefile: [],
  chooseFormat: "",
  templateMapping: "",
  Error: {},
};

export const DiamondDataMaping = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mappedAttributesList, setMappedAttributesList] = useState([]);
  const [notMappedAttributesList, setNotMappedAttributesList] = useState([]);
  const [dataMappingList, setDataMappingList] = useState([]);
  const [TableMappingList, setTableMappingList] = useState([]);
  const [editSelectedDealerColumn, setEditSelectedDealerColumn] = useState(0);
  const [DealerId, setDealerId] = useState();
  const [Mapped, setMapped] = useState();
  const [unMapped, setUnMapped] = useState();
  const [mapLoading, setMapLoading] = useState(false);
  const [ModalLoading, setModalLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [removeAttributeModal, setRemoveAttributeModal] = useState(false);
  const [uploadMappingModal, setUploadMappingModal] = useState(false);
  const [removeattribute, setRemoveattribute] = useState({
    jewelryColumnMappingId: "",
    hasValueMapping: "",
  });
  const [importMappingFile, setImportMappingFile] = useState(
    intialImportMappingFile
  );

  const [importMappingLoading, setImportMappingLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("mapped");
  const [addData, setAddData] = useState({
    attribute: "",
  });
  const [validation, setValidations] = useState({
    attributeVal: "",
  });
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [saveLoader, setSaveLoader] = useState(false);
  const [vendorStatusID, setVendorStatusID] = useState(0);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDiProductList(loginDetials.responseData.dealerId);
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
      }
    }
  }, []);
  const onSearch = (value) => console.log(value);

  const chooseFormatList = [
    {
      title: "Comma",
      value: "COMMA",
    },
    {
      title: "Tab",
      value: "TAB",
    },
  ];

  const handleEditDealerColumn = (id) => {
    setEditSelectedDealerColumn(id);
  };

  const handleEditDealerColumnValue = (id, e) => {
    const tempTableMappingList = TableMappingList.map((x) => {
      if (x.columnId === id) {
        return { ...x, columnName: e.target.value };
      } else return x;
    });
    setTableMappingList(tempTableMappingList);
  };

  const handleCancel = () => {
    setVisible2(false);
    setEditSelectedDealerColumn(null);
    setAddData({
      attribute: "",
    });
    setValidations({
      attributeVal: "",
    });
  };

  const handleAddOnchange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddData({
        ...addData,
        [name]: e,
      });
    } else {
      setAddData({
        ...addData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "attribute") {
        setValidations((prevdata) => ({
          ...prevdata,
          attributeVal: "",
        }));
      }
    }
  };

  const handleValidation = () => {
    const { attribute } = addData;

    const validations = {
      attributeVal,
    };
    let isValid = true;

    if (!attribute.trim()) {
      isValid = false;
      validations.attributeVal = t("Attribute is compulsory");
    } else {
      validations.companyNameVal = "";
    }

    setValidations(validations);
    return isValid;
  };

  const fileUpload = async (e, name) => {
    let tempState = importMappingFile;
    let allFiles = [];
    let selectedFiles = e.target.files[0];
    if (name == "mappingFile") {
      if (e.target.files[0].name.match(/.(xls|txt|csv)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
        }
      } else {
        tempState.Error["Imagefile"] = t("Please Select csv file only");
        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    }
    setImportMappingFile((prevState) => ({
      ...prevState,
      ...importMappingFile,
    }));
  };

  const handleImportMappingOnchange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setImportMappingFile({
        ...importMappingFile,
        [name]: e,
      });

      if (name == "chooseFormat") {
        importMappingFile.Error["chooseFormat"] = "";
      }
    } else {
      setImportMappingFile({
        ...importMappingFile,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleImportMappingValidation = () => {
    const { chooseFormat, ImagefileName } = importMappingFile;

    let isValid = true;
    let tempState = importMappingFile;

    if (!chooseFormat.trim()) {
      isValid = false;
      tempState.Error["chooseFormat"] = t("Attribute is compulsory");
    } else {
      tempState.Error["chooseFormat"] = null;
    }

    if (ImagefileName == null) {
      isValid = false;
      tempState.Error["Imagefile"] = t("Please Select file");
    } else {
      tempState.Error["Imagefile"] = null;
    }

    setImportMappingFile((prevdata) => ({
      ...prevdata,
      ...importMappingFile,
    }));
    return isValid;
  };

  const handleImportMappingCancel = () => {
    setVisible1(false);
    document.getElementById("file-upload").value = "";
    setImportMappingFile({
      ImagefileName: null,
      Imagefile: [],
      chooseFormat: "",
      templateMapping: "",
      Error: {},
    });
  };

  // API binding Start

  const handleGetDiamondMappAttributesList = (id) => {
    setMapLoading(true);
    let inputData = {
      dealerid: id,
    };
    CatalogDiamondDataService.GetDiamondMappAttributesList(inputData)
      .then((response) => {
        setMapLoading(false);
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setMappedAttributesList(responseData.diamondMappAttributes);
          handleGetDiamondColumnsOfDealerUpload(
            loginDetials.responseData.dealerId
          );
          handleGetDiamondMappNotMappCount(loginDetials.responseData.dealerId);
        } else {
          setMappedAttributesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setMapLoading(false);
      })
      .finally(() => {
        setMapLoading(false);
      });
  };
  const handleGetDiamondNotMappAttributesList = (id) => {
    let inputData = {
      dealerid: id || 0,
    };
    setMapLoading(true);
    CatalogDiamondDataService.GetDiamondNotMappAttributesList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setNotMappedAttributesList(responseData.diamondMappAttributes);
        } else {
          setNotMappedAttributesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setMapLoading(false);
      })
      .finally(() => {
        setMapLoading(false);
      });
  };

  const handleGetDiamondColumnsOfDealerUpload = (id) => {
    setModalLoading(true);
    let inputData = {
      dealerId: id,
    };
    CatalogDiamondDataService.GetDiamondColumnsOfDealerUpload(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setTableMappingList(responseData);
        } else {
          setTableMappingList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalLoading(false);
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  // Add New

  const handleAddDiamondColumnsOfDealerUpload = () => {
    let isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    let inputData = {
      dealerId: DealerId,
      columnID: 0,
      columnName: addData.attribute,
      dimFileName: "gemfindmk_310.csv",
    };
    CatalogDiamondDataService.UpdateDiamondColumnsOfDealerUpload(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message == "Data updated and Insert successfully") {
          if (responseData) {
            NotificationManager.success(t("Column Added Successfully."));
            handleGetDiamondColumnsOfDealerUpload(DealerId);
            handleReferesh();
            setAddData({
              attribute: "",
            });
          } else {
            NotificationManager.error(t("Column Not Added."));
          }
        } else {
          NotificationManager.error(t("Column Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateDiamondColumnsOfDealerUpload = () => {
    if (editSelectedDealerColumn) {
      let tempData = TableMappingList.filter(
        (x) => x.columnId == editSelectedDealerColumn
      );

      let inputData = {
        dealerId: tempData[0].dealerId,
        columnID: tempData[0].columnId,
        columnName: tempData[0].columnName,
        dimFileName: tempData[0].dimFileName,
      };
      setSaveLoader(true);
      CatalogDiamondDataService.UpdateDiamondColumnsOfDealerUpload(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Data updated and Insert successfully") {
            if (responseData) {
              setEditSelectedDealerColumn(null);
              NotificationManager.success(t("Column Updated Successfully."));
              handleGetDiamondColumnsOfDealerUpload(DealerId);
              setVisible2(false);
            } else {
              NotificationManager.error(t("Column Not Updated."));
              setVisible2(false);
            }
          } else {
            NotificationManager.error(t("Column Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setSaveLoader(false);
        })
        .finally(() => {
          setSaveLoader(false);
        });
    }
  };

  const handleGetDiamondMappNotMappCount = (id) => {
    try {
      let inputData = {
        dealerId: id,
      };
      CatalogDiamondDataService.GetDiamondMappNotMappCount(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setMapped(responseData.mappedColumnCount);
            setUnMapped(responseData.unMappedColumnCount);
          } else {
            setMapped();
            setUnMapped();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDiProductList = async (id) => {
    setTableLoading(true);
    let inputData = {
      dealerId: id,
    };
    await CatalogDiamondDataService.GetDiamondMappingList(inputData)
      .then((response) => {
        setTableLoading(false);
        let message = response.data.message;
        let responseData = response.data.responseData;

        if (message === "Success") {
          setDataMappingList(responseData);
          handleGetDiamondMappAttributesList(id);
          handleGetDiamondNotMappAttributesList(id);
          handleGetDiamondMappNotMappCount(id);
          if (responseData) {
            handleGetDiamondAttributeDetailsList(responseData[0].columnId);
          }
        } else {
          setDataMappingList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setTableLoading(false);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const handleMappDiamondDealerColumns = async (gColumnId, dColumnId) => {
    let inputData = {
      dealerID: DealerId,
      gemfindColumnId: gColumnId,
      dealerColumnId: dColumnId,
    };
    setTableLoading(true);
    await CatalogDiamondDataService.MappDiamondDealerColumns(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData.returnId) {
            NotificationManager.success(t("Attribute Mapped Successfully."));
            handleReferesh();
          }
        } else {
          NotificationManager.error(t("Attribute Not Mapped."));
        }
      })
      .catch((error) => {
        console.log(error);
        setTableLoading(false);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };
  const handleRemoveMappDiamondDealerColumns = async (row) => {
    let inputData = {
      gemfindColumnId: removeattribute.columnId,
      dealerColumnId: removeattribute.selectedValue,
    };
    await CatalogDiamondDataService.RemoveMappDiamondDealerColumns(inputData)
      .then((response) => {
        let message = response.data.message;

        if (message === "Success") {
          NotificationManager.success(t("Attribute Unmapped Successfully."));
          handleGetDiProductList(DealerId);
          setRemoveAttributeModal(false);
          setRemoveattribute({});
        } else {
          NotificationManager.error(t("Attribute Not Unmapped."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownloadDiamondAttributesMapFile = () => {
    let inputData = {
      dealerId: DealerId,
      dimFileName: "",
    };
    setSubmitLoading(true);
    CatalogDiamondDataService.DownloadDiamondAttributesMapFile(inputData)
      .then((response) => {
        let fileName =
          "DiamondMapping(" +
          DealerId +
          ")_" +
          new Date().toJSON().slice(0, 10).replace(/-/g, "") +
          ".xlsx";

        BlobToDownloadFile(response.data, fileName);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleResetDiamondDataMapping = () => {
    let inputData = {
      dealerId: DealerId,
      dimFileName: "",
    };
    CatalogDiamondDataService.ResetDiamondDataMapping(inputData)
      .then((response) => {
        let message = response.data.message;

        if (message === "Success") {
          NotificationManager.success(t("Mapping Reset Successfully."));
          handleGetDiProductList(DealerId);
        } else {
          NotificationManager.error(t("Mapping Not Reset."));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadDiamondMappSheet = () => {
    let isValid = handleImportMappingValidation();

    if (!isValid) {
      return false;
    }
    const formData = new FormData();
    formData.append("DimFileName", importMappingFile.ImagefileName);
    formData.append("UploadSheet", importMappingFile.Imagefile[0]);
    formData.append("FileFormatName", importMappingFile.chooseFormat);
    formData.append("DealerId", DealerId);
    setImportMappingLoading(true);
    CatalogDiamondDataService.UploadDiamondMappSheet(formData)
      .then((response) => {
        let responseData = response.data.responseData;
        setImportMappingLoading(false);
        if (
          responseData ==
          "Invalid File,please correct your ColumnsHeader with export file columns name"
        ) {
          setVisible1(false);
          setUploadMappingModal(false);
          NotificationManager.error(
            t(
              "Invalid File,Please Correct Your ColumnsHeader With Export File Columns Name."
            )
          );
          setImportMappingFile(intialImportMappingFile);
        } else if (responseData == "Upload Fails--your file is already exist") {
          setVisible1(false);
          setUploadMappingModal(false);
          NotificationManager.error(
            t(
              "Invalid File,Please Correct Your ColumnsHeader With Export File Columns Name."
            )
          );
          setImportMappingFile(intialImportMappingFile);
        } else if (responseData == "Data not available") {
          setVisible1(false);
          setUploadMappingModal(false);
          NotificationManager.error(t("Data Not Available"));
          setImportMappingFile(intialImportMappingFile);
        } else if (responseData == "Data Update Successfully") {
          setVisible1(false);
          setUploadMappingModal(false);
          NotificationManager.success(t("File Uploaded Successfully"));
          handleGetDiProductList(DealerId);
          setImportMappingFile(intialImportMappingFile);
        } else {
          NotificationManager.error(t("Data Map Not Reset."));
          setVisible1(false);
          setUploadMappingModal(false);
        }
      })
      .catch((error) => {
        setImportMappingLoading(false);
        console.log(error);
      })
      .finally(() => {
        setImportMappingLoading(false);
      });
  };

  const handleGetDiamondAttributeDetailsList = (id) => {
    let inputData = {
      columnID: id,
    };
    setSelectedColumnId(id);
    CatalogDiamondDataService.GetDiamondAttributeDetailsList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setRowDetails(responseData);
          } else {
            setRowDetails({});
          }
        } else {
          setRowDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveAttribute = (row) => {
    setRemoveAttributeModal(true);
    setRemoveattribute({
      columnId: row.columnId,
      selectedValue: row.selectedValue,
    });
  };

  const handleCancelRemoveAtrributeModal = () => {
    setRemoveAttributeModal(false);
    setRemoveattribute({
      columnId: 0,
      selectedValue: 0,
    });
  };

  const columns1 = [
    {
      title: t("Column ID"),
      dataIndex: "columnId",
    },
    {
      title: t("Column Name"),
      dataIndex: "columnName",
      render: (item, row) => {
        return (
          <React.Fragment>
            <div className="input__block">
              {row.columnId == editSelectedDealerColumn ? (
                <input
                  className="mr-2"
                  type="text"
                  value={item}
                  onChange={(e) => {
                    handleEditDealerColumnValue(row.columnId, e);
                  }}
                />
              ) : (
                <span>{item}</span>
              )}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: t("Action"),
      width: 100,
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img
              src={Edit_icon}
              onClick={() => handleEditDealerColumn(row.columnId)}
              alt=""
            />
          </div>
        </div>
      ),
    },
  ];

  const { attributeVal } = validation;
  const handleReferesh = () => {
    handleGetDiamondMappAttributesList(DealerId);
    handleGetDiamondMappNotMappCount(DealerId);
    handleGetDiamondNotMappAttributesList(DealerId);
  };

  const handleDropList = (e, dropColumnId) => {
    e.preventDefault();
    let data = e.dataTransfer.getData("text", e.target.id);
    let tempAttributesList = {};
    let objTemp = {};
    if (activeTab == "mapped") {
      tempAttributesList = mappedAttributesList;
      objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
    } else {
      tempAttributesList = notMappedAttributesList;
      objTemp = tempAttributesList.filter((x) => x.columnId == data)[0];
    }

    let tempdataMappingList = dataMappingList;

    handleMappDiamondDealerColumns(
      tempdataMappingList.filter((x) => x.columnId == dropColumnId)[0].columnId,
      data
    );
    tempdataMappingList.filter(
      (x) => x.columnId == dropColumnId
    )[0].mappedValue = objTemp.columnName;
    tempdataMappingList.filter(
      (x) => x.columnId == dropColumnId
    )[0].selectedValue = data;

    setDataMappingList([...tempdataMappingList]);
  };

  const handleDragList = (e) => {
    e.dataTransfer.setData("text", e.target.id);
  };

  const handleAllowDrop = (e) => {
    e.preventDefault();
  };

  const handleCloseUploadMappingModal = () => {
    setUploadMappingModal(false);
  };

  const handleOpenUploadMappingModal = () => {
    let isValid = handleImportMappingValidation();
    setUploadMappingModal(isValid);
  };

  // On Tab Change
  const handlTabChanges = (e) => {
    setActiveTab(e);

    if (e == "mapped") {
      handleGetDiamondMappAttributesList(DealerId);
    } else if (e == "nonmapped") {
      handleGetDiamondNotMappAttributesList(DealerId);
    }
  };

  // HandleNew Column
  const handleAddNewColumn = () => {
    setVisible2(true);
    handleGetDiamondColumnsOfDealerUpload(DealerId);
  };

  return (
    <div className="prodetail__section">
      {vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <div className="form__fields">
          <div className="prod__maindiv prodmarkup__secion essential__section">
            <div className="row">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <h4 className="workarea__heading mt-0 mb-0">
                      {" "}
                      {t("Diamond Mapping")}{" "}
                    </h4>
                  </div>
                  <div className="col-lg-8">
                    <div className="btns__div">
                      <Button
                        className="primary-btn"
                        onClick={handleResetDiamondDataMapping}
                      >
                        {t("Reset Mapping")}
                      </Button>

                      <Button
                        className="primary-btn ml-2"
                        onClick={() => setVisible1(true)}
                      >
                        <img src={noun_upload_export} alt="" />{" "}
                        {t("Import Mapping")}
                      </Button>
                      <Button
                        onClick={handleDownloadDiamondAttributesMapFile}
                        className="primary-btn ml-2"
                        loading={submitLoading}
                      >
                        <img src={noun_upload_import} alt="" />{" "}
                        {t("Export Mapping")}
                      </Button>
                      <Button
                        className="primary-btn ml-2"
                        onClick={() => handleAddNewColumn()}
                      >
                        <img src={Create_icon} alt="" /> {t("Add New Column")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3">
                <div className="row">
                  <div className="col-lg-9">
                    <Table
                      loading={tableLoading}
                      className="datamapping__tbl"
                      columns={[
                        {
                          title: (
                            <span className="font__bold">
                              {t("Attribute")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={t(
                                  "This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "columnName",
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {t("Type")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={t("JewelCloud defined column name.")}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "attributeType",
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {t("Requirement")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={t(
                                  "Data uploads will fail if these columns do not contain a value."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "requirements",
                        },
                        {
                          title: (
                            <span className="font__bold">
                              {t("Your Attribute")}
                              <Tooltip
                                placement="right"
                                className="ml-1"
                                title={t(
                                  "This is the field that corresponds with the JewelCloud defined column."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </span>
                          ),
                          dataIndex: "mappedValue",
                          render: (item, row) => {
                            return (
                              <ul className="tags__ul">
                                <li
                                  title={item}
                                  className="tags__lbl"
                                  key={row.columnId}
                                  onDragOver={handleAllowDrop}
                                  id={row.columnId}
                                  onDrop={(e) =>
                                    handleDropList(e, row.columnId)
                                  }
                                >
                                  <span className="tag__name">{item}</span>
                                  {row.selectedValue > 0 ? (
                                    <span>
                                      <img
                                        onClick={() =>
                                          handleRemoveAttribute(row)
                                        }
                                        src={close}
                                        alt=""
                                        className="close__icn"
                                      />
                                    </span>
                                  ) : null}
                                </li>
                              </ul>
                            );
                          },
                        },
                      ]}
                      dataSource={dataMappingList}
                      scroll={{ y: 800 }}
                      pagination={false}
                      rowClassName={(record) =>
                        record.columnId == selectedColumnId ? "row-active" : ""
                      }
                      onRow={(record, recordIndex) => ({
                        onClick: (event) => {
                          handleGetDiamondAttributeDetailsList(record.columnId);
                        },
                      })}
                    />
                  </div>

                  <div className="col-lg-3">
                    <Spin spinning={mapLoading}>
                      <div className="prodesc__div">
                        <Tabs
                          defaultActiveKey="mapped"
                          id="uncontrolled-tab-example"
                          className="tab__div"
                          onSelect={(e) => handlTabChanges(e)}
                          activeKey={activeTab}
                        >
                          <Tab eventKey="mapped" title={t("Mapped")}>
                            <div className="tab__contentdiv">
                              <div className="col-lg-12">
                                <ul className="tags__ul">
                                  {mappedAttributesList &&
                                    mappedAttributesList.map((item) => {
                                      return (
                                        <React.Fragment>
                                          <li
                                            className="tags__lbl active "
                                            draggable={true}
                                            onDragStart={handleDragList}
                                            key={item.columnId}
                                            id={item.columnId}
                                          >
                                            <span
                                              className="tag__name"
                                              title={item.columnName}
                                            >
                                              {item.columnName}
                                            </span>
                                          </li>
                                        </React.Fragment>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="nonmapped" title={t("Non Mapped")}>
                            <div className="tab__contentdiv">
                              <div className="col-lg-12">
                                <ul className="tags__ul">
                                  {notMappedAttributesList?.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <li
                                          className="tags__lbl active"
                                          onDragStart={handleDragList}
                                          key={item.columnId}
                                          id={item.columnId}
                                          draggable={true}
                                        >
                                          <span
                                            className="tag__name"
                                            title={item.columnName}
                                          >
                                            {item.columnName}
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3">
                <div className="desc__div">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="workarea__heading">
                        {t("Attribute Detail")}
                      </h3>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt"> {t("Name")} </h4>
                        <p className="desc__txt">{rowDetails.columnName}</p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt"> {t("Accepted Values")} </h4>
                        <p className="desc__txt">
                          {rowDetails.attributeType === "Text Field" ||
                          rowDetails.attributeType === "Fixed Labels" ||
                          rowDetails.attributeType === "TextField"
                            ? "Alphanumeric"
                            : rowDetails.attributeType === "Decimal Field"
                            ? "Float,Int"
                            : rowDetails.attributeType === "Date Field"
                            ? "Date"
                            : rowDetails.attributeType === "Boolean Field" ||
                              rowDetails.attributeType === "Boolean"
                            ? "Yes/No"
                            : rowDetails.attributeType === "Numeric" ||
                              rowDetails.attributeType === "Numeric Field"
                            ? "Number"
                            : "-"}
                        </p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt"> {t("Required")} </h4>
                        <p className="desc__txt">
                          {rowDetails.fieldValidationn === "Required"
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt"> {t("Example")} </h4>
                        <p className="desc__txt">{rowDetails.example}</p>
                      </div>
                      <div className="mt-4">
                        <h4 className="head__txt"> {t("Field Type")} </h4>
                        <p className="desc__txt">
                          {rowDetails.attributeType === "Text Field" ||
                          rowDetails.attributeType === "Fixed Labels" ||
                          rowDetails.attributeType === "TextField"
                            ? "Alphanumeric"
                            : rowDetails.attributeType === "Decimal Field"
                            ? "Decimal"
                            : rowDetails.attributeType === "Date Field"
                            ? "Date"
                            : rowDetails.attributeType === "Boolean Field" ||
                              rowDetails.attributeType === "Boolean"
                            ? "Boolean"
                            : rowDetails.attributeType === "Numeric" ||
                              rowDetails.attributeType === "Numeric Field"
                            ? "Integer"
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <h4 className="head__txt"> {t("Definition")} </h4>
                        <p className="desc__txt">{rowDetails.defination}</p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="count__div">
                        <div className="count__indiv">
                          <span className="no__txt">{Mapped}</span>
                          <span className="counthead__txt">
                            {" "}
                            {t("Mapped")}{" "}
                          </span>
                        </div>
                        <div className="count__indiv">
                          <span className="no__txt">{unMapped}</span>
                          <span className="counthead__txt">
                            {" "}
                            {t("Remaining")}{" "}
                          </span>
                        </div>
                        <div className="reset__div">
                          <i class="fa fa-refresh" onClick={handleReferesh}></i>
                        </div>
                      </div>
                      <div className="content__div mt-4">
                        <p className="conthead__txt">
                          {t("Map Your Content")} <br />{" "}
                          {t("Drag & Drop Your Attributes")}
                        </p>
                        <span className="desc__txt">
                          {t(
                            "Match Your attributes with the jewel cloud industry standard"
                          )}
                        </span>
                      </div>
                      <div className="content__div mapping-help-docs-sp mt-4">
                        <p className="mb-0">
                          <a
                            className="conthead__txt"
                            href={`${config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Required%20Fields.xls`}
                            download
                          >
                            <img
                              src={MappingHelpDocs}
                              alt=""
                              className="mr-1"
                            />
                            {t("Mapping Help Docs")}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                title={t("Attribute Value Map")}
                className="datamapping__modal"
                visible={visible}
                width={600}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setVisible(false)}
                  >
                    {t("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <span>
                          {t(
                            "Match Your Variables to the jewelCloud Industry Standard"
                          )}
                        </span>
                      </div>
                      <div className="col-lg-6">
                        <div className="inptsrch__div">
                          <Search
                            placeholder={t("input search text")}
                            onSearch={onSearch}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="head__div">
                      <span> {t("Categories")} </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div>
                            <span> {t("Accessories")} </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="input__block">
                                  <div className="row">
                                    <div className="col-md-3 align-self-center">
                                      <label className="mb-0">
                                        {t("Accessories")} <span>*</span>
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder={t("Enter Accessories")}
                                        name=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input__block">
                                  <div className="row">
                                    <div className="col-md-3 align-self-center">
                                      <label className="mb-0">
                                        {t("Tiaras")} <span>*</span>
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder={t("Enter Tiaras")}
                                        name=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="input__block">
                                  <div className="row">
                                    <div className="col-md-3 align-self-center">
                                      <label className="mb-0">
                                        {t("Womans Fashion Bag")} <span>*</span>
                                      </label>
                                    </div>
                                    <div className="col-md-9">
                                      <input
                                        type="text"
                                        placeholder={t("Enter Womans Fashion Bag")}
                                        name=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div>
                            <span> {t("Atia 81")} </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="col-md-12">
                            <div className="accordian_chkbxdiv">
                              <div>
                                <input type="checkbox" id="abc" />
                                <label for="abc">
                                  {" "}
                                  {t("Fashion Earrings")}{" "}
                                </label>
                              </div>
                              <div>
                                <input type="checkbox" id="asd" />
                                <label for="asd"> {t("Button")} </label>
                              </div>
                              <div>
                                <input type="checkbox" id="sdg" />
                                <label for="sdg"> {t("Chandelier")} </label>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div>
                            <span>
                              {" "}
                              {t("Beads")}/{t("Charms")}{" "}
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="col-md-12">
                            <div className="accordian_chkbxdiv">
                              <div>
                                <input type="checkbox" id="abc" />
                                <label for="abc">
                                  {" "}
                                  {t("Fashion Earrings")}{" "}
                                </label>
                              </div>
                              <div>
                                <input type="checkbox" id="asd" />
                                <label for="asd"> {t("Button")} </label>
                              </div>
                              <div>
                                <input type="checkbox" id="sdg" />
                                <label for="sdg"> {t("Chandelier")} </label>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div>
                            <span> {t("Bracelets")} </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="col-md-12">
                            <div className="accordian_chkbxdiv">
                              <div>
                                <input type="checkbox" id="abc" />
                                <label for="abc">
                                  {" "}
                                  {t("Fashion Earrings")}{" "}
                                </label>
                              </div>
                              <div>
                                <input type="checkbox" id="asd" />
                                <label for="asd"> {t("Button")} </label>
                              </div>
                              <div>
                                <input type="checkbox" id="sdg" />
                                <label for="sdg"> {t("Chandelier")} </label>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Modal>

              {/* Import Mapping */}

              <Modal
                className="datamapping__modal"
                visible={visible1}
                width={600}
                onOk={() => setVisible1(false)}
                onCancel={handleImportMappingCancel}
                footer={[]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12">
                    <div className="imptmap__headdiv">
                      <span> {t("Import Mapping")} </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="excel__maindiv mt-4">
                      <div className="excel__icndiv">
                        <img src={Excel_icon} alt="" />
                      </div>
                      <div className="excel__headtxtdiv">
                        <span className="head__txt">
                          {t("Your sheet just needs your column headers.")}
                        </span>
                        <span className="extn__txt"> {t(".csv")} </span>
                      </div>
                    </div>
                    <div className="excelfileupl__div mt-3">
                      <label
                        for="file-upload"
                        class="primary-btn choose_file_upload"
                      >
                        {t("Choose File")}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={(e) => fileUpload(e, "mappingFile")}
                      />
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                      {importMappingFile.Error ? (
                        <div className="text-danger">
                          {importMappingFile.Error["Imagefile"]}
                        </div>
                      ) : null}
                      {importMappingFile.ImagefileName
                        ? importMappingFile.ImagefileName
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="input__block">
                          <label className="">
                            {t("Choose Format")}
                            <span>*</span>
                          </label>
                          <Select
                            showSearch
                            className="border__grey"
                            placeholder={t("Choose Format")}
                            optionFilterProp="children"
                            value={
                              importMappingFile.chooseFormat
                                ? importMappingFile.chooseFormat
                                : ""
                            }
                            onChange={(e) =>
                              handleImportMappingOnchange(
                                e,
                                "select",
                                "chooseFormat"
                              )
                            }
                          >
                            <Option value="">{t("Choose Format")}</Option>
                            {chooseFormatList &&
                              chooseFormatList.map((item, i) => {
                                return (
                                  <Option value={item.value}>
                                    {item.title}
                                  </Option>
                                );
                              })}
                          </Select>
                          {importMappingFile.Error ? (
                            <div className="text-danger">
                              {importMappingFile.Error["chooseFormat"]}
                            </div>
                          ) : null}
                          <Button
                            onClick={handleOpenUploadMappingModal}
                            className="primary-btn mt-3"
                          >
                            {t("Upload Sheet")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={t("Add A New Mappable Column")}
                className="datamapping__modal"
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={handleCancel}
                footer={[
                  <Button key="" type="" onClick={handleCancel}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleUpdateDiamondColumnsOfDealerUpload}
                  >
                    {t("Save")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <Spin spinning={saveLoader}>
                    <div className="col-lg-12">
                      <span> {t("Add A Single New Column Header.")} </span>
                    </div>
                    <div className="col-lg-12 mt-3">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="input__block">
                            <input
                              type="text"
                              placeholder={t("Add Attribute")}
                              value={addData.attribute}
                              name="attribute"
                              onChange={handleAddOnchange}
                              className={attributeVal && "border__red"}
                            />
                            <div>
                              {attributeVal && (
                                <p className="error-color-red">
                                  {attributeVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block">
                            <button
                              onClick={handleAddDiamondColumnsOfDealerUpload}
                              className="primary-btn"
                            >
                              {" "}
                              {t("Add New")}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                      <Table
                        loading={ModalLoading}
                        className="Addnewmap__tbl"
                        columns={columns1}
                        dataSource={TableMappingList}
                        scroll={{ y: 800 }}
                        pagination={false}
                      />
                    </div>
                  </Spin>
                </div>
              </Modal>
              <Modal
                className="datamapping__modal"
                width={400}
                centered
                visible={removeAttributeModal}
                onCancel={() => handleCancelRemoveAtrributeModal()}
                footer={[
                  <Button onClick={() => handleCancelRemoveAtrributeModal()}>
                    {t("Yikes...Go Back")}
                  </Button>,

                  <Button
                    loading={submitLoading}
                    type="primary"
                    onClick={() => handleRemoveMappDiamondDealerColumns()}
                  >
                    {t("Remove This Mapping")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12 text-center">
                    <p>
                      {" "}
                      {t("Are your sure you want to remove this mapping?")}{" "}
                    </p>
                    <h5>
                      {" "}
                      {t("THIS CAN NOT BE UNDONE")} ... {t("THERE IS NO UNDO")}
                    </h5>
                  </div>
                </div>
              </Modal>
              {/* Conformation Modal */}
              <Modal
                className="datamapping__modal"
                width={400}
                centered
                visible={uploadMappingModal}
                onCancel={() => handleCloseUploadMappingModal()}
                footer={[
                  <Button onClick={() => handleCloseUploadMappingModal()}>
                    {t("Yikes...Go Back")}
                  </Button>,
                  <Button
                    loading={importMappingLoading}
                    type="primary"
                    onClick={() => handleUploadDiamondMappSheet()}
                  >
                    {t("Reset The Map")}
                  </Button>,
                ]}
              >
                <div className="form__fields row border-0 p-0">
                  <div className="col-lg-12 text-center">
                    <p>
                      {" "}
                      {t("Are your sure you want to reset the Data Map?")}{" "}
                    </p>
                    <h5>
                      {" "}
                      {t("THIS CAN NOT BE UNDONE")} ... {t("THERE IS NO UNDO")}
                    </h5>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiamondDataMaping;
