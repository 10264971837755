import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import loginService from "../../services/login.service";
import NotificationManager from "react-notifications/lib/NotificationManager";

const ChangePassword = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setUserName(loginDetials.loginDetials.responseData.userName);
        }
      }
    }
  }, []);

  const [userName, setUserName] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [Passwordobj, setPasswordObj] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validations, setValidations] = useState({
    passwordVal: "",
    confirmPasswordVal: "",
    CheckSameVal: "",
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const handleOnChange = (e) => {
    setDisableBtn(false);
    setPasswordObj({
      ...Passwordobj,
      [e.target.name]: e.target.value,
    });

    if (e.target.name == "password") {
      setValidations((prevdata) => ({
        ...prevdata,
        passwordVal: "",
        CheckSameVal: "",
      }));
    }
    if (e.target.name == "confirmPassword") {
      setValidations((prevdata) => ({
        ...prevdata,
        confirmPasswordVal: "",
        CheckSameVal: "",
      }));
    }
  };

  const handleValidation = () => {
    const validations = {
      confirmPasswordVal: "",
      passwordVal: "",
      CheckSameVal: "",
    };
    let isValid = true;
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!Passwordobj.password) {
      isValid = false;
      validations.passwordVal = t("Password is compulsory");
    } else if (!Passwordobj.password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal = t(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    } else {
      validations.passwordVal = "";
    }
    if (!Passwordobj.confirmPassword) {
      isValid = false;
      validations.confirmPasswordVal = t(
        "Confirm Password is compulsory"
      );
    } else if (!Passwordobj.confirmPassword.match(strongRegex)) {
      isValid = false;
      validations.confirmPasswordVal = t(
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"
      );
    } else {
      validations.confirmPasswordVal = "";
    }
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        isValid = false;
        validations.CheckSameVal = t(
          "Both the entered password should match"
        );
      } else {
        validations.CheckSameVal = "";
      }
    }

    setValidations(validations);
    return isValid;
  };

  const handleAdminResetPassword = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    let inputData = {
      userName: userName,
      password: Passwordobj.password,
    };
    setSaveLoading(true);
    try {
      loginService
        .AdminResetPassword(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "True") {
            NotificationManager.success(
              t("Your Password Has Been Reset Successfully")
            );
            setSaveLoading(false);
            setDisableBtn(true);
            setValidations((prevdata) => ({
              ...prevdata,
              confirmPasswordVal: "",
              passwordVal: "",
              CheckSameVal: "",
            }));
          } else {
            NotificationManager.error(t("Your Password Reset Has Failed"));
            setSaveLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSaveLoading(false);
        });
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  const { password, confirmPassword } = Passwordobj;
  const { passwordVal, confirmPasswordVal, CheckSameVal } = validations;

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {t("Change password")}{" "}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row mt-4">
                    {CheckSameVal && (
                      <p className="error-color-red">{CheckSameVal}</p>
                    )}
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>{t("New Password")}</label>
                        <input
                          type="text"
                          placeholder={t("Enter Password")}
                          name="password"
                          autoComplete="new-password"
                          value={password}
                          onChange={handleOnChange}
                          className={passwordVal && "border__red"}
                        />
                        <div>
                          {passwordVal && (
                            <p className="error-color-red">{passwordVal}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label>{t("Confirm Password")}</label>
                        <input
                          type="text"
                          placeholder={t("Enter Password")}
                          name="confirmPassword"
                          autoComplete="new-password"
                          value={confirmPassword}
                          onChange={handleOnChange}
                          className={confirmPasswordVal && "border__red"}
                        />
                        <div>
                          {confirmPasswordVal && (
                            <p className="error-color-red">
                              {confirmPasswordVal}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div>
                  <Button
                    loading={saveLoading}
                    onClick={handleAdminResetPassword}
                    className={disableBtn ? "disabled-btn" : "primary-btn"}
                    disabled={disableBtn}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
