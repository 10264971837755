import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import { useTranslation } from "react-i18next";
import logoImg from "../../assets/images/JewelCloud_Logo_Black.jpg";
import footerlogoImg from "../../assets/images/GemFind-footer.jpg";

const Print = () => {
  
  return (
    <React.Fragment>
      <div className="form__fields print__section d-none">
        <div className="row">
          <div className="col-md-12">
            <div className="top__header">
                <div className="header__logo">
                    <img src={logoImg} />
                </div>
                <div className="header__text">
                    <h3 className="subheading">Diamond List</h3>
                </div>
                <div className="header__date">
                    <p>7/22/2022</p>
                </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mt-4">
            <div className="table__block">
            <table class="table table-bordered table-striped">
                <thead class="thead-light">
                    <tr>
                        <th width={"5%"}>Stock #</th>
                        <th width={"5%"}>Shape</th>
                        <th width={"3%"}>Size</th>
                        <th width={"3%"}>Cut</th>
                        <th width={"3%"}>Color</th>
                        <th width={"3%"}>Clarity</th>
                        <th width={"4%"}>Off %</th>
                        <th width={"3%"}>Cost</th>
                        <th width={"3%"}>Fluor.</th>
                        <th width={"3%"}>Dep.</th>
                        <th width={"3%"}>Table</th>
                        <th width={"5%"}>Measure.</th>
                        <th width={"3%"}>Cert.</th>
                        <th width={"3%"}>Polish</th>
                        <th width={"3%"}>Sym.</th>
                        <th width={"3%"}>Dealer</th>
                        <th width={"3%"}>Video</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><p className="table__content">AV30000023AV30000023</p></td>
                        <td><p className="table__content">Rose_round</p></td>
                        <td><p className="table__content">0.21</p></td>
                        <td><p className="table__content">Ex</p></td>
                        <td><p className="table__content">W</p></td>
                        <td><p className="table__content">SI1</p></td>
                        <td><p className="table__content">-10</p></td>
                        <td><p className="table__content">$35.28</p></td> 
                        <td><p className="table__content">-</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0.00X0.00X0.00</p></td>
                        <td><p className="table__content">N</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">4302</p></td>
                        <td><p className="table__content linkText"><i class="fa fa-video-camera"></i></p></td>
                    </tr>
                    <tr>
                        <td><p className="table__content">AV30000023AV30000023</p></td>
                        <td><p className="table__content">Rose_round</p></td>
                        <td><p className="table__content">0.21</p></td>
                        <td><p className="table__content">Ex</p></td>
                        <td><p className="table__content">W</p></td>
                        <td><p className="table__content">SI1</p></td>
                        <td><p className="table__content">-10</p></td>
                        <td><p className="table__content">$35.28</p></td> 
                        <td><p className="table__content">-</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0.00X0.00X0.00</p></td>
                        <td><p className="table__content">N</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">4302</p></td>
                        <td><p className="table__content linkText"><i class="fa fa-video-camera"></i></p></td>
                    </tr>
                    <tr>
                        <td><p className="table__content">AV30000023AV30000023</p></td>
                        <td><p className="table__content">Rose_round</p></td>
                        <td><p className="table__content">0.21</p></td>
                        <td><p className="table__content">Ex</p></td>
                        <td><p className="table__content">W</p></td>
                        <td><p className="table__content">SI1</p></td>
                        <td><p className="table__content">-10</p></td>
                        <td><p className="table__content">$35.28</p></td> 
                        <td><p className="table__content">-</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0%</p></td>
                        <td><p className="table__content">0.00X0.00X0.00</p></td>
                        <td><p className="table__content">N</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">VG</p></td>
                        <td><p className="table__content">4302</p></td>
                        <td><p className="table__content linkText"><i class="fa fa-video-camera"></i></p></td> 
                    </tr>
                </tbody>
                </table>
            </div>
          </div>
          <div className="col-md-12">
            <div className="bottom__footer">
                <img src={footerlogoImg} />
            </div>
          </div>
        </div>
      </div>

      <div className="form__fields print__section">
        <div className="row">
          <div className="col-md-12">
            <div className="top__header">
                <div className="header__logo">
                    <img src={logoImg} />
                </div>
                <div className="header__text">
                    <h3 className="subheading">Invoice</h3>
                </div>
                <div className="header__date">
                    <p>7/22/2022</p>
                </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
                    <h5 className="subheading">Retailer Details :</h5>
                    <p className="mb-0">GemFind Test Retailer</p>
                    <p className="mb-0">Michael Mastros</p>
                    <p className="mb-0">3507 Oak Springs Dr</p>
                    <p className="mb-0">Austin, Texas,78721</p>
                    <p className="mb-0">United States</p>
                    <p className="mb-0">T:8003734373</p>
                    <p className="mb-0">Email:michael.mastros@gemfind.com</p>
                </div>

                <div className="col-md-4 mb-4">
                    <h5 className="subheading">Vendor  Details :</h5>
                    <p className="mb-0">ZZ.GemFind Test vendor</p>
                    <p className="mb-0">christopher</p>
                    <p className="mb-0">test</p>
                    <p className="mb-0">null, null,12345</p>
                    <p className="mb-0">test</p>
                    <p className="mb-0">T:123456789</p>
                    <p className="mb-0">Email:vendortest@jewelcloud.com</p>
                </div>
                <div className="col-md-12">
                    <p>Order Notes: This is a test of vendor products.</p>
                </div>
          <div className="col-lg-12 col-md-12 mt-4">
            <div className="table__block">
            <table class="table table-bordered table-striped">
                <thead class="thead-light">
                    <tr>
                        <th width={"5%"}>Item</th>
                        <th width={"5%"}>Product Description</th>
                        <th width={"3%"}>SKU</th>
                        <th width={"3%"}>Vendor SKU</th>
                        <th width={"3%"}>Price</th>
                        <th width={"3%"}>Qty</th>
                        <th width={"4%"}>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"><a> Testing Bracelet </a></p></td>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"> Test132</p></td>
                        <td><p className="table__content"> $200.00</p></td>
                        <td><p className="table__content"> 3</p></td>
                        <td><p className="table__content"> $600.00</p></td>
                    </tr>
                    <tr>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"><a> Testing Bracelet </a></p></td>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"> Test132</p></td>
                        <td><p className="table__content"> $200.00</p></td>
                        <td><p className="table__content"> 3</p></td>
                        <td><p className="table__content"> $600.00</p></td>
                    </tr>
                    <tr>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"><a> Testing Bracelet </a></p></td>
                        <td><p className="table__content"></p></td>
                        <td><p className="table__content"> Test132</p></td>
                        <td><p className="table__content"> $200.00</p></td>
                        <td><p className="table__content"> 3</p></td>
                        <td><p className="table__content"> $600.00</p></td>
                    </tr>
                </tbody>
                </table>
            </div>
            
          </div>
          <div className="divForm">

                    <div className="d-flex justify-content-end">
                        <div className="total__section p-3">
                            <p><span className="font__bold">Total Item:</span> 4</p>
                            <p><span className="font__bold">SubTotal:</span> $750.00</p>
                            <p><span className="font__bold">PO Total:</span> $750.00</p>
                        </div>
                    </div>
                    <p>Vendor to provide shipping/insurance info.</p>
                </div>
          <div className="col-md-12">
            <div className="bottom__footer">
                <img src={footerlogoImg} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Print;
