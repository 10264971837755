import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PathOutline from "../../assets/images/Path_13085_Outline.png";
import Union19 from "../../assets/images/Union_19.png";
import Noundiamond from "../../assets/images/noundiamond.png";
import Noundiamond1 from "../../assets/images/noundiamond1.png";
import Nouncall from "../../assets/images/noun_call.png";
import Nounheart from "../../assets/images/noun-heart-1887999.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { useTranslation } from "react-i18next";
import Conversations from "../contacts/conversation";
import Appointment from "../dashboard/Appointment";
import NoImage from "../../assets/images/No_Image.jpg";
import User_image from "../../assets/images/User_image.png";
import shareImage from "../../assets/images/share.png";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";
import { Checkbox, Button, Modal, Spin } from "antd";
import {
  setContactConversationCount,
  setContactAppointmentCount,
  setContactCustomerCount,
  setContactPageNameType,
  setDashboardPageNameType,
} from "../../actions/Contact/Contact";
import Moment from "moment";
import communityVendorService from "../../services/community-vendor.service";
import { NotificationManager } from "react-notifications";
import {
  setManageInventoryVendorId,
  setManageTabName,
  setVendorProfilePageName,
} from "../../actions/Community/ManageInventory";

const DashboardAdminRetailer = (pageNameType) => {
  const { t } = useTranslation();
  /*API binding starts*/
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [PageNameType, setPageNameType] = useState("Dashboard");

  const [conservationCount, setConservationCount] = useState(0);
  const [appointmentcount, setAppointmentCount] = useState(0);
  const [recentlyUpdatedVendorData, setRecentlyUpdatedVendorData] = useState(
    []
  );
  const [dashBoardSliderData, setDashBoardSliderData] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [sliderLoading, setSliderLoading] = useState(false);
  const [dashboardCount, setDashboardCount] = useState([]);
  const [countLoading, setCountLoading] = useState(0);
  const [dashboardConversation, setDashboardConversation] = useState([]);
  const [datesList, setDatesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const ContactDetails = useSelector((state) => state.contactReducer);
  const dispatch = useDispatch();

  // Modal
  // Modal

  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  const [page, setPage] = useState("RetailerAffirmation");
  const [checkbox, setCheckBox] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [content, setContent] = useState("");
  const [dealerCompany, setDealerCompany] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [row, setRow] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(0);
  const [btnVisible, setBtnVisible] = useState(0);
  const [dataLabel, setDataLabel] = useState(0);
  const [dataLabelConversation, setDataLabelConversation] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);

  const handleSendInvitationModalCancel = () => {
    setCheckBox("");
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setContent("");
  };

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }

  const showSendInvitationModal = (row) => {
    if (row.action == "Send Invitation" || row.action == "Request Access") {
      setRow(row);
      setIsModalSendInvitationVisible(true);
    }
  };

  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: (
        <>
          {t(
            "By checking this box and clicking the submit button below Ihere by affirm that"
          )}
          {dealerCompany ? dealerCompany : null}
          {t("has an open and active account with")}
          {row.dealerCompany}
          {t(",that I am an authorized reseller of")}
          {row.dealerCompany}
          {t(
            ",and that I am permitted to have the ir virtual inventory on my website."
          )}
        </>
      ),
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",
      note: (
        <>
          {t("I am interested in opening a new account with")}{" "}
          {row.dealerCompany}
          {t(
            "and becoming an authorized reseller.By checking this box and clicking the submit button below, your name and store information will be sent to"
          )}
          {row.dealerCompany}.
        </>
      ),
    },
  ];

  const handleGetEmailData = async () => {
    try {
      let inputData = {
        vendorID: row.dealerID,
        dealerID: dealerId,
        emailType: row.emailType,
      };
      setEmailLoading(true);
      communityVendorService
        .GeneratePopupForAffirmation(inputData)
        .then((response) => {
          setEmailLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData.sendMsgBody;
          if (message === "Success") {
            if (responseData) {
              setContent(responseData);
            } else {
              setContent([]);
            }
          } else {
            setContent([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailLoading(false);
        });
    } catch (error) {
      console.log(error);
      setEmailLoading(false);
    }
  };

  const handleNewAccountRequest = async () => {
    try {
      let inputData = {
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .SendEmailToAllEmailType(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(t("Email has been Sent Successfully."));
          } else {
            NotificationManager.error(
              t("Email has not been Sent Successfully.")
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  const handleSendEmail = () => {
    try {
      let inputData = {
        editval: content,
        email_Type: row.emailType,
        vendorID: row.dealerID,
        dealerID: dealerId,
      };
      setLoading(true);
      communityVendorService
        .SendEmailToVendor(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(t("Email has been Sent Successfully."));
          } else {
            NotificationManager.error(
              t("Email has not been Sent Successfully.")
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleSendInvitationModalCancel();
  };

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        if (
          loginDetials.responseData.dealerMemberTypeName == "Admin" ||
          loginDetials.responseData.dealerMemberTypeName == "Retailer"
        ) {
          setDealerId(loginDetials.responseData.dealerId);
          setDealerCompany(loginDetials.responseData.dealerCompany);
          setTimeout(() => {
            handleGetDashBoardAdminCount(loginDetials.responseData.dealerId);
          }, 200);

          setTimeout(() => {
            handleGetDashBoardSlider(loginDetials.responseData.dealerId);
          }, 400);

          setTimeout(() => {
            handleAdminDashBoardBindDataCommVendor(
              loginDetials.responseData.dealerId,
              0
            );
          }, 200);

          handleDashBoardConservationDetailsList(
            loginDetials.responseData.dealerId
          );
          if (ContactDetails) {
            setConservationCount(ContactDetails.contactConversationCount);
            setAppointmentCount(ContactDetails.contactAppointmentCount);
            handleGetCustomerCount(loginDetials.responseData.dealerId);
          }
          if (location.state) {
            setLocationCount(1);
          }
        } else if (
          loginDetials.responseData.dealerMemberTypeName == "Retailer"
        ) {
        } else {
          setDealerId(loginDetials.responseData.dealerId);
          setDealerCompany(loginDetials.responseData.dealerCompany);
          setTimeout(() => {
            handleGetDashBoardAdminCount(loginDetials.responseData.dealerId);
          }, 200);

          setTimeout(() => {
            handleGetDashBoardSlider(loginDetials.responseData.dealerId);
          }, 400);

          setTimeout(() => {
            handleAdminDashBoardBindDataCommVendor(
              loginDetials.responseData.dealerId,
              0
            );
          }, 200);

          handleDashBoardConservationDetailsList(
            loginDetials.responseData.dealerId
          );
          setConservationCount(0);
          setAppointmentCount(0);
          handleGetCustomerCount(loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  // Redux For Count
  const location = useLocation();
  useEffect(() => {
    if (ContactDetails) {
      setConservationCount(ContactDetails.contactConversationCount);
      setAppointmentCount(ContactDetails.contactAppointmentCount);
      if (locationCount == 1) {
        setPageNameType(location.state.key);
      } else {
        setPageNameType(ContactDetails.DashboardPageNameType);
      }
    }
  }, [ContactDetails]);

  const handleSendCount = (val1, val2, val3) => {
    dispatch(setContactConversationCount(val1));
    dispatch(setContactAppointmentCount(val2));
    dispatch(setContactCustomerCount(val3));
  };

  const handleGetCustomerCount = async (id) => {
    let inputData = {
      dealerId: id.toString(),
      condition:
        "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30",
      sortBy: "",
    };

    try {
      AdminDashboardDataService.GetCustomerCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            handleSendCount(
              responseData.conservationCount,
              responseData.appointmentcount,
              responseData.customerCount
            );
          } else {
            setConservationCount(0);
            setAppointmentCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  const handleGetDashBoardAdminCount = async (id) => {
    try {
      let inputData = {
        dealerId: id.toString(),
      };
      setCountLoading(true);
      AdminDashboardDataService.GetDashBoardAdminCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            //
            if (responseData) {
              setDashboardCount(responseData);
              setVendorCount(responseData?.vendorRequest || 0);
              setCountLoading(false);
            } else {
              setVendorCount(0);
              setDashboardCount([]);
              setCountLoading(false);
            }
          } else {
            setVendorCount(0);
            setDashboardCount([]);
            setCountLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCountLoading(false);
        });
    } catch (error) {
      console.log(error);
      setCountLoading(false);
    }
  };

  const handleGetDashBoardSlider = async (id) => {
    try {
      let inputData = {
        dealerID: id,
      };
      setSliderLoading(true);
      AdminDashboardDataService.GetDashBoardAdminSlider(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            //
            if (responseData) {
              setDashBoardSliderData(responseData);
              setSliderLoading(false);
            } else {
              setDashBoardSliderData([]);
              setSliderLoading(false);
            }
          } else {
            setDashboardCount([]);
            setSliderLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSliderLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSliderLoading(false);
    }
  };

  const handleAdminDashBoardBindDataCommVendor = async (id, count) => {
    try {
      let inputData = {
        dealerID: id ? id.toString() : dealerId.toString(),
        filerType: "3",
        currentPage: 1,
        recordOnPage: 10,
        searchKeyChar: "",
        searchType: "",
        sortColumnName: "dealerCompany",
        sortOrder: "asc",
        lstColumnFilter: [],
        limitcount: count ? count : 0,
      };
      setVendorLoading(true);
      communityVendorService
        .AdminDashBoardBindDataCommVendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.vendor_NetworkResponse.length > 0) {
              setRecentlyUpdatedVendorData(responseData.vendor_NetworkResponse);
              setVendorLoading(false);
              if (count == 0) {
                setBtnVisible(1);
              }
              setDataLabel(0);
            } else {
              setRecentlyUpdatedVendorData([]);
              setVendorLoading(false);
              setBtnVisible(0);
              setDataLabel(1);
            }
          } else {
            setRecentlyUpdatedVendorData([]);
            setVendorLoading(false);
            setDataLabel(1);
          }
        })
        .catch((error) => {
          console.log(error);
          setVendorLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVendorLoading(false);
    }
  };

  // Vendor Request Redirection
  let navigate = useNavigate();
  const handleRedirection = (type) => {
    if (type == "PendingRequest") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu("vendorManagerPendingRequests"));
      navigate("/VendorManagerPendingRequests");
    }
  };

  const handleRedirect = (type) => {
    if (type == "all") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["new"]));
      navigate("/new");
    } else if (type == "recently Updated") {
      dispatch(setChecklinkHeaderMenuData("My Catalog"));
      var MenuData = [`${"Data"}`, `${"DataConnection"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu("recentlyUpdated"));

      navigate("/recentlyUpdated");
    }
  };

  const hanleNewTabRedirect = () => {
    navigate("/new");
  };

  const handleDashBoardConservationDetailsList = async (id) => {
    let inputData = {
      dealerID: id,
      showdata: "x",
      limitcondition: 0,
    };
    setLoading(true);
    try {
      AdminDashboardDataService.DashBoardConservationDetailsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setDashboardConversation(responseData);

              var tempDates = [
                ...new Set(
                  responseData.map(({ formatedDate }) => formatedDate)
                ),
              ];
              setDatesList(tempDates);
              setLoading(false);
              setButtonVisible(1);
              setDataLabelConversation(0);
            } else {
              setDashboardConversation([]);
              setDatesList([]);
              setLoading(false);
              setDataLabelConversation(1);
            }
          } else {
            setDashboardConversation([]);
            setLoading(false);
            setDataLabelConversation(1);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setDataLabelConversation(1);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDataLabelConversation(1);
    }
  };

  const handleloadMore = () => {
    setButtonVisible(0);
    handleDashBoardConservationDetailsListLoadMore();
  };

  const handleloadMoreImage = () => {
    setBtnVisible(0);
    handleAdminDashBoardBindDataCommVendor(dealerId, 1);
  };

  const handleDashBoardConservationDetailsListLoadMore = async (id) => {
    let inputData = {
      dealerID: dealerId,
      showdata: "x",
      limitcondition: 1,
    };
    setLoading(true);
    try {
      AdminDashboardDataService.DashBoardConservationDetailsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDashboardConversation(responseData);

            var tempDates = [
              ...new Set(responseData.map(({ formatedDate }) => formatedDate)),
            ];
            setDatesList(tempDates);
            setLoading(false);
          } else {
            setDashboardConversation([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRedirectConversation = (type, id, word) => {
    if (type == "Profile") {
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id ? id : 0,
        },
      });
    } else if (type == "Appointment or Conversation") {
      if (word == " of ") {
        navigate("/contact", {
          state: {
            key: "AppointmentsOnly",
          },
        });
        dispatch(setContactPageNameType("AppointmentsOnly"));
      } else {
        navigate("/profileNew", {
          state: {
            key: "Conversation",
            id: id ? id : 0,
          },
        });
      }
    } else if (type == "Conversation") {
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: id ? id : 0,
        },
      });
    }
  };

  const handleSendPageType = (type) => {
    dispatch(setDashboardPageNameType(type));
  };

  const handleViewMore = (id) => {
    dispatch(setManageTabName("Manage"));
    dispatch(setManageInventoryVendorId(id));
    dispatch(setVendorProfilePageName("Inventory"));
    window.open("/vendorProfile", "_blank");
  };

  return (
    <React.Fragment>
      <div className="catalog__topnav">
        <ul>
          <li onClick={() => handleSendPageType("Dashboard")}>
            {" "}
            <p
              className={
                PageNameType == "Dashboard"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {t("Dashboard")}
            </p>
          </li>
          <li onClick={() => handleSendPageType("Conversation")}>
            {" "}
            <p
              className={
                PageNameType == "Conversation"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {t("Conversations")}{" "}
              <span>{conservationCount ? conservationCount : 0}</span>
            </p>
          </li>
          <li>
            {" "}
            <p
              onClick={() => handleRedirection("PendingRequest")}
              className="topNavLinks"
            >
              {t("Vendor Requests")}{" "}
              <span>{vendorCount ? vendorCount : 0}</span>
            </p>
          </li>

          <li onClick={() => handleSendPageType("AppointmentsOnly")}>
            {" "}
            <p
              className={
                PageNameType == "AppointmentsOnly"
                  ? "topNavLinks linkText"
                  : "topNavLinks"
              }
            >
              {t("Appointments")}{" "}
              <span>{appointmentcount ? appointmentcount : 0}</span>
            </p>
          </li>
        </ul>
      </div>
      {PageNameType == "Dashboard" && (
        <div className="row">
          <div className="col-md-12">
            <div className="slider__section mt-5">
              <Spin spinning={sliderLoading}>
                <OwlCarousel className="owl-theme" {...options}>
                  {dashBoardSliderData &&
                    dashBoardSliderData.map((item, i) => {
                      return (
                        <div class="item" key={i}>
                          <div className="slider__item">
                            <div className="bgimage">
                              <img
                                src={
                                  item.imageName == ""
                                    ? NoImage
                                    : item.imageName
                                }
                                alt="bgimage"
                              />
                            </div>
                            <div className="slider__content">
                              <div className="logo__img">
                                <img
                                  src={
                                    item.dealersImage == ""
                                      ? NoImage
                                      : item.dealersImage
                                  }
                                  alt="logo__img"
                                />
                              </div>
                              <div className="slider__btns">
                                <button
                                  class="primary-btn mr-2"
                                  onClick={() => handleViewMore(item.dealerID)}
                                >
                                  {" "}
                                  {t("View Profile")}{" "}
                                </button>
                                <button
                                  class="primary-btn"
                                  onClick={() => {
                                    showSendInvitationModal(item);
                                  }}
                                >
                                  {" "}
                                  {item.action == "Send Invitation" ||
                                  item.action == "Request Access"
                                    ? t("Request Access")
                                    : t("Connected")}{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </OwlCarousel>
              </Spin>
            </div>
          </div>

          <div className="col-md-12">
            <Spin spinning={countLoading}>
              <div className="row">
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">
                        {dashboardCount.vendor ? dashboardCount.vendor : 0}
                      </span>
                    </div>
                    <div>
                      <span className=""> {t("Jewelry vendors")} </span>
                      <img src={PathOutline} className="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">
                        {dashboardCount.jewelry ? dashboardCount.jewelry : 0}
                      </span>
                    </div>
                    <div>
                      <span className=""> {t("Active Jewelry")} </span>
                      <img src={Union19} className="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">
                        {dashboardCount.diamondVendor
                          ? dashboardCount.diamondVendor
                          : 0}
                      </span>
                    </div>
                    <div>
                      <span className=""> {t("Diamond Vendors")} </span>
                      <img src={Noundiamond} className="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">
                        {dashboardCount.activeDiamond
                          ? dashboardCount.activeDiamond
                          : 0}
                      </span>
                    </div>
                    <div>
                      <span className=""> {t("Active Diamonds")} </span>
                      <img src={Noundiamond1} className="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">
                        {dashboardCount.contacts ? dashboardCount.contacts : 0}
                      </span>
                    </div>
                    <div>
                      <span className=""> {t("Contact Us")} </span>
                      <img src={Nouncall} className="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 mt-4">
                  <div className="count_div">
                    <div>
                      <span className="">{0}</span>
                    </div>
                    <div>
                      <span className=""> {t("Watches")} </span>
                      <img src={Nounheart} className="" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>

          <div className="col-md-12">
            <h3 className="subheading">
              {" "}
              {t("New & Recently Updated Vendors")}{" "}
            </h3>
          </div>

          <div className="col-md-12">
            <div className="vendors_section">
              <Spin spinning={vendorLoading}>
                <div className="row">
                  {recentlyUpdatedVendorData &&
                    recentlyUpdatedVendorData.map((item, i) => {
                      return (
                        <div className="col-lg-4 col-md-6">
                          <div className="slider__block">
                            <div className="bgimage">
                              <img
                                src={
                                  item.imageAdv == "" ? NoImage : item.imageAdv
                                }
                                style={
                                  item.image2 == ""
                                    ? {
                                        width: "200px",
                                        height: "171px",
                                        objectFit: "scale-down",
                                      }
                                    : { width: "100%" }
                                }
                                alt="bgimage"
                              />
                            </div>
                            <div className="slider__content">
                              <div className="logo__img">
                                <img
                                  src={
                                    item.image1 == "" ? NoImage : item.image1
                                  }
                                  alt="logo__img"
                                />
                              </div>
                              <div className="slider__btns">
                                <button
                                  onClick={() => handleViewMore(item.dealerID)}
                                  class="primary-btn mr-2"
                                >
                                  {/* <Link
                                    to={"/vendorProfile"}
                                    // to={"/manageInventory"}

                                    state={{
                                      key: "Inventory",
                                      id: item.dealerID,
                                    }}
                                  >
                                    {" "}
                                    {t("View More")}{" "}
                                  </Link> */}
                                  {t("View More")}{" "}
                                </button>
                                <Button
                                  onClick={() => {
                                    showSendInvitationModal(item);
                                  }}
                                  className="primary-btn"
                                >
                                  {" "}
                                  {item.action == "Send Invitation" ||
                                  item.action == "Request Access"
                                    ? t("Request Access")
                                    : t("Connected")}{" "}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <Modal
                    title={
                      page == "RetailerAffirmation"
                        ? t("Retailer Affirmation")
                        : t("Send Email")
                    }
                    visible={isModalSendInvitationVisible}
                    className="forgot__password"
                    onCancel={handleSendInvitationModalCancel}
                    footer={[
                      //
                      <div
                        className={
                          page == "RetailerAffirmation" ? "d-block" : "d-none"
                        }
                      >
                        <Button
                          key="submit"
                          className={
                            checkbox == "" ? "disabled-btn" : "primary-btn"
                          }
                          disabled={(checkbox == "") == true ? true : false}
                          onClick={
                            checkbox == "CurrentAuthorizedReseller"
                              ? handleShowEmailWindow
                              : handleNewAccountRequest
                          }
                        >
                          {t("Submit")}
                        </Button>
                      </div>,
                      <div
                        className={page == "SendEmail" ? "d-block" : "d-none"}
                      >
                        <Button
                          className="primary-btn"
                          key="submit"
                          onClick={handleSendEmail}
                        >
                          {t("Send")}
                        </Button>
                      </div>,
                    ]}
                  >
                    <div className="form__fields p-0 border-0 mh-auto">
                      {page == "RetailerAffirmation" ? (
                        <div>
                          {category.map((item) => {
                            return (
                              <Fragment>
                                <div className="radio__block">
                                  <Checkbox
                                    key={item.value}
                                    onChange={handleCheckBoxChange}
                                    checked={item.value == checkbox}
                                    value={item.value}
                                    id={item.value}
                                  >
                                    <label htmlFor={item.value}>
                                      {" "}
                                      {t(item.label)}{" "}
                                    </label>
                                  </Checkbox>
                                </div>
                                <div>
                                  <p> {item.note} </p>
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="editor__section">
                          <Spin spinning={emailLoading}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={content}
                              // config={editorConfiguration}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data);
                              }}
                            />
                          </Spin>
                        </div>
                      )}
                    </div>
                  </Modal>
                  {/* <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg2} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg2} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg1} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg1} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg3} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg3} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg4} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg4} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg2} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg2} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg5} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg3} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg1} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg1} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg3} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg4} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="slider__block">
                    <div className="bgimage">
                      <img src={VendorbgImg4} />
                    </div>
                    <div className="slider__content">
                      <div className="logo__img">
                        <img src={Vendor_logoImg3} />
                      </div>
                      <div className="slider__btns">
                        <button class="primary-btn mr-2">
                          {" "}
                          {t("View More")}{" "}
                        </button>
                        <button class="primary-btn">
                          {" "}
                          {t("Request Access")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </Spin>
              {dataLabel == 1 && (
                <div className="col-md-12 text-center mt-4">
                  <p>{t("No Data Available.")}</p>
                </div>
              )}
              {/* <Button className="primary-btn">Load More</Button> */}
              {btnVisible == 1 && (
                <div className="row">
                  <div className="col-md-12 text-center mt-4">
                    <Button
                      className="primary-btn"
                      onClick={() => handleloadMoreImage()}
                    >
                      {" "}
                      {t("Load More")}{" "}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* { PageNameType == "Dashboard" &&
            <div>
            <div className="col-md-12">
                <h3 className="subheading">  </h3>
            </div>
            <div className="col-md-12">
                <div className="vendors_section diamond__dealerdb">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="quicklink__sections">
                                <h4 className="subheading"> {t("Quick Links")} </h4>
                                <ul>
                                    <li>
                                        <Link to="/jewelryRetailerPermission">
                                            {t("View My Retailers")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/updateInventoryImport">
                                            {t("Update Inventory")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/inviteARetailer">
                                            {t("Invite A Retailer")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/jewelryRetailerPermission">
                                            {t("Manage Shows")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="quicklink__sections">
                                <h4 className="subheading"> {t("Details")} </h4>
                                <ul>
                                    <li> {t("Last Updated")}: 08/27/2020 </li>
                                    <li> {t("Jewelry")}: 38101 </li>
                                    <li> {t("Diamonds")}: 0 </li>
                                    <li> {t("Retailers")}: 158 </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="quicklink__sections">
                                <h4 className="subheading"> {t("Ratings")} </h4>
                                <StarRatings
                                    rating= {Number(3)}
                                    starRatedColor="#1883b8"
                                    starDimension="18px"
                                    starSpacing="0px"                                    
                                    numberOfStars={5}
                                    name="rating"
                                />
                                <p>
                                    {t("The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated.")}
                                </p>
                                <a href="http://support.gemfind.net/hc/en-us/articles/215305078-DATA-Vendor-Star-Rating-System" target="_blank">
                                    {t("For details on how you can improve your score, please click here to view this document.")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        } */}

      {PageNameType == "Dashboard" && (
        <div>
          <div className="col-md-12 mt-2">
            <div>
              <a
                className="linkText"
                onClick={() => hanleNewTabRedirect("all")}
              >
                {t("View all new vendors")}{" "}
              </a>
              <a> {t("Or")} </a>
              <a
                className="linkText"
                onClick={() => handleRedirect("recently Updated")}
              >
                {t("see 20 of the most recently updated vendors.")}
              </a>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <Spin spinning={loading}>
              <div className="Notif_section">
                {datesList &&
                  datesList.map((header) => {
                    return (
                      <div className="msg_div">
                        <div>
                          <p className="msg_date">
                            {Moment(header).format("dddd, MMMM DD, YYYY ")}
                          </p>
                        </div>
                        <React.Fragment>
                          {dashboardConversation &&
                            dashboardConversation
                              .filter((x) => x.formatedDate == header)
                              .map((item) => {
                                return (
                                  <div className="msg_divtext">
                                    <span className="msg_txt">
                                      {" "}
                                      {/* <i
                                      class="fa fa-comments msg__icon"
                                      aria-hidden="true"
                                    ></i> */}
                                      <span>
                                        <img
                                          src={
                                            item.icon == "user.png"
                                              ? User_image
                                              : item.icon == "share.png"
                                              ? shareImage
                                              : ""
                                          }
                                          style={
                                            item.icon == "user.png"
                                              ? { width: "17px" }
                                              : { width: "15px" }
                                          }
                                        />
                                      </span>{" "}
                                      <span
                                        onClick={() =>
                                          handleRedirectConversation(
                                            "Profile",
                                            item.customerId
                                          )
                                        }
                                        className="linkText"
                                      >
                                        {item.eventTitle &&
                                          item.eventTitle
                                            .split(">")[1]
                                            .split("<")[0]}
                                      </span>{" "}
                                      <span>
                                        {item.eventTitle &&
                                          item.eventTitle
                                            .split(">")[2]
                                            .split("<")[0]}
                                      </span>{" "}
                                      <span
                                        onClick={() =>
                                          handleRedirectConversation(
                                            "Appointment or Conversation",
                                            item.customerId,
                                            item.eventTitle &&
                                              item.eventTitle.split(">")[4] &&
                                              item.eventTitle
                                                .split(">")[4]
                                                .split("<")[0]
                                          )
                                        }
                                        className="linkText"
                                      >
                                        {item.eventTitle.split(">")[3] &&
                                          item.eventTitle
                                            .split(">")[3]
                                            .split("<")[0]}
                                      </span>{" "}
                                      <span>
                                        {item.eventTitle.split(">")[4] &&
                                          item.eventTitle
                                            .split(">")[4]
                                            .split("<")[0]}
                                      </span>{" "}
                                      <span
                                        onClick={() =>
                                          handleRedirectConversation(
                                            "Conversation",
                                            item.customerId
                                          )
                                        }
                                        className="linkText"
                                      >
                                        {item.eventTitle.split(">")[5] &&
                                          item.eventTitle
                                            .split(">")[5]
                                            .split("<")[0]}
                                      </span>{" "}
                                      <span>
                                        {item.eventTitle.split(">")[6] &&
                                          item.eventTitle
                                            .split(">")[6]
                                            .split("<")[0]}
                                      </span>
                                    </span>

                                    <span className="msg_timetxt">
                                      {item.actionTime == ""
                                        ? ""
                                        : item.actionTime}
                                    </span>
                                  </div>
                                );
                              })}
                        </React.Fragment>
                      </div>
                    );
                  })}
                {buttonVisible == 1 && (
                  <Button
                    className="primary-btn mt-3"
                    onClick={() => handleloadMore()}
                  >
                    {t("Load More")}
                  </Button>
                )}

                {dataLabelConversation == 1 && (
                  <div className="col-md-12 text-center mt-4">
                    <p>{t("No Data Available.")}</p>
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      )}

      {PageNameType == "Conversation" && (
        <Conversations pageNameType={PageNameType} />
      )}

      {PageNameType == "AppointmentsOnly" && (
        <Appointment pageNameType={PageNameType} />
      )}
    </React.Fragment>
  );
};

export default DashboardAdminRetailer;
