import React, { useState, useEffect, Fragment } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Select, Table, Button, Modal, Radio, Spin } from "antd";
import moment from "moment";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const Limits = () => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [page, setPage] = useState("limitType");
	const [radioValue, setRadioValue] = useState("");
	const [getLimitData, setGetLimitData] = useState([]);
	const [retailerList, setRetailerList] = useState([]);
	const [secondaryList, setSecondaryList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [datacount, setDatacount] = useState("");
	const [titleValue, setTitleValue] = useState("");
	const [limitsListResponseLst, setLimitsListResponseLst] = useState([]);
	const [isModalDelVisible, setIsModalDelVisible] = useState(false);
	const [clientData, setClientData] = useState({
		value: "",
		retailer: "",
		diamond: "",
		vendor: "",
	});
	const [validations, setValidations] = useState({
		valueVal: "",
		diamondVal: "",
		retailersVal: "",
		vendorVal: "",
	});
	const [checkbox, setCheckBox] = useState({
		LimitRetailerToDiamondDealer: true,
		LimitDiamondDealerToRetailer: true,
		LimitVendorToRetailer: true,
		LimitRetailerToVendor: true,
	});
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState("");
	const [dealerId, setDealerId] = useState();
	const loginDetials = useSelector((state) => state.loginReducer);
	const [isDisabled, setIsDisabled] = useState(true);
	const [checkBool, setCheckBool] = useState(null);
	const [mainCheckVal, setMainCheckVal] = useState(false);
	const [getDataloading, setgetDataloading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
					handleGetDealerLimits(
						loginDetials.loginDetials.responseData.dealerId,
						pageNo,
						pageSize
					);
				}
			}
		}
	}, []);

	useEffect(() => {
		if (clientData.value === "Limit Retailer To Diamond Dealer") {
			setRadioValue("diamond");
		}
		if (clientData.value === "Limit Diamond Dealer To Retailer") {
			setRadioValue("diamond");
		}
		if (clientData.value === "Limit Vendor To Retailer") {
			setRadioValue("vendor");
		}
		if (clientData.value === "Limit Retailer To Vendor") {
			setRadioValue("vendor");
		}
	}, [clientData.value]);

	const showDelModal = () => {
		if (getLimitData.filter((x) => x.selectedDealerID == true).length == 0) {
			NotificationManager.error(t("Please first select record to delete."));
		} else {
			setIsModalDelVisible(true);
		}
	};

	const handleDelCancel = () => {
		setIsModalDelVisible(false);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setPage("limitType");
		setTitleValue("");
		setClientData({});
		setValidations({});
	};

	const handleValidation = () => {
		const { value, retailer, diamond, vendor } = clientData;
		const validations = {
			valueVal: "",
			diamondVal: "",
			retailersVal: "",
			vendorVal: "",
		};
		let isValid = true;

		if (page === "limitType") {
			if (!value) {
				isValid = false;
				validations.valueVal = t("Limit Type is compulsory");
			}
		}
		if (page === "limitDropdown") {
			if (!retailer) {
				isValid = false;
				validations.retailersVal = t("Kindly select the retailer");
			}

			if (
				clientData.value === "Limit Diamond Dealer To Retailer" ||
				clientData.value === "Limit Retailer To Diamond Dealer"
			) {
				if (!diamond) {
					isValid = false;
					validations.diamondVal = t("Kindly select the Diamond Dealer");
				}
			}

			if (
				clientData.value === "Limit Vendor To Retailer" ||
				clientData.value === "Limit Retailer To Vendor"
			) {
				if (!vendor) {
					isValid = false;
					validations.vendorVal = t("Kindly select the Vendor");
				}
			}
		}

		if (!isValid) {
			setValidations(validations);
		}

		return isValid;
	};
	const handleNext = (e) => {
		e.preventDefault();
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		setPage("limitDropdown");
		setTitleValue(clientData.value);
		handleGetDealerForCreate();
	};

	const handleGetDealerForCreate = () => {
		let inputData = {
			listFor: clientData.value,
		};
		AdminToolsDataService.GetDealerForCreate(inputData)
			.then((response) => {
				setLoading(false);
				var message = response.data.message;
				var responseData = response.data.responseData[0];
				var secondaryData = response.data.responseData[1];
				if (message === "Success") {
					setRetailerList(responseData);
					setSecondaryList(secondaryData);
				} else {
					setRetailerList([]);
					setSecondaryList([]);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setClientData({
				...clientData,
				[name]: e,
			});
		} else {
			setClientData({
				...clientData,
				[e.target.name]: e.target.value,
			});
			setValidations({
				valueVal: "",
			});
		}
	};

	const handleCheckBoxChange = (e, name) => {
		setIsDisabled(false);
		setCheckBox({
			...checkbox,
			[name]: e.target.checked,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		try {
			let inputData = {
				dealerID: (radioValue == "diamond"
					? clientData.diamond
					: clientData.vendor
				).toString(),
				limitSetBy: dealerId.toString(),
				limitSetFor: clientData.value,
				retailerId: clientData.retailer.toString(),
			};
			setLoading(true);
			await AdminToolsDataService.CreateDealerLimit(inputData)
				.then((response) => {
					setLoading(false);
					var message = response.data.message;
					var responseData = response.data.responseData;
					var status = responseData.status;
					if (status == 1) {
						NotificationManager.success(t("Data Added Successfully."));
						handleGetDealerLimits(dealerId, pageNo, pageSize);
					} else if (status == 0) {
						NotificationManager.error(
							t("Limit For This Retailer Is Already Configured.")
						);
						handleGetDealerLimits(dealerId, pageNo, pageSize);
					} else {
						NotificationManager.error(t("Data Not Added"));
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
		handleCancel();
	};

	const handleApplyFilters = () => {
		handleGetDealerLimits(dealerId, pageNo, pageSize);
	};

	const handleGetDealerLimits = (Id, pageNo, pageSize) => {
		const Checkboxs = Object.keys(checkbox)
			.filter((key) => checkbox[key])
			.join(",");

		let inputData = {
			dealerId: Number(Id),
			filterBy: Checkboxs,
			pageNumber: pageNo,
			pageSize: pageSize,
		};
		setLoading(true);
		AdminToolsDataService.GetDealerLimits(inputData)
			.then((response) => {
				setLoading(false);
				var message = response.data.message;
				var responseData =
					response.data.responseData.result.limitsListResponseLst;
				setLimitsListResponseLst(responseData);
				var TotalRowCount = response.data.responseData.result.totalCount;
				var TotalRecords = response.data.responseData.result.totalRecords;

				if (message === "Success") {
					if (responseData !== null) {
						const temp = responseData.map((x) => {
							return { ...x, selectedDealerID: 0 };
						});
						if (checkBool !== null) {
							if (checkBool == true) {
								var tempcommVendorData = temp;
								tempcommVendorData.forEach((element) => {
									element.selectedDealerID = 1;
								});
								setGetLimitData(tempcommVendorData);
								setDatacount(TotalRecords);
								setTotalCount(TotalRowCount);
							} else {
								var tempcommVendorData = temp;
								tempcommVendorData.forEach((element) => {
									element.selectedDealerID = 0;
								});
								setGetLimitData(tempcommVendorData);
								setDatacount(TotalRecords);
								setTotalCount(TotalRowCount);
							}
							setMainCheckVal(false);
						} else {
							setMainCheckVal(false);
							setGetLimitData(temp);
							setDatacount(TotalRecords);
							setTotalCount(TotalRowCount);
						}

						setLoading(false);
					} else {
						setMainCheckVal(false);
						setDatacount(0);
						setTotalCount(0);
						setGetLimitData([]);
						setIsDisabled(true);
					}
				} else {
					setMainCheckVal(false);
					setDatacount(0);
					setTotalCount(0);
					setGetLimitData([]);
					setIsDisabled(true);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDeleteRows = () => {
		setDeleteLoading(true);

		let inputData = getLimitData.filter((x) => x.selectedDealerID == true);
		setLoading(true);
		AdminToolsDataService.DeleteDealerLimit(inputData)
			.then((response) => {
				setLoading(false);
				var message = response.data.message;
				if (message === "Success") {
					setDeleteLoading(false);
					NotificationManager.success(t("Data deleted successfully."));
					handleDelCancel();
					handleGetDealerLimits(dealerId, pageNo, pageSize);
				} else {
					setDeleteLoading(false);
					NotificationManager.error(t("Data not Deleted"));
				}
			})
			.catch((error) => {
				setDeleteLoading(false);
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setDeleteLoading(false);
				setLoading(false);
			});
	};

	const handleDownloadDealerLimits = () => {
		const Checkboxs = Object.keys(checkbox)
			.filter((key) => checkbox[key])
			.join(",");
		let inputData = {
			dealerId: dealerId.toString(),
			filterBy: Checkboxs.toString(),
		};
		setgetDataloading(true);
		AdminToolsDataService.DownloadDealerLimits(inputData)
			.then((response) => {
				setgetDataloading(false);
				var fileName = "Limits.xlsx";
				BlobToDownloadFile(response.data, fileName);
				var message = response.data.message;
				if (message === "Success") {
					setgetDataloading(false);
				} else {
					setgetDataloading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setgetDataloading(false);
			})
			.finally(() => {
				setgetDataloading(false);
			});
	};

	function handlePageNoChange(page, pageSize) {
		setPageNo(page);
		handleGetDealerLimits(dealerId, page, pageSize);
	}
	function handlePageSizeChange(current, size) {
		setPageSize(size);
		handleGetDealerLimits(dealerId, current, size);
	}

	const handleOnCheckGridAll = (e) => {
		setCheckBool(e.target.checked);
		setMainCheckVal(e.target.checked);
		var tempcommVendorData = getLimitData;
		tempcommVendorData.forEach((element) => {
			element.selectedDealerID = e.target.checked == true ? 1 : 0;
		});
		setGetLimitData([...tempcommVendorData]);
	};

	const handleCheckBoxOnChange = (row, e, name) => {
		if (name === "selectedDealerID") {
			const temp = getLimitData.map((x) => {
				if (x.limitLogID == row.limitLogID) {
					return {
						...x,
						selectedDealerID: e.target.checked == true ? 1 : 0,
					};
				} else return x;
			});
			setGetLimitData(temp);
			const testBoolean = temp.every((m) => {
				if (m.selectedDealerID == 0) {
					return false;
				}
				return true;
			});
			setMainCheckVal(testBoolean);
		}
	};

	return (
		<React.Fragment>
			<div className="limit_section">
				<div className="form__fields">
					<div className="row">
						<div className="col-lg-12">
							<h4 className="workarea__heading mt-0 mb-0">{t("Limits")}</h4>
						</div>

						<div className="col-lg-12 mt-4 mobile-m-0">
							<div className="row">
								<div className="col-lg-6">
									<div className="chckbox__div">
										<input
											checked={checkbox.LimitRetailerToDiamondDealer}
											onChange={(e) =>
												handleCheckBoxChange(e, "LimitRetailerToDiamondDealer")
											}
											type="checkbox"
											name="LimitRetailerToDiamondDealer"
											id="limit1"
										/>
										<label htmlFor="limit1">
											{t("Limit Retailer To Diamond Dealer")}
										</label>
									</div>
									<div className="chckbox__div">
										<input
											checked={checkbox.LimitDiamondDealerToRetailer}
											onChange={(e) =>
												handleCheckBoxChange(e, "LimitDiamondDealerToRetailer")
											}
											type="checkbox"
											name="LimitDiamondDealerToRetailer"
											id="limit2"
										/>
										<label htmlFor="limit2">
											{t("Limit Diamond Dealer To Retailer")}
										</label>
									</div>
									<div className="chckbox__div">
										<input
											checked={checkbox.LimitRetailerToVendor}
											onChange={(e) =>
												handleCheckBoxChange(e, "LimitRetailerToVendor")
											}
											type="checkbox"
											name="LimitRetailerToVendor"
											id="limit3"
										/>
										<label htmlFor="limit3">
											{t("Limit Retailer To Vendor")}
										</label>
									</div>
									<div className="chckbox__div">
										<input
											checked={checkbox.LimitVendorToRetailer}
											onChange={(e) =>
												handleCheckBoxChange(e, "LimitVendorToRetailer")
											}
											type="checkbox"
											name="LimitVendorToRetailer"
											id="limit4"
										/>
										<label htmlFor="limit4">
											{t("Limit Vendor To Retailer")}
										</label>
									</div>
								</div>
								<div className="col-lg-6 aplyfilt__btndiv">
									<button
										disabled={isDisabled}
										onClick={() => handleApplyFilters()}
										className={
											isDisabled == true ? "disabled-btn" : "primary-btn"
										}>
										{" "}
										<i className="fa fa-search"></i> {t("Apply Filters")}
									</button>
								</div>
							</div>
						</div>

						<div className="col-lg-12 mt-3">
							<div className="options__div">
								<div className="row">
									<div className="col-lg-4 rules__div">
										<span className="rules__txt">
											{" "}
											<span className="rules__txtno">
												{limitsListResponseLst &&
												limitsListResponseLst.length > 0
													? totalCount
													: 0}{" "}
												/ {datacount}
											</span>{" "}
											{t("Rules")}
										</span>
									</div>
									<div className="col-lg-8 crud__div">
										<span className="select__txt">
											{" "}
											<i className="fa fa-list-ul"></i>{" "}
											{mainCheckVal
												? t("All")
												: getLimitData
												? getLimitData.filter((x) => x.selectedDealerID == true)
														.length
												: 0}{" "}
											{t("Selected")}
										</span>
										<button className="primary-btn" onClick={showDelModal}>
											{" "}
											<i className="fa fa-trash-o"></i> {t("Delete")}
										</button>
										<button className="primary-btn" onClick={showModal}>
											{" "}
											<i className="fa fa-plus-circle"></i> {t("Create")}
										</button>
										<Button
											className="primary-btn"
											onClick={() => handleDownloadDealerLimits()}
											loading={getDataloading}>
											{" "}
											<i className="fa fa-download"></i> {t("Download")}
										</Button>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-12">
							<div className="limit_tbldiv">
								<Spin spinning={loading} size="large">
									<Table
										columns={[
											{
												title: (
													<div className="filtbtn__div">
														<span className="select__all">
															<input
																type="checkbox"
																id="sel"
																onChange={(e) => handleOnCheckGridAll(e)}
																checked={mainCheckVal}
															/>
														</span>
													</div>
												),
												dataIndex: "selectedDealerID",
												width: 50,
												render: (item, row) => {
													return (
														<div className="action__btns">
															<input
																type="checkbox"
																name="selectedDealerID"
																checkedChildren={t("Yes")}
																unCheckedChildren={t("No")}
																checked={
																	row.selectedDealerID == 1 ? true : false
																}
																onChange={(e) =>
																	handleCheckBoxOnChange(
																		row,
																		e,
																		"selectedDealerID"
																	)
																}
															/>
														</div>
													);
												},
											},
											{
												dataIndex: "limitSetFor",
												title: t("Rule Type"),
											},
											{
												dataIndex: "vendor_DealerName",
												title: t("Vendor/Dealer"),
											},
											{
												dataIndex: "retailerName",
												title: t("Retailer"),
											},
											{
												dataIndex: "createdDate",
												title: t("Created Date"),
												render: (text, row) => {
													return moment(row.addedDateTime).format(
														"YYYY-MM-DD HH:mm:ss"
													);
												},
											},
											{
												dataIndex: "adminName",
												title: t("Admin"),
											},
										]}
										scroll={{ x: 900, y: 800 }}
										dataSource={getLimitData}
										onChange={(pagination, filters, sorters) => {
											document
												.getElementsByClassName("content__area")[0]
												.scroll(0, 0);
										}}
										pagination={{
											total: totalCount,
											pageNo: pageNo,
											pageSize: pageSize,
											pageSizeOptions: [10, 20, 50, 100],
											responsive: true,
											showSizeChanger: true,
											onChange: handlePageNoChange,
											onShowSizeChange: handlePageSizeChange,
										}}
									/>
								</Spin>
							</div>
						</div>

						<div className="col-lg-12 ">
							<Modal
								title={titleValue ? t(titleValue) : t("Limits")}
								visible={isModalVisible}
								onCancel={handleCancel}
								footer={[
									<Fragment>
										<div className={page == "limitType" ? "d-block" : "d-none"}>
											<Button onClick={handleNext} className={"primary-btn "}>
												{t("Next")}
											</Button>
										</div>
										<div
											className={
												page == "limitDropdown" ? "d-block" : "d-none"
											}>
											<Button key="back" onClick={handleCancel}>
												{t("Cancel")}
											</Button>
											<Button
												key="submit"
												type="primary"
												onClick={handleSubmit}>
												{t("Save")}
											</Button>
										</div>
									</Fragment>,
								]}
								className="modal__limit">
								<div className="form__fields border-0 p-0">
									<div className="row">
										<div className="col-md-12">
											{page === "limitType" && (
												<div className="">
													<Radio.Group
														onChange={(e) => {
															handleOnChange(e);
														}}
														value={clientData.value}
														name="value">
														<Radio value={"Limit Retailer To Diamond Dealer"}>
															{t("Limit Retailer To Diamond Dealer")}
														</Radio>
														<Radio value={"Limit Diamond Dealer To Retailer"}>
															{t("Limit Diamond Dealer To Retailer")}
														</Radio>
														<Radio value={"Limit Retailer To Vendor"}>
															{t("Limit Retailer To Vendor")}
														</Radio>
														<Radio value={"Limit Vendor To Retailer"}>
															{t("Limit Vendor To Retailer")}
														</Radio>
													</Radio.Group>
													{validations.valueVal && (
														<p className="error-color-red">
															{validations.valueVal}
														</p>
													)}
												</div>
											)}
											{page === "limitDropdown" && (
												<Fragment>
													{clientData.value ==
													"Limit Retailer To Diamond Dealer" ? (
														<Fragment>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Retailer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.retailer
																				? clientData.retailer
																				: null
																		}
																		name="retailer"
																		showSearch
																		placeholder={t("Select Retailer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "retailer")
																		}
																		className={
																			validations.retailersVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{retailerList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	<div>
																		{validations.retailersVal && (
																			<p className="error-color-red">
																				{validations.retailersVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>

															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Diamond Dealer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.diamond
																				? clientData.diamond
																				: null
																		}
																		name="diamond"
																		showSearch
																		placeholder={t("Select Diamond Dealer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "diamond")
																		}
																		className={
																			validations.diamondVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{secondaryList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	{validations.diamondVal && (
																		<p className="error-color-red">
																			{validations.diamondVal}
																		</p>
																	)}
																</div>
															</div>
														</Fragment>
													) : null}
													{clientData.value ==
													"Limit Diamond Dealer To Retailer" ? (
														<Fragment>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Diamond Dealer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.diamond
																				? clientData.diamond
																				: null
																		}
																		name="diamond"
																		showSearch
																		placeholder={t("Select Diamond Dealer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "diamond")
																		}
																		className={
																			validations.diamondVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{secondaryList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	{validations.diamondVal && (
																		<p className="error-color-red">
																			{validations.diamondVal}
																		</p>
																	)}
																</div>
															</div>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Retailer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.retailer
																				? clientData.retailer
																				: null
																		}
																		name="retailer"
																		showSearch
																		placeholder={t("Select Retailer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "retailer")
																		}
																		className={
																			validations.retailersVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{retailerList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	<div>
																		{validations.retailersVal && (
																			<p className="error-color-red">
																				{validations.retailersVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>
														</Fragment>
													) : null}
													{clientData.value == "Limit Retailer To Vendor" ? (
														<Fragment>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Retailer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.retailer
																				? clientData.retailer
																				: null
																		}
																		name="retailer"
																		showSearch
																		placeholder={t("Select Retailer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "retailer")
																		}
																		className={
																			validations.retailersVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{retailerList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	<div>
																		{validations.retailersVal && (
																			<p className="error-color-red">
																				{validations.retailersVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Vendor")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.vendor
																				? clientData.vendor
																				: null
																		}
																		name="vendor"
																		showSearch
																		placeholder={t("Select Vendor ")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "vendor")
																		}
																		className={
																			validations.vendorVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{secondaryList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	{validations.vendorVal && (
																		<p className="error-color-red">
																			{validations.vendorVal}
																		</p>
																	)}
																</div>
															</div>
														</Fragment>
													) : null}
													{clientData.value == "Limit Vendor To Retailer" ? (
														<Fragment>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Vendor")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.vendor
																				? clientData.vendor
																				: null
																		}
																		name="vendor"
																		showSearch
																		placeholder={t("Select Vendor ")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "vendor")
																		}
																		className={
																			validations.vendorVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{secondaryList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	{validations.vendorVal && (
																		<p className="error-color-red">
																			{validations.vendorVal}
																		</p>
																	)}
																</div>
															</div>
															<div className="col-md-12">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Retailer")}
																		<span className="mandatory">*</span>{" "}
																	</label>
																	<Select
																		value={
																			clientData.retailer
																				? clientData.retailer
																				: null
																		}
																		name="retailer"
																		showSearch
																		placeholder={t("Select Retailer")}
																		optionFilterProp="children"
																		onChange={(e) =>
																			handleOnChange(e, "select", "retailer")
																		}
																		className={
																			validations.retailersVal
																				? "border__red"
																				: "border__grey"
																		}>
																		{retailerList.map((item) => {
																			return (
																				<Option value={item.dealerID}>
																					{item.dealerCompany +
																						" (" +
																						item.dealerID +
																						" )"}
																				</Option>
																			);
																		})}
																	</Select>
																	<div>
																		{validations.retailersVal && (
																			<p className="error-color-red">
																				{validations.retailersVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>
														</Fragment>
													) : null}
												</Fragment>
											)}
										</div>
									</div>
								</div>
							</Modal>
							<Modal
								title={t("Delete Limit Rule")}
								visible={isModalDelVisible}
								onCancel={handleDelCancel}
								width={400}
								centered
								footer={[
									<Button key="back" onClick={handleDelCancel}>
										{t("Cancel")}
									</Button>,
									<Button
										loading={deleteLoading}
										key="submit"
										type="primary"
										onClick={handleDeleteRows}>
										{t("Delete")}
									</Button>,
								]}>
								<div className="text-center">
									<p>{t("Are you sure to delete this Rule")} </p>
									<h5>{t("THIS CAN NOT BE UNDONE")}</h5>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Limits;
