import { httpCommunity } from "../helper/http-common";

class CommunityVendorService {
  BindDataCommVendor(inputData) {
    return httpCommunity.post("/Vendor/BindDataCommVendor", inputData);
  }
  UpdateAcceptCollections(inputData) {
    return httpCommunity.post("/Vendor/UpdateAcceptCollections", inputData);
  }
  GeneratePopupForAffirmation(inputData) {
    return httpCommunity.post("/Vendor/GeneratePopupForAffirmation", inputData);
  }
  SendEmailToVendor(inputData) {
    return httpCommunity.post("/Vendor/SendEmailToVendor", inputData);
  }
  SendEmailToAllEmailType(inputData) {
    return httpCommunity.post("/Vendor/SendEmailToAllEmailType", inputData);
  }
  SendEmailToAllEmailTypeForRB(inputData) {
    return httpCommunity.post(
      "/Vendor/SendEmailToAllEmailTypeForRB",
      inputData
    );
  }
  CancelRetailerRequest(inputData) {
    return httpCommunity.post("/Vendor/CancelRetailerRequest", inputData);
  }
  ReActiveCollection(inputData) {
    return httpCommunity.post("/Vendor/ReActiveCollection", inputData);
  }
  ShowDataToRightNew(inputData) {
    return httpCommunity.post("/Vendor/ShowDataToRightNew", inputData);
  }
  ManageLocation(inputData) {
    return httpCommunity.post("/Vendor/ManageLocation", inputData);
  }
  UpdateInventoryByDesignerID(inputData) {
    return httpCommunity.post("/Vendor/UpdateInventoryByDesignerID", inputData);
  }
  LoadVendorProfile(inputData) {
    return httpCommunity.post("/Vendor/LoadVendorProfile", inputData);
  }
  LoadLeftRightSideData(inputData) {
    return httpCommunity.post("/Vendor/LoadLeftRightSideData", inputData);
  }
  LoadVendorConversation(inputData) {
    return httpCommunity.post("/Vendor/LoadVendorConversation", inputData);
  }
  RemoveVendorCustomerConversation(inputData) {
    return httpCommunity.post(
      "/Vendor/RemoveVendorCustomerConversation",
      inputData
    );
  }
  PostVendorConversation(inputData) {
    return httpCommunity.post("/Vendor/PostVendorConversation", inputData);
  }
  PostDealerConversation(inputData) {
    return httpCommunity.post("/Vendor/PostDealerConversation", inputData);
  }
  ShowPopupVendorsAppointment(inputData) {
    return httpCommunity.post("/Vendor/ShowPopupVendorsAppointment", inputData);
  }
  InsertVendorsAppointment(inputData) {
    return httpCommunity.post("/Vendor/InsertVendorsAppointment", inputData);
  }
  TradeShowAppointment(inputData) {
    return httpCommunity.post("/Vendor/TradeShowAppointment", inputData);
  }
  VendorLocationList(inputData) {
    return httpCommunity.post("/Vendor/VendorLocationList", inputData);
  }
  VendorRequest(inputData) {
    return httpCommunity.post("/Vendor/VendorRequest", inputData);
  }
  AdminDashBoardBindDataCommVendor(inputData) {
    return httpCommunity.post(
      "/Vendor/AdminDashBoardBindDataCommVendor",
      inputData
    );
  }
  AddUpdateEDIAccNumber(inputData) {
    return httpCommunity.post("/Vendor/AddAndUpdateEDIAccNumber", inputData);
  }
}

export default new CommunityVendorService();
