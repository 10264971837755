import React, { useState, useEffect } from "react";
import { Slider, Button, Spin, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import appsDiamondlinksService from "../../../services/apps-diamondlinks.service";
import { GetAllNumbersBetween } from "../../../helper/commanFunction";
import { NotificationManager } from "react-notifications";

const AvailableFilterSettings = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [strColors, setStrColors] = useState("");
  const [strCutGrades, setStrCutGrades] = useState("");
  const [strclaritys, setStrClaritys] = useState("");
  const [strPolishs, setStrPolishs] = useState("");
  const [strSymmetrys, setStrSymmetrys] = useState("");
  const [strFluorescences, setStrFluorescences] = useState("");
  const [strShapes, setStrShapes] = useState("");
  const [dealerId, setDealerId] = useState();
  const [AvailableFilterData, setAvailableFilterData] = useState({
    Type: null,
    VideoOnly: false,
    Carat: false,
    Price: false,
    Table: false,
    Depth: false,
    shapeList: [],
    colorList: [],
    cutList: [],
    clarityList: [],
    polishList: [],
    symmetryList: [],
    fluorescenceList: [],
    certificatesList: [],
    minCarat: 0,
    maxCarat: 22,
    minPrice: 0,
    maxPrice: 100000,
    FluoDepth: "",
    FluoTable: "",
    mainTableMin: 0,
    mainTableMax: 100,
    mainDepthMin: 0,
    mainDepthMax: 100,
    tableMin: 0,
    tableMax: 0,
    depthMin: 0,
    depthMax: 0,
  });

  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);

  const [PriceValue, setPriceValue] = useState([0, 100000]);

  const [validation, setValidation] = useState({
    depthMinVal: "",
    depthMaxVal: "",
    tableMaxVal: "",
    tableMinVal: "",
  });

  const [updateModal, setUpdateModal] = useState(false);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency ? currency.charAt(currency.length - 1) : '';


  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetDiamondTypeList(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleCancelUpdateModal = () => {
    setUpdateModal(false);
  };

  const handleUpdateSetting = () => {
    setUpdateModal(true);
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);

    if (isSelect === "select") {
      if (!e && name == "Type") {
        return;
      }
      setAvailableFilterData({
        ...AvailableFilterData,
        [name]: e,
      });
      handleGetDiamondLinkAvailableFilterSetting(dealerId, e);
    } else {
      setAvailableFilterData({
        ...AvailableFilterData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "depthMin") {
        if (e.target.value > 100 || e.target.value == "") {
          validation.depthMinVal = t("Invalid range");
          setDisableBtn(true);
        } else {
          validation.depthMinVal = "";
        }
      }
      if (e.target.name === "depthMax") {
        if (e.target.value > 100 || e.target.value == "") {
          validation.depthMaxVal = t("Invalid range");
          setDisableBtn(true);
        } else {
          validation.depthMaxVal = "";
        }
      }
      if (e.target.name === "tableMin") {
        if (e.target.value > 100) {
          validation.tableMinVal = t("Invalid range");
          setDisableBtn(true);
        } else {
          validation.tableMinVal = "";
        }
      }
      if (e.target.name === "tableMax") {
        if (e.target.value > 100) {
          validation.tableMaxVal = t("Invalid range");
          setDisableBtn(true);
        } else {
          validation.tableMaxVal = "";
        }
      }

      setValidation(validation);

      if (
        validation.tableMaxVal === "" &&
        validation.tableMaxVal === "" &&
        validation.depthMaxVal === "" &&
        validation.depthMinVal === "" &&
        validation.tableMinVal === ""
      ) {
        setDisableBtn(false);
      } else {
        setDisableBtn(true);
      }
    }
  };

  const handleOnCheck = (e, isSelect, name) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    if (isSelect === "select") {
      setAvailableFilterData({
        ...AvailableFilterData,
        [name]: e.target.checked,
      });
    }
  };

  const handleSubmit = async (e) => {
    setCaratSlideValue([
      AvailableFilterData.minCarat,
      AvailableFilterData.maxCarat,
    ]);
    setPriceValue([AvailableFilterData.minPrice, AvailableFilterData.maxPrice]);
    handleSaveDiamondLinkAvailableFilterSetting();
  };

  const onShapeClick = (shape) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strShape = strShapes;
    if (!strShape.includes(shape)) {
      strShape += "," + shape;
    } else {
      strShape = strShape.replace(shape, "");
      strShape = strShape
        .split(",")
        .filter((x) => x.trim() !== "")
        .join(",");
    }
    setStrShapes(strShape);
  };

  const fnCutGradeList = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strCutGrades || "";
    var arrPolish = strCutGrades.split(",") || [];
    var arrPolishList = strCutGrades.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrCutGrades(finalstr);
  };
  const fnColorList = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strColors || "";
    var arrPolish = strColors.split(",") || [];
    var arrPolishList = strColors.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrColors(finalstr);
  };

  const onClarityClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strclaritys || "";
    var arrPolish = strclaritys.split(",") || [];
    var arrPolishList = strclaritys.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.split(",").indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (Number(minPolish) < Number(Polishval)) {
        strPolish = "";
        while (Number(tempMin) <= Number(Polishval)) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (Number(tempMin) > Number(Polishval)) {
        strPolish = "";
        var tempPolish = Polishval;
        while (Number(tempPolish) <= Number(tempMax)) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrClaritys(finalstr);
  };

  const fnPolishList = (Polishval) => {
    //Change Polish
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strPolishs || "";
    var arrPolish = strPolish.split(",") || [];
    var arrPolishList = strPolish.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrPolishs(finalstr);
  };

  const onSymmetryClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    var strPolish = strSymmetrys || "";
    var arrPolish = strSymmetrys.split(",") || [];
    var arrPolishList = strSymmetrys.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrSymmetrys(finalstr);
  };

  const onFluorescenceClick = (Polishval) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    var strPolish = strFluorescences || "";
    var arrPolish = strFluorescences.split(",") || [];
    var arrPolishList = strFluorescences.split(",") || [];

    var minPolish = arrPolish[0];
    if (minPolish == "") minPolish = arrPolish[1];
    var tempMin = minPolish;

    var maxPolish = arrPolish[arrPolish.length - 1];
    var tempMax = maxPolish;
    if (tempMax == "" || tempMax == "NaN" || tempMax == undefined)
      tempMax = Polishval;

    if (strPolish.indexOf(Polishval) >= 0) {
      while (Number(tempMax) >= Number(Polishval)) {
        strPolish = strPolish.replace(tempMax.toString(), "");
        tempMax = parseInt(tempMax) - parseInt(1);
      }
      arrPolishList = strPolish
        .split(",")
        .map((x) => x)
        .filter((z) => z);

      var index = arrPolishList.indexOf(Polishval);
      if (index > -1) {
        arrPolishList.splice(index, 1);
      }
      strPolish = arrPolishList.map((x) => x).join(",");
    } else {
      if (tempMax == Polishval) strPolish = strPolish + Polishval + ",";
      else if (minPolish < Polishval) {
        strPolish = "";
        while (tempMin <= Polishval) {
          strPolish = strPolish + "," + tempMin.toString() + ",";
          tempMin = parseInt(tempMin) + parseInt(1);
        }
      } else if (tempMin > Polishval) {
        strPolish = "";
        var tempPolish = Polishval;
        while (tempPolish <= tempMax) {
          strPolish = strPolish + tempPolish.toString() + ",";
          tempPolish = parseInt(tempPolish) + parseInt(1);
        }
      }
    }
    arrPolishList = strPolish
      .split(",")
      .map((x) => x)
      .filter((z) => z);
    var finalstr = arrPolishList.map((x) => x).join(",");
    setStrFluorescences(finalstr);
  };
  const handleGetDiamondTypeList = async (Id) => {
    try {
      let inputData = {
        dealerId: Id,
      };
      setLoading(true);
      await appsDiamondlinksService
        .GetDiamondTypeList(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData.result;
          if (message === "Success") {
            handleGetDiamondLinkAvailableFilterSetting(
              Id,
              responseData[0].diamondTypeId
            );
            setDisableBtn(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleGetDiamondLinkAvailableFilterSetting = async (Id, typeId) => {
    try {
      let inputData = {
        dealerId: Id,
        diamondTypeId: typeId,
      };
      setLoading(true);
      await appsDiamondlinksService
        .GetDiamondLinkAvailableFilterSetting(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            var tempAvailableFilterData = AvailableFilterData;
            if (responseData.diamondLinkInitialFilterSettingDefaultValues) {
              tempAvailableFilterData.shapeList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsShape;
              tempAvailableFilterData.cutList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsCuts;
              tempAvailableFilterData.colorList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsColor;
              tempAvailableFilterData.clarityList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsClarity;
              tempAvailableFilterData.polishList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsPolish;
              tempAvailableFilterData.symmetryList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsSymmetry;
              tempAvailableFilterData.fluorescenceList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsFluorescence;
              tempAvailableFilterData.certificatesList =
                responseData.diamondLinkInitialFilterSettingDefaultValues.settingsCertificates;
              tempAvailableFilterData.minCarat =
                responseData.diamondLinkInitialFilterSettingDefaultValues.caratMin;
              tempAvailableFilterData.maxCarat =
                responseData.diamondLinkInitialFilterSettingDefaultValues.caratMax;
              tempAvailableFilterData.minPrice =
                responseData.diamondLinkInitialFilterSettingDefaultValues.priceMin;
              tempAvailableFilterData.maxPrice =
                responseData.diamondLinkInitialFilterSettingDefaultValues.priceMax;
            }
            tempAvailableFilterData.Type = typeId;
            tempAvailableFilterData.Carat = responseData.dynamicCarat;
            tempAvailableFilterData.Price = responseData.dynamicPrice;
            tempAvailableFilterData.Table = responseData.dynamicTable;
            tempAvailableFilterData.Depth = responseData.dynamicDepth;
            tempAvailableFilterData.VideoOnly = responseData.isVideoFilter;
            tempAvailableFilterData.tableMin = responseData.tableMin;
            tempAvailableFilterData.tableMax = responseData.tableMax;
            tempAvailableFilterData.depthMin = responseData.depthMin;
            tempAvailableFilterData.depthMax = responseData.depthMax;

            if (responseData.priceMin == 0 && responseData.priceMax == 0) {
              setPriceValue([
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .priceMin,
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .priceMax,
              ]);
            } else {
              setPriceValue([responseData.priceMin, responseData.priceMax]);
            }
            if (responseData.caratMin == 0 && responseData.caratMax == 0) {
              setCaratSlideValue([
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .caratMin,
                responseData.diamondLinkInitialFilterSettingDefaultValues
                  .caratMax,
              ]);
            } else {
              setCaratSlideValue([
                responseData.caratMin,
                responseData.caratMax,
              ]);
            }

            if (responseData.cutGradeMin > 0) {
              setStrCutGrades(
                GetAllNumbersBetween(
                  responseData.cutGradeMin,
                  responseData.cutGradeMax
                ).join(",")
              );
            } else {
              setStrCutGrades("");
            }

            if (responseData.colorMin > 0) {
              setStrColors(
                GetAllNumbersBetween(
                  responseData.colorMin,
                  responseData.colorMax
                ).join(",")
              );
            } else {
              setStrColors("");
            }
            if (responseData.clarityMin > 0) {
              setStrClaritys(
                GetAllNumbersBetween(
                  responseData.clarityMin,
                  responseData.clarityMax
                ).join(",")
              );
            } else {
              setStrClaritys("");
            }
            if (responseData.polishMin > 0) {
              setStrPolishs(
                GetAllNumbersBetween(
                  responseData.polishMin,
                  responseData.polishMax
                ).join(",")
              );
            } else {
              setStrPolishs("");
            }
            if (responseData.symmetryMin > 0) {
              setStrSymmetrys(
                GetAllNumbersBetween(
                  responseData.symmetryMin,
                  responseData.symmetryMax
                ).join(",")
              );
            } else {
              setStrSymmetrys("");
            }

            if (responseData.fluorescenceMin > 0) {
              setStrFluorescences(
                GetAllNumbersBetween(
                  responseData.fluorescenceMin,
                  responseData.fluorescenceMax
                ).join(",")
              );
            } else {
              setStrFluorescences("");
            }

            setStrShapes(responseData.shapelist || "");
            setAvailableFilterData({ ...tempAvailableFilterData });
            setDisableBtn(true);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleSaveDiamondLinkAvailableFilterSetting = async (Id) => {
    try {
      let arrCutGrades =
        strCutGrades.length > 0
          ? strCutGrades.split(",").filter((x) => Number(x))
          : null;
      let arrColors =
        strColors.length > 0
          ? strColors.split(",").filter((x) => Number(x))
          : null;
      let arrPolishs =
        strPolishs.length > 0
          ? strPolishs.split(",").filter((x) => Number(x))
          : null;
      let arrClaritys =
        strclaritys.length > 0
          ? strclaritys.split(",").filter((x) => Number(x))
          : null;
      let arrSymmetrys =
        strSymmetrys.length > 0
          ? strSymmetrys.split(",").filter((x) => Number(x))
          : null;
      let arrFluorescences =
        strFluorescences.length > 0
          ? strFluorescences.split(",").filter((x) => Number(x))
          : null;

      let inputData = {
        id: 0,
        dealerID: dealerId,
        caratMin: caratSlideValue[0],
        caratMax: caratSlideValue[1],
        cutGradeMin: arrCutGrades !== null ? Math.min(...arrCutGrades) : 0,
        cutGradeMax: arrCutGrades !== null ? Math.max(...arrCutGrades) : 0,
        colorMin: arrColors !== null ? Math.min(...arrColors) : 0,
        colorMax: arrColors !== null ? Math.max(...arrColors) : 0,
        clarityMin: arrClaritys !== null ? Math.min(...arrClaritys) : 0,
        clarityMax: arrClaritys !== null ? Math.max(...arrClaritys) : 0,
        priceMin: Number(PriceValue[0]) || 0,
        priceMax: Number(PriceValue[1]) || 0,
        polishMin: arrPolishs !== null ? Math.min(...arrPolishs) : 0,
        polishMax: arrPolishs !== null ? Math.max(...arrPolishs) : 0,
        symmetryMin: arrSymmetrys !== null ? Math.min(...arrSymmetrys) : 0,
        symmetryMax: arrSymmetrys !== null ? Math.max(...arrSymmetrys) : 0,
        depthMin: Number(AvailableFilterData.depthMin) || 0,
        depthMax: Number(AvailableFilterData.depthMax) || 0,
        tableMin: Number(AvailableFilterData.tableMin) || 0,
        tableMax: Number(AvailableFilterData.tableMax) || 0,
        fluorescenceMin:
          arrFluorescences !== null ? Math.min(...arrFluorescences) : 0,
        fluorescenceMax:
          arrFluorescences !== null ? Math.max(...arrFluorescences) : 0,
        culetMin: 0,
        culetMax: 0,
        diamondType: Number(AvailableFilterData.Type),
        diamondPassword: "",
        shapelist: strShapes,
        dynamicCarat:
          AvailableFilterData.Carat == null ? false : AvailableFilterData.Carat,
        dynamicPrice:
          AvailableFilterData.Price == null ? false : AvailableFilterData.Price,
        dynamicTable:
          AvailableFilterData.Table == null ? false : AvailableFilterData.Table,
        dynamicDepth:
          AvailableFilterData.Depth == null ? false : AvailableFilterData.Depth,
        isVideoFilter:
          AvailableFilterData.VideoOnly == null
            ? false
            : AvailableFilterData.VideoOnly,
        certificates: AvailableFilterData.certificatesList
          .filter((x) => x.isSelected === true)
          .map((x) => x.value)
          .join("|"),
      };
      setSubmitLoading(true);
      await appsDiamondlinksService
        .SaveDiamondLinkAvailableFilterSetting(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          setSubmitLoading(false);
          setDisableBtn(true);
          if (message === "Success") {
            NotificationManager.success(
              t("Available Filter Setting Updated Successfully.")
            );
            setUpdateModal(false);
            handleGetDiamondLinkAvailableFilterSetting(
              dealerId,
              AvailableFilterData.Type
            );
          } else {
            NotificationManager.error(t("Available Filter Setting Not Updated."));
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };
  const handleCertificateChange = (e, item) => {
    var tempAvailableFilterData = AvailableFilterData;
    tempAvailableFilterData.certificatesList.filter(
      (x) => x.id == item.id
    )[0].isSelected = e.target.checked;
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    setAvailableFilterData({ ...tempAvailableFilterData });
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstsetCaratSlideValue = (e, num) => {
    var regExp = /[a-zA-Z-!@#$%^&*)(]/g;
    var testString = e.target.value;

    if (regExp.test(testString)) {
      return false;
    }
    setDisableBtn(false);
    let newArr = [...caratSlideValue];

    if (Number(e.target.value) > AvailableFilterData.maxCarat) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (e.target.value[0] == 0) {
          var a = e.target.value.replace("0", "");
        } else {
          var a = e.target.value;
        }
        newArr[0] = a;

        setCaratSlideValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (e.target.value[0] == 0) {
          var b = e.target.value.replace("0", "");
        } else {
          var b = e.target.value;
        }
        newArr[1] = b;
        setCaratSlideValue(newArr);
      }
    }
  };

  const firstSliderPriceValue = (e, num) => {
    setDisableBtn(false);
    let newArr = [...PriceValue];

    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) >
      AvailableFilterData.maxPrice
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(e.target.value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };

  const handleSliderCaratValue = (e) => {
    setCaratSlideValue(e);
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };
  const handleSliderPriceValue = (e) => {
    setPriceValue(e);
    if (
      validation.tableMaxVal === "" &&
      validation.tableMaxVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.tableMinVal === ""
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };
  return (
    <Spin spinning={loading} style={{ height: "100vh" }}>
      {!loading && (
        <React.Fragment>
          <div className="prodetail__section">
            <div className="form__fields">
              <div className="col-md-12">
                <div className="prod__maindiv app__preview p-0 border-0">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="workarea__heading mt-0 mb-3">
                        {t("Set Your Available Diamonds")}
                      </h4>
                      <p>
                        {t(
                          "This will control which diamonds are available in your DiamondLink."
                        )}
                      </p>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="prodesc__div">
                        <div className="tabs__content">
                          <div className="diamond__details">
                            <div className="row">
                              <div className="col-md-10">
                                <div className="row text__block">
                                  <div className="col-md-4 mt-4 mobile-m-0">
                                    <div className="input__block mobile-m-0">
                                      <div className="radio__block enable__video">
                                        <input
                                          type="checkbox"
                                          name="VideoOnly"
                                          checked={
                                            AvailableFilterData.VideoOnly ==
                                            true
                                              ? true
                                              : AvailableFilterData.VideoOnly ==
                                                null
                                              ? false
                                              : false
                                          }
                                          id="videoOnly"
                                          onChange={(e) =>
                                            handleOnCheck(
                                              e,
                                              "select",
                                              "VideoOnly"
                                            )
                                          }
                                        />

                                        <label
                                          htmlFor="videoOnly"
                                          className="mb-0"
                                        >
                                          {" "}
                                          {t(
                                            "Enable Diamonds with"
                                          )}{" "}
                                          {t("Videos Only")}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 mt-4 mobile-m-0">
                                    <div className="input__block row">
                                      <label>
                                        {" "}
                                        {t("Enable Dynamic Range")}{" "}
                                      </label>
                                      <div className="radio__block col-md-3">
                                        <input
                                          type="checkbox"
                                          name="Carat"
                                          id="carat"
                                          checked={
                                            AvailableFilterData.Carat == true
                                              ? true
                                              : AvailableFilterData.Carat ==
                                                null
                                              ? false
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleOnCheck(e, "select", "Carat")
                                          }
                                        />
                                        <label htmlFor="carat" className="mb-0">
                                          {" "}
                                          {t("Carat")}{" "}
                                        </label>
                                      </div>
                                      <div className="radio__block col-md-3">
                                        <input
                                          type="checkbox"
                                          name="Price"
                                          id="price"
                                          checked={
                                            AvailableFilterData.Price == true
                                              ? true
                                              : AvailableFilterData.Price ==
                                                null
                                              ? false
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleOnCheck(e, "select", "Price")
                                          }
                                        />
                                        <label htmlFor="price" className="mb-0">
                                          {" "}
                                          {t("Price")}{" "}
                                        </label>
                                      </div>
                                      <div className="radio__block col-md-3">
                                        <input
                                          type="checkbox"
                                          name="Table"
                                          id="table"
                                          checked={
                                            AvailableFilterData.Table == true
                                              ? true
                                              : AvailableFilterData.Table ==
                                                null
                                              ? false
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleOnCheck(e, "select", "Table")
                                          }
                                        />
                                        <label htmlFor="table" className="mb-0">
                                          {" "}
                                          {t("Table")}{" "}
                                        </label>
                                      </div>
                                      <div className="radio__block col-md-3">
                                        <input
                                          type="checkbox"
                                          name="Depth"
                                          id="depth"
                                          checked={
                                            AvailableFilterData.Depth == true
                                              ? true
                                              : AvailableFilterData.Depth ==
                                                null
                                              ? false
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleOnCheck(e, "select", "Depth")
                                          }
                                        />
                                        <label htmlFor="depth" className="mb-0">
                                          {" "}
                                          {t("Depth")}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <label className="mb-2">
                                      {t("Shape")}
                                    </label>
                                  </div>

                                  <div className="diamond__imgs col-md-12">
                                    {AvailableFilterData.shapeList.map(
                                      (item, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <div className="img__block cursor__pointer">
                                              {!item.isEnabled ? (
                                                <div
                                                  className={"imgselct__div"}
                                                >
                                                  <div
                                                    className={"any-disable"}
                                                  >
                                                    <img
                                                      src={item.shapeImage}
                                                    />
                                                    <span>
                                                      {t(item.name)}
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className={"imgselct__div"}
                                                >
                                                  <div
                                                    className={
                                                      strShapes.includes(
                                                        item.value
                                                      )
                                                        ? "selected"
                                                        : null
                                                    }
                                                    onClick={() =>
                                                      onShapeClick(item.value)
                                                    }
                                                  >
                                                    <img
                                                      src={item.shapeImage}
                                                    />
                                                    <span>
                                                      {t(item.name)}
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="sliderrange__maindiv">
                                        <label> {t("Carats")} </label>
                                        <div className="col-md-12 mt-3">
                                          <Slider
                                            tooltipVisible={false}
                                            range
                                            step={0.01}
                                            value={caratSlideValue}
                                            min={AvailableFilterData.minCarat}
                                            max={AvailableFilterData.maxCarat}
                                            onChange={(e) => {
                                              handleSliderCaratValue(e);
                                            }}
                                          />
                                          <div className="rangeSlider__values">
                                            <div className="input__block">
                                              <input
                                                type="text"
                                                value={caratSlideValue[0]}
                                                onChange={(e) => {
                                                  firstsetCaratSlideValue(e, 0);
                                                }}
                                                onKeyPress={onKeyPressEvent1}
                                              />
                                            </div>
                                            <div className="input__block">
                                              <input
                                                type="text"
                                                value={caratSlideValue[1]}
                                                onChange={(e) => {
                                                  firstsetCaratSlideValue(e, 1);
                                                }}
                                                onKeyPress={onKeyPressEvent1}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="sliderrange__maindiv">
                                        <label> {t("Price")} </label>
                                        <div className="col-md-12 mt-3">
                                          <Slider
                                            tooltipVisible={false}
                                            range
                                            value={PriceValue}
                                            min={AvailableFilterData.minPrice}
                                            max={AvailableFilterData.maxPrice}
                                            onChange={(e) => {
                                              handleSliderPriceValue(e);
                                            }}
                                          />
                                          <div className="rangeSlider__values">
                                            <div className="input__block avfilter">
                                              <input
                                                type="text"
                                                value={PriceValue[0].toLocaleString(
                                                  "en-US"
                                                )}
                                                className="pl-5"
                                                onChange={(e) => {
                                                  firstSliderPriceValue(e, 0);
                                                }}
                                              />
                                              <span className="dollar__symbol">
                                                {currencySymbol}
                                              </span>
                                            </div>
                                            <div className="input__block avfilter">
                                              <input
                                                type="text"
                                                value={PriceValue[1].toLocaleString(
                                                  "en-US"
                                                )}
                                                className="pl-5"
                                                onChange={(e) => {
                                                  firstSliderPriceValue(e, 1);
                                                }}
                                              />
                                              <span className="dollar__symbol">
                                                {currencySymbol}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Diamond_Options col-md-12 mt-4">
                                    <label> {t("Cut")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.cutList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <div>
                                                <span>
                                                  {" "}
                                                  {t(item.name)}{" "}
                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                strCutGrades.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                            >
                                              <div
                                                onClick={() =>
                                                  fnCutGradeList(item.value)
                                                }
                                              >
                                                <span>
                                                  {" "}
                                                  {t(item.name)}{" "}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className=" Diamond_Options col-md-12 mt-4">
                                    <label> {t("Color")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.colorList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <React.Fragment key={i}>
                                              <div
                                                className={
                                                  "selection__box  col any-disable"
                                                }
                                              >
                                                <span>
                                                  {t(item.name)}{" "}
                                                </span>
                                              </div>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment key={i}>
                                              <div
                                                className={
                                                  strColors.includes(item.value)
                                                    ? "selected selection__box  col "
                                                    : "selection__box  col"
                                                }
                                                onClick={() =>
                                                  fnColorList(item.value)
                                                }
                                              >
                                                <span>
                                                  {t(item.name)}{" "}
                                                </span>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className=" Diamond_Options col-md-12 mt-4">
                                    <label> {t("Clarity")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.clarityList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                !item.isEnabled
                                                  ? "any-disable"
                                                  : strclaritys &&
                                                    strclaritys
                                                      .split(",")
                                                      .includes(item.value)
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onClarityClick(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className="Diamond_Options col-md-6 mt-4">
                                    <label> {t("Polish")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.polishList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                strPolishs.includes(item.value)
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                fnPolishList(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className=" Diamond_Options col-md-6 mt-4">
                                    <label> {t("Symmetry")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.symmetryList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <div
                                              className={
                                                "any-disable selection__box  col"
                                              }
                                            >
                                              <span>{t(item.name)}</span>
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                strSymmetrys.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onSymmetryClick(item.value)
                                              }
                                            >
                                              <span>{t(item.name)}</span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className=" Diamond_Options col-md-6 mt-4">
                                    <label> {t("Fluorescence")} </label>
                                    <div className="selection__block row">
                                      {AvailableFilterData.fluorescenceList.map(
                                        (item, i) => {
                                          return !item.isEnabled ? (
                                            <div
                                              className={
                                                "any-disable  selection__box  col"
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                strFluorescences.includes(
                                                  item.value
                                                )
                                                  ? "selected selection__box  col "
                                                  : "selection__box  col"
                                              }
                                              onClick={() =>
                                                onFluorescenceClick(item.value)
                                              }
                                            >
                                              <span>
                                                {" "}
                                                {t(item.name)}{" "}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-3 mt-4">
                                    <div className="input__block">
                                      <label> {t("Depth")} </label>
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <div className="position-relative">
                                            <input
                                              type="number"
                                              name="depthMin"
                                              min={
                                                AvailableFilterData.mainDepthMin
                                              }
                                              max={
                                                AvailableFilterData.mainDepthMax
                                              }
                                              value={
                                                AvailableFilterData.depthMin
                                              }
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.slice(0, 9);
                                                handleOnChange(e, "depthMin");
                                              }}
                                              onKeyPress={onKeyPressEvent}
                                              className={
                                                validation.depthMinVal &&
                                                "border__red"
                                              }
                                            />
                                            <span className="depth_ampcent__txt">
                                              %
                                            </span>
                                            {validation.depthMinVal && (
                                              <span className="error-color-red">
                                                {validation.depthMinVal}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div className="position-relative">
                                            <input
                                              type="number"
                                              name="depthMax"
                                              min={
                                                AvailableFilterData.mainDepthMin
                                              }
                                              max={
                                                AvailableFilterData.mainDepthMax
                                              }
                                              value={
                                                AvailableFilterData.depthMax
                                              }
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.slice(0, 9);
                                                handleOnChange(e, "depthMax");
                                              }}
                                              onKeyPress={onKeyPressEvent}
                                              className={
                                                validation.depthMaxVal &&
                                                "border__red"
                                              }
                                            />
                                            <span className="depth_ampcent__txt">
                                              %
                                            </span>
                                            {validation.depthMaxVal && (
                                              <span className="error-color-red">
                                                {validation.depthMaxVal}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3 mt-4">
                                    <div className="input__block">
                                      <label> {t("Table")} </label>
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          <div className="position-relative">
                                            <input
                                              type="number"
                                              name="tableMin"
                                              min={
                                                AvailableFilterData.mainTableMin
                                              }
                                              max={
                                                AvailableFilterData.mainTableMax
                                              }
                                              value={
                                                AvailableFilterData.tableMin
                                              }
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.slice(0, 9);
                                                handleOnChange(e, "tableMin");
                                              }}
                                              onKeyPress={onKeyPressEvent}
                                              className={
                                                validation.tableMinVal &&
                                                "border__red"
                                              }
                                            />
                                            <span className="depth_ampcent__txt">
                                              %
                                            </span>
                                            {validation.tableMinVal && (
                                              <span className="error-color-red">
                                                {validation.tableMinVal}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-6">
                                          <div className="position-relative">
                                            <input
                                              type="number"
                                              name="tableMax"
                                              min={
                                                AvailableFilterData.mainTableMin
                                              }
                                              max={
                                                AvailableFilterData.mainTableMax
                                              }
                                              value={
                                                AvailableFilterData.tableMax
                                              }
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.slice(0, 9);
                                                handleOnChange(e, "tableMax");
                                              }}
                                              onKeyPress={onKeyPressEvent}
                                              className={
                                                validation.tableMaxVal &&
                                                "border__red"
                                              }
                                            />
                                            <span className="depth_ampcent__txt">
                                              %
                                            </span>
                                            {validation.tableMaxVal && (
                                              <span className="error-color-red">
                                                {validation.tableMaxVal}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <div className="input__block row">
                                      <label>
                                        {" "}
                                        {t("Certificates")}{" "}
                                      </label>
                                      {AvailableFilterData.certificatesList.map(
                                        (item, i) => {
                                          return (
                                            <div className="radio__block col-md-3">
                                              <input
                                                id={i + item.value}
                                                type="checkbox"
                                                name="AGS"
                                                checked={item.isSelected}
                                                onChange={(e) =>
                                                  handleCertificateChange(
                                                    e,
                                                    item
                                                  )
                                                }
                                              />
                                              <label
                                                className="mb-0"
                                                htmlFor={i + item.value}
                                              >
                                                {t(item.name)}
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                      <Button
                                        loading={submitLoading}
                                        onClick={() => handleUpdateSetting()}
                                        className={
                                          disableBtn
                                            ? "disabled-btn "
                                            : "primary-btn "
                                        }
                                        disabled={disableBtn}
                                      >
                                        {" "}
                                        {t("Update Filters")}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modalconsupld__section"
            centered
            visible={updateModal}
            onCancel={() => handleCancelUpdateModal()}
            width={400}
            footer={[
              <Button key="submit" type="primary" onClick={handleSubmit}>
                {t("Yes")}
              </Button>,
              <Button key="back" onClick={handleCancelUpdateModal}>
                {t("No")}
              </Button>,
            ]}
          >
            <div className="col-lg-12">
              <div className="modal__body">
                <div className="col-lg-12">
                  <div className="form__fields border-0 ">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <p>
                          {t("Warning: Changing or modifying your")} "
                          {t("Available Filter Settings")}"{" "}
                          {t("will cause the")} "
                          {t("Initial Filter Settings")}"{" "}
                          {t("to be reset: Click Yes to proceed")}
                        </p>
                        <h5> </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </Spin>
  );
};

export default AvailableFilterSettings;
