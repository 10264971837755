import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import menuIconRight from "../../assets/images/menu-right.svg";
import menuIconLeft from "../../assets/images/menu-left.svg";
import noun_user from "../../assets/images/icons/noun_user.svg";
import noun_language from "../../assets/images/icons/noun_language.svg";
import noun_dollar from "../../assets/images/icons/noun_dollar.svg";
import logout from "../../assets/images/icons/logout.svg";
import { setLoginResetData, setLoginToken } from "../../actions/login/login";
import {
	handleResetIframe,
	setChecklinkHeaderMenuData,
	setMenuResetData,
	setOpenKeyHeaderMenuData,
	setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";
import { Menu, Dropdown, Popover } from "antd";
import { useTranslation } from "react-i18next";
import {
	setContactPageNameType,
	setDashboardPageNameType,
} from "../../actions/Contact/Contact";
// import { setContactPageNameType } from "../../actions/Contact/Contact";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";

export const Header = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		toggleMobileMenuCollapsed,
		toggleCollapsed,
		show,
		showTopNav,
		toggle,
		mobileMenuToggle,
	} = props;

	const [name, setName] = useState("");
	const [userName, setUserName] = useState("");
	const [indexingStatus, setIndexingStatus] = useState("");
	const [dealerId, setDealerId] = useState();
	const [dealerImage, setDealerImage] = useState("");
	const [headerMenuDetails, setHeaderMenuDetails] = useState([]);
	const [reportPermisstion, setReportPermisstion] = useState({});
	const [myData, setMyData] = useState({
		diamondCharts: false,
		jewelryCharts: false,
		globalDiamondCharts: false,
		globalJewelryCharts: false,
	});
	const loginDetials = useSelector((state) => state.loginReducer);
	const headerDetials = useSelector((state) => state.headerMenuReducer);

	useEffect(() => {
		if (loginDetials.impresonateData) {
			var tempData = loginDetials.impresonateData;
			setName(tempData.name);
			setUserName(tempData.userName);
			setDealerId(tempData.dealerID);
			// setCheckLink("Dashboard");
		} else {
			if (loginDetials) {
				if (loginDetials.loginDetials) {
					setIndexingStatus(
						loginDetials.loginDetials.responseData.indexingStatus
					);
					if (!loginDetials.impresonateData) {
						setName(loginDetials.loginDetials.responseData.userProfilename);
						setUserName(loginDetials.loginDetials.responseData.dealerCompany);
						setDealerId(loginDetials.loginDetials.responseData.dealerId);
						setDealerImage(loginDetials.loginDetials.responseData.userAvtar);
						navigate(window.location.pathname);
					} else if (loginDetials.loginDetials.responseData.logInSucceeded) {
						setName(loginDetials.loginDetials.responseData.userProfilename);
						setUserName(loginDetials.loginDetials.responseData.dealerCompany);
						setDealerId(loginDetials.loginDetials.responseData.dealerId);
						setDealerImage(loginDetials.loginDetials.responseData.userAvtar);
						navigate("administrationTools");
					} else {
						navigate("/");
					}
				}
			} else {
				navigate("/");
			}
		}
	}, [loginDetials]);

	//To reset iframe
	useEffect(() => {
		dispatch(handleResetIframe(true));
	}, [window.location.pathname]);

	useEffect(() => {
		if (headerDetials) {
			if (headerDetials.headerMenuDetials) {
				setHeaderMenuDetails(headerDetials.headerMenuDetials);
			}
			if (headerDetials.reportPermisstion) {
				setReportPermisstion(headerDetials.reportPermisstion[0]);
			}
		}
	}, [headerDetials.headerMenuDetials]);

	const handleLoginValueFalse = async () => {
		dispatch(setLoginToken(null));
		dispatch(setLoginResetData());
		dispatch(setMenuResetData());
		navigate("/");
	};

	//Avatar Dropdown
	const menu = (
		<Menu className="dropdown-menu-sp">
			{/* <Menu.Item key="0">
        <NavLink to="/editProfile">
          <label>
            <img src={edit} alt="" /> {t("Edit Profile")}
          </label>
        </NavLink>
      </Menu.Item> */}

			<Menu.Item key="1" onClick={() => handleManageUser()}>
				<NavLink to="/manageUser">
					<label>
						<img src={noun_user} alt="" /> {t("Manage User")}
					</label>
				</NavLink>
			</Menu.Item>

			{/* <Menu.Item key="2" onClick={() => handleUserProfile()}>
        <NavLink to="/userProfile">
          <label>
            <img src={noun_user} alt="" /> {t("Edit Profile")}
          </label>
        </NavLink>
      </Menu.Item> */}

			{/* <Menu.Item key="3">
        <NavLink to="/manageLanguage">
          <label>
            <img src={noun_language} alt="" /> {t("Manage Language")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="4">
        <NavLink to="/manageCurrency">
          <label>
            <img src={noun_dollar} alt="" /> {t("Manage Currency")}
          </label>
        </NavLink>
      </Menu.Item> */}

			<Menu.Item key="5">
				<label onClick={handleLoginValueFalse} className="logout__txt">
					<img src={logout} alt="" /> {t("Logout")}
				</label>
			</Menu.Item>
		</Menu>
	);

	// const IdleTimer = useIdleTimer({
	//   crossTab: true,
	// });
	const handleHeaderMenuClick = (item) => {
		var MenuData = [`${("Marketing", "Marketing List")}`];
		dispatch(setChecklinkHeaderMenuData(item.oneTierPageName));
		if (item.oneTierPageName !== "Contacts") {
			dispatch(setContactPageNameType("Customer"));
		}
		if (item.oneTierPageName !== "Dashboard") {
			dispatch(setDashboardPageNameType("Dashboard"));
		}
		if (item.oneTierPageName == "Admin") {
			dispatch(setSelectSideMenu(["administrationTools"]));
		} else if (item.oneTierPageName == "Marketing") {
			dispatch(setOpenKeyHeaderMenuData(MenuData));
			dispatch(setSelectSideMenu(["marketing"]));
		} else if (item.oneTierPageName == "Search") {
			dispatch(setSelectSideMenu(["diamondSearch"]));
		} else {
			dispatch(setSelectSideMenu([]));
		}
		setTimeout(() => {
			showTopNav();
		}, 100);
	};

	const handleUserProfile = () => {
		var MenuData = [`${"Profile"}`];
		dispatch(setChecklinkHeaderMenuData("Profile"));
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["userProfile"]));
	};

	const handleCompanyProfile = () => {
		var MenuData = [`${"company"}`];
		dispatch(setChecklinkHeaderMenuData("Profile"));
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["companyProfile"]));
	};

	const handleManageUser = () => {
		var MenuData = [`${"company"}`];
		dispatch(setChecklinkHeaderMenuData("Profile"));
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["manageUser"]));
	};

	return (
		<React.Fragment>
			{/* <IdleTimer
        // ref={ref => { Null = ref }}
        crossTab
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={10}
      /> */}

			<div className="left__block">
				<div className="menu__icon">
					{/* <img
           src={menuIcon}
            className="img-fluid desktop__icon"
            onClick={() => toggleCollapsed()}
          /> */}
					<img
						src={toggle ? menuIconLeft : menuIconRight}
						className="img-fluid desktop__menubtn"
						onClick={() => toggleCollapsed()}
					/>

					<img
						src={mobileMenuToggle ? menuIconRight : menuIconLeft}
						onClick={() => toggleMobileMenuCollapsed()}
						className="img-fluid mobile__menubtn"
					/>
				</div>

				<div className="topnav__toggle">
					<h3 onClick={() => showTopNav()}>
						{t("Top Menu")}{" "}
						<i class="fa fa-chevron-down" aria-hidden="true"></i>{" "}
					</h3>
				</div>
				<Fragment>
					<div className={show ? "top__nav show" : "top__nav hide"}>
						<ul>
							{/* {headerMenuDetails &&
                headerMenuDetails.map((item, i) => {
                  if (
                    item.oneTierPageName == "Reports" &&
                    reportPermisstion &&
                    reportPermisstion.diamondCharts == false &&
                    reportPermisstion &&
                    reportPermisstion.jewelryCharts == false &&
                    reportPermisstion &&
                    reportPermisstion.globalDiamondCharts == false &&
                    reportPermisstion &&
                    reportPermisstion.globalJewelryCharts == false
                  ) {
                    return (
                      <li
                        className={
                          headerDetials.checkLink === item.oneTierPageName
                            ? "active"
                            : ""
                        }
                        onClick={() => handleHeaderMenuClick(item)}
                      >
                        <NavLink to={"/notAccess"}>
                          <label>{t(item.oneTierPageName)}</label>
                        </NavLink>
                      </li>
                    );
                  } else {
                    const shouldShowHTML = Object.values(
                      reportPermisstion
                    ).some((permission) => permission);
                    return (
                      <li
                        className={
                          headerDetials.checkLink === item.oneTierPageName
                            ? "active"
                            : ""
                        }
                        onClick={() => handleHeaderMenuClick(item)}
                      >
                        <NavLink to={"/" + item.navigateTo}>
                          <label>{t(item.oneTierPageName)}</label>
                        </NavLink>
                      </li>
                    );
                  }
                })} */}
							{headerMenuDetails &&
								headerMenuDetails.map((item, i) => {
									if (item.oneTierPageName == "Reports") {
										const shouldShowHTML = Object.values(
											reportPermisstion
										).some((permission) => permission);
										if (shouldShowHTML === false) {
											return false;
										} else {
											return (
												<li
													className={
														headerDetials.checkLink === item.oneTierPageName
															? "active"
															: ""
													}
													onClick={() => handleHeaderMenuClick(item)}>
													<NavLink to={"/" + item.navigateTo}>
														<label>{t(item.oneTierPageName)}</label>
													</NavLink>
												</li>
											);
										}
									} else {
										return (
											<li
												className={
													headerDetials.checkLink === item.oneTierPageName
														? "active"
														: ""
												}
												onClick={() => handleHeaderMenuClick(item)}>
												<NavLink to={"/" + item.navigateTo}>
													<label>{t(item.oneTierPageName)}</label>
												</NavLink>
											</li>
										);
									}
								})}
						</ul>
					</div>
				</Fragment>
			</div>

			<div className="right__block">
				{/* <div className="search__block">
          <input className="search__input" type="text" />
          <button className="search__button">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div> */}
				<div className="user__block">
					<div className="user__details">
						<span style={{ color: "#fff" }} onClick={() => handleUserProfile()}>
							<NavLink to="/userProfile" className="nav_link">
								{name}
							</NavLink>
						</span>
						<h4 onClick={() => handleCompanyProfile()}>
							<NavLink to="/companyProfile" className="nav_link">
								<span>
									{userName}| {dealerId}
									{indexingStatus == "InProcess" && (
										<Popover
											content={() => (
												<span>
													{t(
														"Your last inventory changes is in queue to get indexed."
													)}
													<br />
													{t(
														"This notification will be removed as indexing completes"
													)}
												</span>
											)}
											trigger="hover"
											placement="right">
											<span
												style={{
													color: "red",
													padding: "0px 4px",
													border: "1.5px solid red",
													borderRadius: "20px",
													fontSize: "10px",
													fontWeight: "600",
													marginLeft: "5px",
												}}>
												i
											</span>
										</Popover>
									)}
								</span>
							</NavLink>
						</h4>
						{/* <span
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={handleLoginValueFalse}
            >
              Logout
            </span> */}
					</div>

					<Dropdown overlay={menu} trigger={["click"]}>
						<a className="ant-dropdown-link">
							<div className="">
								<img className="profile__img" src={dealerImage} />
							</div>
						</a>
					</Dropdown>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Header;
