import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut, Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const LocationActivity = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  function onSearch(val) {}

  function onChange(e) {}

  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      width: 120,
    },
    {
      title: t("Shape"),
      dataIndex: "shape",
      width: 120,
    },
    {
      title: t("Size"),
      dataIndex: "size",
      width: 120,
    },
    {
      title: t("Color"),
      dataIndex: "color",
      width: 120,
    },
    {
      title: t("Cut"),
      dataIndex: "cut",
      width: 120,
    },
    {
      title: t("Clarity"),
      dataIndex: "clarity",
      width: 120,
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: 120,
    },
  ];

  const data = [
    {
      key: "1",
      date: <span>03/09/2022 - 04/07/2022 </span>,
      shape: <span>Round</span>,
      size: <span>0.50</span>,
      color: <span>H</span>,
      cut: <span>Excellent</span>,
      clarity: <span>VS1</span>,
      price: <span>$944.00</span>,
    },
  ];

  const columns1 = [
    {
      title: t("IPAddress"),
      dataIndex: "IPAddress",
      width: 150,
    },
    {
      title: t("Stock #"),
      dataIndex: "stock",
      width: 120,
    },
    {
      title: t("Dealer Company"),
      dataIndex: "dealercompany",
      width: 120,
    },
    {
      title: t("Shape"),
      dataIndex: "shape",
      width: 120,
    },
    {
      title: t("Size"),
      dataIndex: "size",
      width: 120,
    },
    {
      title: t("Cut"),
      dataIndex: "cut",
      width: 120,
    },
    {
      title: t("Color"),
      dataIndex: "color",
      width: 120,
    },
    {
      title: t("Clarity"),
      dataIndex: "clarity",
      width: 120,
    },
    {
      title: t("Certificates"),
      dataIndex: "certificate",
      width: 120,
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: 120,
    },
    {
      title: t("Day & Time"),
      dataIndex: "dayTime",
      width: 120,
    },
  ];

  const data1 = [
    {
      key: "1",
      IPAddress: <span>175.100.179.26</span>,
      stock: <span>E42116-20</span>,
      dealercompany: <span> GN Diamond</span>,
      shape: <span>Round</span>,
      size: <span>0.50</span>,
      cut: <span>Excellent</span>,
      color: <span>H</span>,
      clarity: <span>VS1</span>,
      certificate: <span>GIA</span>,
      price: <span>$1.789</span>,
      dayTime: <span>3/21 @ 07:25 AM </span>,
    },
    {
      key: "2",
      IPAddress: <span>175.100.179.26</span>,
      stock: <span>E42116-20</span>,
      dealercompany: <span> GN Diamond</span>,
      shape: <span>Round</span>,
      size: <span>0.50</span>,
      cut: <span>Excellent</span>,
      color: <span>H</span>,
      clarity: <span>VS1</span>,
      certificate: <span>GIA</span>,
      price: <span>$1.789</span>,
      dayTime: <span>3/21 @ 07:25 AM </span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {" "}
                {t("Location Activity")}{" "}
              </h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {t("From Date")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {t("To Date")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {t("Type")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder={t("Country")}
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {t("Country")} </Option>
                <Option value="lucy"> {t("State")} </Option>
                <Option value="av"> {t("City")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-3 align-self-end">
            <div className="input__block">
              <button className="primary-btn">
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4">
            <div className="input__block">
              <label>
                <b> {t("Diamond Clicks for GemFind (720)")} </b>
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns1}
              dataSource={data1}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocationActivity;
