import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import { Select, Button, Table, Modal, Spin, Tabs } from "antd";
import Earth_icon from "../../assets/images/earth_icon.png";
import {
  setContactConversationCount,
  setContactAppointmentCount,
  setContactCustomerCount,
  setSideMenuDashboardData,
} from "../../actions/Contact/Contact";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Appointment = () => {
  const { t } = useTranslation();
  const initialDeleteAppointmentData = {
    appointmentID: "",
    messageID: "",
  };
  const initialCreateAppointmentData = {
    contact: "",
    appointmentCalendar: "",
    time: "",
    day: "",
  };
  const initialValidations = {
    contactVal: "",
    appointmentCalendarVal: "",
    timeVal: "",
    dayVal: "",
  };
  const SideMenuData = useSelector((state) => state.contactReducer);

  const { Option } = Select;

  /*API binding starts*/

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState(0);
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [appointmentSubmit, setAppointmentSubmit] = useState(false);
  const [appointmentEdit, setAppointmentEdit] = useState(false);
  const [appointmentValidation, setAppointmentValidation] = useState(false);
  const initialStateAppointmentData = {
    calendar: [],
  };
  const [appointmentData, setAppointmentData] = useState(
    initialStateAppointmentData
  );
  const [visible, setVisible] = useState(false);
  const [createAppointmentData, setCreateAppointmentData] = useState(
    initialCreateAppointmentData
  );
  const [validations, setValidations] = useState(initialValidations);
  const [editValidations, setEditValidations] = useState({
    timeVal: "",
    dayVal: "",
  });

  const [customerList, setCustomerList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editAppointmentData, setEditAppointmentData] = useState({
    day: "",
    time: "",
    customerId: "",
    appid: "",
    appType: "",
  });
  const [rightSideDataList, setRightSideDataList] = useState([]);
  const [rightSideLoading, setRightSideLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteAppointmentData, setDeleteAppointmentData] = useState(
    initialDeleteAppointmentData
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [msgData, setMsgData] = useState({
    msg: "",
  });
  const [postData, setPostData] = useState({
    cust_id: "",
    msg_id: "",
    id: "",
  });

  const [timeList, setTimeList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [initialCount, setInitialCount] = useState(0);
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [msgValidation, setMsgValidation] = useState("");
  const [dayLoading, setDayLoading] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [rowlength, setRowlength] = useState(false);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        if (
          loginDetials.responseData.dealerMemberTypeName == "Admin" ||
          loginDetials.responseData.dealerMemberTypeName == "Retailer"
        ) {
          handleBIndAppointmentCalender();
          setTimeout(() => {
            handleGetCustomerDropDown(loginDetials.responseData.dealerId);
          }, 1000);
          setTimeout(() => {
            handleBindCustomerAppointments(loginDetials.responseData.dealerId);
          }, 1000);
        }
      }
    }
  }, [loginDetials]);
  useEffect(() => {
    handleGetCustomersAppointments(loginDetials.responseData.dealerId, "", "");
  }, [calendarData]);

  const handleSelectChange = (e, name) => {
    const tempAppointmentData = appointmentData;
    tempAppointmentData[name] = e;
    setAppointmentData((prevState) => ({
      ...prevState,
      ...tempAppointmentData,
    }));
    if (e.length > 0) {
      handleGetCustomersAppointments(
        dealerId,
        appointmentData.calendar.join(",").toString(),
        ""
      );
    } else {
      // second param changes for ("empty --> """) for Bug 239236:
      handleGetCustomersAppointments(dealerId, "", "");
    }
  };

  const handleOnChangeMsgData = (e, isSelect, name) => {
    setMsgValidation("");
    if (isSelect === "select") {
      setMsgData({
        ...msgData,
        [name]: e,
      });
    } else {
      setMsgData({
        ...msgData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleVisibleDeleteModal = (row) => {
    setDeleteModalVisible(true);
    setDeleteAppointmentData({
      appointmentID: row.id,
      messageID: row.messageID,
    });
  };

  const handleCancelDeleteModal = () => {
    setDeleteAppointmentData(initialDeleteAppointmentData);
    setDeleteModalVisible(false);
  };

  const handleOnChange = (e, isSelect, name, key) => {
    if (isSelect === "select") {
      setCreateAppointmentData({
        ...createAppointmentData,
        [name]: e,
      });

      if (name == "contact") {
        setValidations((prevdata) => ({
          ...prevdata,
          contactVal: "",
        }));
      }
      if (name == "appointmentCalendar") {
        setValidations((prevdata) => ({
          ...prevdata,
          appointmentCalendarVal: "",
        }));
      }
      if (name == "day") {
        handleBindStoreHoursDropDown(
          e,
          key ? key.key.split("|")[0] : "",
          key ? key.key.split("|")[1] : ""
        );
        setValidations((prevdata) => ({
          ...prevdata,
          dayVal: "",
        }));
      }
      if (name == "time") {
        setValidations((prevdata) => ({
          ...prevdata,
          timeVal: "",
        }));
      }
    } else {
      setCreateAppointmentData({
        ...createAppointmentData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnChangeEditData = (e, isSelect, name, key) => {
    if (isSelect === "select") {
      setEditAppointmentData({
        ...editAppointmentData,
        [name]: e,
      });
      if (name == "day") {
        handleBindStoreHoursDropDown(
          e,
          key ? key.key.split("|")[0] : "",
          key ? key.key.split("|")[1] : ""
        );
        setEditValidations((prevdata) => ({
          ...prevdata,
          dayVal: "",
        }));
      }
    } else {
      setEditAppointmentData({
        ...editAppointmentData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleValidation = () => {
    const { contact, appointmentCalendar, day, time } = createAppointmentData;

    const validations = {
      contactVal: "",
      appointmentCalendarVal: "",
      timeVal: "",
      dayVal: "",
    };
    let isValid = true;

    if (!contact.trim()) {
      isValid = false;
      validations.contactVal = t("Please Select Contact.");
    }
    if (!appointmentCalendar) {
      isValid = false;
      validations.appointmentCalendarVal = t("Please Select Calendar.");
    }
    if (!time.trim()) {
      isValid = false;
      validations.timeVal = t("Please Select Time.");
    }
    if (!day.trim()) {
      isValid = false;
      validations.dayVal = t("Please Select Day.");
    }
    if (day != "" && time == "") {
      setAppointmentValidation(true);
    } else {
      setAppointmentValidation(false);
    }

    setValidations(validations);

    return isValid;
  };

  const handleMsgValidation = () => {
    const { msg } = msgData;

    let isValid = true;

    if (!msg.trim()) {
      isValid = false;
      setMsgValidation(t("Please Enter Message."));
    } else {
      setMsgValidation("");
    }

    return isValid;
  };

  const handleEditValidation = () => {
    const { day, time } = editAppointmentData;

    const editValidations = {
      timeVal: "",
      dayVal: "",
    };
    let isValid = true;

    if (!time.trim()) {
      isValid = false;
      editValidations.timeVal = t("Time is Compulsory.");
    }
    if (!day.trim()) {
      isValid = false;
      editValidations.dayVal = t("Day is Compulsory.");
    }

    setEditValidations(editValidations);

    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setAppointmentValidation(false);
    setValidations(initialValidations);
    setCreateAppointmentData(initialCreateAppointmentData);
  };

  const handleCancelEditModal = () => {
    setEditModalVisible(false);
    setValidations(initialValidations);
    setCreateAppointmentData(initialCreateAppointmentData);
  };

  const handleOpenEditModal = (row) => {
    setEditModalVisible(true);
    setEditAppointmentData({
      day: "",
      time: row.time,
      customerId: row.cust_id,
      appid: row.id,
      appType: row.appointmentTypeID,
    });
    handleBindCustomerAppointments(dealerId);
  };

  const handleGetCustomersAppointments = async (id, condition, sortby) => {
    let valuesFroDropdown = calendarData?.map((e) => e.id)?.join(",") ?? "";
    if (valuesFroDropdown == "") valuesFroDropdown = "10,9,8,7,6,5,4,3,2,1";
    try {
      let inputData = {
        dealerId: id.toString(),
        condition:
          condition == "empty"
            ? ""
            : condition && condition !== ""
            ? condition
            : valuesFroDropdown,
        sortBy: sortby ? sortby.toString() : "",
      };
      setAppointmentLoading(true);
      AdminDashboardDataService.GetCustomersAppointments(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setAppointmentList(responseData);
            if (responseData.length > 0) {
              setRowlength(true);
            } else {
              setRowlength(false);
            }

            let tempDates = [...new Set(responseData.map(({ date }) => date))];
            setEvents(tempDates);
            if (initialCount == 0) {
              handleGetCustomerCount(
                id,
                appointmentData.calendar.length > 0
                  ? appointmentData.calendar.join(",").toString()
                  : "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25",
                ""
              );
            } else {
              handleGetCustomerCount(
                dealerId,
                appointmentData.calendar.length > 0
                  ? appointmentData.calendar.join(",").toString()
                  : "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25",
                ""
              );
            }
            handleShowCustomerRightSide(
              responseData[0].cust_id,
              responseData[0].messageID,
              id
            );
            setPostData({
              cust_id: responseData[0].cust_id,
              msg_id: responseData[0].messageID,
            });
            setAppointmentLoading(false);
            setInitialCount(1);
          } else {
            setRowlength(false);
            setAppointmentList([]);
            setAppointmentLoading(false);
          }
        })
        .catch((error) => {
          setRowlength(false);
          console.log(error);
          setAppointmentLoading(false);
        });
    } catch (error) {
      setRowlength(false);
      console.log(error);
      setAppointmentLoading(false);
    }
  };

  // API for Redux

  const handleSendCount = (val1, val2, val3) => {
    dispatch(setContactConversationCount(val1));
    dispatch(setContactAppointmentCount(val2));
    dispatch(setContactCustomerCount(val3));
  };

  const handleGetCustomerCount = async (id, condition, sortby) => {
    let inputData = {
      dealerId: id.toString(),
      condition: condition
        ? condition
        : appointmentData.calendar.join(",").toString(),
      sortBy: sortby ? sortby : "",
    };

    try {
      AdminDashboardDataService.GetCustomerCount(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            handleSendCount(
              responseData.conservationCount,
              responseData.appointmentcount,
              responseData.customerCount
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCustomerDropDown = async (id) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      AdminDashboardDataService.GetCustomerDropDown(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setCustomerList(responseData);
          } else {
            setCustomerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBIndAppointmentCalender = () => {
    setAppointmentLoading(true);

    try {
      AdminDashboardDataService.BIndAppointmentCalender()
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let tempArray = [];

            setAppointmentData((prevState) => ({
              ...prevState,
              calendar: tempArray,
            }));

            setCalendarData(responseData.calanederList);
          } else {
            setCalendarData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveAppointment = () => {
    let isValid = handleValidation();

    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        appid: "",
        appType: createAppointmentData.appointmentCalendar.toString(),
        message: "",
        appDate: createAppointmentData.day.toString(),
        appTime: createAppointmentData.time.toString(),
        customerId: createAppointmentData.contact.toString(),
        dealerId: dealerId.toString(),
      };
      setAppointmentSubmit(true);
      AdminDashboardDataService.SaveAppointment(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            setVisible(false);
            NotificationManager.success(t("Appointment Added Successfully."));
            setAppointmentValidation(false);
            setAppointmentSubmit(false);
            handleGetCustomersAppointments(
              dealerId,
              appointmentData.calendar.length > 0
                ? appointmentData.calendar.join(",").toString()
                : "",
              ""
            );
            handleGetCustomerCount(
              dealerId,
              appointmentData.calendar.length > 0
                ? appointmentData.calendar.join(",").toString()
                : "1,2,3,4,5,6,7",
              ""
            );
            setCreateAppointmentData(initialCreateAppointmentData);
          } else {
            setVisible(false);
            NotificationManager.error(t("Appointment Not Added."));
            setAppointmentValidation(false);
            setAppointmentSubmit(false);
            setCreateAppointmentData(initialCreateAppointmentData);
          }
        })
        .catch((error) => {
          console.log(error);
          setVisible(false);
          setAppointmentValidation(false);
          setAppointmentSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setVisible(false);
      setAppointmentValidation(false);
      setAppointmentSubmit(false);
    }
  };

  const handleUpdateAppointment = () => {
    let isValid = handleEditValidation();

    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        appid: editAppointmentData.appid,
        appType: editAppointmentData.appType.toString(),
        message: "",
        appDate: editAppointmentData.day.toString(),
        appTime: editAppointmentData.time.toString(),
        customerId: editAppointmentData.customerId.toString(),
        dealerId: dealerId.toString(),
      };
      setAppointmentEdit(true);
      AdminDashboardDataService.UpdateAppointment(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            setEditModalVisible(false);
            NotificationManager.success(t("Appointment Updated Successfully."));
            setAppointmentEdit(false);
            handleGetCustomersAppointments(
              dealerId,
              appointmentData.calendar.length > 0
                ? appointmentData.calendar.join(",").toString()
                : "",
              ""
            );
          } else {
            setEditModalVisible(false);
            NotificationManager.error(t("Appointment Not Updated."));
            setAppointmentEdit(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setEditModalVisible(false);
          setAppointmentEdit(false);
        });
    } catch (error) {
      console.log(error);
      setEditModalVisible(false);
      setAppointmentEdit(false);
    }
  };

  const handleShowCustomerRightSide = async (cust_id, msgID, id) => {
    try {
      let inputData = {
        cust_ID: cust_id.toString(),
        messageID: msgID.toString(),
        dealerID: id ? id.toString() : dealerId.toString(),
      };
      setRightSideLoading(true);
      AdminDashboardDataService.ShowCustomerRightSide(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setRightSideDataList(
              responseData.showsidebarclassList
                ? responseData.showsidebarclassList[0]
                : []
            );
            setRightSideLoading(false);
            setConversationList(responseData.appointmentlink);
          } else {
            setRightSideDataList([]);
            setRightSideLoading(false);
            setConversationList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  const handleRemoveAppointment = () => {
    try {
      let inputData = {
        appointmentID: deleteAppointmentData.appointmentID,
        messageID: deleteAppointmentData.messageID,
      };
      setDeleteLoading(true);
      AdminDashboardDataService.RemoveAppointment(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(t("Appointment Deleted Successfully."));
            handleGetCustomersAppointments(
              dealerId,
              appointmentData.calendar.length > 0
                ? appointmentData.calendar.join(",").toString()
                : "",
              ""
            );
            handleShowCustomerRightSide("", "");
            handleCancelDeleteModal();
            setDeleteLoading(false);
            dispatch(
              setSideMenuDashboardData(!SideMenuData.toggleSideMenuData)
            );
          } else {
            NotificationManager.error(t("Appointment Not Deleted."));
            setDeleteLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteLoading(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
    }
  };

  const handlePostMsg = () => {
    let isValid = handleMsgValidation();

    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        customerID: postData.cust_id.toString(),
        dealerID: dealerId.toString(),
        msg: "APPOINTMENT - " + msgData.msg.toString(),
      };
      setRightSideLoading(true);
      AdminDashboardDataService.PostMsg(inputData)
        .then((response) => {
          let message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(t("Message saved successfully."));
            setRightSideLoading(false);
            setMsgData({
              msg: "",
            });
            handleShowCustomerRightSide(
              postData.cust_id,
              postData.msg_id,
              dealerId
            );
          } else {
            NotificationManager.error(t("Message Not saved."));
            setRightSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  // For Time API
  const [timeLoading, setTimeLoading] = useState(false);
  const handleBindStoreHoursDropDown = async (date, stime, etime) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        sTime: stime ? stime : "8 AM",
        eTime: etime ? etime : "12 PM",
        dtDate: date ? date : "",
        appTime: "",
      };
      setTimeLoading(true);
      AdminDashboardDataService.BindStoreHoursDropDown(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setTimeList(responseData);
            setTimeLoading(false);
          } else {
            setTimeList([]);
            setTimeLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setTimeLoading(false);
        });
    } catch (error) {
      console.log(error);
      setTimeLoading(false);
    }
  };

  const handleBindCustomerAppointments = async (id) => {
    try {
      let inputData = {
        dealerID: id ? id.toString() : dealerId.toString(),
        customerId: "",
        appointDate: "",
      };
      setDayLoading(true);
      AdminDashboardDataService.BindCustomerAppointments(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setDayList(responseData);
            setDayLoading(false);
          } else {
            setDayList([]);
            setDayLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDayLoading(false);
        });
    } catch (error) {
      console.log(error);
      setDayLoading(false);
    }
  };

  /*Appintments API binding Ends*/

  const columns = [
    {
      title: t("Contact"),
      dataIndex: "fullName",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      render: (item, row) => {
        return <span className="linkText">{row.fullName}</span>;
      },
    },
    {
      title: t("Calendar"),
      dataIndex: "calanderName",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      key: "Calendar",
    },
    {
      title: t("Time"),
      dataIndex: "time",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      key: "Time",
    },
    {
      title: t("Day"),
      dataIndex: "day",
      showSorterTooltip: false,
      sorter: (a, b) => {},
      key: "Day",
    },
    {
      title: t("Action"),
      dataIndex: "action",
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img
              src={Edit_icon}
              alt=""
              onClick={() => handleOpenEditModal(row)}
            />
          </div>
          <div className="image__block">
            <img
              src={Delete_iconbl}
              alt=""
              onClick={() => handleVisibleDeleteModal(row)}
            />
          </div>
        </div>
      ),
    },
  ];

  // Redirection
  let navigate = useNavigate();
  const handleRedirection = (type, id) => {
    if (type == "Conversation") {
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: postData.cust_id,
        },
      });
    } else if (type == "Profile") {
      navigate("/profileNew", {
        state: {
          key: "Profile",
          id: id || postData.cust_id,
        },
      });
    }
  };

  // Sorting API

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let Sortby = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column =
        sorter.field == "fullName"
          ? "FullName"
          : sorter.field == "calanderName"
          ? "CalanderName"
          : sorter.field == "time"
          ? "Time"
          : sorter.field == "day"
          ? "Day"
          : "";
      Sortby = column + " " + type;
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column =
        sorter.field == "fullName"
          ? "FullName"
          : sorter.field == "calanderName"
          ? "CalanderName"
          : sorter.field == "time"
          ? "Time"
          : sorter.field == "day"
          ? "Day"
          : "";
      Sortby = column + " " + type;
    } else {
      type = "ASC";
      column = "dealerCompany";
    }

    handleGetCustomersAppointments(
      dealerId,
      appointmentData.calendar.length > 0
        ? appointmentData.calendar.join(",").toString()
        : "",
      Sortby
    );
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  const handleCreateAppointment = () => {
    handleBIndAppointmentCalender();
    handleGetCustomerDropDown(dealerId);
    handleBindCustomerAppointments(dealerId);
    setVisible(true);
  };

  const handleRefresh = () => {
    handleBIndAppointmentCalender();
  };

  return (
    <React.Fragment>
      <div className="col-md-12 form__fields border-0 mt-5">
        <div className="prod__maindiv prodmarkup__secion permission__section">
          <div className="row">
            <div className="col-md-12 magento__mapping">
              <div className="heading__block mb-3">
                <h4 className="workarea__heading mt-0 mb-0">
                  {t("Appointment")}
                </h4>

                <div className="appointment__rightblock">
                  <div className="input__block">
                    <Select
                      mode="multiple"
                      showArrow
                      className="border__grey"
                      placeholder={t("Calender")}
                      name="productType"
                      optionFilterProp="children"
                      value={
                        appointmentData.calendar
                          ? appointmentData.calendar
                          : null
                      }
                      onChange={(e) => handleSelectChange(e, "calendar")}
                    >
                      {calendarData &&
                        calendarData.map((item, i) => {
                          return <Option value={item.id}>{item.title}</Option>;
                        })}
                    </Select>
                  </div>
                  <div className="">
                    <Button
                      onClick={() => handleCreateAppointment()}
                      className="primary-btn mobile-w-100"
                    >
                      {t("Create Appointment")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9">
              <div className="prodesc__div">
                <div className="tab__contentdiv">
                  <div className="col-lg-12">
                    <div className="desc__maindiv">
                      <div
                        className="col-md-12 mb-3"
                        style={{ textAlign: "end" }}
                      >
                        <Button
                          type="primary"
                          onClick={() => {
                            handleRefresh();
                          }}
                        >
                          {t("Refresh")}
                        </Button>
                      </div>
                      <div className="col-lg-12">
                        <Spin spinning={appointmentLoading}>
                          <Table
                            className="pendjewelreq__tbl my-hover-pointer"
                            dataSource={appointmentList}
                            columns={columns}
                            onChange={handleTableASCDES}
                            scroll={{ x: 600, y: 800 }}
                            pagination={{
                              pageSizeOptions: [10, 20, 50, 100],
                              responsive: true,
                              showSizeChanger: true,
                            }}
                            onRow={(record, recordIndex) => ({
                              onClick: (event) => {
                                handleShowCustomerRightSide(
                                  record.cust_id,
                                  record.messageID,
                                  dealerId
                                );
                                setPostData({
                                  cust_id: record.cust_id,
                                  msg_id: record.messageID,
                                  id: record.id,
                                });
                              },
                            })}
                            rowClassName={(record) =>
                              record.id == postData.id
                                ? "row-active-highlight"
                                : ""
                            }
                          />
                        </Spin>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <Spin spinning={rightSideLoading}>
                {rightSideDataList.length != 0 ? (
                  <Tabs>
                    {rowlength && (
                      <Tabs.TabPane tab={t("Customer Info")} key="item-1">
                        <div className="right__section">
                          <div className="profile__block">
                            <p
                              style={{ color: "#000000" }}
                              className="retailer__name"
                            >
                              {rightSideDataList.fullName
                                ? rightSideDataList.fullName
                                : ""}
                            </p>
                            <div className="logoImg__block p-0">
                              <img
                                src={
                                  rightSideDataList.avatarValue
                                    ? rightSideDataList.avatarValue
                                    : ""
                                }
                              />
                            </div>
                            <div className="d-flex">
                              <div>
                                <img src={Earth_icon} />
                              </div>

                              <p className="activeFrom ml-1">
                                {t("Active")} |{" "}
                                {rightSideDataList.activity
                                  ? rightSideDataList.activity
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="retailer__dtls mb-2">
                            <p
                              className="linkText"
                              onClick={() => handleRedirection("Conversation")}
                            >
                              {rightSideDataList.totalMessages
                                ? rightSideDataList.totalMessages
                                : ""}{" "}
                              {t("Messages")}
                            </p>
                          </div>

                          <div className="links__block">
                            <ul>
                              <li>
                                <a href={`mailto:${rightSideDataList.cEmail}`}>
                                  {rightSideDataList.cEmail
                                    ? rightSideDataList.cEmail
                                    : ""}
                                </a>
                              </li>
                            </ul>
                            <div>
                              <p>
                                {rightSideDataList.phoneNumber
                                  ? rightSideDataList.phoneNumber
                                  : ""}
                              </p>
                            </div>

                            <hr />
                          </div>

                          <div className="bottom__block">
                            <div className="input__block">
                              <label>
                                {t("Questions")}/{t("Comments")}
                              </label>

                              <p>
                                {rightSideDataList.message
                                  ? rightSideDataList.message.replace(
                                      "APPOINTMENT -",
                                      ""
                                    )
                                  : null}
                              </p>
                            </div>

                            <div className="input__block">
                              <label>{t("Conversation")}</label>
                              <div className="conversation__msgblock">
                                {conversationList &&
                                  conversationList.map((item, i) => {
                                    return (
                                      <a
                                        onClick={() =>
                                          handleRedirection("Conversation")
                                        }
                                        className="linkText d-block"
                                      >
                                        {item}
                                      </a>
                                    );
                                  })}
                              </div>
                              <label className="mt-3">{t("Reply")}</label>
                              <textarea
                                name="msg"
                                value={msgData.msg}
                                onChange={handleOnChangeMsgData}
                                className={
                                  msgValidation ? "border__red" : "border__grey"
                                }
                              ></textarea>
                              {msgValidation && (
                                <p className="error-color-red">
                                  {msgValidation}
                                </p>
                              )}
                              <div className="text-right">
                                <Button
                                  className="primary-btn mt-2"
                                  onClick={handlePostMsg}
                                >
                                  {t("Post")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tabs.TabPane>
                    )}

                    <Tabs.TabPane tab={t("Calendars")} key="item-2">
                      <div>
                        <Calendar
                          className="w-96 h-full rounded-xl bg-violet-300 "
                          tileClassName={({ date }) => {
                            let day = date.getDate();
                            let month = date.getMonth() + 1;
                            if (date.getMonth() < 9) {
                              month = "0" + month;
                            }
                            if (date.getDate() < 9) {
                              day = "0" + day;
                            }
                            const realDate =
                              day + "-" + month + "-" + date.getFullYear();
                            if (events.find((val) => val === realDate)) {
                              return "highlight__calendar";
                            }
                          }}
                        />
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                ) : (
                  <div className="right__section">
                    <p className="no_data_found">{t("No Data Found")}</p>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </div>
      </div>

      {/* Appointment Modal */}

      <Modal
        title={t("Create Appointment")}
        className="modal__tradeshow"
        centered
        visible={visible}
        onCancel={() => handleCancel()}
        width={500}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={appointmentSubmit}
            onClick={handleSaveAppointment}
          >
            {t("Submit")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div class="col-lg-12"></div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label> {t("Contact")} </label>
                      <Select
                        name="vendor"
                        className={
                          validations.contactVal
                            ? "border__red"
                            : "border__grey"
                        }
                        showSearch
                        placeholder={t("Select Contact")}
                        optionFilterProp="children"
                        value={createAppointmentData.contact}
                        onChange={(e) => handleOnChange(e, "select", "contact")}
                      >
                        <Option value="">{t("Select Contact")}</Option>
                        {customerList &&
                          customerList.map((item, i) => {
                            return (
                              <Option value={item.cust_Id}>
                                {item.fullName}
                              </Option>
                            );
                          })}
                      </Select>
                      {validations.contactVal && (
                        <p className="error-color-red">
                          {validations.contactVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>{t("Calendar")}</label>
                      <Select
                        name="calender"
                        className={
                          validations.appointmentCalendarVal
                            ? "border__red"
                            : "border__grey"
                        }
                        showSearch
                        placeholder={t("Select Start Time")}
                        optionFilterProp="children"
                        value={createAppointmentData.appointmentCalendar}
                        onChange={(e) =>
                          handleOnChange(e, "select", "appointmentCalendar")
                        }
                      >
                        <Option value="">{t("Select Calendar")}</Option>
                        {calendarData &&
                          calendarData.map((item, i) => {
                            return (
                              <Option value={item.id}>{item.title}</Option>
                            );
                          })}
                      </Select>
                      {validations.appointmentCalendarVal && (
                        <p className="error-color-red">
                          {validations.appointmentCalendarVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>{t("Set Day")}</label>
                      <Select
                        name="calender"
                        className={
                          validations.dayVal ? "border__red" : "border__grey"
                        }
                        showSearch
                        placeholder={t("Select Day")}
                        optionFilterProp="children"
                        value={createAppointmentData.day}
                        onChange={(e, key) =>
                          handleOnChange(e, "select", "day", key)
                        }
                      >
                        <Option value="">{t("Select Day")}</Option>
                        {dayList &&
                          dayList.map((item, i) => {
                            return (
                              <Option key={item.value} value={item.dtdate}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                      {validations.dayVal && (
                        <p className="error-color-red">{validations.dayVal}</p>
                      )}
                    </div>
                  </div>
                  {createAppointmentData.day != "" &&
                  createAppointmentData.day != null &&
                  appointmentValidation == false ? (
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{t("Set Time")}</label>
                        <Select
                          name="calender"
                          className={"border__grey"}
                          showSearch
                          placeholder={t("Set Time")}
                          optionFilterProp="children"
                          value={createAppointmentData.time}
                          onChange={(e) => handleOnChange(e, "select", "time")}
                        >
                          <Option value="">{t("Set Time")}</Option>
                          {timeList &&
                            timeList.map((item, i) => {
                              return (
                                <Option value={item.name}>{item.value}</Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  ) : createAppointmentData.day != "" &&
                    createAppointmentData.day != null ? (
                    <div className="col-lg-12">
                      <div className="input__block">
                        <label>{t("Set Time")}</label>
                        <Select
                          name="calender"
                          className={
                            validations.timeVal ? "border__red" : "border__grey"
                          }
                          showSearch
                          placeholder={t("Set Time")}
                          optionFilterProp="children"
                          value={createAppointmentData.time}
                          onChange={(e) => handleOnChange(e, "select", "time")}
                        >
                          <Option value="">{t("Set Time")}</Option>
                          {timeList &&
                            timeList.map((item, i) => {
                              return (
                                <Option value={item.name}>{item.value}</Option>
                              );
                            })}
                        </Select>

                        {validations.timeVal && (
                          <p className="error-color-red">
                            {validations.timeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* End Modal */}

      {/* Appointment Edit Modal */}

      <Modal
        title={t("Edit Appointment")}
        className="modal__tradeshow"
        centered
        visible={editModalVisible}
        onOk={handleUpdateAppointment}
        onCancel={() => handleCancelEditModal()}
        maskClosable={false}
        width={500}
        footer={[
          <Button key="back" onClick={handleCancelEditModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={appointmentEdit}
            onClick={handleUpdateAppointment}
          >
            {t("Submit")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div class="col-lg-12"></div>
                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>{t("Day")}</label>
                      <Select
                        loading={dayLoading}
                        name="calender"
                        className={
                          editValidations.dayVal
                            ? "border__red"
                            : "border__grey"
                        }
                        showSearch
                        placeholder={t("Day")}
                        optionFilterProp="children"
                        value={editAppointmentData.day}
                        onChange={(e, key) =>
                          handleOnChangeEditData(e, "select", "day", key)
                        }
                      >
                        <Option value="">{t("Day")}</Option>
                        {dayList &&
                          dayList.map((item, i) => {
                            return (
                              <Option key={item.value} value={item.dtdate}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                      {editValidations.dayVal && (
                        <p className="error-color-red">
                          {editValidations.dayVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="input__block">
                      <label>{t("Time")}</label>
                      <Select
                        loading={timeLoading}
                        name="calender"
                        className={
                          editValidations.timeVal
                            ? "border__red"
                            : "border__grey"
                        }
                        showSearch
                        placeholder={t("Time")}
                        optionFilterProp="children"
                        value={editAppointmentData.time}
                        onChange={(e) =>
                          handleOnChangeEditData(e, "select", "time")
                        }
                        disabled={
                          editAppointmentData.day == "" ||
                          editAppointmentData.day == null
                            ? true
                            : false
                        }
                      >
                        <Option value="">{t("Set Time")}</Option>
                        {timeList &&
                          timeList.map((item, i) => {
                            return (
                              <Option value={item.name}>{item.value}</Option>
                            );
                          })}
                      </Select>

                      {editValidations.timeVal && (
                        <p className="error-color-red">
                          {editValidations.timeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* End Modal */}

      {/* Delete Confirmation Modal */}

      <Modal
        title={t("Delete Appointment")}
        className="datamapping__modal"
        width={400}
        centered
        visible={deleteModalVisible}
        onCancel={handleCancelDeleteModal}
        maskClosable={false}
        footer={[
          <Button onClick={handleCancelDeleteModal}>{t("Cancel")}</Button>,

          <Button
            loading={deleteLoading}
            type="primary"
            onClick={handleRemoveAppointment}
          >
            {t("Remove")}
          </Button>,
        ]}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12 text-center">
            <p> {t("Are you want to delete this appointment?")} </p>
          </div>
        </div>
      </Modal>

      {/* End Delete Confirmation Modal */}
    </React.Fragment>
  );
};

export default Appointment;
