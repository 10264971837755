import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Select, Button, Table, Modal, Switch, Spin } from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const initialMagentoData = {
	dealer: "",
	apiUrl: "",
	portNo: "",
	userName: "",
	apiKey: "",
	isActive: true,
};

const validationsValidations = {
	dealerVal: "",
	apiUrlVal: "",
	userNameVal: "",
	apiKeyVal: "",
};

export const MagentoMapping = (props) => {
	const { t } = useTranslation();
	const [getMagentoData, setGetMagentoData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [isModalVisible3, setIsModalVisible3] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dealerList, setdealerList] = useState([]);
	const [magentoData, setMagentoData] = useState(initialMagentoData);
	const [validations, setValidations] = useState(validationsValidations);
	const [editmagentoData, setEditMagentoData] = useState({
		id: "",
		dealerID: "",
		dealerCompany: "",
		apiurl: "",
		portNo: "",
		userName: "",
		apiKey: "",
		isActive: true,
	});
	const [delMagentoData, setDelMagentoData] = useState({
		id: "",
		dealerID: "",
	});

	const [editvalidations, setEditValidations] = useState({
		editapiurlVal: "",
		edituserNameVal: "",
		editapiKey: "",
	});

	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [sortType, setSortType] = useState("asc");
	const [sortColumnName, setSortColumnName] = useState("dealerId");

	const handleEditValidation = () => {
		const { apiurl, userName, apiKey } = editmagentoData;
		const editvalidations = {
			editapiurlVal: "",
			edituserNameVal: "",
			editapiKey: "",
		};
		let isValid = true;
		if (!apiurl.trim()) {
			isValid = false;
			editvalidations.editapiurlVal = t("API Url is compulsory");
		}
		if (!userName.trim()) {
			isValid = false;
			editvalidations.editfTPPasswordVal = t("Username is compulsory");
		}
		if (!apiKey.trim()) {
			isValid = false;
			editvalidations.editapiKey = t("API Key is compulsory");
		}

		if (!isValid) {
			setEditValidations(editvalidations);
		}

		return isValid;
	};

	useEffect(() => {
		handleGetOpenSourceMappingList(pageNo, pageSize, sortColumnName, sortType);
	}, []);

	const handleTableASCDES = (pagination, filters, sorter) => {
		var type = "";
		var column = "";
		if (sorter.order == "descend") {
			type = "DESC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else if (sorter.order == "ascend") {
			type = "ASC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else {
			type = "ASC";
			column = "dealerId";
			setSortColumnName(column);
			setSortType(type);
		}
		setPageNo(pagination.current);
		setPageSize(pagination.pageSize);
		handleGetOpenSourceMappingList(
			pagination.current,
			pagination.pageSize,
			column,
			type
		);
	};

	const handleGetOpenSourceMappingList = (pageNo, pageSize, column, type) => {
		let inputData = {
			pageNumber: pageNo,
			pageSize: pageSize,
			sortOrderCol:
				column == "dealerIDCompany"
					? "DealerID"
					: column == "apiurl"
					? "APIURL"
					: column == "userName"
					? "UserName"
					: column == "apiKey"
					? "APIKey"
					: column == "isActive"
					? "IsActive"
					: column,
			sortOrderType: type,
		};
		setLoading(true);
		AdminToolsDataService.GetOpenSourceMappingList(inputData)
			.then((response) => {
				var message = response.data.message;
				if (message === "Success") {
					if (response.data.responseData) {
						var dealerData = response.data.responseData[0];
						var responseData = response.data.responseData[1];
						var totalRecord = response.data.responseData[2][0].totalRecord;
						setGetMagentoData(responseData);
						setdealerList(dealerData);
						setTotalCount(totalRecord);
					} else {
						setGetMagentoData([]);
						setdealerList([]);
						setTotalCount(0);
					}
				} else {
					setGetMagentoData([]);
					setdealerList([]);
					setTotalCount(0);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const editShowModal = (row) => {
		setIsModalVisible(true);
		setEditMagentoData({
			id: row.id,
			dealerID: row.dealerID,
			dealerCompany: row.dealerCompany,
			apiurl: row.apiurl,
			portNo: row.portNo,
			userName: row.userName,
			apiKey: row.apiKey,
			isActive: row.isActive,
		});
	};

	const handleEditSubmit = () => {
		const {
			id,
			dealerID,
			dealerCompany,
			apiurl,
			portNo,
			apiKey,
			isActive,
			userName,
		} = editmagentoData;
		const isValid = handleEditValidation();
		if (!isValid) {
			return false;
		}

		let inputData = {
			id: id,
			dealerID: dealerID,
			dealerCompany: dealerCompany,
			apiURL: apiurl,
			userName: userName,
			apiKey: apiKey,
			isActive: isActive,
			portNo: portNo,
			loginUserId: "",
			loginUserName: "",
			ipAddress: "",
		};
		setLoading(true);
		AdminToolsDataService.UpdateOpenSourceMapping(inputData)
			.then((response) => {
				var message = response.data.message;
				setLoading(false);
				if (message === "Success") {
					NotificationManager.success(t("Mapping Updated Successfully."));
					handleGetOpenSourceMappingList(
						pageNo,
						pageSize,
						sortColumnName,
						sortType
					);
				} else {
					NotificationManager.error(t("Mapping Not Updated."));
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
		handleCancel();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setEditMagentoData({});
		setEditValidations({});
	};

	const delModal = (row) => {
		setIsModalVisible2(true);
		setDelMagentoData({
			id: row.id,
			dealerID: row.dealerID,
		});
	};

	const handlemagentoData = () => {
		setIsModalVisible2(true);

		let inputData = {
			id: parseInt(delMagentoData.id),
			dealerID: parseInt(delMagentoData.dealerID),
		};
		setLoading(true);
		AdminToolsDataService.DeleteOpenSourceMapping(inputData)
			.then((response) => {
				var message = response.data.message;
				setLoading(true);
				if (message === "Success") {
					NotificationManager.success(t("Mapping Deleted Successfully."));
					handleGetOpenSourceMappingList(
						pageNo,
						pageSize,
						sortColumnName,
						sortType
					);
					setLoading(false);
				} else {
					NotificationManager.error(t("Mapping Not Deleted."));
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
		handleCancel2();
	};

	const handleCancel2 = () => {
		setIsModalVisible2(false);
		setDelMagentoData({});
	};

	const showModal3 = () => {
		setIsModalVisible3(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}

		let inputData = {
			id: 0,
			dealerID: parseInt(magentoData.dealer),
			apiURL: magentoData.apiUrl,
			userName: magentoData.userName,
			apiKey: magentoData.apiKey,
			isActive: magentoData.isActive,
			portNo: magentoData.portNo,
		};
		setLoading(true);
		AdminToolsDataService.SaveOpenSourceMapping(inputData)
			.then((response) => {
				var message = response.data.message;
				var ShowMessage = response.data.responseData;
				setLoading(false);
				if (message === "Success") {
					NotificationManager.success(t("Mapping Added Successfully."));
					handleGetOpenSourceMappingList(
						pageNo,
						pageSize,
						sortColumnName,
						sortType
					);
				} else {
					NotificationManager.error(t("Mapping Not Added."));
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
		handleCancel3();
	};

	const handleCancel3 = () => {
		setIsModalVisible3(false);
		setMagentoData(initialMagentoData);
		setValidations(validationsValidations);
	};

	const handleOnChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setMagentoData({
				...magentoData,
				[name]: e,
			});
			if (name === "dealer") {
				setValidations((prevdata) => ({
					...prevdata,
					dealerVal: "",
				}));
			}
		} else {
			setMagentoData({
				...magentoData,
				[e.target.name]: e.target.value,
			});
			if (e.target.name === "apiUrl") {
				setValidations((prevdata) => ({
					...prevdata,
					apiUrlVal: "",
				}));
			}
			if (e.target.name === "apiKey") {
				setValidations((prevdata) => ({
					...prevdata,
					apiKeyVal: "",
				}));
			}
			if (e.target.name === "userName") {
				setValidations((prevdata) => ({
					...prevdata,
					userNameVal: "",
				}));
			}
		}
	};

	const handleValidation = () => {
		const { dealer, apiUrl, userName, apiKey } = magentoData;
		const validations = {
			dealerVal: "",
			apiUrlVal: "",
			userNameVal: "",
			apiKeyVal: "",
		};
		let isValid = true;

		if (!dealer) {
			isValid = false;
			validations.dealerVal = t("Dealer ID is compulsory");
		}
		if (!apiUrl.trim()) {
			isValid = false;
			validations.apiUrlVal = t("Api Url is compulsory");
		}
		if (!userName.trim()) {
			isValid = false;
			validations.userNameVal = t("Username is compulsory");
		}
		if (!apiKey.trim()) {
			isValid = false;
			validations.apiKeyVal = t("Api Key is compulsory");
		}

		if (!isValid) {
			setValidations(validations);
		}

		return isValid;
	};

	const edithandleOnChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setEditMagentoData({
				...editmagentoData,
				[name]: e,
			});
		} else {
			setEditMagentoData({
				...editmagentoData,
				[e.target.name]: e.target.value,
			});
			if (e.target.name === "apiurl") {
				setEditValidations((prevdata) => ({
					...prevdata,
					editapiurlVal: "",
				}));
			}
			if (e.target.name === "apiKey") {
				setEditValidations((prevdata) => ({
					...prevdata,
					editapiKey: "",
				}));
			}
			if (e.target.name === "userName") {
				setEditValidations((prevdata) => ({
					...prevdata,
					edituserNameVal: "",
				}));
			}
		}
	};

	const onKeyPressEvent = (event) => {
		const keyCode = event.keyCode || event.which;
		const keyValue = String.fromCharCode(keyCode);
		if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
		return;
	};

	const { dealer, apiUrl, portNo, userName, apiKey, isActive } = magentoData;
	const { dealerVal, apiUrlVal, userNameVal, apiKeyVal } = validations;
	const { editapiurlVal, edituserNameVal, editapiKey } = editvalidations;
	return (
		<div className="form__fields">
			<div className="row magento__mapping">
				<div className="col-md-12">
					<div className="heading__block mb-3">
						<h4 class="workarea__heading mt-0 mb-0">
							{t("Open Source Mapping")}
						</h4>
						<button className="primary-btn" onClick={showModal3}>
							{" "}
							<img src={Create_icon} alt="" /> {t("Open Source Mapping")}
						</button>
					</div>
				</div>
				<div className="divForm">
					<Spin spinning={loading} size="large">
						<Table
							const
							className="filter__table"
							columns={[
								{
									title: t("Dealer"),
									dataIndex: "dealerIDCompany",
									sorter: (a, b) => {},
									key: "dealerIDCompany",
									width: 170,
								},
								{
									title: t("API URL"),
									dataIndex: "apiurl",
									sorter: (a, b) => {},
									key: "apiurl",
									width: 170,
								},
								{
									title: t("User Name"),
									dataIndex: "userName",
									sorter: (a, b) => {},
									key: "userName",
									width: 120,
								},
								{
									title: t("API Key"),
									dataIndex: "apiKey",
									key: "apiKey",
									sorter: (a, b) => {},
									width: 170,
								},
								{
									title: t("Status"),
									dataIndex: "isActive",
									key: "isActive",
									sorter: (a, b) => {},
									render: (item, row) => (
										<div style={{ width: "5rem" }}>
											{row.isActive == true ? "Active" : "In-Active"}
										</div>
									),
									width: 100,
								},
								{
									title: t("Action"),
									key: "7",
									fixed: "right",
									width: 80,
									render: (item, row) => (
										<div className="action__btns">
											<div className="image__block">
												<img
													src={Edit_icon}
													onClick={() => editShowModal(row)}
													alt=""
												/>
											</div>
											<div className="image__block">
												<img
													src={Delete_iconbl}
													onClick={() => delModal(row)}
													alt=""
												/>
											</div>
										</div>
									),
								},
							]}
							dataSource={getMagentoData}
							onChange={(pagination, filters, sorters) => {
								document
									.getElementsByClassName("content__area")[0]
									.scroll(0, 0);
								handleTableASCDES(pagination, filters, sorters);
							}}
							pagination={{
								total: totalCount,
								pageNo: pageNo,
								pageSize: pageSize,
								pageSizeOptions: [10, 20, 50, 100],
								responsive: true,
								showSizeChanger: true,
							}}
							scroll={{ x: 500, y: 800 }}
						/>
					</Spin>
				</div>

				<Modal
					title={t("Edit Open Source Mapping Info")}
					centered
					visible={isModalVisible}
					onCancel={handleCancel}
					footer={[
						<Button key="back" onClick={handleCancel}>
							{t("Cancel")}
						</Button>,
						<Button key="submit" type="primary" onClick={handleEditSubmit}>
							{t("Save")}
						</Button>,
					]}>
					<div className="form__fields p-0 border-0">
						<div className="row">
							<div className="col-md-12">
								<div className="input__block">
									<label>
										{editmagentoData.dealerCompany}-{editmagentoData.dealerID}{" "}
									</label>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label>
										{" "}
										{t("API URL")}
										<span>*</span>{" "}
									</label>
									<input
										type="text"
										onChange={edithandleOnChange}
										name="apiurl"
										value={editmagentoData.apiurl}
									/>
									<div>
										{editapiurlVal && (
											<p className="error-color-red">{editapiurlVal}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label>
										{" "}
										{t("API Key")}
										<span>*</span>{" "}
									</label>
									<input
										type="text"
										onChange={edithandleOnChange}
										name="apiKey"
										value={editmagentoData.apiKey}
									/>
									<div>
										{editapiKey && (
											<p className="error-color-red">{editapiKey}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="input__block">
									<label>
										{" "}
										{t("Username")}
										<span>*</span>{" "}
									</label>
									<input
										type="text"
										onChange={edithandleOnChange}
										name="userName"
										value={editmagentoData.userName}
									/>
									<div>
										{edituserNameVal && (
											<p className="error-color-red">{edituserNameVal}</p>
										)}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input__block dropdown__hide">
									<label> {t("Port No")} </label>
									<input
										type="number"
										onChange={edithandleOnChange}
										name="portNo"
										value={editmagentoData.portNo}
										onKeyPress={onKeyPressEvent}
									/>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label> {t("IsActive")} </label>
									<Switch
										checkedChildren={t("Yes")}
										unCheckedChildren={t("No")}
										checked={editmagentoData.isActive}
										onChange={(e) =>
											edithandleOnChange(e, "select", "isActive")
										}
										name="isActive"
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					title={t("Delete Dealer Mapping")}
					centered
					visible={isModalVisible2}
					width={400}
					onCancel={handleCancel2}
					footer={[
						<Button key="back" onClick={handleCancel2}>
							{t("Cancel")}
						</Button>,
						<Button key="submit" type="primary" onClick={handlemagentoData}>
							{t("Delete")}
						</Button>,
					]}>
					<div className="col-lg-12">
						<div className="modal__body">
							<div className="col-lg-12">
								<div className="form__fields border-0 p-0">
									<div className="row">
										<div className="col-lg-12 text-center">
											<p>
												{t("Are you sure you want to delete")} "
												{delMagentoData.dealerID}" {t("Dealer Mapping")}
											</p>
											<h5>{t("THIS CAN NOT BE UNDONE")}</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					title={t("Open Source Mapping Info")}
					centered
					visible={isModalVisible3}
					onCancel={handleCancel3}
					width={600}
					footer={[
						<Button key="back" onClick={handleCancel3}>
							{t("Cancel")}
						</Button>,
						<Button key="submit" type="primary" onClick={handleSubmit}>
							{t("Save")}
						</Button>,
					]}>
					<div className="form__fields p-0 border-0">
						<div className="row">
							<div className="col-md-12">
								<div className="input__block">
									<label>
										{t("Dealer ID")} <span>*</span>{" "}
									</label>
									<Select
										showSearch
										placeholder={t("Select Dealer ID")}
										optionFilterProp="children"
										value={dealer ? dealer : null}
										name="dealer"
										onChange={(e) => handleOnChange(e, "select", "dealer")}
										className={dealerVal ? "border__red" : "border__grey"}>
										<Option value=""> {t("Select Dealer ID")} </Option>
										{dealerList.map((item) => {
											return (
												<Option value={item.dealerID}>
													{item.dealerCompany + " (" + item.dealerID + " )"}
												</Option>
											);
										})}
									</Select>
									<div>
										{dealerVal && (
											<p className="error-color-red">{dealerVal}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label>
										{" "}
										{t("API URL")}
										<span>*</span>{" "}
									</label>
									<input
										type="text"
										placeholder={t("Enter API URL")}
										name="apiUrl"
										value={apiUrl}
										onChange={handleOnChange}
										className={apiUrlVal && "border__red"}
									/>
									<div>
										{apiUrlVal && (
											<p className="error-color-red">{apiUrlVal}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-12">
								<div className="input__block">
									<label>
										{" "}
										{t("API Key")} <span>*</span>
									</label>
									<input
										type="text"
										placeholder={t("Enter API Key")}
										name="apiKey"
										value={apiKey}
										onChange={handleOnChange}
										className={apiKeyVal && "border__red"}
									/>
									<div>
										{apiKeyVal && (
											<p className="error-color-red">{apiKeyVal}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="input__block">
									<label>
										{" "}
										{t("Username")} <span>*</span>
									</label>
									<input
										type="text"
										placeholder={t("Enter Username")}
										name="userName"
										value={userName}
										onChange={handleOnChange}
										className={userNameVal && "border__red"}
									/>
									<div>
										{userNameVal && (
											<p className="error-color-red">{userNameVal}</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="input__block dropdown__hide">
									<label> {t("Port No")} </label>
									<input
										type="number"
										placeholder={t("Enter Port No")}
										name="portNo"
										value={portNo}
										onChange={handleOnChange}
										onKeyPress={onKeyPressEvent}
									/>
								</div>
							</div>

							<div className="col-md-6">
								<div className="input__block">
									<label> {t("IsActive")} </label>
									<Switch
										name="isActive"
										checkedChildren={t("Yes")}
										unCheckedChildren={t("No")}
										checked={isActive}
										onChange={(e) => handleOnChange(e, "select", "isActive")}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MagentoMapping);
