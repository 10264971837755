import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Spin } from "antd";
import { useTranslation } from "react-i18next";
import AppsDiamondLinksService from "../../../services/apps-diamondlinks.service";

const ViewInquiry = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dealerId, setDealerId] = useState();
  const [viewInquiryList, setviewInquiryList] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);

  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleviewInquiryData(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize
          );
        }
      }
    }
  }, []);

  const handleviewInquiryData = (id, pageNo, pageSize) => {
    let inputData = {
      dealerID: id,
      pageNumber: pageNo,
      pageSize: pageSize,
    };
    setLoading(true);
    try {
      AppsDiamondLinksService.GetviewInquiry(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData[0]) {
              setLoading(false);
              setviewInquiryList(responseData[0].viewinquireylist);
              setTotalCount(responseData[0].totalRecord);
            }
          } else {
            setLoading(false);
            setviewInquiryList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: t("First Name"),
      dataIndex: "firstName",
    },
    {
      title: t("Last Name"),
      dataIndex: "lastName",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phoneNumber",
    },
    {
      title: t("Email Address"),
      dataIndex: "emailAddress",
    },
    {
      title: t("Comments"),
      dataIndex: "comments",
    },
  ];

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleviewInquiryData(page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleviewInquiryData(current, size);
  }

  return (
    <React.Fragment>
      <div className="pendjewelreq__section magento__mapping">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("View Inquiry")}
              </h4>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={viewInquiryList}
              onChange={(pagination, filters, sorters) => {
                document
                  .getElementsByClassName("content__area")[0]
                  .scroll(0, 0);
              }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                onChange: handlePageNoChange,
                onShowSizeChange: handlePageSizeChange,
              }}
              scroll={{ x: 600,y: 800 }}
              loading={{ size: "large", spinning: loading }}
            />

            <hr />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewInquiry;
