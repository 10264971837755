import React, { useState, useEffect } from "react";
import { Select, Table, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const AddNewRetailerToCore = () => {
  const { t } = useTranslation();
  const [retailerData, setRetailer] = useState({
    core: "",
    retailerId: "",
  });
  const [validations, setValidations] = useState({
    coreVal: "",
    retailerIdVal: "",
  });
  const [solarList, setSolarList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [sortType, setSortType] = useState("asc");
  const [sortOrderCol, setsortOrderCol] = useState("DealerId");

  useEffect(() => {
    handleGetSolarCoreClientList(pageNo, pageSize, "asc", "DealerId");
  }, []);

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setsortOrderCol(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setsortOrderCol(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setsortOrderCol(column);
      setSortType(type);
    }
    handleGetSolarCoreClientList(
      pagination.current,
      pagination.pageSize,
      type,
      column
    );
  };
  const handleGetSolarCoreClientList = (
    pageNo,
    pageSize,
    sortOrderType,
    sortOrderCol
  ) => {
    let inputData = {
      pageNumber: pageNo,
      pageSize: pageSize,
      sortOrderType: sortOrderType || "ASC",
      sortOrderCol: sortOrderCol || "DealerId",
    };
    setLoading(true);
    AdminToolsDataService.GetRetailerCoreList(inputData)
      .then((response) => {
        setLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData.pageCoreClientResponse) {
            setSolarList(responseData.pageCoreClientResponse);
            setTotalCount(responseData.totalRecord);
          } else {
            setSolarList([]);
            setTotalCount(0);
          }
        } else {
          setSolarList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRetailer({
        ...retailerData,
        [name]: e,
      });

      if (name === "core") {
        setValidations((prevdata) => ({
          ...prevdata,
          coreVal: "",
        }));
      }
    } else {
      setRetailer({
        ...retailerData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "retailerId") {
        setValidations((prevdata) => ({
          ...prevdata,
          retailerIdVal: "",
        }));
      }
    }
  };

  const handleSaveSubmit = (e) => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    }

    let inputData = {
      retailerID: retailerData.retailerId,
      coreName: retailerData.core,
    };

    AdminToolsDataService.SaveRetailerCore(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message == "Data save successfully") {
          NotificationManager.success(
            t("Solar Client Added Successfully.")
          );
          handleGetSolarCoreClientList(
            pageNo,
            pageSize,
            sortType,
            sortOrderCol
          );
        } else if (message == "RetailerID already exists.") {
          NotificationManager.error(t("Retailer ID Already Exists."));
        } else if (message == "Invalid Retailer ID") {
          setValidations({
            ...validations,
            retailerIdVal: t("Invalid Retailer ID"),
          });
        } else {
          NotificationManager.error(t("Solar Client Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setRetailer({
          core: "",
          retailerId: "",
        });
        setValidations({
          coreVal: "",
          retailerIdVal: "",
        });
      });
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleValidation = () => {
    const { core, retailerId } = retailerData;
    const validations = { coreVal: "", retailerIdVal: "" };

    let isValid = true;

    if (!retailerId.trim()) {
      isValid = false;
      validations.retailerIdVal = t("Retailer Id is compulsory");
    }

    if (!core) {
      isValid = false;
      validations.coreVal = t("Kindly select the core");
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetSolarCoreClientList(page, pageSize, sortType, sortOrderCol);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetSolarCoreClientList(current, size, sortType, sortOrderCol);
  }

  const { core, retailerId } = retailerData;
  const { coreVal, retailerIdVal } = validations;
  return (
    <React.Fragment>
      <div className="newretailer_section SolrCore">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {t("Add New Retailer To Core")}
              </h4>
            </div>
            <div className="col-lg-3 mt-4 mobile-m-0">
              <div className="input__block mb-0">
                <label>
                  {" "}
                  {t("Select Core")} <span className="mandatory">*</span>
                </label>

                <Select
                  value={core ? core : null}
                  name="core"
                  showSearch
                  placeholder={t("Select Core")}
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "core")}
                  className={coreVal ? "border__red" : "border__grey"}
                >
                  <Option value=""> {t("Select Core")} </Option>
                  <Option value="RetailerCore1">
                    {" "}
                    {t("RetailerCore 1")}
                  </Option>
                  <Option value="RetailerCore2">
                    {" "}
                    {t("RetailerCore 2")}
                  </Option>
                  <Option value="RetailerCore3">
                    {" "}
                    {t("RetailerCore 3")}
                  </Option>
                  <Option value="RetailerCore4">
                    {" "}
                    {t("RetailerCore 4")}
                  </Option>
                  <Option value="RetailerCore5">
                    {" "}
                    {t("RetailerCore 5")}
                  </Option>
                  <Option value="RetailerCore6">
                    {" "}
                    {t("RetailerCore 6")}
                  </Option>
                  <Option value="RetailerCore7">
                    {" "}
                    {t("RetailerCore 7")}
                  </Option>
                  <Option value="RetailerCore8">
                    {" "}
                    {t("RetailerCore 8")}
                  </Option>
                  <Option value="RetailerCore9">
                    {" "}
                    {t("RetailerCore 9")}
                  </Option>
                  <Option value="RetailerCore10">
                    {" "}
                    {t("RetailerCore 10")}
                  </Option>
                  <Option value="RetailerCore11">
                    {" "}
                    {t("RetailerCore 11")}
                  </Option>
                  <Option value="RetailerCore12">
                    {" "}
                    {t("RetailerCore 12")}
                  </Option>
                  <Option value="RetailerCore13">
                    {" "}
                    {t("RetailerCore 13")}
                  </Option>
                </Select>
                <div>
                  {coreVal && <p className="error-color-red">{coreVal}</p>}
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-4">
              <div className="input__block mb-0">
                <label>
                  {" "}
                  {t("Enter Retailer ID")}{" "}
                  <span className="mandatory">*</span>{" "}
                </label>
                <input
                  value={retailerId}
                  type="text"
                  name="retailerId"
                  min="0"
                  placeholder={t("Enter Retailer ID")}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(0, 5);
                    handleOnChange(e);
                  }}
                  onKeyPress={onKeyPressEvent}
                  className={retailerIdVal && "border__red"}
                />
                <div>
                  {retailerIdVal && (
                    <p className="error-color-red">{retailerIdVal}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-4">
              <div className="retailer__btndiv mobile-m-0">
                <button onClick={handleSaveSubmit} className="primary-btn">
                  {/* <img src={Create_icon} alt="" />  */}
                  {t("Add")}
                </button>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="retailer_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "id",
                        title: t("ID"),
                        sorter: (a, b) => a.id - b.id,
                        width: 200,
                      },
                      {
                        dataIndex: "coreName",
                        sorter: (a, b) =>
                          a.coreName.replace("RetailerCore", "") -
                          b.coreName.replace("RetailerCore", ""),
                        title: t("Core Name"),
                      },
                      {
                        dataIndex: "retailerID",
                        title: t("Retailer ID"),
                        sorter: (a, b) => a.retailerID - b.retailerID,
                      },
                      {
                        dataIndex: "dealerCompany",
                        sorter: (a, b) =>
                          a.dealerCompany.localeCompare(b.dealerCompany),
                        title: t("Retailer Name"),
                      },
                    ]}
                    dataSource={solarList}
                    onChange={(pagination, filters, sorters) => {
                      document
                        .getElementsByClassName("content__area")[0]
                        .scroll(0, 0);
                      handleTableASCDES(pagination, filters, sorters);
                    }}
                    pagination={{
                      total: totalCount,
                      pageNo: pageNo,
                      pageSize: pageSize,
                      pageSizeOptions: [10, 20, 50, 100],
                      responsive: true,
                      showSizeChanger: true,
                      onChange: handlePageNoChange,
                      onShowSizeChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 600, y: 800 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewRetailerToCore;
