import React, { useState, useEffect, Fragment } from "react";
import { Table, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import appsDiamondlinksService from "../../../services/apps-diamondlinks.service";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import moment from "moment";

export const RingBuilderDealers = (props) => {
  const { t } = useTranslation();
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [saveLoadingProfileData, setSaveLoadingProfileData] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [dealerId, setDealerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [commVendorData, setCommVendorData] = useState([]);
  const [diamondRapNetPermissionData, setDiamondRapNetPermission] = useState(
    []
  );

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1000);
  const [checkBool, setCheckBool] = useState(null);
  const [mainCheckVal, setMainCheckVal] = useState(false);
  const [diamondCount, setDiamondCount] = useState({});
  const [disabledbtn, setDisabledbtn] = useState(true);

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleRingbuilderBindDealerList(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleRingbuilderBindDealerList = (id, pageNo, pageSize) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setLoading(true);
      AppsRingBuilderService.ringbuilderBindDealerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.dealerList_Ringbuilder;
          var dimaondcount_Ringbuilder =
            response.data.responseData.dimaondcount_Ringbuilder;
          if (message === "Success") {
            if (checkBool !== null) {
              if (checkBool == true) {
                var tempcommVendorData = responseData;
                tempcommVendorData.forEach((element) => {
                  element.selectedDealerID = 1;
                });
                setCommVendorData(tempcommVendorData);
                setDiamondCount(dimaondcount_Ringbuilder);
              } else {
                var tempcommVendorData = responseData;
                tempcommVendorData.forEach((element) => {
                  element.selectedDealerID = 0;
                });
                setCommVendorData(tempcommVendorData);
                setDiamondCount(dimaondcount_Ringbuilder);
              }
            } else {
              setCommVendorData(responseData);
              setDiamondCount(dimaondcount_Ringbuilder);
            }

            setLoading(false);
          } else {
            setCommVendorData([]);

            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (row, e, name) => {
    setDisabledbtn(false);
    if (name === "selectedDealerID") {
      const temp = commVendorData.map((x) => {
        if (x.dealerID == row.dealerID) {
          return {
            ...x,
            selectedDealerID: e.target.checked == true ? 1 : 0,
          };
        } else return x;
      });

      setCommVendorData(temp);
      setDiamondRapNetPermission(temp);

      const testBoolean = temp.every((m) => {
        if (m.selectedDealerID == 0) {
          return false;
        }
        return true;
      });
      setMainCheckVal(testBoolean);
    }
  };

  const handleOnCheckGridAll = (e) => {
    setDisabledbtn(false);
    setCheckBool(e.target.checked);
    setMainCheckVal(e.target.checked);
    var tempcommVendorData = commVendorData;
    tempcommVendorData.forEach((element) => {
      element.selectedDealerID = e.target.checked == true ? 1 : 0;
    });
    setCommVendorData([...tempcommVendorData]);
    setDiamondRapNetPermission([...tempcommVendorData]);
  };

  const handleShowDataToRightNew = (record) => {
    try {
      let inputData = {
        vendorID: record.dealerID.toString(),
        f_Type: "",
        dealerID: dealerId.toString(),
      };
      setSelectedColumnId(record.dealerID);
      setLoadingProfileData(true);
      appsDiamondlinksService
        .ShowDataToRightNew(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.result[0];
          if (message === "Success") {
            if (responseData) {
              setRowDetails(responseData);
              setLoadingProfileData(false);
              setDisabledbtn(true);
            } else {
              setRowDetails({});
              setLoadingProfileData(false);
            }
          } else {
            setRowDetails({});
            setLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingProfileData(false);
    }
  };

  const handleSaveSelect = () => {
    try {
      var selectedList = diamondRapNetPermissionData
        .filter(function (item) {
          return item.selectedDealerID == 1;
        })
        .map(function (item) {
          return item.dealerID;
        });
      let inputData = {
        dealerID: dealerId.toString(),
        listDealerID: selectedList,
      };
      setSaveLoadingProfileData(true);
      AppsRingBuilderService.UpdateList_Click_ringbuilder(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully."));
            // handleBindDataCommVendor(dealerId, pageNo, pageSize);
            handleRingbuilderBindDealerList(dealerId, pageNo, pageSize);
            setSaveLoadingProfileData(false);
            setDisabledbtn(true);
          } else {
            NotificationManager.success(t("Data Not Updated."));
            setSaveLoadingProfileData(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSaveLoadingProfileData(false);
        });
    } catch (error) {
      console.log(error);
      setSaveLoadingProfileData(false);
    }
  };

  const columns = [
    {
      title: t("Vendor Name"),
      dataIndex: "dealerCompany",
      showSorterTooltip: false,
      sorter: (a, b) => a.dealerCompany.localeCompare(b.dealerCompany),
    },
    {
      title: t("Mined Diamonds"),
      dataIndex: "mindCount",
      showSorterTooltip: false,
      sorter: (a, b) => a.mindCount - b.mindCount,
    },
    {
      title: t("Lab Grown Diamonds"),
      dataIndex: "labTotalCount",
      showSorterTooltip: false,
      sorter: (a, b) => a.labTotalCount - b.labTotalCount,
    },
    {
      title: t("Fancy Color Diamonds"),
      dataIndex: "color",
      showSorterTooltip: false,
      sorter: (a, b) => a.color - b.color,
    },
    {
      title: t("Updated"),
      dataIndex: "modified",
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.modified) - new Date(b.modified),
    },
    {
      title: (
        <div className="filtbtn__div filter__eye">
          <label className="table__label">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </label>
          <span className="select__all">
            <input
              type="checkbox"
              id="sel"
              onChange={(e) => handleOnCheckGridAll(e)}
              checked={mainCheckVal}
            />
          </span>
        </div>
      ),
      dataIndex: "selectedDealerID",
      width: 30,
      render: (item, row) => {
        return (
          <div className="action__btns">
            <input
              type="checkbox"
              name="selectedDealerID"
              checkedChildren={t("Yes")}
              unCheckedChildren={t("No")}
              checked={row.selectedDealerID == 1 ? true : false}
              onChange={(e) => handleOnChange(row, e, "selectedDealerID")}
            />
          </div>
        );
      },
    },
  ];

  // function handlePageNoChange(page, pageSize) {
  //   setPageNo(page);
  //   handleBindDataCommVendor(page, pageSize);
  // }
  // function handlePageSizeChange(current, size) {
  //   setPageSize(size);
  //   handleBindDataCommVendor(current, size);
  // }

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {t("Diamond Vendors")}
            </h4>
          </div>
        </div>
        
        <div className="col-lg-9 divForm vendors-section">
          <Spin spinning={loading}>
            <Table
              columns={columns}
              dataSource={commVendorData}
              scroll={{ x: 600,y: 800 }}
              rowKey={(record) => record.dealerID}
              onChange={(pagination, filters, sorters) => {
                document
                  .getElementsByClassName("content__area")[0]
                  .scroll(0, 0);
              }}
              pagination={{
                showSizeChanger: true,
                responsive: true,
              }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  handleShowDataToRightNew(record);
                },
              })}
            />
          </Spin>
        </div>

        <div className="col-lg-3 col-md-3">
          <Spin spinning={loadingprofileData}>
            <div className="right__section">
              {selectedColumnId !== 0 ? (
                <Fragment>
                  <div className="profile__block">
                    {/* <li> */}
                    <Link
                      to={"/vendorProfile"}
                      state={{
                        key: "Profile",
                        id: selectedColumnId,
                      }}
                      className="linkText"
                    >
                      {rowDetails.designerName ? rowDetails.designerName : null}
                    </Link>
                    {/* </li> */}
                    <div className="logoImg__block p-0">
                      <img src={rowDetails.src ? rowDetails.src : null} />
                    </div>
                  </div>

                  <div className="retailer__dtls mb-4">
                    <p>
                      {" "}
                      {rowDetails.dealerName
                        ? rowDetails.dealerName
                        : null}{" "}
                    </p>
                    <p>
                      {" "}
                      {rowDetails.dealerEmail
                        ? rowDetails.dealerEmail
                        : null}{" "}
                    </p>
                    <p>
                      {" "}
                      {rowDetails.dealerPhone
                        ? rowDetails.dealerPhone
                        : null}{" "}
                    </p>

                    <hr />
                  </div>
                  <div className="links__block">
                    <ul>
                      <li>
                        <Link
                          to={"/vendorProfile"}
                          state={{
                            key: "Profile",
                            id: selectedColumnId,
                          }}
                        >
                          <i
                            class="fa fa-address-book-o"
                            aria-hidden="true"
                          ></i>{" "}
                          {t("View Profile")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/vendorProfile"}
                          state={{
                            key: "Conversation",
                            id: selectedColumnId,
                          }}
                        >
                          <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                          {t("Conversation")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/vendorProfile"}
                          state={{
                            key: "Inventory",
                            id: selectedColumnId,
                          }}
                        >
                          <i class="fa fa-diamond" aria-hidden="true"></i>{" "}
                          {t("View Inventory")}
                        </Link>
                      </li>
                    </ul>
                    <hr />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="retailer__dtls mb-4">
                    <p>{t("Total Mined Diamonds")}</p>
                    <span>{diamondCount.totalMindCount}</span>

                    <hr />

                    <p>{t("Total LabGrown Diamonds")}</p>
                    <span>{diamondCount.totalLabTotalCount}</span>

                    <hr />
                    <p>{t("Total Color Diamonds")}</p>
                    <span>{diamondCount.totalColor}</span>
                  </div>
                </Fragment>
              )}
              <div className="input__block">
                <button
                  disabled={disabledbtn}
                  className={
                    disabledbtn == true ? "disabled-btn" : "primary-btn"
                  }
                  onClick={handleSaveSelect}
                  loading={saveLoadingProfileData}
                >
                  {" "}
                  {t("Update List")}{" "}
                </button>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default RingBuilderDealers;
