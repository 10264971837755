import React, { useState, useEffect } from "react";
import { Select, Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import AdminReportDataService from "../../../services/admin-report.service";
import { DatePicker } from "antd";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import moment from "moment";
const { RangePicker } = DatePicker;

const { Option } = Select;

/*Initial State for Binding Starts*/
const initialSyncState = {
  client: "0",
  dataRangeFrom: moment().subtract(30, "days").format("MM/DD/YYYY"),
  dataRangeTo: moment().format("MM/DD/YYYY"),
  type: [1, 2, 3, 4, 5],
  status: [200, 500, 1]
};
/*Initial State for Binding Ends*/

const Syncs = () => {
  const { t } = useTranslation();
  const [globalEvents, setGlobalEvents] = useState(initialSyncState);
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [SyncsGridDataList, setSyncsGridDataList] = useState([]);
  const [SyncsClientList, setSyncsClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("dealerId");
  const [sortType, setSortType] = useState("asc");
  const [EventsCount, setEventsCount] = useState(0);
  const [TotalEventsCount, setTotalEventsCount] = useState(0);
  const [key, setKey] = useState(true);

  useEffect(() => {
    handleGetSyncsClientList();
    handleSyncReportsData(pageNo, pageSize, "DealerID", "Asc");
  }, []);

  const handleOnCheck = (e, isSelect, name, flag) => {
    if (flag === "syncType") {
      let types = globalEvents.type;
      if (!types.includes(name)) {
        types.push(name);
      } else {
        let index = types.indexOf(name);
        types.splice(index, 1);
      }
      setGlobalEvents({
        ...globalEvents,
        type: types
      });
    } else {
      let status = globalEvents.status;
      if (!status.includes(name)) {
        status.push(name);
      } else {
        let index = status.indexOf(name);
        status.splice(index, 1);
      }
      setGlobalEvents({
        ...globalEvents,
        status: status
      });
    }
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalEvents((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1]
      }));
    }
  };
  /*DatePicker Binding Ends*/
  function onSearch(val) {}
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setGlobalEvents({
        ...globalEvents,
        [name]: e
      });
    } else {
      setGlobalEvents({
        ...globalEvents,
        [e.target.name]: e.target.value
      });
    }
  };

  /*API Binding Starts*/
  const handleGetSyncsClientList = () => {
    try {
      AdminReportDataService.GetSyncsClientList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSyncsClientList(responseData);
            }
          } else {
            setSyncsClientList([]);
          }
        })
        .catch((error) => {
          setSyncsClientList([]);
        });
    } catch (error) {
      setSyncsClientList([]);
    }
  };
  const handleSyncReportsData = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    let inputData = {
      dealerID: globalEvents.client.toString(),
      fromDate: globalEvents.dataRangeFrom
        ? moment(globalEvents.dataRangeFrom).format("MM/DD/YYYY")
        : "08/21/2012",
      toDate: globalEvents.dataRangeTo
        ? moment(globalEvents.dataRangeTo).format("MM/DD/YYYY")
        : "08/21/2013",
      type: globalEvents.type.toString(),
      status: globalEvents.status.toString(),
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "DealerID",
      sortOrder: sortOrder || "Asc"
    };

    setLoading(true);
    AdminReportDataService.SyncReportsData(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var syncreportDatarespresplist =
          response.data.responseData.syncreportDatarespresplist;
        var TotalEventsCount =
          response.data.responseData.syncreportDatarespresplist[0].totalcount;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            var temptotalRecords = syncreportDatarespresplist[0].totalcount;
            setTotalCount(temptotalRecords);
            setEventsCount(pageSize);
            setTotalEventsCount(TotalEventsCount);
            setSyncsGridDataList(syncreportDatarespresplist);
          }
        } else {
          setSyncsGridDataList([]);
          setTotalCount(0);
          setEventsCount(pageSize);
          setTotalEventsCount(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setSyncsGridDataList([]);
        setEventsCount(0);
        setTotalEventsCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDownloadDataCSV_Synclist = () => {
    let inputData = {
      dealerID: globalEvents.client.toString(),
      fromDate: globalEvents.dataRangeFrom
        ? moment(globalEvents.dataRangeFrom).format("MM/DD/YYYY")
        : "08/21/2012",
      toDate: globalEvents.dataRangeTo
        ? moment(globalEvents.dataRangeTo).format("MM/DD/YYYY")
        : "08/21/2013",
      type: globalEvents.type.toString(),
      status: globalEvents.status.toString()
    };
    setDownloadDataloading(true);
    AdminReportDataService.downloadDataCSV_Synclist(inputData)
      .then((response) => {
        var fileName = "ReportFileSyncReport.csv";
        BlobToDownloadFile(response.data, fileName);
      })
      .catch((error) => {
        setDownloadDataloading(false);
        console.log(error);
      })
      .finally(() => {
        setDownloadDataloading(false);
      });
  };

  const handleApplyFilter = () => {
    setKey(!key);
    handleSyncReportsData(1, 10, sortColumnName, sortType);
    setPageNo(1);
    setPageSize(10);
  };

  /*Sorting API Starts*/
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerId";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleSyncReportsData(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*Sorting API Ends*/

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">{t("Syncs")}</h4>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block mobile-m-0">
              <label> {t("Client")} </label>
              <Select
                className="border__grey"
                placeholder={t("All")}
                showSearch
                name="client"
                optionFilterProp="children"
                value={globalEvents.client ? globalEvents.client : null}
                onChange={(e) => handleOnChange(e, "select", "client")}
                onSearch={onSearch}
              >
                {/* <Option value="all">All</Option> */}
                {SyncsClientList.length > 0 &&
                  SyncsClientList.map((item, i) => {
                    if (item.dealerID == 0) {
                      return (
                        <Option value={item.dealerID.toString()}>
                          {item.dealerCompany}
                        </Option>
                      );
                    } else {
                      return (
                        <Option value={item.dealerID.toString()}>
                          {item.dealerCompany} ({item.dealerID})
                        </Option>
                      );
                    }
                  })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block dataRange-input-block datepicker__block">
              <label> {t("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-8 mb-2">
            <div className="input__block">
              <label> {t("Sync Type")} </label>
            </div>
            <div className="row">
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showFullSyncs"
                  id="showFullSyncs"
                  checked={globalEvents.type.includes(1) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 1, "syncType")}
                />
                <label htmlFor="showFullSyncs" className="font__normal">
                  {" "}
                  {t("Show Full Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showUpdateSyncs"
                  id="showUpdateSyncs"
                  checked={globalEvents.type.includes(2) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 2, "syncType")}
                />
                <label htmlFor="showUpdateSyncs" className="font__normal">
                  {" "}
                  {t("Show Update Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showDeleteSyncs"
                  id="showDeleteSyncs"
                  checked={globalEvents.type.includes(3) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 3, "syncType")}
                />
                <label htmlFor="showDeleteSyncs" className="font__normal">
                  {" "}
                  {t("Show Delete Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showPriceSyncs"
                  id="showPriceSyncs"
                  checked={globalEvents.type.includes(4) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 4, "syncType")}
                />
                <label htmlFor="showPriceSyncs" className="font__normal">
                  {" "}
                  {t("Show Price Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showForcedSyncs"
                  id="showForcedSyncs"
                  checked={globalEvents.type.includes(5) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 5, "syncType")}
                />
                <label htmlFor="showForcedSyncs" className="font__normal">
                  {" "}
                  {t("Show Forced Syncs")}{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="input__block">
              <label> {t("Sync Status")} </label>
            </div>
            <div className="row">
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showSuccessSyncs"
                  id="showSuccessSyncs"
                  checked={globalEvents.status.includes(200) ? true : false}
                  onChange={(e) =>
                    handleOnCheck(e, "select", 200, "syncStatus")
                  }
                />
                <label htmlFor="showSuccessSyncs" className="font__normal">
                  {" "}
                  {t("Show Success Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showFailedSyncs"
                  id="showFailedSyncs"
                  checked={globalEvents.status.includes(500) ? true : false}
                  onChange={(e) =>
                    handleOnCheck(e, "select", 500, "syncStatus")
                  }
                />
                <label htmlFor="showFailedSyncs" className="font__normal">
                  {" "}
                  {t("Show Failed Syncs")}{" "}
                </label>
              </div>
              <div className="radio__block col-md-3">
                <input
                  type="checkbox"
                  name="showPendingSyncs"
                  id="showPendingSyncs"
                  checked={globalEvents.status.includes(1) ? true : false}
                  onChange={(e) => handleOnCheck(e, "select", 1, "syncStatus")}
                />
                <label htmlFor="showPendingSyncs" className="font__normal">
                  {" "}
                  {t("Show Pending Syncs")}{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <div className="button__block mb-4">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-4 rules__div">
                    <span className="rules__txt">
                      {" "}
                      {EventsCount > TotalEventsCount ? (
                        <span className="rules__txtno">
                          {TotalEventsCount} / {TotalEventsCount}
                        </span>
                      ) : (
                        <span className="rules__txtno">
                          {EventsCount} / {TotalEventsCount}
                        </span>
                      )}{" "}
                      {t("Rules")}
                    </span>
                  </div>
                  <div className="col-lg-8 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleDownloadDataCSV_Synclist()}
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {t("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={key}
              className="pendjewelreq__tbl"
              loading={loading}
              columns={[
                {
                  title: t("Dealer ID"),
                  dataIndex: "dealerId",
                  sorter: (a, b) => {},
                  width: 100
                },
                {
                  title: t("Dealer Company"),
                  dataIndex: "dealerCompany",
                  sorter: (a, b) => {}
                },
                {
                  title: t("Type"),
                  dataIndex: "type",
                  sorter: (a, b) => {},
                  width: 100
                },
                {
                  title: t("Status"),
                  dataIndex: "status",
                  sorter: (a, b) => {},
                  width: 100
                },
                {
                  title: t("Initiated"),
                  dataIndex: "initiated",
                  sorter: (a, b) => {}
                },
                {
                  title: t("Complete"),
                  sorter: (a, b) => {},
                  dataIndex: "complete"
                }
              ]}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true
              }}
              dataSource={SyncsGridDataList}
              onChange={handleTableASCDES}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Syncs;
