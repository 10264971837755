import React, { useState, useEffect, useRef } from "react";
import { Select, Checkbox, Button, Tooltip, Modal, Table, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Create_icon from "../../assets/images/create_icon.png";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import AdminManageCurrencyService from "../../services/admin-manageCurrency.service";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { setCurrency, setCurrencySign } from "../../actions/login/login";

const { Option } = Select;
const ProductMarkup = () => {
  const { t } = useTranslation();
  const refMarkUpDataList = useRef();
  const initialState = {
    Currency: null,
    Rounding: null,
    DefaultMarkup: null,
    MinPrice: null,
    MaxPrice: null,
    MarkUP: null,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [CategotyList, setCategotyList] = useState([]);
  const [RoundingList, setRoundingList] = useState([]);
  const [DealerId, setDealerId] = useState(null);
  const [MarkUpDataList, setMarkUpDataList] = useState([]);
  const [MarkUpPriceRangeList, setMarkUpPriceRangeList] = useState([]);
  const [OverrideDataList, setOverrideDataList] = useState([]);
  const [Defaultloading, setDefaultloading] = useState(false);
  const [Overrideloading, setOverrideloading] = useState(false);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [selectedTab, setSelectedTab] = useState("general");
  const [delOverrideData, setDelOverrideData] = useState({
    recordId: "",
    overRideValue: "",
  });
  const [visible3, setVisible3] = useState(false);
  const [CustomMarkupID, setCustomMarkupID] = useState(null);
  const [LastID, setLastID] = useState(null);
  const [IsDelete, setIsDelete] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [selectCurrencyDropdowenList, setSelectCurrencyDropdowenList] =
    useState([]);
  const [savePricingVisible, setSavePricingVisible] = useState(true);
  const [addValid, setAddValid] = useState(1);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [mainCheckVal, setMainCheckVal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [delRow, setDelRow] = useState({});
  const [editRow, setEditRow] = useState({});
  const [customMarkupId, setCustomMarkupId] = useState({
    custommarkupid: "",
  });
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol =
    currency && currency.substring(currency.lastIndexOf("-") + 1);
  const dispatch = useDispatch();
  // const style = <span> {t("content not provided.")} </span>;

  useEffect(() => {
    handleGetSelectCurrencyDropdowen();
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
    setCustomMarkupId({
      custommarkupid: "",
    });
  };

  const handleGetSelectCurrencyDropdowen = () => {
    setDefaultloading(true);
    try {
      AdminManageCurrencyService.GetSelectCurrencyDropdowen()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setSelectCurrencyDropdowenList(responseData);
              handleGetRoundingList();
            } else {
              setSelectCurrencyDropdowenList([]);
            }
          } else {
            setSelectCurrencyDropdowenList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleValidation = () => {
    var isValid = true;
    if (!state.Currency) {
      state.Error["Currency"] = t("Please select currency.");
      isValid = false;
    }
    if (!state.Rounding) {
      state.Error["Rounding"] = t("Please select rounding.");
      isValid = false;
    }
    if (!state.DefaultMarkup) {
      state.Error["DefaultMarkup"] = t("Please enter default markup.");
      isValid = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const maxLengthCheck = () => {
    var isValid = true;
    if (state.MarkUP > 100) {
      state.Error["MarkUP"] = t(
        "Please enter default markup value less then 100%."
      );
      isValid = false;
    } else {
      state.Error["MarkUP"] = "";
    }
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    return isValid;
  };
  const handleOnClickRow = (record, recordIndex) => {
    if (IsDelete) {
      setCustomMarkupID(null);
      setIsDelete(false);
    } else {
      setCustomMarkupID(record.customMarkupID);
    }

    state.MinPrice = record.minRange;
    state.MaxPrice = record.maxRange;
    state.MarkUP = record.markup;

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  // const handleAddMarkUpPrice = () => {
  //   //
  //   // var isValid = handleValidateMarkup()
  //   // if(!isValid){
  //   //   return false
  //   // }
  //   //
  //   if (isEdit) {
  //
  //     if (MarkUpDataList.length > 0) {
  //       const temp = MarkUpDataList.map((x) => {
  //         if (x.customMarkupID === editRow.customMarkupID) {
  //           return {
  //             ...x,
  //             minRange: Number(state.MinPrice).toFixed(2),
  //             maxRange: Number(state.MaxPrice).toFixed(2),
  //             markup: Number(state.MarkUP).toFixed(2),
  //           };
  //         } else return x;
  //       });
  //       setMarkUpDataList(temp);
  //     } else {
  //       var tempState = [];
  //       var custID =
  //         MarkUpDataList.length == null ? 1 : MarkUpDataList.length - 1;

  //       let inputData = {
  //         customMarkupID: custID,
  //         dealerID: Number(DealerId),
  //         minRange: Number(state.MinPrice).toFixed(2),
  //         maxRange: Number(state.MaxPrice).toFixed(2),
  //         markup: Number(state.MarkUP).toFixed(2),
  //       };
  //       tempState.push(inputData);
  //       var arr1 = MarkUpDataList || [];
  //       var arr2 = tempState;
  //       var arr3 = [...arr1, ...arr2];
  //       setMarkUpDataList(arr3);
  //     }
  //   } else {
  //     var tempState = [];
  //     var custID =
  //       MarkUpDataList.length == null ? 1 : MarkUpDataList.length - 1;

  //     let inputData = {
  //       customMarkupID: custID,
  //       dealerID: Number(DealerId),
  //       minRange: Number(state.MinPrice).toFixed(2),
  //       maxRange: Number(state.MaxPrice).toFixed(2),
  //       markup: Number(state.MarkUP).toFixed(2),
  //     };
  //     tempState.push(inputData);
  //     var arr1 = MarkUpDataList || [];
  //     var arr2 = tempState;
  //     var arr3 = [...arr1, ...arr2];
  //     setMarkUpDataList(arr3);
  //   }
  //   setIsEdit(false);
  //   setEditRow({});
  // };

  const handleAddMarkUpPrice = () => {
    if (!addValid == 1) {
      return false;
    }
    if (handleValidateMarkup()) handleSaveRingBuilderSettingMarkup();
  };

  const handleRemoveRingBuilderSettingMarkup = (row) => {
    setSavePricingVisible(false);
    let tempArray = MarkUpDataList.filter(
      (x) => x.customMarkupID != row.customMarkupID
    );
    setMarkUpDataList(tempArray);
    setDeleteModalVisible(false);
    setIsEdit(false);
    setEditRow({});

    if (MarkUpDataList.length > 0) {
      var data = MarkUpDataList[MarkUpDataList.length - 1];
      state.MinPrice = parseFloat(data.maxRange);
      state.MaxPrice = parseFloat(1000000.0);
      state.MarkUP = "";
    } else {
      state.MinPrice = parseFloat(0.0);
      state.MaxPrice = parseFloat(1000000.0);
      state.MarkUP = null;
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleSaveRingBuilderSettingMarkup = () => {
    setSavePricingVisible(false);
    if (isEdit) {
      if (MarkUpDataList.length > 0) {
        const temp = MarkUpDataList.map((x) => {
          if (x.customMarkupID === editRow.customMarkupID) {
            return {
              ...x,
              minRange: Number(state.MinPrice).toFixed(2),
              maxRange: Number(state.MaxPrice).toFixed(2),
              markup: Number(state.MarkUP).toFixed(2),
            };
          } else return x;
        });
        setMarkUpDataList(temp);
      } else {
        var tempState = [];
        var custID = Date.now();
        let inputData = {
          customMarkupID: custID,
          dealerID: Number(DealerId),
          minRange: Number(state.MinPrice).toFixed(2),
          maxRange: Number(state.MaxPrice).toFixed(2),
          markup: Number(state.MarkUP).toFixed(2),
        };
        tempState.push(inputData);
        var arr1 = MarkUpDataList || [];
        var arr2 = tempState;
        var arr3 = [...arr1, ...arr2];
        setMarkUpDataList(arr3);
      }
    } else {
      var tempState = [];
      var custID = Date.now();
      let inputData = {
        customMarkupID: custID,
        dealerID: Number(DealerId),
        minRange: Number(state.MinPrice).toFixed(2),
        maxRange: Number(state.MaxPrice).toFixed(2),
        markup: Number(state.MarkUP).toFixed(2),
      };
      tempState.push(inputData);
      var arr1 = MarkUpDataList || [];
      var arr2 = tempState;
      var arr3 = [...arr1, ...arr2];
      setMarkUpDataList(arr3);
    }
    // var data = arr3[arr3.length - 1];
    // state.MinPrice = data.maxRange.toFixed(2);
    // const num1 = 1000000;
    // state.MaxPrice = num1.toFixed(2);
    // state.MarkUP = "";
    var data = arr3[arr3.length - 1];
    var num = Number(data.maxRange);
    var newNum = num + 0.01;
    state.MinPrice = newNum.toFixed(2);
    const num1 = 1000000;
    state.MaxPrice = num1.toFixed(2);
    state.MarkUP = "";
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    setIsEdit(false);
    setEditRow({});
  };

  const handleDelete = (row) => {
    setCustomMarkupID(null);
    setIsDelete(true);
    let tempArray = MarkUpDataList.filter(
      (x) => x.customMarkupID != row.customMarkupID
    );
    setMarkUpDataList(tempArray);
  };

  const handleDeleteModal = (row) => {
    setDelOverrideData({
      recordId: row.id,
      overRideValue: row.overrideType,
    });
    setVisible3(true);
  };

  const handleDeleteModal1 = (row) => {
    setDeleteModalVisible(true);
    setDelRow(row);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const handleOnEditChange = (row, e, name) => {
    setSavePricingVisible(false);
    if (name === "enabled") {
      const temp = OverrideDataList.map((x) => {
        if (x.id === row.id) {
          return { ...x, enabled: e.target.checked };
        } else return x;
      });
      setOverrideDataList(temp);
    }
  };

  const OnSaveButton = () => {
    if (selectedTab == "general") {
      //var data = JSON.stringify(MarkUpDataList);
      handleSaveMarkUpDataList(MarkUpDataList);
    }
    if (selectedTab == "overrides") {
      var data = JSON.stringify(OverrideDataList);
      handleUpdateOverrideValue(data);
    }
  };

  const handleTabsChange = (e) => {
    setSelectedTab(e);
    // if (e === "general") {
    //   handleGetMarkUpDataList(DealerId, pageNo, pageSize);
    // }
    if (e === "overrides") {
      handleGetOverRideDataList(DealerId);
    }
  };

  const handleValidateMarkup = () => {
    var tempState = state;
    var isValid = true;
    // if (!tempState.MinPrice) {
    //   isValid = false;
    //   tempState.Error["MinPrice"] = t("Please Enter Min Price.");
    // } else {
    //   isValid = true;
    //   tempState.Error["MinPrice"] = "";
    // }
    if (!tempState.MaxPrice) {
      isValid = false;
      tempState.Error["MaxPrice"] = t("Please Enter Max Price.");
    } else if (!/^[0-9]*\.[0-9][0-9]$/.test(tempState.MaxPrice)) {
      isValid = false;
      tempState.Error["MaxPrice"] =
        "Please enter Max price up to two decimal place";
    } else {
      isValid = true;
      tempState.Error["MaxPrice"] = "";
    }
    if (!tempState.MarkUP) {
      isValid = false;
      tempState.Error["MarkUP"] = t("Please Enter Markup.");
    } else if (state.MarkUP > 100) {
      tempState.Error["MarkUP"] = t(
        "Please enter default markup value less then 100%."
      );
      isValid = false;
    } else {
      tempState.Error["MarkUP"] = "";
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    return isValid;
  };

  const onChange = (e, name) => {
    setSavePricingVisible(false);
    let tempState = state;
    tempState[name] = e.target.value;
    if (name === "DefaultMarkup") {
      tempState.Error["DefaultMarkup"] = "";
    }
    if (name === "MinPrice") {
      tempState.Error["MinPrice"] = "";
    }
    if (name === "MaxPrice") {
      tempState.Error["MaxPrice"] = "";
    }
    if (name === "MarkUP") {
      tempState.Error["MarkUP"] = "";
    }

    if (name === "MinPrice" || name === "MaxPrice" || name === "MarkUP") {
      var cv = Math.round(parseFloat(tempState.MaxPrice) * 100) / 100;
      var av = Math.round(parseFloat(tempState.MinPrice) * 100) / 100;

      if ((name = "MinPrice" || name == "MaxPrice")) {
        if (cv < av) {
          tempState.Error["MaxPrice"] =
            "MaxPrice Should be greater than MinPrice";
          setAddValid(0);
          setIsAddDisabled(true);
        } else {
          tempState.Error["MaxPrice"] = "";
          setAddValid(1);
          setIsAddDisabled(false);
        }
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChangeDrop = (e, name) => {
    setSavePricingVisible(false);
    let tempState = state;
    tempState[name] = e;

    if (name === "Currency") {
      tempState.Error["Currency"] = "";
    }
    if (name === "Rounding") {
      tempState.Error["Rounding"] = "";
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleGetCurrencyList = () => {
    try {
      CatalogJewelryDataService.GetCurrencyList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            //var data =JSON.parse(responseData);
            setCategotyList(responseData);
            handleGetRoundingList();
          } else {
            setCategotyList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRoundingList = () => {
    try {
      CatalogJewelryDataService.GetRoundingList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setRoundingList(responseData);
            handleGetMarkUpDataList(
              loginDetials.responseData.dealerId,
              pageNo,
              pageSize
            );
          } else {
            setRoundingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDefaultDataMarkupForDealerList = (id) => {
    let inputData = {
      dealerId: id || DealerId,
    };
    try {
      CatalogJewelryDataService.GetDefaultDataMarkupForDealerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            state.DefaultMarkup = responseData;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMarkUpDataList = async (id, pageNo, pageSize) => {
    setDefaultloading(true);
    let inputData = {
      dealerId: id,
      pageNumber: pageNo,
      pageSize: pageSize,
    };
    try {
      await CatalogJewelryDataService.GetMarkUpDataList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var List = responseData.markUpValLst;
          if (message === "Success") {
            dispatch(setCurrencySign(responseData?.currencysign || ""));
            state.Currency = parseInt(responseData.currency) || null;
            state.Rounding = parseInt(responseData.roundingoption) || null;
            state.DefaultMarkup = responseData.defaultMarkUp || null;
            if (List && List.length > 0) {
              var data = List.slice(-1);
              var MaxRangeVal = Number(data[0].maxRange);
              var newNum = MaxRangeVal + 0.01;
              state.MinPrice = newNum.toFixed(2);
              state.MaxPrice = Number("1000000").toFixed(2);
              state.MarkUP = "";
            } else {
              var num1 = 0;
              var num2 = 1000000;
              state.MinPrice = num1.toFixed(2);
              state.MaxPrice = num2.toFixed(2);
              state.MarkUP = null;
            }
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setMarkUpDataList(responseData.markUpValLst);
            setTotalCount(responseData.totalRecord);
            setDefaultloading(false);
          } else {
            state.Currency = null;
            state.Rounding = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setDefaultloading(false);
            setMarkUpDataList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          state.Currency = null;
          state.Rounding = null;
          setState((prevState) => ({
            ...prevState,
            ...state,
          }));
          setDefaultloading(false);
          console.log(error);
        });
    } catch (error) {
      state.Currency = null;
      state.Rounding = null;
      setState((prevState) => ({
        ...prevState,
        ...state,
      }));
      setDefaultloading(false);
      console.log(error);
    }
  };

  const handleGetOverRideDataList = (id) => {
    setOverrideloading(true);
    let inputData = {
      dealerId: id || DealerId,
    };
    try {
      CatalogJewelryDataService.GetOverRideDataList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message === "Success") {
            setOverrideDataList(responseData);

            const testBoolean = responseData.every((m) => {
              if (m.enabled == 0) {
                return false;
              }
              return true;
            });
            setMainCheckVal(testBoolean);
          } else {
            setOverrideDataList([]);
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
  };

  const handleUpdateOverrideValue = (inputData) => {
    setOverrideloading(true);
    try {
      CatalogJewelryDataService.UpdateOverrideValue(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message === "Success") {
            NotificationManager.success(t("Markup Updated Successfully"));
            handleGetOverRideDataList();
            setSavePricingVisible(true);
          } else {
            NotificationManager.error(t("Markup Not Updated."));
            setSavePricingVisible(true);
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
  };

  const handleDeleteOverrideValue = () => {
    let inputData = [
      {
        recordId: delOverrideData.recordId,
        overRideValue: delOverrideData.overRideValue,
      },
    ];
    setOverrideloading(true);
    try {
      CatalogJewelryDataService.DeleteOverrideValue(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message === "Success") {
            NotificationManager.success(t("Override Deleted Successfully."));
            handleGetOverRideDataList();
          } else {
            NotificationManager.error(t("Override Not Deleted."));
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
    setVisible3(false);
    setDelOverrideData({});
  };

  const handleSaveMarkUpDataList = (JsonData) => {
    if (handleValidation()) {
      let inputData = {
        dealerId: loginDetials.responseData.dealerId,
        defaultMarkUp: state.DefaultMarkup + "",
        currency: state.Currency + "",
        markupDataList: JsonData ? JsonData : [],
        roundingoption: state.Rounding.toString(),
      };
      setSubmitLoading1(true);
      try {
        CatalogJewelryDataService.SaveMarkUpDataList(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            setSubmitLoading1(false);
            if (message === "Success") {
              NotificationManager.success(t("Markup Updated Successfully."));
              const currencyType = selectCurrencyDropdowenList.filter(
                (item) => item.currencyId == state.Currency
              )?.[0]?.currencyType;
              dispatch(setCurrency(currencyType));
              handleGetMarkUpDataList(DealerId, pageNo, pageSize);
              state.Error["DefaultMarkup"] = "";
              setSavePricingVisible(true);
            } else {
              NotificationManager.error(t("Markup Not Updated."));
              setSavePricingVisible(true);
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        console.log(error);
      }
    }
  };
  const data1 = [
    {
      markup: <span>100%</span>,
      cost: <span>{currencySymbol} 1,000</span>,
      retail: <span>{currencySymbol} 2,000</span>,
      mrk: <span>(Key)</span>,
    },
    {
      markup: <span>120%</span>,
      cost: <span>{currencySymbol} 1,000</span>,
      retail: <span>{currencySymbol} 2,200</span>,
      mrk: <span>(2.2)</span>,
    },
    {
      markup: <span>150%</span>,
      cost: <span>{currencySymbol} 1,000</span>,
      retail: <span>{currencySymbol} 2,500</span>,
      mrk: <span>(2.5)</span>,
    },
    {
      markup: <span>80%</span>,
      cost: <span>{currencySymbol} 1,000</span>,
      retail: <span>{currencySymbol} 1,800</span>,
      mrk: <span>(1.8)</span>,
    },
  ];

  const onKeyPressEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) e.preventDefault();
    return;
  };

  const handleOnChange = (row, e, name) => {
    setSavePricingVisible(false);
    if (name === "enabled") {
      const temp = OverrideDataList.map((x) => {
        if (x.id == row.id) {
          return {
            ...x,
            enabled: e.target.checked == true ? true : false,
          };
        } else return x;
      });

      setOverrideDataList(temp);

      const testBoolean = temp.every((m) => {
        if (m.enabled == 0) {
          return false;
        }
        return true;
      });
      setMainCheckVal(testBoolean);
    }
  };

  const handleOnCheckGridAll = (e) => {
    setSavePricingVisible(false);
    setMainCheckVal(e.target.checked);
    var tempcommVendorData = OverrideDataList;
    tempcommVendorData.forEach((element) => {
      element.enabled = e.target.checked == true ? true : false;
    });
    setOverrideDataList([...tempcommVendorData]);
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion prodmark__block magento__mapping">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading__block mb-3">
                    <h4 className="workarea__heading mt-0 mb-0">
                      {" "}
                      {t("Jewelry Markup")}{" "}
                    </h4>
                    {/* <Button
                      disabled={savePricingVisible}
                      className={
                        savePricingVisible == true
                          ? "disabled-btn"
                          : "primary-btn"
                      }
                      onClick={(e) => OnSaveButton()}
                      loading={submitLoading1}
                    >
                      {t("Save Pricing")}
                    </Button> */}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      // defaultActiveKey="general"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-4"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="general" title={t("General")}>
                        <Spin spinning={Defaultloading}>
                          <div className="tab__contentdiv">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="head__div">
                                      <h4> {t("General Settings")} </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="col-lg-12 pt-3 pb-3">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div className="input__block mb-0">
                                              <label>
                                                {t("Currency")}
                                                <Tooltip
                                                  placement="right"
                                                  title=""
                                                >
                                                  <img src={UnionIcon} alt="" />
                                                </Tooltip>{" "}
                                                <span>*</span>
                                              </label>
                                              <Select
                                                showSearch
                                                className="border__grey"
                                                placeholder={t(
                                                  "Select Currency"
                                                )}
                                                optionFilterProp="children"
                                                value={state.Currency}
                                                onChange={(e) =>
                                                  onChangeDrop(e, "Currency")
                                                }
                                              >
                                                <Option value="">
                                                  {" "}
                                                  {t("Select Currency")}{" "}
                                                </Option>
                                                {/* {CategotyList.map((item, i) => {
                                                return (
                                                  <Option
                                                    value={item.currencyId}
                                                  >
                                                    {item.currencyText}
                                                  </Option>
                                                );
                                              })} */}
                                                {selectCurrencyDropdowenList.map(
                                                  (item) => {
                                                    return (
                                                      <React.Fragment>
                                                        <Option
                                                          value={
                                                            item.currencyId
                                                          }
                                                        >
                                                          {item.currencyType}
                                                        </Option>
                                                      </React.Fragment>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                              {state.Error ? (
                                                <div className="text-danger">
                                                  {state.Error["Currency"]}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="input__block mb-0">
                                              <label>
                                                {t("Rounding")}
                                                <Tooltip
                                                  placement="right"
                                                  title=""
                                                >
                                                  <img src={UnionIcon} alt="" />
                                                </Tooltip>{" "}
                                                <span>*</span>
                                              </label>
                                              <Select
                                                showSearch
                                                className="border__grey"
                                                placeholder={t(
                                                  "Select Rounding"
                                                )}
                                                optionFilterProp="children"
                                                value={state.Rounding}
                                                onChange={(e) =>
                                                  onChangeDrop(e, "Rounding")
                                                }
                                              >
                                                <Option value="">
                                                  {" "}
                                                  {t("Select Rounding")}{" "}
                                                </Option>
                                                {RoundingList.map((item, i) => {
                                                  return (
                                                    <Option
                                                      value={item.roundingId}
                                                    >
                                                      {item.roundingText}
                                                    </Option>
                                                  );
                                                })}
                                              </Select>
                                              {state.Error ? (
                                                <div className="text-danger">
                                                  {state.Error["Rounding"]}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="head__div">
                                      <h4> {t("Default Markup")} </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="col-lg-12 pt-3 pb-3">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div className="input__block">
                                              <label>
                                                {t("Default Markup")}

                                                <span>*</span>
                                              </label>
                                              <div className="mrkup__inptdiv">
                                                <input
                                                  type="number"
                                                  min="0"
                                                  max="100"
                                                  onKeyPress={onKeyPressEvent}
                                                  placeholder={t(
                                                    "Default Markup"
                                                  )}
                                                  className="dmrkup__inpt"
                                                  value={state.DefaultMarkup}
                                                  onChange={(e) => {
                                                    onChange(
                                                      e,
                                                      "DefaultMarkup"
                                                    );
                                                  }}
                                                />
                                                <span className="ampcent__txt ampcent__right">
                                                  %
                                                </span>
                                                {state.Error ? (
                                                  <div className="text-danger">
                                                    {
                                                      state.Error[
                                                        "DefaultMarkup"
                                                      ]
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-12">
                                            <div className="table__scroll">
                                              <Table
                                                const
                                                columns={[
                                                  {
                                                    title: t("Markup"),
                                                    dataIndex: "markup",
                                                    key: "markup",
                                                    fixed: "left",
                                                    width: 100,
                                                  },
                                                  {
                                                    title: t("Cost"),
                                                    dataIndex: "cost",
                                                    key: "cost",
                                                    width: 100,
                                                  },
                                                  {
                                                    title: t("Retail"),
                                                    dataIndex: "retail",
                                                    key: "retail",
                                                    width: 100,
                                                  },
                                                  {
                                                    title: t("Mrk"),
                                                    dataIndex: "mrk",
                                                    key: "mrk",
                                                    width: 100,
                                                  },
                                                ]}
                                                dataSource={data1}
                                                pagination={false}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="head__div">
                                      <h4> {t("Markup By Price Range")} </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="col-lg-12 pt-3 pb-3">
                                        <div className="row">
                                          <div className="col-lg-3">
                                            <div className="input__block">
                                              <div className="mrkup__inptdiv currency__block">
                                                <span className="dollr__txt">
                                                  {currencySymbol}
                                                </span>
                                                <input
                                                  type="text"
                                                  min="0"
                                                  max="100"
                                                  onKeyPress={onKeyPressEvent}
                                                  placeholder={t("Min Price")}
                                                  //disabled
                                                  value={state.MinPrice}
                                                  onChange={(e) =>
                                                    onChange(e, "MinPrice")
                                                  }
                                                  className="disabled mrkup__rangeinpt"
                                                  disabled={true}
                                                />
                                                {state.Error["MinPrice"] ? (
                                                  <span className="error-color-red">
                                                    {state.Error["MinPrice"]}
                                                  </span>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="input__block">
                                              <div className="mrkup__inptdiv currency__block">
                                                <span className="dollr__txt">
                                                  {currencySymbol}
                                                </span>
                                                <input
                                                  type="text"
                                                  min="0"
                                                  max="100"
                                                  onKeyPress={onKeyPressEvent}
                                                  placeholder={t("Max Price")}
                                                  className={
                                                    MarkUpDataList
                                                      ? MarkUpDataList.filter(
                                                          (x) =>
                                                            x.maxRange >=
                                                            1000000.0
                                                        ).length == 0
                                                        ? "mrkup__rangeinpt"
                                                        : "disabled mrkup__rangeinpt"
                                                      : "mrkup__rangeinpt"
                                                  }
                                                  value={state.MaxPrice}
                                                  onChange={(e) =>
                                                    onChange(e, "MaxPrice")
                                                  }
                                                  disabled={
                                                    MarkUpDataList
                                                      ? MarkUpDataList.filter(
                                                          (x) =>
                                                            x.maxRange >=
                                                            1000000.0
                                                        ).length == 0
                                                        ? false
                                                        : true
                                                      : false
                                                  }
                                                />
                                                {state.Error["MaxPrice"] ? (
                                                  <span className="error-color-red">
                                                    {state.Error["MaxPrice"]}
                                                  </span>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="input__block">
                                              <div className="mrkup__inptdiv">
                                                <input
                                                  type="text"
                                                  min="0"
                                                  max="100"
                                                  onKeyPress={onKeyPressEvent}
                                                  placeholder={t("MarkUP")}
                                                  className={
                                                    MarkUpDataList
                                                      ? MarkUpDataList.filter(
                                                          (x) =>
                                                            x.maxRange >=
                                                            1000000.0
                                                        ).length == 0
                                                        ? "dmrkup__inpt"
                                                        : "disabled dmrkup__inpt"
                                                      : "dmrkup__inpt"
                                                  }
                                                  value={state.MarkUP}
                                                  onChange={(e) =>
                                                    onChange(e, "MarkUP")
                                                  }
                                                  disabled={
                                                    MarkUpDataList
                                                      ? MarkUpDataList.filter(
                                                          (x) =>
                                                            x.maxRange >=
                                                            1000000.0
                                                        ).length == 0
                                                        ? false
                                                        : true
                                                      : false
                                                  }
                                                />
                                                <span className="ampcent__txt">
                                                  %
                                                </span>
                                                {state.Error ? (
                                                  <div className="text-danger">
                                                    {state.Error["MarkUP"]}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <Button
                                              disabled={isAddDisabled}
                                              className="primary-btn"
                                              onClick={(e) =>
                                                handleAddMarkUpPrice()
                                              }
                                            >
                                              {" "}
                                              {/* <img src={Create_icon} alt="" /> */}
                                              {t("Add")}
                                            </Button>
                                          </div>
                                          <div className="col-lg-12">
                                            <div className="table__scroll mobile__topspace">
                                              <Table
                                                columns={[
                                                  {
                                                    title: t("Min Price"),
                                                    dataIndex: "minRange",
                                                    width: 100,
                                                    render: (row, item) => {
                                                      return (
                                                        <span>
                                                          {" "}
                                                          {currencySymbol}{" "}
                                                          {item.minRange}{" "}
                                                        </span>
                                                      );
                                                    },
                                                  },
                                                  {
                                                    title: t("Max Price"),
                                                    dataIndex: "maxRange",
                                                    width: 100,
                                                    render: (row, item) => {
                                                      return (
                                                        <span>
                                                          {" "}
                                                          {currencySymbol}{" "}
                                                          {item.maxRange}{" "}
                                                        </span>
                                                      );
                                                    },
                                                  },
                                                  {
                                                    title: t("Mark Up"),
                                                    dataIndex: "markup",
                                                    width: 100,
                                                    render: (row, item) => {
                                                      return (
                                                        <span>
                                                          {" "}
                                                          {item.markup} %{" "}
                                                        </span>
                                                      );
                                                    },
                                                  },
                                                  {
                                                    title: t("Action"),
                                                    width: 100,
                                                    render: (item, row) => {
                                                      return (
                                                        <div className="action__btns">
                                                          <div className="image__block">
                                                            <img
                                                              src={
                                                                Delete_iconbl
                                                              }
                                                              alt=""
                                                              onClick={(e) =>
                                                                handleDeleteModal1(
                                                                  row
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      );
                                                    },
                                                  },
                                                ]}
                                                onRow={(
                                                  record,
                                                  recordIndex
                                                ) => ({
                                                  onClick: (event) => {
                                                    setIsEdit(true);
                                                    setEditRow(record);
                                                    setState({
                                                      ...state,
                                                      MinPrice: record.minRange,
                                                      MaxPrice: record.maxRange,
                                                      MarkUP: record.markup,
                                                    });
                                                  },
                                                })}
                                                onChange={(
                                                  pagination,
                                                  filters,
                                                  sorters
                                                ) => {
                                                  document
                                                    .getElementsByClassName(
                                                      "content__area"
                                                    )[0]
                                                    .scroll(0, 0);
                                                }}
                                                pagination={{
                                                  showSizeChanger: true,
                                                  responsive: true,
                                                }}
                                                dataSource={MarkUpDataList}
                                                scroll={{ y: 800 }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Spin>
                      </Tab>
                      <Tab eventKey="overrides" title={t("Overrides")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 pb-3">
                              <Table
                                const
                                columns={[
                                  {
                                    title: (
                                      <div className="filtbtn__div filter__eye">
                                        <label className="table__label"></label>
                                        <span className="select__all">
                                          <input
                                            type="checkbox"
                                            id="sel"
                                            onChange={(e) =>
                                              handleOnCheckGridAll(e)
                                            }
                                            checked={mainCheckVal}
                                          />
                                        </span>
                                      </div>
                                    ),
                                    dataIndex: "enabled",
                                    width: 30,
                                    render: (item, row) => {
                                      return (
                                        <div className="action__btns">
                                          <input
                                            type="checkbox"
                                            name="enabled"
                                            checkedChildren={t("Yes")}
                                            unCheckedChildren={t("No")}
                                            checked={
                                              row.enabled == 1 ? true : false
                                            }
                                            onChange={(e) =>
                                              handleOnChange(row, e, "enabled")
                                            }
                                          />
                                        </div>
                                      );
                                    },
                                  },

                                  {
                                    title: t("Name"),
                                    dataIndex: "overrideName",
                                    key: "overrideName",
                                  },
                                  {
                                    title: t("Type"),
                                    dataIndex: "overrideType",
                                    key: "overrideType",
                                    width: 100,
                                  },
                                  {
                                    title: t("Override"),
                                    dataIndex: "override",
                                    key: "override",
                                    width: 100,
                                    render: (row, item) => {
                                      return (
                                        <span>
                                          {item.enabled
                                            ? `${item.override} %`
                                            : "Disabled"}
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    title: t("Updated"),
                                    dataIndex: "updated",
                                    key: "updated",
                                    width: 170,
                                  },
                                  {
                                    title: t("Action"),
                                    dataIndex: "action",
                                    key: "action",
                                    width: 100,
                                    render: (item, row) => {
                                      return (
                                        <div className="action__btns">
                                          <div className="image__block">
                                            <img
                                              src={Delete_iconbl}
                                              alt=""
                                              onClick={() =>
                                                handleDeleteModal(row)
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    },
                                  },
                                ]}
                                // rowKey={(record) => record.id}
                                // rowSelection={rowSelection}
                                loading={Overrideloading}
                                dataSource={OverrideDataList}
                                scroll={{ x: 600, y: 800 }}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    <div class="col-md-6 mt-3">
                      <Button
                        disabled={savePricingVisible}
                        className={
                          savePricingVisible == true
                            ? "disabled-btn"
                            : "primary-btn"
                        }
                        onClick={(e) => OnSaveButton()}
                        loading={submitLoading1}
                      >
                        {t("Save Pricing")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                title={t("Delete")}
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel3}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleDeleteOverrideValue}
                  >
                    {t("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>
                              {t(
                                "Are you sure you want to remove this override?"
                              )}
                              {/* {delOverrideData.recordId}"{" "} */}
                              {/* {t("Dealer Data")} */}
                            </p>
                            {/* <h5> {t("THIS CAN NOT BE UNDONE")} </h5> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              {/* Delete Modal */}

              <Modal
                title=""
                className="modalconsupld__section"
                centered
                visible={deleteModalVisible}
                // onOk={() => setVisible(false)}
                onCancel={handleCancelDeleteModal}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancelDeleteModal}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={DeleteDealerMarkupData}
                  >
                    {t("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 mt-4 text-center">
                            <p>
                              {t(
                                "Are You Sure You Want To Delete This Markup ?"
                              )}
                            </p>
                            <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              {/* End */}

              <Modal
                title={t("Delete")}
                className="modalconsupld__section"
                centered
                visible={deleteModalVisible}
                onOk={() => handleRemoveRingBuilderSettingMarkup(delRow)}
                onCancel={handleCancelDeleteModal}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancelDeleteModal}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleRemoveRingBuilderSettingMarkup(delRow)}
                  >
                    {t("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p>{t("Are you sure you want to delete")}</p>
                            <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductMarkup;
