import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Customer from "../contacts/customer";
import Conversations from "../contacts/conversation";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import Appointment from "../dashboard/Appointment";
import {
  setContactConversationCount,
  setContactAppointmentCount,
  setContactCustomerCount,
  setContactPageNameType,
} from "../../actions/Contact/Contact";
import { Spin } from "antd";

export const Contacts = () => {
  const { t } = useTranslation();
  const [pageNameType, setpageNameType] = useState("Customer");
  const [dealerId, setDealerId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [conservationCount, setConservationCount] = useState(0);
  const [appointmentcount, setAppointmentCount] = useState(0);
  const [locationCount, setLocationCount] = useState(0);
  const ContactDetails = useSelector((state) => state.contactReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const [locationKey, setLocationKey] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const tabname = searchParams.get("tabname");

  const handleSendPageType = (type) => {
    dispatch(setContactPageNameType(type));
  };
  useEffect(() => {
    if (
      typeof tabname === "string" &&
      tabname.toLowerCase().includes("conversation")
    ) {
      handleSendPageType("Conversation");
    } else if (
      typeof tabname === "string" &&
      tabname.toLowerCase().includes("customer")
    ) {
      handleSendPageType("Customer");
    } else if (
      typeof tabname === "string" &&
      tabname.toLowerCase().includes("appointment")
    ) {
      handleSendPageType("AppointmentsOnly");
    }
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setCompanyName(loginDetials.loginDetials.responseData.dealerCompany);
          if (ContactDetails) {
            setConservationCount(ContactDetails.contactConversationCount);
            setAppointmentCount(ContactDetails.contactAppointmentCount);
            handleGetCustomerCount(
              loginDetials.loginDetials.responseData.dealerId
            );
          }
          if (location.state) {
            setLocationCount(1);
            setLocationKey(location.state.key);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (ContactDetails) {
      setConservationCount(ContactDetails.contactConversationCount);
      setAppointmentCount(ContactDetails.contactAppointmentCount);
      if (locationCount == 1) {
        setpageNameType(locationKey);
        setLocationCount(0);
      } else {
        setpageNameType(ContactDetails.ContactPageNameType);
      }
    }
  }, [ContactDetails]);

  const handleSendCount = (val1, val2, val3) => {
    dispatch(setContactConversationCount(val1));
    dispatch(setContactAppointmentCount(val2));
    dispatch(setContactCustomerCount(val3));
  };

  const handleGetCustomerCount = async (id) => {
    let inputData = {
      dealerId: id.toString(),
      condition:
        "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30",
      sortBy: "",
    };
    try {
      AdminDashboardDataService.GetCustomerCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            handleSendCount(
              responseData.conservationCount,
              responseData.appointmentcount,
              responseData.customerCount
            );

          } else {
            setConservationCount(0);
            setAppointmentCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Export API
  const [exportLoading, setExportLoading] = useState(false);
  const handleGetCustomerExportDetails = async () => {
    try {
      let inputData = {
        dealerId: dealerId,
        dealerName: companyName,
      };
      setExportLoading(true);
      AdminDashboardDataService.GetCustomerExportDetails(inputData)
        .then((response) => {
          setExportLoading(false);
          window.open(response.data.responseData);
        })
        .catch((error) => {
          console.log(error);
          setExportLoading(false);
        });
    } catch (error) {
      console.log(error);
      setExportLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="catalog__list mb-5">
        <div className="catalog__topnav">
          <ul>
            <li onClick={() => handleSendPageType("Customer")}>
              {" "}
              <p
                className={
                  pageNameType == "Customer"
                    ? "topNavLinks linkText"
                    : "topNavLinks"
                }
              >
                {t("Customers")}
              </p>
            </li>
            <li onClick={() => handleSendPageType("Conversation")}>
              {" "}
              <p
                className={
                  pageNameType == "Conversation"
                    ? "topNavLinks linkText"
                    : "topNavLinks"
                }
              >
                {t("Conversations")}{" "}
                <span>{conservationCount ? conservationCount : 0}</span>
              </p>
            </li>

            <li onClick={() => handleSendPageType("AppointmentsOnly")}>
              {" "}
              <p
                className={
                  pageNameType == "AppointmentsOnly"
                    ? "topNavLinks linkText"
                    : "topNavLinks"
                }
              >
                {t("Appointments")}{" "}
                <span>{appointmentcount ? appointmentcount : 0}</span>
              </p>
            </li>
            <Spin spinning={exportLoading}>
              <li onClick={handleGetCustomerExportDetails}>
                {" "}
                <p className="topNavLinks"> {t("Export")}</p>
              </li>
            </Spin>
          </ul>
        </div>
      </div>

      {pageNameType == "Customer" && <Customer pageNameType={pageNameType} 
      onClickSchedule={() =>  handleSendPageType("AppointmentsOnly")}
      />}

      {pageNameType == "Conversation" && (
        <Conversations pageNameType={pageNameType} />
      )}

      {pageNameType == "AppointmentsOnly" && (
        <Appointment pageNameType={pageNameType} />
      )}
    </React.Fragment>
  );
};

export default Contacts;
