import React, { useState, useEffect } from "react";
import { Button, Modal, Popconfirm } from "antd";
import { ProgressBar } from "react-bootstrap";

import userIcon from "../../../assets/images/user-icon.png";
import ImageFormatIcon from "../../../assets/images/imageformaticon.png";
import HelpBook from "../../../assets/images/help_docs.png";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { httpCatalog } from "../../../helper/http-common";
import { useTranslation } from "react-i18next";
import Config from "./../../../helper/config";

const MarketingUploadFile = () => {
  const { t } = useTranslation();
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: "COMMA",
    IsServer: false,
    rbtnRemove: false,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const [imageMessage1, setImageMessage1] = useState(false);
  const [imageMessage2, setImageMessage2] = useState(false);
  const [imageProgress, setImageProgress] = useState();
  const [progressStart, setProgressStart] = useState(false);

  const [progressFileName, setProgressFileName] = useState(null);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  // for Popup
  const [visible, setVisible] = useState(false);
  const [Result, setResult] = useState("");
  // end

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  // For Popup
  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    setVisible(false);
  };
  // end

  const handleValidation = (upName) => {
    var isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = t("Please select image.");
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = t("Please select image.");
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = t("Please select file.");
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = t("Please select file.");
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = t("Please select delimiter.");
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const fileUpload = async (e, name) => {
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(png|jpg|zip)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Imagefile"] =
          "Please select .png|.jpg|.zip file only.";

        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt|json)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState.Error["Datafile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Datafile"] = t(
          "Please select .csv|.xls|.txt|.json file only."
        );
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  const option = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.round((100 * loaded) / total);
      setImageProgress(percent);
    },
  };

  const handleUploadJewelryImageFiles = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      setImageMessage1(true);
      setProgressStart(true);
      const formData = new FormData();

      formData.append("file", state.Imagefile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      httpCatalog
        .post("/Jewelry/UploadJewelryImageFiles", formData, option)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(t("File Uploaded Successfully."));

            state["Imagefile"] = null;
            state["ImagefileName"] = null;
            state.Error["Imagefile"] = null;

            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setImageMessage2(true);
          } else {
            NotificationManager.error(t("File Not Uploaded."));
            state["Imagefile"] = null;
            state["ImagefileName"] = null;
            state.Error["Imagefile"] = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
          }
        })
        .catch((error) => {
          setSubmitLoading1(false);
          setProgressStart(false);
          setImageMessage1(false);
          console.log(error);
        })
        .finally(() => {
          setSubmitLoading1(false);
          setProgressStart(false);
          setImageMessage1(false);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {t("Upload Your Media File")}
                  </h4>
                </div>

                <div className="col-lg-12">
                  <div className="input__block">
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-4 col-md-4">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {t("Choose Image")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => fileUpload(e, "Image")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Imagefile"]}
                              </div>
                            ) : null}
                            {imageMessage1 ? (
                              <span className="">
                                {" "}
                                {t(
                                  "Please wait while file is Uploading.."
                                )}{" "}
                              </span>
                            ) : null}

                            {state.ImagefileName ? (
                              ""
                            ) : (
                              <span className="mb-4">
                                {t("No File Choosen")}{" "}
                              </span>
                            )}
                            <span className="">{state.ImagefileName}</span>
                            <span className="">
                              {" "}
                              500 x 500 {t("Pixels")}{" "}
                            </span>
                            <span className="">
                              {" "}
                              {t("Jpg/Png Format")}{" "}
                            </span>
                          </div>

                          <div className="preview__img choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <p>
                            -{" "}
                            {t(
                              "Share marketing materials across your reatailer network."
                            )}
                          </p>
                          <div className="help-doc-sp">
                            <img src={HelpBook} width="15px" height="15px" />
                            <p>
                              {t("Questions? View the")}
                              <a
                                href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf`}
                                target="_blank"
                                download
                              >
                                {t("Media Import")}
                              </a>
                              {t("Help Doc")}.
                            </p>
                          </div>

                          <Button
                            className="primary-btn"
                            onClick={(e) =>
                              handleUploadJewelryImageFiles("Upload1")
                            }
                            loading={submitLoading1}
                          >
                            {t("Upload Data")}
                          </Button>
                          {imageMessage2 ? (
                            <>
                              <p className="mt-2 mb-0 text-success">
                                {" "}
                                {t("Your File is InProgress")}.{" "}
                              </p>
                              <Link to={"/UploadHistory"}>
                                <span className="">
                              
                                  {t("Please click here to see history")}
                                  .
                                </span>
                              </Link>
                            </>
                          ) : null}
                        </div>
                        <div className="col-md-2">
                          <div className="format__img">
                            <img src={ImageFormatIcon} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Popup */}
                  <Popconfirm
                    title={t("Are you sure to update Permission?")}
                    visible={visible}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  ></Popconfirm>

                  {/* End */}
                </div>
              </div>
              <Modal
                title={t("Progress")}
                className="modalconsupld__section"
                centered
                visible={progressStart}
                onOk={() => setProgressStart(false)}
                onCancel={() => setProgressStart(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={() => setProgressStart(false)}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setProgressStart(false)}
                  >
                    {t("Ok")}
                  </Button>,
                ]}
              >
                <div>
                  <ProgressBar
                    now={imageProgress}
                    label={`${imageProgress}%`}
                  />
                  <span>
                    {" "}
                    {t("Uploaded")} {imageProgress}% {t("Total")}{" "}
                    100%
                  </span>
                  <br></br>
                  <span>
                    {" "}
                    {t("Uploading file")}: {progressFileName}
                  </span>
                </div>
              </Modal>
              <Modal
                title={t("Confirmation")}
                centered
                visible={visibleConfirmModal}
                onCancel={() => setVisibleConfirmModal(false)}
                width={400}
                footer={[
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setVisibleConfirmModal(false)}
                  >
                    {t("Ok")}
                  </Button>,
                ]}
              >
                <div className="text-center">
                  <h5> {t("This option will override the data.")} </h5>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MarketingUploadFile;
