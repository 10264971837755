import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import AdminToolsService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const ClientDataMapping = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [clientDataMappingList, setClientDataMapList] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [sortType, setSortType] = useState("asc");
	const [sortColumnName, setSortColumnName] = useState("dealerId");

	useEffect(() => {
		handleGetClientDataMappingList(pageNo, pageSize, "dealerId", "asc");
	}, []);

	function showConfirm(id) {
		confirm({
			title: t("Set Default Mapping"),
			icon: <ExclamationCircleOutlined />,
			content: t(
				"Are you Sure By Submit Existing Mapping replace with Default mapping. ?"
			),
			onOk() {
				handleSetDefaultMapping(id);
			},
			onCancel() {},
			okText: t("OK"),
			cancelText: t("Cancel"),
		});
	}
	const handleTableASCDES = (pagination, filters, sorter) => {
		var type = "";
		var column = "";
		if (sorter.order == "descend") {
			type = "DESC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else if (sorter.order == "ascend") {
			type = "ASC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else {
			type = "ASC";
			column = "dealerId";
			setSortColumnName(column);
			setSortType(type);
		}
		setPageNo(pagination.current);
		setPageSize(pagination.pageSize);
		handleGetClientDataMappingList(
			pagination.current,
			pagination.pageSize,
			column,
			type
		);
	};

	const handleGetClientDataMappingList = (
		pageNo,
		pageSize,
		sortColumnName,
		sortOrder
	) => {
		let inputData = {
			pageNumber: pageNo ? pageNo : 1,
			pageSize: pageSize ? pageSize : 10,
			sortOrderCol: sortColumnName || "dealerId",
			sortOrderType: sortOrder || "ASC",
		};
		setLoading(true);
		AdminToolsService.GetClientDataMappingList(inputData)
			.then((response) => {
				var message = response.data.message;
				var responseData = response.data.responseData;
				setLoading(false);
				if (message === "Success") {
					//
					setClientDataMapList(responseData.client_dataMapListResponse);
					setTotalCount(responseData.totalRecord);
				} else {
					setClientDataMapList([]);
					setTotalCount(0);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleSetDefaultMapping = (id) => {
		setLoading(true);
		let inputData = {
			dealerID: id,
		};
		AdminToolsService.SetDefaultMapping(inputData)
			.then((response) => {
				var message = response.data.message;
				setLoading(false);
				if (message === "Success") {
					handleGetClientDataMappingList(
						pageNo,
						pageSize,
						sortColumnName,
						sortType
					);
					NotificationManager.success(
						t("Defualt Mapping Created Successfully.")
					);
				} else {
					NotificationManager.error(t("Not Set Defualt Mapping."));
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<React.Fragment>
			<div className="clientdata_section">
				<div className="form__fields">
					<div className="row">
						<div className="col-lg-12">
							<h4 className="workarea__heading mt-0 mb-0">
								{t("Client Data Maps")}
							</h4>
						</div>

						<div className="col-lg-12 mt-4">
							<div className="clientdata_tbldiv clientDataMapTable">
								<Table
									className="table-responsive"
									loading={loading}
									columns={[
										{
											title: t("Dealer ID"),
											dataIndex: "dealerID",
											sorter: (a, b) => a.dealerID - b.dealerID,
											width: 120,
										},
										{
											title: t("Dealer Company"),
											dataIndex: "dealerCompany",
											sorter: (a, b) =>
												a.dealerCompany.localeCompare(b.dealerCompany),
										},
										{
											title: t("Website Address"),
											dataIndex: "websiteAddress",
										},
										{
											title: t("Upload"),
											dataIndex: "upload",
											width: 80,
											render: (row, item) => {
												return (
													<React.Fragment>
														<div className="action__btns">
															<div className="image__block">
																<Link
																	to={"/uploadProductFile"}
																	state={{
																		dealerData: item,
																		key: "ClientDataMapping",
																	}}>
																	<img src={Edit_icon} alt="" />
																</Link>
															</div>
														</div>
													</React.Fragment>
												);
											},
											width: 100,
										},
										{
											title: t("InBound Data Map"),
											dataIndex: "inbound_datamap",
											width: 100,
											render: (row, item) => {
												return (
													<React.Fragment>
														<div className="action__btns">
															<div className="image__block">
																<Link
																	to={"/adminDataMapping"}
																	state={{ key: item.dealerID, columnID: 14 }}>
																	<img src={Edit_icon} alt="" />
																</Link>
															</div>
														</div>
													</React.Fragment>
												);
											},
											width: 100,
										},
										{
											title: t("Outgoing Data Map"),
											dataIndex: "outgoing_datamap",
											width: 100,
											render: (row, item) => {
												return (
													<React.Fragment>
														<div className="action__btns">
															<div className="image__block">
																<Link
																	to={"/outGoingDataMap"}
																	state={{ dealerID: item.dealerID }}>
																	<img src={Edit_icon} alt="" />
																</Link>
															</div>
														</div>
													</React.Fragment>
												);
											},
											width: 100,
										},
										{
											title: t("OutBound Sync"),
											dataIndex: "outbound_sync",
											width: 100,
											render: (row, item) => {
												return (
													<React.Fragment>
														<div className="action__btns">
															<div className="image__block">
																<Link
																	to={"/outBoundSync"}
																	state={{ dealerID: item.dealerID }}>
																	<img src={Edit_icon} alt="" />
																</Link>
															</div>
														</div>
													</React.Fragment>
												);
											},
											width: 100,
										},
										{
											title: t("Set Default Mapping"),
											dataIndex: "setdefault_mapping",
											width: 120,
											render: (row, item) => {
												return (
													<React.Fragment>
														<Button
															className="primary-btn"
															onClick={(e) => {
																showConfirm(item.dealerID);
															}}>
															{t("Submit")}
														</Button>
													</React.Fragment>
												);
											},
											width: 120,
										},
									]}
									onChange={(pagination, filters, sorters) => {
										document
											.getElementsByClassName("content__area")[0]
											.scroll(0, 0);
										handleTableASCDES(pagination, filters, sorters);
									}}
									dataSource={clientDataMappingList}
									pagination={{
										total: totalCount,
										pageNo: pageNo,
										pageSize: pageSize,
										pageSizeOptions: [10, 20, 50, 100],
										responsive: true,
										showSizeChanger: true,
									}}
									scroll={{ y: 800 }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ClientDataMapping;
