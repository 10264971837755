import React, { useState, useEffect } from "react";
import { Select, Switch, Spin, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const RingbuilderNavigation = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [disableBtn, setDisableBtn] = useState(true);
  const [dealerId, setDealerId] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opendDiamondSetupModal, setOpendDiamondSetupModal] = useState(false);

  const [ViewNavigationData, setViewNavigationData] = useState({
    StandardSearch: true,
    AdvancedSearch: true,
    LabGrownSearch: true,
    RequestaDiamond: true,
    CompareDiamonds: true,
    FancyColoredSearch: true,
    LabSetting: true,
    MinedSetting: true,
    standardSearchOrderNo: "",
    advancedSearchOrderNo: "",
    fancyColoredSearchOrderNo: "",
    requestDiamondOrderNo: "",
    compareDiamondOrderNo: "",
    labGrownDiamondsOrderNo: "",
    minedSettingOrderNo: "",
    labSettingOrderNo: "",
  });

  const loginDetials = useSelector((state) => state.loginReducer);
  const headerDetails = useSelector((state) => state.headerMenuReducer);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetNavigationDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleGetNavigationDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };
    setLoading(true);
    try {
      AppsRingBuilderService.GetRingBuilderNavigationDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          if (message === "Success") {
            if (responseData) {
              setLoading(false);
              setViewNavigationData((prevState) => ({
                ...prevState,
                StandardSearch: responseData.standardSearch,
                AdvancedSearch: responseData.advancedSearch,
                LabGrownSearch: responseData.labGrownDiamonds,
                RequestaDiamond: responseData.requestDiamond,
                FancyColoredSearch: responseData.fancyColoredSearch,
                CompareDiamonds: responseData.compareDiamond,
                LabSetting: responseData.labSetting,
                MinedSetting: responseData.minedSetting,
                standardSearchOrderNo:
                  responseData.standardSearchOrderNo.toString(),
                advancedSearchOrderNo:
                  responseData.advancedSearchOrderNo.toString(),
                fancyColoredSearchOrderNo:
                  responseData.fancyColoredSearchOrderNo.toString(),
                requestDiamondOrderNo:
                  responseData.requestDiamondOrderNo.toString(),
                compareDiamondOrderNo:
                  responseData.compareDiamondOrderNo.toString(),
                labGrownDiamondsOrderNo:
                  responseData.labGrownDiamondsOrderNo.toString(),
                minedSettingOrderNo:
                  responseData.minedSettingOrderNo.toString(),
                labSettingOrderNo: responseData.labSettingOrderNo.toString(),
              }));
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId,
        Type: "RingBuilder",
        standardSearch:
          !ViewNavigationData.StandardSearch &&
          !ViewNavigationData.LabGrownSearch &&
          !ViewNavigationData.RequestaDiamond &&
          !ViewNavigationData.FancyColoredSearch &&
          !ViewNavigationData.CompareDiamonds
            ? true
            : ViewNavigationData.StandardSearch,
        advancedSearch: ViewNavigationData.AdvancedSearch,
        labGrownDiamonds: ViewNavigationData.LabGrownSearch,
        requestDiamond: ViewNavigationData.RequestaDiamond,
        fancyColoredSearch: ViewNavigationData.FancyColoredSearch,
        compareDiamond: ViewNavigationData.CompareDiamonds,
        labSetting: ViewNavigationData.LabSetting,
        minedSetting: ViewNavigationData.MinedSetting,
        standardSearchOrderNo:
          !ViewNavigationData.StandardSearch &&
          !ViewNavigationData.LabGrownSearch &&
          !ViewNavigationData.RequestaDiamond &&
          !ViewNavigationData.FancyColoredSearch &&
          !ViewNavigationData.CompareDiamonds
            ? "1"
            : ViewNavigationData.standardSearchOrderNo,
        advancedSearchOrderNo: ViewNavigationData.advancedSearchOrderNo,
        fancyColoredSearchOrderNo: ViewNavigationData.fancyColoredSearchOrderNo,
        requestDiamondOrderNo: ViewNavigationData.requestDiamondOrderNo,
        compareDiamondOrderNo: ViewNavigationData.compareDiamondOrderNo,
        labGrownDiamondsOrderNo: ViewNavigationData.labGrownDiamondsOrderNo,
        minedSettingOrderNo: ViewNavigationData.minedSettingOrderNo,
        labSettingOrderNo: ViewNavigationData.labSettingOrderNo,
      };
      setLoading(true);
      AppsRingBuilderService.UpdateRingBuilderNavigationDetails(inputData)
        .then((response) => {
          let message = response.data.message;

          setLoading(false);
          setSubmitLoading(false);
          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully."));
            handleGetNavigationDetails(dealerId);
          } else {
            NotificationManager.success(t("Data not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
    setDisableBtn(true);
  };

  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      setViewNavigationData({
        ...ViewNavigationData,
        [name]: e,
      });
    } else {
      setViewNavigationData({
        ...ViewNavigationData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOpenDiamondSetup = () => {
    setOpendDiamondSetupModal(true);
  };
  const handleCancelDiamondSetupModal = () => {
    setOpendDiamondSetupModal(false);
  };
  return (
    <div className="navigation__section">
      <div className="form__fields">
        <div className="col-md-12 mt-0">
          <h4 className="workarea__heading mt-0 mb-0"> {t("Navigation")} </h4>
        </div>
        <Spin spinning={loading}>
          <div className="col-lg-6 mt-3">
            <div className="form__section">
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Standard Search")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="StandardSearch"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.StandardSearch}
                      onChange={(e) =>
                        handleOnChange(e, "select", "StandardSearch")
                      }
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}
                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.standardSearchOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "standardSearchOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Advanced Search")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="AdvanvedSearch"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.AdvancedSearch}
                      onChange={(e) =>
                        handleOnChange(e, "select", "AdvancedSearch")
                      }
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}

                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.advancedSearchOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "advancedSearchOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Lab Grown Search")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="LabGrownSearch"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.LabGrownSearch}
                      onChange={(e) =>
                        handleOnChange(e, "select", "LabGrownSearch")
                      }
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}

                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.labGrownDiamondsOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "labGrownDiamondsOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Request a Diamond")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="RequestaDiamond"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.RequestaDiamond}
                      onChange={(e) =>
                        handleOnChange(e, "select", "RequestaDiamond")
                      }
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}

                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.requestDiamondOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "requestDiamondOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Compare Diamonds")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="CompareDiamonds"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.CompareDiamonds}
                      onChange={(e) =>
                        handleOnChange(e, "select", "CompareDiamonds")
                      }
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}
                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.compareDiamondOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "compareDiamondOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Mined Setting")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="MinedSetting"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.MinedSetting}
                      onChange={(e) =>
                        handleOnChange(e, "select", "MinedSetting")
                      }
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}
                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.minedSettingOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "minedSettingOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {t("Lab Setting")} </span>
                </div>

                <div className="inpt__div row">
                  <div className="col-md-4 mobile__bottomspace">
                    <Switch
                      name="LabSetting"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={ViewNavigationData.LabSetting}
                      onChange={(e) =>
                        handleOnChange(e, "select", "LabSetting")
                      }
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="input__block mb-0">
                      <Select
                        className="border__grey"
                        placeholder={t("Standard Serach")}
                        name=""
                        optionFilterProp="children"
                        value={ViewNavigationData.labSettingOrderNo}
                        onChange={(e) =>
                          handleOnChange(e, "select", "labSettingOrderNo")
                        }
                      >
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <p>
              *
              {t(
                "New Diamond Dealer inventories are automatically added to yourDiamond Dealer List. If you wish this to not occur, then"
              )}
              {headerDetails?.applicationPermisstionResponse?.diamondLink ? (
                <Link
                  to={"/options"}
                  state={{
                    isSetup: true,
                  }}
                >
                  {t("Click Here")}
                </Link>
              ) : (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={handleOpenDiamondSetup}
                >
                  {t("Click Here")}
                </span>
              )}
            </p>
          </div>
          <div className="col-lg-12 mt-5">
            <button
              className={disableBtn ? "disabled-btn" : "primary-btn"}
              onClick={handleSubmit}
              disabled={disableBtn}
              loading={submitLoading}
            >
              {" "}
              {t("Update Settings")}{" "}
            </button>
          </div>
        </Spin>
      </div>
      <Modal
        title={t("Alert")}
        className="datamapping__modal"
        width={400}
        centered
        visible={opendDiamondSetupModal}
        onCancel={handleCancelDiamondSetupModal}
        maskClosable={false}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12 text-center">
            <p> {t("Please Contact Gemfind")} </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RingbuilderNavigation;
