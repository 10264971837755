import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import AdminReportDataService from "../../../services/admin-report.service";

/*Initial State for Binding Starts*/
const initialDataRelatedState = {
  toolsReportRadio: "cate"
};
/*Initial State for Binding Ends*/

const DataRelated = () => {
  const { t } = useTranslation();
  const [globalEvents, setGlobalEvents] = useState(initialDataRelatedState);
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [DataRelatedGridDataList, setDataRelatedGridDataList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("Name");
  const [selectedRadio, setSelectedRadio] = useState("cate");
  const [sortType, setSortType] = useState("asc");
  const [key, setKey] = useState(true);

  useEffect(() => {
    handleGetDataRelatedRecord(pageNo, pageSize, sortColumnName, sortType);
  }, []);

  const handleOnChangeRadio = (event) => {
    setGlobalEvents({
      ...globalEvents,
      toolsReportRadio: event.target.name
    });
  };

  /*Sorting API Starts*/
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "Name";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetDataRelatedRecord(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  const formatReportTxt = (text) => {
    return text ? text.replace(/<br\s*\/?>/gi, "\n") : "";
  };
  /*Sorting API Ends*/

  /*API Binding Starts*/
  const handleGetDataRelatedRecord = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    setSelectedRadio(globalEvents.toolsReportRadio);
    let inputData = {
      type: globalEvents.toolsReportRadio,
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "Name",
      sortOrder: sortOrder || "ASC"
    };
    setTableLoading(true);
    AdminReportDataService.GetDataRelatedRecord(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var dataRelatedGridDataList =
          response.data.responseData.getDataRelatedRecordresplist;
        let newDataRelatedGridDataList = dataRelatedGridDataList?.map(
          (item) => {
            return {
              ...item,
              reportTxt: item?.reportTxt
                ? item.reportTxt.replace(/<br\s*\/?>/gi, "\n")
                : null,
            };
          }
        );
        if (message === "Success") {
          if (responseData) {
            var temptotalRecords = newDataRelatedGridDataList[0].totalRecord;

            // logString.replace(/<br \/>/g, '\n')
            setTotalCount(temptotalRecords);
            setDataRelatedGridDataList(newDataRelatedGridDataList);
          }
        } else {
          setDataRelatedGridDataList([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        setDataRelatedGridDataList([]);
        console.log(error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const handleDatarealatedDownload = () => {
    let inputData = {
      type: globalEvents.toolsReportRadio
    };
    setDownloadDataloading(true);
    AdminReportDataService.DatarealatedDownload(inputData)
      .then((response) => {
        openInNewTab(response.data.responseData);
      })
      .catch((error) => {
        setDownloadDataloading(false);
        console.log(error);
      })
      .finally(() => setDownloadDataloading(false));
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleApplyFilter = () => {
    setKey(!key);
    handleGetDataRelatedRecord(1, 10, sortColumnName, sortType);
    setPageNo(1);
    setPageSize(10);
    setTotalCount(0);
  };

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Data Related")}
              </h4>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="Categories"
                        name="cate"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === "cate"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="Categories">
                        {" "}
                        {t("Categories")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="MetalTypes"
                        name="mtype"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === "mtype"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="MetalTypes">
                        {" "}
                        {t("Metal Types")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="MetalColors"
                        name="mcolor"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === "mcolor"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="MetalColors">
                        {" "}
                        {t("Metal Colors")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="DiamondPairs"
                        name="dpair"
                        value={globalEvents.toolsReportRadio}
                        checked={
                          globalEvents.toolsReportRadio === "dpair"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="DiamondPairs">
                        {" "}
                        {t("Diamond Pairs")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="button__block mb-4 mt-2 mobile-m-0">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-12 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() => handleDatarealatedDownload()}
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {t("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={key}
              className="pendjewelreq__tbl"
              loading={tableLoading}
              columns={[
                ...(selectedRadio !== "dpair"
                  ? [
                      {
                        title: t("Name"),
                        dataIndex: "name"
                      },
                      {
                        title: t("Default Mapping Values"),
                        dataIndex: "mappingValue"
                      },
                      {
                        title: t("Items"),
                        dataIndex: "itemCount"
                      },
                      {
                        title: t("Hierarchy"),
                        dataIndex: "heirachy"
                      }
                    ]
                  : []),

                ...(selectedRadio === "dpair"
                  ? [
                      {
                        title: t("Report Name"),
                        dataIndex: "reportTxt",
                        render: (text) => (
                          <div
                            dangerouslySetInnerHTML={{ __html: formatReportTxt(text) }}
                            style={{ whiteSpace: 'pre-line' }} // Ensures new lines are preserved
                          />
                        ),
                        with: 300
                      },
                      {
                        title: t("Send Date"),
                        dataIndex: "sendDateFormated",
                         onCell: () => ({
                          style: { verticalAlign: 'top' } 
                        }),
                        with: 150
                      }
                    ]
                  : [])
              ]}
              dataSource={DataRelatedGridDataList}
              onChange={handleTableASCDES}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true
              }}
              scroll={{ x: 600, y: 800 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataRelated;
