import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import RetailerDataService from "../../services/retailer-retailer.service";
import communityVendorService from "../../services/community-vendor.service";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { setChecklinkHeaderMenuData } from "../../actions/headerMenu/headerMenu";
import { NotificationManager } from "react-notifications";
import UnreadIcon from "../../assets/images/unread.png";
import ReadIcon from "../../assets/images/read.png";
import RepliedIcon from "../../assets/images/replied.png";
import { Select, Button, Switch, Modal, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import {
	setVendorConversationCount,
	setVendorAppointmentCount,
	setVendorConversationCountUnRead,
	setVendorConversationCountSent,
	setJewelryTab,
} from "../../actions/Contact/Contact";
import parse from "html-react-parser";

const ConversationsDashboard = ({ pageNameType, conversationCommonCount }) => {
	const { t } = useTranslation();
	const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
	const [dealerId, setDealerId] = useState(0);
	const [allTotal, setAllTotal] = useState(0);
	const [unreadTotal, setUnreadTotal] = useState(0);
	const [sentTotal, setSentTotal] = useState(0);

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.responseData) {
				setDealerId(loginDetials.responseData.dealerId);
			}
			handleGetRetailerConversationByTypeAllConv(
				loginDetials.responseData.dealerId
			);
			handleGetBindSalesReps(loginDetials.responseData.dealerId);
			GetSalesRepsList(loginDetials.responseData.dealerId);
		}
	}, []);

	const dispatch = useDispatch();
	const conversationSection = useRef(null);
	const { Option } = Select;

	//Conversation API Binding Starts
	const [conversationByTypeAllConvList, setConversationByTypeAllConvList] =
		useState([]);
	const [conversationByTypeUnreadList, setConversationByTypeUnreadList] =
		useState([]);
	const [conversationByTypeSentList, setConversationByTypeSentList] = useState(
		[]
	);
	const [salesRepList, setsalesRepList] = useState([]);
	const [SalesRepId, setSalesRepId] = useState("");
	const [SalesRep, setSalesRep] = useState([]);
	const [selectedColumnId, setSelectedColumnId] = useState(0);
	const [selectedTabName, setSelectedTabName] = useState("allConversations");
	const [allConvLoading, setAllConvLoading] = useState(false);
	const [unreadConvLoading, setUnreadLoading] = useState(false);
	const [sentConvLoading, setSentConvLoading] = useState(false);
	const [visible2, setVisible2] = useState(false);
	const [loadingprofileData, setLoadingProfileData] = useState(false);
	const [rowDetails, setRowDetails] = useState({});
	const [disconnectSwitch, setDisconnectSwitch] = useState(true);
	const [conversationDateList, setConversationDateList] = useState([]);
	const [conversationDateListUnread, setConversationDateListUnread] = useState(
		[]
	);
	const [conversationDateListSent, setConversationDateListSent] = useState([]);
	let navigate = useNavigate();

	const initialState = {
		userId: "",
	};
	const [salesRepData, setSalesRepData] = useState(initialState);
	const [delData, setDelData] = useState({
		id: 0,
	});
	const [retailerData, setRetailerData] = useState({
		retailerId: "",
		dealerId: "",
	});
	const OnConversationRowClick = (item) => {
		setRetailerData({
			retailerId: item.retailerID,
		});

		handleGetRetailerProfile(item.retailerID, false, dealerId);
	};
	const onTabsChange = (key) => {
		setColumnName("");
		setSortName("");
		if (key == "unread") {
			handleGetRetailerConversationByTypeUnread(dealerId, salesRepData.userId);
			setSelectedTabName("unread");
		} else if (key == "sent") {
			handleGetRetailerConversationByTypeSent(dealerId, salesRepData.userId);
			setSelectedTabName("sent");
		} else {
			handleGetRetailerConversationByTypeAllConv(dealerId, salesRepData.userId);
			setSelectedTabName("allConversations");
		}
	};
	const handleGetRetailerConversationByTypeAllConv = (
		id,
		salesRep = "",
		sortBy
	) => {
		setAllConvLoading(true);
		try {
			let inputData = {
				dealerID: id.toString(),
				type: 0,
				salesRep: salesRep,
				tagId: "",
				orderby: sortBy ? sortBy.toString() : "",
			};
			AdminDashboardDataService.GetRetailerConversationByType(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var conversationByTypeAllConvList =
						responseData.getRetailerConversationByTypeResponcelist;
					if (message === "Success") {
						setAllConvLoading(false);
						if (
							responseData &&
							responseData.getRetailerConversationByTypeResponcelist
						) {
							setConversationByTypeAllConvList(conversationByTypeAllConvList);
							handleGetRetailerProfile(
								conversationByTypeAllConvList.length > 0
									? conversationByTypeAllConvList[0].retailerID
									: 0,
								false,
								id
							);
							var tempDates = [
								...new Set(
									conversationByTypeAllConvList.map(
										({ formatedDate }) => formatedDate
									)
								),
							];
							setConversationDateList(tempDates);
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
						} else {
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
							setConversationByTypeAllConvList([]);
							setConversationDateList([]);
						}
					} else {
						setAllTotal(0);
						setUnreadTotal(0);
						setSentTotal(0);
						setAllConvLoading(false);
						setConversationByTypeAllConvList([]);
					}
				})
				.catch((error) => {
					setAllTotal(0);
					setUnreadTotal(0);
					setSentTotal(0);
					setAllConvLoading(false);
					console.log(error);
				});
		} catch (error) {
			setAllTotal(0);
			setUnreadTotal(0);
			setSentTotal(0);
			setAllConvLoading(false);
			console.log(error);
		}
	};

	const handleGetRetailerConversationByTypeUnread = (
		id,
		salesRep = "",
		orderby
	) => {
		setUnreadLoading(true);
		try {
			let inputData = {
				dealerID: id.toString(),
				type: 1,
				salesRep: salesRep,
				tagId: "",
				orderby: orderby ? orderby.toString() : "",
			};
			AdminDashboardDataService.GetRetailerConversationByType(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var conversationByTypeUnreadList =
						responseData.getRetailerConversationByTypeResponcelist;

					if (message === "Success") {
						setUnreadLoading(false);
						if (
							responseData &&
							responseData.getRetailerConversationByTypeResponcelist
						) {
							setConversationByTypeUnreadList(conversationByTypeUnreadList);
							handleGetRetailerProfile(
								conversationByTypeUnreadList.length > 0
									? conversationByTypeUnreadList[0].retailerID
									: 0,
								false,
								id
							);
							var tempDates = [
								...new Set(
									conversationByTypeUnreadList.map(
										({ formatedDate }) => formatedDate
									)
								),
							];
							setConversationDateListUnread(tempDates);
							setConversationDateList(tempDates);
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
						} else {
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
							setConversationByTypeUnreadList([]);
							setConversationDateListUnread([]);
							setConversationDateList([]);
						}
					} else {
						setAllTotal(0);
						setUnreadTotal(0);
						setSentTotal(0);
						setConversationByTypeUnreadList([]);
						setConversationDateListUnread([]);
						setConversationDateList([]);
					}
				})
				.catch((error) => {
					setAllTotal(0);
					setUnreadTotal(0);
					setSentTotal(0);
					setUnreadLoading(false);
					console.log(error);
				});
		} catch (error) {
			setUnreadLoading(false);
			console.log(error);
		}
	};
	const handleGetRetailerConversationByTypeSent = (
		id,
		salesRep = "",
		orderby
	) => {
		setSentConvLoading(true);
		try {
			let inputData = {
				dealerID: id.toString(),
				type: 3,
				salesRep: salesRep,
				tagId: "",
				orderby: orderby ? orderby.toString() : "",
			};
			AdminDashboardDataService.GetRetailerConversationByType(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var conversationByTypeSentList =
						responseData.getRetailerConversationByTypeResponcelist;

					if (message === "Success") {
						setSentConvLoading(false);
						if (
							responseData &&
							responseData.getRetailerConversationByTypeResponcelist
						) {
							setConversationByTypeSentList(conversationByTypeSentList);
							handleGetRetailerProfile(
								conversationByTypeSentList.length > 0
									? conversationByTypeSentList[0].retailerID
									: 0,
								false,
								id
							);
							var tempDates = [
								...new Set(
									conversationByTypeSentList.map(
										({ formatedDate }) => formatedDate
									)
								),
							];
							setConversationDateListSent(tempDates);
							setConversationDateList(tempDates);
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
						} else {
							setAllTotal(responseData?.allCount || 0);
							setUnreadTotal(responseData?.unreadCount || 0);
							setSentTotal(responseData?.sentCount || 0);
							setConversationByTypeSentList([]);
							handleGetRetailerProfile("", "", "");
							setConversationDateListSent([]);
						}
					} else {
						setAllTotal(0);
						setUnreadTotal(0);
						setSentTotal(0);
						setConversationByTypeSentList([]);
					}
				})
				.catch((error) => {
					setAllTotal(0);
					setUnreadTotal(0);
					setSentTotal(0);
					setSentConvLoading(false);
					console.log(error);
				});
		} catch (error) {
			setAllTotal(0);
			setUnreadTotal(0);
			setSentTotal(0);
			setSentConvLoading(false);
			console.log(error);
		}
	};
	const handleGetBindSalesReps = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			AdminDashboardDataService.GetBindSalesReps(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var salesRepList = responseData;
					if (message === "Success") {
						if (responseData) {
							setsalesRepList(salesRepList);
						} else {
							setsalesRepList([]);
						}
					} else {
						setsalesRepList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetConversionCount = (dealerId) => {
		try {
			let inputData = {
				vendorId: dealerId,
			};
			AdminDashboardDataService.GetConversionCount(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							dispatch(setVendorConversationCount(responseData.allCount));
							dispatch(setVendorConversationCountSent(responseData.allSent));
							dispatch(
								setVendorConversationCountUnRead(responseData.allUnReadCount)
							);
							dispatch(setJewelryTab("ConversationsOnly"));
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetRetailerAppointmentData = (
		id,
		salesRep,
		tradeShowId,
		fromDate,
		toDate
	) => {
		try {
			let inputData = {
				dealerId: id.toString(),
				salesRep: salesRep ? salesRep : "",
				tradeShowId: tradeShowId ? tradeShowId : "",
				fromDate: fromDate ? fromDate : "",
				toDate: toDate ? toDate : "",
				orderby: "",
			};
			AdminDashboardDataService.GetRetailerAppointmentData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;

					if (message === "Success") {
						if (responseData) {
							dispatch(setVendorAppointmentCount(responseData.length));
						} else {
						}
					} else {
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteComments = () => {
		try {
			let inputData = {
				id: delData.id,
			};
			AdminDashboardDataService.DeleteComments(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Comments Deleted Successfully."));
						handleGetConversionCount(dealerId);
						handleGetRetailerAppointmentData(dealerId);
						if (selectedTabName == "allConversations") {
							handleGetRetailerConversationByTypeAllConv(
								dealerId,
								salesRepData.userId
							);
						} else if (selectedTabName == "unread") {
							handleGetRetailerConversationByTypeUnread(
								dealerId,
								salesRepData.userId
							);
						} else if (selectedTabName == "sent") {
							handleGetRetailerConversationByTypeSent(
								dealerId,
								salesRepData.userId
							);
						}
					} else {
						NotificationManager.error(t("Comments Not Deleted."));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
		handleCancel2();
	};
	const handleLoadUnreadConversationofRetailer = (
		retailerID,
		unreadMessageID
	) => {
		try {
			let inputData = {
				dealerID: dealerId,
				retailerID: retailerID,
				unreadMessageID: unreadMessageID,
			};
			AdminDashboardDataService.LoadUnreadConversationofRetailer(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Unread updated Successfully."));
					} else {
						NotificationManager.error(t("Unread Not Updated."));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetRetailerProfile = (id, showBtn, dealerId) => {
		try {
			let inputData = {
				retailerID: id,
				showBtn: false,
				dealerId: dealerId,
			};
			setSelectedColumnId(id);
			setLoadingProfileData(true);
			RetailerDataService.GetRetailerProfile(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData[0];
					if (message === "Success") {
						if (responseData) {
							setSalesRepId(
								responseData.salesUserId ? responseData.salesUserId : ""
							);
							setRowDetails(responseData);
							setLoadingProfileData(false);
						} else {
							setRowDetails({});
							setLoadingProfileData(false);
						}
					} else {
						setRowDetails({});
						setLoadingProfileData(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoadingProfileData(false);
				});
		} catch (error) {
			console.log(error);
			setLoadingProfileData(false);
		}
	};

	const GetSalesRepsList = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			RetailerDataService.GetSalesRepsList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setSalesRep(responseData);
						} else {
							setSalesRep([]);
						}
					} else {
						setSalesRep([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleSwitch = () => {
		setDisconnectSwitch(false);
		try {
			let inputData = {
				dealerID: Number(dealerId),
				vendorID: Number(rowDetails.dealerID),
				flg: 1,
			};
			communityVendorService
				.ReActiveCollection(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Retailer Has Been Disconnected."));
					} else {
						NotificationManager.error(
							t("Retailer Has Not Been Not Disconnected.")
						);
						setDisconnectSwitch(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleOnSelectChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setSalesRepId({
				...SalesRepId,
				[name]: e,
			});
			handleSaveSalesReps(e);
		} else {
			setSalesRepId({
				...SalesRepId,
				[e.target.name]: e.target.value,
			});
		}
	};
	const handleSaveSalesReps = (id) => {
		try {
			let inputData = {
				dealerID: dealerId,
				retailerID: rowDetails.dealerID,
				salesRepId: id,
			};
			RetailerDataService.SaveSalesReps(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Sales Rep Added Successfully"));
						handleGetRetailerProfile(retailerData.retailerId, false, dealerId);
						if (selectedTabName == "allConversations") {
							handleGetRetailerConversationByTypeAllConv(dealerId, "");
						} else if (selectedTabName == "unread") {
							handleGetRetailerConversationByTypeUnread(dealerId, "");
						} else if (selectedTabName == "sent") {
							handleGetRetailerConversationByTypeSent(dealerId, "");
						}
					} else {
						NotificationManager.success(t("Sales Rep Not Added"));
						handleGetRetailerProfile(retailerData.retailerId, false, dealerId);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	function onSearch(val) {}
	const handleFilterBySalesRepDropdown = (salesRepData) => {
		if (selectedTabName == "allConversations") {
			handleGetRetailerConversationByTypeAllConv(dealerId, salesRepData);
		} else if (selectedTabName == "unread") {
			handleGetRetailerConversationByTypeUnread(dealerId, salesRepData);
		} else if (selectedTabName == "sent") {
			handleGetRetailerConversationByTypeSent(dealerId, salesRepData);
		}
	};
	const handleOnChangeConversation = (e, isSelect, name) => {
		if (isSelect === "select") {
			setSalesRepData({
				...salesRepData,
				[name]: e,
			});
		}
		handleFilterBySalesRepDropdown(e);
	};
	const handleCancel2 = () => {
		setVisible2(false);
		setDelData({
			id: 0,
		});
	};
	const handleDeleteModal = (row) => {
		setVisible2(true);
		setDelData({
			id: row.id,
		});
	};
	const checkIfUnread = (value, retailerID, unreadMessageID) => {
		if (value == "UnRead") {
			handleLoadUnreadConversationofRetailer(retailerID, unreadMessageID);
		}
	};

	// conversation table ends
	const handleHeaderMenuClick = (record) => {
		if (record.messageetype == "Conversation") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Conversation",
					id: record.retailerID.toString(),
					messageId: record.id,
				},
			});
		} else if (record.messageetype == "Appointment") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Appointments",
					id: record.retailerID.toString(),
					messageId: record.id,
					appointmentDefaultMsg: record.message.replace(
						/(<([^>]+)>|Appointment -|&nbsp;)/gi,
						""
					),
				},
			});
		}
	};
	const handleHeaderMenuClickForSideProfile = (type) => {
		if (type == "Conversation") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Conversation",
					id: selectedColumnId,
				},
			});
		} else if (type == "Profile") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Profile",
					id: selectedColumnId,
				},
			});
		} else if (type == "Appointments") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/makeAppoitments", {
				state: {
					key: "Appointments",
					id: selectedColumnId,
				},
			});
		} else if (type == "Notes") {
			dispatch(setChecklinkHeaderMenuData("Retailers"));
			navigate("/retailerProfile", {
				state: {
					key: "Notes",
					id: selectedColumnId,
				},
			});
		}
	};
	//Conversation API Binding Ends

	// Sorting

	const [columnName, setColumnName] = useState("");
	const [sortName, setSortName] = useState("");

	const handleThClick = (ColumnName) => {
		let sortType = "";
		setColumnName(ColumnName);

		if (ColumnName == columnName) {
			sortType = "Desc";
			setColumnName("");
		} else {
			sortType = "Asc";
		}
		handleTableCascade(ColumnName, sortType);
	};

	const handleTableCascade = (ColumnName, sortType) => {
		let sortBy = ColumnName + " " + sortType;
		setSortName(ColumnName + " " + sortType);

		if (selectedTabName == "unread") {
			handleGetRetailerConversationByTypeUnread(
				dealerId,
				salesRepData.userId,
				ColumnName == "To" ? "" : sortBy
			);
		} else if (selectedTabName == "sent") {
			handleGetRetailerConversationByTypeSent(
				dealerId,
				salesRepData.userId,
				ColumnName == "To" ? "" : sortBy
			);
		} else if (selectedTabName == "allConversations") {
			handleGetRetailerConversationByTypeAllConv(
				dealerId,
				salesRepData.userId,
				ColumnName == "To" ? "" : sortBy
			);
		}
	};

	const handleSetData = (id, value) => {
		var tempArray = conversationByTypeAllConvList
			.filter((x) => x.id === id)
			.map((x) => (x.showlenght = value));
	};

	const handleSetDataSent = (id, value) => {
		var tempArray = conversationByTypeSentList
			.filter((x) => x.id === id)
			.map((x) => (x.showlenght = value));
	};

	const handleSetDataUnread = (id, value) => {
		var tempArray = conversationByTypeUnreadList
			.filter((x) => x.id === id)
			.map((x) => (x.showlenght = value));
	};

	return (
		<React.Fragment>
			{pageNameType == "ConversationsOnly" && (
				<div className="conversations-page-section " ref={conversationSection}>
					<div>&nbsp;</div>
					<div className="col-md-12 mt-5">
						<h3 className="subheading"> {t("Conversations")}</h3>
					</div>
					{/* Tabs Start */}

					<div className="form__fields">
						<div className="prod__maindiv prodmarkup__secion permission__section">
							<div className="row">
								<div className="col-lg-9 col-md-9">
									<div className="col-md-3">
										<div className="input__block">
											<label> {t("Filter By Sales Rep")} </label>
											<Select
												placeholder={t("Select Sales Rep")}
												value={salesRepData.userId ? salesRepData.userId : null}
												name="userId"
												showSearch
												optionFilterProp="children"
												className="border__grey"
												onChange={(e) =>
													handleOnChangeConversation(e, "select", "userId")
												}
												filterOption={(input, option) =>
													option.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}>
												<Option value="">{t("Filter By Sales Rep")}</Option>
												{salesRepList &&
													salesRepList.map((item) => {
														return (
															<Option value={item.userId}>{item.name} </Option>
														);
													})}
											</Select>
										</div>
									</div>
									<div className="prodetail__section">
										<div className="prod__maindiv border-0 p-0">
											<div className="prodesc__div">
												<Tabs
													defaultActiveKey="all"
													id="uncontrolled-tab-example"
													className="tab__div mb-3 mt-4 pt-3"
													onSelect={onTabsChange}>
													<Tab
														eventKey="all"
														title={
															<>
																{t("All Conversations")} ({allTotal})
															</>
														}>
														<Spin spinning={allConvLoading}>
															<div className="custom__table mobile-height-auto">
																<table className="table table-striped">
																	<thead>
																		<tr>
																			<th
																				onClick={() =>
																					handleThClick("CreatedDate_")
																				}
																				style={{ width: "200px" }}>
																				{t("Date")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-50%" }}>
																						<div
																							className={
																								sortName == "FormatedDate Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "FormatedDate Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>

																			<th
																				onClick={() =>
																					handleThClick("messageetype")
																				}
																				style={{ width: "100px" }}>
																				{t("Type")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "messageetype Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "messageetype Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Type")}
																				style={{ width: "100px" }}>
																				{t("Status")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "Type Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Type Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("ActionTime")
																				}
																				style={{ width: "90px" }}>
																				{t("Time")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-16px" }}>
																						<div
																							className={
																								sortName == "ActionTime Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "ActionTime Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("DealerCompany")
																				}
																				style={{ width: "90px" }}>
																				{t("From")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "DealerCompany Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "DealerCompany Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("To")}
																				style={{ width: "90px" }}>
																				{t("To")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "To Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "To Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Message")}
																				style={{ width: "120px" }}>
																				{t("Message")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "Message Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Message Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th className="vendor__action">
																				{t("Action")}
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{conversationByTypeAllConvList.length ===
																			0 && (
																			<tr>
																				<td colspan="8">
																					<p className="table-no-data">
																						{t("No Data Found")}
																					</p>
																				</td>
																			</tr>
																		)}

																		{conversationDateList.length > 0 &&
																			conversationDateList.map((header) => {
																				return (
																					<React.Fragment>
																						<tr className="header__row">
																							<td
																								colspan="8"
																								className="header__block">
																								<p className="date__format">
																									{header}
																								</p>
																							</td>
																						</tr>
																						{conversationByTypeAllConvList
																							? conversationByTypeAllConvList
																									.filter(
																										(x) =>
																											x.formatedDate == header
																									)
																									.map((item) => {
																										return (
																											<tr
																												onClick={() =>
																													OnConversationRowClick(
																														item
																													)
																												}>
																												<td
																													style={{
																														width: 190,
																													}}></td>
																												<td>
																													{item.messageetype}
																												</td>
																												<td>
																													{item.messageetype ==
																													"Conversation" ? (
																														<Link
																															to={
																																"/retailerProfile"
																															}
																															onClick={() =>
																																checkIfUnread(
																																	item.type,
																																	item.retailerID,
																																	item.id
																																)
																															}
																															state={{
																																key: "Conversation",
																																id: item.retailerID.toString(),
																																messageId:
																																	item.id,
																															}}
																															className="icon__link">
																															{item.type ==
																															"UnRead" ? (
																																<img
																																	src={
																																		UnreadIcon
																																	}
																																/>
																															) : item.type ==
																															  "Read" ? (
																																<img
																																	src={ReadIcon}
																																/>
																															) : (
																																<img
																																	src={
																																		RepliedIcon
																																	}
																																/>
																															)}
																															<span>
																																{item.type}
																															</span>
																														</Link>
																													) : (
																														<Link
																															to={
																																"/retailerProfile"
																															}
																															onClick={() =>
																																checkIfUnread(
																																	item.type,
																																	item.retailerID,
																																	item.id
																																)
																															}
																															state={{
																																key: "Appointments",
																																id: item.retailerID.toString(),
																																messageId:
																																	item.id,
																															}}
																															className="icon__link">
																															{item.type ==
																															"UnRead" ? (
																																<img
																																	src={
																																		UnreadIcon
																																	}
																																/>
																															) : item.type ==
																															  "Read" ? (
																																<img
																																	src={ReadIcon}
																																	height="14"
																																	width="14"
																																/>
																															) : (
																																<img
																																	src={
																																		RepliedIcon
																																	}
																																/>
																															)}
																															<span>
																																{item.type}
																															</span>
																														</Link>
																													)}
																												</td>
																												<td>
																													{item.actionTime}
																												</td>
																												<td>
																													<Link
																														to={
																															"/retailerProfile"
																														}
																														state={{
																															key: "Profile",
																															id: item.retailerID.toString(),
																														}}
																														className="icon__link">
																														{item.dealerCompany}
																													</Link>
																												</td>
																												<td>{item.to}</td>

																												<td className="message__block">
																													{item.message &&
																													item.showlenght ==
																														true
																														? parse(
																																item.message
																																	.replaceAll(
																																		/<p>|<\/p>|<strong>|<\/strong>|<span>|<\/span>|<br>|&nbsp;|&nbsp|\n/g,
																																		" "
																																	)
																																	.slice(0, 10)
																														  ) + "..."
																														: item.showlenght ==
																														  "false"
																														? parse(
																																item.message
																														  )
																														: parse(
																																item.message
																														  )}
																												</td>

																												<td>
																													<div className="action__btns">
																														<div className="conversations-delete-sp">
																															{item.messageetype ==
																															"Conversation" ? (
																																<span
																																	onClick={() =>
																																		handleHeaderMenuClick(
																																			item
																																		)
																																	}
																																	className="linkText">
																																	{t("Reply")}
																																</span>
																															) : (
																																<span
																																	onClick={() =>
																																		handleHeaderMenuClick(
																																			item
																																		)
																																	}
																																	className="linkText">
																																	{t("Reply")}
																																</span>
																															)}
																														</div>
																														{(item.showlenght ==
																															true ||
																															item.showlenght ==
																																"false") && (
																															<>
																																<div className="conversations-delete-sp ml-1">
																																	{item.showlenght ==
																																		true && (
																																		<span
																																			onClick={() =>
																																				handleSetData(
																																					item.id,
																																					"false"
																																				)
																																			}
																																			className="linkText">
																																			More
																																		</span>
																																	)}
																																	{item.showlenght ==
																																		"false" && (
																																		<span
																																			onClick={() =>
																																				handleSetData(
																																					item.id,
																																					true
																																				)
																																			}
																																			className="linkText">
																																			Hide
																																		</span>
																																	)}
																																</div>
																															</>
																														)}

																														<div className="conversations-delete-sp ml-1">
																															<span
																																onClick={() => {
																																	handleDeleteModal(
																																		item
																																	);
																																}}>
																																{t("Delete")}
																															</span>
																														</div>
																													</div>
																												</td>
																											</tr>
																										);
																									})
																							: t("No Data")}
																					</React.Fragment>
																				);
																			})}
																	</tbody>
																</table>
															</div>
														</Spin>
													</Tab>
													<Tab
														eventKey="unread"
														title={
															<>
																{t("Unread")} {"("}{unreadTotal}{")"} 
															</>
														}
													>
														<Spin spinning={unreadConvLoading}>
															<div className="custom__table">
																<table className="table table-striped">
																	<thead>
																		<tr>
																			<th
																				onClick={() =>
																					handleThClick("CreatedDate_")
																				}
																				style={{ width: "140px" }}>
																				{t("Date")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-40%" }}>
																						<div
																							className={
																								sortName == "FormatedDate Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "FormatedDate Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>

																			<th
																				onClick={() =>
																					handleThClick("messageetype")
																				}
																				style={{ width: "100px" }}>
																				{t("Type")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "messageetype Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "messageetype Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Type")}
																				style={{ width: "100px" }}>
																				{t("Status")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "Type Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Type Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("ActionTime")
																				}
																				style={{ width: "90px" }}>
																				{t("Time")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-16px" }}>
																						<div
																							className={
																								sortName == "ActionTime Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "ActionTime Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("DealerCompany")
																				}
																				style={{ width: "90px" }}>
																				{t("From")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "DealerCompany Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "DealerCompany Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("To")}
																				style={{ width: "90px" }}>
																				{t("To")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "To Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "To Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Message")}
																				style={{ width: "120px" }}>
																				{t("Message")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "Message Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Message Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th className="vendor__action">Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		{conversationByTypeUnreadList.length ===
																			0 && (
																			<tr>
																				<td colspan="8">
																					<p className="table-no-data">
																						{t("No Data Found")}
																					</p>
																				</td>
																			</tr>
																		)}
																		{conversationDateListUnread.length > 0 &&
																			conversationDateListUnread.map(
																				(header) => {
																					return (
																						<React.Fragment>
																							<tr className="header__row">
																								<td
																									colspan="8"
																									className="header__block">
																									<p className="date__format">
																										{header}
																									</p>
																								</td>
																							</tr>
																							{conversationByTypeUnreadList
																								? conversationByTypeUnreadList
																										.filter(
																											(x) =>
																												x.formatedDate == header
																										)
																										.map((item) => {
																											return (
																												<tr
																													onClick={() =>
																														OnConversationRowClick(
																															item
																														)
																													}>
																													<td></td>

																													<td>
																														{item.messageetype}
																													</td>
																													<td>
																														{item.messageetype ==
																														"Conversation" ? (
																															<Link
																																to={
																																	"/retailerProfile"
																																}
																																onClick={() =>
																																	checkIfUnread(
																																		item.type,
																																		item.retailerID,
																																		item.id
																																	)
																																}
																																state={{
																																	key: "Conversation",
																																	id: item.retailerID.toString(),
																																	messageId:
																																		item.id,
																																}}
																																className="icon__link">
																																{item.type ==
																																"UnRead" ? (
																																	<img
																																		src={
																																			UnreadIcon
																																		}
																																	/>
																																) : item.type ==
																																  "Read" ? (
																																	<img
																																		src={
																																			ReadIcon
																																		}
																																	/>
																																) : (
																																	<img
																																		src={
																																			RepliedIcon
																																		}
																																	/>
																																)}
																																<span>
																																	{item.type}
																																</span>
																															</Link>
																														) : (
																															<Link
																																to={
																																	"/retailerProfile"
																																}
																																onClick={() =>
																																	checkIfUnread(
																																		item.type,
																																		item.retailerID,
																																		item.id
																																	)
																																}
																																state={{
																																	key: "Appointments",
																																	id: item.retailerID.toString(),
																																	messageId:
																																		item.id,
																																}}
																																className="icon__link">
																																{item.type ==
																																"UnRead" ? (
																																	<img
																																		src={
																																			UnreadIcon
																																		}
																																	/>
																																) : item.type ==
																																  "Read" ? (
																																	<img
																																		src={
																																			ReadIcon
																																		}
																																	/>
																																) : (
																																	<img
																																		src={
																																			RepliedIcon
																																		}
																																	/>
																																)}
																																<span>
																																	{item.type}
																																</span>
																															</Link>
																														)}
																													</td>
																													<td>
																														{item.actionTime}
																													</td>
																													<td>
																														<Link
																															to={
																																"/retailerProfile"
																															}
																															state={{
																																key: "Profile",
																																id: item.retailerID.toString(),
																															}}>
																															{
																																item.dealerCompany
																															}
																														</Link>
																													</td>
																													<td>{item.to}</td>
																													<td className="message__block">
																														{item.message &&
																														item.showlenght ==
																															true
																															? parse(
																																	item.message
																																		.replaceAll(
																																			/<p>|<\/p>|<br>|&nbsp;|&nbsp|\n/g,
																																			" "
																																		)
																																		.slice(
																																			0,
																																			10
																																		)
																															  ) + "..."
																															: item.showlenght ==
																															  "false"
																															? parse(
																																	item.message
																															  )
																															: parse(
																																	item.message
																															  )}
																													</td>
																													<td>
																														<div className="action__btns">
																															<div className="conversations-delete-sp">
																																{item.messageetype ==
																																"Conversation" ? (
																																	<span
																																		onClick={() =>
																																			handleHeaderMenuClick(
																																				item
																																			)
																																		}
																																		className="linkText">
																																		{t("Reply")}
																																	</span>
																																) : (
																																	<span
																																		onClick={() =>
																																			handleHeaderMenuClick(
																																				item
																																			)
																																		}
																																		className="linkText">
																																		{t("Reply")}
																																	</span>
																																)}
																															</div>
																															{(item.showlenght ==
																																true ||
																																item.showlenght ==
																																	"false") && (
																																<>
																																	<div className="conversations-delete-sp ml-1">
																																		{item.showlenght ==
																																			true && (
																																			<span
																																				onClick={() =>
																																					handleSetDataUnread(
																																						item.id,
																																						"false"
																																					)
																																				}
																																				className="linkText">
																																				More
																																			</span>
																																		)}
																																		{item.showlenght ==
																																			"false" && (
																																			<span
																																				onClick={() =>
																																					handleSetDataUnread(
																																						item.id,
																																						true
																																					)
																																				}
																																				className="linkText">
																																				Hide
																																			</span>
																																		)}
																																	</div>
																																</>
																															)}

																															<div className="conversations-delete-sp ml-2">
																																<span
																																	onClick={() => {
																																		handleDeleteModal(
																																			item
																																		);
																																	}}>
																																	{t("Delete")}
																																</span>
																															</div>
																														</div>
																													</td>
																												</tr>
																											);
																										})
																								: t("No Data")}
																						</React.Fragment>
																					);
																				}
																			)}
																	</tbody>
																</table>
															</div>
														</Spin>
													</Tab>
													<Tab eventKey="sent"
													 
													 title={
														<>
															{t("Sent")} ({sentTotal})
														</>
													}>
														<Spin spinning={sentConvLoading}>
															<div className="custom__table">
																<table className="table table-striped ">
																	<thead>
																		<tr>
																			<th
																				onClick={() =>
																					handleThClick("FormatedDate")
																				}
																				style={{ width: "140px" }}>
																				{t("Date")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-50%" }}>
																						<div
																							className={
																								sortName == "FormatedDate Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "FormatedDate Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>

																			<th
																				onClick={() =>
																					handleThClick("messageetype")
																				}
																				style={{ width: "100px" }}>
																				{t("Type")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "messageetype Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "messageetype Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Type")}
																				style={{ width: "120px" }}>
																				{t("Status")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-20%" }}>
																						<div
																							className={
																								sortName == "Type Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Type Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("ActionTime")
																				}
																				style={{ width: "90px" }}>
																				{t("Time")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-16px" }}>
																						<div
																							className={
																								sortName == "ActionTime Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "ActionTime Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() =>
																					handleThClick("DealerCompany")
																				}
																				style={{ width: "90px" }}>
																				{t("From")}
																				<div className="date__sort">
																					<div
																						className="date__child"
																						style={{ right: "-35%" }}>
																						<div
																							className={
																								sortName == "DealerCompany Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "DealerCompany Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("To")}
																				style={{ width: "90px" }}>
																				{t("To")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "To Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "To Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th
																				onClick={() => handleThClick("Message")}
																				style={{ width: "120px" }}>
																				{t("Message")}
																				<div className="date__sort">
																					<div className="date__child">
																						<div
																							className={
																								sortName == "Message Asc"
																									? "up-arrow-active"
																									: "up-arrow"
																							}></div>
																						<div
																							className={
																								sortName == "Message Desc"
																									? "down-arrow-active"
																									: "down-arrow"
																							}></div>
																					</div>
																				</div>
																			</th>
																			<th className="vendor__action">
																				{t("Action")}
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{conversationByTypeSentList.length ===
																			0 && (
																			<tr>
																				<td colspan="8">
																					<p className="table-no-data">
																						{t("No Data Found")}
																					</p>
																				</td>
																			</tr>
																		)}
																		{conversationDateListSent.length > 0 &&
																			conversationDateListSent.map((header) => {
																				return (
																					<React.Fragment>
																						<tr className="header__row">
																							<td
																								colspan="8"
																								className="header__block">
																								<p className="date__format">
																									{header
																										? moment(header).format(
																												"dddd, MMMM DD, YYYY"
																										  )
																										: ""}
																								</p>
																							</td>
																						</tr>
																						{conversationByTypeSentList.length >
																						0
																							? conversationByTypeSentList
																									.filter(
																										(x) =>
																											x.formatedDate == header
																									)
																									.map((item) => {
																										return (
																											<tr
																												onClick={() =>
																													OnConversationRowClick(
																														item
																													)
																												}>
																												<td></td>

																												<td>
																													{item.messageetype}
																												</td>
																												<td>
																													{item.messageetype ==
																													"Conversation" ? (
																														<Link
																															to={
																																"/retailerProfile"
																															}
																															onClick={() =>
																																checkIfUnread(
																																	item.type,
																																	item.retailerID,
																																	item.id
																																)
																															}
																															state={{
																																key: "Conversation",
																																id: item.retailerID.toString(),
																																messageId:
																																	item.id,
																															}}
																															className="icon__link">
																															{item.type ==
																															"UnRead" ? (
																																<img
																																	src={
																																		UnreadIcon
																																	}
																																/>
																															) : item.type ==
																															  "Read" ? (
																																<img
																																	src={ReadIcon}
																																/>
																															) : (
																																<img
																																	src={
																																		RepliedIcon
																																	}
																																/>
																															)}
																															<span>
																																{item.type}
																															</span>
																														</Link>
																													) : (
																														<Link
																															to={
																																"/retailerProfile"
																															}
																															onClick={() =>
																																checkIfUnread(
																																	item.type,
																																	item.retailerID,
																																	item.id
																																)
																															}
																															state={{
																																key: "Appointments",
																																id: item.retailerID.toString(),
																																messageId:
																																	item.id,
																															}}
																															className="icon__link">
																															{item.type ==
																															"UnRead" ? (
																																<img
																																	src={
																																		UnreadIcon
																																	}
																																/>
																															) : item.type ==
																															  "Read" ? (
																																<img
																																	src={ReadIcon}
																																/>
																															) : (
																																<img
																																	src={
																																		RepliedIcon
																																	}
																																/>
																															)}
																															<span>
																																{item.type}
																															</span>
																														</Link>
																													)}
																												</td>
																												<td>
																													{item.actionTime}
																												</td>
																												<td>
																													<Link
																														to={
																															"/retailerProfile"
																														}
																														state={{
																															key: "Profile",
																															id: item.retailerID.toString(),
																														}}
																														className="icon__link">
																														{item.dealerCompany}
																													</Link>
																												</td>
																												<td>{item.to}</td>

																												<td className="message__block">
																													{item.message &&
																													item.showlenght ==
																														true
																														? parse(
																																item.message
																																	.replaceAll(
																																		/<p>|<\/p>|<br>|&nbsp;|&nbsp|\n/g,
																																		" "
																																	)
																																	.slice(0, 10)
																														  ) + "..."
																														: item.showlenght ==
																														  "false"
																														? parse(
																																item.message
																														  )
																														: parse(
																																item.message
																														  )}
																												</td>
																												<td>
																													<div className="action__btns">
																														<div className="conversations-delete-sp">
																															{item.messageetype ==
																															"Conversation" ? (
																																<span
																																	onClick={() =>
																																		handleHeaderMenuClick(
																																			item
																																		)
																																	}
																																	className="linkText">
																																	{t("Reply")}
																																</span>
																															) : (
																																<span
																																	onClick={() =>
																																		handleHeaderMenuClick(
																																			item
																																		)
																																	}
																																	className="linkText">
																																	{t("Reply")}
																																</span>
																															)}
																														</div>

																														{(item.showlenght ==
																															true ||
																															item.showlenght ==
																																"false") && (
																															<>
																																<div className="conversations-delete-sp ml-1">
																																	{item.showlenght ==
																																		true && (
																																		<span
																																			onClick={() =>
																																				handleSetDataSent(
																																					item.id,
																																					"false"
																																				)
																																			}
																																			className="linkText">
																																			More
																																		</span>
																																	)}
																																	{item.showlenght ==
																																		"false" && (
																																		<span
																																			onClick={() =>
																																				handleSetDataSent(
																																					item.id,
																																					true
																																				)
																																			}
																																			className="linkText">
																																			Hide
																																		</span>
																																	)}
																																</div>
																															</>
																														)}

																														<div className="conversations-delete-sp ml-2">
																															<span
																																onClick={() => {
																																	handleDeleteModal(
																																		item
																																	);
																																}}>
																																{t("Delete")}
																															</span>
																														</div>
																													</div>
																												</td>
																											</tr>
																										);
																									})
																							: t("No Data")}
																					</React.Fragment>
																				);
																			})}
																	</tbody>
																</table>
															</div>
														</Spin>
													</Tab>
												</Tabs>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-3">
									<Spin spinning={loadingprofileData}>
										{conversationByTypeAllConvList.length > 0 ? (
											<div className="right__section">
												<div className="profile__block">
													<span href="#" className="retailer__name">
														{rowDetails.dealerCompany
															? rowDetails.dealerCompany
															: null}
													</span>
													<Link
														to={"/retailerProfile"}
														state={{
															key: "Profile",
															id: selectedColumnId,
														}}>
														<div className="logoImg__block p-0">
															<img src={rowDetails.companylogo} />
														</div>
													</Link>
													<p className="activeFrom">
														{t("Active")} |{" "}
														{rowDetails.activity ? rowDetails.activity : null}
													</p>
												</div>

												<div className="links__block">
													<ul>
														<li>
															<span
																onClick={() =>
																	handleHeaderMenuClickForSideProfile("Profile")
																}
																className="linkText">
																<i
																	class="fa fa-address-book-o"
																	aria-hidden="true"></i>{" "}
																{t("View Profile")}
															</span>
														</li>
														<li>
															<span
																onClick={() =>
																	handleHeaderMenuClickForSideProfile(
																		"Conversation"
																	)
																}
																className="linkText">
																<i
																	class="fa fa-comments-o"
																	aria-hidden="true"></i>{" "}
																{t("Conversation")}
															</span>
														</li>
														<li>
															<span
																onClick={() =>
																	handleHeaderMenuClickForSideProfile(
																		"Appointments"
																	)
																}
																className="linkText">
																<i
																	class="fa fa-calendar-check-o"
																	aria-hidden="true"></i>{" "}
																{t("Make Appointment")}
															</span>
														</li>
														<li>
															<span
																onClick={() =>
																	handleHeaderMenuClickForSideProfile("Notes")
																}
																className="linkText">
																<i
																	class="fa fa-pencil-square-o"
																	aria-hidden="true"></i>{" "}
																{t("Store Notes")}
															</span>
														</li>
													</ul>
													<hr />
												</div>

												<div className="bottom__block">
													<div className="input__block">
														<label> {t("Sales Rep")} </label>
														<Select
															placeholder={t("Select Sales Rep")}
															value={SalesRepId ? SalesRepId : null}
															name="userId"
															showSearch
															optionFilterProp="children"
															onChange={(e) =>
																handleOnSelectChange(e, "select", "userId")
															}
															onSearch={onSearch}
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >= 0
															}>
															<Option>Sales Rep</Option>
															{SalesRep.map((item) => {
																return (
																	<Option value={item.userId}>
																		{item.name}{" "}
																	</Option>
																);
															})}
														</Select>
													</div>
													{rowDetails.showBtn == true ? (
														<div className="input__block">
															<label> {t("Disconnect Retailer")} </label>
															<Switch
																checkedChildren={t("Yes")}
																unCheckedChildren={t("No")}
																defaultChecked
																name="disconnectSwitch"
																checked={disconnectSwitch}
																onChange={handleSwitch}
															/>
														</div>
													) : null}
												</div>
												<div className="retailer__dtls mb-4">
													<p>
														{rowDetails.dealerName
															? rowDetails.dealerName
															: null}
													</p>
													<a href={`mailto:${rowDetails.dealerEmail}`}>
														{rowDetails.dealerEmail
															? rowDetails.dealerEmail
															: null}
													</a>
													<p className="mt-2">
														{rowDetails.dealerPhone
															? rowDetails.dealerPhone
															: null}
													</p>
													<Link
														target="_blank"
														to={`//${rowDetails.websiteAddress}`}>
														{" "}
														{rowDetails.websiteAddress
															? rowDetails.websiteAddress
															: null}
													</Link>

													<hr />
												</div>
											</div>
										) : (
											<div className="right__section">
												<p className="no_data_found">{t("No Data Found")}</p>
											</div>
										)}
									</Spin>
								</div>
							</div>
						</div>
					</div>
					<Modal
						className="delete_conv_modal"
						centered
						visible={visible2}
						onOk={() => setVisible2(false)}
						onCancel={() => setVisible2(false)}
						width={400}
						footer={[
							<Button key="back" onClick={handleCancel2}>
								{t("Cancel")}
							</Button>,
							<Button
								key="submit"
								type="primary"
								onClick={handleDeleteComments}>
								{t("Delete")}
							</Button>,
						]}>
						<div className="col-lg-12">
							<div className="modal__body">
								<div className="col-lg-12">
									<div className="form__fields border-0 p-0">
										<div className="row">
											<div className="col-lg-12 text-center">
												<p className="subheading">
													{t("Are you sure you want to delete this Comments?")}
												</p>
												<p>{t("THIS CANNOT BE UNDONE")}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{/* Tabs End */}
				</div>
			)}
		</React.Fragment>
	);
};

export default ConversationsDashboard;
