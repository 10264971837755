import React, { useState, useEffect, useRef, Fragment } from "react";
import {
	Input,
	Modal,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Switch,
	Tooltip,
	Table,
	Radio,
	Spin,
	Upload,
} from "antd";
import { Accordion, Container } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import Noun_arrow from "../../assets/images/noun_arrow.png";
import Rectangle from "../../assets/images/Rectangle.png";
import userIcon from "../../assets/images/user-icon.png";
import DaimondImg from "../../assets/images/Round.jpg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import catalogJewelryService from "../../services/catalog-jewelry.service";
import { SizingData } from "../../helper/commanData";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import previewImg from "../../assets/images/prod__img.jpg";
import { Label } from "rc-easyui";
import {
	setOpenKeyHeaderMenuData,
	setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";

const { Option } = Select;
const AddProducts = () => {
	const { t } = useTranslation();
	// const initialState = { CheckBoxlist: {} };
	// const [state, setState] = useState(initialState);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [visible6, setVisible6] = useState(false);
	const [catlogData, setCatlogData] = useState({
		productName: "",
		retailDescription: "",
		retailPrice: "",
		style: "",
		dealerStock: "",
		collection: [],
		materialType: "",
		visibility: true,
		materialColor: "",
		Period: "",
		CheckBoxlist: [],
		file: null,
		imageCatlog: "",
		imageUrl: "",
		productType: 0,
	});
	const [validations, setValidations] = useState({
		productNameVal: "",
		materialTypeVal: "",
		retailDescriptionVal: "",
		retailPriceVal: "",
		StyleNoVal: "",
		EssentialTabVal: "",
		ImageVal: "",
		CheckBoxVal: "",
		MediaVal: "",
		ImageMediaVal: "",
		VideoMediaVal: "",
		ZipFileVal: "",
	});
	const [additionalData, setAdditionalData] = useState({
		QtyOnHand: "",
		DisplayOrder: "",
		Gender: "",
		Setting: "",
		Status: "",
		FinishingTechnique: "",
		ClassType: "",
		ChainType: "",
		BackFinding: "",
		AdditionalInformation: "",
		AdditionalInformation2: "",
	});
	const [sizingData, setSizingData] = useState({
		Weight: "",
		WeightType: "grams",
		Thickness: "",
		ThicknessType: "mm",
		Width: "",
		WidthType: "mm",
		Dimensions: "",
		UnitOfMeasure: "",
		StockFingerSize: "",
		MinFingerSize: "",
		MaxFingerSize: "",
		FingerSizeIncrement: "",
	});
	const [pricingData, setPriceingData] = useState({
		PriceMethod: "",
		PriceMethodInput: "",
		WholesalePriceMethod: "",
		WholesaleBasePrice: "",
		BaseMetalMarket: "",
		MetalType: "",
		PriceFactor: "",
		RelationalPrice: "",
		RelationalMetalMarket: "",
		MetalLaborCode: "",
		OtherLaborCode: "",
		MetalFactorCode: "",

		RetailPriceMethod: "",
		MSRP: "",
		PriceType: "",
	});
	const [relatedData, setRelatedData] = useState({
		MatchingStyles: "",
		UpSellStyles: "",
		GroupedProductStyles: "",
	});
	const [mediaData, setMediaData] = useState({
		Label: "",
		loadImageFromURL: "",
		loadVideoFromURL: "",
		AddVideoType: "",
		VideoFile: null,
		imageFile: [],
		UploadImages: [],
		UploadLabel: [],
		ImageIds: "",
		zipFile: null,
		zipFileName: "",
		EditAdditionalImages: [],
	});
	const [detailsdData, setDetailsdData] = useState({
		BulletPoint1: "",
		BulletPoint2: "",
		BulletPoint3: "",
		BulletPoint4: "",
		BulletPoint5: "",
		DiscountA: "",
		Qty1: "",
		Qty2: "",
		Qty3: "",
		Qty4: "",
		Qty5: "",
		RetailerBrandName: "",
		VendorName: "",
		SecondaryMetalType: "",
		aprod: false,
	});
	const [gemstoneData, setGemstoneData] = useState([
		{
			GemstoneID: 0,
			TotalDiamondWeight: "",
			TotalGemstoneWeight: "",
			GemstoneType: "",
			DimensionUnitOfMeasure: "",
			NoOfGemstones: "",
			GemstoneShape: "",
			GemstoneOrigin: "",
			GemstoneCaratWeight: "",
			GemstoneDimensions: "",
			GemstoneQuality: "",
			StoneCreationMethod: "",
			GemstoneLotCode: "",
			GemstoneSettingLaborCode: "",
			StoneTreatementMethod: "",
			GemStoneAdditionalInformation: "",
		},
	]);

	const [watchData, setWatchData] = useState({
		BandMaterial: "",
		NumberType: "",
		BandType: "",
		Size: "",
		CaseMaterial: "",
		Type: "",
		CaseShape: "",
		ComesPackagedIn: "",
		CrystalType: "",
		Warranty: "",
		Bezel: "",
		WatchCondition: "",
		DialColor: "",
		ManufactureDate: "",
		DisplayType: "",
		Certification: "",
		Movement: "",
		Energy: "",
	});
	const [selectedTab, setSelectedTab] = useState("essential");
	const [counter, setCounter] = useState(0);
	const [checkBool, setCheckBool] = useState(null);
	const [mainCheckVal, setMainCheckVal] = useState(false);
	const [isStyleNo, setIsStyleNo] = useState(true);

	useEffect(() => {}, [counter]);

	/// Dropdown Lists
	const [gemStoneTypeList, setGemStoneTypeList] = useState([]);
	const [gemStoneShapeList, setGemStoneShapeList] = useState([]);
	const [metalTypeList, setMetalTypeList] = useState([]);
	const [genderList, setGenderList] = useState([]);
	const [settingList, setSettingList] = useState([]);
	const [deliveryList, setDeliveryList] = useState([]);
	const [collectionList, setCollectionList] = useState([]);
	const [jewelryMetalTypeList, setJewelryMetalTypeList] = useState([]);
	const [jewelryMetalColorList, setJewelryMetalColorList] = useState([]);
	const [watchBandMaterialList, setWatchBandMaterialList] = useState([]);
	const [bandTypeList, setBandTypeList] = useState([]);
	const [caseMaterialList, setCaseMaterialList] = useState([]);
	const [caseShapeList, setCaseShapeList] = useState([]);
	const [crystalTypeList, setCrystalTypeList] = useState([]);
	const [watchDialColorList, setWatchDialColorList] = useState([]);
	const [displayTypeList, setDisplayTypeList] = useState([]);
	const [watchMovementList, setWatchMovementList] = useState([]);
	const [watchNumberTypeList, setWatchNumberTypeList] = useState([]);
	const [watchSizeList, setWatchSizeList] = useState([]);
	const [watchTypeList, setWatchTypeList] = useState([]);
	const [watchEnergyList, setWatchEnergyList] = useState([]);
	const [jewelryAttributeList, setJewelryAttributeList] = useState([]);
	const [HideShowTable, setHideShowTable] = useState(true);

	const [sizingDataTable, setSizingDataTable] = useState(SizingData);

	const [loading, setLoading] = useState(false);
	const [CategoryLoading, setCategoryLoading] = useState(false);
	const [data, setData] = useState([]);

	const [dealerId, setDealerId] = useState();
	const [dealerCompany, setdealerCompany] = useState("");
	const loginDetials = useSelector((state) => state.loginReducer);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [jewelryCategoryList, setjewelryCategoryList] = useState([]);
	const [selectSizingRow, setSelectSizingRow] = useState([]);
	const [isedit, setIsEdit] = useState(false);
	const [gfInventoryIDId, setGfInventoryID] = useState("");
	const [username, setUsername] = useState("");
	const [editloading, setEditLoading] = useState(false);
	const [vendorStatusID, setVendorStatusID] = useState(0);
	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					handleGetEssentialTabData(
						loginDetials.loginDetials.responseData.dealerId
					);
					setVendorStatusID(
						loginDetials?.loginDetials?.responseData?.vendorStatusID || 0
					);
					setUsername(loginDetials.loginDetials.responseData.dealerName);
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
				}
				handleGetAdditionalToGemStoneTabData();
				handleGetWatchToAttributeListTabData();
				GetProductCategoryList();
			}
		}
	}, []);

	const handleHideShowTable = () => {
		setHideShowTable(!HideShowTable);
	};

	const location = useLocation();
	useEffect(() => {
		if (location.state) {
			if (location.state.key) {
				setGfInventoryID(location.state.key);
				handleGetProductDetailsByID(location.state.key);
			}
		}
	}, []);

	const handleOnOk = () => {
		setVisible6(false);
		var MenuData = [`${"ProductsJewelry"}`, `${"Products"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["myProducts"]));
		navigate("/myProducts");
	};

	const handleGetProductDetailsByID = async (id) => {
		var inputData = {
			gfInventoryID: id,
		};
		try {
			setEditLoading(true);
			await catalogJewelryService
				.GetProductDetailsByID(inputData)
				.then((response) => {
					var responseData = response.data.responseData;
					var message = response.data.message;
					if (message === "Success") {
						setIsEdit(true);

						setHasImage(responseData.hasImage);
						handleGetEssentialTabData(
							loginDetials.loginDetials.responseData.dealerId
						);
						setCatlogData({
							productName: responseData.productName,
							retailDescription: responseData.productDescription,
							retailPrice: responseData.retailPrice.toString(),
							style: responseData.styleNumber,
							dealerStock: responseData.dealerStockNumber,
							collection: responseData.collection
								? responseData.collection.split(",")
								: [],
							materialType: responseData.metalTypeID,
							visibility: responseData.visibleAs,
							materialColor:
								responseData.metalColorID == 0
									? ""
									: responseData.metalColorID.toString(),
							Period: responseData.periodID,
							CheckBoxlist: [],
							imageCatlog: responseData.imagePath,
							productType: responseData.productType,
						});
						setAdditionalData({
							QtyOnHand:
								responseData.quantity == 0 ? "" : responseData.quantity,
							DisplayOrder:
								responseData.displayOrder == 0 ? "" : responseData.displayOrder,
							Gender: responseData.genderID,
							Setting: responseData.setting,
							Status:
								responseData.statusID == 0
									? ""
									: responseData.statusID.toString(),
							FinishingTechnique: responseData.finishingTechnique,
							ClassType: responseData.claspType,
							ChainType: responseData.chainType,
							BackFinding: responseData.backFinding,
							AdditionalInformation: responseData.additionalInformation,
							AdditionalInformation2: responseData.additionalInformation2,
						});
						setSizingData({
							Weight: responseData.weight_gm == 0 ? "" : responseData.weight_gm,
							WeightType: responseData.weightUnit,
							Thickness:
								responseData.thickness_mm == 0 ? "" : responseData.thickness_mm,
							ThicknessType: responseData.thicknessUnit,
							Width: responseData.width_mm == null ? "" : responseData.width_mm,
							WidthType: responseData.widthUnit,
							Dimensions: responseData.dimensions,
							UnitOfMeasure: responseData.dimensionUnitOfMeasure,
							StockFingerSize: responseData.fingerSize,
							MinFingerSize: responseData.fingerSizeMinRange,
							MaxFingerSize: responseData.fingerSizeMaxRange,
							FingerSizeIncrement: responseData.fingerSizeIncrement,
						});
						setPriceingData({
							PriceMethod:
								responseData.wholesalePriceMethod !== 0
									? responseData.wholesalePriceMethod.toString()
									: "",
							PriceMethodInput:
								responseData.wholesalePriceDetails == 0
									? ""
									: responseData.wholesalePriceDetails.toString(),
						});
						setRelatedData({
							MatchingStyles: responseData.matchingSKUs,
							UpSellStyles: responseData.upSellSKUs,
							GroupedProductStyles: responseData.groupedProductSKUs,
						});
						setMediaData({
							Label: "",
							loadImageFromURL: "",
							loadVideoFromURL: responseData.videoURL,
							AddVideoType: responseData.videoType,
							VideoFile: null,
							imageFile: [],
							UploadImages: [],
							UploadLabel: [],
							zipFile: null,
							zipFileName: "",
							EditAdditionalImages:
								responseData.jewelrysAdditionalImages != null
									? responseData.jewelrysAdditionalImages
									: [],
						});
						setDetailsdData({
							BulletPoint1: responseData.bulletPoint1,
							BulletPoint2: responseData.bulletPoint2,
							BulletPoint3: responseData.bulletPoint3,
							BulletPoint4: responseData.bulletPoint4,
							BulletPoint5: responseData.bulletPoint5,
							DiscountA:
								responseData.discountA == 0
									? ""
									: responseData.discountA.toString(),
							Qty1: responseData.qty1 == 0 ? "" : responseData.qty1,
							Qty2: responseData.qty2 == 0 ? "" : responseData.qty2,
							Qty3: responseData.qty3 == 0 ? "" : responseData.qty3,
							Qty4: responseData.qty4 == 0 ? "" : responseData.qty4,
							Qty5: responseData.qty5 == 0 ? "" : responseData.qty5,
							RetailerBrandName: responseData.retailerBrandName,
							VendorName: responseData.vendorName,
							SecondaryMetalType: responseData.secondaryMetalType,
							aprod: responseData.amazonProduct,
						});
						if (responseData.gemStoneResponse == null) {
						} else {
							var arr = [];
							for (var i = 0; i < responseData.gemStoneResponse.length; i++) {
								var obj = {
									GemstoneID: responseData.gemStoneResponse[i].gemstoneID,
									TotalDiamondWeight:
										responseData.gemStoneResponse[i].totalDiamondWeight,
									TotalGemstoneWeight:
										responseData.gemStoneResponse[i].totalGemstoneWeight,
									GemstoneType: responseData.gemStoneResponse[i].gemstoneType,
									DimensionUnitOfMeasure:
										responseData.gemStoneResponse[i].dimensionUnitOfMeasure,
									NoOfGemstones:
										responseData.gemStoneResponse[i].noOfGemstones == 0
											? ""
											: responseData.gemStoneResponse[i].noOfGemstones,
									GemstoneShape: responseData.gemStoneResponse[i].gemstoneShape,
									GemstoneOrigin:
										responseData.gemStoneResponse[i].gemstoneOrigin,
									GemstoneCaratWeight:
										responseData.gemStoneResponse[i].gemstoneCaratWeight == 0
											? ""
											: responseData.gemStoneResponse[i].noOfGemstones,
									GemstoneDimensions:
										responseData.gemStoneResponse[i].gemstoneDimensions,
									GemstoneQuality:
										responseData.gemStoneResponse[i].gemstoneQuality,
									StoneCreationMethod:
										responseData.gemStoneResponse[i].stoneCreationMethod,
									GemstoneLotCode:
										responseData.gemStoneResponse[i].gemstoneLotCode,
									GemstoneSettingLaborCode:
										responseData.gemStoneResponse[i].gemstoneSettingLaborCode,
									StoneTreatementMethod:
										responseData.gemStoneResponse[i].stoneTreatementMethod,
									GemStoneAdditionalInformation:
										responseData.gemStoneResponse[i]
											.gemStoneAdditionalInformation,
								};
								arr.push(obj);
							}
							setGemstoneData(arr);
						}
						setHideShowTable(false);
						if (responseData.fingerSizeList == null) {
						} else {
							var FingerSizetypeList = responseData.fingerSizeList;
							// for (i = 0; i < FingerSizetypeList.length; i++) {
							//   const temp = sizingDataTable.map((x) => {
							//     if (x.size == FingerSizetypeList[i].size) {
							//       return {
							//         ...x,
							//         ischecked: true,
							//         ID: FingerSizetypeList[i].id,
							//         priceType: FingerSizetypeList[i].priceType,
							//         additionalPrice: FingerSizetypeList[i].additionalPrice,
							//       };
							//     } else return x;
							//   });
							//   setSizingDataTable(temp);
							// }
							setSizingDataTable(FingerSizetypeList);
						}
						GetProductEditCategoryList(responseData.jewelryCategory_Mapping);
						setEditLoading(false);
					} else {
						setCatlogData({
							productName: "",
							retailDescription: "",
							retailPrice: "",
							style: "",
							dealerStock: "",
							collection: [],
							materialType: "",
							visibility: true,
							materialColor: "",
							Period: "",
							CheckBoxlist: [],
							file: null,
							imageCatlog: "",
							imageUrl: "",
						});
						setAdditionalData({
							QtyOnHand: "",
							DisplayOrder: "",
							Gender: "",
							Setting: "",
							Status: "",
							FinishingTechnique: "",
							ClassType: "",
							ChainType: "",
							BackFinding: "",
							AdditionalInformation: "",
							AdditionalInformation2: "",
						});
						setSizingData(SizingData);
						setPriceingData({
							PriceMethod: "",
							PriceMethodInput: "",
							WholesalePriceMethod: "",
							WholesaleBasePrice: "",
							BaseMetalMarket: "",
							MetalType: "",
							PriceFactor: "",
							RelationalPrice: "",
							RelationalMetalMarket: "",
							MetalLaborCode: "",
							OtherLaborCode: "",
							MetalFactorCode: "",

							RetailPriceMethod: "",
							MSRP: "",
							PriceType: "",
						});
						setRelatedData({
							MatchingStyles: "",
							UpSellStyles: "",
							GroupedProductStyles: "",
						});
						setMediaData({
							Label: "",
							loadImageFromURL: "",
							loadVideoFromURL: "",
							AddVideoType: "",
							VideoFile: null,
							imageFile: [],
							UploadImages: [],
							UploadLabel: [],
							zipFile: null,
							zipFileName: "",
						});
						setDetailsdData({
							BulletPoint1: "",
							BulletPoint2: "",
							BulletPoint3: "",
							BulletPoint4: "",
							BulletPoint5: "",
							DiscountA: "",
							Qty1: "",
							Qty2: "",
							Qty3: "",
							Qty4: "",
							Qty5: "",
							RetailerBrandName: "",
							VendorName: "",
							SecondaryMetalType: "",
							aprod: false,
						});
						setGemstoneData([
							{
								TotalDiamondWeight: "",
								TotalGemstoneWeight: "",
								GemstoneType: "",
								DimensionUnitOfMeasure: "",
								NoOfGemstones: "",
								GemstoneShape: "",
								GemstoneOrigin: "",
								GemstoneCaratWeight: "",
								GemstoneDimensions: "",
								GemstoneQuality: "",
								StoneCreationMethod: "",
								GemstoneLotCode: "",
								GemstoneSettingLaborCode: "",
								StoneTreatementMethod: "",
								GemStoneAdditionalInformation: "",
							},
						]);
						// setWatchData({});
						setEditLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setEditLoading(false);
				});
		} catch (error) {
			console.log(error);
			setEditLoading(false);
		}
	};
	const GetProductCategoryList = () => {
		try {
			setLoading(true);
			catalogJewelryService
				.GetProductCategoryList()
				.then((response) => {
					var responseData = response.data.responseData;
					var message = response.data.message;
					setLoading(false);
					if (message === "Success") {
						var tempcommVendorData = responseData.jewelryCategoryList;
						tempcommVendorData.forEach((element) => {
							element.isChecked = false;
						});
						setjewelryCategoryList(tempcommVendorData);
					} else {
						setjewelryCategoryList([]);
						setLoading(false);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const GetProductEditCategoryList = async (row) => {
		try {
			setLoading(true);
			await catalogJewelryService
				.GetProductCategoryList()
				.then((response) => {
					var responseData = response.data.responseData;
					var message = response.data.message;
					setLoading(false);
					if (message === "Success") {
						var tempcommVendorData = responseData.jewelryCategoryList;
						const temp = tempcommVendorData.map((x) => {
							return { ...x, isChecked: false };
						});
						temp.forEach((element) => {
							if (
								row.filter((x) => x.categoryID == element.jewelryCategoryID)
									.length > 0
							) {
								element.isChecked = true;
							} else {
								element.isChecked = false;
							}
						});
						setjewelryCategoryList(temp);
						setLoading(false);
					} else {
						setjewelryCategoryList([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleGetWatchToAttributeListTabData = () => {
		var inputData = {
			dealerID: dealerId,
		};
		try {
			catalogJewelryService
				.GetWatchToAttributeListTabData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var WatchBandMaterialList =
						response.data.responseData.getWatchBandMaterialList;
					var BandTypeList = response.data.responseData.getBandTypeList;
					var CaseMaterialList = response.data.responseData.getCaseMaterialList;
					var CaseShapeList = response.data.responseData.getCaseShapeList;
					var CrystalTypeList = response.data.responseData.getCrystalTypeList;
					var WatchDialColorList =
						response.data.responseData.getWatchDialColorList;
					var DisplayTypeList = response.data.responseData.getDisplayTypeList;
					var WatchMovementList =
						response.data.responseData.getWatchMovementList;
					var WatchNumberTypeList =
						response.data.responseData.getWatchNumberTypeList;
					var WatchSizeList = response.data.responseData.getWatchSizeList;
					var WatchTypeList = response.data.responseData.getWatchTypeList;
					var WatchEnergyList = response.data.responseData.getWatchEnergyList;
					var JewelryAttributeList =
						response.data.responseData.getJewelryAttributeList;

					if (message === "Success") {
						setWatchBandMaterialList(WatchBandMaterialList);
						setBandTypeList(BandTypeList);
						setCaseMaterialList(CaseMaterialList);
						setCaseShapeList(CaseShapeList);
						setCrystalTypeList(CrystalTypeList);
						setWatchDialColorList(WatchDialColorList);
						setDisplayTypeList(DisplayTypeList);
						setWatchMovementList(WatchMovementList);
						setWatchNumberTypeList(WatchNumberTypeList);
						setWatchSizeList(WatchSizeList);
						setWatchTypeList(WatchTypeList);
						setWatchEnergyList(WatchEnergyList);
						setJewelryAttributeList(JewelryAttributeList);
					} else {
						setWatchBandMaterialList([]);
						setBandTypeList([]);
						setCaseMaterialList([]);
						setCaseShapeList([]);
						setCrystalTypeList([]);
						setWatchDialColorList([]);
						setDisplayTypeList([]);
						setWatchMovementList([]);
						setWatchNumberTypeList([]);
						setWatchSizeList([]);
						setWatchTypeList([]);
						setWatchEnergyList([]);
						setJewelryAttributeList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetEssentialTabData = (id) => {
		var inputData = {
			dealerID: id,
		};
		try {
			catalogJewelryService
				.GetEssentialTabData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var DeliveryTimeList = response.data.responseData.getDeliveryTimeList;
					var CollectionList = response.data.responseData.getCollectionList;
					var JewelryMetalTypeList =
						response.data.responseData.getJewelryMetalTypeList;
					var JewelryMetalColorList =
						response.data.responseData.getJewelryMetalColorList;
					if (message === "Success") {
						setDeliveryList(DeliveryTimeList);
						setCollectionList(CollectionList);
						setJewelryMetalTypeList(JewelryMetalTypeList);
						setJewelryMetalColorList(JewelryMetalColorList);
					} else {
						setDeliveryList([]);
						setCollectionList([]);
						setJewelryMetalColorList([]);
						setJewelryMetalTypeList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetAdditionalToGemStoneTabData = () => {
		var inputData = {
			productID: 0,
		};
		try {
			catalogJewelryService
				.GetAdditionalToGemStoneTabData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var GemStoneTypeList = response.data.responseData.getGemstoneTypeList;
					var GemstoneShapeList =
						response.data.responseData.getGemstoneShapeList;
					var MetalTypeList = response.data.responseData.getMetalTypeList;
					var GenderList = response.data.responseData.getGenderList;
					var SettingList = response.data.responseData.getSettingList;

					if (message === "Success") {
						setGemStoneTypeList(GemStoneTypeList);
						setGemStoneShapeList(GemstoneShapeList);
						setMetalTypeList(MetalTypeList);
						setGenderList(GenderList);
						setSettingList(SettingList);
					} else {
						setGemStoneTypeList([]);
						setGemStoneShapeList([]);
						setMetalTypeList([]);
						setGenderList([]);
						setSettingList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const retail = <span>Tooltip content not given.</span>;

	const style = (
		<span>
			{t(
				"This must be a unique item number identifying the particular item or the"
			)}
			"{t("parent")}"{" "}
			{t(
				"item which has many variations - if this is a style root then you will want to set this item as a"
			)}
			"{t("configurable product")}"{" "}
			{t(
				"to configure the variables such as carat weight, metal type, diamond quality, length etc."
			)}
		</span>
	);

	const dealer_stock = (
		<span>
			{" "}
			{t("This is a unique identification number of this particular item.")}
		</span>
	);

	const availability = (
		<span>Standard delivery time is 2 weeks unless you specify otherwise.</span>
	);

	const sku = <span>Leave blank, unless adding a configurable product.</span>;

	const handleChangeCheckBox = (e, Id) => {
		const tempProductList = jewelryCategoryList.map((x) => {
			if (x.jewelryCategoryID === Id) {
				setValidations({
					CheckBoxVal: "",
				});
				return { ...x, ["isChecked"]: e.target.checked };
			} else return x;
		});
		setjewelryCategoryList(tempProductList);
	};

	const handleSizingTableChange = (name, row, e) => {
		if (name === "additionalPrice") {
			const tempDealerColumnList = sizingDataTable.map((x) => {
				if (x.size === row.size) {
					return { ...x, additionalPrice: e.target.value };
				} else return x;
			});
			setSizingDataTable(tempDealerColumnList);
		}
		if (name === "priceType") {
			const tempDealerColumnList = sizingDataTable.map((x) => {
				if (x.size === row.size) {
					return { ...x, priceType: e };
				} else return x;
			});
			setSizingDataTable(tempDealerColumnList);
		}
	};

	const [hasImage, setHasImage] = useState(false);

	const handleValidation = () => {
		const validations = {};
		let isValid = true;

		if (!isedit) {
			if (!catlogData.file && catlogData.imageUrl == "") {
				isValid = false;
				validations.ImageVal = t("Please select the image");
			} else {
				validations.ImageVal = "";
			}
		} else {
			if (!hasImage) {
				isValid = false;
				validations.ImageVal = t("Please select the image");
			} else {
				if (!catlogData.imageCatlog) {
					if (!catlogData.file && !catlogData.imageUrl) {
						isValid = false;
						validations.ImageVal = t("Please select the image");
					} else {
						validations.ImageVal = "";
					}
				}
			}
		}

		if (!catlogData.style) {
			isValid = false;
			validations.StyleNoVal = t("Style Number is compulsory");
		} else {
			validations.StyleNoVal = "";
		}

		if (!catlogData.productName) {
			isValid = false;
			validations.productNameVal = t("Product name is compulsory");
		} else {
			validations.productNameVal = "";
		}
		if (!catlogData.retailDescription) {
			isValid = false;
			validations.retailDescriptionVal = t("Retail Description is compulsory");
		} else {
			validations.retailDescriptionVal = "";
		}

		if (isedit && catlogData.productType == 1) {
		} else {
			if (!catlogData.retailPrice) {
				isValid = false;
				validations.retailPriceVal = t("Retail Price is compulsory");
			} else if (!/[0-9]/.test(catlogData.retailPrice)) {
				isValid = false;
				validations.retailPriceVal = t("Kindly Enter Valid Price");
			} else {
				validations.retailPriceVal = "";
			}
			if (!catlogData.materialType) {
				isValid = false;
				validations.materialTypeVal = t("Kindly select the Material Type");
			} else {
				validations.materialTypeVal = "";
			}
		}

		if (jewelryCategoryList.filter((x) => x.isChecked === true).length == 0) {
			isValid = false;
			validations.CheckBoxVal = t("Kindly select the Category");
		} else {
			validations.CheckBoxVal = "";
		}
		if (
			validations.productNameVal ||
			validations.retailDescriptionVal ||
			validations.retailPriceVal ||
			validations.materialTypeVal ||
			validations.ImageVal ||
			validations.CheckBoxVal ||
			validations.StyleNoVal
		) {
			isValid = false;
			validations.EssentialTabVal = t(
				"Kindly select all the necessary field from essential"
			);
		}

		if (!isValid) {
			setValidations(validations);
		}

		if (catlogData.productType !== 1) {
			if (pricingData.PriceMethod !== "") {
				if (pricingData.PriceMethod == 4) {
					isValid = true;
				} else if (
					pricingData.PriceMethodInput &&
					parseInt(pricingData.PriceMethodInput) > 0
				) {
					isValid = true;
				} else {
					NotificationManager.error(t("Price should be greater than 0"));
					isValid = false;
				}
			} else {
				if (
					catlogData?.retailPrice &&
					Number(catlogData?.retailPrice?.replace(/,/g, "")) > 0
				) {
					isValid = true;
				} else {
					NotificationManager.error(t("Price should be greater than 0"));
					isValid = false;
				}
			}
			// if (pricingData.PriceMethod == 4) {
			//   isValid = true;
			// } else if (pricingData.PriceMethod == 3) {
			//   if (
			//     pricingData.PriceMethodInput &&
			//     parseInt(pricingData.PriceMethodInput) > 0
			//   ) {
			//     isValid = true;
			//   } else {
			//     NotificationManager.error("Price should be greater than 0");
			//     isValid = false;
			//   }
			// } else {
			//   if (
			//     (pricingData.PriceMethodInput && pricingData.PriceMethodInput > 0) ||
			//     (catlogData && catlogData.retailPrice && catlogData.retailPrice > 0)
			//   ) {
			//     isValid = true;
			//   } else {
			//     NotificationManager.error("Price should be greater than 0");
			//     isValid = false;
			//   }
			// }
		}
		return isValid;
	};

	const handleVisible6 = () => {
		setVisible6(true);
	};

	const handleRedirect = () => {
		var MenuData = [`${"ProductsJewelry"}`, `${"Products"}`];
		dispatch(setOpenKeyHeaderMenuData(MenuData));
		dispatch(setSelectSideMenu(["myProducts"]));
		navigate("/myProducts");
	};
	const handlePriceCheck = () => {
		if (pricingData.PriceMethod == 4) {
			return true;
		} else {
			if (pricingData.PriceMethodInput && pricingData.PriceMethodInput > 0) {
				return true;
			} else {
				return false;
			}
		}
	};

	const handleSubmit = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		if (!isedit) {
			if (isStyleNo == "false") {
				return false;
			}
		}
		var strCategories = jewelryCategoryList
			.filter((x) => x.isChecked == true)
			.map((x) => x.jewelryCategoryID)
			.join(",");

		const formData = new FormData();

		var tempRetailPriceWithDot =
			catlogData.retailPrice &&
			catlogData.retailPrice.toString().replace(",", "");
		var tempRetailPriceWithAnd =
			tempRetailPriceWithDot &&
			tempRetailPriceWithDot.toString().replace("&", "");
		var tempRetailPrice =
			tempRetailPriceWithAnd &&
			tempRetailPriceWithAnd.toString().replace(/,/g, "");
		var tempGemstoneData = gemstoneData;
		if (tempGemstoneData) {
			tempGemstoneData.forEach((element, index) => {
				element.GemstoneShape = Number(element.GemstoneShape);
				element.GemstoneType = Number(element.GemstoneType);
				element.GemstoneID = Number(element.GemstoneID);
				element.NoOfGemstones = Number(element.NoOfGemstones);
				element.GemstoneCaratWeight = Number(element.GemstoneCaratWeight);
			});
		}
		var NewSizingTable = sizingDataTable;
		// if (NewSizingTable) {
		//   NewSizingTable.forEach((element) => {
		//     element.additionalPrice = Number(element.additionalPrice);
		//   });
		// }
		for (let i = 0; i < NewSizingTable.length; i++) {
			if (NewSizingTable[i].additionalPrice == "") {
				NewSizingTable[i].additionalPrice = 0;
			} else {
				NewSizingTable[i].additionalPrice = Number(
					NewSizingTable[i].additionalPrice
				);
			}
		}

		var arr = mediaData.EditAdditionalImages;
		let EditImageIDS = arr.map((user) => user.imageID);
		var objAddProductFields = {
			strCategories: strCategories,
			GetGemstonetypeDataList: gemstoneData ? gemstoneData : [],
			FingerSizetypeList: NewSizingTable,
		};
		var objbollet = {
			BulletPoint1: detailsdData.BulletPoint1,
			BulletPoint2: detailsdData.BulletPoint2,
			BulletPoint3: detailsdData.BulletPoint3,
			BulletPoint4: detailsdData.BulletPoint4,
			BulletPoint5: detailsdData.BulletPoint5,
		};
		var ManageProductRequest = {
			GFInventoryID: isedit ? Number(gfInventoryIDId) : 0,
			DealerID: Number(dealerId),
			StyleNumber: catlogData.style + "",
			DealerStockNumber: catlogData.dealerStock + "",
			RetailerStockNumber: catlogData.retailStock,
			ProductName: catlogData.productName,
			ShortDescription: additionalData.WholesaleDescription,
			ProductDescription: catlogData.retailDescription,
			Quantity: Number(additionalData.QtyOnHand),
			DisplayOrder: additionalData.DisplayOrder
				? Number(additionalData.DisplayOrder)
				: 0,

			StatusID: additionalData.Status ? Number(additionalData.Status) : 0,

			MetalTypeID: catlogData.materialType
				? Number(catlogData.materialType)
				: 0,

			MetalColorID: catlogData.materialColor
				? Number(catlogData.materialColor)
				: 0,

			RetailPrice: tempRetailPrice ? Number(tempRetailPrice) : 0,

			MSRPTypeID: pricingData.PriceType ? Number(pricingData.PriceType) : 0,

			ImagePath: mediaData.UploadImages
				? mediaData.UploadImages.toString()
				: "",

			ImageLable: mediaData.UploadLabel ? mediaData.UploadLabel.toString() : "",

			FlashFileCode: mediaData.FlashFileCode,

			VideoURL: mediaData.loadVideoFromURL,
			VideoType: mediaData.AddVideoType,

			AdditionalInformation: additionalData.AdditionalInformation,

			FinishingTechnique: additionalData.FinishingTechnique,
			Setting: additionalData.Setting + "",

			GenderID: additionalData.Gender ? Number(additionalData.Gender) : 0,
			Width_mm: sizingData.Width.toString(),

			Thickness_mm: sizingData.Thickness ? Number(sizingData.Thickness) : 0,

			Weight_gm: sizingData.Weight ? Number(sizingData.Weight) : 0,

			FingerSize: sizingData.StockFingerSize
				? Number(sizingData.StockFingerSize)
				: 0,

			FingerSizeMinRange: sizingData.MinFingerSize
				? Number(sizingData.MinFingerSize)
				: 0,

			FingerSizeMaxRange: sizingData.MaxFingerSize
				? Number(sizingData.MaxFingerSize)
				: 0,

			MatchingSKUs: relatedData.MatchingStyles,
			UpSellSKUs: relatedData.UpSellStyles,
			GroupedProductSKUs: relatedData.GroupedProductStyles,

			ProductType: Number(catlogData.productType)
				? Number(catlogData.productType)
				: 0,
			WatchBandMaterialID: watchData.BandMaterial
				? Number(watchData.BandMaterial)
				: 0,
			WatchBandTypeID: watchData.BandType ? Number(watchData.BandType) : 0,
			WatchCaseMaterialID: watchData.CaseMaterial
				? Number(watchData.CaseMaterial)
				: 0,
			WatchCaseShapeID: watchData.CaseShape ? Number(watchData.CaseShape) : 0,
			WatchCrystalTypeID: watchData.CrystalType
				? Number(watchData.CrystalType)
				: 0,
			WatchBezel: watchData.Bezel,

			WatchDialColorID: watchData.DialColor ? Number(watchData.DialColor) : 0,

			WatchDisplayTypeID: watchData.DisplayType
				? Number(watchData.DisplayType)
				: 0,
			WatchMovementID: watchData.Movement ? Number(watchData.Movement) : 0,
			WatchNumberTypeID: watchData.NumberType
				? Number(watchData.NumberType)
				: 0,
			WatchSizeID: watchData.Size ? Number(watchData.Size) : 0,
			WatchTypeID: watchData.Type ? Number(watchData.Type) : 0,

			ComesPackagedIn: watchData.ComesPackagedIn
				? watchData.ComesPackagedIn
				: "",
			Warranty: watchData.Warranty,
			WatchCondition: watchData.WatchCondition,
			WatchManufactureDate: watchData.ManufactureDate,
			WatchCertification: watchData.Certification,

			WatchEnergyID: Number(watchData.Energy) ? Number(watchData.Energy) : 0,
			VisibleAs: catlogData.visibility,

			WeightUnit: sizingData.WeightType,
			WidthUnit: sizingData.WidthType,
			ThicknessUnit: sizingData.ThicknessType,
			CustomAttribute: "",
			CustomAttributeLabel: "",
			Dimensions: sizingData.Dimensions,
			HasImage: true,
			ImageLabel: mediaData.Label,

			DiscountA: detailsdData.DiscountA,
			Qty1: detailsdData.Qty1 ? Number(detailsdData.Qty1) : 0,
			Qty2: detailsdData.Qty2 ? Number(detailsdData.Qty2) : 0,
			Qty3: detailsdData.Qty3 ? Number(detailsdData.Qty3) : 0,
			Qty4: detailsdData.Qty4 ? Number(detailsdData.Qty4) : 0,
			Qty5: detailsdData.Qty5 ? Number(detailsdData.Qty5) : 0,

			FingerSizeIncrement: sizingData.FingerSizeIncrement
				? Number(sizingData.FingerSizeIncrement)
				: 0,
			VendorName: detailsdData.VendorName,
			RetailerBrandName: detailsdData.RetailerBrandName,
			DimensionUnitOfMeasure: sizingData.UnitOfMeasure,
			ClaspType: additionalData.ClassType,
			ChainType: additionalData.ChainType,
			BackFinding: additionalData.BackFinding,

			AdditionalInformation2: additionalData.AdditionalInformation2,

			AmazonProduct: detailsdData.aprod + "",
			SecondaryMetalType: detailsdData.SecondaryMetalType,
			Availability: catlogData.availability,
			Collection:
				catlogData.collection && catlogData.collection.length > 1
					? catlogData.collection.toString()
					: catlogData.collection.length > 0
					? catlogData.collection.join(",").toString()
					: "",

			WholesalePriceDetails: pricingData.PriceMethodInput
				? pricingData.PriceMethodInput.toString().replace(/,/g, "")
				: "0",

			WholesalePriceMethod: pricingData.PriceMethod
				? Number(pricingData.PriceMethod)
				: 0,

			UserName: username,
			mainProductImageUrl: catlogData.imageUrl,
			EditProductImageUrl:
				isedit && catlogData.imageCatlog ? catlogData.imageCatlog : "",
			ImageIds: isedit ? EditImageIDS + "" : "",
			AddProductFields: objAddProductFields,
			AssBulletPoint: objbollet,
		};
		formData.append("objResquest", JSON.stringify(ManageProductRequest));
		formData.append("ZipImage", mediaData.zipFile);
		formData.append("productImage", catlogData.file);
		for (let i = 0; i < mediaData.imageFile.length; i++) {
			formData.append("productImage2", mediaData.imageFile[i]);
		}
		formData.append("videoFile", mediaData.VideoFile);
		try {
			setSubmitLoading(true);
			catalogJewelryService
				.ManageEditProduct(formData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Data Added Successfully."));
						setSubmitLoading(false);
						setSelectSizingRow(null);
						setCatlogData({
							productName: "",
							retailDescription: "",
							retailPrice: "",
							style: "",
							dealerStock: "",
							collection: [],
							materialType: "",
							visibility: true,
							materialColor: "",
							Period: "",
							CheckBoxlist: [],
							file: null,
						});
						setValidations({
							productNameVal: "",
							materialTypeVal: "",
							retailDescriptionVal: "",
							retailPriceVal: "",
							EssentialTabVal: "",
							ImageVal: "",
							CheckBoxVal: "",
							ImageMediaVal: "",
							VideoMediaVal: "",
						});
						setAdditionalData({
							QtyOnHand: "",
							DisplayOrder: "",
							Gender: "",
							Setting: "",
							Status: "",
							FinishingTechnique: "",
							ClassType: "",
							ChainType: "",
							BackFinding: "",
							AdditionalInformation: "",
							AdditionalInformation2: "",
						});
						setSizingData({
							Weight: "",
							WeightType: "grams",
							Thickness: "",
							ThicknessType: "mm",
							Width: "",
							WidthType: "mm",
							Dimensions: "",
							UnitOfMeasure: "",
							StockFingerSize: "",
							MinFingerSize: "",
							MaxFingerSize: "",
							FingerSizeIncrement: "",
						});

						setPriceingData({
							WholesalePriceMethod: "",
							WholesaleBasePrice: "",
							BaseMetalMarket: "",
							MetalType: "",
							PriceFactor: "",
							RelationalPrice: "",
							RelationalMetalMarket: "",
							MetalLaborCode: "",
							OtherLaborCode: "",
							MetalFactorCode: "",

							RetailPriceMethod: "",
							MSRP: "",
							PriceType: "",
						});
						setRelatedData({
							MatchingStyles: "",
							UpSellStyles: "",
							GroupedProductStyles: "",
						});
						setMediaData({
							Label: "",
							loadImageFromURL: "",
							loadVideoFromURL: "",
							AddVideoType: "",
							VideoFile: null,
							imageFile: [],
							UploadImages: [],
							UploadLabel: [],
							zipFile: null,
							zipFileName: "",
							EditAdditionalImages: [],
						});
						setDetailsdData({
							BulletPoint1: "",
							BulletPoint2: "",
							BulletPoint3: "",
							BulletPoint4: "",
							BulletPoint5: "",
							DiscountA: "",
							Qty1: "",
							Qty2: "",
							Qty3: "",
							Qty4: "",
							Qty5: "",
							RetailerBrandName: "",
							VendorName: "",
							SecondaryMetalType: "",
							aprod: false,
						});
						setGemstoneData([
							{
								TotalDiamondWeight: "",
								TotalGemstoneWeight: "",
								GemstoneType: "",
								DimensionUnitOfMeasure: "",
								NoOfGemstones: "",
								GemstoneShape: "",
								GemstoneOrigin: "",
								GemstoneCaratWeight: "",
								GemstoneDimensions: "",
								GemstoneQuality: "",
								StoneCreationMethod: "",
								GemstoneLotCode: "",
								GemstoneSettingLaborCode: "",
								StoneTreatementMethod: "",
								GemStoneAdditionalInformation: "",
							},
						]);
						setWatchData({
							BandMaterial: "",
							NumberType: "",
							BandType: "",
							Size: "",
							CaseMaterial: "",
							Type: "",
							CaseShape: "",
							ComesPackagedIn: "",
							CrystalType: "",
							Warranty: "",
							Bezel: "",
							WatchCondition: "",
							DialColor: "",
							ManufactureDate: "",
							DisplayType: "",
							Certification: "",
							Movement: "",
							Energy: "",
						});
						setSelectedTab("essential");
						setSizingDataTable(SizingData);
						setHideShowTable(true);
						setIsEdit(false);
						handleVisible6();
					} else {
						NotificationManager.error(t("Data Not Added."));
						setSubmitLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);

					setSubmitLoading(false);
				});
		} catch (error) {
			console.log(error);

			setSubmitLoading(false);
		}
	};

	const onKeyPressMinusEvent = (e) => {
		if (e.code === "Minus") {
			e.preventDefault();
		}
	};

	const onKeyPressEvent = (event) => {
		const keyCode = event.keyCode || event.which;
		const keyValue = String.fromCharCode(keyCode);
		if (!new RegExp("[0-9,.]").test(keyValue)) event.preventDefault();
		return;
	};

	const onKeyPressPriceEvent = (event) => {
		const keyCode = event.keyCode || event.which;
		const keyValue = String.fromCharCode(keyCode);
		if (!new RegExp("[0-9,.&]").test(keyValue)) event.preventDefault();
		return;
	};

	function onSearch(val) {}

	function formatMoney(amount) {
		var newAmount = amount.replace(/,/g, "");
		try {
			if (newAmount && newAmount.toString().includes(".")) {
				var dottedamount = newAmount.split(".");
				var amount =
					Number(dottedamount[0]).toLocaleString("en-IN") +
					"." +
					dottedamount[1];
				// && Number(amount).toLocaleString("en-US")
			} else {
				var amount = Number(newAmount).toLocaleString("en-IN");
			}
			return amount;
		} catch (e) {
			console.log(e);
		}
	}

	const handleTextChange = (e) => {
		const { name, value } = e.target;
		if (selectedTab == "essential") {
			const tempCatlogData = catlogData;
			tempCatlogData[name] = value;
			setCatlogData((prevState) => ({
				...prevState,
				...tempCatlogData,
			}));
			if (e.target.name === "productName") {
				setValidations((prevdata) => ({
					...prevdata,
					productNameVal: "",
				}));
			}
			if (e.target.name === "retailDescription") {
				setValidations((prevdata) => ({
					...prevdata,
					retailDescriptionVal: "",
				}));
			}
			if (e.target.name === "style") {
				setValidations((prevdata) => ({
					...prevdata,
					StyleNoVal: "",
				}));
			}
			if (e.target.name === "retailPrice") {
				setValidations((prevdata) => ({
					...prevdata,
					retailPriceVal: "",
				}));
			}
		}
		if (selectedTab == "Additional") {
			const tempAdditionalData = additionalData;
			tempAdditionalData[name] = value;
			setAdditionalData((prevState) => ({
				...prevState,
				...tempAdditionalData,
			}));
		}
		if (selectedTab == "sizing") {
			const tempSizingData = sizingData;
			tempSizingData[name] = value;
			setSizingData((prevState) => ({
				...prevState,
				...tempSizingData,
			}));
		}
		if (selectedTab == "pricing") {
			const tempPricingData = pricingData;
			tempPricingData[name] = value;
			setPriceingData((prevState) => ({
				...prevState,
				...tempPricingData,
			}));
		}
		if (selectedTab == "related") {
			const tempRelatedData = relatedData;
			tempRelatedData[name] = value;
			setRelatedData((prevState) => ({
				...prevState,
				...tempRelatedData,
			}));
		}
		if (selectedTab == "media") {
			const tempMediaData = mediaData;
			tempMediaData[name] = value;
			setMediaData((prevState) => ({
				...prevState,
				...tempMediaData,
			}));
		}
		if (selectedTab == "details") {
			const tempDetailsData = detailsdData;
			tempDetailsData[name] = value;
			setDetailsdData((prevState) => ({
				...prevState,
				...tempDetailsData,
			}));
		}
		if (selectedTab == "Gemstone") {
			const tempGemstoneData = gemstoneData;
			tempGemstoneData[name] = value;
			setGemstoneData((prevState) => ({
				...prevState,
				...tempGemstoneData,
			}));
		}

		if (selectedTab == "watch") {
			const tempWatchData = watchData;
			tempWatchData[name] = value;
			setWatchData((prevState) => ({
				...prevState,
				...tempWatchData,
			}));
		}
	};
	const handleSelectChange = (name, value) => {
		if (selectedTab == "essential") {
			const tempCatlogData = catlogData;
			tempCatlogData[name] = value;
			setCatlogData((prevState) => ({
				...prevState,
				...tempCatlogData,
			}));

			if (name === "materialType") {
				setValidations((prevdata) => ({
					...prevdata,
					materialTypeVal: "",
				}));
			}
		}
		if (selectedTab == "Additional") {
			const tempAdditionalData = additionalData;
			tempAdditionalData[name] = value;
			setAdditionalData((prevState) => ({
				...prevState,
				...tempAdditionalData,
			}));
		}
		if (selectedTab == "sizing") {
			const tempSizingData = sizingData;
			tempSizingData[name] = value;
			setSizingData((prevState) => ({
				...prevState,
				...tempSizingData,
			}));
		}
		if (selectedTab == "pricing") {
			const tempPricingData = pricingData;
			tempPricingData[name] = value;
			setPriceingData((prevState) => ({
				...prevState,
				...tempPricingData,
			}));
		}
		if (selectedTab == "related") {
			const tempRelatedData = relatedData;
			tempRelatedData[name] = value;
			setRelatedData((prevState) => ({
				...prevState,
				...tempRelatedData,
			}));
		}
		if (selectedTab == "media") {
			const tempMediaData = mediaData;
			tempMediaData[name] = value;
			setMediaData((prevState) => ({
				...prevState,
				...tempMediaData,
			}));
		}
		if (selectedTab == "details") {
			const tempDetailsData = detailsdData;
			tempDetailsData[name] = value;
			setDetailsdData((prevState) => ({
				...prevState,
				...tempDetailsData,
			}));
		}
		if (selectedTab == "Gemstone") {
			const tempGemstoneData = gemstoneData;
			tempGemstoneData[name] = value;
			setGemstoneData((prevState) => ({
				...prevState,
				...tempGemstoneData,
			}));
		}

		if (selectedTab == "watch") {
			const tempWatchData = watchData;
			tempWatchData[name] = value;
			setWatchData((prevState) => ({
				...prevState,
				...tempWatchData,
			}));
		}
	};
	const handleTabsChange = (e) => {
		setSelectedTab(e);
	};

	const handleCheckBoxChange = (e) => {
		if (selectedTab == "sizing") {
			const tempDetailsData = sizingData;
			tempDetailsData[e.target.name] = e.target.value;
			setSizingData((prevState) => ({
				...prevState,
				...tempDetailsData,
			}));
		}
	};

	const handleCancel = () => {
		// setCatlogData({
		//   // productName: "",
		//   // retailDescription: "",
		//   // retailPrice: "",
		//   // style: "",
		//   // dealerStock: "",
		//   // collection: [],
		//   // materialType: "",
		//   // visibility: true,
		//   // materialColor: "",
		//   // Period: "",
		//   // CheckBoxlist: [],
		//   // file: null
		// });
		setValidations({
			productNameVal: "",
			materialTypeVal: "",
			retailDescriptionVal: "",
			retailPriceVal: "",
			EssentialTabVal: "",
			ImageVal: "",
			CheckBoxVal: "",
			ImageMediaVal: "",
			VideoMediaVal: "",
		});
		setAdditionalData({
			QtyOnHand: "",
			DisplayOrder: "",
			Gender: "",
			Setting: "",
			Status: "",
			FinishingTechnique: "",
			ClassType: "",
			ChainType: "",
			BackFinding: "",
			AdditionalInformation: "",
			AdditionalInformation2: "",
		});
		setSizingData({
			Weight: "",
			WeightType: "grams",
			Thickness: "",
			ThicknessType: "mm",
			Width: "",
			WidthType: "mm",
			Dimensions: "",
			UnitOfMeasure: "",
			StockFingerSize: "",
			MinFingerSize: "",
			MaxFingerSize: "",
			FingerSizeIncrement: "",
		});
		setPriceingData({
			WholesalePriceMethod: "",
			WholesaleBasePrice: "",
			BaseMetalMarket: "",
			MetalType: "",
			PriceFactor: "",
			RelationalPrice: "",
			RelationalMetalMarket: "",
			MetalLaborCode: "",
			OtherLaborCode: "",
			MetalFactorCode: "",

			RetailPriceMethod: "",
			MSRP: "",
			PriceType: "",
		});
		setRelatedData({
			MatchingStyles: "",
			UpSellStyles: "",
			GroupedProductStyles: "",
		});
		setMediaData({
			Label: "",
			loadImageFromURL: "",
			loadVideoFromURL: "",
			AddVideoType: "",
			VideoFile: null,
			imageFile: [],
			UploadImages: [],
			UploadLabel: [],
			ImageIds: "",
			zipFile: null,
			zipFileName: "",
			EditAdditionalImages: [],
		});
		setDetailsdData({
			BulletPoint1: "",
			BulletPoint2: "",
			BulletPoint3: "",
			BulletPoint4: "",
			BulletPoint5: "",
			DiscountA: "",
			Qty1: "",
			Qty2: "",
			Qty3: "",
			Qty4: "",
			Qty5: "",
			RetailerBrandName: "",
			VendorName: "",
			SecondaryMetalType: "",
			aprod: false,
		});
		setGemstoneData([
			{
				GemstoneID: 0,
				TotalDiamondWeight: "",
				TotalGemstoneWeight: "",
				GemstoneType: "",
				DimensionUnitOfMeasure: "",
				NoOfGemstones: "",
				GemstoneShape: "",
				GemstoneOrigin: "",
				GemstoneCaratWeight: "",
				GemstoneDimensions: "",
				GemstoneQuality: "",
				StoneCreationMethod: "",
				GemstoneLotCode: "",
				GemstoneSettingLaborCode: "",
				StoneTreatementMethod: "",
				GemStoneAdditionalInformation: "",
			},
		]);
		setSizingDataTable(SizingData);
		setHideShowTable(false);
		GetProductCategoryList();
		setWatchData({
			BandMaterial: "",
			NumberType: "",
			BandType: "",
			Size: "",
			CaseMaterial: "",
			Type: "",
			CaseShape: "",
			ComesPackagedIn: "",
			CrystalType: "",
			Warranty: "",
			Bezel: "",
			WatchCondition: "",
			DialColor: "",
			ManufactureDate: "",
			DisplayType: "",
			Certification: "",
			Movement: "",
			Energy: "",
		});
		handleGetEssentialTabData(loginDetials.loginDetials.responseData.dealerId);
		if (isedit) {
			navigate("/myProducts");
		} else {
			navigate("/cataloglist");
		}
	};

	const handleCancelImage = () => {
		setCatlogData((prevState) => ({
			...prevState,
			file: null,
		}));
		setCatlogData((prevState) => ({
			...prevState,
			imageCatlog: null,
		}));
	};

	const handleCancelImageFile = (index) => {
		var array = mediaData.imageFile;
		if (index !== -1) {
			array.splice(index, 1);
			setMediaData((prevState) => ({
				...prevState,
				imageFile: array,
			}));
		}
	};

	const handleCancelZipFile = () => {
		setMediaData((prevState) => ({
			...prevState,
			zipFileName: null,
			zipFile: null,
		}));
	};

	const handleCancelImageUpload = (index) => {
		var arrayLabel = mediaData.UploadLabel;
		var arrayImage = mediaData.UploadImages;
		if (index !== -1) {
			setMediaData((prevState) => ({
				...prevState,
				UploadImages: arrayImage.filter((value, i) => i !== index),
				UploadLabel: arrayLabel.filter((value, i) => i !== index),
			}));
		}
	};

	const handleCancelEditImageUpload = (index) => {
		var arrayImage = mediaData.EditAdditionalImages;
		if (index !== -1) {
			setMediaData((prevState) => ({
				...prevState,
				EditAdditionalImages: arrayImage.filter((value, i) => i !== index),
			}));
		}
	};

	const handleCancelVideo = () => {
		setMediaData((prevState) => ({
			...prevState,
			VideoFile: null,
		}));
		setMediaData((prevState) => ({
			...prevState,
			VideoFile: null,
		}));
	};

	const fileUpload = async (e, name) => {
		if (name === "CatalogImage") {
			setHasImage(true);
			if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
				let img = new Image();
				img.src = window.URL.createObjectURL(e.target.files[0]);
				img.onload = () => {
					if (img.width <= 500 && img.height <= 500) {
						setCatlogData({
							...catlogData,
							["file"]: e.target.files[0],
						});
						setValidations({
							...validations,
							["file"]: null,
						});
					} else {
						setValidations({
							...validations,
							ImageVal: t("Please Select Correct Image Size."),
						});
					}
				};
			} else {
				setValidations({
					...validations,
					ImageVal: t("Please Select Only Images."),
				});
				setCatlogData({
					...catlogData,
					["file"]: null,
				});
			}
		}

		if (name === "MediaImage") {
			var file = e.target.files[0];
			var UploadImages = mediaData.imageFile;
			if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
				let img = new Image();
				img.src = window.URL.createObjectURL(file);
				img.onload = () => {
					if (img.width <= 500 && img.height <= 500) {
						UploadImages.push(file);
						setMediaData({
							...mediaData,
							imageFile: UploadImages,
						});
						setValidations({
							...validations,
							ImageMediaVal: null,
							MediaVal: null,
						});
					} else {
						setValidations({
							...validations,
							ImageMediaVal: t("Please Select Correct Image Size."),
							MediaVal: null,
						});
					}
				};
			}
			// else if (file.name.match(/.(zip)$/i)) {
			//   setMediaData({
			//     ...mediaData,
			//     zipFile: file,
			//     zipFileName: file.name,
			//   });
			//   setValidations({
			//     ...validations,
			//     ImageMediaVal: null,
			//     MediaVal: null,
			//   });
			// }
			else {
				setValidations({
					...validations,
					ImageMediaVal: "",
					MediaVal: t("Please Select Images Only"),
				});
			}
		}

		if (name === "MediaVideo") {
			if (
				e.target.files[0].name.match(/.(MP4|MOV|WMV|FLV|AVI|AVCHD|WebM|MKV)$/i)
			) {
				setMediaData({
					...mediaData,
					VideoFile: e.target.files[0],
				});
				setValidations({
					...validations,
					VideoMediaVal: null,
				});
			} else {
				setValidations({
					...validations,
					VideoMediaVal: t("Please Select Only Videos."),
				});
			}
		}
	};

	const handleUploadImage = () => {
		var trimArray = mediaData.loadImageFromURL.split(",");
		var List = trimArray.filter((x) => x !== "");

		var trimListLabel = mediaData.Label.split(",");
		var ListLabel = trimListLabel.filter((x) => x !== "");

		let tempState = mediaData;
		if (mediaData.loadImageFromURL !== "" && mediaData.Label !== "") {
			if (List.includes("") || ListLabel.includes("")) {
				setValidations({
					...validations,
					MediaVal: t("Kindly Upload Files and Label Properly"),
				});
			} else {
				if (ListLabel.length == List.length) {
					tempState["UploadImages"] = List;
					tempState["UploadLabel"] = ListLabel;
					setMediaData(tempState);
					setMediaData({
						...mediaData,
						loadImageFromURL: "",
						Label: "",
					});
					setValidations({
						...validations,
						MediaVal: "",
					});
				} else {
					setValidations({
						...validations,
						MediaVal: t("No of files uploaded and No of label should match"),
					});
				}
			}
		} else {
			setValidations({
				...validations,
				MediaVal: t("Kindly Upload Files and Label Properly"),
			});
		}
	};

	const handleGemInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...gemstoneData];
		list[index][name] = value;
		setGemstoneData(list);
	};

	const handleGemSelectChange = (name, e, index) => {
		const list = [...gemstoneData];
		list[index][name] = e;
		setGemstoneData(list);
	};

	// handle click event of the Remove button
	const handleRemoveClick = (index) => {
		const list = [...gemstoneData];
		list.splice(index + 1, 1);
		setGemstoneData(list);
	};

	// handle click event of the Add button
	const handleAddClick = () => {
		setGemstoneData([
			...gemstoneData,
			{
				GemstoneID: 0,
				TotalDiamondWeight: "",
				TotalGemstoneWeight: "",
				GemstoneType: "",
				DimensionUnitOfMeasure: "",
				NoOfGemstones: "",
				GemstoneShape: "",
				GemstoneOrigin: "",
				GemstoneCaratWeight: "",
				GemstoneDimensions: "",
				GemstoneQuality: "",
				StoneCreationMethod: "",
				GemstoneLotCode: "",
				GemstoneSettingLaborCode: "",
				StoneTreatementMethod: "",
				GemStoneAdditionalInformation: "",
			},
		]);
	};

	const handleOnCheckGridAll = (e) => {
		setCheckBool(e.target.checked);
		setMainCheckVal(e.target.checked);
		var tempcommVendorData = sizingDataTable;
		tempcommVendorData.forEach((element) => {
			element.ischecked = e.target.checked == true ? true : false;
		});
		setSizingDataTable([...tempcommVendorData]);
	};

	const handleCheckBoxOnChange = (row, e, name) => {
		if (name === "ischecked") {
			const temp = sizingDataTable.map((x) => {
				if (x.size == row.size) {
					return {
						...x,
						ischecked: e.target.checked == true ? true : false,
					};
				} else return x;
			});
			setSizingDataTable(temp);
			const testBoolean = temp.every((m) => {
				if (m.ischecked == 0) {
					return false;
				}
				return true;
			});
			setMainCheckVal(testBoolean);
		}
	};

	const handleGetStyleNumber = async () => {
		try {
			if (!catlogData.style) {
				return false;
			}
			let inputData = {
				dealerID: dealerId,
				styleNumber: catlogData.style,
			};
			setLoading(true);
			await catalogJewelryService
				.GetStyleNumber(inputData)
				.then((response) => {
					// var message = response.data.message;
					var checkMessage = response.data.responseData.result.styleNum;
					setLoading(false);
					setValidations((prevState) => ({
						...prevState,
						StyleNoVal:
							checkMessage == "false" ? "Style No is Already Present" : "",
					}));
					setIsStyleNo(checkMessage);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	var UploadImageCount = mediaData.UploadImages.length;
	var imageFileCount = mediaData.imageFile.length;
	var EditAdditionalImagesCount =
		mediaData.EditAdditionalImages && mediaData.EditAdditionalImages.length > 0
			? mediaData.EditAdditionalImages.length
			: 0;
	var TotalCount =
		UploadImageCount + imageFileCount + EditAdditionalImagesCount;

	return (
		<React.Fragment>
			<div className="prodetail__section form__fields addition__tab">
				{vendorStatusID == 3 ? (
					<>{t("Please Contact Gemfind to get Access.")}</>
				) : (
					<Spin spinning={editloading}>
						<div className="col-md-12">
							<div className="prod__maindiv data__maping">
								<div className="row">
									<div className="col-md-12">
										<h4 className="workarea__heading mt-0 mb-3">
											{" "}
											{isedit ? t("Edit Product") : t("Add Product")}
										</h4>
									</div>
									{validations.EssentialTabVal && (
										<p className="error-color-red">
											{validations.EssentialTabVal}
										</p>
									)}
									<div className="col-lg-12 col-md-12">
										<div className="prodesc__div">
											{/* <button onClick={scrollLeft}>Click me left</button>
                    <button onClick={scrollRight}>Click me right</button> */}

											<div className="tabs__arrow">
												<button class="left-button disable">
													<i
														class="fa fa-chevron-circle-left"
														aria-hidden="true"></i>
												</button>

												<button class="right-button">
													<i
														class="fa fa-chevron-circle-right"
														aria-hidden="true"></i>
												</button>
											</div>

											<Tabs
												defaultActiveKey="essential"
												className="tab__div mb-3"
												onSelect={(e) => {
													handleTabsChange(e);
												}}
												activeKey={selectedTab}>
												<Tab eventKey="essential" title={t("Essential")}>
													{/* <p>Essential Here</p> */}
													<div className="form__fields mh-auto">
														<div className="row">
															<div className="col-md-6">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Add Image")}{" "}
																		<span className="text-red">*</span>
																	</label>
																	<div className="col-md-12 image_block">
																		<div class="row">
																			{catlogData.file ||
																			catlogData.imageCatlog ? (
																				<Fragment>
																					<div class="col-lg-5 col-md-12 divimage_block">
																						<span
																							onClick={handleCancelImage}
																							className="close_image">
																							x
																						</span>

																						{isedit ? (
																							catlogData.file ? (
																								<img
																									src={URL.createObjectURL(
																										catlogData.file
																									)}
																									className="img-fluid"
																								/>
																							) : (
																								<img
																									src={catlogData.imageCatlog}
																									className="img-fluid"
																								/>
																							)
																						) : catlogData.file ? (
																							<img
																								src={URL.createObjectURL(
																									catlogData.file
																								)}
																								className="img-fluid"
																							/>
																						) : null}
																					</div>
																				</Fragment>
																			) : (
																				<Fragment>
																					<div
																						className={
																							validations.ImageVal
																								? " col-lg-5 col-md-12 divimage_block border__red "
																								: "col-lg-5 col-md-12 divimage_block"
																						}>
																						<label
																							htmlFor="fileCatalog-upload"
																							className="primary-btn choose_file_upload">
																							{t("Choose Image")}
																						</label>
																						<input
																							id="fileCatalog-upload"
																							type="file"
																							onChange={(e) =>
																								fileUpload(e, "CatalogImage")
																							}
																						/>
																						{validations.ImageVal ? (
																							<div className="text-danger">
																								{validations.ImageVal}
																							</div>
																						) : null}
																						<span className="mb-4">
																							{}
																							{t("No File Choosen")}{" "}
																						</span>

																						<span className="">
																							500 X 500 {t("Pixels")}
																						</span>
																						<span className="">
																							{" "}
																							{t("JPG Format")}{" "}
																						</span>
																					</div>
																					<div class="col-lg-7 col-md-12">
																						<p className="mt-2"> {t("OR")} </p>
																						<label className="mt-2">
																							{t("Load Image From URL")}
																						</label>
																						<input
																							type="text"
																							placeholder={t("Enter Url")}
																							name="imageUrl"
																							value={catlogData.imageUrl}
																							onChange={(e) =>
																								handleTextChange(e)
																							}
																						/>
																						{/* <button className="primary-btn mt-3">
                                          {t("Load Image")}
                                        </button> */}
																					</div>
																				</Fragment>
																			)}
																		</div>
																		<div className="row d-none">
																			<div className="choose_file_divimg">
																				<img src={userIcon} alt="img" />
																				<span>x</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-6">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Product Name")} <span>*</span>
																	</label>
																	<input
																		type="text"
																		placeholder={t("Enter Product Name")}
																		name="productName"
																		value={catlogData.productName}
																		onChange={(e) => handleTextChange(e)}
																		className={
																			validations.productNameVal &&
																			"border__red"
																		}
																	/>
																	<div>
																		{validations.productNameVal && (
																			<p className="error-color-red">
																				{validations.productNameVal}
																			</p>
																		)}
																	</div>
																</div>
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Retail Description")} <span>*</span>
																	</label>
																	<textarea
																		style={{ height: "164px" }}
																		id=""
																		name="retailDescription"
																		value={catlogData.retailDescription}
																		onChange={(e) => handleTextChange(e)}
																		rows="6"
																		cols="50"
																		placeholder={t("Enter Retail Description")}
																		className={
																			validations.retailDescriptionVal &&
																			"border__red"
																		}></textarea>
																	<div>
																		{validations.retailDescriptionVal && (
																			<p className="error-color-red">
																				{validations.retailDescriptionVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>

															<div className="col-lg-3 col-md-6">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Style #")}
																		<span>*</span>
																		<Tooltip placement="right" title={style}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<input
																		type="text"
																		placeholder={t("Enter Style")}
																		name="style"
																		value={catlogData.style}
																		onChange={(e) => handleTextChange(e)}
																		onBlur={handleGetStyleNumber}
																		className={
																			validations.StyleNoVal && "border__red"
																		}
																	/>
																	<div>
																		{validations.StyleNoVal && (
																			<p className="error-color-red">
																				{validations.StyleNoVal}
																			</p>
																		)}
																	</div>
																</div>
															</div>
															<div className="col-lg-3 col-md-6">
																<div className="input__block">
																	<label>
																		{" "}
																		{t("Dealer Stock #")}
																		<Tooltip
																			placement="right"
																			title={dealer_stock}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<input
																		type="text"
																		placeholder={t("Enter Dealer Stock")}
																		name="dealerStock"
																		value={catlogData.dealerStock}
																		onChange={(e) => handleTextChange(e)}
																	/>
																</div>
															</div>
															{catlogData.productType == 0 && (
																<>
																	<div className="col-lg-3 col-md-6">
																		<div className="input__block">
																			<label>
																				{" "}
																				{t("Retail Price")} <span>*</span>
																			</label>
																			<input
																				type="text"
																				onKeyPress={onKeyPressPriceEvent}
																				placeholder="Enter Retail Price"
																				name="retailPrice"
																				value={formatMoney(
																					catlogData.retailPrice
																				)}
																				onChange={(e) => handleTextChange(e)}
																				className={
																					validations.retailPriceVal &&
																					"border__red"
																				}
																			/>
																			<div>
																				{validations.retailPriceVal && (
																					<p className="error-color-red">
																						{validations.retailPriceVal}
																					</p>
																				)}
																			</div>
																		</div>
																	</div>

																	<div className="col-lg-3 col-md-6">
																		<div className="input__block">
																			<label> {t("Collection")} </label>

																			<Select
																				showSearch
																				mode="multiple"
																				className="border__grey"
																				placeholder={t("Select Collection")}
																				showArrow
																				name="collection"
																				optionFilterProp="children"
																				onChange={(e) =>
																					handleSelectChange("collection", e)
																				}
																				value={
																					catlogData.collection
																						? catlogData.collection
																						: []
																				}
																				onSearch={onSearch}>
																				<Option value="0">
																					{" "}
																					{t("Select all")}{" "}
																				</Option>
																				{collectionList &&
																					collectionList.map((item) => {
																						return (
																							<Option
																								value={item.collectionID.toString()}
																								key={item.collectionID}>
																								{item.collection}
																							</Option>
																						);
																					})}
																			</Select>
																		</div>
																	</div>
																	<div className="col-lg-3 col-md-6">
																		<div className="input__block">
																			<label>
																				{" "}
																				{t("Material Type")} <span>*</span>
																			</label>
																			<Select
																				showSearch
																				placeholder={t("Select Material Type")}
																				name="materialType"
																				optionFilterProp="children"
																				onChange={(e) =>
																					handleSelectChange("materialType", e)
																				}
																				value={
																					catlogData.materialType
																						? catlogData.materialType
																						: null
																				}
																				className={
																					validations.materialTypeVal
																						? "border__red"
																						: "border__grey"
																				}>
																				<Option value="">
																					{" "}
																					{t("Material Type")}{" "}
																				</Option>
																				{jewelryMetalTypeList &&
																					jewelryMetalTypeList.map((item) => {
																						return (
																							<Option
																								key={item.metalTypeID}
																								value={item.metalTypeID}>
																								{item.metalType}
																							</Option>
																						);
																					})}
																			</Select>
																			<div>
																				{validations.materialTypeVal && (
																					<p className="error-color-red">
																						{validations.materialTypeVal}
																					</p>
																				)}
																			</div>
																		</div>
																	</div>
																	{/* <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {t("Period")} </label>
                              <input
                                type="text"
                                onKeyPress={onKeyPressEvent}
                                placeholder="Enter Period"
                                name="Period"
                                value={catlogData.Period}
                                onChange={(e) => handleTextChange(e)}
                              />
                            </div>
                          </div> */}
																	<div className="col-lg-3 col-md-6">
																		<div className="input__block">
																			<label> {t("Material Color")} </label>
																			<Select
																				showSearch
																				className="border__grey"
																				placeholder={t("Select Material Color")}
																				name="materialColor"
																				optionFilterProp="children"
																				onChange={(e) =>
																					handleSelectChange("materialColor", e)
																				}
																				value={
																					catlogData.materialColor
																						? catlogData.materialColor
																						: null
																				}>
																				<Option value="">
																					{" "}
																					{t("Material Color")}{" "}
																				</Option>
																				{jewelryMetalColorList &&
																					jewelryMetalColorList.map((item) => {
																						return (
																							<Option
																								key={item.metalColorID}
																								value={item.metalColorID.toString()}>
																								{item.metalColor}
																							</Option>
																						);
																					})}
																			</Select>
																		</div>
																	</div>
																</>
															)}
															<div className="col-lg-3 col-md-6">
																<div className="input__block">
																	<label> {t("Visibility")} </label>
																	<Switch
																		name="visibility"
																		checkedChildren={t("On")}
																		unCheckedChildren={t("Off")}
																		defaultChecked
																		onChange={(e) =>
																			handleSelectChange("visibility", e)
																		}
																	/>
																</div>
															</div>
														</div>
													</div>

													<div className="row">
														<div className="col-md-12">
															<h3 className="subheading"> {t("Category")} </h3>
														</div>
														<div>
															{validations.CheckBoxVal && (
																<p className="error-color-red">
																	{validations.CheckBoxVal}
																</p>
															)}
														</div>
													</div>
													<div className="form__fields mh-auto">
														<Spin spinning={loading}>
															<div className="row">
																{jewelryCategoryList
																	? jewelryCategoryList
																			.filter((y) => y.parentCategoryID == 0)
																			.map((item, i) => {
																				return (
																					<div className="col-md-6">
																						<Accordion>
																							<Accordion.Item
																								eventKey={item.parentCategoryID}
																								key={i}>
																								<Accordion.Header>
																									<div className="accordian__head">
																										<input
																											type="checkbox"
																											name={
																												item.jewelryCategory
																											}
																											// checked={
																											//   catlogData.CheckBoxlist
																											//     .jewelryCategory
																											// }
																											checked={
																												item.isChecked == true
																													? true
																													: false
																											}
																											onChange={(e) => {
																												handleChangeCheckBox(
																													e,
																													item.jewelryCategoryID
																												);
																											}}
																											id={item.parentCategoryID}
																										/>
																										<label>
																											{" "}
																											{item.jewelryCategory}
																										</label>
																									</div>
																								</Accordion.Header>
																								<Accordion.Body>
																									{jewelryCategoryList
																										.filter(
																											(x) =>
																												x.parentCategoryID ===
																												item.jewelryCategoryID
																										)
																										.map((bdata, k) => {
																											return (
																												<div>
																													{jewelryCategoryList.filter(
																														(x) =>
																															x.parentCategoryID ===
																															bdata.jewelryCategoryID
																													).length > 0 ? (
																														<Accordion>
																															<Accordion.Item
																																eventKey={
																																	bdata.parentCategoryID
																																}>
																																<Accordion.Header>
																																	<div>
																																		<input
																																			type="checkbox"
																																			name={
																																				bdata.jewelryCategory
																																			}
																																			checked={
																																				bdata.isChecked ==
																																				true
																																					? true
																																					: false
																																			}
																																			onChange={(
																																				e
																																			) => {
																																				handleChangeCheckBox(
																																					e,
																																					bdata.jewelryCategoryID
																																				);
																																			}}
																																			id={
																																				bdata.jewelryCategoryID
																																			}
																																		/>
																																		<label
																																			htmlFor={
																																				bdata.jewelryCategoryID
																																			}>
																																			{" "}
																																			{
																																				bdata.jewelryCategory
																																			}
																																		</label>
																																	</div>
																																</Accordion.Header>
																																<Accordion.Body>
																																	{jewelryCategoryList
																																		.filter(
																																			(x) =>
																																				x.parentCategoryID ===
																																				bdata.jewelryCategoryID
																																		)
																																		.map(
																																			(
																																				ndata,
																																				j
																																			) => {
																																				return (
																																					<div>
																																						<input
																																							type="checkbox"
																																							name={
																																								ndata.jewelryCategory
																																							}
																																							checked={
																																								ndata.isChecked ==
																																								true
																																									? true
																																									: false
																																							}
																																							onChange={(
																																								e
																																							) => {
																																								handleChangeCheckBox(
																																									e,
																																									ndata.jewelryCategoryID
																																								);
																																							}}
																																							id={
																																								ndata.jewelryCategoryID
																																							}
																																						/>
																																						<label
																																							htmlFor={
																																								ndata.jewelryCategoryID
																																							}>
																																							{" "}
																																							{
																																								ndata.jewelryCategory
																																							}
																																						</label>
																																					</div>
																																				);
																																			}
																																		)}
																																</Accordion.Body>
																															</Accordion.Item>
																														</Accordion>
																													) : (
																														<div>
																															<input
																																type="checkbox"
																																name={
																																	data.jewelryCategory
																																}
																																checked={
																																	bdata.isChecked ==
																																	true
																																		? true
																																		: false
																																}
																																onChange={(
																																	e
																																) => {
																																	handleChangeCheckBox(
																																		e,
																																		bdata.jewelryCategoryID
																																	);
																																}}
																																id={
																																	bdata.jewelryCategoryID
																																}
																															/>
																															<label
																																htmlFor={
																																	bdata.jewelryCategoryID
																																}>
																																{" "}
																																{
																																	bdata.jewelryCategory
																																}
																															</label>
																														</div>
																													)}
																												</div>
																											);
																										})}
																								</Accordion.Body>
																							</Accordion.Item>
																						</Accordion>
																					</div>
																				);
																			})
																	: null}
															</div>
														</Spin>
													</div>
												</Tab>

												<Tab eventKey="Additional" title={t("Additional")}>
													{/* <div className="row">
                        <div className="col-md-2">
                          <div className="input__block">
                            <div className="image_block p-2">
                              <div className="addproductimg">
                                <img src={DaimondImg} className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
													<div className="row">
														<div className="col-md-4">
															<div className="input__block">
																<label>
																	{t("Qty On Hand")}
																	<Tooltip
																		placement="right"
																		title={t(
																			"Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."
																		)}>
																		<img src={UnionIcon} alt="" />
																	</Tooltip>
																</label>
																<input
																	type="text"
																	name="QtyOnHand"
																	onKeyPress={onKeyPressEvent}
																	value={additionalData.QtyOnHand}
																	onChange={(e) => handleTextChange(e)}
																	placeholder={t("Enter Qty On Hand")}
																/>
															</div>
														</div>

														<div className="col-md-4">
															<div className="input__block">
																<label>
																	{t("Display Order")}
																	<Tooltip
																		placement="right"
																		title={t(
																			"This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."
																		)}>
																		<img src={UnionIcon} alt="" />
																	</Tooltip>
																</label>
																<input
																	type="text"
																	name="DisplayOrder"
																	onKeyPress={onKeyPressEvent}
																	value={additionalData.DisplayOrder}
																	onChange={(e) => handleTextChange(e)}
																	placeholder={t("Enter Display Order")}
																/>
															</div>
														</div>
														{catlogData.productType == 0 && (
															<>
																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Gender")} </label>
																		<Select
																			className="border__grey"
																			placeholder={t("Gender")}
																			name="availability"
																			optionFilterProp="children"
																			value={
																				additionalData.Gender
																					? additionalData.Gender
																					: null
																			}
																			showSearch
																			onChange={(e) =>
																				handleSelectChange("Gender", e)
																			}>
																			<Option value=""> {t("Gender")} </Option>
																			{genderList &&
																				genderList.map((item) => {
																					return (
																						<Option
																							key={item.genderID}
																							value={item.genderID}>
																							{item.gender}
																						</Option>
																					);
																				})}
																		</Select>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Setting")} </label>
																		<Select
																			className="border__grey"
																			placeholder={t("Setting")}
																			name="availability"
																			optionFilterProp="children"
																			value={
																				additionalData.Setting
																					? additionalData.Setting
																					: null
																			}
																			onChange={(e) =>
																				handleSelectChange("Setting", e)
																			}
																			showSearch>
																			<Option value=""> {t("Setting")} </Option>
																			{settingList &&
																				settingList.map((item) => {
																					return (
																						<Option
																							key={item.settingID}
																							value={item.settingID.toString()}>
																							{item.settingName}
																						</Option>
																					);
																				})}
																		</Select>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Status")} </label>
																		<Select
																			className="border__grey"
																			placeholder={t("Status")}
																			name="availability"
																			optionFilterProp="children"
																			value={
																				additionalData.Status
																					? additionalData.Status
																					: null
																			}
																			onChange={(e) =>
																				handleSelectChange("Status", e)
																			}
																			showSearch>
																			<Option value=""> {t("Status")} </Option>
																			<Option value="1">
																				{" "}
																				{t("Best Seller")}{" "}
																			</Option>
																			<Option value="2"> {t("Active")} </Option>
																			<Option value="3">
																				{" "}
																				{t("Close Out/ Discontinue")}{" "}
																			</Option>
																			<Option value="4">
																				{" "}
																				{t("Available Online Only")}{" "}
																			</Option>
																			<Option value="5">
																				{" "}
																				{t("Clearance / Sale")}{" "}
																			</Option>
																		</Select>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Finishing Technique")} </label>
																		<input
																			type="text"
																			name="FinishingTechnique"
																			value={additionalData.FinishingTechnique}
																			onChange={(e) => handleTextChange(e)}
																			placeholder={t(
																				"Enter Finishing Technique"
																			)}
																		/>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Clasp Type")} </label>
																		<input
																			type="text"
																			name="ClassType"
																			value={additionalData.ClassType}
																			onChange={(e) => handleTextChange(e)}
																			placeholder={t("Enter Clasp Type")}
																		/>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Chain Type")} </label>
																		<input
																			type="text"
																			name="ChainType"
																			value={additionalData.ChainType}
																			onChange={(e) => handleTextChange(e)}
																			placeholder={t("Enter Chain Type")}
																		/>
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="input__block">
																		<label> {t("Back Finding")} </label>
																		<input
																			type="text"
																			name="BackFinding"
																			value={additionalData.BackFinding}
																			onChange={(e) => handleTextChange(e)}
																			placeholder={t("Enter Back Finding")}
																		/>
																	</div>
																</div>
															</>
														)}
														<div className="col-md-4">
															<div className="input__block">
																<label> {t("Additional Information")} </label>
																<textarea
																	className="product-textarea"
																	type="text"
																	name="AdditionalInformation"
																	value={additionalData.AdditionalInformation}
																	onChange={(e) => handleTextChange(e)}
																	placeholder={t(
																		"Enter Additional Information"
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className="input__block">
																<label> {t("Additional Information 2")} </label>
																<textarea
																	type="text"
																	className="product-textarea"
																	name="AdditionalInformation2"
																	value={additionalData.AdditionalInformation2}
																	onChange={(e) => handleTextChange(e)}
																	placeholder={t(
																		"Enter Additional Information 2"
																	)}
																/>
															</div>
														</div>
													</div>
												</Tab>
												{catlogData.productType == 0 && (
													<Tab eventKey="sizing" title={t("Sizing")}>
														<div className="row">
															<div className="col-md-12">
																<h5 className="subheading"> {t("General")} </h5>
															</div>
															<div className="col-md-4">
																<div className="input__block mb-1">
																	<label> {t("Weight")} </label>
																	<input
																		type="number"
																		min="0"
																		max="100"
																		onKeyPress={onKeyPressMinusEvent}
																		name="Weight"
																		value={sizingData.Weight}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t("Enter Weight")}
																	/>
																</div>
																<div class="radio__block">
																	<Radio.Group
																		name="WeightType"
																		value={sizingData.WeightType}
																		onChange={(e) => handleCheckBoxChange(e)}>
																		<Radio value="grams"> {t("grams")} </Radio>
																		<Radio value="dwt"> {t("dwt")} </Radio>
																	</Radio.Group>
																</div>
															</div>

															<div className="col-md-4">
																<div className="input__block mb-1">
																	<label> {t("Thickness")} </label>
																	<input
																		type="number"
																		min="0"
																		max="100"
																		onKeyPress={onKeyPressMinusEvent}
																		name="Thickness"
																		value={sizingData.Thickness}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t("Enter Thickness")}
																	/>
																</div>
																<div class="radio__block">
																	<Radio.Group
																		name="ThicknessType"
																		value={sizingData.ThicknessType}
																		onChange={(e) => handleCheckBoxChange(e)}>
																		<Radio value={"mm"}> {t("mm")} </Radio>
																		<Radio value={"inches"}>
																			{" "}
																			{t("inches")}{" "}
																		</Radio>
																	</Radio.Group>
																</div>
															</div>

															<div className="col-md-4">
																<div className="input__block mb-1">
																	<label> {t("Width")} </label>
																	<input
																		type="number"
																		min="0"
																		max="100"
																		onKeyPress={onKeyPressMinusEvent}
																		name="Width"
																		value={sizingData.Width}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t("Enter Width")}
																	/>
																</div>
																<div class="radio__block">
																	<Radio.Group
																		name="WidthType"
																		value={sizingData.WidthType}
																		onChange={(e) => handleCheckBoxChange(e)}>
																		<Radio value={"mm"}> {t("mm")} </Radio>
																		<Radio value={"inches"}>
																			{" "}
																			{t("inches")}{" "}
																		</Radio>
																	</Radio.Group>
																</div>
															</div>

															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Dimensions")} </label>
																	<input
																		type="text"
																		name="Dimensions"
																		value={sizingData.Dimensions}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t("Enter Dimensions")}
																	/>
																</div>
															</div>
															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Unit Of Measure")} </label>
																	<input
																		type="text"
																		name="UnitOfMeasure"
																		value={sizingData.UnitOfMeasure}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t("Enter Unit Of Measure")}
																	/>
																</div>
															</div>

															<div className="col-md-12">
																<hr />
															</div>

															<div className="col-md-12">
																<h5 className="subheading">
																	{" "}
																	{t("Finger Size")}{" "}
																</h5>
															</div>

															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Stock Finger Size")} </label>
																	<Select
																		showSearch
																		className="border__grey"
																		placeholder={t("Select Finger Size")}
																		name="availability"
																		optionFilterProp="children"
																		value={
																			sizingData.StockFingerSize
																				? sizingData.StockFingerSize
																				: null
																		}
																		onChange={(e) =>
																			handleSelectChange("StockFingerSize", e)
																		}>
																		<Option value="">
																			{" "}
																			{t("Select Finger Size")}{" "}
																		</Option>
																		<Option value="3">3</Option>
																		<Option value="3.25">3.25</Option>
																		<Option value="3.5">3.5</Option>
																		<Option value="3.75">3.75</Option>
																		<Option value="4">4</Option>
																		<Option value="4.25">4.25</Option>
																		<Option value="4.5">4.5</Option>
																		<Option value="4.75">4.75</Option>
																		<Option value="5">5</Option>
																		<Option value="5.25">5.25</Option>
																		<Option value="5.5">5.5</Option>
																		<Option value="5.75">5.75</Option>
																		<Option value="6">6</Option>
																		<Option value="6.25">6.25</Option>
																		<Option value="6.5">6.5</Option>
																		<Option value="6.75">6.75</Option>
																		<Option value="7">7</Option>
																		<Option value="7.25">7.25</Option>
																		<Option value="7.5">7.5</Option>
																		<Option value="7.75">7.75</Option>
																		<Option value="8">8</Option>
																		<Option value="8.25">8.25</Option>
																		<Option value="8.5">8.5</Option>
																		<Option value="8.75">8.75</Option>
																		<Option value="9">9</Option>
																		<Option value="9.25">9.25</Option>
																		<Option value="9.5">9.5</Option>
																		<Option value="9.75">9.75</Option>
																		<Option value="10">10</Option>
																		<Option value="10.25">10.25</Option>
																		<Option value="10.5">10.5</Option>
																		<Option value="10.75">10.75</Option>
																		<Option value="11">11</Option>
																		<Option value="11.25">11.25</Option>
																		<Option value="11.5">11.5</Option>
																		<Option value="11.75">11.75</Option>
																		<Option value="12">12</Option>
																		<Option value="12.25">12.25</Option>
																		<Option value="12.5">12.5</Option>
																		<Option value="12.75">12.75</Option>
																		<Option value="13">13</Option>
																		<Option value="13.25">13.25</Option>
																		<Option value="13.5">13.5</Option>
																		<Option value="13.75">13.75</Option>
																		<Option value="14">14</Option>
																		<Option value="14.25">14.25</Option>
																		<Option value="14.5">14.5</Option>
																		<Option value="14.75">14.75</Option>
																		<Option value="15">15</Option>
																		<Option value="15.25">15.25</Option>
																		<Option value="15.5">15.5</Option>
																		<Option value="15.75">15.75</Option>
																		<Option value="16">16</Option>
																		<Option value="16.25">16.25</Option>
																		<Option value="16.5">16.5</Option>
																		<Option value="16.75">16.75</Option>
																		<Option value="17">17</Option>
																		<Option value="17.25">17.25</Option>
																		<Option value="17.5">17.5</Option>
																		<Option value="17.75">17.75</Option>
																		<Option value="18">18</Option>
																	</Select>
																</div>
															</div>

															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Min Finger Size")} </label>
																	<Select
																		showSearch
																		className="border__grey"
																		placeholder={t("Select Finger Size")}
																		name="availability"
																		optionFilterProp="children"
																		value={
																			sizingData.MinFingerSize
																				? sizingData.MinFingerSize
																				: null
																		}
																		onChange={(e) =>
																			handleSelectChange("MinFingerSize", e)
																		}>
																		<Option value="">
																			{" "}
																			{t("Select Finger Size")}{" "}
																		</Option>
																		<Option value="3">3</Option>
																		<Option value="3.25">3.25</Option>
																		<Option value="3.5">3.5</Option>
																		<Option value="3.75">3.75</Option>
																		<Option value="4">4</Option>
																		<Option value="4.25">4.25</Option>
																		<Option value="4.5">4.5</Option>
																		<Option value="4.75">4.75</Option>
																		<Option value="5">5</Option>
																		<Option value="5.25">5.25</Option>
																		<Option value="5.5">5.5</Option>
																		<Option value="5.75">5.75</Option>
																		<Option value="6">6</Option>
																		<Option value="6.25">6.25</Option>
																		<Option value="6.5">6.5</Option>
																		<Option value="6.75">6.75</Option>
																		<Option value="7">7</Option>
																		<Option value="7.25">7.25</Option>
																		<Option value="7.5">7.5</Option>
																		<Option value="7.75">7.75</Option>
																		<Option value="8">8</Option>
																		<Option value="8.25">8.25</Option>
																		<Option value="8.5">8.5</Option>
																		<Option value="8.75">8.75</Option>
																		<Option value="9">9</Option>
																		<Option value="9.25">9.25</Option>
																		<Option value="9.5">9.5</Option>
																		<Option value="9.75">9.75</Option>
																		<Option value="10">10</Option>
																		<Option value="10.25">10.25</Option>
																		<Option value="10.5">10.5</Option>
																		<Option value="10.75">10.75</Option>
																		<Option value="11">11</Option>
																		<Option value="11.25">11.25</Option>
																		<Option value="11.5">11.5</Option>
																		<Option value="11.75">11.75</Option>
																		<Option value="12">12</Option>
																		<Option value="12.25">12.25</Option>
																		<Option value="12.5">12.5</Option>
																		<Option value="12.75">12.75</Option>
																		<Option value="13">13</Option>
																		<Option value="13.25">13.25</Option>
																		<Option value="13.5">13.5</Option>
																		<Option value="13.75">13.75</Option>
																		<Option value="14">14</Option>
																		<Option value="14.25">14.25</Option>
																		<Option value="14.5">14.5</Option>
																		<Option value="14.75">14.75</Option>
																		<Option value="15">15</Option>
																		<Option value="15.25">15.25</Option>
																		<Option value="15.5">15.5</Option>
																		<Option value="15.75">15.75</Option>
																		<Option value="16">16</Option>
																		<Option value="16.25">16.25</Option>
																		<Option value="16.5">16.5</Option>
																		<Option value="16.75">16.75</Option>
																		<Option value="17">17</Option>
																		<Option value="17.25">17.25</Option>
																		<Option value="17.5">17.5</Option>
																		<Option value="17.75">17.75</Option>
																		<Option value="18">18</Option>
																	</Select>
																</div>
															</div>
															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Max Finger Size")} </label>
																	<Select
																		showSearch
																		className="border__grey"
																		placeholder={t("Select Finger Size")}
																		name="availability"
																		optionFilterProp="children"
																		value={
																			sizingData.MaxFingerSize
																				? sizingData.MaxFingerSize
																				: null
																		}
																		onChange={(e) =>
																			handleSelectChange("MaxFingerSize", e)
																		}>
																		<Option value="">
																			{" "}
																			{t("Select Finger Size")}{" "}
																		</Option>
																		<Option value="3">3</Option>
																		<Option value="3.25">3.25</Option>
																		<Option value="3.5">3.5</Option>
																		<Option value="3.75">3.75</Option>
																		<Option value="4">4</Option>
																		<Option value="4.25">4.25</Option>
																		<Option value="4.5">4.5</Option>
																		<Option value="4.75">4.75</Option>
																		<Option value="5">5</Option>
																		<Option value="5.25">5.25</Option>
																		<Option value="5.5">5.5</Option>
																		<Option value="5.75">5.75</Option>
																		<Option value="6">6</Option>
																		<Option value="6.25">6.25</Option>
																		<Option value="6.5">6.5</Option>
																		<Option value="6.75">6.75</Option>
																		<Option value="7">7</Option>
																		<Option value="7.25">7.25</Option>
																		<Option value="7.5">7.5</Option>
																		<Option value="7.75">7.75</Option>
																		<Option value="8">8</Option>
																		<Option value="8.25">8.25</Option>
																		<Option value="8.5">8.5</Option>
																		<Option value="8.75">8.75</Option>
																		<Option value="9">9</Option>
																		<Option value="9.25">9.25</Option>
																		<Option value="9.5">9.5</Option>
																		<Option value="9.75">9.75</Option>
																		<Option value="10">10</Option>
																		<Option value="10.25">10.25</Option>
																		<Option value="10.5">10.5</Option>
																		<Option value="10.75">10.75</Option>
																		<Option value="11">11</Option>
																		<Option value="11.25">11.25</Option>
																		<Option value="11.5">11.5</Option>
																		<Option value="11.75">11.75</Option>
																		<Option value="12">12</Option>
																		<Option value="12.25">12.25</Option>
																		<Option value="12.5">12.5</Option>
																		<Option value="12.75">12.75</Option>
																		<Option value="13">13</Option>
																		<Option value="13.25">13.25</Option>
																		<Option value="13.5">13.5</Option>
																		<Option value="13.75">13.75</Option>
																		<Option value="14">14</Option>
																		<Option value="14.25">14.25</Option>
																		<Option value="14.5">14.5</Option>
																		<Option value="14.75">14.75</Option>
																		<Option value="15">15</Option>
																		<Option value="15.25">15.25</Option>
																		<Option value="15.5">15.5</Option>
																		<Option value="15.75">15.75</Option>
																		<Option value="16">16</Option>
																		<Option value="16.25">16.25</Option>
																		<Option value="16.5">16.5</Option>
																		<Option value="16.75">16.75</Option>
																		<Option value="17">17</Option>
																		<Option value="17.25">17.25</Option>
																		<Option value="17.5">17.5</Option>
																		<Option value="17.75">17.75</Option>
																		<Option value="18">18</Option>
																	</Select>
																</div>
															</div>

															<div className="col-md-4">
																<div className="input__block">
																	<label> {t("Finger Size Increment")} </label>
																	<input
																		type="number"
																		onKeyPress={onKeyPressEvent}
																		name="FingerSizeIncrement"
																		value={sizingData.FingerSizeIncrement}
																		onChange={(e) => handleTextChange(e)}
																		placeholder={t(
																			"Enter Finger Size Increment"
																		)}
																	/>
																</div>
															</div>

															<div className="col-md-12">
																<hr />
															</div>

															<div className="col-md-4">
																<button
																	className="primary-btn"
																	onClick={handleHideShowTable}>
																	{HideShowTable ? (
																		<span className="d-flex">
																			<i
																				className="fa fa-plus-circle"
																				aria-hidden="true"></i>
																			{t("Vary Pricing By Finger Size")}{" "}
																		</span>
																	) : (
																		<span className="d-flex">
																			<i
																				className="fa fa-minus-circle"
																				aria-hidden="true"></i>
																			{t("Vary Pricing By Finger Size")}{" "}
																		</span>
																	)}
																</button>
															</div>

															<div
																className={
																	HideShowTable
																		? "hide-table-sp"
																		: "show-table-sp"
																}>
																<div className="col-lg-7 mt-3">
																	<Table
																		className="vdiamond__table fingersizing"
																		columns={[
																			{
																				title: (
																					<div className="filtbtn__div">
																						{/* <span className="select__all">
                                          <input
                                            type="checkbox"
                                            id="sel"
                                            onChange={(e) =>
                                              handleOnCheckGridAll(e)
                                            }
                                            checked={mainCheckVal}
                                          />
                                        </span> */}
																					</div>
																				),
																				dataIndex: "ischecked",
																				width: 50,
																				render: (item, row) => {
																					return (
																						<div className="action__btns">
																							<input
																								type="checkbox"
																								name="selectedDealerID"
																								checkedChildren={t("Yes")}
																								unCheckedChildren={t("No")}
																								checked={
																									row.ischecked == true
																										? true
																										: false
																								}
																								onChange={(e) =>
																									handleCheckBoxOnChange(
																										row,
																										e,
																										"ischecked"
																									)
																								}
																							/>
																						</div>
																					);
																				},
																			},
																			{
																				title: t("Size"),
																				dataIndex: "size",
																			},
																			{
																				title: t("Price Type"),
																				dataIndex: "priceType",
																				width: 250,
																				render: (item, row) => {
																					return (
																						<div className="input__block mb-0">
																							<Select
																								name="priceType"
																								showSearch
																								placeholder={t("Select Option")}
																								optionFilterProp="children"
																								value={
																									row.priceType
																										? row.priceType
																										: null
																								}
																								onChange={(e) =>
																									handleSizingTableChange(
																										"priceType",
																										row,
																										e
																									)
																								}
																								className="border__grey">
																								<Option value="%">
																									{" "}
																									{t("Percentage")}{" "}
																								</Option>
																								<Option value="Fixed">
																									{" "}
																									{t("Fixed")}{" "}
																								</Option>
																							</Select>
																						</div>
																					);
																				},
																			},
																			{
																				title: t("Additional Price"),
																				dataIndex: "additionalPrice",
																				width: 250,
																				render: (item, row) => {
																					return (
																						<div className="input__block mb-0">
																							<input
																								name="additionalPrice"
																								type="text"
																								onKeyPress={onKeyPressEvent}
																								value={row.additionalPrice}
																								onChange={(e) =>
																									handleSizingTableChange(
																										"additionalPrice",
																										row,
																										e
																									)
																								}
																							/>
																						</div>
																					);
																				},
																			},
																		]}
																		dataSource={sizingDataTable}
																		scroll={{ y: 800 }}
																		pagination={false}></Table>
																</div>
															</div>
														</div>
													</Tab>
												)}
												{catlogData.productType == 0 && (
													<Tab eventKey="pricing" title={t("Pricing")}>
														<div className="row">
															<div className="col-md-4">
																<div className="input__block">
																	<label>
																		{t("PriceMethod")}
																		<Tooltip
																			placement="right"
																			title={t(
																				"'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"
																			)}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<Select
																		className="border__grey"
																		placeholder={t("Select Price Method")}
																		name="availability"
																		optionFilterProp="children"
																		value={
																			pricingData.PriceMethod
																				? pricingData.PriceMethod
																				: null
																		}
																		onChange={(e) =>
																			handleSelectChange("PriceMethod", e)
																		}
																		showSearch>
																		<Option value="1"> {t("Retail")} </Option>
																		<Option value="2">
																			{" "}
																			{t("WholeSale")}{" "}
																		</Option>
																		<Option value="3"> {t("MSRP")} </Option>
																		<Option value="4">
																			{" "}
																			{t("Call For Price")}{" "}
																		</Option>
																	</Select>
																</div>
															</div>
															{pricingData.PriceMethod == 1 ||
															pricingData.PriceMethod == 2 ||
															pricingData.PriceMethod == 3 ? (
																<div className="row manual__inputs">
																	<div className="col-md-4">
																		<div className="input__block">
																			<label>
																				{pricingData.PriceMethod == 1
																					? t("Retail Price")
																					: pricingData.PriceMethod == 2
																					? t("WholeSale")
																					: pricingData.PriceMethod == 3
																					? t("MSRP")
																					: null}
																			</label>
																			<input
																				type="text"
																				// onKeyPress={
																				//   pricingData.PriceMethod == 2
																				//     ? onKeyPressPriceEvent
																				//     : onKeyPressEvent
																				// }
																				onKeyPress={onKeyPressPriceEvent}
																				name="PriceMethodInput"
																				value={formatMoney(
																					pricingData.PriceMethodInput
																				)}
																				onChange={(e) => handleTextChange(e)}
																				placeholder={t("Enter Price")}
																			/>
																		</div>
																	</div>
																</div>
															) : null}
														</div>
													</Tab>
												)}
												{catlogData.productType == 0 && (
													<Tab eventKey="related" title={t("Related")}>
														<div className="row">
															<div className="col-lg-12">
																<div className="input__block">
																	<label>
																		{t("Matching Styles")}
																		<Tooltip
																			placement="right"
																			className="ml-2"
																			title={t(
																				"Matching Sku's are items from the same ensemble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."
																			)}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<textarea
																		id="1"
																		rows="4"
																		cols="50"
																		name="MatchingStyles"
																		value={relatedData.MatchingStyles}
																		onChange={(e) =>
																			handleTextChange(e)
																		}></textarea>
																</div>
															</div>
															<div className="col-lg-12">
																<div className="input__block">
																	<label>
																		{t("Up Sell Styles")}
																		<Tooltip
																			placement="right"
																			className="ml-2"
																			title={t(
																				"Up sell Sku's are items that are related by at slightly higher price points."
																			)}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<textarea
																		id="1"
																		rows="4"
																		cols="50"
																		name="UpSellStyles"
																		value={relatedData.UpSellStyles}
																		onChange={(e) =>
																			handleTextChange(e)
																		}></textarea>
																</div>
															</div>
															<div className="col-lg-12">
																<div className="input__block">
																	<label>
																		{t("Grouped Product Styles")}
																		<Tooltip
																			placement="right"
																			className="ml-2"
																			title={t(
																				"To define the product styles that are grouped together. Enter Dealer ID or Style #' separated with commas."
																			)}>
																			<img src={UnionIcon} alt="" />
																		</Tooltip>
																	</label>
																	<textarea
																		id="1"
																		rows="4"
																		cols="50"
																		name="GroupedProductStyles"
																		value={relatedData.GroupedProductStyles}
																		onChange={(e) =>
																			handleTextChange(e)
																		}></textarea>
																</div>
															</div>
														</div>
													</Tab>
												)}
												<Tab eventKey="media" title={t("Media")}>
													<div className="row">
														<div className="col-lg-12">
															<div className="input__block">
																<div className="col-md-12 image_block">
																	<div class="row">
																		<Fragment>
																			<div className="col-lg-5 col-md-12">
																				<div
																					className={
																						validations.ImageMediaVal
																							? "divimage_block border__red "
																							: "divimage_block"
																					}>
																					<label
																						htmlFor="fileMedia-upload"
																						className={
																							TotalCount >= 7
																								? "disabled-btn"
																								: "primary-btn choose_file_upload"
																						}
																						disabled={
																							TotalCount >= 7 ? true : false
																						}>
																						{t("Choose Image")}
																					</label>
																					<input
																						disabled={
																							TotalCount >= 7 ? true : false
																						}
																						id="fileMedia-upload"
																						type="file"
																						onChange={(e) =>
																							fileUpload(e, "MediaImage")
																						}
																					/>
																					{validations.ImageMediaVal ? (
																						<div className="text-danger">
																							{validations.ImageMediaVal}
																						</div>
																					) : null}

																					{validations.MediaVal ? (
																						<div className="text-danger">
																							{validations.MediaVal}
																						</div>
																					) : null}
																					<Fragment
																						className={
																							mediaData.UploadImages.length <=
																								0 ||
																							mediaData.imageFile.length <= 0 ||
																							mediaData.zipFile !== null
																								? "display-block"
																								: "display-none"
																						}>
																						<span
																							className={
																								mediaData.zipFile !== null
																									? "display-block"
																									: "display-none"
																							}>
																							{t("No File Choosen")}{" "}
																						</span>

																						<span className="">
																							{" "}
																							500 X 500 {t("Pixels")}{" "}
																						</span>
																						<span className="">
																							{" "}
																							{t("JPG Format")}{" "}
																						</span>
																					</Fragment>
																				</div>
																				{mediaData.zipFileName ? (
																					<span className="image__label">
																						{mediaData.zipFileName}
																						<span
																							className="mr-2"
																							onClick={handleCancelZipFile}>
																							x
																						</span>
																					</span>
																				) : null}
																				<div className="image__preview">
																					{mediaData.imageFile &&
																						mediaData.imageFile.map(
																							(item, index) => (
																								<div className="whole__block">
																									<div className="img__blck">
																										<span
																											className="close_image"
																											onClick={() =>
																												handleCancelImageFile(
																													index
																												)
																											}>
																											x
																										</span>
																										<img
																											src={URL.createObjectURL(
																												item
																											)}
																											className="img-fluid"
																										/>
																									</div>
																									<div className="image__label">
																										<p>{item.name}</p>
																									</div>
																								</div>
																							)
																						)}
																				</div>
																				<div class="image__preview">
																					{mediaData.UploadImages &&
																						mediaData.UploadImages.map(
																							(item, index) => (
																								<div className="whole__block">
																									<div className="img__blck">
																										<span
																											className="close_image"
																											onClick={() =>
																												handleCancelImageUpload(
																													index
																												)
																											}>
																											x
																										</span>{" "}
																										<img
																											src={item}
																											className="img-fluid"
																										/>
																									</div>
																								</div>
																							)
																						)}
																				</div>
																				{isedit ? (
																					<div class="image__preview">
																						{mediaData.EditAdditionalImages &&
																							mediaData.EditAdditionalImages.map(
																								(item, index) => (
																									<div className="whole__block">
																										<div className="img__blck">
																											<span
																												className="close_image"
																												onClick={() =>
																													handleCancelEditImageUpload(
																														index
																													)
																												}>
																												x
																											</span>{" "}
																											<img
																												src={item.imagePath}
																												className="img-fluid"
																											/>
																										</div>
																									</div>
																								)
																							)}
																					</div>
																				) : null}
																			</div>
																			<div class="col-lg-7 col-md-12">
																				{/* <p className="mt-2"> {t("OR")} </p> */}
																				<div className="input__block">
																					<label>
																						{t("Load Image From Server")}
																						<Tooltip
																							placement="right"
																							className="ml-1"
																							title={t(
																								"From here the user can upload at the most 7 comma separated images from server at once"
																							)}>
																							<img src={UnionIcon} alt="" />
																						</Tooltip>
																					</label>
																					<textarea
																						rows="2"
																						className="h-auto"
																						placeholder={t("Enter Url")}
																						name="loadImageFromURL"
																						value={mediaData.loadImageFromURL}
																						onChange={(e) =>
																							handleTextChange(e)
																						}></textarea>
																				</div>

																				<div className="input__block">
																					<label>
																						{" "}
																						{t("Label")}
																						<Tooltip
																							placement="right"
																							className="ml-1"
																							title={t(
																								"From here the user can has to add comma separated label/Name for the same No of images uploaded from server at once"
																							)}>
																							<img src={UnionIcon} alt="" />
																						</Tooltip>
																					</label>
																					<textarea
																						rows="2"
																						className="h-auto"
																						placeholder={t("Enter Label")}
																						name="Label"
																						value={mediaData.Label}
																						onChange={(e) =>
																							handleTextChange(e)
																						}></textarea>
																					<button
																						className={
																							TotalCount >= 7 ||
																							// (mediaData.loadImageFromURL == "" &&
																							// mediaData.Label == "") // ||
																							mediaData.loadImageFromURL.split(
																								","
																							).length >= 8 ||
																							mediaData.Label.split(",")
																								.length >= 8
																								? "disabled-btn"
																								: "primary-btn"
																						}
																						disabled={
																							TotalCount >= 7 ||
																							// (mediaData.loadImageFromURL == "" &&
																							// mediaData.Label == "") // ||
																							mediaData.loadImageFromURL.split(
																								","
																							).length >= 8 ||
																							mediaData.Label.split(",")
																								.length >= 8
																								? true
																								: false
																						}
																						onClick={handleUploadImage}
																						style={{ marginTop: "1rem" }}>
																						{/* {t("Load Image")} */}
																						{t("Upload")}
																					</button>
																				</div>

																				<div className="input__block">
																					<label>
																						{t("Load Video From URL")}
																						<Tooltip
																							placement="right"
																							className="ml-1"
																							title={t(
																								"User can add Video Url from here"
																							)}>
																							<img src={UnionIcon} alt="" />
																						</Tooltip>
																					</label>
																					<input
																						type="text"
																						placeholder={t("Enter Url")}
																						name="loadVideoFromURL"
																						value={mediaData.loadVideoFromURL}
																						onChange={(e) =>
																							handleTextChange(e)
																						}
																					/>
																				</div>
																			</div>
																		</Fragment>
																	</div>
																	<div className="row d-none">
																		<div className="choose_file_divimg">
																			<img src={userIcon} alt="img" />
																			<span>x</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="col-lg-12 d-none">
															<div className="input__block">
																<div className="col-md-12 image_block">
																	<div class="row">
																		<div class="col-lg-5 col-md-12 divimage_block">
																			<div>
																				<label
																					htmlFor="file-upload"
																					class="primary-btn choose_file_upload">
																					{t("Choose Video")}
																				</label>
																				<input
																					id="file-upload"
																					type="file"
																					onChange={(e) =>
																						fileUpload(e, "MediaVideo")
																					}
																					className={
																						validations.VideoMediaVal &&
																						"border__red"
																					}
																				/>
																				<span className="mt-2">
																					{mediaData.VideoFile ? (
																						<Fragment>
																							<span>
																								{mediaData.VideoFile.name}
																							</span>{" "}
																							<span
																								onClick={handleCancelVideo}
																								className="close_image">
																								x
																							</span>
																						</Fragment>
																					) : (
																						"No File Choosen"
																					)}{" "}
																				</span>
																			</div>
																			{validations.VideoMediaVal ? (
																				<span>{validations.VideoMediaVal}</span>
																			) : null}
																		</div>
																		<div class="col-lg-7 col-md-12">
																			<div>
																				{/* <p className="mt-2"> {t("OR")} </p> */}
																				<div className="input__block">
																					<label>
																						{t("Load Video From URL")}
																					</label>
																					<input
																						type="text"
																						placeholder={t("Enter Url")}
																						name="loadVideoFromURL"
																						value={mediaData.loadVideoFromURL}
																						onChange={(e) =>
																							handleTextChange(e)
																						}
																					/>
																				</div>

																				<div className="input__block">
																					<label> {t("Add Video Type")} </label>
																					<Select
																						className="border__grey selct__inpt"
																						placeholder={t("Video Type")}
																						value={
																							mediaData.AddVideoType
																								? mediaData.AddVideoType
																								: null
																						}
																						onChange={(e) =>
																							handleSelectChange(
																								"AddVideoType",
																								e
																							)
																						}
																						showSearch>
																						<Option value="">
																							{" "}
																							{t("Video Type")}{" "}
																						</Option>
																						<Option value="1">1</Option>
																						<Option value="1">2</Option>
																						<Option value="1">3</Option>
																					</Select>
																				</div>

																				{/* <button className="primary-btn">
                                     
                                      Upload
                                    </button> */}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{/* <div className="col-lg-4 mt-2">
                          <div className="input__block">
                            <label> {t("Add Video Type")} </label>
                            <Select
                              className="border__grey selct__inpt"
                              placeholder="Video Type"
                              value={
                                mediaData.AddVideoType
                                  ? mediaData.AddVideoType
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("AddVideoType", e)
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            > 
                              <Option value="">Video Type</Option>
                              <Option value="1">1</Option>
                              <Option value="1">2</Option>
                              <Option value="1">3</Option>
                            </Select>
                          </div>
                        </div> */}

														{/* <div className="col-lg-4">
                          <div className="input__block">
                            <label> {t("Label")} </label>
                            <input
                              type="text"
                              placeholder="Enter Label"
                              name="Label"
                              value={mediaData.Label}
                              onChange={(e) => handleTextChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <button className="primary-btn mt-3">
                            {" "}
                            {t("Upload")}{" "}
                          </button>
                        </div> */}
													</div>
												</Tab>

												{/*Details Section*/}

												<Tab eventKey="details" title={t("Details")}>
													<div className="row">
														<div className="col-lg-12">
															<div className="row">
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Bullet Point 1")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Bullet Point 1")}
																			name="BulletPoint1"
																			value={detailsdData.BulletPoint1}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Bullet Point 2")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Bullet Point 2")}
																			name="BulletPoint2"
																			value={detailsdData.BulletPoint2}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Bullet Point 3")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Bullet Point 3")}
																			name="BulletPoint3"
																			value={detailsdData.BulletPoint3}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Bullet Point 4")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Bullet Point 4")}
																			name="BulletPoint4"
																			value={detailsdData.BulletPoint4}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Bullet Point 5")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Bullet Point 5")}
																			name="BulletPoint5"
																			value={detailsdData.BulletPoint5}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Discount A")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Discount A")}
																			name="DiscountA"
																			value={detailsdData.DiscountA}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Qty 1")} </label>
																		<input
																			type="text"
																			onKeyPress={onKeyPressEvent}
																			placeholder={t("Enter Qty 1")}
																			name="Qty1"
																			value={detailsdData.Qty1}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Qty 2")} </label>
																		<input
																			type="text"
																			onKeyPress={onKeyPressEvent}
																			placeholder={t("Enter Qty 2")}
																			name="Qty2"
																			value={detailsdData.Qty2}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Qty 3")} </label>
																		<input
																			type="text"
																			onKeyPress={onKeyPressEvent}
																			placeholder={t("Enter Qty 3")}
																			name="Qty3"
																			value={detailsdData.Qty3}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Qty 4")} </label>
																		<input
																			type="text"
																			onKeyPress={onKeyPressEvent}
																			placeholder={t("Enter Qty 4")}
																			name="Qty4"
																			value={detailsdData.Qty4}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Qty 5")} </label>
																		<input
																			type="text"
																			onKeyPress={onKeyPressEvent}
																			placeholder={t("Enter Qty 5")}
																			name="Qty5"
																			value={detailsdData.Qty5}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Retailer Brand Name")} </label>
																		<input
																			type="text"
																			placeholder={t(
																				"Enter Retailer Brand Name"
																			)}
																			name="RetailerBrandName"
																			value={detailsdData.RetailerBrandName}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Vendor Name")} </label>
																		<input
																			type="text"
																			placeholder={t("Enter Vendor Name")}
																			name="VendorName"
																			value={detailsdData.VendorName}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																<div className="col-lg-4 mt-2">
																	<div className="input__block">
																		<label> {t("Secondary Metal Type")} </label>
																		<input
																			type="text"
																			placeholder={t(
																				"Enter Retailer Secondary Metal Type"
																			)}
																			name="SecondaryMetalType"
																			value={detailsdData.SecondaryMetalType}
																			onChange={(e) => handleTextChange(e)}
																		/>
																	</div>
																</div>
																{/* <div className="col-lg-4 mt-2">
                                  <div className="mt-4">
                                    <label for="aprod" className="mr-2">
                                      {" "}
                                      {t("Amazon Product")}{" "}
                                    </label>
                                    <Switch
                                      name="aprod"
                                      checkedChildren={t("Yes")}
                                      unCheckedChildren={t("No")}
                                      checked={detailsdData.aprod}
                                      onChange={e =>
                                        handleSelectChange("aprod", e)
                                      }
                                    />
                                  </div>
                                </div> */}
															</div>
														</div>
													</div>
												</Tab>
												{/*Details Section*/}
												{catlogData.productType == 0 && (
													<Tab eventKey="Gemstone" title={t("Gemstone")}>
														<div className="">
															{/* <div className="row d-none">
                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {t("Total Diamond Weight")}{" "}
                              </label>
                              <input
                                type="number"
                                min="0"
                                max="100"
                                onKeyPress={onKeyPressMinusEvent}
                                name="TotalDiamondWeight"
                                value={detailsdData.TotalDiamondWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Total Diamond Weight"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {t("Total Gemstone Weight")}{" "}
                              </label>
                              <input
                                type="number"
                                min="0"
                                max="100"
                                onKeyPress={onKeyPressMinusEvent}
                                name="TotalGemstoneWeight"
                                value={detailsdData.TotalGemstoneWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Total Gemstone Weight"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Gemstone Type")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Select GemstoneType"
                                optionFilterProp="children"
                                value={
                                  gemstoneData.GemstoneType
                                    ? gemstoneData.GemstoneType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("GemstoneType", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {gemStoneTypeList &&
                                  gemStoneTypeList.map((item) => {
                                    return (
                                      <Option
                                        key={item.primaryGemstoneTypeID}
                                        value={item.primaryGemstoneTypeID}
                                      >
                                        {item.primaryGemstoneTypeName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {t("Dimension Unit Of Measure")} :{" "}
                              </label>
                              <input
                                type="text"
                                name="DimensionUnitOfMeasure"
                                value={gemstoneData.DimensionUnitOfMeasure}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Dimension Unit Of Measure"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Number Of Gemstones")} </label>
                              <input
                                type="text"
                                name="NoOfGemstones"
                                value={gemstoneData.NoOfGemstones}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Number Of Gemstones"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Gemstone Shape")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Select GemstoneShape"
                                optionFilterProp="children"
                                value={
                                  gemstoneData.GemstoneShape
                                    ? gemstoneData.GemstoneShape
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("GemstoneShape", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {gemStoneShapeList &&
                                  gemStoneShapeList.map((item) => {
                                    return (
                                      <Option
                                        key={item.primaryDiamondID}
                                        value={item.primaryDiamondID}
                                      >
                                        {item.primaryDiamondName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Gemstone Origin")} </label>
                              <input
                                type="text"
                                name="GemstoneOrigin"
                                value={gemstoneData.GemstoneOrigin}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Origin"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {t("Gemstone Carat Weight")}{" "}
                              </label>
                              <input
                                type="number"
                                min="0"
                                max="100"
                                onKeyPress={onKeyPressMinusEvent}
                                name="GemstoneCaratWeight"
                                value={gemstoneData.GemstoneCaratWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Carat Weight"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Gemstone Dimensions")} </label>
                              <input
                                type="text"
                                name="GemstoneDimensions"
                                value={gemstoneData.GemstoneDimensions}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Dimensions"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Gemstone Quality")} </label>
                              <input
                                type="text"
                                name="GemstoneQuality"
                                value={gemstoneData.GemstoneQuality}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Quality"
                              />
                            </div>
                          </div>
                        </div> */}
															<div className="row">
																{gemstoneData &&
																	gemstoneData.map((x, i) => {
																		return (
																			<Fragment>
																				<div className="col-md-4 ">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Total Diamond Weight")}{" "}
																							{i + 1}
																						</label>
																						<input
																							name="TotalDiamondWeight"
																							value={x.TotalDiamondWeight}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							type="number"
																							placeholder={t(
																								"Enter Total Diamond Weight"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Total Gemstone Weight")}{" "}
																							{i + 1}
																						</label>
																						<input
																							name="TotalGemstoneWeight"
																							value={x.TotalGemstoneWeight}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							type="number"
																							placeholder={t(
																								"Enter Total Gemstone Weight"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Type")} {i + 1}
																						</label>
																						<Select
																							className="border__grey"
																							placeholder={t(
																								"Select GemstoneType"
																							)}
																							optionFilterProp="children"
																							value={
																								x.GemstoneType
																									? x.GemstoneType
																									: null
																							}
																							onChange={(e) =>
																								handleGemSelectChange(
																									"GemstoneType",
																									e,
																									i
																								)
																							}
																							showSearch>
																							{gemStoneTypeList &&
																								gemStoneTypeList.map((item) => {
																									return (
																										<Option
																											key={
																												item.primaryGemstoneTypeID
																											}
																											value={
																												item.primaryGemstoneTypeID
																											}>
																											{
																												item.primaryGemstoneTypeName
																											}
																										</Option>
																									);
																								})}
																						</Select>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{t("Dimension Unit Of Measure")}{" "}
																							{i + 1}
																						</label>
																						<input
																							type="text"
																							name="DimensionUnitOfMeasure"
																							value={x.DimensionUnitOfMeasure}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Dimension Unit Of Measure"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Number Of Gemstones")} {i + 1}
																						</label>
																						<input
																							type="text"
																							onKeyPress={onKeyPressEvent}
																							name="NoOfGemstones"
																							value={
																								x.NoOfGemstones == 0
																									? ""
																									: x.NoOfGemstones
																							}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Number Of Gemstones"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Shape")} {i + 1}
																						</label>
																						<Select
																							className="border__grey"
																							placeholder={t(
																								"Select GemstoneShape"
																							)}
																							optionFilterProp="children"
																							value={
																								x.GemstoneShape
																									? x.GemstoneShape
																									: null
																							}
																							onChange={(e) =>
																								handleGemSelectChange(
																									"GemstoneShape",
																									e,
																									i
																								)
																							}
																							showSearch>
																							<Option value="0">
																								{t("Shape Name")}
																							</Option>
																							{gemStoneShapeList &&
																								gemStoneShapeList.map(
																									(item) => {
																										return (
																											<Option
																												key={
																													item.primaryDiamondID
																												}
																												value={
																													item.primaryDiamondID
																												}>
																												{
																													item.primaryDiamondName
																												}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Origin")} {i + 1}
																						</label>
																						<input
																							type="text"
																							name="GemstoneOrigin"
																							value={x.GemstoneOrigin}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Origin"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Carat Weight")}{" "}
																							{i + 1}
																						</label>
																						<input
																							type="text"
																							onKeyPress={onKeyPressEvent}
																							name="GemstoneCaratWeight"
																							value={
																								x.GemstoneCaratWeight == 0
																									? ""
																									: x.GemstoneCaratWeight
																							}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Carat Weight"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Dimensions")} {i + 1}
																						</label>
																						<input
																							type="text"
																							name="GemstoneDimensions"
																							value={x.GemstoneDimensions}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Dimensions"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Quality")} {i + 1}
																						</label>
																						<input
																							type="text"
																							name="GemstoneQuality"
																							value={x.GemstoneQuality}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Quality"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Stone Creation Method")}{" "}
																							{i + 1}
																						</label>
																						<input
																							type="text"
																							name="StoneCreationMethod"
																							value={x.StoneCreationMethod}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Stone Creation Method"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Gemstone Lot Code")} {i + 1}
																						</label>
																						<input
																							type="text"
																							name="GemstoneLotCode"
																							value={x.GemstoneLotCode}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Lot Code"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t(
																								"Gemstone Setting Labor Code"
																							)}{" "}
																							{i + 1}
																						</label>
																						<input
																							type="text"
																							name="GemstoneSettingLaborCode"
																							value={x.GemstoneSettingLaborCode}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Gemstone Setting Labor Code"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Stone Treatment Method")}{" "}
																							{i + 1}
																						</label>
																						<input
																							type="text"
																							name="StoneTreatementMethod"
																							value={x.StoneTreatementMethod}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Stone Treatment Method"
																							)}
																						/>
																					</div>
																				</div>
																				<div className="col-md-4">
																					<div className="input__block">
																						<label>
																							{" "}
																							{t("Additional Information")}{" "}
																							{i + 1}
																						</label>
																						<textarea
																							rows={2}
																							name="GemStoneAdditionalInformation"
																							value={
																								x.GemStoneAdditionalInformation
																							}
																							onChange={(e) =>
																								handleGemInputChange(e, i)
																							}
																							placeholder={t(
																								"Enter Additional Information"
																							)}
																							// style={{ height: "80px" }}
																						/>
																					</div>
																				</div>
																				<div className="col-md-12 mb-2 mt-4">
																					{gemstoneData.length !== 1 &&
																						gemstoneData.length - 1 !== i && (
																							<button
																								className="primary-btn"
																								onClick={() =>
																									handleRemoveClick(i)
																								}>
																								{t("Remove")}
																							</button>
																						)}
																					{gemstoneData.length - 1 === i && (
																						<button
																							className={
																								gemstoneData.length == 5
																									? "disabled-btn mx-2"
																									: "primary-btn mx-2"
																							}
																							onClick={handleAddClick}
																							disabled={
																								gemstoneData.length == 5
																									? true
																									: false
																							}>
																							{t("Add")}
																						</button>
																					)}
																				</div>
																			</Fragment>
																		);
																	})}
															</div>
														</div>
													</Tab>
												)}

												{/* <Tab eventKey="watch" title={t("Watch")}>
                      <div className="">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Band Material")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Band Material"
                                optionFilterProp="children"
                                value={
                                  watchData.BandMaterial
                                    ? watchData.BandMaterial
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("BandMaterial", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Band Material")} </Option>
                                {watchBandMaterialList &&
                                  watchBandMaterialList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.material}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Number Type")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Number Type"
                                optionFilterProp="children"
                                value={
                                  watchData.NumberType
                                    ? watchData.NumberType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("NumberType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Number Type")} </Option>
                                {watchNumberTypeList &&
                                  watchNumberTypeList.map((item) => {
                                    return (
                                      <Option key={item.id} value={item.id}>
                                        {item.numberType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Band Type")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Band Type:"
                                optionFilterProp="children"
                                value={
                                  watchData.BandType ? watchData.BandType : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("BandType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Band Type")} </Option>
                                {bandTypeList &&
                                  bandTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.bandType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Size")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Size"
                                optionFilterProp="children"
                                value={watchData.Size ? watchData.Size : null}
                                onChange={(e) => handleSelectChange("Size", e)}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Size")} </Option>
                                {watchSizeList &&
                                  watchSizeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchSize}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Case Material")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Case Material"
                                optionFilterProp="children"
                                value={
                                  watchData.CaseMaterial
                                    ? watchData.CaseMaterial
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CaseMaterial", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Case Material")} </Option>
                                {caseMaterialList &&
                                  caseMaterialList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.caseMaterial}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Type")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Type"
                                optionFilterProp="children"
                                value={watchData.Type ? watchData.Type : null}
                                onChange={(e) => handleSelectChange("Type", e)}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Type")} </Option>
                                {watchTypeList &&
                                  watchTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Case Shape")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Case Shape"
                                optionFilterProp="children"
                                value={
                                  watchData.CaseShape
                                    ? watchData.CaseShape
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CaseShape", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Case Shape")} </Option>
                                {caseShapeList &&
                                  caseShapeList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.caseShape}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {t("Comes Packaged In")} ? :{" "}
                              </label>
                              <Select
                                className="border__grey"
                                placeholder="Select Comes Packaged In "
                                optionFilterProp="children"
                                value={
                                  watchData.ComesPackagedIn
                                    ? watchData.ComesPackagedIn
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("ComesPackagedIn", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Select")} </Option>
                                <Option value={t("Yes")}> {t("Yes")} </Option>
                                <Option value="No"> {t("No")} </Option>
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Crystal Type")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Crystal Type"
                                optionFilterProp="children"
                                value={
                                  watchData.CrystalType
                                    ? watchData.CrystalType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CrystalType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Crystal Type")} </Option>
                                {crystalTypeList &&
                                  crystalTypeList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.crystalType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Dial Color")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Dial Color"
                                optionFilterProp="children"
                                value={
                                  watchData.DialColor
                                    ? watchData.DialColor
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("DialColor", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Dial Color")} </Option>
                                {watchDialColorList &&
                                  watchDialColorList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.watchDialColor}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Manufacture Date")}: </label>
                              <input
                                type="text"
                                name="ManufactureDate"
                                value={pricingData.ManufactureDate}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Manufacture Date"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Display Type")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Display Type"
                                optionFilterProp="children"
                                value={
                                  watchData.DisplayType
                                    ? watchData.DisplayType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("DisplayType", e)
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Display Type")} </Option>
                                {displayTypeList &&
                                  displayTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.displayType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Certification")}: </label>
                              <input
                                type="text"
                                name="Certification"
                                value={pricingData.Certification}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Certification"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Movement")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Movement"
                                optionFilterProp="children"
                                value={
                                  watchData.Movement ? watchData.Movement : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("Movement", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Movement")} </Option>
                                {watchMovementList &&
                                  watchMovementList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchMovement}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {t("Energy")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Energy"
                                optionFilterProp="children"
                                value={
                                  watchData.Energy ? watchData.Energy : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("Energy", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value=""> {t("Energy")} </Option>
                                {watchEnergyList &&
                                  watchEnergyList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchEngergy}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab> */}
											</Tabs>

											<div className="col-md-6 mt-4">
												<Button
													className="primary-btn"
													onClick={handleSubmit}
													loading={submitLoading}>
													{t("Save Product")}
												</Button>
												<Button
													className="primary-btn ml-2"
													onClick={handleCancel}>
													{t("Cancel Product")}
												</Button>
												{/* <button class="primary-btn ml-2" onClick={handleCancel}>
                          {t("Cancel Product")}
                        </button> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Spin>
				)}

				<Modal
					title={" "}
					className="modalconsupld__section"
					centered
					visible={visible6}
					onCancel={() => handleOnOk()}
					width={400}
					maskClosable={false}
					footer={[
						<Button key="submit" type="primary" onClick={() => handleOnOk()}>
							{t("OK")}
						</Button>,
					]}>
					<div className="col-lg-12">
						<div className="modal__body">
							<div className="col-lg-12">
								<div className="form__fields border-0 p-0">
									<div className="row">
										<div className="col-lg-12 text-center">
											<p>
												{t(
													"Your Jewelry are Added Successfully. However it will take some moments to Add them from cache."
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</React.Fragment>
	);
};

export default AddProducts;
