import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Switch, Table, Spin } from "antd";
import { NavigationData } from "../../../helper/commanData";
import DiamondLinksNavigationDataService from "../../../services/diamondLinks-navigation.service";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const Navigation = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [ViewNavigationData, setViewNavigationData] = useState({
    standardSearch: true,
    advancedSearch: true,
    fancyColoredSearch: true,
    labGrownDiamonds: true,
    requestDiamond: true,
    compareDiamond: true,
    standardSearchOrderNo: "",
    advancedSearchOrderNo: "",
    fancyColoredSearchOrderNo: "",
    requestDiamondOrderNo: "",
    compareDiamondOrderNo: "",
    labGrownDiamondsOrderNo: "",
  });
  const [NaviDataTable, setNaviDataTable] = useState(NavigationData);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isStatic, setIsStatic] = useState();
  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      setViewNavigationData({
        ...ViewNavigationData,
        [name]: e,
      });
    } else {
      setViewNavigationData({
        ...ViewNavigationData,
        [e.target.name]: e.target.value,
      });
    }
  };

  //NULL VALUE SEND FUNCTIONALTIY ON UPDATE

  const handleNaviTableChange = (name, row, e) => {
    setDisableBtn(false);
    if (name === "showCheckBox") {
      const tempDealerColumnList = inputTextValue.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          if (e.target.checked) {
            return { ...x, showCheckBox: e.target.checked };
          } else {
            return {
              ...x,
              extUrl: "",
              openIn: 0,
              showCheckBox: e.target.checked,
            };
          }
        } else return x;
      });
      setinputTextValue(tempDealerColumnList);
    }
    if (name === "extUrl") {
      const tempDealerColumnList = inputTextValue.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          return { ...x, extUrl: e.target.value };
        } else return x;
      });
      setinputTextValue(tempDealerColumnList);
    }
    if (name === "openIn") {
      const tempDealerColumnList = inputTextValue.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          return { ...x, openIn: e };
        } else return x;
      });
      setinputTextValue(tempDealerColumnList);
    }
  };

  const handleStaticChange = (name, row, e) => {
    setDisableBtn(false);
    if (name === "showCheckBox") {
      const tempDealerColumnList = NaviDataTable.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          if (e.target.checked) {
            return { ...x, showCheckBox: e.target.checked };
          } else {
            return {
              ...x,
              extUrl: "",
              openIn: 0,
              showCheckBox: e.target.checked,
            };
          }
        } else return x;
      });
      setNaviDataTable(tempDealerColumnList);
    }
    if (name === "extUrl") {
      const tempDealerColumnList = NaviDataTable.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          return { ...x, extUrl: e.target.value };
        } else return x;
      });
      setNaviDataTable(tempDealerColumnList);
    }
    if (name === "openIn") {
      const tempDealerColumnList = NaviDataTable.map((x) => {
        if (x.subMenuID === row.subMenuID) {
          return { ...x, openIn: e };
        } else return x;
      });
      setNaviDataTable(tempDealerColumnList);
    }
  };

  const handleSubmit = async (e) => {
    handleUpdateDiamondLinkNavigationDetails(DealerId);
  };

  /*API Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  const [inputTextValue, setinputTextValue] = useState([]);
  const [DealerId, setDealerID] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerID(loginDetials.responseData.dealerId);
        handleGetNavigationDetails(loginDetials.responseData.dealerId);
      }
    }
  }, []);
  const handleGetNavigationDetails = (id) => {
    setLoading(true);
    try {
      let inputData = {
        dealerId: id,
      };
      DiamondLinksNavigationDataService.GetNavigationDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length !== 0) {
              setViewNavigationData({
                standardSearch: responseData[0].standardSearch,
                advancedSearch: responseData[0].advancedSearch,
                fancyColoredSearch: responseData[0].fancyColoredSearch,
                labGrownDiamonds: responseData[0].labGrownDiamonds,
                requestDiamond: responseData[0].requestDiamond,
                compareDiamond: responseData[0].compareDiamond,
                standardSearchOrderNo:
                  responseData[0].standardSearchOrderNo.toString(),
                advancedSearchOrderNo:
                  responseData[0].advancedSearchOrderNo.toString(),
                fancyColoredSearchOrderNo:
                  responseData[0].fancyColoredSearchOrderNo.toString(),
                requestDiamondOrderNo:
                  responseData[0].requestDiamondOrderNo.toString(),
                compareDiamondOrderNo:
                  responseData[0].compareDiamondOrderNo.toString(),
                labGrownDiamondsOrderNo:
                  responseData[0].labGrownDiamondsOrderNo.toString(),
              });
              setinputTextValue(responseData[0].navigationMasterLinkOptions);
              setLoading(false);
              if (
                responseData[0].navigationMasterLinkOptions == null ||
                responseData[0].navigationMasterLinkOptions == []
              ) {
                setIsStatic(true);
              } else {
                setIsStatic(false);
                setLoading(false);
              }
            } else {
              setLoading(false);
              setIsStatic(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleUpdateDiamondLinkNavigationDetails = (id) => {
    try {
      let inputData = {
        dealerId: id,
        standardSearch:
          ViewNavigationData.standardSearch == false &&
          ViewNavigationData.fancyColoredSearch == false &&
          ViewNavigationData.requestDiamond == false &&
          ViewNavigationData.compareDiamond == false &&
          ViewNavigationData.labGrownDiamonds == false
            ? true
            : ViewNavigationData.standardSearch,
        advancedSearch: ViewNavigationData.advancedSearch,
        fancyColoredSearch: ViewNavigationData.fancyColoredSearch,
        requestDiamond: ViewNavigationData.requestDiamond,
        compareDiamond: ViewNavigationData.compareDiamond,
        labGrownDiamonds: ViewNavigationData.labGrownDiamonds,
        standardSearchOrderNo:
          ViewNavigationData.standardSearch == false &&
          ViewNavigationData.fancyColoredSearch == false &&
          ViewNavigationData.requestDiamond == false &&
          ViewNavigationData.compareDiamond == false &&
          ViewNavigationData.labGrownDiamonds == false
            ? "1"
            : ViewNavigationData.standardSearchOrderNo,
        advancedSearchOrderNo: ViewNavigationData.advancedSearchOrderNo,
        fancyColoredSearchOrderNo: ViewNavigationData.fancyColoredSearchOrderNo,
        requestDiamondOrderNo: ViewNavigationData.requestDiamondOrderNo,
        compareDiamondOrderNo: ViewNavigationData.compareDiamondOrderNo,
        labGrownDiamondsOrderNo: ViewNavigationData.labGrownDiamondsOrderNo,
        navigationMasterLinkOptions: isStatic ? NaviDataTable : inputTextValue,
      };
      setLoading(true);
      DiamondLinksNavigationDataService.UpdateDiamondLinkNavigationDetails(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          setLoading(true);
          if (message === "Success") {
            NotificationManager.success(t("Updated Successfully"));
            handleGetNavigationDetails(DealerId);
            setLoading(false);
            setDisableBtn(true);
            if (responseData) {
              handleGetNavigationDetails(DealerId);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /*API Binding Ends*/
  return (
    <div className="navigation__section">
      <div className="form__fields">
        <Spin spinning={loading}>
          <div className="col-lg-12">
            <div className="form__section row">
              <div className="col-md-6">
                <div className="col-md-12 mt-0">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {t("Navigation")}{" "}
                  </h4>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Standard Search")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="standardSearch"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.standardSearch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "standardSearch")
                        }
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Search")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.standardSearchOrderNo}
                          onChange={(e) =>
                            handleOnChange(e, "select", "standardSearchOrderNo")
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Advanced Search")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="AdvanvedSearch"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.advancedSearch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "advancedSearch")
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Serach")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.advancedSearchOrderNo}
                          onChange={(e) =>
                            handleOnChange(e, "select", "advancedSearchOrderNo")
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Fancy Colored Search")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="fancyColoredSearch"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.fancyColoredSearch}
                        onChange={(e) =>
                          handleOnChange(e, "select", "fancyColoredSearch")
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Serach")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.fancyColoredSearchOrderNo}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "fancyColoredSearchOrderNo"
                            )
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Lab Grown Search")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="labGrownDiamonds"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.labGrownDiamonds}
                        onChange={(e) =>
                          handleOnChange(e, "select", "labGrownDiamonds")
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Serach")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.labGrownDiamondsOrderNo}
                          onChange={(e) =>
                            handleOnChange(
                              e,
                              "select",
                              "labGrownDiamondsOrderNo"
                            )
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Request a Diamond")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="requestDiamond"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.requestDiamond}
                        onChange={(e) =>
                          handleOnChange(e, "select", "requestDiamond")
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Serach")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.requestDiamondOrderNo}
                          onChange={(e) =>
                            handleOnChange(e, "select", "requestDiamondOrderNo")
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="desc__div">
                  <div className="headtxt__div">
                    <span className=""> {t("Compare Diamond")} </span>
                  </div>

                  <div className="inpt__div row">
                    <div className="col-md-4 mobile__bottomspace">
                      <Switch
                        name="compareDiamond"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={ViewNavigationData.compareDiamond}
                        onChange={(e) =>
                          handleOnChange(e, "select", "compareDiamond")
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <div className="input__block mb-0">
                        <Select
                          className="border__grey"
                          placeholder={t("Standard Serach")}
                          name=""
                          optionFilterProp="children"
                          value={ViewNavigationData.compareDiamondOrderNo}
                          onChange={(e) =>
                            handleOnChange(e, "select", "compareDiamondOrderNo")
                          }
                        >
                          <Option value="1">1</Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">5</Option>
                          <Option value="6">6</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <p className="workarea__heading mt-0 mb-2">
                  {" "}
                  {t("Left Navigation for MasterLink")}{" "}
                </p>
                <div className="table__scroll">
                  <Table
                    className="vdiamond__table navigation_table navtable"
                    columns={[
                      {
                        dataIndex: isStatic ? "showCheckBox" : "showCheckBox",
                        width: 50,
                        render: (item, row) => {
                          return (
                            <div className="radio__block col-md-3 mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  row.showCheckBox == true ? true : false
                                }
                                onChange={(e) =>
                                  isStatic
                                    ? handleStaticChange("showCheckBox", row, e)
                                    : handleNaviTableChange(
                                        "showCheckBox",
                                        row,
                                        e
                                      )
                                }
                                id={row.subMenuID}
                                name={
                                  isStatic ? "showCheckBox" : "showCheckBox"
                                }
                              />
                            </div>
                          );
                        },
                      },
                      {
                        dataIndex: isStatic ? "data" : "subMenuID",
                        width: 150,
                        render: (item, row) => {
                          return (
                            <div className="input__block mb-0">
                              {isStatic ? (
                                <span> {row.data ? row.data : null} </span>
                              ) : (
                                <span>
                                  {row.subMenuID == 1
                                    ? t("Standard Search")
                                    : row.subMenuID == 2
                                    ? t("Our Signature Diamond")
                                    : row.subMenuID == 3
                                    ? t("Best Value Diamonds")
                                    : row.subMenuID == 4
                                    ? t("Private Reserve Diamonds")
                                    : row.subMenuID == 62
                                    ? t("HeartStar Diamonds")
                                    : null}
                                </span>
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        dataIndex: isStatic ? "extUrl" : "extUrl",
                        width: 300,
                        render: (item, row) => {
                          return (
                            <div className="input__block mb-0">
                              <input
                                name={isStatic ? "extUrl" : "extUrl"}
                                placeholder={t("Enter Text Here")}
                                inputTextValue
                                input="text"
                                value={isStatic ? row.extUrl : row.extUrl}
                                onChange={(e) =>
                                  isStatic
                                    ? handleStaticChange("extUrl", row, e)
                                    : handleNaviTableChange("extUrl", row, e)
                                }
                              />
                            </div>
                          );
                        },
                      },
                      {
                        dataIndex: "openIn",
                        width: 150,

                        render: (item, row) => {
                          return (
                            <div className="input__block mb-0">
                              <Select
                                showSearch
                                optionFilterProp="children"
                                name={isStatic ? "openIn" : "openIn"}
                                onChange={(e) =>
                                  isStatic
                                    ? handleStaticChange("openIn", row, e)
                                    : handleNaviTableChange("openIn", row, e)
                                }
                                className="border__grey"
                                value={isStatic ? row.openIn : row.openIn}
                              >
                                <Option value={0}>
                                  {" "}
                                  {t("Select Option")}{" "}
                                </Option>
                                <Option value={1}> {t("Same Window")} </Option>
                                <Option value={2}> {t("New Window")} </Option>
                                <Option value={3}> {t("In Frame")} </Option>
                              </Select>
                            </div>
                          );
                        },
                      },
                    ]}
                    dataSource={isStatic ? NaviDataTable : inputTextValue}
                    pagination={false}
                  ></Table>
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <p>
                  *{" "}
                  {t(
                    "New Diamond Dealer inventories are automatically added to your Diamond Dealer List. If you wish this to not occur, then"
                  )}{" "}
                  <Link
                    to={"/options"}
                    state={{
                      isSetup: true,
                    }}
                  >
                    {t("Click Here")}
                  </Link>
                </p>
              </div>
              <div className="col-lg-12 mt-4">
                <button
                  className={disableBtn ? "disabled-btn" : "primary-btn"}
                  onClick={handleSubmit}
                  disabled={disableBtn ? "true" : ""}
                >
                  {" "}
                  {t("Update Settings")}{" "}
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Navigation;
