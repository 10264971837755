import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const CreateDiscardCollection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      handleCreateDiscardCollection(
        location.state.DealerID,
        location.state.SummaryID
      );
    }
  }, []);

  const handleCreateDiscardCollection = (id, summaryId) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        summaryID: summaryId.toString(),
      };
      setLoading(true);
      CatalogJewelryDataService.CreateDiscardCollection(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(
              t("Collection has been created successfully.")
            );
            setLoading(false);

            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            NotificationManager.error(t("Collection has not been created."));
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <div>Hello</div>
      </Spin>
    </>
  );
};

export default CreateDiscardCollection;
