import React, { useState, useEffect } from "react";
import {
	Input,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Switch,
	Tooltip,
} from "antd";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import userIcon from "../../assets/images/user-icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Catlog = () => {
	const { t } = useTranslation();
	const initialState = {
		appActivationControl: {},
	};
	const [state, setState] = useState(initialState);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	// const [parentCategoryIDs, setparentCategoryIDs] = useState([]);
	// const [jewelryCategoryList, setjewelryCategoryList] = useState([]);
	useEffect(() => {
		GetProductCategoryList();
	}, []);
	const GetProductCategoryList = () => {
		try {
			setLoading(true);
			CatalogJewelryDataService.GetProductCategoryList()
				.then((response) => {
					var responseData = response.data.responseData;
					var message = response.data.message;
					setLoading(false);
					if (message === "Success") {
						setData(responseData);
						// setparentCategoryIDs(responseData.parentCategoryIDs);
						// setjewelryCategoryList(responseData.jewelryCategoryList);
					} else {
						setData([]);
						// setparentCategoryIDs([]);
						// setjewelryCategoryList([]);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const [catlogData, setCatlogData] = useState({
		productName: "",
		retailDescription: "",
		retailStock: "",
		style: "",
		dealerStock: "",
		retailPrice: "",
		availability: "",
		collection: "",
		materialType: "",
		visibility: true,
		materialColor: "",
		productType: "",
		parentSKU: "",
	});
	const [validations, setValidations] = useState({
		productNameVal: "",
		materialTypeVal: "",
		retailDescriptionVal: "",
		retailPriceVal: "",
	});

	const { Option } = Select;

	const retail = <span>{t("Tooltip content not given.")}</span>;

	const style = (
		<span>
			{t("This must be a unique item number identifying the particular item or the 'parent' item which has many variations - if this is a style root then you 	variables such as carat weight, metal type, diamond quality, length etc")}

			
		</span>
	);

	const dealer_stock = (
		<span>{t("This is a unique identification number of this particular item.")}</span>
	);

	const availability = (
		<span>{t("Standard delivery time is 2 weeks unless you specify otherwise.")}</span>
	);

	const sku = <span>{t("Leave blank, unless adding a configurable product.")}</span>;

	function onChange(value) {}

	function onSearch(val) {}

	function onChange(e) {}

	const handleChangeCheckBox = (e, name) => {
		let tempState = state;
		if (name === name && e.target.checked === false) {
			tempState.appActivationControl[name] = false;
			// tempState.appActivationControl["Big & important Bracelets"] = false;
			// tempState.appActivationControl["Chain"] = false;
			// tempState.appActivationControl["Cluff"] = false;
		}
		// if (name === "Earrings" && e.target.checked === false) {
		//   tempState.appActivationControl["Fashion Earrings"] = false;
		//   tempState.appActivationControl["Button"] = false;
		//   tempState.appActivationControl["Chandelier"] = false;
		//   tempState.appActivationControl["Dangle"] = false;
		// }
		// if (name === "Necklaces" && e.target.checked === false) {
		//   tempState.appActivationControl["Basic Fashion Neckwears"] = false;
		//   tempState.appActivationControl["Beads"] = false;
		//   tempState.appActivationControl["Big & Important"] = false;
		//   tempState.appActivationControl["Briolette"] = false;
		// }
		// if (name === "Watches" && e.target.checked === false) {
		//   tempState.appActivationControl["Mens"] = false;
		//   tempState.appActivationControl["Womens"] = false;
		//   tempState.appActivationControl["Pocket Watch"] = false;
		//   tempState.appActivationControl["Diamond"] = false;
		// }
		// if (name === "Charms" && e.target.checked === false) {
		//   tempState.appActivationControl["Charm Necklaces"] = false;
		//   tempState.appActivationControl["Animals"] = false;
		//   tempState.appActivationControl["Apparel"] = false;
		//   tempState.appActivationControl["Birthday & Anniversaries"] = false;
		// }
		// if (name === "Chains" && e.target.checked === false) {
		//   tempState.appActivationControl["Crystal"] = false;
		//   tempState.appActivationControl["Fine Crystal"] = false;
		//   tempState.appActivationControl["Key Chains"] = false;
		//   tempState.appActivationControl["Sunglasses"] = false;
		// }

		if (e.target) {
			tempState.appActivationControl[name] = e.target.checked;
		} else {
			tempState[name] = e;
		}
		setState((prevState) => ({
			...prevState,
			...tempState,
		}));
	};

	const handleOnChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setCatlogData({
				...catlogData,
				[name]: e,
			});
		} else {
			setCatlogData({
				...catlogData,
				[e.target.name]: e.target.value,
			});
		}
	};
	const handleValidation = () => {
		const validations = {};
		let isValid = true;

		if (!catlogData.productName.trim()) {
			isValid = false;
			validations.productNameVal = t("Product name is compulsory");
		}
		if (!catlogData.retailDescription.trim()) {
			isValid = false;
			validations.retailDescriptionVal = t("Retail Description is compulsory");
		}
		if (!catlogData.retailPrice.trim()) {
			isValid = false;
			validations.retailPriceVal = t("Retail Price is compulsory");
		}
		if (!catlogData.materialType) {
			isValid = false;
			validations.materialTypeVal = t("Kindly select the Material Type");
		}

		if (!isValid) {
			setValidations(validations);
		}

		return isValid;
	};

	const handleSubmit = () => {
		const isValid = handleValidation();
		if (!isValid) {
			return false;
		}
		setCatlogData({
			productName: "",
			retailDescription: "",
			retailStock: "",
			style: "",
			dealerStock: "",
			retailPrice: "",
			availability: "",
			collection: "",
			materialType: "",
			visibility: true,
			materialColor: "",
			productType: "",
			parentSKU: "",
		});
		setValidations({
			productNameVal: "",
			materialTypeVal: "",
			retailDescriptionVal: "",
			retailPriceVal: "",
		});
	};
	const handleCancel = () => {
		setCatlogData({
			productName: "",
			retailDescription: "",
			retailStock: "",
			style: "",
			dealerStock: "",
			retailPrice: "",
			availability: "",
			collection: "",
			materialType: "",
			visibility: true,
			materialColor: "",
			productType: "",
			parentSKU: "",
		});
		setValidations({
			productNameVal: "",
			materialTypeVal: "",
			retailDescriptionVal: "",
			retailPriceVal: "",
		});
	};

	return (
		<React.Fragment>
			<div className="form__fields">
				<div className="row">
					<div className="col-md-6">
						<div className="input__block">
							<label> {t("Add Image")}</label>
							<div className="col-md-12 image_block">
								<div class="row">
									<div class="col-lg-5 col-md-12 divimage_block">
										<label
											htmlFor="file-upload"
											class="primary-btn choose_file_upload">
											{t("Choose Image")}
										</label>
										<input id="file-upload" type="file" />
										<span className="mb-4">
											{t("No File Choosen")} <span className="text-red">*</span>
										</span>
										<span className="">500 X 500 Pixels</span>
										<span className="">JPG Format</span>
									</div>
									<div class="col-lg-7 col-md-12">
										<p className="mt-2">{t("OR")}</p>
										<label className="mt-2">{t("Load Image From URL")}</label>
										<input type="text" placeholder={t("Enter Url")} />
										<button className="primary-btn mt-3">{t("Load Image")}</button>
									</div>
								</div>
								<div className="row d-none">
									<div className="choose_file_divimg">
										<img src={userIcon} alt="img" />
										<span>x</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Product Name")} <span>*</span>
							</label>
							<input
								type="text"
								placeholder={t("Enter Product Name")}
								name="productName"
								value={catlogData.productName}
								onChange={handleOnChange}
								className={validations.productNameVal && "border__red"}
							/>
							<div>
								{validations.productNameVal && (
									<p className="error-color-red">
										{validations.productNameVal}
									</p>
								)}
							</div>
						</div>
						<div className="input__block">
							<label>
								{" "}
								{t("Retail Description")}<span>*</span>
							</label>
							<textarea
								id=""
								name="retailDescription"
								value={catlogData.retailDescription}
								onChange={handleOnChange}
								rows="6"
								cols="50"
								placeholder={t("Enter Retail Description")}
								className={
									validations.retailDescriptionVal && "border__red"
								}></textarea>
							<div>
								{validations.retailDescriptionVal && (
									<p className="error-color-red">
										{validations.retailDescriptionVal}
									</p>
								)}
							</div>
						</div>
					</div>

					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Retail Stock #")}
								<Tooltip placement="right" title={retail}>
									<img src={UnionIcon} alt="" />
								</Tooltip>
							</label>
							<input
								type="text"
								placeholder={t("Enter Retail Stock")}
								name="retailStock"
								value={catlogData.retailStock}
								onChange={handleOnChange}
							/>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
							{t("Style #")}
								<Tooltip placement="right" title={style}>
									<img src={UnionIcon} alt="" />
								</Tooltip>
							</label>
							<input
								type="text"
								placeholder={t("Enter Style")}
								name="style"
								value={catlogData.style}
								onChange={handleOnChange}
							/>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Dealer Stock #")}
								<Tooltip placement="right" title={dealer_stock}>
									<img src={UnionIcon} alt="" />
								</Tooltip>
							</label>
							<input
								type="text"
								placeholder={t("Enter Dealer Stock")}
								name="dealerStock"
								value={catlogData.dealerStock}
								onChange={handleOnChange}
							/>
						</div>
					</div>

					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Retail Price")} <span>*</span>
							</label>
							<input
								type="text"
								placeholder={t("Enter Retail Price")}
								name="retailPrice"
								value={catlogData.retailPrice}
								onChange={handleOnChange}
								className={validations.retailPriceVal && "border__red"}
							/>
							<div>
								{validations.retailPriceVal && (
									<p className="error-color-red">
										{validations.retailPriceVal}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Availability")}
								<Tooltip placement="right" title={availability}>
									<img src={UnionIcon} alt="" />
								</Tooltip>
							</label>
							<Select
								showSearch
								className="border__grey"
								placeholder={t("Delivery Time")}
								name="availability"
								optionFilterProp="children"
								onChange={(e) => handleOnChange(e, "select", "availability")}
								onSearch={onSearch}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}>
								<Option value="Delivery Time">{t("Delivery Time")}</Option>
								<Option value="24-48 hours">24-48 hours</Option>
								<Option value="1 Week">1 Week</Option>
								<Option value="2 Week">2 Week</Option>
								<Option value="2-3 Week">2-3 Week</Option>
								<Option value="3-4 Week">3-4 Week</Option>
								<Option value="4-6 Week">4-6 Week</Option>
								<Option value="6-8 Week">6-8 Week</Option>
							</Select>
						</div>
					</div>

					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label> {t("Collection")}</label>
							<Select
								showSearch
								className="border__grey"
								placeholder={t("Select Collection")}
								name="collection"
								optionFilterProp="children"
								onChange={(e) => handleOnChange(e, "select", "collection")}
								onSearch={onSearch}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="tom">Tom</Option>
							</Select>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{" "}
								{t("Material Type")} <span>*</span>
							</label>
							<Select
								showSearch
								placeholder={t("Select Material Type")}
								name="materialType"
								optionFilterProp="children"
								onChange={(e) => handleOnChange(e, "select", "materialType")}
								onSearch={onSearch}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}
								className={
									validations.materialTypeVal ? "border__red" : "border__grey"
								}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="tom">Tom</Option>
							</Select>
							<div>
								{validations.materialTypeVal && (
									<p className="error-color-red">
										{validations.materialTypeVal}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label> Visibility </label>
							<Switch
								name="visibility"
								checkedChildren={t("On")}
								unCheckedChildren={t("Off")}
								defaultChecked
								onChange={(e) => handleOnChange(e, "select", "visibility")}
							/>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label> {t("Material Color")}</label>
							<Select
								showSearch
								className="border__grey"
								placeholder={t("Select Material Color")}
								name="materialColor"
								optionFilterProp="children"
								onChange={(e) => handleOnChange(e, "select", "materialColor")}
								onSearch={onSearch}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}>
								<Option value="Metal Color">{t("Metal Color")}</Option>
								<Option value="Yellow">Yellow</Option>
								<Option value="White">White</Option>
								<Option value="Pink">Pink</Option>
								<Option value="Green">Green</Option>
								<Option value="Two Tone">Two Tone</Option>
								<Option value="Three Tone">Three Tone</Option>
								<Option value="Rose">Rose</Option>
								<Option value="Polished/Chrome">Polished/Chrome</Option>
								<Option value="Black">Black</Option>
								<Option value="N/A">N/A</Option>
								<Option value="Gunmetal">Gunmetal</Option>
								<Option value="Brown">Brown</Option>
								<Option value="Grey">Grey</Option>
								<Option value="Titanium">Titanium</Option>
								<Option value="Red">Red</Option>
								<Option value="Purple">Purple</Option>
								<Option value="Orange">Orange</Option>
								<Option value="Blue">Blue</Option>
								<Option value="Bronze">Bronze</Option>
								<Option value="Silver">Silver</Option>
								<Option value="Multi Color">Multi Color</Option>
								<Option value="Other">Other</Option>
								<Option value="Vanilla">Vanilla</Option>
								<Option value="Strawberry">Strawberry</Option>
								<Option value="Honey">Honey</Option>
								<Option value="Tungsten">Tungsten</Option>
								<Option value="Gold">Gold</Option>
								<Option value="Rose & White">Rose & White</Option>
								<Option value="Rose & Yellow">Rose & Yellow</Option>
								<Option value="White & Yellow">White & Yellow</Option>
								<Option value="Rhodium">Rhodium</Option>
							</Select>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label> {t("Product Type")}</label>
							<Select
								showSearch
								className="border__grey"
								placeholder={t("Select Product Type")}
								name="productType"
								optionFilterProp="children"
								onChange={(e) => handleOnChange(e, "select", "productType")}
								onSearch={onSearch}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >=
									0
								}>
								<Option value="Simple Product">Simple Product</Option>
								<Option value="Configurable Product">
									Configurable Product
								</Option>
							</Select>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="input__block">
							<label>
								{t("Parent SKU #")}
								<Tooltip placement="right" title={sku}>
									<img src={UnionIcon} alt="" />
								</Tooltip>
							</label>
							<span className="check_txt">{t("Check")}</span>
							<input
								type="text"
								placeholder={t("Enter Parent SKU")}
								name="parentSKU"
								value={catlogData.parentSKU}
								onChange={handleOnChange}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<h3 className="subheading">{t("Category")}</h3>
				</div>
			</div>

			<div className="form__fields">
				<div className="row">
					{data.jewelryCategoryList
						? data.jewelryCategoryList
								.filter((y) => y.parentCategoryID === 0)
								.map((item, i) => {
									return (
										<div className="col-md-6">
											<Accordion>
												<Accordion.Item
													eventKey={item.parentCategoryID}
													key={i}>
													<Accordion.Header>
														<div>
															<input
																type="checkbox"
																name={item.jewelryCategory}
																checked={
																	state.appActivationControl.jewelryCategory
																}
																onChange={(e) => {
																	handleChangeCheckBox(e, item.jewelryCategory);
																}}
																id={item.parentCategoryID}
															/>
															<label for={item.parentCategoryID}>
																{" "}
																{item.jewelryCategory}
															</label>
														</div>
													</Accordion.Header>
													<Accordion.Body>
														{data.jewelryCategoryList
															.filter(
																(x) =>
																	x.parentCategoryID === item.jewelryCategoryID
															)
															.map((bdata, k) => {
																return (
																	<div>
																		{data.jewelryCategoryList.filter(
																			(x) =>
																				x.parentCategoryID ===
																				bdata.jewelryCategoryID
																		).length > 0 ? (
																			<Accordion>
																				<Accordion.Item
																					eventKey={bdata.parentCategoryID}>
																					<Accordion.Header>
																						<div>
																							<input
																								type="checkbox"
																								name={bdata.jewelryCategory}
																								checked={
																									state.appActivationControl
																										.jewelryCategoryID
																								}
																								onChange={(e) => {
																									handleChangeCheckBox(
																										e,
																										bdata.jewelryCategory
																									);
																								}}
																								id={bdata.jewelryCategoryID}
																							/>
																							<label
																								for={bdata.jewelryCategoryID}>
																								{" "}
																								{bdata.jewelryCategory}
																							</label>
																						</div>
																					</Accordion.Header>
																					<Accordion.Body>
																						{data.jewelryCategoryList
																							.filter(
																								(x) =>
																									x.parentCategoryID ===
																									bdata.jewelryCategoryID
																							)
																							.map((ndata, j) => {
																								return (
																									<div>
																										<input
																											type="checkbox"
																											name={
																												ndata.jewelryCategory
																											}
																											checked={
																												state
																													.appActivationControl
																													.jewelryCategoryID
																											}
																											onChange={(e) => {
																												handleChangeCheckBox(
																													e,
																													ndata.jewelryCategory
																												);
																											}}
																											id={
																												ndata.jewelryCategoryID
																											}
																										/>
																										<label
																											for={
																												ndata.jewelryCategoryID
																											}>
																											{" "}
																											{ndata.jewelryCategory}
																										</label>
																									</div>
																								);
																							})}
																					</Accordion.Body>
																				</Accordion.Item>
																			</Accordion>
																		) : (
																			<div>
																				<input
																					type="checkbox"
																					name={data.jewelryCategory}
																					checked={
																						state.appActivationControl
																							.jewelryCategoryID
																					}
																					onChange={(e) => {
																						handleChangeCheckBox(
																							e,
																							bdata.jewelryCategory
																						);
																					}}
																					id={bdata.jewelryCategoryID}
																				/>
																				<label for={bdata.jewelryCategoryID}>
																					{" "}
																					{bdata.jewelryCategory}
																				</label>
																			</div>
																		)}
																	</div>
																);
															})}
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</div>
									);
								})
						: null}

					<div className="col-md-12 mt-4">
						<button className="primary-btn mt-md-3" onClick={handleSubmit}>
							{t("Save Product")}
						</button>
						<button className="primary-btn mt-md-3 ml-2" onClick={handleCancel}>
							{t("Cancel Product")}
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Catlog;
