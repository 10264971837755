import React from "react";
import { useTranslation } from "react-i18next";

const MasterLinkShoppingIntegration = () => {
  const { t } = useTranslation();
  return (
    <div className="shopintgrate__section">
      <div className="form__fields">
        <div className="col-md-12 mt-2">
          <h4 className="workarea__heading mt-0 mb-0">
            {" "}
            {""}{" "}
            {t("Masterlink Shopping cart configuration instructions")}:{" "}
            {""}{" "}
          </h4>
        </div>

        <div className="col-lg-12 mt-3">
          <p>
            {" "}
            {""}{" "}
            {t(
              "We will be sending parameters to the page on your website as below url"
            )}{" "}
            {""}{" "}
          </p>

          <a target="_blank" className="link__txt" style={{ cursor: "text" }}>
            http://www.yourwebsite.com/shoppingcart.aspx?DealerID=&ProductID=
          </a>

          <p className="subheading mt-4">
            {" "}
            {""} {t("Example Url")}: {""}{" "}
          </p>

          <a target="_blank" className="link__txt" style={{ cursor: "text" }}>
            http://www.yourwebsite.com/shoppingcart.aspx?DealerID=1234&ProductID=123456
          </a>

          <p className="subheading mt-4">
            {" "}
            {""} {t("To Get Product Detail")}: {""}{" "}
          </p>

          <p>
            {" "}
            {""}{" "}
            {t(
              "You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."
            )}{" "}
            {""}{" "}
          </p>

          <span>
            <a
              href="http://www.gemfind.net/GetDesignerProductInfo.asmx?op=FetchData"
              target="_blank"
              className="link__txt"
            >
              {" "}
              {t("Click Here")}{" "}
            </a>{" "}
            {t("to access API and checkout how it works online.")}{" "}
          </span>

          <p className="subheading mt-4">
            {" "}
            {""}{" "}
            {t(
              "Below is the list of parameters with respective possible values which can be supplied to each parameter."
            )}{" "}
            {""}{" "}
          </p>

          <p className="mt-4">
            <span className="headred__txt">
              {" "}
              {""} {t("DealerID")}:-{" "}
            </span>{" "}
            {t(
              "Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."
            )}{" "}
            {""}{" "}
          </p>

          <p className="mt-4">
            <span className="headred__txt">
              {" "}
              {""} {t("JewelryID")}:-{" "}
            </span>{" "}
            {t("Pass JewelryID # for which you want to get data.")} {""}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MasterLinkShoppingIntegration;
