import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import AdminReportDataService from "../../../services/admin-report.service";
import { Spin } from "antd";
import { useSelector } from "react-redux";

/*Initial State for Binding Starts*/
const initialClientsReportState = {
  clientsReportRadio: "vendor",
};
/*Initial State for Binding Ends*/

const ClientsReports = () => {
  const { t } = useTranslation();
  const [visible, setOpen] = useState(false);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [globalEvents, setGlobalEvents] = useState(initialClientsReportState);
  const [downloadDataloading, setDownloadDataloading] = useState(false);
  const [clientReportsGridDataList, setClientReportsGridDataList] = useState(
    []
  );
  const [clientDetailsList, setClientDetailsList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortColumnName, setSortColumnName] = useState("DealerId");
  const [sortType, setSortType] = useState("asc");
  const [EventsCount, setEventsCount] = useState(0);
  const [TotalEventsCount, setTotalEventsCount] = useState(0);
  const [key, setKey] = useState(true);
  const clientsReportRadioRef = useRef(null);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
        handleGetClientVendorRetailerReportsData(
          pageNo,
          pageSize,
          sortColumnName,
          sortType
        );
      }
    }
  }, []);

  const handleOnChangeRadio = (event) => {
    setGlobalEvents({
      ...globalEvents,
      clientsReportRadio: event.target.name,
    });
  };

  const OnDetailsClick = (item) => {
    setOpen(true);
    handleLoadDataByClientID(dealerId, item.dealerID);
  };

  /*API Binding Starts*/
  const handleGetClientVendorRetailerReportsData = (
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder
  ) => {
    clientsReportRadioRef.current = globalEvents.clientsReportRadio;
    let inputData = {
      pageIndex: pageNo,
      pageSize: pageSize,
      sortColumnName: sortColumnName || "DealerId",
      sortOrder: sortOrder || "ASC",
      clientType: globalEvents.clientsReportRadio,
    };

    setTableLoading(true);
    AdminReportDataService.GetClientVendorRetailerReportsData(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var clientReportsGridDataList =
          response.data.responseData.clientVendorRetailerReportsresplistData;
        var TotalEventsCount =
          response.data.responseData.clientVendorRetailerReportsresplistData[0]
            .totalcount;
        if (message === "Success") {
          if (responseData) {
            var temptotalRecords = clientReportsGridDataList[0].totalcount;
            setTotalCount(temptotalRecords);
            setEventsCount(pageSize);
            setTotalEventsCount(TotalEventsCount);
            setClientReportsGridDataList(clientReportsGridDataList);
          }
        } else {
          setClientReportsGridDataList([]);
          setTotalCount(0);
          setEventsCount(pageSize);
          setTotalEventsCount(0);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        setClientReportsGridDataList([]);
        setEventsCount(0);
        setTotalEventsCount(0);
        console.log(error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const handleLoadDataByClientID = (dealerId, clientID) => {
    let inputData = {
      dealerID: dealerId.toString(),
      clientID: clientID.toString(),
      clientType: globalEvents.clientsReportRadio,
    };
    setDetailsLoading(true);
    AdminReportDataService.LoadDataByClientID(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var clientDetailsList = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setClientDetailsList(clientDetailsList);
          }
        } else {
          setClientDetailsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setDetailsLoading(false);
        setClientDetailsList([]);
      })
      .finally(() => {
        setDetailsLoading(false);
      });
  };
  const handleDownloadClientreportCSV_ServerClick = () => {
    let inputData = {
      orderBy: "",
      clientType: clientsReportRadioRef?.current ?? "",
    };
    setDownloadDataloading(true);
    AdminReportDataService.downloadClientreportCSV_ServerClick(inputData)
      .then((response) => {
        openInNewTab(response.data.responseData);
      })
      .catch((error) => {
        setDownloadDataloading(false);
        console.log(error);
      })
      .finally(() => setDownloadDataloading(false));
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleApplyFilter = () => {
    setPageNo(1);
    setPageSize(10);
    setKey(!key);
    handleGetClientVendorRetailerReportsData(1, 10, sortColumnName, sortType);
  };

  /*Sorting API Starts*/
  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "DealerId";
      setSortColumnName(column);
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetClientVendorRetailerReportsData(
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };
  /*Sorting API Ends*/

  /*API Binding Ends*/
  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Clients Reports")}
              </h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="vendor"
                        name="vendor"
                        value={globalEvents.clientsReportRadio}
                        checked={
                          globalEvents.clientsReportRadio === "vendor"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="vendor"> {t("Vendor")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="uplaod__file">
                  <div className="radio__block col">
                    <div className="radio__btn">
                      <input
                        type="radio"
                        id="Retailer"
                        name="retailer"
                        value={globalEvents.clientsReportRadio}
                        checked={
                          globalEvents.clientsReportRadio === "retailer"
                            ? true
                            : false
                        }
                        onChange={handleOnChangeRadio}
                      />
                      <label htmlFor="Retailer"> {t("Retailer")}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="button__block mb-4 mobile-m-0">
              <button className="primary-btn mr-2" onClick={handleApplyFilter}>
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="limit_section">
            <div className="col-lg-12 mt-3">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-4 rules__div">
                    <span className="rules__txt">
                      {" "}
                      <span className="rules__txtno">
                        {EventsCount > TotalEventsCount ? (
                          <span className="rules__txtno">
                            {TotalEventsCount} / {TotalEventsCount}
                          </span>
                        ) : (
                          <span className="rules__txtno">
                            {EventsCount} / {TotalEventsCount}
                          </span>
                        )}
                      </span>{" "}
                      {t("Events")}
                    </span>
                  </div>
                  <div className="col-lg-8 crud__div">
                    <Button
                      className="primary-btn"
                      onClick={() =>
                        handleDownloadClientreportCSV_ServerClick()
                      }
                      loading={downloadDataloading}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {t("Download")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={key}
              className="pendjewelreq__tbl"
              loading={tableLoading}
              columns={[
                {
                  title: t("Dealer ID"),
                  dataIndex: "dealerID",
                  width: 100,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Dealer Company"),
                  dataIndex: "dealerCompany",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Jewelry"),
                  dataIndex: "totalJewelry",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Diamonds"),
                  dataIndex: "totalDiamonds",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Permissions"),
                  dataIndex: "totalConnected",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Open Requests"),
                  dataIndex: "totalPendingReq",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Status"),
                  dataIndex: "accountStatus",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Last Updated"),
                  dataIndex: "modifiedDate",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Days Old"),
                  dataIndex: "daysOld",
                  sorter: (a, b) => {},
                },
                {
                  title: t("Details"),
                  dataIndex: "details",
                  render: (item, row) => {
                    return (
                      <React.Fragment>
                        <span
                          className="linkText"
                          onClick={() => OnDetailsClick(row)}
                        >
                          {t(row.details)}
                        </span>
                      </React.Fragment>
                    );
                  },
                },
              ]}
              dataSource={clientReportsGridDataList}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
              scroll={{ x: 900, y: 800 }}
            />
          </div>

          <Modal
            title={t("Connected and Pending Request Details")}
            centered
            visible={visible}
            okText={t("OK")}
            cancelText={t("Cancel")}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={800}
          >
            <div className="request__modal">
              <Spin spinning={detailsLoading}>
                <div className="row">
                  {clientDetailsList.length !== 0 &&
                    clientDetailsList.map((item) => {
                      return (
                        <React.Fragment>
                          <div className="col-md-4 p-0">
                            <div className="request__block">
                              <div className="top__name">
                                <p>
                                  {item.dealerCompany} ({item.dealerID})
                                </p>
                                {item.dealerStatus == "C" && (
                                  <span className="status">
                                    {item.dealerStatus}
                                  </span>
                                )}
                                {item.dealerStatus == "P" && (
                                  <span className="status pending">
                                    {item.dealerStatus}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  {clientDetailsList.length == 0 && (
                    <h4 className="text-center">
                      {t("No Data Available.")}
                    </h4>
                  )}
                </div>
              </Spin>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientsReports;
