import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Table, Tooltip, Slider, Spin, Pagination } from "antd";
import Filter_icon from "../../assets/images/filter_icon.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Info_icon from "../../assets/images/info_icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Close from "../../assets/images/close.png";
import { useTranslation } from "react-i18next";
import space__icon from "../../assets/images/space__icon.png";
import SearchJewelryDataService from "../../services/search-jewelry.service";

//Initial Watch Data
const myWatchData = {
  category: "",
  materialType: "",
  genderSelect: "",
  vendor: "",
  materialColor: "",
  gemstone: "",
  collectionSelect: "",
  priceMin: 0,
  priceMax: 999999,
};

const { Option } = Select;

const JewelrySearch = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [state, setState] = useState({
    productChange: {},
  });
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const [filterWatchValue, setfilterWatchValue] = useState(myWatchData);
  const [dealerId, setDealerId] = useState();
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol =
    currency && currency.substring(currency.lastIndexOf("-") + 1);
  const currencySign = useSelector((state) => state.loginReducer.currencySign);
  // Slider
  const [priceValue, setPriceValue] = useState([0, 999999]);

  const [loading, setLoading] = useState(false);
  const [metalTypesList, setMetalTypesList] = useState([]);
  const [metalColorsList, setMetalColorsList] = useState([]);
  const [gendersList, setGendersList] = useState([]);
  const [categoryList, setProductCategoryList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [viewALLProductList, setViewALLProductList] = useState([]);
  const [jewelryTotalRecords, setJewelryTotalRecords] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortType, setSortType] = useState("asc");
  const [sortColumnName, setSortColumnName] = useState("GT.MODIFIED");
  const [key, setKey] = useState(false);

  //Onchange Event
  const handleOnChange = (eValue, name) => {
    setfilterWatchValue({
      ...filterWatchValue,
      [name]: eValue,
    });
  };
  /*Slider Min Max Values Starts*/
  const onSliderChange = (eValue, name) => {
    setPriceValue(eValue);
    setfilterWatchValue({
      ...filterWatchValue,
      price: eValue,
    });
  };
  /*Slider Min Max Values Ends*/
  //Apply Filters
  const applyFilters = () => {
    setHideShowFilter(true);
    handleViewALLProduct(
      1,
      10,
      dealerId,
      filterWatchValue.category,
      filterWatchValue.vendor,
      filterWatchValue.materialType,
      filterWatchValue.genderSelect,
      filterWatchValue.collectionSelect,
      filterWatchValue.materialColor,
      filterWatchValue.price,
      sortColumnName,
      sortType,
      filterWatchValue.gemstone
    );
  };

  //Reset Filters
  const resetFilters = () => {
    setfilterWatchValue(myWatchData);
    setSortColumnName("styleNumber");
    setSortType("Asc");
    setKey(!key);
    setPriceValue([filterWatchValue.priceMin, filterWatchValue.priceMax]);
    handleViewALLProduct(
      1,
      10,
      dealerId,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "styleNumber",
      "Asc",
      ""
    );
  };

  const gender = (
    <span>
      {t(
        "Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."
      )}
    </span>
  );

  const collection = (
    <span> {t("Please select a vendor before choosing a collection.")} </span>
  );

  const handleProductChangeOpen = (gfInventoryID) => {
    state.productChange = {};
    state.productChange[gfInventoryID] = true;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleProductChangeClose = (gfInventoryID) => {
    state.productChange = {};
    state.productChange[gfInventoryID] = false;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  //Table Rows Checkbox Logic
  useEffect(() => {
    handleGetALLJewelryDataList();
    handleGetALLJewelryDataListSecond();
    handleGetALLJewelryDataListCollections();
    handleGetALLJewelryDataListVendorname();
    handleGetProductCategoryList();

    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleViewALLProduct(
            pageNo,
            pageSize,
            loginDetials.loginDetials.responseData.dealerId,
            filterWatchValue.category,
            filterWatchValue.vendor,
            filterWatchValue.materialType,
            filterWatchValue.genderSelect,
            filterWatchValue.collectionSelect,
            filterWatchValue.materialColor,
            filterWatchValue.price,
            "styleNumber",
            "Asc",
            filterWatchValue.gemstone
          );
        }
      }
    }
  }, []);

  /*API Binding Starts*/

  const handleGetALLJewelryDataList = async () => {
    try {
      let inputData = {
        orderby: "GFInventoryID",
        order: "ASC",
        category: "",
        vendor: "",
        materialType: "",
        gender: "",
        collection: "",
        collectionID: "",
        materialColor: "",
        minRange: 0,
        maxRange: 0,
        styleNumber: "",
        dealerType: "All",
        noOfRows: 0,
        offset: 0,
        gemstoneType: "",
        strSelectedInvID: "",
        image: "",
      };
      await SearchJewelryDataService.GetALLJewelryDataList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var metalTypesList = response.data.responseData.metalTypes;
          var metalColorsList = response.data.responseData.metalColors;
          if (message === "Success") {
            if (responseData) {
              setMetalTypesList(metalTypesList);
              setMetalColorsList(metalColorsList);
            } else {
              setMetalTypesList([]);
              setMetalColorsList([]);
            }
          } else {
            setMetalTypesList([]);
            setMetalColorsList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetALLJewelryDataListSecond = async () => {
    try {
      let inputData = {
        orderby: "GFInventoryID",
        order: "ASC",
        category: "",
        vendor: "",
        materialType: "",
        gender: "",
        collection: "",
        collectionID: "",
        materialColor: "",
        minRange: 0,
        maxRange: 0,
        styleNumber: "",
        dealerType: "All",
        noOfRows: 0,
        offset: 0,
        gemstoneType: "",
        strSelectedInvID: "",
        image: "",
      };
      await SearchJewelryDataService.GetALLJewelryDataListSecond(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var gendersList = response.data.responseData.genders;
          if (message === "Success") {
            if (responseData) {
              setGendersList(gendersList);
            } else {
              setGendersList([]);
            }
          } else {
            setGendersList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetProductCategoryList = async () => {
    try {
      let inputData = {
        orderby: "GFInventoryID",
        order: "ASC",
        category: "",
        vendor: "",
        materialType: "",
        gender: "",
        collection: "",
        collectionID: "",
        materialColor: "",
        minRange: 0,
        maxRange: 0,
        styleNumber: "",
        dealerType: "All",
        noOfRows: 0,
        offset: 0,
        gemstoneType: "",
        strSelectedInvID: "",
        image: "",
      };
      await SearchJewelryDataService.GetProductCategoryList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var jewelryCategoryList =
            response.data.responseData.jewelryCategoryList;
          if (message === "Success") {
            if (responseData) {
              setProductCategoryList(jewelryCategoryList);
            } else {
              setProductCategoryList([]);
            }
          } else {
            setProductCategoryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetALLJewelryDataListCollections = async () => {
    try {
      let inputData = {
        orderby: "GFInventoryID",
        order: "ASC",
        category: "",
        vendor: "",
        materialType: "",
        gender: "",
        collection: "",
        collectionID: "",
        materialColor: "",
        minRange: 0,
        maxRange: 0,
        styleNumber: "",
        dealerType: "All",
        noOfRows: 0,
        offset: 0,
        gemstoneType: "",
        strSelectedInvID: "",
        image: "",
      };
      await SearchJewelryDataService.GetALLJewelryDataListCollections(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var collectionsList = response.data.responseData.collections;
          if (message === "Success") {
            if (responseData) {
              setCollectionList(collectionsList);
            } else {
              setCollectionList([]);
            }
          } else {
            setCollectionList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetALLJewelryDataListVendorname = async () => {
    try {
      let inputData = {
        orderby: "GFInventoryID",
        order: "ASC",
        category: "",
        vendor: "",
        materialType: "",
        gender: "",
        collection: "",
        collectionID: "",
        materialColor: "",
        minRange: 0,
        maxRange: 0,
        styleNumber: "",
        dealerType: "All",
        noOfRows: 0,
        offset: 0,
        gemstoneType: "",
        strSelectedInvID: "",
        image: "",
      };
      await SearchJewelryDataService.GetALLJewelryDataListVendornameforjewelrysearch(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var vendorsList = response.data.responseData.vendors;
          if (message === "Success") {
            if (responseData) {
              setVendorsList(vendorsList);
            } else {
              setVendorsList([]);
            }
          } else {
            setVendorsList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleViewALLProduct = async (
    no,
    size,
    id,
    category,
    vendor,
    materialtype,
    gender,
    collection,
    materialcolor,
    price,
    columnName,
    sortby,
    gemstone
  ) => {
    setPageNo(no);
    setPageSize(size);
    setLoading(true);
    try {
      let inputData = {
        orderby: "GFInventoryID",
        dealerID: id,
        pageNumber: no,
        order: "ASC",
        category: category.toString(),
        gemstone: gemstone,
        vendor: vendor,
        materialType: materialtype,
        gender: gender,
        collection: collection,
        collectionIDs: "",
        materialColor: materialcolor,
        minRange: price ? price[0].toString() : "",
        maxRange: price ? price[1].toString() : "",
        styleNumber: "",
        dealerType: "search",
        noOfRows: size + "",
        offset: "0",
        strSelectedInvID: "",
        hasImage: "true",
        viewBy: "2",
        orderby: columnName ? columnName.toString() : sortType,
        order: sortby ? sortby.toString() : sortColumnName,
      };

      await SearchJewelryDataService.ViewALLProduct(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            if (responseData?.length > 0) {
              setViewALLProductList(responseData);
              setJewelryTotalRecords(responseData[0].jewelryTotalRecords);
              setLoading(false);
            } else {
              setViewALLProductList([]);
              setLoading(false);
              setJewelryTotalRecords(0);
            }
          } else {
            setViewALLProductList([]);
            setLoading(false);
            setJewelryTotalRecords(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  /*API Binding Ends*/
  function handlePageNoChange(page, pageSize) {
    if (pageNo !== page) {
      setPageNo(page);
      handleViewALLProduct(
        page,
        pageSize,
        dealerId,
        filterWatchValue.category,
        filterWatchValue.vendor,
        filterWatchValue.materialType,
        filterWatchValue.genderSelect,
        filterWatchValue.collectionSelect,
        filterWatchValue.materialColor,
        filterWatchValue.price,
        sortColumnName,
        sortType,
        filterWatchValue.gemstone
      );
    }
  }
  function handlePageSizeChange(current, size) {
    if (pageSize !== size) {
      setPageSize(size);
      handleViewALLProduct(
        pageNo,
        size,
        dealerId,
        filterWatchValue.category,
        filterWatchValue.vendor,
        filterWatchValue.materialType,
        filterWatchValue.genderSelect,
        filterWatchValue.collectionSelect,
        filterWatchValue.materialColor,
        filterWatchValue.price,
        sortColumnName,
        sortType,
        filterWatchValue.gemstone
      );
    }
  }

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "GT.MODIFIED";
      setSortColumnName(column);
      setSortType(type);
    }

    handleViewALLProduct(
      pagination.current,
      pagination.pageSize,
      dealerId,
      filterWatchValue.category,
      filterWatchValue.vendor,
      filterWatchValue.materialType,
      filterWatchValue.genderSelect,
      filterWatchValue.collectionSelect,
      filterWatchValue.materialColor,
      filterWatchValue.price,
      sorter.field,
      type,
      filterWatchValue.gemstone
    );
  };

  function formatMoney(amount) {
    try {
      if (amount && amount.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="filter_section p-0 m-0">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {t("Jewelry Search")}
                </h4>
              </div>

              <div className="col-md-12 mt-4 desktop__view">
                <div className="filt_optndiv">
                  <div className="gview__div">
                    <span
                      className={
                        viewItem === "grid"
                          ? "fa fa-th grid_icon active"
                          : "fa fa-th grid_icon"
                      }
                      onClick={() => handleViewChange("grid")}
                    ></span>
                    <span
                      className={
                        viewItem === "list"
                          ? "fa fa-th-list list_icon active"
                          : "fa fa-th-list list_icon"
                      }
                      onClick={() => handleViewChange("list")}
                    ></span>

                    <span className="rslsrch__txt">
                      {" "}
                      {t("There are")}{" "}
                      <span className="no__txt">{jewelryTotalRecords}</span>{" "}
                      {t("results for your search")}.
                    </span>
                  </div>

                  <div className="filtbtn__div">
                    <button
                      className="primary-btn"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {" "}
                          {t("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {t("Hide Filter")} <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4 mobile__view">
                <div className="filt_optndiv">
                  <div className="gview__div">
                    <p className="mb-0">
                      <span
                        className={
                          viewItem === "grid"
                            ? "fa fa-th grid_icon active"
                            : "fa fa-th grid_icon"
                        }
                        onClick={() => handleViewChange("grid")}
                      ></span>
                      <span
                        className={
                          viewItem === "list"
                            ? "fa fa-th-list list_icon active"
                            : "fa fa-th-list list_icon"
                        }
                        onClick={() => handleViewChange("list")}
                      ></span>
                    </p>

                    <span className="rslsrch__txt">
                      {" "}
                      {t("There are")}{" "}
                      <span className="no__txt">{jewelryTotalRecords}</span>{" "}
                      {t("results for your search")}.
                    </span>
                  </div>

                  <div className="filtbtn__div">
                    <button
                      className="primary-btn"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {" "}
                          {t("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {t("Hide Filter")} <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className={
                    hideShowFilter
                      ? "form__fields filter_inpsection hide"
                      : "form__fields filter_inpsection"
                  }
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Category")} </label>
                          <Select
                            className="border__grey"
                            placeholder={t("Select")}
                            showSearch
                            value={
                              filterWatchValue.category
                                ? filterWatchValue.category
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "category")
                            }
                            optionFilterProp="children"
                          >
                            {categoryList &&
                              categoryList.map((item) => {
                                return (
                                  <Option
                                    value={item.jewelryCategoryID}
                                    key={item.jewelryCategoryID}
                                  >
                                    {item.jewelryCategory}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Material Type")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Select Material Type")}
                            optionFilterProp="children"
                            value={
                              filterWatchValue.materialType
                                ? filterWatchValue.materialType
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialType")
                            }
                          >
                            {metalTypesList &&
                              metalTypesList.map((item) => {
                                return (
                                  <Option
                                    value={item.metalTypeID}
                                    key={item.metalTypeID}
                                  >
                                    {item.metalType}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {t("Gender")}
                            <Tooltip placement="right" title={gender}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Select Gender")}
                            optionFilterProp="children"
                            value={
                              filterWatchValue.genderSelect
                                ? filterWatchValue.genderSelect
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "genderSelect")
                            }
                          >
                            {gendersList &&
                              gendersList.map((item) => {
                                return (
                                  <Option
                                    value={item.genderID}
                                    key={item.genderID}
                                  >
                                    {item.gender}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Vendor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Select Vendor")}
                            optionFilterProp="children"
                            value={
                              filterWatchValue.vendor
                                ? filterWatchValue.vendor
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "vendor")
                            }
                          >
                            {vendorsList &&
                              vendorsList.map((item) => {
                                return (
                                  <Option
                                    value={item.vendorNameID}
                                    key={item.vendorNameID}
                                  >
                                    {item.vendorName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Material Color")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Select Material Color")}
                            optionFilterProp="children"
                            value={
                              filterWatchValue.materialColor
                                ? filterWatchValue.materialColor
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialColor")
                            }
                          >
                            {metalColorsList &&
                              metalColorsList.map((item) => {
                                return (
                                  <Option
                                    value={item.metalColorID}
                                    key={item.metalColorID}
                                  >
                                    {item.metalColor}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {t("Collection")}
                            <Tooltip placement="right" title={collection}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder={t("Select Collection")}
                            optionFilterProp="children"
                            value={
                              filterWatchValue.collectionSelect
                                ? filterWatchValue.collectionSelect
                                : null
                            }
                            onChange={(eValue) =>
                              handleOnChange(eValue, "collectionSelect")
                            }
                          >
                            {collectionList &&
                              collectionList.map((item) => {
                                return (
                                  <Option
                                    value={item.collectionID}
                                    key={item.collectionID}
                                  >
                                    {item.collectionNames}
                                    {" ("}
                                    {item.dealerName}
                                    {")"}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                        <div className="sliderrange__maindiv">
                          <label> {t("Price")} </label>
                          <div className="col-md-12 mt-3">
                            <Slider
                              range
                              value={priceValue}
                              min={filterWatchValue.priceMin}
                              max={filterWatchValue.priceMax}
                              onChange={(e) => {
                                onSliderChange(e);
                              }}
                            />
                            <div className="rangeSlider__values">
                              <div className="input__block">
                                <input
                                  type="text"
                                  value={
                                    currencySymbol + formatMoney(priceValue[0])
                                  }
                                />
                              </div>
                              <div>
                                <img
                                  src={space__icon}
                                  className="img-fluid space__icon"
                                />
                              </div>
                              <div className="input__block">
                                <input
                                  type="text"
                                  value={
                                    currencySymbol + formatMoney(priceValue[1])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-12 filtbtn_div">
                        <button
                          className="primary-btn mr-2"
                          onClick={resetFilters}
                        >
                          {" "}
                          {t("Reset Filters")}{" "}
                        </button>
                        <button className="primary-btn" onClick={applyFilters}>
                          {" "}
                          {t("Apply Filters")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {viewItem === "grid" && (
                <React.Fragment>
                  <Spin spinning={loading} size="large">
                    <div className="col-md-12">
                      <hr className="mt-4 mb-4" />

                      <div className="prod__section">
                        <div className="row">
                          {/*--------------- Grid view product card starts--------------- */}
                          {viewALLProductList.map((item) => {
                            return (
                              <div
                                className="col-lg-3 col-md-6 mt-4"
                                key={item.gfInventoryID}
                              >
                                <div
                                  className="prod__maindiv"
                                  style={{ minHeight: "20rem" }}
                                >
                                  <div className="prodimg__div">
                                    <img src={item.imagepath} alt="" />
                                  </div>
                                  <div className="details__div">
                                    <span className="style__txt">
                                      {item.productName}
                                    </span>
                                    {/* <span className="price__txt">
                                      {item.wholesaleprice ==
                                      "Call for Price" ? (
                                        item.wholesaleprice
                                      ) : (
                                        <>
                                          {currencySymbol} {item.wholesaleprice}
                                        </>
                                      )}
                                    </span> */}
                                  </div>

                                  {!state.productChange[item.gfInventoryID] ? (
                                    <div
                                      className="info__div"
                                      onClick={() => {
                                        handleProductChangeOpen(
                                          item.gfInventoryID
                                        );
                                      }}
                                    >
                                      <img src={Info_icon} alt="" />
                                    </div>
                                  ) : (
                                    <div className="descr__div">
                                      <div className="product-list overlay__block">
                                        <p>
                                          <span> {t("Retail Stock #")} </span>
                                          <span>:</span>
                                          <span>{item.styleNumber}</span>
                                        </p>
                                        <p>
                                          <span> {t("Category")}</span>
                                          <span>:</span>
                                          <span>{item.categoryName}</span>
                                        </p>
                                        <p>
                                          <span> {t("Collection")}</span>
                                          <span>:</span>
                                          <span>{item.collectionName}</span>
                                        </p>
                                        <p>
                                          <span> {t("Vendor")}</span>
                                          <span>:</span>
                                          <span>{item.vendorName}</span>
                                        </p>
                                        {/* <p>
                                          <span> {t("Cost")}</span>
                                          <span>:</span>
                                          <span>
                                            {item.wholesaleprice ==
                                            "Call for Price" ? (
                                              item.wholesaleprice
                                            ) : (
                                              <>
                                                {currencySign} {currencySymbol}{" "}
                                                {item.wholesaleprice}
                                              </>
                                            )}
                                          </span>
                                        </p> */}
                                        {/* <p>
                                          <span> {t("Retail")}</span>
                                          <span>:</span>
                                          <span>
                                            {item.retailPrice ==
                                              "Call for Price" ? (
                                              item.retailPrice
                                            ) : (
                                              <>
                                                {currencySign} {currencySymbol}{" "}
                                                {item.retailPrice}
                                              </>
                                            )}
                                          </span>
                                        </p> */}
                                        <p>
                                          <span> {t("Metal Type")}</span>
                                          <span>:</span>
                                          <span>{item.metalType}</span>
                                        </p>
                                        <p>
                                          <span> {t("Metal Color")}</span>
                                          <span>:</span>
                                          <span>{item.metalColor}</span>
                                        </p>
                                      </div>
                                      <div
                                        className="close__div"
                                        onClick={() =>
                                          handleProductChangeClose(
                                            item.gfInventoryID
                                          )
                                        }
                                      >
                                        <img src={Close} alt="" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          {/* Grid view product card ends */}
                        </div>
                      </div>
                    </div>
                    <Pagination
                      style={{ marginTop: "30px" }}
                      total={jewelryTotalRecords}
                      current={pageNo}
                      pageSize={pageSize}
                      pageSizeOptions={[10, 20, 50, 100]}
                      responsive={true}
                      showSizeChanger={true}
                      onChange={handlePageNoChange}
                      onShowSizeChange={handlePageSizeChange}
                    />
                  </Spin>
                </React.Fragment>
              )}
              {viewItem === "list" && (
                <div className="col-md-12 mt-4">
                  <div className="lviewprod__tbl table__scroll">
                    <Table
                      key={key}
                      columns={[
                        {
                          title: t("Retail Stock #"),
                          dataIndex: "styleNumber",
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Category"),
                          dataIndex: "categoryName",
                          showSorterTooltip: false,
                        },
                        {
                          title: t("Vendor"),
                          dataIndex: "vendorName",
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Collection"),
                          dataIndex: "collectionName",
                          showSorterTooltip: false,
                        },
                        // {
                        //   title: t(`${currencySymbol} cost`),
                        //   dataIndex: "wholesaleprice",
                        //   showSorterTooltip: false,
                        //   sorter: (a, b) => {},
                        //   render: (item, row) => {
                        //     return (
                        //       <p>
                        //         {isNaN(parseFloat(row.wholesaleprice))
                        //           ? row.wholesaleprice
                        //           : currencySymbol + " " + row.wholesaleprice}
                        //       </p>
                        //     );
                        //   },
                        // },
                        // {
                        //   title: t(`${currencySymbol} Retail`),
                        //   dataIndex: "retailPrice",
                        //   showSorterTooltip: false,
                        //   sorter: (a, b) => {},
                        //   render: (item, row) => {
                        //     return (
                        //       <p>
                        //         {isNaN(parseFloat(row.retailPrice))
                        //           ? row.retailPrice
                        //           : currencySymbol + " " + row.retailPrice}
                        //       </p>
                        //     );
                        //   },
                        // },
                      ]}
                      dataSource={viewALLProductList}
                      pagination={{
                        total: jewelryTotalRecords,
                        pageNo: pageNo,
                        current: pageNo,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        responsive: true,
                        showSizeChanger: true,
                      }}
                      loading={loading}
                      scroll={{ y: 800 }}
                      onChange={handleTableASCDES}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelrySearch;
