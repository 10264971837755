import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Table, Spin } from "antd";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";
import AppsStudBuilderService from "../../../services/apps-studbuilder.service";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const StudBuilderPendingVendorRequest = () => {
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [getLoading, setGetLoading] = useState(false);
  const [pendingVendorsData, setPendingVendorsData] = useState([]);
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [dealerId, setDealerId] = useState(0);

  const loginDetials = useSelector((state) => state.loginReducer);

  const columns = [
    {
      title: t("Vendor"),
      dataIndex: "dealerCompany",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => {
        return (
          <Link
            to={"/vendorProfile"}
            state={{
              key: "Profile",
              id: row.dealerID,
            }}
          >
            <span className="linkText">{row.dealerCompany}</span>
          </Link>
        );
      },
    },
    {
      title: t("Type"),
      //   dataIndex: "vendorStatus",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      className: "display__tablecell",
      //   render: (index, row) => {
      //     return <Fragment>{row.vendorStatus}</Fragment>;
      //   },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      width: 150,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => {
        return <Fragment>{row.status}</Fragment>;
      },
    },
    {
      title: t("Data Rating"),
      //   dataIndex: "ratingCount",
      width: 125,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => (
        <Fragment>
          <StarRatings
            // rating={Number(row.ratingCount)}
            starRatedColor="#1883b8"
            starDimension="18px"
            starSpacing="0px"
            numberOfStars={5}
          />
        </Fragment>
      ),
    },
    {
      title: t("Items"),
      className: "d-none ",
      //   dataIndex: "totalNumber",
      width: 30,
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: t("Last Requested"),
      //   dataIndex: "modified",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      dataIndex: "reqdDate",
      render: (index, row) => {
        return <Fragment>{row.reqdDate}</Fragment>;
      },
    },
    {
      title: t("Action"),
      //   dataIndex: "action",
      render: (index, row) => {
        return (
          <Fragment>
            {/* <p>
              {row.action == "Send Invitation" ? (
                <span
                  onClick={() => {
                    showSendInvitationModal(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {t("Send Invitation")}{" "}
                </span>
              ) : row.action == "Connected" ? (
                <span> {t("Connected")} </span>
              ) : row.action == "Request Access" ? (
                <span
                  onClick={() => {
                    showSendInvitationModal(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {t("Request Access")}{" "}
                </span>
              ) : row.action == "Re-Activate" ? (
                <span
                  onClick={() => {
                    handleReActiveCollection(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {t("Re-Activate")}{" "}
                </span>
              ) : row.action == "Re-ActivateReEnableVenor" ? (
                <span
                  onClick={() => {
                    handleReActiveCollection(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {t("Re-Activate")}{" "}
                </span>
              ) : row.action == "ResubmitCancel" ? (
                <div className="action__btns">
                  <div className="mx-2">
                    <span
                      onClick={() => {
                        showSendInvitationModal(row);
                      }}
                      className="linkText mb-0"
                      alt=""
                    >
                      {" "}
                      {t("Resubmit")}
                    </span>
                  </div>
                  <div className="mx-2">
                    <span
                      onClick={() => {
                        handleCancelRetailerRequest(row);
                      }}
                      className="linkText mb-0"
                      alt=""
                    >
                      {t("Cancel")}
                    </span>
                  </div>
                </div>
              ) : null}
            </p> */}
            <p>
              {" "}
              <span
                className="linkText mb-0"
                onClick={() => handleApproveVendor(dealerId, row.retailerID)}
              >
                {t("Approve")}
              </span>{" "}
              <span
                className="linkText mb-0"
                onClick={() => handleDisapproveVendor(dealerId, row.retailerID)}
              >
                {t("Disapprove")}
              </span>{" "}
            </p>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          getRingBuilderPendingVendorRequestData(
            loginDetials.loginDetials.responseData.dealerId,
            "",
            "",
            "",
            []
          );
        }
      }
    }
  }, []);

  const getRingBuilderPendingVendorRequestData = async (
    id,
    sales,
    sortColumnName,
    sortOrder,
    lstColumnFilter
  ) => {
    try {
      let inputData = {
        dealerID: id,
        sales,
        sortColumnName,
        sortOrder,
        lstColumnFilter,
      };
      setGetLoading(true);
      await AppsStudBuilderService.GetPendingRequests(inputData)
        .then((response) => {
          let responseData = response.data.responseData;
          let message = response.data.message;
          if (message === "Success") {
            setPendingVendorsData(responseData);
            setGetLoading(false);
            setTotalCount(responseData.length);
          } else {
            setPendingVendorsData([]);
            setGetLoading(false);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
          setGetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setGetLoading(false);
    }
  };

  const handleApproveVendor = async (dealerID, retailerID) => {
    let inputData = {
      dealerID: dealerID + "",
      retailerID: retailerID + "",
    };
    try {
      await AppsStudBuilderService.StudBuilderPendingToApprove(inputData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisapproveVendor = async (dealerID, retailerID) => {
    let inputData = {
      dealerID: dealerID + "",
      retailerID: retailerID + "",
    };
    try {
      await AppsStudBuilderService.StudBuilderPendingToDisApprove(inputData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 class="workarea__heading mt-0 mb-0 d-flex">
              {" "}
              <h4 className="workarea__heading mt-0 mb-0 mx-1">
                {t("Pending Vendor Request")}
              </h4>
              {"(" + totalCount + ")"}
            </h4>
          </div>
        </div>
        <div
          className={
            selectedColumnId !== 0
              ? "col-lg-9 divForm vendors-section"
              : "col-lg-12"
          }
        >
          <Spin spinning={getLoading}>
            <Table
              columns={columns}
              //   onChange={handleTableASCDES}
              dataSource={pendingVendorsData}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                // onChange: handlePageNoChange,
                // onShowSizeChange: handlePageSizeChange,
              }}
              // onChange={(pagination, filters, sorters) => {
              //   document
              //     .getElementsByClassName("content__area")[0]
              //     .scroll(0, 0);
              // }}
              //   onRow={(record, recordIndex) => ({
              //     onClick: (event) => {
              //       handleShowDataToRightNew(record);
              //     },
              //   })}
              rowClassName={(record) =>
                record.dealerID == selectedColumnId ? "row-active" : ""
              }
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default StudBuilderPendingVendorRequest;
