import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Modal, Spin } from "antd";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";

const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: [],
};
const { RangePicker } = DatePicker;
const { Option } = Select;

const JewelryMetalTypeReport = () => {
  const { t } = useTranslation();
  const chartRefPie = useRef("");
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [tableChartData, setTableChartData] = useState([]);
  const [metalTypeData, setMetalTypeData] = useState([]);
  const [countMetalTypeData, setCountMetalTypeData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [colorData, setColorData] = useState([]);
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [showTableLoader, setShowTableLoader] = useState(true);
  const [metalData, setMetalData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const data1 = [];
  const [key, setKey] = useState(true);
  const [metalTypeId, setMetalTypeId] = useState("");
  const [colorTypeId, setColorTypeId] = useState("");
  const [sortType, setSortType] = useState("desc");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId,
          }));
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
    chartDataHandler(metalTypeData);
  }, []);

  function chartDataHandler(arr) {
    const color = [];
    for (let i = 0; i < arr.length; i++) {
      color.push("#" + Math.floor(Math.random() * 16777215).toString(16));
    }
    setColorData(color);
  }

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id,
      };
      setShowLoader(true);
      AdminJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer,
            }));

            handleGetMetalTypeChartData(id, responseData.forDealer);
            handleGetTableChartData(id, responseData.forDealer);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTableChartData = async (
    DealerId,
    forDealerId,
    type,
    metalColorID,
    pageNumber,
    pageSize,
    sortColumn,
    sorttype
  ) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate,
      toDate: selectedData.toDate,
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      category: "",
      metalType: type ? type : "",
      metalColorType: metalColorID ? metalColorID : "",
      price: "",
      vendor: "",
      retailer: "",
      reporttype: "Global",
      pageIndex: pageNumber ? pageNumber : 1,
      pageSize: pageSize ? pageSize : 10,
      sortColumnName:
        sortColumn == "UserIPAddress"
          ? "UserIPAddress"
          : sortColumn == "Vendor"
          ? "VendorName"
          : sortColumn == "Retailer"
          ? "RetailerName"
          : sortColumn == "Collection"
          ? "CollectionNew"
          : sortColumn == "Category"
          ? "Category"
          : sortColumn == "Date"
          ? "Date"
          : sortColumn == "StyleNumber"
          ? "StyleNumber"
          : sortColumn == "MetalType"
          ? "MetalType"
          : sortColumn == "MetalColor"
          ? "MetalColor"
          : sortColumn == "Price"
          ? "Price"
          : "date",
      sortOrder: sorttype ? sorttype.toString() : sortType.toString(),
    };
    setShowTableLoader(true);
    AdminJewelryReportService.GetTableChartData(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setTotalCount(responseData?.[0]?.totalRecords || 0);
          responseData.map((elem, index) => {
            data1.push({
              key: index,
              ProductImage: (
                <span onClick={() => imageClickHandler(elem.imagePathNew)}>
                  <img src={elem.imagePathNew} className="product__img" />
                </span>
              ),
              UserIPAddress: <span>{elem.userIPAddress}</span>,
              Vendor: (
                <span>{elem.vendorName === null ? "" : elem.vendorName}</span>
              ),
              Retailer: (
                <span>
                  {elem.retailerName === null ? "" : elem.retailerName}
                </span>
              ),
              Collection: <span>{elem.collectionNew}</span>,
              Category: <span>{elem.category}</span>,
              Date: <span>{elem.dateTime}</span>,
              StyleNumber: <span>{elem.styleNumber}</span>,
              MetalType: <span>{elem.metalType}</span>,
              MetalColor: <span>{elem.metalColor}</span>,
              Price: <span>{elem.price}</span>,
            });
          });
          setTableChartData(data1);
        } else {
          setTotalCount(0);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowTableLoader(false);
      });
  };

  const handleGetMetalTypeChartData = (DealerId, forDealerId) => {
    const initialEventState = {
      ...selectedData,
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate
        ? moment(selectedData.fromDate).format("YYYY-MM-DD")
        : "2020-08-17",
      toDate: selectedData.toDate
        ? moment(selectedData.toDate).format("YYYY-MM-DD")
        : "2022-09-15",
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
    };
    setShowLoader(true);
    AdminJewelryReportService.GetMetalTypeData(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let showResp = responseData.every((element) => {
            return element.count == "0";
          });
          setShowChart(showResp);
          let metalType = [];
          let count = [];
          setMetalData(responseData);
          for (let i of responseData) {
            metalType.push(i.metalType);
            count.push(i.count);
          }
          var totalValueForPercentage = count.reduce((a, b) => a + b, 0);
          setTotalValueForPercentage(totalValueForPercentage);
          setCountMetalTypeData(count);
          setMetalTypeData(metalType);
          chartDataHandler(metalType);
          setKey(!key);
        } else {
          setShowChart(true);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowLoader(false);
        setShowChart(false);
      });
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer",
    };
    AdminJewelryReportService.GetVendorDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer",
    };
    AdminJewelryReportService.GetRetailerDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString(),
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId,
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e,
        }));
      }
      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId,
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e,
        }));
      }
      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const changeRetailerListHandler = (data) => {
    data = data.toString();
    let inputData = {
      vendorIds: data,
    };
    AdminJewelryReportService.ChangeRetailerList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changeVendorListHandler = (data) => {
    data = data.toString();
    let inputData = {
      retailerIds: data,
    };
    AdminJewelryReportService.ChangeVendorList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const applyFilterHandler = () => {
    handleGetMetalTypeChartData(selectedData.dealerID, selectedData.forDealer);
    handleGetTableChartData(selectedData.dealerID, selectedData.forDealer);
    setMetalTypeId("");
    setColorTypeId("");
    setPageNo(1);
    setPageSize(10);
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const columns = [
    {
      title: t("Product Image"),
      dataIndex: "ProductImage",
      width: 90,
    },
    {
      title: t("User IP Address"),
      dataIndex: "UserIPAddress",
      sorter: (a, b) => {},
      width: 90,
    },
    {
      title: t("Vendor"),
      dataIndex: "Vendor",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Retailer"),
      dataIndex: "Retailer",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Collection"),
      dataIndex: "Collection",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Category"),
      dataIndex: "Category",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Date"),
      dataIndex: "Date",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Style Number"),
      dataIndex: "StyleNumber",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Metal Type"),
      dataIndex: "MetalType",
      sorter: (a, b) => {},
      width: 120,
    },
    {
      title: t("Metal Color"),
      dataIndex: "MetalColor",
      sorter: (a, b) => {},
      width: 100,
    },
    {
      title: t("Price"),
      dataIndex: "Price",
      sorter: (a, b) => {},
      width: 100,
    },
  ];

  const tableSortHandler = (type) => {
    let index = -1;

    for (let i = 0; i < metalData.length; i++) {
      if (metalData[i].metalType === type) {
        index = i;
        break;
      }
    }
    let id = metalData[index].metalTypeID.toString();
    let metalColorID = metalData[index].metalColorID.toString();
    setMetalTypeId(id);
    setColorTypeId(metalColorID);
    setPageNo(1);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      id,
      metalColorID
    );
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
    } else {
      type = "DESC";
      column = "date";
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      metalTypeId,
      colorTypeId,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Jewelry Metal Type Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {t("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {t("Vendor(s)")} </label>
              <Select
                className="border__grey"
                showSearch
                mode="multiple"
                showArrow
                placeholder={t("Select one or more vendor")}
                optionFilterProp="children"
                value={selectedData.vendorIds}
                onChange={(e) => handleOnChange(e, "select", "vendors")}
              >
                {vendorList.map((item, key) => {
                  return (
                    <Option key={item.dealerID} value={item.dealerID}>
                      {" "}
                      {item.dealerCompany}{" "}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {t("Retailer(s)")} </label>
              <Select
                showSearch
                showArrow
                className="border__grey"
                placeholder={t("Select one or more retailer ")}
                optionFilterProp="children"
                onChange={(e) => handleOnChange(e, "select", "retailers")}
                notFoundContent={t("No Data Found")}
                mode="multiple"
                value={selectedData.retailerIds}
              >
                {retailerList.map((item, key) => {
                  return (
                    <Option key={key} value={item.dealerID}>
                      {" "}
                      {item.dealerCompany}{" "}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={applyFilterHandler}>
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          {!showChart ? (
            <Spin spinning={showLoader}>
              <div className="col-lg-12">
                <div className="chart__section">
                  {metalTypeData !== null &&
                    metalTypeData.length >= 1 &&
                    countMetalTypeData.length >= 1 && (
                      <Doughnut
                        key={key}
                        ref={chartRefPie}
                        onClick={(event) => {
                          const element = getElementAtEvent(
                            chartRefPie.current,
                            event
                          );
                          const { index } = element[0];
                          tableSortHandler(metalTypeData[index]);
                        }}
                        data={{
                          labels: metalTypeData,
                          datasets: [
                            {
                              data: countMetalTypeData,
                              backgroundColor:
                                colorData.length > 0 ? colorData : "",
                              borderColor:
                                colorData.length > 0 ? colorData : "",
                              borderWidth: 1,
                            },
                          ],
                        }}
                        width={300}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          plugins: {
                            datalabels: {
                              display: true,
                              align: "middle",
                              color: "rgb(255, 255, 255)",
                              font: {
                                size: 12,
                              },
                              formatter: (value) => {
                                if (totalValueForPercentage) {
                                  let finalPercentage = (
                                    (value / totalValueForPercentage) *
                                    100
                                  ).toFixed(2);
                                  setTooltipPercentage(finalPercentage);
                                  if (finalPercentage >= 5) {
                                    return finalPercentage + "%";
                                  } else {
                                    return "";
                                  }
                                }
                              },
                            },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: function (context) {
                                  let label = context.label || "";
                                  let formattedValue =
                                    context.formattedValue || "";
                                  if (label) {
                                    label =
                                      label +
                                      " " +
                                      formattedValue +
                                      " (" +
                                      TooltipPercentage +
                                      "%" +
                                      ")";
                                  }
                                  return label;
                                },
                              },
                            },
                            legend: {
                              position: "left",
                            },
                          },
                        }}
                      />
                    )}
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <Table
                  className="pendjewelreq__tbl"
                  columns={columns}
                  dataSource={tableChartData}
                  scroll={{ x: 600, y: 800 }}
                  loading={showTableLoader}
                  pagination={{
                    total: totalCount,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: pageNo,
                  }}
                  onChange={handleTableASCDES}
                />
              </div>
              {/* )} */}
            </Spin>
          ) : (
            <div className="no-report-render">
              <div className="desc-space">
                <h4 className="text-center">{t("No Data Found")}</h4>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default JewelryMetalTypeReport;
