import React, { useState, useEffect } from "react";
import { Select, Button, Table, Modal, Switch, Spin } from "antd";
import { useTranslation } from "react-i18next";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

export const Worksheet = (props) => {
  const { t } = useTranslation();
  const [workSheetData, setWorkSheetData] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [workSheetLoading, setWorkSheetLoading] = useState(false);
  const [worksheetDetails, setWorkSheetDetails] = useState({
    workSheetName: "",
  });
  const [worksheetDetailsVal, setWorkSheetDetailsVal] = useState({
    workSheetNameVal: "",
  });
  const [filteredVendor, setFilteredVendor] = useState({
    vendorname: "",
  });
  const [vendorData, setVendorData] = useState([]);
  const [aftervendorselected, setAfterVendorSelected] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [worksheetDeleteDetails, setWorksheetDeleteDetails] = useState({
    workSheetId: 0,
    workSheetName: "",
  });

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetWorksheetDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
          setTimeout(() => {
            handleGetDashBoardVendorDetails(
              loginDetials.loginDetials.responseData.dealerId
            );
          }, 500);
        }
      }
    }
  }, []);

  let navigate = useNavigate();
  const handleRedirection = (id, name, vendorDynamicId, vendorDynamicName) => {
    navigate("/workSheetDetails", {
      state: {
        id: id ? id : 0,
        name: name ? name : "",
        vendorDynamicId: vendorDynamicId ? vendorDynamicId : "",
        vendorDynamicName: vendorDynamicName ? vendorDynamicName : "",
      },
    });
  };

  const columns = [
    {
      title: t("Worksheet Name"),
      dataIndex: "workSheetName",
      width: 180,
      render: (item, row) => {
        return (
          <a
            onClick={() =>
              handleRedirection(
                row.poWorkSheetID,
                row.workSheetName,
                row.vendorID,
                row.vendorName
              )
            }
            className="linkText"
          >
            {row.workSheetName}
          </a>
        );
      },
    },
    {
      title: t("Item Count"),
      dataIndex: "itemCount",
    },
    {
      title: t("Total Base Price"),
      dataIndex: "totalBasePrice",
      render: (item, row) => {
        return (
          <span>
            {row.totalBasePrice == "" ? "$" + "0.00" : "$" + row.totalBasePrice}
          </span>
        );
      },
    },
    {
      title: t("Updated"),
      dataIndex: "lastModifiedDate",
      render: (item, row) => {
        return (
          <span>
            {row.lastModifiedDate ? row.lastModifiedDate : row.addedDateTime}
          </span>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      render: (item, row) => {
        return (
          <Switch
            checkedChildren={t("Yes")}
            unCheckedChildren={t("No")}
            checked={row.status}
            onChange={(e) => handleSwitchOnChange(e, row)}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 60,
    },
  ];

  const [loading, setLoading] = useState(false);

  const handleGetDashBoardVendorDetails = async (id) => {
    let inputData = {
      dealerID: id.toString(),
      orderby: "GFInventoryID",
      order: "ASC",
      category: "",
      vendor: "",
      materialType: "",
      gender: "",
      collection: "",
      collectionID: "",
      materialColor: "",
      minRange: "",
      maxRange: "9999999",
      styleNumber: "",
      dealerType: "MergeProduct",
      noOfRows: "50",
      offset: "0",
      flgComm_VR: "",
      gemstoneType: "",
      strSelectedInvID: "",
      image: "",
    };
    setLoading(true);
    try {
      PurchaseOrderServices.GetPOConnectedVendorDropdown(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.result.vendorBrand;
          if (message === "Success") {
            if (responseData.length > 0) {
              setVendorData(responseData);
              setLoading(false);
            } else {
              setVendorData([]);
              setLoading(false);
            }
          } else {
            setVendorData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChangeVendor = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFilteredVendor({
        ...filteredVendor,
        [name]: e,
      });
    } else {
      setFilteredVendor({
        ...filteredVendor,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGetWorksheetDetails = (id) => {
    let inputData = {
      delarId: id ? id : 0,
    };
    setWorkSheetLoading(true);
    try {
      PurchaseOrderServices.GetWorksheetDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setWorkSheetData(responseData);
              setWorkSheetLoading(false);

              setWorksheetDeleteDetails({
                workSheetId: responseData[0].poWorkSheetID
                  ? responseData[0].poWorkSheetID
                  : 0,
                workSheetName: responseData[0].workSheetName
                  ? responseData[0].workSheetName
                  : "",
              });
            } else {
              setWorkSheetData([]);
              setWorkSheetLoading(false);
              setWorksheetDeleteDetails({
                workSheetId: 0,
                workSheetName: "",
              });
            }
          } else {
            setWorkSheetData([]);
            setWorkSheetLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setWorkSheetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWorkSheetLoading(false);
    }
  };

  const handleSwitchOnChange = (e, row) => {
    const temp = workSheetData.map((x) => {
      if (x.poWorkSheetID == row.poWorkSheetID) {
        return { ...x, status: e };
      } else return x;
    });
    setWorkSheetData(temp);
    var item = temp.filter(
      (item) => item.poWorkSheetID == row.poWorkSheetID
    )[0];

    handleUpdateWorkSheetStatus(item.status, item.poWorkSheetID);
  };

  const handleUpdateWorkSheetStatus = (status, workSheetId) => {
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetId: workSheetId ? workSheetId : 0,
      status: status,
    };

    try {
      PurchaseOrderServices.UpdateWorkSheetStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(
              t("Status Has Been Updated Successfully.")
            );
            handleGetWorksheetDetails(dealerId);
          } else {
            NotificationManager.error(t("Status Has Not Been Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Insert WorkSheet Modal

  const handleValidation = () => {
    const { workSheetName } = worksheetDetails;

    const worksheetDetailsVal = {
      workSheetNameVal: "",
    };
    let isValid = true;

    if (!workSheetName.trim()) {
      isValid = false;
      worksheetDetailsVal.workSheetNameVal = t("Enter WorkSheet Name");
    } else {
      worksheetDetailsVal.workSheetNameVal = "";
    }
    setWorkSheetDetailsVal(worksheetDetailsVal);
    return isValid;
  };

  const handleInsertWorksheetDetails = () => {
    var isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetName: worksheetDetails.workSheetName
        ? worksheetDetails.workSheetName.toString()
        : "",
      vendorName: filteredVendor.vendorname ? filteredVendor.vendorname : "",
    };
    setInsertLoading(true);
    try {
      PurchaseOrderServices.InsertWorksheetDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.returnMessage == "Worksheet Already Exist") {
              NotificationManager.error(t("Worksheet Name Already Exists."));
              setInsertLoading(false);
              handleCancelCreateModal();
            } else {
              setInsertLoading(false);
              handleCancelCreateModal();
              NotificationManager.success(
                t("WorkSheet Has Been Created Successfully.")
              );
              handleGetWorksheetDetails(dealerId);
            }
          } else {
            setInsertLoading(false);
            handleCancelCreateModal();
            NotificationManager.error(t("WorkSheet Has Not Been Created."));
          }
        })
        .catch((error) => {
          console.log(error);
          setInsertLoading(false);
          handleCancelCreateModal();
        });
    } catch (error) {
      console.log(error);
      setInsertLoading(false);
      handleCancelCreateModal();
    }
  };

  const handleWorksheetOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setWorkSheetDetails({
        ...worksheetDetails,
        [name]: e,
      });
    } else {
      setWorkSheetDetails({
        ...worksheetDetails,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name == "workSheetName") {
      setWorkSheetDetailsVal((prevdata) => ({
        ...prevdata,
        workSheetNameVal: "",
      }));
    }
  };

  const handleBackOpenVendorModelCreateModal = () => {
    setCreateModalVisible(true);
    setAfterVendorSelected(false);
    setWorkSheetDetailsVal({
      workSheetNameVal: "",
    });
  };

  const handleGetCreateModelOpen = () => {
    if (filteredVendor.vendorname != "") {
      setCreateModalVisible(false);
      setAfterVendorSelected(true);
    }
  };

  const handleCancelCreateModal = () => {
    setWorkSheetDetails({
      workSheetName: "",
    });
    setFilteredVendor({
      vendorname: "",
    });
    setWorkSheetDetailsVal({
      workSheetNameVal: "",
    });
    setCreateModalVisible(false);
    setAfterVendorSelected(false);
  };

  // End Insert Modal All

  // Delete Modal

  const handleRemoveWorkSheetName = () => {
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: worksheetDeleteDetails.workSheetId
        ? worksheetDeleteDetails.workSheetId
        : 0,
    };
    setDeleteLoading(true);
    try {
      PurchaseOrderServices.RemoveWorkSheetName(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.success(
              t("WorkSheet Has Been Deleted Successfully.")
            );
            handleGetWorksheetDetails(dealerId);
          } else {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.error(t("WorkSheet Has Not Been Deleted."));
          }
        })
        .catch((error) => {
          console.log(error);
          setInsertLoading(false);
          setDeleteModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  // End Delete Modal

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {t("Worksheet")}{" "}
            </h4>
            <div className="">
              <Button
                onClick={() =>
                  handleRedirection(
                    worksheetDeleteDetails.workSheetId,
                    worksheetDeleteDetails.workSheetName,
                    "",
                    ""
                  )
                }
                className="primary-btn mr-2"
              >
                {t("Edit")}
              </Button>
              <Button
                className="primary-btn mr-2"
                onClick={() => {
                  setDeleteModalVisible(true);
                }}
              >
                {t("Delete")}
              </Button>
              <Button
                className="primary-btn mr-2"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                {t("Create")}
              </Button>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="divForm">
            <Spin spinning={workSheetLoading}>
              <div className="table__scroll">
                <Table
                  columns={columns}
                  dataSource={workSheetData}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                  onRow={(record, recordIndex) => ({
                    onClick: (event) => {
                      setWorksheetDeleteDetails({
                        workSheetId: record.poWorkSheetID,
                        workSheetName: record.workSheetName,
                      });
                    },
                  })}
                  rowClassName={(record) =>
                    record.poWorkSheetID == worksheetDeleteDetails.workSheetId
                      ? "row-active-highlight"
                      : ""
                  }
                />
              </div>
            </Spin>
          </div>
        </div>
      </div>

      <Modal
        title={t("Delete")}
        className="modalconsupld__section"
        centered
        visible={deleteModalVisible}
        onOk={handleRemoveWorkSheetName}
        onCancel={handleCancelDeleteModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelDeleteModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={deleteLoading}
            onClick={handleRemoveWorkSheetName}
          >
            {t("Delete Worksheet")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>
                      {t("Delete the")} "
                      {worksheetDeleteDetails.workSheetName}"
                      {t("Worksheet")}{" "}
                    </p>
                    <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Vendor")}
        className="modalconsupld__section"
        centered
        visible={createModalVisible}
        onCancel={handleCancelCreateModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelCreateModal}>
            {t("Cancel")}
          </Button>,
          <Button
            className={
              filteredVendor.vendorname ? "primary-btn" : "disabled-btn"
            }
            disabled={!filteredVendor.vendorname}
            onClick={handleGetCreateModelOpen}
          >
            {t("Next")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <Spin spinning={loading}>
                <div className="form__fields border-0 p-0">
                  {vendorData.length > 0 ? (
                    <div class="col-lg-12 col-md-12">
                      <div className="input__block">
                        <label> {t("Vendor")} </label>
                        <Select
                          value={filteredVendor.vendorname}
                          name="vendorname"
                          placeholder={t("Select Vendor")}
                          onChange={(e) =>
                            handleOnChangeVendor(e, "select", "vendorname")
                          }
                          showSearch
                          optionFilterProp="children"
                          className="border__grey"
                        >
                          <Option value="">
                            {" "}
                            {t("Select Vendor")}{" "}
                          </Option>
                          {vendorData &&
                            vendorData.map((item, i) => {
                              return (
                                <Option value={item.vendorNameID}>
                                  {item.vendorNameValue}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  ) : (
                    <span>
                      {t(
                        "Please Connect the Vendor for more information."
                      )}
                    </span>
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Create Worksheet")}
        className="modalconsupld__section"
        centered
        visible={aftervendorselected}
        onOk={handleInsertWorksheetDetails}
        onCancel={handleCancelCreateModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelCreateModal}>
            {t("Cancel")}
          </Button>,
          <Button key="back" onClick={handleBackOpenVendorModelCreateModal}>
            {t("Back")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={insertLoading}
            onClick={handleInsertWorksheetDetails}
          >
            {t("Create")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="input__block">
                  <label>
                    {t("Worksheet Name")} <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="workSheetName"
                    className={
                      worksheetDetailsVal.workSheetNameVal && "border__red"
                    }
                    value={worksheetDetails.workSheetName}
                    onChange={handleWorksheetOnChange}
                  />
                  {worksheetDetailsVal.workSheetNameVal && (
                    <p className="error-color-red">
                      {worksheetDetailsVal.workSheetNameVal}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Worksheet;
