import React from "react";
import { useTranslation } from "react-i18next";

const PageNotAccess = () => {
  const { t } = useTranslation();
  // return <div>Page Not access Please Contect to admin.</div>;
  return <div>{t("Page Not access Please Contact to admin")}</div>;
};

export default PageNotAccess;
