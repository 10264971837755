import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip, Modal, Popconfirm, Spin } from "antd";
import { ProgressBar } from "react-bootstrap";

import userIcon from "../../assets/images/user-icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import DownloadBox from "../../assets/images/download_sample_icon.png";
import ImageFormatIcon from "../../assets/images/imageformaticon.png";
import ExcellFormatIcon from "../../assets/images/excelFormatIcon.png";

import HelpBook from "../../assets/images/help_docs.png";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";

import { useLocation } from "react-router-dom";
import { httpCatalog } from "../../helper/http-common";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectSideMenu } from "../../actions/headerMenu/headerMenu";

import Config from "./../../helper/config";

const UploadProductFile = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: "COMMA",
    IsServer: false,
    rbtnRemove: false,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [submitLoading2, setSubmitLoading2] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const { Option } = Select;
  const [imageMessage1, setImageMessage1] = useState(false);
  const [imageMessage2, setImageMessage2] = useState(false);
  const [imageProgress, setImageProgress] = useState();
  const [progressStart, setProgressStart] = useState(false);

  const [progressFileName, setProgressFileName] = useState(null);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  // for Popup
  const [visible, setVisible] = useState(false);
  const [Result, setResult] = useState("");
  // end

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);
  const loadImg = (
    <span>
      {t(
        "If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"
      )}
    </span>
  );

  const replaceDetail = (
    <span>
      {" "}
      {t(
        "Inventory with Dealer Stock # not included in the upload will be deleted."
      )}
    </span>
  );

  const addDetail = (
    <span>
      {" "}
      {t(
        "Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."
      )}
    </span>
  );

  function onChangeDrop(e, name) {
    let tempState = state;

    tempState[name] = e;
    tempState.Error["Delimiter"] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeCheck(e, name) {
    let tempState = state;

    tempState[name] = e.currentTarget.checked;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeRadio(e, name) {
    let tempState = state;
    if (name === "ReplaceAll") {
      setVisibleConfirmModal(true);
      tempState["rbtnRemove"] = true;
    } else {
      tempState["rbtnRemove"] = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }

  // For Popup
  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    // let tempState = state;
    setVisible(false);
  };
  // end

  const handleValidation = (upName) => {
    var isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = t("Please select image.");
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = t("Please select image.");
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = t("Please select file.");
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = t("Please select file.");
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = t("Please select delimiter.");
        isValid = false;
      }
    }
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    return isValid;
  };

  const fileUpload = async (e, name) => {
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(png|jpg|zip)$/i)) {
        const fileSizeBytes = 2147483648; // bytes
        let fileSizeMB = e.target.files[0].size;
        if (fileSizeMB > fileSizeBytes) {
          tempState["Imagefile"] = null;
          tempState["ImagefileName"] = null;
          NotificationManager.error(t("File Should be less than 2Gb"));
        } else {
          if (selectedFiles) {
            allFiles.push(selectedFiles);
            tempState["Imagefile"] = allFiles;
            tempState["ImagefileName"] = allFiles[0].name;
            tempState.Error["Imagefile"] = null;
            setProgressFileName(allFiles[0].name);
          }
        }
      } else {
        tempState.Error["Imagefile"] =
          "Please select .png|.jpg|.zip file only.";

        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt|json)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          var test = e.target.files[0].name;
          if (test.includes("json")) {
            setResult("json");
          } else if (test.includes("csv")) {
            setResult("csv");
          } else if (test.includes("xls")) {
            setResult("xls");
          } else if (test.includes("txt")) {
            setResult("txt");
          }
          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState.Error["Datafile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Datafile"] = t(
          "Please select .csv|.xls|.txt|.json file only."
        );
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const option = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.round((100 * loaded) / total);
      setImageProgress(percent);
    },
  };

  const handleUploadJewelryImageFiles = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      setImageMessage1(true);
      setProgressStart(true);
      const formData = new FormData();

      formData.append("file", state.Imagefile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        httpCatalog
          .post("/Jewelry/UploadJewelryImageFiles", formData, option)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            setSubmitLoading1(false);
            setImageMessage1(false);
            if (
              responseData ==
              "Image upload request has been sent and the file processing has started. You can check upload status in Upload History."
            ) {
              NotificationManager.success(
                t(
                  "Image upload request has been sent and the file processing has started. You can check upload status in Upload History."
                )
              );

              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;

              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setImageMessage2(true);
              document.getElementById("file-upload").value = "";
            } else if (
              message ==
              "The file you have uploaded is already in process with the same name. Please change the file name and try again."
            ) {
              NotificationManager.error(
                t("The file you have uploaded is already in process with the same name. Please change the file name and try again.")
              );
              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setImageMessage2(true);
              document.getElementById("file-upload").value = "";
            } else {
              NotificationManager.error(
                 t("Zip File Not Uploaded.")
              );
              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setImageMessage2(true);
              document.getElementById("file-upload").value = "";
            }
          })
          .catch((error) => {
    
            state["Imagefile"] = null;
            state["ImagefileName"] = null;
            state.Error["Imagefile"] = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
    
            document.getElementById("file-upload").value = "";
            setSubmitLoading1(false);
            setProgressStart(false);
            setImageMessage1(false);
            console.log(error);
            NotificationManager.error(t("File Not Uploaded."));
          });
      } catch (error) {
        NotificationManager.error(t("File Not Uploaded."));
        state["Imagefile"] = null;
        state["ImagefileName"] = null;
        state.Error["Imagefile"] = null;
        setState((prevState) => ({
          ...prevState,
          ...state,
        }));

        document.getElementById("file-upload").value = "";
        setSubmitLoading1(false);
        setProgressStart(false);
        setImageMessage1(false);
        console.log(error);
      }
    }
  };

  const handleUploadJewelryDataFile = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading2(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", DealerId);
      formData.append(
        "fileType",
        Result == "json"
          ? "JSON"
          : Result == "txt"
          ? "TXT"
          : Result == "csv"
          ? "CSV"
          : Result == "xls"
          ? "XLS"
          : ""
      );
      formData.append("userName", ""); //DealerUseName
      formData.append("rbtnRemoveJew", state.rbtnRemove);
      formData.append("SummaryIDJewLog", 0);
      formData.append("SummaryIDJew", 0);
      formData.append(
        "ddlDELIMITERJew",
        Result == "json" ? "JSON" : state.Delimiter
      );
      formData.append("chkflgIsServer", state.IsServer);
      formData.append("PriceType", "");
      formData.append("IsManufacturer", false);
      try {
        CatalogJewelryDataService.UploadJewelryDataFile(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading2(false);
            if (message === "Success") {
              if (
                responseData.lblMsgJew ==
                "Your previous file is already in process. You can not upload this file at this time."
              ) {
                NotificationManager.error(
                  t(
                    "Your previous file is already in process. You can not upload this file at this time."
                  )
                );
              } else if (
                responseData.lblMsgJew ==
                "Please do the mapping of columns based on uploaded sheet."
              ) {
                NotificationManager.error(
                  t("Please do the mapping of columns based on uploaded sheet.")
                );
              } else {
                NotificationManager.success(t("File Uploaded Successfully."));
              }
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setResult("");
              document.getElementById("file-upload1").value = "";
            } else {
              NotificationManager.error(t("File Not Uploaded."));
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              document.getElementById("file-upload1").value = "";
            }
            setResult("");
          })
          .catch((error) => {
            setSubmitLoading2(false);
            console.log(error);
            setResult("");
          });
      } catch (error) {
        setSubmitLoading2(false);
        console.log(error);
        setResult("");
      }
    }
  };


  const saveFile = () => {
    axios({
      url: `${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ImageGuideline.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleRedirectImageUpload = () => {
    dispatch(setSelectSideMenu(["uploadHistory"]));
    navigate("/uploadHistory", {
      state: {
        key: "ImageUpload",
      },
    });
  };
  return (
    <React.Fragment>
      <div className="prodetail__section">
        {loginDetials?.responseData?.vendorStatusID == 3 ? (
          <>{t("Please Contact Gemfind to get Access.")}</>
        ) : (
          <div className="form__fields">
            <div className="col-md-12">
              <div className="prod__maindiv data__maping uplaod__file">
                <Spin spinning={submitLoading1 || submitLoading2}>
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="workarea__heading mt-0 mb-3">
                        {" "}
                        {t("Upload Your Images")}{" "}
                      </h4>
                    </div>
                    <div className="col-lg-9">
                      <div className="input__block">
                        {/* <label> Upload Logo Image for Designers</label> */}
                        <div className="col-md-12 image_block">
                          <div class="row">
                            <div class="col-lg-4 col-md-12">
                              <div className="divimage_block">
                                <label
                                  htmlFor="file-upload"
                                  class="primary-btn choose_file_upload"
                                >
                                  {t("Choose Image")}
                                </label>
                                <input
                                  id="file-upload"
                                  type="file"
                                  onChange={(e) => fileUpload(e, "Image")}
                                />
                                {state.Error ? (
                                  <div className="text-danger">
                                    {state.Error["Imagefile"]}
                                  </div>
                                ) : null}
                                {imageMessage1 ? (
                                  <span className="">
                                    {" "}
                                    {t(
                                      "Please wait while file is Uploading.."
                                    )}{" "}
                                  </span>
                                ) : null}

                                {state.ImagefileName ? (
                                  ""
                                ) : (
                                  <span className="mb-4">
                                    {t("No File Choosen")}{" "}
                                    {/* <span className="text-red">*</span> */}
                                  </span>
                                )}
                                <span className="">{state.ImagefileName}</span>
                                <span className="">
                                  {" "}
                                  500 x 500 {t("Pixels")}{" "}
                                </span>
                                <span className="">
                                  {" "}
                                  {t("Jpg/Png/Zip Format")}{" "}
                                </span>
                              </div>

                              <div className="preview__img choose_file_divimg d-none">
                                <img src={userIcon} alt="img" />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <p>
                                -{" "}
                                {t(
                                  "Compress images together & upload in a .zip format."
                                )}
                              </p>
                              <p>
                                {" "}
                                -{" "}
                                {t(
                                  "Backgrounds should be white or transparent."
                                )}{" "}
                              </p>
                              <p>
                                -{" "}
                                {t(
                                  "Images should be larger than 500 x 500 pixels."
                                )}
                              </p>
                              <p>
                                -{" "}
                                {t(
                                  "Products should occupy more than 80% of canvas."
                                )}
                              </p>
                              <p>
                                - {t("File should be less than 2Gb.")}
                              </p>
                              <div className="help-doc-sp">
                                <img
                                  src={HelpBook}
                                  width="15px"
                                  height="15px"
                                />
                                <p>
                                  {t("Questions? View the")}
                                  <a
                                    href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf`}
                                    target="_blank"
                                    download
                                  >
                                    {" "}
                                    {t("Image Guidelines")}{" "}
                                  </a>
                                  {t("Help Doc")}.
                                </p>
                              </div>

                              <Button
                                className="primary-btn"
                                onClick={(e) =>
                                  handleUploadJewelryImageFiles("Upload1")
                                }
                              >
                                {t("Upload Data")}
                              </Button>
                              {imageMessage2 ? (
                                <>
                                  <p className="mt-2 mb-0 text-success">
                                    {" "}
                                    {t("Your File is InProgress")}.{" "}
                                  </p>

                                  <span
                                    onClick={() => handleRedirectImageUpload()}
                                    className="linkText"
                                  >
                                    {" "}
                                    {t(
                                      "Please click here to see history"
                                    )}
                                    .{" "}
                                  </span>
                                </>
                              ) : null}
                            </div>
                            <div className="col-md-2">
                              <div className="format__img">
                                <img
                                  src={ImageFormatIcon}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="input__block">
                        <h4 className="workarea__heading">
                          {" "}
                          {t("Upload Your Data File")}{" "}
                        </h4>
                        <div className="col-md-12 image_block">
                          <div class="row">
                            <div class="col-lg-4 col-md-12">
                              <div className="divimage_block">
                                <label
                                  htmlFor="file-upload1"
                                  class="primary-btn choose_file_upload"
                                >
                                  {t("Choose File")}
                                </label>
                                <input
                                  id="file-upload1"
                                  type="file"
                                  onChange={(e) => fileUpload(e, "Data")}
                                />
                                {state.Error ? (
                                  <div className="text-danger">
                                    {state.Error["Datafile"]}
                                  </div>
                                ) : null}
                                {state.DatafileName ? (
                                  ""
                                ) : (
                                  <span className="mb-4">
                                    {t("No File Choosen")}{" "}
                                  </span>
                                )}
                                <span>
                                  {" "}
                                  {t("Xls/Txt/Csv/Json Format")}{" "}
                                </span>
                                <span className="">{state.DatafileName}</span>
                              </div>

                              <div className="choose_file_divimg d-none">
                                <img src={userIcon} alt="img" />
                                <span>x</span>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <div>
                                <Select
                                  className="border__grey mobile-w-100"
                                  optionFilterProp="children"
                                  placeholder={t("Please Select")}
                                  value={
                                    Result == "json" ? "JSON" : state.Delimiter
                                  }
                                  style={{ width: 177 }}
                                  onChange={(e) => onChangeDrop(e, "Delimiter")}
                                  disabled={Result == "json" ? true : false}
                                >
                                  {/* <Option value="">Select</Option> */}
                                  <Option value="COMMA">
                                    {" "}
                                    {t("Comma")}{" "}
                                  </Option>
                                  <Option value="TAB">
                                    {" "}
                                    {t("Tab")}{" "}
                                  </Option>
                                  <Option value="JSON"> JSON </Option>
                                </Select>
                                {state.Error ? (
                                  <div className="text-danger">
                                    {state.Error["Delimiter"]}
                                  </div>
                                ) : null}

                                <div className="load__img">
                                  <label>
                                    <input
                                      type="checkbox"
                                      value={state.IsServer}
                                      onChange={(e) =>
                                        onChangeCheck(e, "IsServer")
                                      }
                                    />
                                    <label>
                                      <span>
                                        {" "}
                                        {t(
                                          "Load Images From My Server"
                                        )}{" "}
                                      </span>
                                      <Tooltip
                                        placement="right"
                                        title={loadImg}
                                      >
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip>
                                    </label>
                                  </label>
                                </div>

                                <div className="radio__block">
                                  <div className="radio__btn">
                                    <input
                                      type="radio"
                                      id="dr"
                                      name="mt"
                                      className="mr-1"
                                      onChange={(e) =>
                                        onChangeRadio(e, "ReplaceAll")
                                      }
                                    />
                                    <label
                                    >
                                      {t("Replace All")}
                                      <Tooltip
                                        placement="right"
                                        title={replaceDetail}
                                      >
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip>
                                    </label>
                                  </div>
                                  <div className="radio__btn">
                                    <input
                                      type="radio"
                                      id="jr"
                                      name="mt"
                                      className="mr-1"
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangeRadio(e, "AddUpdate")
                                      }
                                    />
                                    <label
                                    >
                                      {t("Add & Update")}
                                      <Tooltip
                                        placement="right"
                                        title={addDetail}
                                      >
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip>
                                    </label>
                                  </div>
                                </div>
                                <div className="help-doc-sp">
                                  <img
                                    src={HelpBook}
                                    width="15px"
                                    height="15px"
                                  />
                                  <p>
                                    {t("Questions? View the")}{" "}
                                    <a href="#" className="linkText">
                                      {" "}
                                      {t("Jewelry Import")}{" "}
                                    </a>{" "}
                                    {t("Help Doc")}.
                                  </p>
                                </div>
                                <Button
                                  className="primary-btn"
                                  onClick={(e) =>
                                    handleUploadJewelryDataFile("Upload2")
                                  }
                                >
                                  {t("Upload Data")}
                                </Button>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="format__img">
                                <img
                                  src={ExcellFormatIcon}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Popup */}
                      <Popconfirm
                        title={t("Are you sure to update Permission?")}
                        visible={visible}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                      ></Popconfirm>

                      {/* End */}
                    </div>

                    <div className="col-lg-3">
                      <div className="right__sec">
                        <h5>
                          {t(
                            "Add new jewelry items in bulk or make mass item updates."
                          )}
                        </h5>
                        <h5> {t("First time importing?")} </h5>
                        <ul>
                          <li>
                            <b> {t("Import Your Content")} </b>
                            <p>
                              {t(
                                "Choose & upload your data or connect to a 3rd party"
                              )}
                              .
                            </p>
                          </li>
                          <li>
                            <b> {t("Wait For Confirmation")} </b>
                            <p>
                              {t(
                                "You will receive an e-mail confirmation when your content has been uploaded."
                              )}
                            </p>
                          </li>
                          <li>
                            <b> {t("Map Your Content")} </b>
                            <p>
                              {t(
                                "Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."
                              )}
                            </p>
                            <p>
                              {" "}
                              {t(
                                "Required if your data file is not in our format."
                              )}{" "}
                            </p>
                          </li>
                        </ul>
                        <br />
                        <div className="row download__block">
                          <h5> {t("Preparing Your Data")} </h5>
                          <br />
                          <br />
                          <h5> {t("Download Templates")} </h5>
                          <br />
                          <div className="col-md-2 col-2">
                            <img src={DownloadBox} />
                          </div>
                          <div className="col-md-10 col-10 mobile-p-0">
                            <a
                              href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Required%20Fields.xls`}
                              download
                            >
                              {t("Required Fields Form")}
                            </a>
                            <br />
                            <a
                              href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Simple%20Product.xlsx`}
                              download
                            >
                              {t("Simple Product Forms")}{" "}
                            </a>
                            <br />
                            <a
                              href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Ring%20Builder.xlsx`}
                              download
                            >
                              {t("Ring Builder Data Form")}{" "}
                            </a>
                            <br />
                            <a
                              href={`${Config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Configurable%20Product.xlsx`}
                              download
                            >
                              {t("Configurable Product Form")}
                            </a>
                          </div>

                          <br />
                        </div>
                        <br />
                        <div className="row download__block">
                          <h5> {t("Help Docs")} </h5>
                          <br />
                          <div className="col-md-2 col-2">
                            <img src={HelpBook} />
                          </div>
                          <div className="col-md-10 col-10 mobile-p-0">
                            <a className="linkText" href="#" onClick={saveFile}>
                              {t("Image Guideline")}
                            </a>
                            <br />
                          </div>

                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>

                {/* <button onClick={()=> setProgressStart(true)}>Progress Modal</button> */}
                <Modal
                  title={t("Progress")}
                  className="modalconsupld__section"
                  centered
                  visible={progressStart}
                  onOk={() => setProgressStart(false)}
                  onCancel={() => setProgressStart(false)}
                  width={400}
                  footer={[
                    <Button key="back" onClick={() => setProgressStart(false)}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setProgressStart(false)}
                    >
                      {t("Ok")}
                    </Button>,
                  ]}
                >
                  <div>
                    <ProgressBar
                      now={imageProgress}
                      label={`${imageProgress}%`}
                    />
                    <span>
                      {" "}
                      {t("Uploaded")} {imageProgress}%{" "}
                      {t("Total")} 100%
                    </span>
                    <br></br>
                    <span>
                      {" "}
                      {t("Uploading file")}: {progressFileName}
                    </span>
                  </div>
                </Modal>
                <Modal
                  title={t("Confirmation")}
                  centered
                  visible={visibleConfirmModal}
                  onCancel={() => setVisibleConfirmModal(false)}
                  width={400}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setVisibleConfirmModal(false)}
                    >
                      {t("Ok")}
                    </Button>,
                  ]}
                >
                  <div className="text-center">
                    <h5> {t("This option will override the data.")} </h5>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UploadProductFile;
