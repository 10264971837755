import React, { useState, useEffect } from "react";
import {
  Tooltip,
} from "antd";

import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WatchCustomizerService from "../../../services/watch-customizer.service";
import NotificationManager from "react-notifications/lib/NotificationManager";

const WatchCustomizerOptions = () => {
  const { t } = useTranslation();
  const initialWatchCustOptionState = {
    watchCustomizerURL: "",
  };

  const [WatchCustOptionState, setWatchCustOptionState] = useState(
    initialWatchCustOptionState
  );
  const [dealerId, setDealerId] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  /*API binding starts*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetWatchOptionUrl(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);
  const handleGetWatchOptionUrl = (dealerId) => {
    try {
      let inputData = {
        dealerID: dealerId ? dealerId.toString() : "",
      };
      WatchCustomizerService.GetWatchOptionUrl(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setWatchCustOptionState({
                watchCustomizerURL: responseData.watchBuilderURL,
              });
            } else {
              setWatchCustOptionState({});
            }
          } else {
            setWatchCustOptionState({});
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const handleUpdateWatchOptionUrl = (dealerId, url) => {
    try {
      let inputData = {
        dealerID: dealerId ? dealerId.toString() : "",
        watchBuilderURL: url ? url : "",
      };
      WatchCustomizerService.GetUpdateOptionUrl(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              NotificationManager.success(t("Updated successfully."));
              handleGetWatchOptionUrl(dealerId);
            } else {
              setWatchCustOptionState({});
              NotificationManager.error(t("Update failed."));
            }
          } else {
            setWatchCustOptionState({});
            NotificationManager.error(t("Update failed."));
          }
        })
        .catch((error) => {
          NotificationManager.error(t("Update failed."));
        });
    } catch (error) {
      NotificationManager.error(t("Update failed."));
    }
  };
  /*API binding ends*/

  const handleOnChange = (e, name) => {
    setWatchCustOptionState({
      ...WatchCustOptionState,
      [name]: e.target.value,
    });
  };
  const handleApplyFilter = () => {
    handleUpdateWatchOptionUrl(
      dealerId,
      WatchCustOptionState.watchCustomizerURL
    );
  };

  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0">
                {t("Watch Customizer Options")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label>
                {t("Watch Customizer URL")}
                <Tooltip
                  placement="right"
                  title={t(
                    "Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
              <input
                type="text"
                name="watchCustomizerURL"
                value={
                  WatchCustOptionState.watchCustomizerURL
                    ? WatchCustOptionState.watchCustomizerURL
                    : ""
                }
                onChange={(e) => handleOnChange(e, "watchCustomizerURL")}
              />
            </div>
          </div>

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-1" onClick={handleApplyFilter}>
                {" "}
                {t("Update Setting")}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WatchCustomizerOptions;
