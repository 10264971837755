import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";

const UploadMarketingMaterial = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <h4 class="workarea__heading mt-2"> {t("Upload Marketing Material")} </h4>
          </div>

          <div className="col-md-12">
            <div className="input__block">
              <div className="col-md-4 image_block image-block-marketing-sp">
                <div className="row">
                  <div class="col-lg-12 col-md-12">
                    <label className="mt-2"> {t("Folder Path")} </label>
                    <input type="text" placeholder={t("Enter Folder Path")} />
                  </div>

                  <div class="col-lg-12 col-md-12 divimage_block file-upload-sp mt-4">
                    <p className="mt-2"> {t("Choose File To Upload")} </p>
                    <label
                      for="file-upload"
                      class="primary-btn choose_file_upload"
                    >
                      {t("Choose File")}
                    </label>
                    <input id="file-upload" type="file" />
                    <span className="d-inline ml-1">
                    {t("No File Choosen")} <span className="requires_txt">*</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 mt-5">
                  <button className="primary-btn w-100"> {t("Upload File")} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadMarketingMaterial;
