import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
  Spin,
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import { useTranslation } from "react-i18next";
import CatalogGroupDiscountService from "../../services/catalog-group-discount.service";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
const { Option } = Select;

const RetailerGroup = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dealerId, setDealerId] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [retailerGroupList, setRetailerGroupList] = useState([]);
  const [selectedValues, setSelectedValues] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetRetailerGroupDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleGetRetailerGroupDetails = (id) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setLoading(true);
      CatalogGroupDiscountService.GetRetailerGroupDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerGroupList(responseData);
            setSelectedValues(responseData.selectedValue);
            setLoading(false);
          } else {
            setRetailerGroupList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateAddRetailerOnDropdownChange = (
    id,
    retailerId,
    priceGrp
  ) => {
    try {
      let inputData = {
        dealerId: id.toString(),
        retailerID: retailerId.toString(),
        priceGroups: priceGrp.toString(),
      };
      CatalogGroupDiscountService.UpdateAddRetailerOnDropdownChange(inputData)
        .then((response) => {
          var message = response.data.message;

          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully."));
            handleGetRetailerGroupDetails(dealerId);
          } else {
            NotificationManager.error(t("Data Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: t("Company Name (User Name)"),
      dataIndex: "dealerCompany",
    },
    {
      title: t("Group Name"),
      dataIndex: "groupDropDownList",
      render: (item, row) => {
        return (
          <div className="input__block mb-0">
            <Select
              name=""
              className="border__grey"
              showSearch
              placeholder=""
              optionFilterProp="children"
              value={
                row.selectedValue && row.selectedValue != "0"
                  ? parseInt(row.selectedValue)
                  : ""
              }
              onChange={(e) =>
                handleUpdateAddRetailerOnDropdownChange(
                  dealerId,
                  row.dealerID,
                  e
                )
              }
            >
              {row.groupDropDownList.map((item, i) => {
                return <Option value={item.id}>{item.groupName}</Option>;
              })}
            </Select>
          </div>
        );
      },
    },
    // {
    //   title: t("Action"),
    //   dataIndex: "action",
    //   render: (item, row) => {
    //     return (
    //       <div className="action__btns">
    //         <div className="image__block">
    //           <img
    //             src={Edit_icon}
    //             alt=""
    //             onClick={() => setVisible(true)}
    //           />
    //         </div>
    //         <div className="image__block">
    //           <img
    //             src={Delete_iconbl}
    //             alt=""
    //             onClick={(e) =>handleDeleteAddRetailer(row.groupDropDownList[0].id)}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   width: 100,
    // }
  ];

  const data = [
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
    {
      key: "1",
      CompanyName: <span>ZZ.GemFind Leo Schachter</span>,
      GroupName: <span></span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Retailer Group")}
              </h4>
              {/* <button className="primary-btn">
                <img src={Create_icon} />
                {t("Add Retailer")}
              </button> */}
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="button__block my-4">
              <button className="primary-btn">Download</button>
            </div>
          </div> */}

          <div className="col-lg-12 col-md-12">
            <Spin spinning={loading}>
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={retailerGroupList}
                scroll={{ x: 600, y: 800 }}
              />
            </Spin>
          </div>
        </div>
      </div>
      <Modal
        title={t("Add Retailer")}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t("Submit")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Username")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="ChrisDoddridge"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Password")}</label>
                      <input name="showName" type="text" value="Ae15aSxi" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("BrandGroup")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Role")}</label>
                      <input name="showName" type="text" value="Retailer" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("First Name")}</label>
                      <input name="showName" type="text" value="Chris" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Last Name")}</label>
                      <input name="showName" type="text" value="Doddridge" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Address")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="15732 TUSTIN VILLAGE WAY"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Address2")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Email")}</label>
                      <input
                        name="showName"
                        type="email"
                        value="chris@jeweleryexchange.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Company")}</label>
                      <input
                        name="showName"
                        type="text"
                        value="GOLDEN WEST CORP."
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("City")}</label>
                      <input name="showName" type="text" value="TUSTIN" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("State")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder={t("Select State")}
                        optionFilterProp="children"
                      >
                        <Option value="">{t("Select GroupName")}</Option>
                        <Option value="1"> {t("Option 1")} </Option>
                        <Option value="2"> {t("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Retailer Number")}</label>
                      <input name="showName" type="text" value="NY-300637" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Sales Person")}</label>
                      <input name="showName" type="text" value="Lenny Kramer" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Zip")}</label>
                      <input name="showName" type="text" value="92780" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Active")}</label>
                      <Switch
                        name="enabled"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Price Group")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder={t("Select Price Group")}
                        optionFilterProp="children"
                      >
                        <Option value="">{t("Select GroupName")}</Option>
                        <Option value="1"> {t("Option 1")} </Option>
                        <Option value="2"> {t("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Country")}</label>
                      <Select
                        name=""
                        className="border__grey"
                        showSearch
                        placeholder={t("Select Country")}
                        optionFilterProp="children"
                      >
                        <Option value="">{t("Select GroupName")}</Option>
                        <Option value="1"> {t("Option 1")} </Option>
                        <Option value="2"> {t("Option 2")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block">
                      <label>{t("Telephone")}</label>
                      <input name="showName" type="text" value="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default RetailerGroup;
