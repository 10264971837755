import React, { useState, useEffect } from "react";
import { Select, Table, Spin, Modal } from "antd";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import DoughnutChart from "../../admin/JewelryReport/Doughnut";
import { useTranslation } from "react-i18next";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";
import NoReportDataIcon from "../../../assets/images/no-report-img.png";

/*Initial State for Binding Starts*/
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: []
};
/*Initial State for Binding Ends*/
const { RangePicker } = DatePicker;
const { Option } = Select;

const GlobalJewelryCategoryReport = () => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [countCategoryColumnData, setCountCategoryColumnData] = useState([]);
  const [categoryColumnData, setCategoryColumnData] = useState([]);
  const [categoryTotalValuePercentage, setCategoryTotalValuePercentage] =
    useState(0);
  const [tableChartData, setTableChartData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [defaultVisible, setDefaultVisible] = useState(false);
  const [key, setKey] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId
          }));
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id
      };
      setShowLoader(true);
      AdminJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer
            }));

            handleGetClicksBasedOnCategory(id, responseData.forDealer);
            handleGetTableChartData(id, responseData.forDealer);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetClicksBasedOnCategory = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString()
      };
      setShowLoader(true);
      AdminJewelryReportService.GetClicksBasedOnCategory(
        initialEventState
      ).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let showResp = responseData.every((element) => {
            return element.count == "0";
          });

          setShowChart(showResp);
          let category = [];
          let count = [];
          for (let i of responseData) {
            category.push(i.category);
            count.push(i.count);
          }
          let totalValuePercentage = count.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );

          setCountCategoryColumnData(count);
          setCategoryColumnData(category);
          setCategoryTotalValuePercentage(totalValuePercentage);
          setShowLoader(false);
          setKey(!key);
        } else {
          setShowLoader(false);
          setShowChart(true);
        }
      });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      setShowChart(true);
    }
  };
  const handleGetTableChartData = async (
    DealerId,
    forDealerId,
    type,
    pageNumber,
    pageSiz,
    sortColumnName,
    sortOrder
  ) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId,
        forDealer: forDealerId,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: type === "empty" ? "" : type ? type : category.toString(),
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: "",
        reporttype: "Global",
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSiz ? pageSiz : 10,
        sortColumnName:
          sortColumnName == "UserIPAddress"
            ? "UserIPAddress"
            : sortColumnName == "Vendor"
            ? "VendorName"
            : sortColumnName == "Retailer"
            ? "RetailerName"
            : sortColumnName == "Collection"
            ? "CollectionNew"
            : sortColumnName == "category"
            ? "Category"
            : sortColumnName == "date"
            ? "Date"
            : sortColumnName == "StyleNumber"
            ? "StyleNumber"
            : sortColumnName == "metalType"
            ? "MetalType"
            : sortColumnName == "metalColor"
            ? "MetalColor"
            : sortColumnName == "price"
            ? "Price"
            : "date",
        sortOrder: sortOrder ? sortOrder.toString() : sortType.toString()
      };
      setShowTableLoader(true);
      await AdminJewelryReportService.GetTableChartData(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setTotalCount(responseData?.[0]?.totalRecords || 0);
            setTableChartData(responseData);
            setShowTableLoader(false);
          } else {
            setTotalCount(0);
            setTableChartData([]);
            setShowTableLoader(false);
          }
        }
      );
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
    }
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetVendorDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "global"
    };
    try {
      AdminJewelryReportService.GetRetailerDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString()
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e
        }));
      }

      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const changeRetailerListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        vendorIds: data
      };
      AdminJewelryReportService.ChangeRetailerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const changeVendorListHandler = (data) => {
    try {
      data = data.toString();
      let inputData = {
        retailerIds: data
      };
      AdminJewelryReportService.ChangeVendorList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setVendorList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const applyFilterHandler = () => {
    setCategory("");
    handleGetClicksBasedOnCategory(
      selectedData.dealerID,
      selectedData.forDealer
    );
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      "",
      pageNo,
      pageSize
    );
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const [category, setCategory] = useState("");

  const tableSortHandler = (type) => {
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      type
    );
    setCategory(type);
  };

  const [sortType, setSortType] = useState("desc");

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order === "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
    } else if (sorter.order === "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
    } else {
      type = "DESC";
      column = "date";
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      "",
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Jewelry Category Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {t("Date Range")} </label>
              <RangePicker
                format={"MM-DD-YYYY"}
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          {dealerMemberTypeName === "Admin" ? (
            <>
              <div className="col-md-4">
                <div className="input__block">
                  <label> {t("Vendor(s)")} </label>
                  <Select
                    className="border__grey"
                    showSearch
                    mode="multiple"
                    showArrow
                    placeholder={t("Select one or more vendor")}
                    optionFilterProp="children"
                    value={selectedData.vendorIds}
                    onChange={(e) => handleOnChange(e, "select", "vendors")}
                  >
                    {vendorList.map((item, key) => {
                      return (
                        <Option key={item.dealerID} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="input__block">
                  <label> {t("Retailer(s)")} </label>
                  <Select
                    showSearch
                    showArrow
                    className="border__grey"
                    placeholder={t("Select one or more retailer ")}
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "retailers")}
                    notFoundContent={t("No Data Found")}
                    mode="multiple"
                    value={selectedData.retailerIds}
                  >
                    {retailerList.map((item, key) => {
                      return (
                        <Option key={key} value={item.dealerID}>
                          {item.dealerCompany}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </>
          ) : dealerMemberTypeName === "Vendor" ? (
            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Retailer(s)")} </label>
                <Select
                  showSearch
                  showArrow
                  className="border__grey"
                  placeholder={t("Select one or more retailer ")}
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "retailers")}
                  notFoundContent={t("No Data Found")}
                  mode="multiple"
                  value={selectedData.retailerIds}
                >
                  {retailerList.map((item, key) => {
                    return (
                      <Option key={key} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          ) : (
            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Vendor(s)")} </label>
                <Select
                  className="border__grey"
                  showSearch
                  mode="multiple"
                  showArrow
                  placeholder={t("Select one or more vendor")}
                  optionFilterProp="children"
                  value={selectedData.vendorIds}
                  onChange={(e) => handleOnChange(e, "select", "vendors")}
                >
                  {vendorList.map((item, key) => {
                    return (
                      <Option key={item.dealerID} value={item.dealerID}>
                        {item.dealerCompany}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-1" onClick={applyFilterHandler}>
                {t("Apply Filter")}
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <label>
              <b>{t("Jewelry Clicks Based on Category")}</b>
            </label>
          </div>
          <div>
            {!showChart ? (
              <Spin spinning={showLoader}>
                {categoryColumnData !== null &&
                  categoryColumnData.length >= 1 &&
                  countCategoryColumnData.length >= 1 &&
                  categoryTotalValuePercentage !== 0 && (
                    <div className="col-lg-12">
                      <div className="chart__section">
                        <DoughnutChart
                          key={key}
                          label={categoryColumnData}
                          data={countCategoryColumnData}
                          typeGetter={tableSortHandler}
                          totalValuePercentage={categoryTotalValuePercentage}
                        />
                      </div>
                    </div>
                  )}
              </Spin>
            ) : (
              <div className="no-report-render">
                <div className="img-space">
                  <img src={NoReportDataIcon} alt={t("No Data")} />
                </div>
                <div className="desc-space">
                  <h6 className="linkText">
                    {t("Sorry, No Chart Data was found")}
                  </h6>
                </div>
              </div>
            )}
            <div className="col-lg-12 col-md-12">
              <Table
                className="pendjewelreq__tbl"
                columns={[
                  {
                    title: t("Product Image"),
                    dataIndex: "imagePathNew",
                    width: 120,
                    render: (item, data) => (
                      <>
                        {data.imagePathNew ? (
                          <span
                            onClick={() => imageClickHandler(data.imagePathNew)}
                          >
                            <img
                              src={data.imagePathNew}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        ) : (
                          <span onClick={() => setDefaultVisible(true)}>
                            <img
                              src={DefaultImage}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        )}
                      </>
                    )
                  },
                  {
                    title: t("User IP Address"),
                    dataIndex: "userIPAddress",
                    sorter: (a, b) => {},
                    width: 120
                  },
                  {
                    title: t("Category"),
                    dataIndex: "category",
                    sorter: (a, b) => {},
                    width: 120
                  },
                  {
                    title: t("Date"),
                    dataIndex: "date",
                    sorter: (a, b) => {},
                    width: 120,
                    render: (row, item) => {
                      //

                      if (Object.keys(item.date).length !== 0) {
                        return (
                          <React.Fragment>
                            <span>
                              {moment(item.date).format(
                                "MM-DD-YYYY, hh:mm:ss A"
                              )}
                            </span>
                          </React.Fragment>
                        );
                      }
                    }
                  },
                  {
                    title: t("Metal Type"),
                    dataIndex: "metalType",
                    sorter: (a, b) => {},
                    width: 120
                  },
                  {
                    title: t("Metal Color"),
                    dataIndex: "metalColor",
                    sorter: (a, b) => {},
                    width: 120
                  },
                  {
                    title: t("Price"),
                    dataIndex: "price",
                    sorter: (a, b) => {},
                    width: 120
                  }
                ]}
                onChange={handleTableASCDES}
                pagination={{
                  total: totalCount,
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                  pageSize: pageSize,
                  current: pageNo
                }}
                dataSource={tableChartData}
                loading={showTableLoader}
                scroll={{ x: 600, y: 800 }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} alt="img" className="img-fluid" />
        </div>
      </Modal>

      <Modal
        title=""
        centered
        visible={defaultVisible}
        onOk={() => setDefaultVisible(false)}
        onCancel={() => setDefaultVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={DefaultImage} alt="img" className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GlobalJewelryCategoryReport;
