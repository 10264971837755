import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Button,
  Table,
  Modal,
  Slider,
  Empty,
  Spin,
  Input,
  Space,
  Checkbox,
  Tooltip,
} from "antd";
import Roundimg from "../../../assets/images/Round_65x65.jpg";
import RadiantImg from "../../../assets/images/Radiant.jpg";
import PrincessImg from "../../../assets/images/Princess.jpg";
import PearImg from "../../../assets/images/Pear.jpg";
import OvalImg from "../../../assets/images/Oval.jpg";
import MarquiseImg from "../../../assets/images/Marquise.jpg";
import HeartImg from "../../../assets/images/Heart.jpg";
import EmeraldImg from "../../../assets/images/emerald.jpg";
import CushionImg from "../../../assets/images/Cushion.jpg";
import AsscherImg from "../../../assets/images/Asscher.jpg";
import { useTranslation } from "react-i18next";
import Filter_icon from "../../../assets/images/filter_icon.png";
import space__icon from "../../../assets/images/space__icon.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import catalogDiamondService from "../../../services/catalog-diamond.service";
import { NotificationManager } from "react-notifications";
import DealerProfileService from "../../../services/dealer-profile.service";
import logoImg from "../../../assets/images/JewelCloud_Logo_Black.jpg";
import footerlogoImg from "../../../assets/images/GemFind-footer.jpg";
import videoIcon from "../../../assets/images/video_icon.png";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../../actions/headerMenu/headerMenu";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";

const initialState = {
  DealerID: [],
  CertificateID: [],
  Shapes: [],
  CutFrom: null,
  CutTo: null,
  CaratMin: 0,
  CaratMax: 22,
  PriceMin: 0,
  PriceMax: 1000000,
  PriceCaratMin: 0,
  PriceCaratMax: 22000,
  ClarityFrom: null,
  ClarityTo: null,
  ColorFrom: null,
  ColorTo: null,
  DepthMin: 0,
  DepthMax: 100,
  TableMin: 0,
  TableMax: 100,
  PolishFrom: null,
  PolishTo: null,
  LengthMin: null,
  LengthMax: null,
  WidthMin: null,
  WidthMax: null,
  HeigthMin: null,
  HeigthMax: null,
  SummetryFrom: null,
  SummetryTo: null,
  GirdleFrom: null,
  GirdleTo: null,
  CrownAngleMin: 0,
  CrownAngleMax: 100,
  PavilionAngleMin: 0,
  PavilionAngleMax: 100,
  CuletFrom: null,
  CuletTo: null,
  CuletCondFrom: null,
  CuletCondTo: null,
  FluorescenceFrom: null,
  FluorescenceTo: null,
  MatchPairID: null,
  HasVideo: "",

  ShapeIsSelected: {
    Round: false,
    Radiant: false,
    Princess: false,
    Pear: false,
    Oval: false,
    Marquise: false,
    Heart: false,
    Emerald: false,
    Cushion: false,
    Asscher: false,
    All: false,
  },
};

const initialValidation = {
  depthMinVal: "",
  depthMaxVal: "",
  tableMinVal: "",
  tableMaxVal: "",
  CrownAngleMinVal: "",
  CrownAngleMaxVal: "",
  PavilionAngleMinVal: "",
  PavilionAngleMaxVal: "",
};

const { Option } = Select;
const ViewMyMinedDiamond = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVideo, setIsVideo] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visibleImageZoom, setVisibleImageZoom] = useState(false);
  const [sumbitLoding, setSumbitLoding] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 11]);
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [priceCaratValue, setPriceCaratValue] = useState([0, 22000]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [emailCertificat, setEmailCertificat] = useState({});
  const [errorEmailCertificat, setErrorEmailCertificat] = useState({});
  const [tableResetKey, setTableResetKey] = useState(false);
  const [state, setState] = useState(initialState);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sendDetails, setSendDetails] = useState({});
  const [errorSendDetails, setErrorSendDetails] = useState({});
  const [dealerList, setDealerList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [cutList, setCutList] = useState([]);
  const [clarityList, setClarityList] = useState([]);
  const [bordered, setBordered] = React.useState(true);
  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);
  const [colorList] = useState([
    {
      colorID: null,
      colorName: t("Any"),
    },
    {
      colorID: 68,
      colorName: "D",
    },
    {
      colorID: 69,
      colorName: "E",
    },
    {
      colorID: 70,
      colorName: "F",
    },
    {
      colorID: 71,
      colorName: "G",
    },
    {
      colorID: 72,
      colorName: "H",
    },
    {
      colorID: 73,
      colorName: "I",
    },
    {
      colorID: 74,
      colorName: "J",
    },
    {
      colorID: 75,
      colorName: "K",
    },
    {
      colorID: 76,
      colorName: "L",
    },
    {
      colorID: 77,
      colorName: "M",
    },
    {
      colorID: 78,
      colorName: "N",
    },
    {
      colorID: 79,
      colorName: "O",
    },
    {
      colorID: 80,
      colorName: "P",
    },
    {
      colorID: 81,
      colorName: "Q",
    },
    {
      colorID: 82,
      colorName: "R",
    },
    {
      colorID: 83,
      colorName: "S",
    },
    {
      colorID: 84,
      colorName: "T",
    },
    {
      colorID: 85,
      colorName: "U",
    },
    {
      colorID: 86,
      colorName: "V",
    },
    {
      colorID: 87,
      colorName: "W",
    },
    {
      colorID: 88,
      colorName: "X",
    },
    {
      colorID: 89,
      colorName: "Y",
    },
    {
      colorID: 90,
      colorName: "Z",
    },
  ]);
  const [polishList, setPolishList] = useState([]);
  const [summetryList, setSummetryList] = useState([]);
  const [girdleList, setGirdleList] = useState([]);
  const [culetList, setCuletList] = useState([]);
  const [culetCondList, setCuletCondList] = useState([]);
  const [fluorescenceList] = useState([
    {
      fluorescenceID: 1,
      fluorescenceName: "None",
    },
    {
      fluorescenceID: 2,
      fluorescenceName: "Very Slight",
    },
    {
      fluorescenceID: 3,
      fluorescenceName: "Slight",
    },
    {
      fluorescenceID: 4,
      fluorescenceName: "Faint",
    },
    {
      fluorescenceID: 5,
      fluorescenceName: "Medium",
    },
    {
      fluorescenceID: 6,
      fluorescenceName: "Strong",
    },
    {
      fluorescenceID: 7,
      fluorescenceName: "Very Strong",
    },
  ]);
  const [matchedPairList] = useState([
    {
      matchedPairID: "0",
      matchedPairName: "Search All Stones",
    },
    {
      matchedPairID: "Yes",
      matchedPairName: "Search Single Stone",
    },
    {
      matchedPairID: "No",
      matchedPairName: "Search Match Pairs",
    },
  ]);
  const [dealerId, setDealerId] = useState(0);
  const [msgData, setMsgData] = useState("");
  const [msgLoading, setMsgLoading] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [EmailLoading, setEmailLoading] = useState(false);
  const [validation, setValidation] = useState(initialValidation);
  const [filterBtnVisble, setFilterBtnVisble] = useState(true);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [companyLogoLoading, setcompanyLogoLoading] = useState(false);
  const [btnValue, setBtnValue] = useState(1);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [sortColumnName, setSortColumnName] = useState("tmpColumn Asc");
  const [sortType, setSortType] = useState("Asc");
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filter, setFilter] = useState("");
  const [showMoreLess, setShowMoreLess] = useState(false);
  const location = useLocation();
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol = currency?.substring(currency?.lastIndexOf("-") + 1);
  const [diamondList, setDiamondList] = useState([]);
  const [caratSlideValue1, setCaratSlideValue1] = useState([0, 11]);
  const [priceValue1, setPriceValue1] = useState([0, 1000000]);
  const [priceCaratValue1, setPriceCaratValue1] = useState([0, 22000]);
  const [depthMinMax, setDepthMinMax] = useState([0, 0]);
  const [tableMinMax, setTableMinMax] = useState([0, 0]);
  const [currencySign, setCurrencySign] = useState("$");
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (location.state) {
        handleGetDynamicData(location.state.id);
        setDealerId(location.state.id);
      } else if (loginDetials.responseData) {
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDynamicData(loginDetials.responseData.dealerId);
        setDealerMemberTypeName(loginDetials.responseData.dealerMemberTypeName);
      }
    }
    PageLoadResetFilter();
  }, []);

  useEffect(() => {
    const currentPageDiamondIds = diamondList
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .map((diamond) => diamond.intDiamondId);

    if (currentPageDiamondIds.every((id) => checkBoxValue.includes(id))) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [checkBoxValue, diamondList, currentPage, pageSize]);

  const handleSendFriendemail = () => {
    let inputData = {
      name: emailCertificat.Name,
      email: emailCertificat.Email,
      friendsName: emailCertificat.FriendsName,
      friendsEmail: emailCertificat.FriendsEmail,
      comments: emailCertificat.Comments,
      dealerID: parseInt(dealerId),
      dInventoryID: parseInt(selectedRowDetails.intDiamondId),
    };
    setEmailLoading(true);
    catalogDiamondService
      .SendFriendemail(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          setVisible3(false);
          NotificationManager.success(t("Mail sent successfully."));
        } else {
          NotificationManager.error(t("Mail not sent."));
        }
      })
      .catch((error) => {
        setEmailLoading(false);
        console.log(error);
      })
      .finally(() => {
        setEmailLoading(false);
      });

    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };

  const handleSendMsg = () => {
    let inputData = {
      dealrId: selectedRowDetails.dealerID,
      msg: msgData,
      intDiamondId: selectedRowDetails.intDiamondId.toString(),
    };
    setMsgLoading(true);
    catalogDiamondService
      .SendMsg(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          setVisible1(false);
          NotificationManager.success(t("Mail sent successfully."));
        } else {
          NotificationManager.error(t("Mail not sent."));
        }
      })
      .catch((error) => {
        setMsgLoading(false);
        console.log(error);
      })
      .finally(() => {
        setMsgLoading(false);
      });
  };

  const handleMsgValidation = () => {
    if (msgData) {
      if (msgData.trim() === "") {
        setMsgError(t("Please enter message."));
      } else {
        handleSendMsg();
      }
    } else {
      setMsgError(t("Please enter message."));
    }
  };

  const handleCancel1 = () => {
    setVisible1(false);
    setSelectedDealer("");
    setMsgData("");
    setMsgError("");
  };
  const handleOk1 = (item) => {
    setSelectedRowDetails(item);
    handleGetCompanyProfileDetails(item.dealerID);
    setMsgData("");
    setMsgError("");
    setVisible1(true);
  };

  const handleGetCompanyProfileDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setcompanyLogoLoading(true);
    DealerProfileService.GetCompanyProfileDetails(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setSelectedDealer(responseData.dealerCompany);
          }
        } else {
          setSelectedDealer("");
        }
      })
      .catch((error) => {
        setcompanyLogoLoading(false);
        console.log(error);
      })
      .finally(() => {
        setcompanyLogoLoading(false);
      });
  };

  const handleGetDealersForDiamondCatalogList = async (id, Data) => {
    let inputData = {
      type: props.type || "Mined",
      dealerId: id,
    };
    catalogDiamondService
      .GetDealersForDiamondCatalogList(inputData)
      .then((response) => {
        handleGetCertificatesForDiamondCatalogList(id);
        let message = response.data.message;
        let responseData = response.data.responseData;
        // responseData.push({ dealerID: id, dealerCompany: "" });
        handleGetDiamondList(
          id,
          pageNo,
          pageSize,
          sortColumnName,
          sortType,
          filter,
          responseData,
          false,
          Data
        );
        if (message === "Success") {
          setDealerList(responseData);
        } else {
          setDealerList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setcompanyLogoLoading(false);
      });
  };

  const handleGetCertificatesForDiamondCatalogList = async (id) => {
    let inputData = {
      type: props.type || "Mined",
    };
    catalogDiamondService
      .GetCertificatesForDiamondCatalogList(inputData)
      .then((response) => {
        handleGetAllMINEDropDownList();
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setCertificateList(responseData);
        } else {
          setCertificateList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetAllMINEDropDownList = async (id) => {
    let inputData = {
      type: props.type || "Mined",
    };
    await catalogDiamondService
      .GetAllMINEDropDownList(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setCutList(responseData[0]);
          setClarityList(responseData[1]);
          setPolishList(responseData[2]);
          setSummetryList(responseData[3]);
          setGirdleList(responseData[4]);
          setCuletList(responseData[5]);
          setCuletCondList(responseData[6]);
        } else {
          setCutList([]);
          setClarityList([]);
          setPolishList([]);
          setSummetryList([]);
          setGirdleList([]);
          setCuletList([]);
          setCuletCondList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenDetailsModal = (row) => {
    setVisible(true);
    setSelectedRowDetails(row);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const handleCancel5 = () => {
    setVisible5(false);
  };

  const handleDeleteModal = () => {
    if (selectedRows.length > 0) {
      setVisible3(true);
    } else {
      NotificationManager.error(
        t("Please select diamonds from list to delete.")
      );
    }
  };

  const handleSendModal = () => {
    if (selectedRows.length > 0 || isSelectAll) {
      setVisible4(true);
    } else {
      NotificationManager.error(
        t("Please select diamonds from list to send via email.")
      );
    }
  };

  const onChangeMultiDrop = (e, name) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setFilterBtnVisble(false);
      setBtnValue(1);
    } else {
      setFilterBtnVisble(true);
      setBtnValue(0);
    }
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChangeDrop = (e, name) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setFilterBtnVisble(false);
      setBtnValue(1);
    } else {
      setFilterBtnVisble(true);
      setBtnValue(0);
    }
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChange = (e, name) => {
    setFilterBtnVisble(false);
    let tempState = state;
    tempState[name] = e.target.value;

    if (name === "DepthMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.depthMinVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.depthMinVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "DepthMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.depthMaxVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.depthMaxVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "TableMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.tableMinVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.tableMinVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "TableMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.tableMaxVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.tableMaxVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "CrownAngleMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.CrownAngleMinVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.CrownAngleMinVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "CrownAngleMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.CrownAngleMaxVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.CrownAngleMaxVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "PavilionAngleMin") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.PavilionAngleMinVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.PavilionAngleMinVal = "";
        setFilterBtnVisble(false);
      }
    }
    if (name === "PavilionAngleMax") {
      if (e.target.value > 100 || e.target.value < 0) {
        validation.PavilionAngleMaxVal = "Incorrect Range";
        setFilterBtnVisble(true);
      } else {
        validation.PavilionAngleMaxVal = "";
        setFilterBtnVisble(false);
      }
    }
    setValidation(validation);
    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setFilterBtnVisble(false);
      setBtnValue(1);
    } else {
      setFilterBtnVisble(true);
      setBtnValue(0);
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const PageLoadResetFilter = () => {
    setTableResetKey(!tableResetKey);
    let tempState = state;
    tempState.DealerID = [];
    tempState.CertificateID = [];
    tempState.CutFrom = null;
    tempState.CutTo = null;

    tempState.ClarityFrom = null;
    tempState.ClarityTo = null;
    tempState.ColorFrom = null;
    tempState.ColorTo = null;
    tempState.DepthMin = 0;
    tempState.DepthMax = 1000000;
    tempState.TableMin = 0;
    tempState.TableMax = 100;
    tempState.PolishFrom = null;
    tempState.PolishTo = null;
    tempState.LengthMin = null;
    tempState.LengthMax = null;
    tempState.WidthMin = null;
    tempState.WidthMax = null;
    tempState.HeigthMin = null;
    tempState.HeigthMax = null;
    tempState.SummetryFrom = null;
    tempState.SummetryTo = null;
    tempState.GirdleFrom = null;
    tempState.GirdleTo = null;
    tempState.CrownAngleMin = 0;
    tempState.CrownAngleMax = 100;
    tempState.PavilionAngleMin = 0;
    tempState.PavilionAngleMax = 100;
    tempState.CuletFrom = null;
    tempState.CuletTo = null;
    tempState.CuletCondFrom = null;
    tempState.CuletCondTo = null;
    tempState.FluorescenceFrom = null;
    tempState.FluorescenceTo = null;
    tempState.MatchPairID = null;
    tempState.HasVideo = "";
    state.ShapeIsSelected = {
      Round: false,
      Radiant: false,
      Princess: false,
      Pear: false,
      Oval: false,
      Marquise: false,
      Heart: false,
      Emerald: false,
      Cushion: false,
      Asscher: false,
      All: false,
    };
    state.Shapes = [];
    setCaratSlideValue([state.CaratMin, state.CaratMax]);
    setPriceValue([state.PriceMin, state.PriceMax]);
    setPriceCaratValue([state.PriceCaratMin, state.PriceCaratMax]);
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setValidation(initialValidation);
  };

  const onResetFilter = () => {
    setTableResetKey(!tableResetKey);
    let tempState = state;
    tempState.DealerID = [];
    tempState.CertificateID = [];
    tempState.CutFrom = null;
    tempState.CutTo = null;

    tempState.ClarityFrom = null;
    tempState.ClarityTo = null;
    tempState.ColorFrom = null;
    tempState.ColorTo = null;
    tempState.DepthMin = 0;
    tempState.DepthMax = 100;
    tempState.TableMin = 0;
    tempState.TableMax = 100;
    tempState.PolishFrom = null;
    tempState.PolishTo = null;
    tempState.LengthMin = null;
    tempState.LengthMax = null;
    tempState.WidthMin = null;
    tempState.WidthMax = null;
    tempState.HeigthMin = null;
    tempState.HeigthMax = null;
    tempState.SummetryFrom = null;
    tempState.SummetryTo = null;
    tempState.GirdleFrom = null;
    tempState.GirdleTo = null;
    tempState.CrownAngleMin = 0;
    tempState.CrownAngleMax = 100;
    tempState.PavilionAngleMin = 0;
    tempState.PavilionAngleMax = 100;
    tempState.CuletFrom = null;
    tempState.CuletTo = null;
    tempState.CuletCondFrom = null;
    tempState.CuletCondTo = null;
    tempState.FluorescenceFrom = null;
    tempState.FluorescenceTo = null;
    tempState.MatchPairID = null;
    tempState.HasVideo = "";
    state.ShapeIsSelected = {
      Round: false,
      Radiant: false,
      Princess: false,
      Pear: false,
      Oval: false,
      Marquise: false,
      Heart: false,
      Emerald: false,
      Cushion: false,
      Asscher: false,
      All: false,
    };
    state.Shapes = [];
    setCaratSlideValue([state.CaratMin, state.CaratMax]);
    setPriceValue([state.PriceMin, state.PriceMax]);
    setPriceCaratValue([state.PriceCaratMin, state.PriceCaratMax]);
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setValidation(initialValidation);

    handleGetDiamondList(
      dealerId,
      pageNo,
      pageSize,
      sortColumnName,
      sortType,
      filter,
      "",
      false,
      state,
      true
    );
  };

  const onShapeClick = (shape) => {
    if (
      validation.tableMaxVal === "" &&
      validation.tableMinVal === "" &&
      validation.depthMaxVal === "" &&
      validation.depthMinVal === "" &&
      validation.CrownAngleMinVal === "" &&
      validation.CrownAngleMaxVal === "" &&
      validation.PavilionAngleMinVal === "" &&
      validation.PavilionAngleMaxVal === ""
    ) {
      setFilterBtnVisble(false);
      setBtnValue(1);
    } else {
      setFilterBtnVisble(true);
      setBtnValue(0);
    }
    if (shape === "All") {
      state.ShapeIsSelected.All = !state.ShapeIsSelected.All;
      state.ShapeIsSelected.Round = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Radiant = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Princess = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Pear = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Oval = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Marquise = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Heart = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Emerald = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Cushion = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Asscher = state.ShapeIsSelected.All;
    } else {
      state.ShapeIsSelected.All = false;
      state.ShapeIsSelected[shape] = !state.ShapeIsSelected[shape];
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      if (diamondList.length === selectedRows.length) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleSendInviteMailForDiamonds = () => {
    var mapping = [];
    for (var i = 0; i < selectedRows.length; i++) {
      var obj = {};

      obj["isLabCreated"] = true;
      obj["inventoryOnly"] = true;
      obj["publicViewing"] = true;
      obj["outonMemo"] = "";
      obj["sold"] = true;
      obj["inventoryRegion"] = "";
      obj["dealerID"] = selectedRows[i].dealerID
        ? parseInt(selectedRows[i].dealerID)
        : 0;
      obj["DInventoryID"] = selectedRows[i].intDiamondId
        ? Number(selectedRows[i].intDiamondId)
        : 0;
      obj["comments"] = selectedRows[i].comments;
      obj["dealerInventoryNo"] = selectedRows[i].dealerInventoryNo;
      obj["employeeName"] = "";
      obj["employeeID"] = "";
      obj["cut"] = selectedRows[i].txtShape;
      obj["measurements"] = selectedRows[i].txtMeasurements;
      obj["parcel"] = "";
      obj["tableMeasure"] = selectedRows[i].fltTable
        ? selectedRows[i].fltTable
        : 0;
      obj["depth"] = selectedRows[i].fltDepth ? selectedRows[i].fltDepth : 0;
      obj["color"] = selectedRows[i].txtColor;
      obj["clarityID"] =
        selectedRows[i].intClarityPriority == "None"
          ? 0
          : parseInt(selectedRows[i].intClarityPriority);
      obj["size"] = selectedRows[i].fltCarat ? selectedRows[i].fltCarat : 0;
      obj["polish"] = selectedRows[i].txtPolish;
      obj["symmetry"] = selectedRows[i].txtSymmetry;
      obj["flouresence"] = selectedRows[i].txtFluorescence;
      obj["girdle"] = selectedRows[i].txtgridle;
      obj["crown"] = selectedRows[i].fltCrown;
      obj["culet"] = selectedRows[i].txtCulet;
      obj["pavillion"] = selectedRows[i].txtPavillion;
      obj["certificateNo"] = selectedRows[i].certificateNo;
      obj["stones"] = selectedRows[i].stones
        ? parseInt(selectedRows[i].stones)
        : 0;
      obj["costperCarat"] = selectedRows[i].fltPrice
        ? parseInt(selectedRows[i].fltPrice)
        : 0;
      obj["sellingPrice"] = 0;
      obj["certificate"] = selectedRows[i].txtCertificate;
      obj["pairSeparable"] = "";
      obj["pairStockNumber"] = selectedRows[i].txtStockNo;
      obj["cutGrade"] = selectedRows[i].txtCutGrade;
      obj["culetCondition"] = "";
      obj["crownPercentage"] = "";
      obj["pavilionPercentage"] = "";
      obj["isActive"] = "";
      obj["city"] = "";
      obj["state"] = "";
      obj["country"] = "";
      mapping.push(obj);
    }

    let inputData = {
      dealerId: dealerId,
      diamondDetailList: isSelectAll ? "All" : mapping,
      name: sendDetails.Name,
      eMail: sendDetails.Email,
      markUp: Number(sendDetails.Markup),
      message: sendDetails.Message,
      mailType: "",
    };

    setSumbitLoding(true);
    catalogDiamondService
      .SendInviteMailForDiamonds(inputData)
      .then((response) => {
        let message = response.data.message;

        if (message === "Success") {
          NotificationManager.success(t("Email has been sent successfully."));
          handleCancel4();
          handleResetAfterEmailSend();
          setIsSelectAll(false);
        } else {
          NotificationManager.error(t("Email has not been sent."));
          handleCancel4();
          handleResetAfterEmailSend();
          setIsSelectAll(false);
        }
      })
      .catch((error) => {
        setSumbitLoding(false);
        console.log(error);
      })
      .finally(() => {
        setSumbitLoding(false);
      });
  };

  const handleOnChangeRadioHasVideo = (event) => {
    setState({
      ...state,
      HasVideo: event.target.name,
    });
    setFilterBtnVisble(false);
  };

  const handleDeleteConfirmation = () => {
    setVisible3(false);
    if (
      selectedRows &&
      selectedRows.map((x) => x.dealerID) == dealerId.toString
    ) {
      setVisible5(true);
      NotificationManager.error(
        "#" +
          selectedRows.map((x) => x.intDiamondId).join(",") +
          t("not deleted as those are not your diamonds.")
      );
    } else {
      handleDeleteDiamondByIds();
    }
  };
  const handleVisible6 = () => {
    setVisible6(true);
  };
  const handleDeleteDiamondByIds = () => {
    let inputData = {
      dealerId: dealerId,
      diamondIds: isSelectAll
        ? "All"
        : selectedRows?.map((x) => x.intDiamondId)?.join(","),
    };
    setSumbitLoding(true);
    catalogDiamondService
      .DeleteDiamondByIds(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData[0].message == "Not Authorize") {
            setVisible5(true);
          } else {
            setVisible3(false);
            NotificationManager.success(t("Record Deleted Successfully."));
            setTableResetKey(!tableResetKey);
            handleGetDynamicData(dealerId);
            setSelectedRows([]);
            setCheckBoxValue([]);
            handleVisible6();
          }
        } else {
          NotificationManager.error(t("Record Not Deleted."));
        }
      })
      .catch((error) => {
        setSumbitLoding(false);
        console.log(error);
      })
      .then(() => {
        setSumbitLoding(false);
      });
  };
  const handleGetDiamondList = async (
    id,
    no,
    size,
    sortColumnName,
    sortType,
    filter,
    dealerIds,
    val,
    responseData,
    reset
  ) => {
    let tempshapes = Object.keys(state.ShapeIsSelected)
      .filter((k) => state.ShapeIsSelected[k] === true)
      .map((x) => x)
      .join(",");
    let strSelectedDealers = [];
    let tempDealerState = state;
    if (dealerIds) {
      for (let i = 0; i < dealerIds.length; i++) {
        strSelectedDealers.push(dealerIds[i].dealerID);
      }
      tempDealerState["DealerID"] = strSelectedDealers;

      setState((prevState) => ({
        ...prevState,
        ...tempDealerState,
      }));
    } else {
      for (let i = 0; i < dealerList.length; i++) {
        strSelectedDealers.push(dealerList[i].dealerID);
      }
    }

    var inputData = {
      dealerID: id.toString(),
      shapes: reset ? "" : tempshapes,
      colorMin: state.ColorFrom == null ? "-1" : state.ColorFrom.toString(),
      colorMax: state.ColorTo == null ? "-1" : state.ColorTo.toString(),
      clarityMin: reset
        ? "-1"
        : state.ClarityFrom == null
        ? "-1"
        : state.ClarityFrom.toString(),

      clarityMax: reset
        ? "-1"
        : state.ClarityTo == null
        ? "-1"
        : state.ClarityTo.toString(),

      caratMin: reset
        ? caratSlideValue1[0].toString()
        : responseData.sldCaratMin
        ? responseData.sldCaratMin.toString()
        : caratSlideValue[0]
        ? caratSlideValue[0].toString()
        : "0",
      caratMax: reset
        ? caratSlideValue1[1].toString()
        : responseData.sldCaratMax
        ? responseData.sldCaratMax.toString()
        : caratSlideValue[1]
        ? caratSlideValue[1].toString()
        : "0",
      priceMin: reset
        ? priceValue1[0].toString()
        : responseData.sldPriceMin
        ? responseData.sldPriceMin.toString()
        : priceValue[0]
        ? priceValue[0].toString()
        : "0",
      priceMax: reset
        ? priceValue1[1].toString()
        : responseData.sldPriceMax
        ? responseData.sldPriceMax.toString()
        : priceValue[1]
        ? priceValue[1].toString()
        : "0",
      certificate:
        state.CertificateID.length > 0
          ? state.CertificateID.map((x) => x).join(",")
          : "%",

      cutGradeMin: reset
        ? "-1"
        : state.CutFrom == null
        ? "-1"
        : state.CutFrom.toString(),

      cutGradeMax: reset
        ? "-1"
        : state.CutTo == null
        ? "-1"
        : state.CutTo.toString(),

      symmetryMin: reset
        ? "-1"
        : state.SummetryFrom == null
        ? "-1"
        : state.SummetryFrom.toString(),
      symmetryMax: reset
        ? "-1"
        : state.SummetryTo == null
        ? "-1"
        : state.SummetryTo.toString(),

      polishMin: reset
        ? "-1"
        : state.PolishFrom == null
        ? "-1"
        : state.PolishFrom.toString(),

      polishMax: reset
        ? "-1"
        : state.PolishTo == null
        ? "-1"
        : state.PolishTo.toString(),

      depthMin: reset
        ? depthMinMax[0].toString()
        : responseData.sldDepthMin
        ? responseData.sldDepthMin.toString()
        : state.DepthMin
        ? state.DepthMin.toString()
        : "",
      depthMax: reset
        ? depthMinMax[1].toString()
        : responseData.sldDepthMax
        ? responseData.sldPriceMax.toString()
        : state.DepthMax
        ? state.DepthMax.toString()
        : "",
      tableMin: reset
        ? tableMinMax[0].toString()
        : responseData.sldTableMin
        ? responseData.sldTableMin.toString()
        : state.TableMin
        ? state.TableMin.toString()
        : "",
      tableMax: reset
        ? tableMinMax[1].toString()
        : responseData.sldTableMax
        ? responseData.sldTableMax.toString()
        : state.TableMax
        ? state.TableMax.toString()
        : "",

      fluorescenceMin: reset
        ? "-1"
        : state.FluorescenceFrom == null
        ? "-1"
        : state.FluorescenceFrom.toString(),

      fluorescenceMax: reset
        ? "-1"
        : state.FluorescenceTo == null
        ? "-1"
        : state.FluorescenceTo.toString(),

      pairCode: "",
      orderBy:
        sortColumnName == "txtStockNo"
          ? "DealerInventoryNo"
          : sortColumnName == "txtShape"
          ? "Cut"
          : sortColumnName == "fltCarat"
          ? "Size"
          : sortColumnName == "txtCulet"
          ? "CutGrade"
          : sortColumnName == "txtColor"
          ? "Color"
          : sortColumnName == "txtClarity"
          ? "Clarity"
          : sortColumnName == "fltRapPercent"
          ? "OffRapaport"
          : sortColumnName == "fltPrice"
          ? "FltPrice"
          : sortColumnName == "fltDepth"
          ? "Depth"
          : sortColumnName == "fltTable"
          ? "TableMeasure"
          : sortColumnName == "txtCertificate"
          ? "Certificate"
          : sortColumnName == "txtSymmetry"
          ? "Symmetry"
          : sortColumnName == "dealer"
          ? "DealerID"
          : sortColumnName == "txtFluorescence"
          ? "Flouresence"
          : sortColumnName == "txtMeasurements"
          ? "Measurements"
          : sortColumnName == "txtPolish"
          ? "Polish"
          : sortColumnName == "offRapaport"
          ? "offRapaport"
          : "tmpColumn Asc",
      orderType: sortType ? sortType : "Asc",
      pageNumber: no + "",
      pageSize: size + "",
      invenID: "",
      strDetailLinkURL: "dealerlink",
      did: "",
      caratPriceMin: reset
        ? priceCaratValue1[0].toString()
        : priceCaratValue[0]
        ? priceCaratValue[0].toString()
        : "0",

      caratPriceMax: reset
        ? "22000"
        : priceCaratValue[1]
        ? priceCaratValue[1].toString()
        : "0",
      polishList: reset
        ? "-1"
        : state.PolishFrom == null && state.PolishTo == null
        ? "-1"
        : "",
      symmetryList: reset
        ? "-1"
        : state.SummetryFrom == null && state.SummetryTo == null
        ? "-1"
        : "",
      fluorescenceList: reset
        ? "-1"
        : state.FluorescenceFrom == null && state.FluorescenceTo == null
        ? "-1"
        : "",
      cutGradeList: reset
        ? "-1"
        : state.CutFrom == null && state.CutTo == null
        ? "-1"
        : "",
      colorList: reset
        ? "-1"
        : state.ColorFrom == null && state.ColorTo == null
        ? "-1"
        : "",
      clarityList:
        state.ClarityFrom == null && state.ClarityTo == null ? "-1" : "",
      sOrigin: "",
      intOptimize: 0,
      intCulet1: reset ? 0 : state.CuletFrom ? state.CuletFrom : 0,
      intCulet2: reset ? 20 : state.CuletTo ? state.CuletTo : 20,
      intCuletCondition1: reset
        ? 0
        : state.CuletCondFrom
        ? state.CuletCondFrom
        : 0,
      intCuletCondition2: reset
        ? 20
        : state.CuletCondTo
        ? state.CuletCondTo
        : 20,
      fltCrownAngle1: reset ? 0 : state.CrownAngleMin ? state.CrownAngleMin : 0,
      fltCrownAngle2: reset
        ? 100
        : state.CrownAngleMax
        ? state.CrownAngleMax
        : 100,
      fltPavilionAngle1: reset
        ? 0
        : state.PavilionAngleMin
        ? state.PavilionAngleMin
        : 0,
      fltPavilionAngle2: reset
        ? 100
        : state.PavilionAngleMax
        ? state.PavilionAngleMax
        : 100,
      intGirdle1: reset ? 0 : state.GirdleFrom ? state.GirdleFrom : 0,
      intGirdle2: reset ? 0 : state.GirdleTo ? state.GirdleTo : 0,
      intCutGrade: 0,
      uploadDateMin: "",
      uploadDateMax: "",
      strSelectedDealers:
        state.DealerID == "0"
          ? dealerList
            ? dealerList.map((x) => x.dealerID).join(",")
            : dealerId
          : state.DealerID.length > 0
          ? state.DealerID.map((x) => x).join(",")
          : strSelectedDealers + "",

      selectedDealerConunt:
        state.DealerID.length > 0 ? state.DealerID.length : 1,
      fancyColor: "",
      intIntensity: 0,
      intOvertone: 1,
      strMatchedPairs: state.MatchPairID ? state.MatchPairID.toString() : "0",
      fltWidth1: reset
        ? "-1"
        : state.WidthMin
        ? state.WidthMin.toString()
        : "-1",
      fltWidth2: reset
        ? "-1"
        : state.WidthMax
        ? state.WidthMax.toString()
        : "-1",
      fltHeight1: reset
        ? "-1"
        : state.HeigthMin
        ? state.HeigthMin.toString()
        : "-1",
      fltHeight2: reset
        ? "-1"
        : state.HeigthMax
        ? state.HeigthMax.toString()
        : "-1",
      fltLength1: reset
        ? "-1"
        : state.LengthMin
        ? state.LengthMin.toString()
        : "-1",
      fltLength2: reset
        ? "-1"
        : state.LengthMax
        ? state.LengthMax.toString()
        : "-1",
      strSelectedInvID: "",
      flagDD: "0",
      checkInventory: "Catalog",
      video: reset
        ? ""
        : state.HasVideo === ""
        ? ""
        : state.HasVideo === "true"
        ? "1"
        : "0",
      isComm: 0,
      strDInventoryId: filter ? filter : "",
      IsFirstTime: val,
    };

    setLoading(true);
    try {
      await catalogDiamondService
        .GetDiamonds(inputData, props.type)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          // handleGetDealersForDiamondCatalogList(id);
          if (message === "Success") {
            if (responseData) {
              setDiamondList(responseData);
              setTotalCount(Number(responseData[0].intTotalRecords));
            }
          } else {
            setDiamondList([]);
            setTotalCount(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setDiamondList([]);
          setTotalCount(0);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      setDiamondList([]);
      setTotalCount(0);
    }
  };

  const handleGetDynamicData = (id) => {
    let inputData = {
      dealerId: id.toString(),
    };
    setLoading(true);
    catalogDiamondService
      .GetDynamicData(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          const tempState = state;
          tempState.CaratMax = parseFloat(responseData.sldCaratMax || 0);
          tempState.CaratMin = parseFloat(responseData.sldCaratMin || 0);
          tempState.PriceMax = parseFloat(responseData.sldPriceMax || 0);
          tempState.PriceMin = parseFloat(responseData.sldPriceMin || 0);
          tempState.DepthMax = parseFloat(responseData.sldDepthMax || 0);
          tempState.DepthMin = parseFloat(responseData.sldDepthMin || 0);
          tempState.TableMax = parseFloat(responseData.sldTableMax || 0);
          tempState.TableMin = parseFloat(responseData.sldTableMin || 0);
          setCaratSlideValue([
            tempState.CaratMin.toString(),
            tempState.CaratMax.toString(),
          ]);
          setPriceValue([
            tempState.PriceMin.toString(),
            tempState.PriceMax.toString(),
          ]);
          setCurrencySign(responseData?.currencySign || "$");
          setPriceCaratValue([0, 100000]);
          setCaratSlideValue1([tempState.CaratMin, tempState.CaratMax]);
          setPriceValue1([tempState.PriceMin, tempState.PriceMax]);
          setPriceCaratValue1([
            responseData.sldPriceMin,
            responseData.sldDepthMax,
          ]);
          setDepthMinMax([tempState.DepthMin, tempState.DepthMax]);
          setTableMinMax([tempState.TableMin, tempState.TableMax]);

          setState((prevState) => ({
            ...prevState,
            ...tempState,
          }));
        }
        handleGetDealersForDiamondCatalogList(id, responseData);
      })
      .catch((error) => {
        handleGetDealersForDiamondCatalogList(id, state);
        console.log(error);
      });
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const showModalFullscreen = (row) => {
    setSelectedRowDetails(row);
    setIsModalVisibleFullscreen(true);
  };
  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };
  const handleCancel4 = () => {
    setVisible4(false);
    var tempObj = { Name: "", Email: "", Markup: "", Message: "" };
    setSendDetails(tempObj);
    setSumbitLoding(false);
    var tempErrorObj = {};
    setErrorSendDetails(tempErrorObj);
  };
  const handleOpenIsEmail = () => {
    setIsEmail(true);
  };
  const handleCloseIsEmail = () => {
    setIsEmail(false);
    emailCertificat.Name = "";
    emailCertificat.Email = "";
    emailCertificat.FriendsName = "";
    emailCertificat.FriendsEmail = "";
    emailCertificat.Comments = "";
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
  };
  const handleEmailCertificatChange = (e) => {
    const { name, value } = e.target;
    emailCertificat[name] = value;
    errorEmailCertificat[name] = null;
    setEmailCertificat((prevState) => ({
      ...prevState,
      ...emailCertificat,
    }));
    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...errorEmailCertificat,
    }));
  };
  const handleValidEmailCertificat = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var isValid = true;
    var tempEmailCertificat = emailCertificat;
    var tempErrorEmailCertificat = errorEmailCertificat;
    if (!tempEmailCertificat["Name"]) {
      tempErrorEmailCertificat["Name"] = t("Enter Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["Name"] = null;
    }

    if (!tempEmailCertificat["Email"]) {
      tempErrorEmailCertificat["Email"] = t("Enter Email Address.");
      isValid = false;
    } else if (!tempEmailCertificat["Email"].trim()) {
      tempErrorEmailCertificat["Email"] = t("Enter Email Address.");
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["Email"] = t("Email is not valid.");
      isValid = false;
    }

    if (!tempEmailCertificat["FriendsName"]) {
      tempErrorEmailCertificat["FriendsName"] = t("Enter Friend's Name.");
      isValid = false;
    } else {
      tempErrorEmailCertificat["FriendsName"] = null;
    }

    if (!tempEmailCertificat["FriendsEmail"]) {
      tempErrorEmailCertificat["FriendsEmail"] = t(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (!tempEmailCertificat["FriendsEmail"].trim()) {
      tempErrorEmailCertificat["FriendsEmail"] = t(
        "Enter Friend's Email Address."
      );
      isValid = false;
    } else if (regex.test(tempEmailCertificat["Email"]) === false) {
      tempErrorEmailCertificat["FriendsEmail"] = t("Email is not valid.");
      isValid = false;
    }

    setErrorEmailCertificat((prevState) => ({
      ...prevState,
      ...tempErrorEmailCertificat,
    }));
    return isValid;
  };

  const handleCertificateEmailSend = () => {
    if (handleValidEmailCertificat()) {
      handleSendFriendemail();
    }
  };
  const handleViewDiamond = () => {
    if (selectedRows.length > 0) {
      setDiamondList(selectedRows);
      setTotalCount(selectedRows.length);
      setPageNo(1);
      setShowSelected(true);
    } else {
      NotificationManager.error(t("Please select diamonds from list to view."));
    }
  };
  const handleResetAfterEmailSend = () => {
    setTableResetKey(!tableResetKey);
    setSelectedRows([]);
    setCheckBoxValue([]);
  };

  const handleReset = () => {
    setTableResetKey(!tableResetKey);
    setSelectedRows([]);
    setCheckBoxValue([]);
    setPageNo(1);
    setPageSize(10);
    onResetFilter();
    setSortColumnName("tmpColumn Asc");
    setSortType("Asc");
    setShowSelected(false);
  };

  const handleValidateSendDetails = () => {
    let isValid = true;
    let tempSendDetails = sendDetails;
    let tempErrorSendDetails = errorSendDetails;

    if (!tempSendDetails.Name) {
      tempErrorSendDetails["Name"] = t("Please enter name.");
      isValid = false;
    } else {
      tempErrorSendDetails["Name"] = "";
    }
    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!tempSendDetails.Email || !tempSendDetails.Email.trim()) {
      isValid = false;
      tempErrorSendDetails["Email"] = t("Please enter email.");
    } else if (!tempSendDetails.Email.match(validRegex)) {
      isValid = false;
      tempErrorSendDetails["Email"] = t("Email address is invalid");
    } else {
      tempErrorSendDetails["Email"] = "";
    }

    if (!tempSendDetails.Markup) {
      tempErrorSendDetails["Markup"] = t("Please Enter Markup.");
      isValid = false;
    } else {
      tempErrorSendDetails["Markup"] = "";
    }
    if (!tempSendDetails.Message) {
      tempErrorSendDetails["Message"] = t("Please Enter Message.");
      isValid = false;
    } else {
      tempErrorSendDetails["Message"] = "";
    }
    setErrorSendDetails({ ...tempErrorSendDetails });
    return isValid;
  };

  const handleSendDetailsChange = (e) => {
    const { name, value } = e.target;
    var tempSendDetails = sendDetails;
    var tempErrorSendDetails = errorSendDetails;
    if (name == "Markup") {
      if (!isNaN(value) && value) {
        tempSendDetails[name] = value;
      } else {
        tempSendDetails[name] = 0;
      }
    } else {
      tempSendDetails[name] = value;
    }
    tempErrorSendDetails[name] = "";
    setSendDetails({ ...tempSendDetails });
    setErrorSendDetails({ ...tempErrorSendDetails });
  };
  const handleSendDetailsModal = () => {
    if (handleValidateSendDetails()) {
      handleSendInviteMailForDiamonds();
    }
  };

  // API Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";

    var obj = {};
    var filter = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "tmpColumn Asc";
      setSortColumnName(column);
      setSortType(type);
    }
    if (filters.txtStockNo) {
      obj = {
        columnName: "txtStockNo",
        columnValue: filters.txtStockNo[0],
        columnType: "Input",
      };
      filter = obj.columnValue;
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    if (showSelected) return;
    handleGetDiamondList(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      filter,
      "",
      false,
      state
    );
  };

  const firstsetCaratSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[0] == 0 && Value.includes(".")) {
          var dottedamount = Value.split(".");
          var amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[0] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    } else if (Number(num) == 1) {
      if (e.target.value == "" || e.target.value == ".") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          var dottedamount = Value.split(".");
          var amount =
            Number(dottedamount[0]).toLocaleString("en-US") +
            "." +
            dottedamount[1];
          newArr[1] = amount;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }
      }
    }
  };

  function formatMoney(amount) {
    try {
      if (amount && amount.toString().includes(".")) {
        var dottedamount = amount.split(".");
        var amount =
          Number(dottedamount[0]).toLocaleString("en-IN") +
          "." +
          dottedamount[1];
      } else {
        var amount = Number(amount).toLocaleString("en-IN");
      }
      return amount;
    } catch (e) {
      console.log(e);
    }
  }

  const firstsetPriceSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");
    var Value = StrValue.replace(/,/g, "");

    if (Value.replace(/,/g, "") > state.PriceMax) {
      e.preventDefault();
    } else if (num == 0 || num == "0") {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          newArr[0] = Value;
          setPriceValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }
      }
    }
  };

  const firstsetPriceCaratSlideValue = (e, num) => {
    setFilterBtnVisble(false);
    let newArr = [...priceCaratValue];
    var StrValue = e.target.value && e.target.value.replace(currencySign, "");

    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceCaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceCaratValue(newArr);
      } else {
        if (newArr[0] == 0) {
          newArr[0] = Value;
          setPriceCaratValue(newArr);
        } else {
          newArr[0] = Value;
          setPriceCaratValue(newArr);
        }
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceCaratValue(newArr);
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceCaratValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceCaratValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceCaratValue(newArr);
          }
        }
      }
    }
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  // For Search
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={t("Search..")}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("Search")}
            </Button>
            <Button
              onClick={() => handleResetSearch(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {t("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleResetSearch(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  function handlePrintDiamondList() {
    var disp_setting =
      "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    var docprint = window.open("", "", disp_setting);
    docprint.document.open();
    var htmlContent = document.getElementById("printsection").innerHTML;

    htmlContent +=
      '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    htmlContent +=
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

    docprint.document.write(htmlContent);
    docprint.document.close();

    setTimeout(() => {
      docprint.print();
    }, 500);
    docprint.focus();
  }

  const handleCreateRedirect = () => {
    var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["addSingleDiamond"]));
    navigate("/addSingleDiamond");
  };

  const handleUploadRedirect = () => {
    var MenuData = [`${"Import"}`, `${"ImportDiamond"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["uploadDiamondFile"]));
    navigate("/uploadDiamondFile");
  };

  const handleApplyFilter = () => {
    setHideShowFilter(true);
    handleGetDiamondList(
      dealerId,
      1,
      pageSize,
      sortColumnName,
      sortType,
      filter,
      "",
      true,
      state
    );
    setPageNo(1);
    setTableResetKey(!tableResetKey);
  };

  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });
  const [certInfoDataValidation, setcertInfoDataValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVAl: "",
    commentsVal: "",
  });
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [requestCertModal, setRequestMoreCertModal] = useState(false);

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVAl: "",
      }));
    }
    if (name == "comments") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };
  const [requestInfoDetails, setRequestInfoDetails] = useState({
    intDiamondId: "",
    dealerID: "",
  });
  const handleCertificateOpen = (item) => {
    if (item.certlink == "") {
      showCertInfoModal();
      setRequestInfoDetails({
        intDiamondId: item.intDiamondId,
        dealerID: item.dealerID,
      });
    } else {
      window.open(
        item.certlink,
        "CERTVIEW",
        "scrollbars=yes,resizable=no,width=650,height=630"
      );
    }
  };
  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVAl: "",
      commentsVal: "",
    });
  };
  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
  };

  const handleValidation = () => {
    const { name, email, phoneNumber, comments } = certInfoData;

    const certInfoDataValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVAl: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      certInfoDataValidation.nameVal = t("Name is compulsory");
    } else {
      certInfoDataValidation.nameVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      certInfoDataValidation.emailVal = t("Email is compulsory");
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoDataValidation.emailVal = t("Email is Invalid");
    } else {
      certInfoDataValidation.emailVal = "";
    }
    if (!phoneNumber.trim()) {
      isValid = false;
      certInfoDataValidation.phoneNumberVAl = t("Phone Number is compulsory");
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoDataValidation.phoneNumberVAl = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoDataValidation.phoneNumberVAl = t("Phone number is invalid");
    } else {
      certInfoDataValidation.phoneNumberVAl = "";
    }
    if (!comments.trim()) {
      isValid = false;
      certInfoDataValidation.commentsVal = t("Comments is Compulsory");
    } else {
      certInfoDataValidation.commentsVal = "";
    }

    setcertInfoDataValidation(certInfoDataValidation);
    return isValid;
  };

  const handleCancelCert = () => {
    setRequestMoreCertModal(true);
  };

  const handleSaveRequestCertificate = () => {
    var isValid = handleValidation();
    if (!isValid) {
      handleCancelCert();
      return false;
    }
    let inputData = {
      dealerID: dealerId.toString(),
      did: requestInfoDetails.intDiamondId,
      name: certInfoData.name,
      email: certInfoData.email,
      phone: certInfoData.phoneNumber,
      comments: certInfoData.comments,
      retailerID: requestInfoDetails.dealerID,
    };

    setRequestMoreCertModalLoading(true);
    AppsRingBuilderService.RingBuilderRequestCertificate(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            t(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            )
          );
          setcertInfoData({
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
          });
          setcertInfoDataValidation({
            nameVal: "",
            emailVal: "",
            phoneNumberVAl: "",
            commentsVal: "",
          });
        } else {
          NotificationManager.success(
            t(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            )
          );
          setcertInfoData({
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
          });
          setcertInfoDataValidation({
            nameVal: "",
            emailVal: "",
            phoneNumberVAl: "",
            commentsVal: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setRequestMoreCertModalLoading(false);
        setRequestMoreCertModal(false);
        setcertInfoData({
          name: "",
          email: "",
          phoneNumber: "",
          comments: "",
        });
        setcertInfoDataValidation({
          nameVal: "",
          emailVal: "",
          phoneNumberVAl: "",
          commentsVal: "",
        });
      })
      .finally(() => {
        setRequestMoreCertModalLoading(false);
        setRequestMoreCertModal(false);
      });
  };

  const handleOnOk = () => {
    setVisible6(false);
  };

  const handleSelectChange = (e, row) => {
    let tempArray = [...checkBoxValue];
    let tempSelectedRow = [...selectedRows];

    if (e.target.checked) {
      tempArray.push(row.intDiamondId);
      tempSelectedRow.push(row);
    } else {
      let index = tempArray.indexOf(row.intDiamondId);
      tempSelectedRow = tempSelectedRow.filter(
        (selectedRow) => selectedRow.intDiamondId !== row.intDiamondId
      );
      tempArray.splice(index, 1);
    }

    setCheckBoxValue(tempArray);
    setSelectedRows(tempSelectedRow);
  };

  const handleSelectAllChange = (e) => {
    const currentPageDiamonds = diamondList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    if (e.target.checked) {
      const newIds = currentPageDiamonds
        .filter((diamond) => !checkBoxValue.includes(diamond.intDiamondId))
        .map((diamond) => diamond.intDiamondId);
      setCheckBoxValue([...checkBoxValue, ...newIds]);
      setSelectedRows([
        ...selectedRows,
        ...currentPageDiamonds.filter((diamond) =>
          newIds.includes(diamond.intDiamondId)
        ),
      ]);
    } else {
      const currentPageDiamondIds = currentPageDiamonds.map(
        (diamond) => diamond.intDiamondId
      );
      setCheckBoxValue(
        checkBoxValue.filter((id) => !currentPageDiamondIds.includes(id))
      );
      setSelectedRows(
        selectedRows.filter(
          (row) => !currentPageDiamondIds.includes(row.intDiamondId)
        )
      );
    }
    setIsSelectAll(e.target.checked);
  };

  return (
    <React.Fragment>
      {vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <>
          <div className="vdiamond_section magento__mapping">
            <div className="form__fields">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="heading__block">
                          <h4 className="workarea__heading mt-0 mb-0">
                            {t("View My")} {props.type ? t("Lab") : t("Mined")}{" "}
                            {t("Diamond")}
                          </h4>

                          <button
                            className="primary-btn"
                            onClick={handleHideShowFilter}
                          >
                            <img src={Filter_icon} alt="" width="14px" />
                            {hideShowFilter ? (
                              <span>
                                {t("Show Filter")}{" "}
                                <i className="fa fa-angle-down"></i>
                              </span>
                            ) : (
                              <span>
                                {t("Hide Filter")}{" "}
                                <i className="fa fa-angle-up"></i>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className="filter_section"
                      style={{ position: "none" }}
                    >
                      <div
                        className={
                          hideShowFilter
                            ? "form__fields filter_inpsection hide"
                            : "form__fields filter_inpsection"
                        }
                        style={{ position: "unset", marginBottom: "1rem" }}
                      >
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="input__block">
                                <label> {t("Shapes")} </label>
                                <div className="imgselct__div">
                                  <div
                                    className={
                                      state.ShapeIsSelected.Round
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Round")}
                                  >
                                    <img src={Roundimg} alt="" />
                                    <span> {t("Round")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Radiant
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Radiant")}
                                  >
                                    <img src={RadiantImg} alt="" />
                                    <span> {t("Radiant")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Princess
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Princess")}
                                  >
                                    <img src={PrincessImg} alt="" />
                                    <span> {t("Princess")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Pear
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Pear")}
                                  >
                                    <img src={PearImg} alt="" />
                                    <span> {t("Pear")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Oval
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Oval")}
                                  >
                                    <img src={OvalImg} alt="" />
                                    <span> {t("Oval")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Marquise
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Marquise")}
                                  >
                                    <img src={MarquiseImg} alt="" />
                                    <span> {t("Marquise")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Heart
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Heart")}
                                  >
                                    <img src={HeartImg} alt="" />
                                    <span> {t("Heart")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Emerald
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Emerald")}
                                  >
                                    <img src={EmeraldImg} alt="" />
                                    <span> {t("Emerald")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Cushion
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Cushion")}
                                  >
                                    <img src={CushionImg} alt="" />
                                    <span> {t("Cushion")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.Asscher
                                        ? "selected"
                                        : null
                                    }
                                    onClick={() => onShapeClick("Asscher")}
                                  >
                                    <img src={AsscherImg} alt="" />
                                    <span> {t("Asscher")} </span>
                                  </div>
                                  <div
                                    className={
                                      state.ShapeIsSelected.All
                                        ? "selected all__div"
                                        : "all__div"
                                    }
                                    onClick={() => onShapeClick("All")}
                                  >
                                    <span> {t("All")} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Dealers")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  mode="multiple"
                                  showArrow
                                  placeholder={t("Select Dealer")}
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeMultiDrop(e, "DealerID")
                                  }
                                  value={state.DealerID}
                                >
                                  <Option value="0">{t("Search all")}</Option>
                                  {dealerList?.map((item, i) => {
                                    return (
                                      <Option value={item.dealerID}>
                                        {item.dealerCompany}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {t("Certificates")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  mode="multiple"
                                  showArrow
                                  placeholder={t("Select Certificate")}
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeMultiDrop(e, "CertificateID")
                                  }
                                  value={state.CertificateID}
                                >
                                  {certificateList.map((item, i) => {
                                    return (
                                      <Option value={item.certificateValue}>
                                        {item.certificateValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Cut")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "CutFrom")
                                      }
                                      value={state.CutFrom}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {cutList.map((item, i) => {
                                        return (
                                          <Option value={item.cutGradeId}>
                                            {item.cutGradeValue}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) => onChangeDrop(e, "CutTo")}
                                      value={state.CutTo}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {cutList.map((item, i) => {
                                        return (
                                          <Option value={item.cutGradeId}>
                                            {item.cutGradeValue}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label> {t("Carat")} </label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={caratSlideValue}
                                    min={state.CaratMin}
                                    max={state.CaratMax}
                                    step={0.01}
                                    onChange={(e) => {
                                      setCaratSlideValue(e);
                                      {
                                        btnValue == 1
                                          ? setFilterBtnVisble(false)
                                          : setFilterBtnVisble(true);
                                      }
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={caratSlideValue[0].toLocaleString(
                                          "en-US"
                                        )}
                                        onChange={(e) => {
                                          firstsetCaratSlideValue(e, 0);
                                          // handleSliderCaratValue(e);
                                          // setCaratSlideValue(e);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                      {/* <span className="dollar__symbol">$</span> */}
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={caratSlideValue[1].toLocaleString(
                                          "en-US"
                                        )}
                                        // value={formatMoney(caratSlideValue[1])}
                                        onChange={(e) => {
                                          firstsetCaratSlideValue(e, 1);
                                          // handleSliderCaratValue(e);
                                          // setCaratSlideValue(e);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                      {/* <span className="dollar__symbol">$</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label> {t("Price")} </label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={priceValue}
                                    min={state.PriceMin}
                                    max={state.PriceMax}
                                    onChange={(e) => {
                                      setPriceValue(e);

                                      {
                                        btnValue == 1
                                          ? setFilterBtnVisble(false)
                                          : setFilterBtnVisble(true);
                                      }
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        // value={
                                        //   currencySign +
                                        //   formatMoney(priceValue[0])
                                        // }
                                        value={
                                          currencySign +
                                          priceValue[0]
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        }
                                        onChange={(e) => {
                                          firstsetPriceSlideValue(e, 0);
                                          // handleSliderCaratValue(e);
                                          // setCaratSlideValue(e);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        // value={
                                        //   currencySign +
                                        //   formatMoney(priceValue[1])
                                        // }
                                        value={
                                          currencySign +
                                          priceValue[1]
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        }
                                        onChange={(e) => {
                                          firstsetPriceSlideValue(e, 1);
                                          // handleSliderCaratValue(e);
                                          // setCaratSlideValue(e);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="sliderrange__maindiv">
                                <label>
                                  {t("Price")} / {t("Carat")}{" "}
                                </label>
                                <div className="col-md-12 mt-3">
                                  <Slider
                                    range
                                    value={priceCaratValue}
                                    min={state.PriceCaratMin}
                                    max={state.PriceCaratMax}
                                    onChange={(e) => {
                                      setPriceCaratValue(e);

                                      {
                                        btnValue == 1
                                          ? setFilterBtnVisble(false)
                                          : setFilterBtnVisble(true);
                                      }
                                    }}
                                  />
                                  <div className="rangeSlider__values">
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(priceCaratValue[0])
                                        }
                                        onChange={(e) => {
                                          firstsetPriceCaratSlideValue(e, 0);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={space__icon}
                                        className="img-fluid space__icon"
                                      />
                                    </div>
                                    <div className="input__block">
                                      <input
                                        type="text"
                                        value={
                                          currencySign +
                                          formatMoney(priceCaratValue[1])
                                        }
                                        onChange={(e) => {
                                          firstsetPriceCaratSlideValue(e, 1);
                                        }}
                                        onKeyPress={onKeyPressEvent1}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Clarity")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "ClarityFrom")
                                      }
                                      value={state.ClarityFrom}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {clarityList.map((item, i) => {
                                        return (
                                          <Option value={item.clarityID}>
                                            {item.claritydetails}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "ClarityTo")
                                      }
                                      value={state.ClarityTo}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {clarityList.map((item, i) => {
                                        return (
                                          <Option value={item.clarityID}>
                                            {item.claritydetails}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Color")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "ColorFrom")
                                      }
                                      value={state.ColorFrom}
                                    >
                                      {colorList.map((item, i) => {
                                        return (
                                          <Option value={item.colorID}>
                                            {item.colorName}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "ColorTo")
                                      }
                                      value={state.ColorTo}
                                    >
                                      {colorList.map((item, i) => {
                                        return (
                                          <Option value={item.colorID}>
                                            {item.colorName}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Depth")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <input
                                      type="number"
                                      value={state.DepthMin}
                                      onChange={(e) => onChange(e, "DepthMin")}
                                      className={
                                        validation.depthMinVal && "border__red"
                                      }
                                    />
                                    <span className="ampcent__txt">%</span>
                                    {validation.depthMinVal && (
                                      <span className="error-color-red">
                                        {validation.depthMinVal}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <input
                                      type="number"
                                      value={state.DepthMax}
                                      onChange={(e) => onChange(e, "DepthMax")}
                                      className={
                                        validation.depthMaxVal && "border__red"
                                      }
                                    />
                                    <span className="ampcent__txt">%</span>
                                    {validation.depthMaxVal && (
                                      <span className="error-color-red">
                                        {validation.depthMaxVal}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Table")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <input
                                      type="number"
                                      value={state.TableMin}
                                      onChange={(e) => onChange(e, "TableMin")}
                                      className={
                                        validation.tableMinVal && "border__red"
                                      }
                                    />
                                    <span className="ampcent__txt">%</span>
                                    {validation.tableMinVal && (
                                      <span className="error-color-red">
                                        {validation.tableMinVal}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <input
                                      type="number"
                                      value={state.TableMax}
                                      onChange={(e) => onChange(e, "TableMax")}
                                      className={
                                        validation.tableMaxVal && "border__red"
                                      }
                                    />
                                    <span className="ampcent__txt">%</span>
                                    {validation.tableMaxVal && (
                                      <span className="error-color-red">
                                        {validation.tableMaxVal}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label className="lbl__txt">
                                    {" "}
                                    {t("Polish")}{" "}
                                  </label>
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "PolishFrom")
                                      }
                                      value={state.PolishFrom}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {polishList.map((item, i) => {
                                        return (
                                          <Option value={item.polishID}>
                                            {item.polishValue}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-lg-2 text-center">
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="col-lg-5">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder={t("Any")}
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChangeDrop(e, "PolishTo")
                                      }
                                      value={state.PolishTo}
                                    >
                                      <Option> {t("Any")} </Option>
                                      {polishList.map((item, i) => {
                                        return (
                                          <Option value={item.polishID}>
                                            {item.polishValue}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showMoreLess == false ? null : (
                          <div className="col-md-12 mt-2">
                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Length(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "LengthMin")
                                        }
                                        value={state.LengthMin}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "LengthMax")
                                        }
                                        value={state.LengthMax}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Width(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "WidthMin")
                                        }
                                        value={state.WidthMin}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "WidthMax")
                                        }
                                        value={state.WidthMax}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Height(mm)")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "HeigthMin")
                                        }
                                        value={state.HeigthMin}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "HeigthMax")
                                        }
                                        value={state.HeigthMax}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {Array(15)
                                          .fill(1)
                                          .map((item, i) => {
                                            return (
                                              <Option value={i + 1}>
                                                {i + 1}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Symmetry")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "SummetryFrom")
                                        }
                                        value={state.SummetryFrom}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {summetryList.map((item, i) => {
                                          return (
                                            <Option value={item.symmetryID}>
                                              {item.symmetryValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "SummetryTo")
                                        }
                                        value={state.SummetryTo}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {summetryList.map((item, i) => {
                                          return (
                                            <Option value={item.symmetryID}>
                                              {item.symmetryValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Girdle")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "GirdleFrom")
                                        }
                                        value={state.GirdleFrom}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {girdleList.map((item, i) => {
                                          return (
                                            <Option
                                              value={item.diamondGirdleID}
                                            >
                                              {item.girdleValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "GirdleTo")
                                        }
                                        value={state.GirdleTo}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {girdleList.map((item, i) => {
                                          return (
                                            <Option
                                              value={item.diamondGirdleID}
                                            >
                                              {item.girdleValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Crown Angle")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        value={state.CrownAngleMin}
                                        onChange={(e) =>
                                          onChange(e, "CrownAngleMin")
                                        }
                                        className={
                                          validation.CrownAngleMinVal &&
                                          "border__red"
                                        }
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.CrownAngleMinVal && (
                                        <span className="error-color-red">
                                          {validation.CrownAngleMinVal}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        value={state.CrownAngleMax}
                                        onChange={(e) =>
                                          onChange(e, "CrownAngleMax")
                                        }
                                        className={
                                          validation.CrownAngleMaxVal &&
                                          "border__red"
                                        }
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.CrownAngleMaxVal && (
                                        <span className="error-color-red">
                                          {validation.CrownAngleMaxVal}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Pavilion Angle")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        value={state.PavilionAngleMin}
                                        onChange={(e) =>
                                          onChange(e, "PavilionAngleMin")
                                        }
                                        className={
                                          validation.PavilionAngleMinVal &&
                                          "border__red"
                                        }
                                      />

                                      <span className="ampcent__txt">%</span>
                                      {validation.PavilionAngleMinVal && (
                                        <span className="error-color-red">
                                          {validation.PavilionAngleMinVal}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <input
                                        type="number"
                                        value={state.PavilionAngleMax}
                                        onChange={(e) =>
                                          onChange(e, "PavilionAngleMax")
                                        }
                                        className={
                                          validation.PavilionAngleMaxVal &&
                                          "border__red"
                                        }
                                      />
                                      <span className="ampcent__txt">%</span>
                                      {validation.PavilionAngleMaxVal && (
                                        <span className="error-color-red">
                                          {validation.PavilionAngleMaxVal}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Culet")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "CuletFrom")
                                        }
                                        value={state.CuletFrom}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {culetList &&
                                          culetList.map((item, i) => {
                                            return (
                                              <Option
                                                value={item.diamondCuletID}
                                              >
                                                {item.culetValue}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "CuletTo")
                                        }
                                        value={state.CuletTo}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {culetList &&
                                          culetList.map((item, i) => {
                                            return (
                                              <Option
                                                value={item.diamondCuletID}
                                              >
                                                {item.culetValue}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Culet Condition")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "CuletCondFrom")
                                        }
                                        value={state.CuletCondFrom}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {culetCondList.map((item, i) => {
                                          return (
                                            <Option
                                              value={
                                                item.diamondCuletConditionID
                                              }
                                            >
                                              {item.culetConditionValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "CuletCondTo")
                                        }
                                        value={state.CuletCondTo}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {culetCondList.map((item, i) => {
                                          return (
                                            <Option
                                              value={
                                                item.diamondCuletConditionID
                                              }
                                            >
                                              {item.culetConditionValue}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <label className="lbl__txt">
                                      {" "}
                                      {t("Fluorescence")}{" "}
                                    </label>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "FluorescenceFrom")
                                        }
                                        value={state.FluorescenceFrom}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {fluorescenceList.map((item, i) => {
                                          return (
                                            <Option value={item.fluorescenceID}>
                                              {item.fluorescenceName}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 text-center">
                                    <img
                                      src={space__icon}
                                      className="img-fluid space__icon"
                                    />
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="input__block">
                                      <Select
                                        className="border__grey"
                                        showSearch
                                        placeholder={t("Any")}
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeDrop(e, "FluorescenceTo")
                                        }
                                        value={state.FluorescenceTo}
                                      >
                                        <Option> {t("Any")} </Option>
                                        {fluorescenceList.map((item, i) => {
                                          return (
                                            <Option value={item.fluorescenceID}>
                                              {item.fluorescenceName}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Matched Pairs")} </label>
                                  <Select
                                    className="border__grey"
                                    showSearch
                                    placeholder={t("Search All Stones")}
                                    optionFilterProp="children"
                                    onChange={(e) =>
                                      onChangeDrop(e, "MatchPairID")
                                    }
                                    value={state.MatchPairID}
                                  >
                                    {matchedPairList.map((item, i) => {
                                      return (
                                        <Option value={item.matchedPairID}>
                                          {item.matchedPairName}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="input__block">
                                  <label> {t("Has Video")} </label>
                                  <div className="radio__block row">
                                    <div className="radio__btn w-20 d-flex">
                                      <input
                                        type="radio"
                                        value={state.HasVideo}
                                        name="true"
                                        checked={
                                          state.HasVideo === "true"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadioHasVideo}
                                      />

                                      <label>{t("Yes")}</label>
                                    </div>
                                    <div className="radio__btn w-20 d-flex">
                                      <input
                                        type="radio"
                                        name="false"
                                        value={state.HasVideo}
                                        checked={
                                          state.HasVideo === "false"
                                            ? true
                                            : false
                                        }
                                        onChange={handleOnChangeRadioHasVideo}
                                      />

                                      <label>{t("No")}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12 filtbtn_div">
                              <Button
                                className="primary-btn mr-2"
                                onClick={onResetFilter}
                              >
                                {" "}
                                {t("Reset Filters")}
                              </Button>
                              <Button
                                className="primary-btn mr-2"
                                onClick={() => setShowMoreLess(!showMoreLess)}
                              >
                                {showMoreLess == false
                                  ? t("Show More Filters")
                                  : t("Show Less Filters")}
                              </Button>
                              <Button
                                className={
                                  filterBtnVisble == true
                                    ? "disabled-btn"
                                    : "primary-btn"
                                }
                                disabled={filterBtnVisble}
                                onClick={() => {
                                  handleApplyFilter();
                                }}
                                loading={loading}
                              >
                                {t("Apply Filters")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="options__div">
                      <div className="row">
                        <div className="col-lg-6 add__div">
                          <span className="diamnd__txt">
                            {t("You Have")}{" "}
                            <span className="txt__no">{totalCount}</span>{" "}
                            {t("Diamonds")}
                          </span>
                          <button
                            className="primary-btn"
                            onClick={() => handleCreateRedirect()}
                          >
                            <i className="fa fa-plus-circle"></i> {t("Add")}
                          </button>
                          <button
                            className="primary-btn"
                            onClick={() => handleUploadRedirect()}
                          >
                            <i className="fa fa-cloud-upload"></i> {t("Upload")}
                          </button>
                          <button
                            className="primary-btn"
                            onClick={() => handleReset()}
                          >
                            <i className="fa fa-rotate-left"></i> {t("Reset")}
                          </button>
                        </div>
                        <div className="col-lg-6 print__div">
                          <span className="select__txt">
                            <i className="fa fa-list-ul"></i>{" "}
                            <span className="no__txt">
                              {isSelectAll ? "All" : selectedRows.length}
                            </span>{" "}
                            {t("Selected")}
                          </span>
                          <Button
                            className="primary-btn"
                            onClick={() => handleViewDiamond()}
                            disabled={isSelectAll}
                          >
                            <i className="fa fa-eye"></i> {t("View")}
                          </Button>

                          <Button
                            className="primary-btn"
                            onClick={() => {
                              handlePrintDiamondList();
                            }}
                          >
                            <i className="fa fa-print"></i> {t("Print")}
                          </Button>

                          <Button
                            className="primary-btn"
                            onClick={handleSendModal}
                          >
                            <i className="fa fa-envelope"></i> {t("Send")}
                          </Button>
                          <Button
                            className="primary-btn"
                            onClick={handleDeleteModal}
                            disabled={isSelectAll}
                          >
                            <i className="fa fa-trash-o"></i> {t("Delete")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {/*there is a search filter in react for table*/}
                    <Table
                      key={tableResetKey}
                      className="vdiamond__table table-width mobile_tbl"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      bordered={bordered}
                      columns={[
                        {
                          title: (
                            <Checkbox
                              checked={isSelectAll}
                              onChange={handleSelectAllChange}
                            />
                          ),
                          dataIndex: "view",
                          width: window.innerWidth > 768 ? 30 : 8,
                          render: (item, row) => {
                            return (
                              <React.Fragment>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={checkBoxValue.includes(
                                      row.intDiamondId
                                    )}
                                    onChange={(e) => handleSelectChange(e, row)}
                                  />
                                  <div
                                    className="infoicon__div"
                                    onClick={() => handleOpenDetailsModal(row)}
                                  >
                                    <i className="fa fa-info-circle"></i>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          },
                        },
                        {
                          title: t("Stock #"),
                          dataIndex: "txtStockNo",
                          width: 55,
                          ...getColumnSearchProps("txtStockNo"),
                          key: "txtStockNo",
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          ellipsis: true,
                        },
                        {
                          title: t("Shape"),
                          dataIndex: "txtShape",
                          width: window.innerWidth > 768 ? 50 : 12,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          ellipsis: true,
                          render: (item, row) => {
                            return (
                              <div className="shape__img">
                                <div className="shape__icon">
                                  <img src={row.imageSmallShape} />
                                </div>
                                <span>
                                  {window.innerWidth > 768 && (
                                    <span>{row.txtShape}</span>
                                  )}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Size"),
                          dataIndex: "fltCarat",
                          width: window.innerWidth > 768 ? 40 : 8,
                          showSorterTooltip: false,
                          // responsive:['lg'],
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Cut"),
                          dataIndex: "txtCulet",
                          // width: 30,
                          width: window.innerWidth > 768 ? 30 : 8,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return (
                              <>
                                {row.txtCulet ? (
                                  <span>{row.txtCulet.toUpperCase()}</span>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: t("Color"),
                          dataIndex: "txtColor",
                          // width: 50,
                          width: window.innerWidth > 768 ? 50 : 10,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          ellipsis: true,
                        },
                        {
                          title: t("Clarity"),
                          dataIndex: "txtClarity",
                          width: window.innerWidth > 768 ? 50 : 8,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Off %"),
                          dataIndex: "fltRapPercent",
                          width: 50,
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                          responsive: ["lg"],
                          render: (item, row) => {
                            return (
                              <>
                                {row.fltRapPercent ? (
                                  <span>
                                    {row.fltRapPercent.includes("-")
                                      ? row.fltRapPercent
                                      : "-" + row.fltRapPercent}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: t("Cost"),
                          dataIndex: "fltPrice",
                          // width: 50,
                          width: window.innerWidth > 768 ? 60 : 16,
                          sorter: (a, b) => {},
                          showSorterTooltip: false,
                          render: (item, row) => {
                            return (
                              <>
                                {row?.currencySign || "$"}{" "}
                                {row.fltPrice
                                  ? parseFloat(row.fltPrice).toFixed(2)
                                  : ""}
                              </>
                            );
                          },
                        },
                        {
                          title: t("Fluor."),
                          dataIndex: "txtFluorescence",
                          width: 40,
                          sorter: (a, b) => {},
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          render: (item, row) => {
                            return row.txtFluorescence;
                          },
                        },
                        {
                          title: t("Dep."),
                          dataIndex: "fltDepth",
                          width: 50,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return row.fltDepth + "%";
                          },
                        },
                        {
                          title: t("Table"),
                          dataIndex: "fltTable",
                          width: 40,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (item, row) => {
                            return row.fltTable + "%";
                          },
                        },
                        // {
                        //   title: t("Measure."),
                        //   dataIndex: "txtMeasurements",
                        //   width: 160,
                        //   showSorterTooltip: false,
                        //   sorter: (a, b) =>
                        //     a.txtMeasurements.length - b.txtMeasurements.length,
                        //   render: (item, row) => {
                        //     return row.txtMeasurements;
                        //   },
                        // },
                        {
                          title: t("Cert."),
                          dataIndex: "txtCertificate",
                          // width: 50,
                          width: window.innerWidth > 768 ? 50 : 12,
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (row, item) => {
                            return (
                              <div className="infoicon__div">
                                <span
                                  className="linkText"
                                  onClick={() => handleCertificateOpen(item)}
                                >
                                  {item.txtCertificate}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Polish"),
                          dataIndex: "txtPolish",
                          width: 40,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Sym."),
                          dataIndex: "txtSymmetry",
                          width: 40,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                        },
                        {
                          title: t("Dealer"),
                          dataIndex: "dealer",
                          width: 40,
                          responsive: ["lg"],
                          showSorterTooltip: false,
                          sorter: (a, b) => {},
                          render: (row, item) => {
                            return (
                              <div className="infoicon__div">
                                <span onClick={() => handleOk1(item)}>
                                  <i
                                    className="fa fa-comments"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {item.dealerID}{" "}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Video"),
                          width: 30,
                          responsive: ["lg"],
                          ellipsis: true,
                          render: (item, row) => {
                            return row.videoFileName ? (
                              <div className="infoicon__div">
                                <i
                                  onClick={() => {
                                    handleOpenVideoImageModal(row, true);
                                  }}
                                  className="fa fa-video-camera"
                                  style={{
                                    color: "#0078d7",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                            ) : null;
                          },
                        },
                      ]}
                      loading={{ spinning: loading, size: "large" }}
                      onChange={handleTableASCDES}
                      dataSource={diamondList}
                      // rowSelection={rowSelection}
                      pagination={{
                        total: totalCount,
                        pageNo: pageNo,
                        current: pageNo,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        responsive: true,
                        showSizeChanger: true,
                      }}
                      scroll={{ x: window.innerWidth > 768 ? 1000 : 0 }}
                      rowKey={(record) => record.intDiamondId}
                    />
                  </div>
                  <div className="col-lg-12">
                    {/* Modal Request Certifiacate  */}

                    <Modal
                      title={t("Request Diamond Certificate")}
                      visible={requestCertModal}
                      style={{ top: 70 }}
                      className="request_info"
                      onCancel={closeCertInfoModal}
                      footer={[
                        <Button key="back" onClick={closeCertInfoModal}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          loading={requestCertModalLoading}
                          type="primary"
                          onClick={handleSubmitRequestCert}
                        >
                          {t("Request")}
                        </Button>,
                      ]}
                    >
                      <div className="row">
                        <div class="col-md-12">
                          <p className="mb-1">
                            {t(
                              "The report for this diamond is not viewable online."
                            )}
                          </p>
                          <p>
                            {t(
                              "Please contact us by telephone or the form below if you are interested in receiving more information about this diamond."
                            )}
                          </p>
                          <div className="table__block">
                            <div className="row p-0 border-0 form__fields mh-auto">
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("Name")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.name}
                                    className={
                                      certInfoDataValidation.nameVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(e, "name", "input")
                                    }
                                  />
                                  {certInfoDataValidation.nameVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.nameVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("E-Mail Address")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.email}
                                    className={
                                      certInfoDataValidation.emailVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(e, "email", "input")
                                    }
                                  />
                                  {certInfoDataValidation.emailVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.emailVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input__block">
                                  <label>
                                    {t("Phone Number")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={certInfoData.phoneNumber}
                                    className={
                                      certInfoDataValidation.phoneNumberVAl &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(
                                        e,
                                        "phoneNumber",
                                        "input"
                                      )
                                    }
                                  />
                                  {certInfoDataValidation.phoneNumberVAl && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.phoneNumberVAl}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="input__block">
                                  <label>
                                    {t("Comments")}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <textarea
                                    value={certInfoData.comments}
                                    className={
                                      certInfoDataValidation.commentsVal &&
                                      "border__red"
                                    }
                                    onChange={(e) =>
                                      handleCertInfoChange(
                                        e,
                                        "comments",
                                        "input"
                                      )
                                    }
                                  ></textarea>
                                  {certInfoDataValidation.commentsVal && (
                                    <p className="error-color-red">
                                      {certInfoDataValidation.commentsVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* End */}

                    {/* View DiamondImage Modal */}

                    <Modal
                      title=""
                      visible={isModalVisibleFullscreen}
                      className="fullscreen__modal"
                      onCancel={handleCancelFullscreen}
                      footer={false}
                      width={500}
                      //style={{ top: 30 }}
                    >
                      <div className="thimage__slider text-center">
                        <img
                          src={
                            selectedRowDetails.imageBigShape
                              ? selectedRowDetails.imageBigShape
                              : ""
                          }
                          alt=""
                          className="img-fluid"
                          height={"400px"}
                          width="300"
                        />
                      </div>
                    </Modal>
                    {/* End */}

                    {/* View Diamond Modal */}
                    <Modal
                      title={t("View Details")}
                      className="modalvdiamond__section"
                      centered
                      visible={visible}
                      onOk={() => setVisible(false)}
                      onCancel={() => setVisible(false)}
                      width={1000}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => handleOk1(selectedRowDetails)}
                        >
                          {t("Contact Dealer")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-2 mt-4 text-center">
                                  <div className="viewdtls__left">
                                    <div>
                                      <img
                                        src={selectedRowDetails.imageBigShape}
                                        className="img-fluid"
                                        alt="img-diamond"
                                      />
                                    </div>
                                    <div>
                                      {selectedRowDetails.videoFileName ? (
                                        <i
                                          onClick={() => {
                                            handleOpenVideoImageModal(
                                              selectedRowDetails,
                                              true
                                            );
                                          }}
                                          className="fa fa-video-camera mt-2 linkText"
                                        ></i>
                                      ) : null}

                                      {selectedRowDetails.imageBigShape ? (
                                        <i
                                          class="fa fa-arrows-alt mt-6 linkText ml-2"
                                          aria-hidden="true"
                                          onClick={() => {
                                            showModalFullscreen(
                                              selectedRowDetails
                                            );
                                          }}
                                        ></i>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-10 mt-4">
                                  <div className="info__div">
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("GemFind")}#:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.intDiamondId}
                                        </span>
                                      </p>
                                      {dealerMemberTypeName !== "Vendor" &&
                                        dealerMemberTypeName !==
                                          "VendorDiamond" && (
                                          <p>
                                            <span className="head__txt">
                                              {t("Dealer Price")}
                                            </span>
                                            <span className="val__txt">
                                              {/* {selectedRowDetails.currencySign} */}
                                              {"$"}
                                              {selectedRowDetails.realPrice}
                                            </span>
                                          </p>
                                        )}
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Color")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtColor}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Depth")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.fltDepth + "%"}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Carat Weight")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.fltCarat}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Symmetry")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtSymmetry}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Measurements")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtMeasurements}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Treatment")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.treatment}
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Shape")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtShape}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Clarity")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtClarity}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Table")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.fltTable + "%"}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Polish")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtPolish}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Crown")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.crown}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Lab")}:
                                        </span>{" "}
                                        <span
                                          className="val__txt linkText"
                                          onClick={() =>
                                            handleCertificateOpen(
                                              selectedRowDetails
                                            )
                                          }
                                        >
                                          {selectedRowDetails.txtCertificate}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Matched Pair Stock")}#:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.pairDiamondID}
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Seller")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.dealerCompany}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Stock")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.txtStockNo}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Phone")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.dealerPhone}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Location")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.dealerLocation}
                                        </span>
                                      </p>
                                      <p>
                                        <span className="head__txt">
                                          {" "}
                                          {t("Updated")}:
                                        </span>{" "}
                                        <span className="val__txt">
                                          {selectedRowDetails.modified}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Message Send Modal */}

                    <Modal
                      title={
                        <React.Fragment>
                          <h4 className="d-flex mb-0">
                            {t("Message to")}
                            <h4 className="linkText mx-2 mb-0">
                              {selectedDealer ? selectedDealer : ""}
                            </h4>
                          </h4>
                        </React.Fragment>
                      }
                      className="modalvdiamond__section"
                      centered
                      visible={visible1}
                      onOk={handleCancel1}
                      onCancel={handleCancel1}
                      width={600}
                      footer={[
                        <Button key="back" onClick={handleCancel1}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleMsgValidation}
                          loading={msgLoading}
                        >
                          {t("Send")}
                        </Button>,
                      ]}
                    >
                      <Spin spinning={companyLogoLoading}>
                        <div className="col-lg-12">
                          <div className="modal__body">
                            <div className="col-lg-12">
                              <div className="form__fields border-0 p-0">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <p>
                                      {t("Contact the dealer regarding")}.{" "}
                                      <span className="linkText">
                                        {selectedRowDetails.txtStockNo} .
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-lg-12">
                                    <div class="input__block">
                                      <textarea
                                        id=""
                                        name=""
                                        rows="6"
                                        cols="50"
                                        placeholder={t(
                                          "Write your message here..."
                                        )}
                                        class=""
                                        value={msgData}
                                        onChange={(e) => {
                                          setMsgData(e.target.value);
                                          setMsgError("");
                                        }}
                                      ></textarea>

                                      {msgError ? (
                                        <div className="text-danger">
                                          {msgError}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </Modal>

                    {/* Delete Conformation Modal */}
                    <Modal
                      title={t("Delete")}
                      className="modalconsupld__section"
                      centered
                      visible={visible3}
                      onOk={() => handleDeleteConfirmation()}
                      onCancel={() => setVisible3(false)}
                      width={400}
                      footer={[
                        <Button key="back" onClick={handleCancel3}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          loading={sumbitLoding}
                          key="submit"
                          type="primary"
                          onClick={() => {
                            handleDeleteConfirmation();
                          }}
                        >
                          {t("Delete")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <p>
                                    {" "}
                                    {t(
                                      "Are you sure to delete this diamond"
                                    )}{" "}
                                  </p>
                                  <h5> {t("THIS CAN NOT BE UNDONE")} </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* After Delete Modal PopUp Message */}

                    <Modal
                      title={" "}
                      className="modalconsupld__section"
                      centered
                      visible={visible6}
                      width={400}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => handleOnOk()}
                        >
                          {t("OK")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <p>
                                    {t(
                                      "Your diamonds are deleted.However it will take some moments to remove them from cache.Deleted diamonds will be removed from this page as soon cache gets refreshed."
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* For No dealer Delete */}

                    <Modal
                      title={t("Not Authorized")}
                      className="modalconsupld__section"
                      centered
                      visible={visible5}
                      onCancel={() => setVisible5(false)}
                      width={400}
                      footer={[
                        <Button
                          type="primary"
                          key="back"
                          onClick={handleCancel5}
                        >
                          {t("Ok")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-lg-12 text-center">
                                  <h5>
                                    #
                                    {selectedRows
                                      .map((x) => x.intDiamondId)
                                      .join(",")}{" "}
                                    {t(
                                      "Not deleted as those are not your diamonds."
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Send Modal */}
                    <Modal
                      className="modalconsupld__section"
                      centered
                      visible={visible4}
                      onOk={() => setVisible4(false)}
                      onCancel={handleCancel4}
                      width={450}
                      footer={[
                        <Button key="back" onClick={handleCancel4}>
                          {t("Cancel")}
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={sumbitLoding}
                          onClick={() => handleSendDetailsModal()}
                        >
                          {t("Send")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 className="d-flex mb-0">
                                    {t("Send")}
                                    <h4 className="linkText mx-2 mb-0">
                                      {isSelectAll
                                        ? "All"
                                        : selectedRows.length}
                                    </h4>
                                    {t("Diamonds")}
                                  </h4>
                                  <p>
                                    {t(
                                      "Your recipient will recieve the details on your selected diamonds"
                                    )}
                                  </p>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Name")} </label>
                                    <input
                                      type="text"
                                      placeholder={t("Enter Name")}
                                      name="Name"
                                      value={sendDetails.Name || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    />
                                    {errorSendDetails.Name ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Name"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Email")} </label>
                                    <input
                                      type="email"
                                      placeholder={t("Enter Email")}
                                      name="Email"
                                      value={sendDetails.Email || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    />
                                    {errorSendDetails.Email ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Email"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Markup")} </label>
                                    <div className="row">
                                      <div className="col-12">
                                        <input
                                          type="text"
                                          placeholder={t("Enter Markup")}
                                          name="Markup"
                                          value={sendDetails.Markup || ""}
                                          onChange={(e) =>
                                            handleSendDetailsChange(e)
                                          }
                                        />
                                        <span className="markup__symbol">
                                          %
                                        </span>
                                        {errorSendDetails.Markup ? (
                                          <div className="text-danger">
                                            {errorSendDetails["Markup"]}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block">
                                    <label> {t("Message")} </label>
                                    <textarea
                                      placeholder={t("Enter Message")}
                                      name="Message"
                                      value={sendDetails.Message || ""}
                                      onChange={(e) =>
                                        handleSendDetailsChange(e)
                                      }
                                    ></textarea>
                                    {errorSendDetails.Message ? (
                                      <div className="text-danger">
                                        {errorSendDetails["Message"]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Video and Image View Modal */}
                    <Modal
                      className="modalconsupld__section"
                      centered
                      title={t("Video")}
                      width={500}
                      visible={videoImageModal}
                      onCancel={() => handleCloseVideoImageModal()}
                      footer={[
                        <Button onClick={() => handleCloseVideoImageModal()}>
                          {t("Cancel")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              <div className="row">
                                {isVideo ? (
                                  selectedRowDetails.videoFileName ? (
                                    <iframe
                                      height={"400px"}
                                      title="diamond-video"
                                      src={selectedRowDetails.videoFileName}
                                    ></iframe>
                                  ) : (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  )
                                ) : selectedRowDetails.imageFileName ? (
                                  <img
                                    src={selectedRowDetails.imageFileName}
                                    alt="img-diamond"
                                  />
                                ) : (
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* Image zoom modal */}
                    <Modal
                      title={isEmail ? "Email Diamond Certificate" : "Image"}
                      className="modalvdiamond__section image__zoommodal"
                      centered
                      visible={visibleImageZoom}
                      onOk={() => setVisibleImageZoom(false)}
                      onCancel={() => setVisibleImageZoom(false)}
                      width={600}
                      footer={
                        isEmail
                          ? null
                          : [
                              <Button key="back"> {t("Print")} </Button>,
                              <Button
                                key="submit"
                                type="primary"
                                onClick={handleOpenIsEmail}
                              >
                                {t("Email")}
                              </Button>,
                            ]
                      }
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields border-0 p-0">
                              {isEmail ? (
                                <React.Fragment>
                                  <div className="form__fields">
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label> {t("Name")}: </label>
                                          <input
                                            type={"text"}
                                            name="Name"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.Name}
                                          />
                                          {errorEmailCertificat.Name ? (
                                            <div className="text-danger">
                                              {errorEmailCertificat["Name"]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label> {t("Email")}: </label>
                                          <input
                                            type={"text"}
                                            name="Email"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.Email}
                                          />
                                          {errorEmailCertificat.Email ? (
                                            <div className="text-danger">
                                              {errorEmailCertificat["Email"]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="input__block">
                                          <label> {t("Friend's Name")}: </label>
                                          <input
                                            type={"text"}
                                            name="FriendsName"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.FriendsName}
                                          />
                                          {errorEmailCertificat.FriendsName ? (
                                            <div className="text-danger">
                                              {
                                                errorEmailCertificat[
                                                  "FriendsName"
                                                ]
                                              }
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label>
                                            {" "}
                                            {t("Friend's Email")}:{" "}
                                          </label>
                                          <input
                                            type={"text"}
                                            name="FriendsEmail"
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            value={emailCertificat.FriendsEmail}
                                          />
                                          {errorEmailCertificat.FriendsEmail ? (
                                            <div className="text-danger">
                                              {
                                                errorEmailCertificat[
                                                  "FriendsEmail"
                                                ]
                                              }
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12 ">
                                        <div className="input__block">
                                          <label> {t("Comments")}: </label>
                                          <textarea
                                            name="Comments"
                                            value={emailCertificat.Comments}
                                            onChange={(e) =>
                                              handleEmailCertificatChange(e)
                                            }
                                            rows="6"
                                            cols="50"
                                            placeholder={t("Enter Comments")}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-lg-12 ">
                                      <Button
                                        type={"primary"}
                                        loading={EmailLoading}
                                        onClick={() =>
                                          handleCertificateEmailSend()
                                        }
                                      >
                                        {t("Submit")}
                                      </Button>
                                      <Button
                                        onClick={handleCloseIsEmail}
                                        className="ml-2"
                                      >
                                        {t("Cancel")}
                                      </Button>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-12 mt-4">
                                    <div className="image__zoom">
                                      <img
                                        src={selectedRowDetails.imageFileName}
                                        alt="cer-img"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="printsection" style={{ padding: "10px", display: "none" }}>
            <div className="print__section">
              <div className="row">
                <div className="col-md-12">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="header__logo">
                      <img src={logoImg} style={{ width: "160px" }} />
                    </div>
                    <div className="header__text">
                      <h3 className="subheading">Diamond List</h3>
                    </div>
                    <div className="header__date">
                      <p>
                        {new Date().getMonth() +
                          1 +
                          "/" +
                          new Date().getDate() +
                          "/" +
                          new Date().getFullYear()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 mt-4">
                  <div className="table__block">
                    <div className="table__scroll">
                      <table
                        class="table table-bordered table-striped"
                        cellSpacing={0}
                        cellPadding={0}
                        border={"1px solid black"}
                      >
                        <thead
                          class="thead-light"
                          style={{ backgroundColor: "rgb(222, 222, 222)" }}
                        >
                          <tr>
                            <th style={{ padding: "2px" }} width={"15%"}>
                              Stock #
                            </th>
                            <th style={{ padding: "2px" }} width={"5%"}>
                              Shape
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Size
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Cut
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Color
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Clarity
                            </th>
                            <th style={{ padding: "2px" }} width={"4%"}>
                              Off %
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Cost
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Fluor.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Dep.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Table
                            </th>
                            <th style={{ padding: "2px" }} width={"15%"}>
                              Measure.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Cert.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Polish
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Sym.
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Dealer
                            </th>
                            <th style={{ padding: "2px" }} width={"3%"}>
                              Video
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {diamondList.map((item, i) => {
                            return (
                              <React.Fragment>
                                <tr
                                  style={{
                                    backgroundColor:
                                      i % 2 == 0 ? "#efefef" : "white",
                                  }}
                                >
                                  <td cells>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtStockNo}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtShape}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltCarat}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtCutGrade}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtColor}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtClarity}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltRapPercent.includes("-")
                                        ? item.fltRapPercent
                                        : "-" + item.fltRapPercent}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        // overflow: "hidden",
                                        // textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {currencySymbol + parseInt(item.fltPrice)}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtFluorescence}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltDepth + "%"}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.fltTable + "%"}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtMeasurements}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        maxWidth: "150px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtCertificate}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtPolish}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.txtSymmetry}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.dealerID}
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: "0px",
                                        maxWidth: "80px",
                                        padding: "2px",
                                      }}
                                    >
                                      {item.videoFileName ? (
                                        <div className="infoicon__div">
                                          <img
                                            src={videoIcon}
                                            style={{
                                              width: "20px",
                                              padding: "5px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className="bottom__footer"
                    style={{
                      background: "#e9e8ea",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <img src={footerlogoImg} style={{ width: "160px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ViewMyMinedDiamond;
