import React, { useState, useEffect } from "react";
import { Select, Table, Button, Pagination, Input, Modal } from "antd";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Eye_icon from "../../../assets/images/eye__icon.svg";

const CampaignData = () => {
  const { t } = useTranslation();
  const initialState = {
    ComposeID: null,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [campaignDropList, setcampaignDropList] = useState([]);
  const [campaignTableList, setcampaignTableList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [campaignStats, setCampaignStats] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [campaignCreatedDate, setCampaignCreatedDate] = useState(null);

  const { Option } = Select;
  const { Search } = Input;

  const CampaignStats = [
    // {
    // 	title: "Submitted",
    // 	dataIndex: "field1",
    // 	key: "field1",
    // },
    {
      title: t("Delivered"),
      dataIndex: "delivered",
      key: "delivered",
    },
    {
      title: t("Total Opens"),
      dataIndex: "opens",
      key: "opens",
    },
    {
      title: t("Total Clicks"),
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: t("Created"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: () => {
        const date = new Date(campaignCreatedDate);
        return date.toLocaleDateString(); // Display only the date
      },
    },
  ];

  useEffect(() => {
    // handleGetCampaignList();
    handleGetCampaignMailingList(pageNo, pageSize, searchCampaign);
  }, []);

  function onSearch(val) {
    setPageNo(1);
    setPageSize(10);
    handleGetCampaignMailingList(1, 10, val);
    setSearchCampaign(val);
  }

  const onChangeDrop = (e, name) => {
    setcampaignTableList([]);
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleMailingListByCampaignName();
  };

  const handleGetCampaignList = () => {
    setLoading(true);

    AdminCampaignsService.GetCampaignList()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData);
          setcampaignDropList(data.items);
          setcampaignTableList(data.items);
        } else {
          setcampaignDropList([]);
          setcampaignTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetCampaignMailingList = (page, size, val) => {
    let inputData = {
      pageno: page,
      pagesize: size,
      search: val,
    };
    setLoading(true);
    AdminCampaignsService.GetCampaignMailingListV2(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          let list = response?.data?.responseData?.campaignMailStatuslist;
          list.forEach((item) => {
            item.key = item.campaignID;
          });
          if (list) {
            setcampaignTableList(list);
            setLoading(false);
            setTotalCount(response.data.responseData.totalrecords);
          } else {
            setLoading(false);
          }
        } else {
          NotificationManager.error(t(message));
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleMailingListByCampaignName = () => {
    setLoading(true);
    let inputData = {
      campaignID: state.ComposeID,
    };
    AdminCampaignsService.GetMailingListByCampaignName(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          var data = JSON.parse(responseData);
          setcampaignTableList(data.items);
        } else {
          setcampaignTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const handleSendCampaignMails = () => {
    setLoading(true);
    let inputData = {
      campaignID: selectedRowKeys.join(","),
    };
    AdminCampaignsService.PostSendCampaignMails(inputData)
      .then((response) => {
        let message = response.data.message;
        let showMessage = response.data.responseData;
        if (message === "Success") {
          NotificationManager.success(t(showMessage));
          handleGetCampaignMailingList(pageNo, pageSize, searchCampaign);
          setSelectedRowKeys([]);
        } else {
          NotificationManager.error(t(showMessage));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  function handlePageNoChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetCampaignMailingList(page, pageSize, searchCampaign);
    }
  }

  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetCampaignMailingList(current, size, searchCampaign);
  }
  const onRowSelectChange = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  // Detail GetCampaignStats API Calling
  const handleIconClick = (sendGridCampaignID, campaignName, createddate) => {
    setModalTitle(campaignName);
    setCampaignCreatedDate(createddate);
    setIsModalVisible(true);
    AdminCampaignsService.GetCampaignStats(sendGridCampaignID)

      .then((response) => {
        var responseData = response.data.responseData;

        var stats = responseData[0].stats;

        setCampaignStats([stats]);
      })
      .catch((error) => {
        console.error("Error fetching campaign stats:", error);
      });
  };

  return (
    <React.Fragment>
      <div className="campaign__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">{t("Campaigns")}</h4>
            </div>

            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="row">
                {/* <div className="col-lg-3 col-md-6"> */}
                {/* <div className="input__block">
                    <label> {t("Select Campaign")} </label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="Select Campaign"
                      optionFilterProp="children"
                      value={state.ComposeID}
                      onChange={(e) => onChangeDrop(e, "ComposeID")}
                      onSearch={onSearch}
                      filterOption={(input, option) => {
                        if (
                          option?.children &&
                          typeof option?.children == "string" &&
                          input
                        )
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          );
                      }}
                    >
                      <Option value=""> {t("Select Campaign")} </Option>
                      {campaignDropList.map((item, i) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </div> */}
                {/* </div> */}
                {/* <div className="col-lg-3"> */}
                {/* <div className="input__block"> */}
                {/* <input
                        name="campaignName"
                        value={searchCampaign}
                        onChange={(e) => setSearchCampaign(e.target.value)}
                        type="text"
                        placeholder="Search campaign"
                      /> */}
                <Search
                  placeholder={t("Search")}
                  allowClear
                  onSearch={(e) => onSearch(e)}
                  style={{
                    width: 300,
                  }}
                  className="mb-4"
                />
                {/* </div>
                  </div> */}
                <div className="col-lg-8 text-right mobile-mt-0 text-left-mobile addedit__btndiv col-lg-2">
                  <Button
                    className={
                      selectedRowKeys.length > 0
                        ? "primary-btn"
                        : "disabled-btn"
                    }
                    onClick={() => handleSendCampaignMails()}
                    disabled={selectedRowKeys.length > 0 ? false : true}
                  >
                    {t("Run Campaign")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="campaign_tbldiv">
                {/* <Table
                  const
                  columns={[
                    {
                      title: t("Campaign Name"),

                      dataIndex: "name",
                      key: "name",
                      fixed: "left",
                      width: 160,
                    },
                    {
                      title: t("Submitted"),
                      dataIndex: "submitted_count",
                      width: 120,
                      key: "submitted_count",
                    },
                    {
                      title: t("Delivered"),
                      width: 120,
                      dataIndex: "delivered_count",
                      key: "delivered_count",
                    },
                    {
                      title: t("Total Opens"),
                      width: 120,
                      dataIndex: "opened_count",
                      key: "opened_count",
                    },
                    {
                      title: t("Total Clicks"),
                      width: 120,
                      dataIndex: "clicked_count",
                      key: "clicked_count",
                    },
                    {
                      title: t("Created"),
                      width: 300,
                      dataIndex: "created_at",
                      key: "created_at",
                    },
                  ]}
                  loading={loading}
                  dataSource={campaignTableList}
                  scroll={{ x: 600, y: 800 }}
                /> */}
                <Table
                  const
                  columns={[
                    {
                      title: t("Campaign Name"),
                      dataIndex: "campaignName",
                      key: "campaignName",
                    },
                    {
                      title: t("Group Name"),
                      dataIndex: "dealerGroupName",
                      key: "dealerGroupName",
                    },
                    {
                      title: t("Template Name"),
                      dataIndex: "templateName",
                      key: "templateName",
                    },
                    {
                      title: t("Email Status"),
                      dataIndex: "emailStatus",
                      key: "emailStatus",
                      width: 100,
                    },
                    {
                      title: t("Action"),
                      key: "action",
                      render: (text, record) => (
                        <div className="action__btns">
                          {record.sendGridCampaignID !== "" && (
                            <div
                              className="image__block"
                              onClick={() =>
                                handleIconClick(
                                  record.sendGridCampaignID,
                                  record.campaignName,
                                  record.createddate
                                )
                              }
                            >
                              <img src={Eye_icon} alt="View Stats" />
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  loading={loading}
                  dataSource={campaignTableList}
                  pagination={false}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: onRowSelectChange,
                    hideDefaultSelections: true,
                    getCheckboxProps: (record) => ({
                      disabled: record.emailStatus === "Completed",
                      className:
                        record.emailStatus === "Completed"
                          ? "hide-checkbox"
                          : "",
                    }),
                  }}
                  scroll={{ x: 600, y: 800 }}
                />
                <Modal
                  width={800}
                  title={modalTitle}
                  visible={isModalVisible}
                  onCancel={() => {
                    setIsModalVisible(false);
                    setCampaignStats([]);
                  }}
                  footer={null}
                >
                  <Table dataSource={campaignStats} columns={CampaignStats} />
                </Modal>

                <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                  <Pagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={totalCount}
                    onChange={handlePageNoChange}
                    onShowSizeChange={handlePageSizeChange}
                    showSizeChanger="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignData;
