import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ReportsDiamondService from "../../../services/reports-Diamond.service";
import { useSelector } from "react-redux";
import moment from "moment";

const DiamondReportsCutGradeComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  const { t } = useTranslation();
  /*React Binding Starts*/
  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  const [diamondCutGradeSearchesLabels, setdiamondCutGradeSearchesLabels] =
    useState([]);
  const [DiamondCutGradeColorData, setDiamondCutGradeColorData] = useState([]);
  const [DiamondCutGradeYAxisList, setDiamondCutGradeYAxisList] = useState([]);
  const [key, setKey] = useState(true);

  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          handleLoadExtendedDiamondChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/

  const handleLoadExtendedDiamondChartReports = (dealerId, forDealer) => {
    try {
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName ===
        "VendorDiamond"
      ) {
        forDealer = 1;
      }
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom
          ? moment(globalDiamonds.dataRangeFrom).format("YYYY-MM-DD")
          : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo
          ? moment(globalDiamonds.dataRangeTo).format("YYYY-MM-DD")
          : "2022-09-15",
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        reportName: "ExtDiamondChrt",
      };
      setLoadChartReportsLoading(true);

      ReportsDiamondService.LoadExtendedDiamondChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondCutGradeSearchesList =
            responseData.DiamondCutGradeSearches;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              var DiamondCutGradeSearchesLabelArr =
                DiamondCutGradeSearchesList.map((item) => {
                  return item.CutGrade;
                });
              setdiamondCutGradeSearchesLabels(DiamondCutGradeSearchesLabelArr);
              var DiamondCutGradeYAxisList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.CNT;
                }
              );
              var DiamondCutGradeColorList = DiamondCutGradeSearchesList.map(
                (item) => {
                  return item.CutGrade;
                }
              );
              setDiamondCutGradeYAxisList(DiamondCutGradeYAxisList);
              diamondCutGradeColorHandler(DiamondCutGradeColorList);
              setKey(!key);
            }
          } else {
            setDiamondCutGradeYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondCutGradeYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondCutGradeSearchesLabels([]);
        });
    } catch (error) {
      setDiamondCutGradeYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondCutGradeSearchesLabels([]);
    }
  };
  /*API Binding Ends*/
  const diamondCutGradeColorHandler = (list) => {
    const color = [];
    for (let i = 0; i < list.length; i++) {
      color.push("rgb(66, 133, 244)");
    }
    setDiamondCutGradeColorData(color);
  };
  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {t("Diamond CutGrade Searches")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            <Bar
              key={key}
              data={{
                labels: diamondCutGradeSearchesLabels,
                datasets: [
                  {
                    label: "Count",
                    data: DiamondCutGradeYAxisList,
                    backgroundColor: DiamondCutGradeColorData,
                    borderColor: DiamondCutGradeColorData,
                    borderWidth: 1,
                  },
                ],
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: false,
                      text: "Certificate",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondReportsCutGradeComp;
