import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import AdminDiamondReportService from "../../../services/reports-AdminDiamond-service";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const DiamondClicks = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const initialGlobalDiamondState = {
    dataRangeFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
    dataRangeTo: moment().format("YYYY-MM-DD"),
    SelectedDiamondValue: "All",
    retailerIds: [],
    vendorIds: [],
  };
  const [topDiamondKey, setTopDiamondKey] = useState(true);
  const [recentDiamondKey, setRecentDiamondKey] = useState(true);
  const [loading, setLoading] = useState(false);
  const [
    TopDiamondAttributeValuesTableList,
    setTopDiamondAttributeValuesTableList,
  ] = useState([]);
  const [
    RecentDiamondAttributeSearchesTableList,
    setRecentDiamondAttributeSearchesTableList,
  ] = useState([]);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [forDealer, setForDealer] = useState("");
  const [globalDiamonds, setGlobalDiamond] = useState(
    initialGlobalDiamondState
  );
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [TotalDiamondRecords, setTotalDiamondRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrderCol, setsortOrderCol] = useState("date");
  const [sortOrderType, setsortOrderType] = useState("desc");

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setGlobalDiamond((prevState) => ({
        ...prevState,
        dataRangeFrom: dateStrings[0],
        dataRangeTo: dateStrings[1],
      }));
    } else {
    }
  };

  function handleOnChange(e, isSelect, name) {
    setGlobalDiamond({
      ...globalDiamonds,
      [name]: e,
    });
  }

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleCheckDealermembers(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleCheckDealermembers = (id) => {
    let inputData = {
      dealerID: id,
    };

    AdminDiamondReportService.CheckDealermembers(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var forDealer = responseData.forDealer;
        if (message === "Success") {
          if (Object.keys(responseData).length !== 0) {
            setForDealer(forDealer);
          } else {
            setForDealer("");
          }
          handleGetTopDiamondAttributeValuesList(id, forDealer);
          handleGetRecentDiamondAttributeSearchesList(
            id,
            forDealer,
            pageNo,
            pageSize,
            sortOrderCol,
            sortOrderType
          );
        } else {
          setForDealer("");
        }
      })
      .catch((error) => {
        setForDealer("");
      });
  };

  const handleGetTopDiamondAttributeValuesList = (dealerId, forDealer) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom,
      toDate: globalDiamonds.dataRangeTo,
      retailerIds: globalDiamonds.retailerIds.toString(),
      vendorIds: globalDiamonds.vendorIds.toString(),
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
    };
    setLoading(true);
    AdminDiamondReportService.GetTopDiamondAttributeTableList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var tempArray = [];
        tempArray.push(responseData);
        if (message === "Success") {
          setTopDiamondAttributeValuesTableList(tempArray);
        } else {
          setTopDiamondAttributeValuesTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleGetRecentDiamondAttributeSearchesList = (
    dealerId,
    forDealer,
    pageNo,
    pageSize,
    sortOrderCol,
    sortOrderType
  ) => {
    let inputData = {
      dealerID: dealerId.toString(),
      forDealer: forDealer ? forDealer.toString() : "",
      fromDate: globalDiamonds.dataRangeFrom,
      toDate: globalDiamonds.dataRangeTo,
      clarity: "",
      cutGrade: "",
      shape: "",
      color: "",
      size: "",
      certificate: "",
      reporttype: "Global",
      selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
      pageNumber: pageNo,
      pageSize: pageSize,
      sortOrderCol: sortOrderCol || "date",
      sortOrderType: sortOrderType || "DESC",
    };
    setLoading(true);
    AdminDiamondReportService.GetRecentDiamondAttributeTableList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRecentDiamondAttributeSearchesTableList(
            responseData.diamondVendorTableChartresp
          );
          setTotalDiamondRecords(responseData.totalCount);
        } else {
          setRecentDiamondAttributeSearchesTableList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleApplyFilter = () => {
    setTopDiamondKey(!topDiamondKey);
    setRecentDiamondKey(!recentDiamondKey);
    handleCheckDealermembers(dealerId);
    setIsApplyFilter(!isApplyFilter);
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setsortOrderType(type);
      setsortOrderCol(column);
    } else {
      type = "DESC";
      column = "date";
      setsortOrderCol(column);
      setsortOrderType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetRecentDiamondAttributeSearchesList(
      dealerId,
      forDealer,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block mobile-m-0">
              <h4 class="workarea__heading mt-0 mb-0">
                {t("Diamond Clicks")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block dataRange-input-block">
              <label> {t("Date Range")} </label>

              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {t("Select Diamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder={t("All")}
                optionFilterProp="children"
                name="SelectedDiamondValue"
                value={
                  globalDiamonds.SelectedDiamondValue
                    ? globalDiamonds.SelectedDiamondValue
                    : null
                }
                onChange={(e) =>
                  handleOnChange(e, "select", "SelectedDiamondValue")
                }
              >
                <Option value="All"> {t("All")} </Option>
                <Option value="Mined"> {t("Mined Diamond")} </Option>
                <Option value="Lab"> {t("Lab Grown Diamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button
                className="primary-btn mobile-mt-0"
                style={{ marginTop: 25 }}
                onClick={handleApplyFilter}
              >
                {" "}
                {t("Apply Filter")}{" "}
              </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4 mobile-m-0">
            <div className="input__block">
              <label>
                <b> {t("Top Diamond Attribute Values")} </b>
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="table__scroll">
              <Table
                key={topDiamondKey}
                className="pendjewelreq__tbl"
                columns={[
                  {
                    title: t("Date"),
                    dataIndex: "date",
                    width: 200,
                  },
                  {
                    title: t("Shape"),
                    dataIndex: "shape",
                    width: 120,
                  },
                  {
                    title: t("Size"),
                    dataIndex: "size",
                    width: 120,
                  },
                  {
                    title: t("Color"),
                    dataIndex: "color",
                    width: 120,
                  },
                  {
                    title: t("Cut"),
                    dataIndex: "cutGrade",
                    width: 120,
                  },
                  {
                    title: t("Clarity"),
                    dataIndex: "clarity",
                    width: 120,
                  },
                  {
                    title: t("Price"),
                    dataIndex: "price",
                    width: 120,
                  },
                ]}
                loading={loading}
                dataSource={TopDiamondAttributeValuesTableList}
                scroll={{ y: 300, y: 800 }}
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4 mobile-m-0">
            <div className="input__block">
              <label>
                <b> {t("Recent Diamond Attribute Searches")} </b>
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              key={recentDiamondKey}
              className="pendjewelreq__tbl"
              columns={[
                {
                  title: t("UsersIPAddress"),
                  dataIndex: "usersIPAddress",
                  width: 150,
                  sorter: (a, b) =>
                    a.usersIPAddress.localeCompare(b.usersIPAddress),
                },
                {
                  title: t("Day & Time"),
                  dataIndex: "date",
                  width: 200,
                  sorter: (a, b) => {},
                  render: (row, item) => {
                    //

                    if (Object.keys(item.date).length !== 0) {
                      return (
                        <React.Fragment>
                          <span>
                            {moment(item.date).format("MM-DD-YYYY, hh:mm:ss A")}
                          </span>
                        </React.Fragment>
                      );
                    }
                  },
                },
                {
                  title: t("Shape"),
                  dataIndex: "shape",
                  width: 90,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Size"),
                  dataIndex: "size",
                  width: 90,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Color"),
                  dataIndex: "color",
                  width: 90,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Cut"),
                  dataIndex: "cutGrade",
                  width: 120,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Clarity"),
                  dataIndex: "clarity",
                  width: 120,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Certificates"),
                  dataIndex: "certificate",
                  width: 120,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Retail Price"),
                  dataIndex: "retailPrice",
                  width: 120,
                  sorter: (a, b) => {},
                },
                {
                  title: t("Cost"),
                  dataIndex: "cost",
                  width: 120,
                  sorter: (a, b) => {},
                },
              ]}
              loading={loading}
              dataSource={RecentDiamondAttributeSearchesTableList}
              scroll={{ x: 600, y: 800 }}
              pagination={{
                total: TotalDiamondRecords,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onChange={handleTableASCDES}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondClicks;
