import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const StudBuilderSettings = props => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const columns = [
        {
            title: t("Setting"),
            dataIndex: "setting",
        },
        {
            title: t("Vendor"),
            dataIndex: "vendor",
        },
        {
            title: t("Metal Type"),
            dataIndex: "metalType",
        },
        {
            title: t("Price"),
            dataIndex: "price",
        },
        {
            title: t("Markup"),
            dataIndex: "markup",
        },
        {
            title: t("Status"),
            dataIndex: "status",
        },

    ];

    const data = [
        {
            key: "1",
            setting: "Simple Setting",
            vendor: "SH1001",
            metalType: "14K Gold",
            price: "500",
            markup: "250",
            status: <input type="checkbox" />
        },
        {
            key: "2",
            setting: "Simple Setting",
            vendor: "SH1001",
            metalType: "14K Gold",
            price: "500",
            markup: "250",
            status: <input type="checkbox" />
        },
        {
            key: "3",
            setting: "Simple Setting",
            vendor: "SH1001",
            metalType: "14K Gold",
            price: "500",
            markup: "250",
            status: <input type="checkbox" />
        },
        {
            key: "4",
            setting: "Simple Setting",
            vendor: "SH1001",
            metalType: "14K Gold",
            price: "500",
            markup: "250",
            status: <input type="checkbox" />
        }
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0"> {t("Settings")} </h4>
                    </div>
                </div>
                <div className="divForm vendors-section">
                    <Table columns={columns} dataSource={data}scroll={{ y: 800}} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudBuilderSettings);
