import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Modal, Button, Spin } from "antd";

import { useLocation } from "react-router-dom";
import RetailerLogo from "../../../assets/images/VendorLogo.png";
import ImageNone from "../../../assets/images/image-none.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { useTranslation } from "react-i18next";
import ManageBrandsService from "../../../services/manage-brands.service";
import { NotificationManager } from "react-notifications";

import Config from "./../../../helper/config";

const Brands = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [DeleteVisible, setDeleteVisible] = useState(false);

  const showModalFullscreen = () => {
    setIsModalVisibleFullscreen(true);
  };

  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };

  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);

  const [dealerId, setDealerId] = useState();
  const [brandData, setBrandData] = useState([]);
  const [editBrandData, setEditBrandData] = useState({});
  const [DesignerId, setDesignerId] = useState("");
  const [DesignerName, setDesignerName] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [isedit, setIsEdit] = useState(false);

  const [validations, setValidations] = useState({
    DesignerNameVal: "",
    EmailVal: "",
  });
  const [selectedId, setSelectedId] = useState("");
  const location = useLocation();
  const data = [];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);

          if (location.state) {
            if (location.state.id) {
              getBrandDataHandler(location.state.id);
            }
          } else {
            getBrandDataHandler(
              loginDetials.loginDetials.responseData.dealerId
            );
          }
        }
      }
    }
  }, []);
  const getBrandDataHandler = (id) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setShowLoader(true);
      ManageBrandsService.GetBrandData(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          responseData[""].map((elem, index) => {
            data.push({
              key: index,
              image: (
                <img
                  onClick={showModalFullscreen}
                  className="brands-list-logo"
                  src={elem.logoimg}
                  width="50"
                  alt=""
                />
              ),
              brandId: <span>{elem.DesignerId}</span>,
              brandName: <span>{elem.DesignerName}</span>,
              link: <span className="linkText">{elem.Url}</span>,
              action: (
                <div className="action__btns">
                  <div className="image__block">
                    <img
                      src={Edit_icon}
                      alt=""
                      onClick={() => showEditBrand(elem.DesignerId)}
                    />
                  </div>
                  <div className="image__block">
                    <img
                      src={Delete_iconbl}
                      alt=""
                      onClick={() => {
                        setDesignerId(elem.DesignerId);
                        setDesignerName(elem.brandName);
                        setDeleteVisible(true);
                      }}
                    />
                  </div>
                </div>
              ),
            });
            setSelectedId("");
          });
          setBrandData(data);
          setShowLoader(false);
          setSelectedId("");
        } else {
          setSelectedId("");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const showEditBrand = (id) => {
    try {
      setVisible(true);
      let inputData = {
        dealerID: loginDetials.loginDetials.responseData.dealerId.toString(),
        designerID: id.toString(),
      };
      setSelectedId(id);
      ManageBrandsService.LoadEditBrand(inputData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setIsEdit(true);
          setEditBrandData((prevState) => ({
            ...responseData,
            imgDesignerLogo: null,
            imgDesignerLogoLink: responseData.imgDesignerLogo,
            imgDesignerImage4: null,
            imgDesignerImage4Link: responseData.imgDesignerImage4,
            imgDesignerImage5: null,
            imgDesignerImage5Link: responseData.imgDesignerImage5,
            imgDesignerImage6: null,
            imgDesignerImage6Link: responseData.imgDesignerImage6,
            imgSmallBanner: null,
            imgSmallBannerLink: responseData.imgSmallBanner,
            showCaseLogo: responseData.showCaseLogo,
            showCaseImage: responseData.showCaseImage,
            showCaseText: responseData.showCaseText,
          }));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleCancel = () => {
    setSelectedId("");

    setVisible(false);
    setIsEdit(false);
    setValidations({
      DesignerNameVal: "",
      EmailVal: "",
    });
  };
  const columns = [
    {
      title: "",
      dataIndex: "image",
      width: 50,
    },
    {
      title: t("Brand/Designer"),
      dataIndex: "brandName",
      width: 100,
    },
    {
      title: t("Link"),
      dataIndex: "link",
      width: 300,
    },
    {
      title: t("Action"),
      dataIndex: "action",
      width: 100,
    },
  ];
  const onEditChangeHandler = (e, name) => {
    let value = e.target.value;
    setEditBrandData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onCheckBoxChangeHandler = (e, name) => {
    if (isedit) {
      if (name === "divLogo" && editBrandData.divLogo === true) {
        setEditBrandData((prevState) => ({
          ...prevState,
          imgDesignerLogoLink: null,
        }));
      }
    }
    if (
      name === "radShowCaseImage1" ||
      name === "radShowCaseImage2" ||
      name === "radShowCaseImage3"
    ) {
      setEditBrandData((prevState) => ({
        ...prevState,
        radShowCaseImage1: false,
        radShowCaseImage2: false,
        radShowCaseImage3: false,
      }));
      setEditBrandData((prevState) => ({
        ...prevState,
        [name]: !editBrandData[name],
      }));
    } else {
      setEditBrandData((prevState) => ({
        ...prevState,
        [name]: !editBrandData[name],
      }));
    }
  };
  const handleValidation = () => {
    const validations = {};
    let isValid = true;
    if (!editBrandData.txtdesignerName) {
      isValid = false;
      validations.DesignerNameVal = t("Designer name is compulsory");
    } else {
      validations.DesignerNameVal = "";
    }
    if (!editBrandData.txtEmail) {
      isValid = false;
      validations.EmailVal = t("Email is compulsory");
    } else {
      validations.EmailVal = "";
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const fileUpload = async (e, name) => {
    setEditBrandData((prevState) => ({
      ...prevState,
      [name]: e.target.files[0],
    }));
  };

  const onDeleteImage = (name) => {
    if (name == "imgDesignerLogo") {
      setEditBrandData({
        ...editBrandData,
        [name]: "",
        imgDesignerLogoLink: "",
      });
    } else if (name == "imgDesignerImage4") {
      setEditBrandData({
        ...editBrandData,
        [name]: "",
        imgDesignerImage4Link: "",
      });
    } else if (name == "imgDesignerImage5") {
      setEditBrandData({
        ...editBrandData,
        [name]: "",
        imgDesignerImage5Link: "",
      });
    } else if (name == "imgDesignerImage6") {
      setEditBrandData({
        ...editBrandData,
        [name]: "",
        imgDesignerImage6Link: "",
      });
    } else if (name == "imgSmallBanner") {
      setEditBrandData({
        ...editBrandData,
        [name]: "",
        imgSmallBannerLink: "",
      });
    }
  };
  /*Form Data Starts */
  const formData = new FormData();

  const onSubmitHandler = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }

    formData.append("txtdesignerName", editBrandData.txtdesignerName);
    formData.append("DesignerID", isedit ? selectedId.toString() : "0");
    formData.append("DealerID", dealerId.toString());
    formData.append("txtEmail", editBrandData.txtEmail);
    formData.append("txtUrl", editBrandData.txtUrl);
    formData.append("txtaboutDesigner", editBrandData.txtaboutDesigner);
    formData.append("txttagLine", editBrandData.txttagLine);
    formData.append("txtvideoUrl", editBrandData.txtvideoUrl);
    formData.append(
      "chkDesigner",
      editBrandData.chkDesigner ? editBrandData.chkDesigner : false
    );
    formData.append(
      "chkWatchBrand",
      editBrandData.chkWatchBrand ? editBrandData.chkWatchBrand : false
    );
    formData.append(
      "chkBridalDesigner",
      editBrandData.chkBridalDesigner ? editBrandData.chkBridalDesigner : false
    );
    formData.append(
      "radShowCaseImage1",
      editBrandData.radShowCaseImage1 ? editBrandData.radShowCaseImage1 : false
    );
    formData.append(
      "radShowCaseImage2",
      editBrandData.radShowCaseImage2 ? editBrandData.radShowCaseImage2 : false
    );
    formData.append(
      "radShowCaseImage3",
      editBrandData.radShowCaseImage3 ? editBrandData.radShowCaseImage3 : false
    );
    formData.append(
      "Fu_LogoImage",
      editBrandData.imgDesignerLogo ? editBrandData.imgDesignerLogo : null
    );
    formData.append(
      "Fu_LogoImage_flag",
      editBrandData.imgDesignerLogoLink
        ? editBrandData.imgDesignerLogoLink.replace(
            `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
            ""
          )
        : null
    );
    formData.append(
      "Fu_ShowcaseImage1",
      editBrandData.imgDesignerImage4 ? editBrandData.imgDesignerImage4 : null
    );
    formData.append(
      "Fu_ShowcaseImage1_flag",
      editBrandData.imgDesignerImage4Link
        ? editBrandData.imgDesignerImage4Link.replace(
            `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
            ""
          )
        : null
    );
    formData.append(
      "Fu_ShowcaseImage2",
      editBrandData.imgDesignerImage5 ? editBrandData.imgDesignerImage5 : null
    );
    formData.append(
      "Fu_ShowcaseImage2_flag",
      editBrandData.imgDesignerImage5Link
        ? editBrandData.imgDesignerImage5Link.replace(
            `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
            ""
          )
        : null
    );
    formData.append(
      "Fu_ShowcaseImage3",
      editBrandData.imgDesignerImage6 ? editBrandData.imgDesignerImage6 : null
    );
    formData.append(
      "Fu_ShowcaseImage3_flag",
      editBrandData.imgDesignerImage6Link
        ? editBrandData.imgDesignerImage6Link.replace(
            `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
            ""
          )
        : null
    );
    formData.append(
      "Fu_SmallImage",
      editBrandData.imgSmallBanner ? editBrandData.imgSmallBanner : null
    );
    formData.append(
      "Fu_SmallImage_flag",
      editBrandData.imgSmallBannerLink
        ? editBrandData.imgSmallBannerLink.replace(
            `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/`,
            ""
          )
        : null
    );
    formData.append("chkUseVendorProfile", editBrandData.divLogo);
    try {
      ManageBrandsService.SubmitEditBrandData(formData).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setVisible(false);
          NotificationManager.success(t("Brand Updated Successfully."));
          getBrandDataHandler(dealerId);

          setIsEdit(false);
        } else {
          NotificationManager.error(t("Brand Update Failed."));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteBrandData = (id) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        designerID: id.toString(),
      };
      ManageBrandsService.DeleteBrandData(inputData).then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(t("Brand has been Deleted"));
          getBrandDataHandler(dealerId);
          setDeleteVisible(false);
          getBrandDataHandler(dealerId);
        } else {
          NotificationManager.success(t("Brand has not been Deleted"));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={showLoader}>
        <div className="form__fields">
          <div className="row magento__mapping permission__section">
            <div className="row">
              <div className="col-md-12">
                <div className="heading__block">
                  <h4 class="workarea__heading mt-0 mb-0">
                    {t("Manage Brand")}/{t("Designer")}
                  </h4>
                </div>
              </div>
              <div className="col-md-9">
                <p>
                  {t(
                    "If your company sells its product under a consumer brand name, then use this interface to configure the brand info. Brand names will be listed on retailer's websites in category navigation (unless restricted by vendor)."
                  )}
                </p>
              </div>
              <div className="col-lg-12">
                <div className="shipping_tbldiv">
                  <div className="table__scroll">
                    <Table
                      columns={columns}
                      dataSource={brandData}
                      scroll={{ y: 800 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <Modal
        title={isedit ? t("Update Brands") : t("Insert Brands")}
        className="modal__addcolor"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={550}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmitHandler}>
            {t("Submit")}
          </Button>,
        ]}
      >
        <div className="modal__body">
          <div className="form__fields border-0 p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="input__block">
                  <label>
                    {t("Designer Name")} <span className="text-red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      (editBrandData.txtdesignerName === undefined ||
                        editBrandData.txtdesignerName === "") &&
                      validations.DesignerNameVal
                        ? "border__red"
                        : ""
                    }
                    value={editBrandData.txtdesignerName}
                    onChange={(e) => {
                      onEditChangeHandler(e, "txtdesignerName");
                    }}
                  />
                  {(editBrandData.txtdesignerName === undefined ||
                    editBrandData.txtdesignerName === "") &&
                  validations.DesignerNameVal ? (
                    <div className="text-danger">
                      {validations.DesignerNameVal}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {t("Designer Logo")}{" "}
                    {/* <span className="text-red">*</span> */}
                  </label>
                  <div
                    className="image__block image_block"
                    // className={
                    //   (editBrandData.imgDesignerLogo === undefined ||
                    //     editBrandData.imgDesignerLogo === "") &&
                    //     validations.VendorProfileLogoVal
                    //     ? " image__block image_block border__red "
                    //     : "image__block image_block"
                    // }
                  >
                    <div className="divimage_block border-0 p-0">
                      {editBrandData.imgDesignerLogo === undefined ||
                      editBrandData.imgDesignerLogo === "" ? (
                        <div className="">
                          <label
                            htmlFor="file-upload"
                            class="primary-btn choose_file_upload"
                          >
                            {t("Choose File")}
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            onChange={(e) => fileUpload(e, "imgDesignerLogo")}
                          />
                          {/* {validations.VendorProfileLogoVal ? (
                            <div className="text-danger">
                              {validations.VendorProfileLogoVal}
                            </div>
                          ) : null} */}
                          <span class="mb-4"> {t("No File Choosen")} </span>
                          <span class=""> (Preferred size 500px x 500px)</span>
                        </div>
                      ) : (
                        <div class="img__showdiv manage-user-img-sp">
                          {isedit ? (
                            editBrandData.imgDesignerLogo ? (
                              <img
                                src={URL.createObjectURL(
                                  editBrandData.imgDesignerLogo
                                )}
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                // src={editBrandData.imgDesignerLogoLink}
                                src={
                                  editBrandData.imgDesignerLogoLink ===
                                  `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
                                    ? ImageNone
                                    : editBrandData.imgDesignerLogoLink
                                }
                                className="img-fluid"
                              />
                            )
                          ) : editBrandData.imgDesignerLogo ? (
                            <img
                              src={URL.createObjectURL(
                                editBrandData.imgDesignerLogo
                              )}
                              className="img-fluid"
                            />
                          ) : null}
                          <span
                            className="close_image"
                            onClick={() => onDeleteImage("imgDesignerLogo")}
                          >
                            x
                          </span>
                          {!editBrandData.imgDesignerLogoLink &&
                            !editBrandData.imgDesignerLogo && (
                              <div class="text-danger">
                                Please Select the Image
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="radio__block mb-0">
                    <input
                      type="checkbox"
                      checked={editBrandData.divLogo}
                      onChange={(e) => onCheckBoxChangeHandler(e, "divLogo")}
                    />
                    <label>{t("Use Vendor Profile Logo")}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{t("Navigation Category")}</label>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="radio__block mb-0">
                        <input
                          type="checkbox"
                          id="chkDesigner"
                          checked={editBrandData.chkDesigner}
                          onChange={(e) =>
                            onCheckBoxChangeHandler(e, "chkDesigner")
                          }
                        />
                        <label>{t("Fashion Designer")}</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="radio__block mb-0">
                        <input
                          type="checkbox"
                          checked={editBrandData.chkWatchBrand}
                          onChange={(e) =>
                            onCheckBoxChangeHandler(e, "chkWatchBrand")
                          }
                        />
                        <label>{t("Watch Designer")}</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="radio__block mb-0">
                        <input
                          type="checkbox"
                          checked={editBrandData.chkBridalDesigner}
                          onChange={(e) =>
                            onCheckBoxChangeHandler(e, "chkBridalDesigner")
                          }
                        />
                        <label>{t("Bridal Designer")}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{t("Showcase Image 1")}</label>
                  <div className="image__block image_block">
                    <div className="divimage_block border-0 p-0">
                      {editBrandData.imgDesignerImage4 === undefined ||
                      editBrandData.imgDesignerImage4 === "" ? (
                        <div className="">
                          <label
                            htmlFor="file-upload1"
                            class="primary-btn choose_file_upload"
                          >
                            {t("Choose File")}
                          </label>
                          <input
                            id="file-upload1"
                            type="file"
                            onChange={(e) => fileUpload(e, "imgDesignerImage4")}
                          />
                          <span class="mb-4"> {t("No File Choosen")} </span>
                          <span class=""> (Preferred size 200px x 200px)</span>
                        </div>
                      ) : (
                        <div class="img__showdiv manage-user-img-sp">
                          {isedit ? (
                            editBrandData.imgDesignerImage4 ? (
                              <img
                                src={URL.createObjectURL(
                                  editBrandData.imgDesignerImage4
                                )}
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  editBrandData.imgDesignerImage4Link ===
                                  `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
                                    ? ImageNone
                                    : editBrandData.imgDesignerImage4Link
                                }
                                className="img-fluid"
                              />
                            )
                          ) : editBrandData.imgDesignerImage4 ? (
                            <img
                              src={URL.createObjectURL(
                                editBrandData.imgDesignerImage4
                              )}
                              className="img-fluid"
                            />
                          ) : null}
                          {editBrandData.imgDesignerImage4Link === null &&
                            editBrandData.imgDesignerImage4 === null && (
                              <h6>No Image Selected</h6>
                            )}
                          <span
                            className="close_image"
                            onClick={() => onDeleteImage("imgDesignerImage4")}
                          >
                            x
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="radio__block">
                    <input
                      type="checkbox"
                      checked={editBrandData.radShowCaseImage1}
                      onChange={(e) =>
                        onCheckBoxChangeHandler(e, "radShowCaseImage1")
                      }
                    />
                    <label>{t("Use In Showcase")}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{t("Showcase Image 2")}</label>
                  <div class="image__block image_block">
                    <div class="divimage_block border-0 p-0">
                      {editBrandData.imgDesignerImage5 === undefined ||
                      editBrandData.imgDesignerImage5 === "" ? (
                        <div className="">
                          <label
                            htmlFor="file-upload3"
                            class="primary-btn choose_file_upload"
                          >
                            {t("Choose File")}
                          </label>
                          <input
                            type="file"
                            id="file-upload3"
                            onChange={(e) => fileUpload(e, "imgDesignerImage5")}
                          />
                          <span class="mb-4"> {t("No File Choosen")} </span>
                          <span class=""> (Preferred size 200px x 200px)</span>
                        </div>
                      ) : (
                        <div class="img__showdiv manage-user-img-sp">
                          {isedit ? (
                            editBrandData.imgDesignerImage5 ? (
                              <img
                                src={URL.createObjectURL(
                                  editBrandData.imgDesignerImage5
                                )}
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  editBrandData.imgDesignerImage5Link ===
                                  `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
                                    ? ImageNone
                                    : editBrandData.imgDesignerImage5Link
                                }
                                className="img-fluid"
                              />
                            )
                          ) : editBrandData.imgDesignerImage5 ? (
                            <img
                              src={URL.createObjectURL(
                                editBrandData.imgDesignerImage5
                              )}
                              className="img-fluid"
                            />
                          ) : null}
                          {editBrandData.imgDesignerImage5Link === null &&
                            editBrandData.imgDesignerImage5 === null && (
                              <h6>No Image Selected</h6>
                            )}
                          <span
                            className="close_image"
                            onClick={() => onDeleteImage("imgDesignerImage5")}
                          >
                            x
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="radio__block">
                    <input
                      type="checkbox"
                      checked={editBrandData.radShowCaseImage2}
                      onChange={(e) =>
                        onCheckBoxChangeHandler(e, "radShowCaseImage2")
                      }
                    />
                    <label>{t("Use In Showcase")}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{t("Showcase Image 3")}</label>
                  <div class="image__block image_block">
                    <div class="divimage_block border-0 p-0">
                      {editBrandData.imgDesignerImage6 === undefined ||
                      editBrandData.imgDesignerImage6 === "" ? (
                        <div className="">
                          <label
                            htmlFor="file-upload4"
                            class="primary-btn choose_file_upload"
                          >
                            {t("Choose File")}
                          </label>
                          <input
                            type="file"
                            id="file-upload4"
                            onChange={(e) => fileUpload(e, "imgDesignerImage6")}
                          />
                          <span class="mb-4"> {t("No File Choosen")} </span>
                          <span class=""> (Preferred size 200px x 200px)</span>
                        </div>
                      ) : (
                        <div class="img__showdiv manage-user-img-sp">
                          {isedit ? (
                            editBrandData.imgDesignerImage6 ? (
                              <img
                                src={URL.createObjectURL(
                                  editBrandData.imgDesignerImage6
                                )}
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  editBrandData.imgDesignerImage6Link ===
                                  `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
                                    ? ImageNone
                                    : editBrandData.imgDesignerImage6Link
                                }
                                className="img-fluid"
                              />
                            )
                          ) : editBrandData.imgDesignerImage6 ? (
                            <img
                              src={URL.createObjectURL(
                                editBrandData.imgDesignerImage6
                              )}
                              className="img-fluid"
                            />
                          ) : null}
                          {editBrandData.imgDesignerImage6Link === null &&
                            editBrandData.imgDesignerImage6 === null && (
                              <h6>No Image Selected</h6>
                            )}

                          <span
                            className="close_image"
                            onClick={() => onDeleteImage("imgDesignerImage6")}
                          >
                            x
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="radio__block">
                    <input
                      type="checkbox"
                      checked={editBrandData.radShowCaseImage3}
                      onChange={(e) =>
                        onCheckBoxChangeHandler(e, "radShowCaseImage3")
                      }
                    />
                    <label>{t("Use In Showcase")}</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{t("Designer Small Banner")}</label>
                  <div class="image__block image_block">
                    <div class="divimage_block border-0 p-0">
                      {editBrandData.imgSmallBanner === undefined ||
                      editBrandData.imgSmallBanner === "" ? (
                        <div className="">
                          <label
                            htmlFor="file-upload5"
                            class="primary-btn choose_file_upload"
                          >
                            {t("Choose File")}
                          </label>
                          <input
                            type="file"
                            id="file-upload5"
                            onChange={(e) => fileUpload(e, "imgSmallBanner")}
                          />
                          <span class="mb-4"> {t("No File Choosen")} </span>
                          <span class=""> (Preferred size 271px x 271px)</span>
                        </div>
                      ) : (
                        <div class="img__showdiv manage-user-img-sp">
                          {isedit ? (
                            editBrandData.imgSmallBanner ? (
                              <img
                                src={URL.createObjectURL(
                                  editBrandData.imgSmallBanner
                                )}
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  editBrandData.imgSmallBannerLink ===
                                  `${Config.BlobURL}gemfind2staging/ProfileBrandLocationImages/null`
                                    ? ImageNone
                                    : editBrandData.imgSmallBannerLink
                                }
                                className="img-fluid"
                              />
                            )
                          ) : editBrandData.imgSmallBanner ? (
                            <img
                              src={URL.createObjectURL(
                                editBrandData.imgSmallBanner
                              )}
                              className="img-fluid"
                            />
                          ) : null}
                          {editBrandData.imgSmallBannerLink === null &&
                            editBrandData.imgSmallBanner === null && (
                              <h6>No Image Selected</h6>
                            )}
                          <span
                            className="close_image"
                            onClick={() => onDeleteImage("imgSmallBanner")}
                          >
                            x
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input__block">
                  <label>{t("Video URL")}</label>
                  <input
                    type="text"
                    value={editBrandData.txtvideoUrl}
                    onChange={(e) => onEditChangeHandler(e, "txtvideoUrl")}
                  />
                  <a
                    className="linkText mt-2"
                    href="https://platform.stage.jewelcloud.com/uploadvideo.aspx"
                    target="_blank"
                  >
                    {t("How To Upload Video")}
                  </a>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input__block">
                  <label>{t("URL")}</label>
                  <input
                    type="text"
                    value={editBrandData.txtUrl}
                    onChange={(e) => onEditChangeHandler(e, "txtUrl")}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="input__block">
                  <label>
                    {t("Email")} <span className="text-red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className={
                      (editBrandData.txtEmail === undefined ||
                        editBrandData.txtEmail === "") &&
                      validations.EmailVal
                        ? "border__red"
                        : ""
                    }
                    value={editBrandData.txtEmail}
                    onChange={(e) => onEditChangeHandler(e, "txtEmail")}
                  />
                  {(editBrandData.txtEmail === undefined ||
                    editBrandData.txtEmail === "") &&
                  validations.EmailVal ? (
                    <div className="text-danger">{validations.EmailVal}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="input__block">
                  <label>{t("About Designer")} </label>
                  <textarea
                    value={editBrandData.txtaboutDesigner}
                    onChange={(e) => onEditChangeHandler(e, "txtaboutDesigner")}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="input__block">
                  <label>{t("Tag Line")}</label>

                  <input
                    type="text"
                    value={editBrandData.txttagLine}
                    onChange={(e) => onEditChangeHandler(e, "txttagLine")}
                  />
                  <p>{t("You can enter up to 100 characters.")}</p>
                </div>
              </div>
              {isedit && (
                <div className="col-md-12">
                  <h5>{t("Sample Showcase Preview")}</h5>
                  <div className="showcase-review-box">
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={
                            editBrandData.showCaseLogo
                              ? editBrandData.showCaseLogo
                              : null
                          }
                          alt="NO IMAGE AVAILABLE"
                        />
                      </div>
                      <div className="col-md-6">
                        <img
                          src={
                            editBrandData.showCaseImage
                              ? editBrandData.showCaseImage
                              : null
                          }
                          alt="NO IMAGE AVAILABLE"
                        />
                      </div>
                      <div className="col-md-12">
                        <div className="tag-line-bg mt-1">
                          <span>
                            {editBrandData.showCaseText
                              ? editBrandData.showCaseText
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal__addcolor"
        centered
        visible={DeleteVisible}
        onOk={() => setDeleteVisible(false)}
        onCancel={() => setDeleteVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={() => setDeleteVisible(false)}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onDeleteBrandData(DesignerId);
            }}
          >
            {t("Delete")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="subheading">
                      {t("Delete The ")}"{DesignerName}" {t("Brand")}
                    </p>
                    <p>{t("THIS CANNOT BE UNDONE")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Fullscreen Modal Starts */}
      <Modal
        title=""
        visible={isModalVisibleFullscreen}
        className=""
        onCancel={handleCancelFullscreen}
        footer={false}
        width={300}
      >
        <div className="thimage__slider text-center">
          <img src={RetailerLogo} alt="" />
        </div>
      </Modal>
      {/* Fullscreen Modal Ends */}
    </React.Fragment>
  );
};

export default Brands;
