import React, { useState, useEffect } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import SCAMasterService from "../../../services/setting-SCA.service";
import { Select, Button, Switch, Spin, Modal } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const string = "ABCDEFGHIJKLMNOPQRSTUVXYZ";
const lowerstring = "abcdefghijklmnopqrstuvwxyz";
const numeric = "0123456789";
const punctuation = "!@#$&}{[]:;?><,./-=";

const initialClientData = {
  companyName: "",
  streetAddress: "",
  city: "",
  state: "",
  zipcode: "",
  country: "",
  contactName: "",
  emailAddress: "",
  phoneNumber: "",
  username: "",
  password: "",
  permissionType: "",
  verified: true,
  retailerStatus: "",
  vendorStatus: "",
  tags: "",
  SCAvalue: "",
  receiverId: "",
};
const initialValidation = {
  companyNameVal: "",
  streetAddressVal: "",
  cityVal: "",
  stateVal: "",
  zipcodeVal: "",
  countryVal: "",
  contactNameVal: "",
  emailAddressVal: "",
  phoneNumberVal: "",
  usernameVal: "",
  passwordVal: "",
  permissionTypeVal: "",
  retailerStatusVal: "",
  vendorStatusVal: "",
  // scaVal: "",
};

export const CreateNewClient = (props) => {
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [clientData, setClientData] = useState(initialClientData);
  const [validations, setValidations] = useState(initialValidation);
  const [permisssionTypeMaster, setPermisssionTypeMaster] = useState([]);
  const [retailerStatusMaster, setRetailerStatusMaster] = useState([]);
  const [vendorStatusMaster, setVendorStatusMaster] = useState([]);
  const { Option } = Select;
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [locationDropdownListByCountry, setLocationDropdownListByCountry] =
    useState([]);
  const [locationDropdownListByState, setLocationDropdownListByState] =
    useState([]);
  const [locationDropdownListByCity, setLocationDropdownListByCity] = useState(
    []
  );
  const [permisssionTypeMasterLoading, setPermisssionTypeMasterLoading] =
    useState(false);
  const [retailerStatusMasterLoading, setRetailerStatusMasterLoading] =
    useState(false);
  const [vendorStatusMasterLoading, setVendorStatusMasterLoading] =
    useState(false);
  const [scaMasterLoading, setScaMasterLoading] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [isCompanyName, setIsCompanyName] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isUserName, setIsUserName] = useState(true);
  const [scaList, setScaList] = useState([]);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  useEffect(() => {
    GetPermisssionTypeMaster();
    GetRetailerStatusMaster();
    GetVendorStatusMaster();
    handleGetLocationDropdownDetailsByCountry();
    handleGetLocationDropdownDetailsByState(233, "State");
    handleGetSCAList();
    setClientData((prevdata) => ({
      ...prevdata,
      country: 233,
    }));
  }, []);

  const handleGetLocationDropdownDetailsByCountry = () => {
    let inputData = {
      id: 0,
      dropdownType: "Country",
    };
    setCountryListLoading(true);
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setLocationDropdownListByCountry(responseData);
            setClientData((prevState) => ({
              ...prevState,
              country: 233,
            }));
            setCountryListLoading(false);
          } else {
            setLocationDropdownListByCountry([]);
            setCountryListLoading(false);
          }
        } else {
          setLocationDropdownListByCountry([]);
          setCountryListLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setCountryListLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetLocationDropdownDetailsByState = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByState(responseData);
        } else {
          setLocationDropdownListByState([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetLocationDropdownDetailsByCity = (id, dropdownType) => {
    let inputData = {
      id: id,
      dropdownType: dropdownType,
    };
    AdminToolsDataService.GetLocationDropdownDetails(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setLocationDropdownListByCity(responseData);
        } else {
          setLocationDropdownListByCity([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Cascading Dropdown API ends
  const GetPermisssionTypeMaster = () => {
    setPermisssionTypeMasterLoading(true);
    AdminToolsDataService.GetPermisssionTypeMaster()
      .then((response) => {
        setPermisssionTypeMasterLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setPermisssionTypeMaster(responseData);
          setPermisssionTypeMasterLoading(false);
        } else {
          setPermisssionTypeMaster([]);
          setPermisssionTypeMasterLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPermisssionTypeMasterLoading(false);
      })
      .finally(() => {
        setPermisssionTypeMasterLoading(false);
      });
  };

  const GetRetailerStatusMaster = () => {
    setRetailerStatusMasterLoading(true);
    AdminToolsDataService.GetRetailerStatusMaster()
      .then((response) => {
        setRetailerStatusMasterLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerStatusMaster(responseData);
          setRetailerStatusMasterLoading(false);
        } else {
          setRetailerStatusMaster([]);
          setRetailerStatusMasterLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setRetailerStatusMasterLoading(false);
      })
      .finally(() => {
        setRetailerStatusMasterLoading(false);
      });
  };

  const GetVendorStatusMaster = () => {
    setVendorStatusMasterLoading(true);
    AdminToolsDataService.GetVendorStatusMaster()
      .then((response) => {
        setVendorStatusMasterLoading(false);
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorStatusMaster(responseData);
          setVendorStatusMasterLoading(false);
        } else {
          setVendorStatusMaster([]);
          setVendorStatusMasterLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setVendorStatusMasterLoading(false);
      })
      .finally(() => {
        setVendorStatusMasterLoading(false);
      });
  };
  const handleGetSCAList = () => {
    setScaMasterLoading(true);
    SCAMasterService.GetSCAList()
      .then((response) => {
        if (response.data.message === "Success") {
          setScaList(response.data.responseData || []);
          setScaMasterLoading(false);
        } else {
          NotificationManager.error(t("Fail To Fetch Data"));
          setScaMasterLoading(false);
        }
        setScaMasterLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setScaMasterLoading(false);
      });
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setClientData({
        ...clientData,
        [name]: e,
      });
      if (name === "country") {
        setValidations((prevdata) => ({
          ...prevdata,
          countryVal: "",
        }));
        setClientData((prevdata) => ({
          ...prevdata,
          state: "",
        }));

        handleGetLocationDropdownDetailsByState(e, "State");
      }
      if (name === "state") {
        setValidations((prevdata) => ({
          ...prevdata,
          stateVal: "",
        }));
        setClientData((prevdata) => ({
          ...prevdata,
          city: "",
        }));
        handleGetLocationDropdownDetailsByCity(e, "City");
      }
      if (name === "city") {
        setValidations((prevdata) => ({
          ...prevdata,
          cityVal: "",
        }));
      }
      if (name === "permissionType") {
        setValidations((prevdata) => ({
          ...prevdata,
          permissionTypeVal: "",
        }));
      }
      if (name === "retailerStatus") {
        setValidations((prevdata) => ({
          ...prevdata,
          retailerStatusVal: "",
        }));
      }
      if (name === "vendorStatus") {
        setValidations((prevdata) => ({
          ...prevdata,
          vendorStatusVal: "",
        }));
      }
      // if (name === "SCAvalue") {
      //   setValidations((prevdata) => ({
      //     ...prevdata,
      //     scaVal: "",
      //   }));
      // }
    } else {
      setClientData({
        ...clientData,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === "companyName") {
        setValidations((prevdata) => ({
          ...prevdata,
          companyNameVal: "",
        }));
      }
      if (e.target.name === "streetAddress") {
        setValidations((prevdata) => ({
          ...prevdata,
          streetAddressVal: "",
        }));
      }

      if (e.target.name === "zipcode") {
        setValidations((prevdata) => ({
          ...prevdata,
          zipcodeVal: "",
        }));
      }
      if (e.target.name === "contactName") {
        setValidations((prevdata) => ({
          ...prevdata,
          contactNameVal: "",
        }));
      }
      if (e.target.name === "emailAddress") {
        setValidations((prevdata) => ({
          ...prevdata,
          emailAddressVal: "",
        }));
      }
      if (e.target.name === "phoneNumber") {
        setValidations((prevdata) => ({
          ...prevdata,
          phoneNumberVal: "",
        }));
      }
      if (e.target.name === "username") {
        setValidations((prevdata) => ({
          ...prevdata,
          usernameVal: "",
        }));
      }
      if (e.target.name === "password") {
        setValidations((prevdata) => ({
          ...prevdata,
          passwordVal: "",
        }));
      }
    }
  };

  const handleGetEmailTemplatesById = () => {
    let inputParams = {
      DealerID: 0,
      TypeName: "NewAccount",
    };
    setContent("");
    setVisible(true);
    setLoading(true);
    AdminToolsDataService.GetHTMLMailTemplet(inputParams)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          if (responseData[0]) {
            var tempContent = responseData[0].emailBody;
            tempContent = tempContent.replace(
              "@@CopanyName",
              clientData.companyName
            );
            tempContent = tempContent.replace(
              "@@CompanyEmail",
              clientData.emailAddress
            );
            setContent(tempContent);
          }
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generatePassword = (e) => {
    e.preventDefault();
    let character = "";
    let password = "";
    while (password.length < 10) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(
        lowerstring.length * Math.random() * Math.random()
      );
      const entity4 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      character += string.charAt(entity1);
      character += numeric.charAt(entity2);
      character += lowerstring.charAt(entity3);
      character += punctuation.charAt(entity4);
      password = character;
    }
    password = password;
    setClientData((prevState) => ({
      ...prevState,
      password: password.substring(0, 10),
    }));
  };

  const handleSubmit = async (e) => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    } else {
      if (!isCompanyName && !isEmail && !isUserName) {
        handleGetEmailTemplatesById();
      }
    }
  };

  const handleCheckDealerCompanyName = () => {
    if (!clientData.companyName) {
      return false;
    }
    let inputData = {
      companyName: clientData.companyName,
    };
    setLoading(true);
    AdminToolsDataService.CheckDealerCompanyName(inputData)
      .then((response) => {
        var checkMessage = response.data.responseData.isCompanyExist;
        setLoading(false);
        setValidations((prevState) => ({
          ...prevState,
          companyNameVal: checkMessage ? t("Company name is Taken") : "",
        }));
        setIsCompanyName(checkMessage);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckDealerEmail = async () => {
    try {
      if (!clientData.emailAddress) {
        return false;
      }
      let inputData = {
        email: clientData.emailAddress,
      };

      var isValid = true;
      var emailAddressVal = "";
      var validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!clientData.emailAddress.trim()) {
        isValid = false;
        emailAddressVal = t("Email address is compulsory");
      } else if (!clientData.emailAddress.match(validRegex)) {
        isValid = false;
        emailAddressVal = t("Email address is invalid");
      } else {
        emailAddressVal = "";
      }
      if (!isValid) {
        setValidations({ ...validations, emailAddressVal });
        return false;
      } else {
        setLoading(true);
        await AdminToolsDataService.CheckDealerEmail(inputData)
          .then((response) => {
            var checkMessage = response.data.responseData.isEmailExist;
            setLoading(false);
            setValidations((prevState) => ({
              ...prevState,
              emailAddressVal: checkMessage ? "Email is Taken" : "",
            }));
            setIsEmail(checkMessage);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  const handleCheckDealerUser = () => {
    try {
      if (!clientData.username) {
        return false;
      }
      let inputData = {
        userName: clientData.username,
      };
      setLoading(true);
      var isValid = true;
      AdminToolsDataService.CheckDealerUser(inputData)
        .then((response) => {
          var checkMessage = response.data.responseData.isUserNameExist;
          setLoading(false);
          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            usernameVal: checkMessage && t("Username is Taken"),
          }));
          setIsUserName(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setIsEmail(false);
    setIsCompanyName(false);
    setIsUserName(false);
  };

  function onSearch(val) {}

  const handleValidation = () => {
    const {
      companyName,
      streetAddress,
      city,
      state,
      zipcode,
      country,
      contactName,
      emailAddress,
      phoneNumber,
      username,
      password,
      permissionType,
      verified,
      retailerStatus,
      vendorStatus,
    } = clientData;
    const validations = {
      companyNameVal: "",
      streetAddressVal: "",
      cityVal: "",
      stateVal: "",
      zipcodeVal: "",
      countryVal: "",
      contactNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      usernameVal: "",
      passwordVal: "",
      permissionTypeVal: "",
      retailerStatusVal: "",
      vendorStatusVal: "",
    };
    let isValid = true;

    if (!companyName.trim()) {
      isValid = false;
      validations.companyNameVal = t("Company name is compulsory");
    } else {
      validations.companyNameVal = "";
    }

    if (!streetAddress.trim()) {
      isValid = false;
      validations.streetAddressVal = t("Street address is compulsory");
    } else {
      validations.streetAddressVal = "";
    }
    if (locationDropdownListByCity.length > 0) {
      if (!city) {
        isValid = false;
        validations.cityVal = t("City is compulsory");
      } else {
        validations.cityVal = "";
      }
    }

    if (locationDropdownListByState.length > 0) {
      if (!state) {
        isValid = false;
        validations.stateVal = t("State is compulsory");
      } else {
        validations.stateVal = "";
      }
    }

    if (!country) {
      isValid = false;
      validations.countryVal = t("Kindly select the Country");
    } else {
      validations.countryVal = "";
    }

    if (!zipcode.trim()) {
      isValid = false;
      validations.zipcodeVal = t("Zip code is compulsory");
    } else {
      validations.zipcodeVal = "";
    }

    if (!contactName.trim()) {
      isValid = false;
      validations.contactNameVal = t("Contact name is compulsory");
    } else {
      validations.contactNameVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = t("Email address is compulsory");
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = t("Email address is invalid");
    } else {
      validations.emailAddressVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = t("Phone number is compulsory");
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^[ ()+]*([0-9][ ()+]*){10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = t("Phone number is invalid");
    } else {
      validations.phoneNumberVal = "";
    }
    if (!username.trim()) {
      isValid = false;
      validations.usernameVal = t("Username is compulsory");
    } else {
      validations.usernameVal = "";
    }

    const strongRegex =
      /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#?:$%^&*}{,/-])(?=.{6,15})/;

    if (!password) {
      isValid = false;
      validations.passwordVal = t("Password is compulsory");
    } else if (!password.match(strongRegex)) {
      isValid = false;
      validations.passwordVal = t(
        "Password Must contain Minimum 6 characters, Maximum 15 characters, at least one uppercase letter, one number and one special character and should not contain blank spaces"
      );
    } else if (password.length > 15) {
      isValid = false;
      validations.passwordVal = t(
        "Password Must contain Minimum 6 characters, Maximum 15 characters, at least one uppercase letter, one number and one special character and should not contain blank spaces"
      );
    } else {
      validations.passwordVal = "";
    }
    if (!permissionType) {
      isValid = false;
      validations.permissionTypeVal = t("Kindly select the permission type");
    } else {
      validations.permissionTypeVal = "";
    }

    if (permissionType == 2) {
      if (!retailerStatus) {
        isValid = false;
        validations.retailerStatusVal = t("Kindly select the retailer status");
      } else {
        validations.retailerStatusVal = "";
      }
    }
    if (permissionType == 1 || permissionType == 3) {
      if (!vendorStatus) {
        isValid = false;
        validations.vendorStatusVal = t("Kindly select the vendor status");
      } else {
        validations.vendorStatusVal = "";
      }
    }
    // if (permissionType == 1) {
    //   if (!SCAvalue) {
    //     isValid = false;
    //     validations.scaVal = t("Kindly select the SCA");
    //   } else {
    //     validations.scaVal = "";
    //   }
    // }

    setValidations(validations);
    return isValid;
  };

  const handleSaveClientDetails = () => {
    const {
      companyName,
      streetAddress,
      city,
      state,
      zipcode,
      country,
      contactName,
      emailAddress,
      phoneNumber,
      username,
      password,
      permissionType,
      verified,
      retailerStatus,
      vendorStatus,
    } = clientData;
    setVisible(false);

    let inputData = {
      dealerId: dealerId,
      companyName: companyName,
      streetAddress: streetAddress,
      cityId: 0,
      stateId: 0,
      dealerCity: city.toString(),
      dealerState: state.toString(),
      zipCode: zipcode,
      countryId: country,
      contactName: contactName,
      emailAddress: emailAddress,
      phoneNumber: phoneNumber,
      userName: username,
      password: password,
      permissionTypeId: permissionType,
      isVerified: verified,
      retailerStatusId: retailerStatus == "" ? 0 : retailerStatus,
      vendorStatusId: vendorStatus == "" ? 0 : vendorStatus,
      ipAddress: "noneknown",
      logInUser: 0,
      logInUserName: "datapending",
      mailBody: content,
      mode: "Test",
      isRegister: false,
      scaId: Array.isArray(SCAvalue) ? SCAvalue.join(",") : "",
      recvid: receiverId,
    };
    setLoading(true);
    setMainLoader(true);
    AdminToolsDataService.CreateNewClient(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          setLoading(false);
          handleCancel();
          setClientData(initialClientData);
          setValidations(initialValidation);
          setMainLoader(false);
          NotificationManager.success(t("New Client Added Successfully."));
        } else {
          NotificationManager.success(t("New Client Not Added."));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setMainLoader(false);
      })
      .finally(() => {
        setLoading(false);
        setMainLoader(false);
      });
  };

  const {
    companyName,
    streetAddress,
    city,
    state,
    zipcode,
    country,
    contactName,
    emailAddress,
    phoneNumber,
    username,
    password,
    permissionType,
    verified,
    retailerStatus,
    vendorStatus,
    SCAvalue,
    receiverId,
  } = clientData;
  const {
    companyNameVal,
    streetAddressVal,
    cityVal,
    stateVal,
    zipcodeVal,
    countryVal,
    contactNameVal,
    emailAddressVal,
    phoneNumberVal,
    usernameVal,
    passwordVal,
    permissionTypeVal,
    retailerStatusVal,
    vendorStatusVal,
  } = validations;
  return (
    <div className="form__fields CreatNewClientChanges">
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0">{t("Add New Client")}</h4>
          </div>
        </div>
        <Spin spinning={mainLoader}>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Company Name")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  placeholder={t("Enter Company Name")}
                  name="companyName"
                  autocomplete="off"
                  value={companyName}
                  onChange={handleOnChange}
                  className={companyNameVal && "border__red"}
                  onBlur={handleCheckDealerCompanyName}
                />
                <div>
                  {companyNameVal && (
                    <p className="error-color-red">{companyNameVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Street Address")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  placeholder={t("Enter Street Address")}
                  name="streetAddress"
                  autocomplete="off"
                  value={streetAddress}
                  onChange={handleOnChange}
                  className={streetAddressVal && "border__red"}
                />
                <div>
                  {streetAddressVal && (
                    <p className="error-color-red">{streetAddressVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Country")}
                  <span className="mandatory">*</span>
                </label>

                <Select
                  showSearch
                  name="country"
                  placeholder={t("Select Country")}
                  value={country ? country : null}
                  optionFilterProp="children"
                  autocomplete="off"
                  onChange={(e) => handleOnChange(e, "select", "country")}
                  onSearch={onSearch}
                  className={countryVal ? "border__red" : "border__grey"}
                  loading={countryListLoading}
                >
                  <Option>Select Country</Option>
                  {locationDropdownListByCountry.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                <div>
                  {countryVal && (
                    <p className="error-color-red">{countryVal}</p>
                  )}
                </div>
              </div>
            </div>

            {country && (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {t("State")}
                    <span className="mandatory">*</span>
                  </label>

                  <Select
                    showSearch
                    name="state"
                    placeholder={t("Select State")}
                    value={state ? state : null}
                    autocomplete="off"
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "state")}
                    onSearch={onSearch}
                    className={stateVal ? "border__red" : "border__grey"}
                  >
                    {locationDropdownListByState.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                  <div>
                    {stateVal && (
                      <p className="error-color-red">{validations.stateVal}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {state && (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {t("City")}
                    <span className="mandatory">*</span>
                  </label>

                  <Select
                    showSearch
                    name="city"
                    placeholder={t("Select City")}
                    value={city ? city : null}
                    autocomplete="off"
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "city")}
                    onSearch={onSearch}
                    className={cityVal ? "border__red" : "border__grey"}
                  >
                    {locationDropdownListByCity.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                  <div>
                    {cityVal && <p className="error-color-red">{cityVal}</p>}
                  </div>
                </div>
              </div>
            )}

            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Zip Code")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  placeholder={t("Enter Zip Code")}
                  name="zipcode"
                  autocomplete="off"
                  value={zipcode}
                  onChange={handleOnChange}
                  className={zipcodeVal && "border__red"}
                />
                <div>
                  {zipcodeVal && (
                    <p className="error-color-red">{zipcodeVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Contact Name")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  placeholder={t("Enter Contact Name")}
                  name="contactName"
                  autocomplete="off"
                  value={contactName}
                  onChange={handleOnChange}
                  className={contactNameVal && "border__red"}
                />
                <div>
                  {contactNameVal && (
                    <p className="error-color-red">{contactNameVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Email Address")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="email"
                  placeholder={t("Enter Email Address")}
                  name="emailAddress"
                  autocomplete="off"
                  value={emailAddress}
                  onChange={handleOnChange}
                  className={emailAddressVal && "border__red"}
                  onBlur={handleCheckDealerEmail}
                />
                <div>
                  {emailAddressVal && (
                    <p className="error-color-red">{emailAddressVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Phone Number")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="tel"
                  placeholder={t("Enter Phone Number")}
                  autocomplete="off"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleOnChange}
                  className={phoneNumberVal && "border__red"}
                />
                <div>
                  {phoneNumberVal && (
                    <p className="error-color-red">{phoneNumberVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Account Type")}
                  <span className="mandatory">*</span>{" "}
                </label>
                <Select
                  value={permissionType ? permissionType : null}
                  name="permissionType"
                  placeholder={t("Select Type")}
                  onChange={(e) =>
                    handleOnChange(e, "select", "permissionType")
                  }
                  autocomplete="off"
                  showSearch
                  optionFilterProp="children"
                  className={permissionTypeVal ? "border__red" : "border__grey"}
                  loading={permisssionTypeMasterLoading}
                >
                  <Option value=""> {t("Select Type")} </Option>
                  {permisssionTypeMaster.map((item) => {
                    return (
                      <Option value={item.membeshipTypeId}>
                        {item.membeshipTypeText == "VendorDiamond"
                          ? "Vendor-Diamond"
                          : item.membeshipTypeText}
                      </Option>
                    );
                  })}
                </Select>
                <div>
                  {permissionTypeVal && (
                    <p className="error-color-red">{permissionTypeVal}</p>
                  )}
                </div>
              </div>
            </div>
            {permissionType == 1 || permissionType == 3 ? null : (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {t("Retailer Status")}
                    <span className="mandatory"></span>{" "}
                  </label>
                  <Select
                    value={retailerStatus ? retailerStatus : null}
                    name="retailerStatus"
                    showSearch
                    placeholder={t("Select Retailer Status ")}
                    optionFilterProp="children"
                    onChange={(e) =>
                      handleOnChange(e, "select", "retailerStatus")
                    }
                    autocomplete="off"
                    className={
                      retailerStatusVal ? "border__red" : "border__grey"
                    }
                    loading={retailerStatusMasterLoading}
                  >
                    <Option value=""> {t("Select Retailer Status")} </Option>
                    {retailerStatusMaster.map((item) => {
                      return (
                        <Option value={item.statusId}>{item.statusText}</Option>
                      );
                    })}
                  </Select>
                  <div>
                    {retailerStatusVal && (
                      <p className="error-color-red">{retailerStatusVal}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            {permissionType !== 2 ? (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {t("Vendor Status")}
                    <span className="mandatory"></span>{" "}
                  </label>
                  <Select
                    value={vendorStatus ? vendorStatus : null}
                    autocomplete="off"
                    name="vendorStatus"
                    showSearch
                    placeholder={t("Select Vendor Status")}
                    optionFilterProp="children"
                    onChange={(e) =>
                      handleOnChange(e, "select", "vendorStatus")
                    }
                    className={vendorStatusVal ? "border__red" : "border__grey"}
                    loading={vendorStatusMasterLoading}
                  >
                    <Option value=""> {t("Select Vendor Status")} </Option>
                    {vendorStatusMaster.map((item) => {
                      return (
                        <Option value={item.statusId}>{item.statusText}</Option>
                      );
                    })}
                  </Select>
                  <div>
                    {vendorStatusVal && (
                      <p className="error-color-red">{vendorStatusVal}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {permissionType == 1 ? (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {t("Standard Carrier Alpha")}
                    <span className="mandatory"></span>{" "}
                  </label>
                  <Select
                    mode="multiple"
                    value={SCAvalue || []}
                    autocomplete="off"
                    name="SCAvalue"
                    showSearch
                    placeholder={t("Select SCA")}
                    optionFilterProp="children"
                    onChange={(e) => handleOnChange(e, "select", "SCAvalue")}
                    className={"border__grey"}
                    loading={scaMasterLoading}
                  >
                    {scaList.map((item) => {
                      return <Option value={item.id}>{item.scaccode}</Option>;
                    })}
                  </Select>
                  {/* <div>
                    {scaVal && <p className="error-color-red">{scaVal}</p>}
                  </div> */}
                </div>
              </div>
            ) : null}
            {permissionType == 1 ? (
              <div className="col-lg-4 col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {t("EDI Receiver Id")}
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <input
                    type="text"
                    placeholder={t("EDI Receiver Id")}
                    name="receiverId"
                    value={receiverId}
                    autocomplete="off"
                    onChange={handleOnChange}
                    // className={usernameVal && "border__red"}
                  />
                  {/* <div>
                    {usernameVal && (
                      <p className="error-color-red">{usernameVal}</p>
                    )}
                  </div> */}
                </div>
              </div>
            ) : null}

            <div className="col-lg-4 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Username")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  placeholder={t("Enter Username")}
                  name="username"
                  value={username}
                  autocomplete="off"
                  onChange={handleOnChange}
                  className={usernameVal && "border__red"}
                  onBlur={handleCheckDealerUser}
                />
                <div>
                  {usernameVal && (
                    <p className="error-color-red">{usernameVal}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Password")}
                  <span className="mandatory">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      name="password"
                      type="text"
                      autocomplete="new-password"
                      placeholder={t("Enter Password")}
                      value={password}
                      onChange={handleOnChange}
                      className={passwordVal && "border__red"}
                    />
                    <div>
                      {passwordVal && (
                        <p className="error-color-red">{passwordVal}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="primary-btn mobilebtn"
                      onClick={generatePassword}
                    >
                      {t("Generate Password")}
                    </button>
                  </div>
                  <div className="col-md-3">
                    <div className="input__block radio__block">
                      <label className="mr-2">{t("Verified")}</label>
                      <Switch
                        name="verified"
                        checkedChildren={t("Yes")}
                        unCheckedChildren={t("No")}
                        checked={verified}
                        onChange={(e) =>
                          handleOnChange(e, "select", "verified")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <div className="col-md-6">
          <Button onClick={handleSubmit} className="primary-btn">
            {t("Create New Client")}
          </Button>
        </div>
        <div className="col-lg-12">
          <Modal
            title={t("Send Email")}
            className="modalvdiamond__section"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => handleCancel()}
            width={600}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {t("Cancel")}
              </Button>,
              <Button key="Save" onClick={handleSaveClientDetails}>
                {t("Save")}
              </Button>,
            ]}
          >
            <div className="editor__section">
              <Spin spinning={loading}>
                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />
              </Spin>
            </div>
          </Modal>
        </div>
      </Spin>
    </div>
  );
};

export default CreateNewClient;
