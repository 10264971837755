import {
  SET_HEADERMENU_DATA,
  SET_IMPERSONATE_HEADERMENU_DATA,
  SET_ADMIN_HEADERMENU_DATA,
  SET_CHECKLINK_HEADERMENU_DATA,
  SET_OPENKEY_HEADERMENU_DATA,
  RESET_STATE,
  SELECT_SIDE_MENU,
  EDIT_THIRD_MENU,
  EDIT_FOURTIER_MENU,
  OLD_URL_ARRAY,
  ADMIN_MENU_ARRAY,
  CONTACT_PAGE_NAME_TYPE,
  RESET_IFRAME,
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_VENDOR,
} from "../../actions/headerMenu/types";
const initialState = {
  headerMenuDetials: null,
  sideMenuTierTwoDetails: [],
  sideMenuTierThreeDetails: [],
  sideMenuTierFourDetails: [],
  checkLink: "",
  openKey: [],
  reportPermisstion: {},
  applicationPermisstionResponse: {},
  selectSideMenu: [],
  url: [],
  ToogleValueProduct: "",
  ToogleCollectionValue: "",
  AdminMenuDetails: null,
  pageNameType: "Customer",
  isResetIframe: true,
  language: "en",
  vendorSelected: "",
};
function headerMenuReducer(headerMenuState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_HEADERMENU_DATA:
      let oneTierPageName = {};
      let pageName = "notAccess";

      if (payload?.reportPermisstion[0]?.diamondCharts == true) {
        oneTierPageName = payload.oneTierPageName;
      } else {
        if (payload?.reportPermisstion[0]?.jewelryCharts == true) {
          pageName = "jewelryCategoryReport";
        } else {
          if (payload?.reportPermisstion[0]?.globalDiamondCharts == true) {
            pageName = "globalDiamondCertificateReport";
          } else {
            if (payload?.reportPermisstion[0]?.globalJewelryCharts == true) {
              pageName = "globalJewelryCategoryReport";
            }
          }
        }
        oneTierPageName = payload.oneTierPageName.map((item) => {
          if (item.oneTierPageName == "Reports") {
            return {
              ...item,
              navigateTo: pageName,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...headerMenuState,
        headerMenuDetials: oneTierPageName,
        sideMenuTierTwoDetails: payload.twoTierPageName,
        sideMenuTierThreeDetails: payload.threeTierPageName,
        sideMenuTierFourDetails: payload.fourTierPageName,
        reportPermisstion: payload.reportPermisstion,
        applicationPermisstionResponse: payload.applicationPermisstionResponse,
      };
    case ADMIN_MENU_ARRAY:
      return {
        ...headerMenuState,
        AdminMenuDetails: payload,
      };

    case SET_IMPERSONATE_HEADERMENU_DATA:
      return { ...headerMenuState, headerMenuDetials: payload.oneTierPageName };
    case SET_ADMIN_HEADERMENU_DATA:
      return {
        ...headerMenuState,
        headerMenuDetials: payload.oneTierPageName,
        sideMenuTierTwoDetails: payload.twoTierPageName,
        sideMenuTierThreeDetails: payload.threeTierPageName,
        sideMenuTierFourDetails: payload.fourTierPageName,
        reportPermisstion: payload.reportPermisstion,
        applicationPermisstionResponse: payload.applicationPermisstionResponse,
      };
    case SET_CHECKLINK_HEADERMENU_DATA:
      return {
        ...headerMenuState,
        checkLink: payload,
        openKey: payload == "Admin" ? ["Tools"] : [],
      };
    case SET_OPENKEY_HEADERMENU_DATA:
      return { ...headerMenuState, openKey: payload };
    case RESET_STATE:
      return {
        ...headerMenuState,
        headerMenuDetials: null,
        sideMenuTierTwoDetails: null,
        sideMenuTierThreeDetails: null,
        sideMenuTierFourDetails: null,
        checkLink: "",
        openKey: [],
        reportPermisstion: {},
        applicationPermisstionResponse: {},
        selectSideMenu: [],
      };
    case SELECT_SIDE_MENU:
      return { ...headerMenuState, selectSideMenu: payload };
    case EDIT_FOURTIER_MENU:
      return { ...headerMenuState, ToogleValueProduct: payload };
    case EDIT_THIRD_MENU:
      return { ...headerMenuState, ToogleCollectionValue: payload };

    case SELECT_SIDE_MENU:
      return { ...headerMenuState, selectSideMenu: payload };
    // case CONTACT_PAGE_NAME_TYPE:
    //   return { ...headerMenuState, pageNameType: payload };
    case OLD_URL_ARRAY:
      return {
        ...headerMenuState,
        url: payload,
      };
    //To Reset Iframe
    case RESET_IFRAME:
      return {
        ...headerMenuState,
        isResetIframe: payload,
      };
    case SET_SELECTED_LANGUAGE:
      return {
        ...headerMenuState,
        language: payload,
      };
    case SET_SELECTED_VENDOR:
      return {
        ...headerMenuState,
        vendorSelected: payload,
      };
    default:
      return headerMenuState;
  }
}
export default headerMenuReducer;
