import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import RetailerLogo from "../../assets/images/retailer-logo.jpeg";

const RingBuilderRetailerPermission = () => {
  const { t } = useTranslation();
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const [MyRetailerData, setMyRetailerData] = useState([
    {
      // key: "1",
      id: 1,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      // key: "2",
      id: 2,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      //key: "3",
      id: 3,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      // key: "4",
      id: 4,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
  ]);

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleOnEditChange = (row, e, name) => {
    if (name === "jewelry") {
      const temp = MyRetailerData.map((x) => {
        if (x.id === row.id) {
          return { ...x, jewelry: e.target.checked };
        } else return x;
      });
      setMyRetailerData(temp);
    }
    if (name === "porder") {
      const temp = MyRetailerData.map((x) => {
        if (x.id === row.id) {
          return { ...x, porder: e.target.checked };
        } else return x;
      });
      setMyRetailerData(temp);
    }
    if (name === "jewelryapi") {
      const temp = MyRetailerData.map((x) => {
        if (x.id === row.id) {
          return { ...x, jewelryapi: e.target.checked };
        } else return x;
      });
      setMyRetailerData(temp);
    }
  };

  const columns1 = [
    {
      title: t("Store Name"),
      dataIndex: "storename",
    },
    {
      title: t("Retailer Type"),
      dataIndex: "retailertype",
    },
    {
      title: t("City"),
      dataIndex: "city",
    },
    {
      title: t("State"),
      dataIndex: "state",
    },
    {
      title: t("Requested Date"),
      dataIndex: "requesteddate",
    },
  ];

  const data1 = [
    {
      key: "1",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
    },
    {
      key: "2",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
    },
    {
      key: "3",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
    },
    {
      key: "4",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion permission__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {t("Ring Builder Retailer & Permission")}{" "}
                  </h4>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="myretailer"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                    >
                      <Tab
                        eventKey="myretailer"
                        title={t("My Retailer")}
                      >
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <Table
                                  const
                                  columns={[
                                    {
                                      title: t("Store Name"),
                                      dataIndex: "storename",
                                      key: "storename",
                                    },
                                    {
                                      title: t("Retailer Type"),
                                      dataIndex: "retailertype",
                                      key: "retailertype",
                                    },
                                    {
                                      title: t("City"),
                                      dataIndex: "city",
                                      key: "city",
                                    },
                                    {
                                      title: t("State"),
                                      dataIndex: "state",
                                      key: "state",
                                    },
                                    {
                                      title: t("Requested Date"),
                                      dataIndex: "requesteddate",
                                      key: "requesteddate",
                                    },
                                    {
                                      title: t("Jewelry"),
                                      dataIndex: "jewelry",
                                      key: "jewelry",
                                      render: (item, row) => {
                                        return (
                                          <Checkbox
                                            checked={row.jewelry}
                                            onChange={(e) =>
                                              handleOnEditChange(
                                                row,
                                                e,
                                                "jewelry"
                                              )
                                            }
                                          />
                                        );
                                      },
                                    },
                                    {
                                      title: t("Purchase Order"),
                                      dataIndex: "porder",
                                      key: "porder",
                                      render: (item, row) => {
                                        return (
                                          <Checkbox
                                            checked={row.porder}
                                            onChange={(e) =>
                                              handleOnEditChange(
                                                row,
                                                e,
                                                "porder"
                                              )
                                            }
                                          />
                                        );
                                      },
                                    },
                                    {
                                      title: t("Jewelry API"),
                                      dataIndex: "jewelryapi",
                                      key: "porder",
                                      render: (item, row) => {
                                        return (
                                          <Checkbox
                                            checked={row.jewelryapi}
                                            onChange={(e) =>
                                              handleOnEditChange(
                                                row,
                                                e,
                                                "jewelryapi"
                                              )
                                            }
                                          />
                                        );
                                      },
                                    },
                                  ]}
                                  dataSource={MyRetailerData}
                                  scroll={{ x: 600, y: 800 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="allretailer"
                        title={t("All Retailer")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <Table
                                columns={columns1}
                                dataSource={data1}
                                scroll={{ x: 600, y: 800 }}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="right__section">
                    <div className="profile__block">
                      <a href="#" className="retailer__name">
                        {" "}
                        {t("Gemfind")}{" "}
                      </a>
                      <div className="logoImg__block p-0">
                        <img src={RetailerLogo} />
                      </div>
                      <p className="activeFrom">
                        {" "}
                        {t("Active")} | 10/08/2020 @ 12:42PM
                      </p>
                    </div>

                    <div className="retailer__dtls mb-4">
                      <p>Alex Fetanat</p>
                      <p>info@gemfind.com</p>
                      <p>(800)373-4373</p>
                      <a href="http://www.gemfind.com">
                        http://www.gemfind.com
                      </a>
                      <hr />
                    </div>

                    <div className="links__block">
                      <ul>
                        <li>
                          <a href="#">
                            <i
                              class="fa fa-address-book-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {t("View Profile")}{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                            {t("Conversation")}{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i
                              class="fa fa-calendar-check-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {t("Make Appointment")}{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i
                              class="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {t("Store Notes")}{" "}
                          </a>
                        </li>
                      </ul>
                      <hr />
                    </div>

                    <div className="bottom__block">
                      <div className="input__block">
                        <label> {t("Sales Rep")} </label>
                        <Select
                          className="border__grey"
                          placeholder={t("Sales Rep")}
                          name="productType"
                          optionFilterProp="children"
                        >
                          <Option value="Option 1">
                            {" "}
                            {t("Option 1")}{" "}
                          </Option>
                          <Option value="Option 2">
                            {" "}
                            {t("Option 2")}{" "}
                          </Option>
                        </Select>
                      </div>

                      <div className="input__block">
                        <label> {t("Retailer Type")} </label>
                        <Select
                          className="border__grey"
                          placeholder= {t("Retailer Type")}
                          name="productType"
                          optionFilterProp="children"
                        >
                          <Option value="Option 1"> {t("All")} </Option>
                          <Option value="Option 2">
                            {" "}
                            {t("Jewelry")}{" "}
                          </Option>
                          <Option value="Option 2">
                            {" "}
                            {t("Diamond")}{" "}
                          </Option>
                        </Select>
                      </div>

                      <div className="input__block">
                        <label> {t("Disconnect Retailer")} </label>
                        <Switch
                          checkedChildren={t("Yes")}
                          unCheckedChildren={t("No")}
                          defaultChecked
                          onChange={handleSwitch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RingBuilderRetailerPermission;
