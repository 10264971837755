import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";

import Login from "./component/login/login";
import Register from "./component/login/Register";
import ClientStatus from "./component/admin/tools/clientStatus";
import CreateNewClient from "./component/admin/tools/createNewClient";
import Catlog from "./component/Catlog/Catlog";
import Layout from "./component/main/layout";
import CatlogList from "./component/Catlog/CatlogList";
import Dashboard from "./component/dashboard/Dashboard";
import UpdateInventoryImport from "./component/dashboard/UpdateInventoryImport";
import Allproducts from "./component/All Products/Allproducts";
import SharedProduct from "./component/Catlog/SharedProduct";
import Appointment from "./component/dashboard/Appointment";
import ProductDetails from "./component/All Products/ProductDetails";
import Administrationtools from "./component/admin/tools/administrationtools";
import ApplicationPermissions from "./component/admin/tools/ApplicationPermissions";
import DiamondRapNetPermission from "./component/admin/tools/DiamondRapNetPermission";
import AddNewRetailerToCore from "./component/admin/tools/AddNewRetailerToCore";
import VendorRatings from "./component/admin/tools/VendorRatings";
import ClientDataMapping from "./component/admin/tools/ClientDataMapping";
import Limits from "./component/admin/tools/Limits";
import Legacy from "./component/admin/tools/Legacy";
import ReportsPermissions from "./component/admin/tools/ReportsPermissions";
import Retailerlocator from "./component/admin/tools/Retailerlocator";
import BlockDesignersRetailer from "./component/admin/tools/BlockDesignersRetailer";
import DataMaps from "./component/admin/settings/DataMaps";
import MetalMarket from "./component/admin/settings/MetalMarket";
import LoginPreferences from "./component/admin/tools/LoginPreferences";
import Permissions from "./component/admin/settings/Permissions";
import EmailTemplate from "./component/admin/settings/EmailTemplate";
import MagentoMapping from "./component/admin/tools/MagentoMapping";
import ConsoleUpload from "./component/admin/tools/ConsoleUpload";
import TransferZZGemfind from "./component/admin/tools/TransferZZGemfind";
import TradeShows from "./component/admin/settings/TradeShows";
import MailingLists from "./component/admin/campaigns/MailingLists";
import CampaignData from "./component/admin/campaigns/CampaignData";
import ComposeMail from "./component/admin/campaigns/ComposeMail";
import ApiAccessToken from "./component/admin/settings/ApiAccessToken";
import Addcolor from "./component/admin/settings/Addcolor";
import UploadHistory from "./component/Catlog/UploadHistory";
import DiamondUploadHistory from "./component/Catlog/Diamonds/DiamondUploadHistory";
import UploadDiamondFile from "./component/Catlog/Diamonds/UploadDiamondFile";
import UploadProductFile from "./component/Catlog/UploadProductFile";
import ManageCollection from "./component/Catlog/ManageCollection";
import AddSignleDiamond from "./component/Catlog/Diamonds/AddSingleDiamond";
import DiamondMapping from "./component/Catlog/Diamonds/DiamondMapping";
import ProductMarkup from "./component/Catlog/ProductMarkup";
import AddCollection from "./component/Catlog/AddCollection";
import ViewMyMinedDiamond from "./component/Catlog/Diamonds/ViewMyMinedDiamond";
import ViewMyColorDiamond from "./component/Catlog/Diamonds/ViewMyColorDiamond";
import ViewMyLabDiamond from "./component/Catlog/Diamonds/ViewMyLabDiamond";
import JewelrySearch from "./component/SearchFeature/JewelrySearch";
import WatchesSearch from "./component/SearchFeature/WatchesSearch";

import DiamondSearch from "./component/SearchFeature/DiamondSearch";
import ColorDiamondSearch from "./component/SearchFeature/ColorDiamondSearch";
import LabDiamondSearch from "./component/SearchFeature/LabDiamondSearch";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import FTPCredential from "./component/Catlog/Diamonds/FTPCredential";
import FancyColorMarkup from "./component/Apps/DiamondLinks/FancyColorMarkup";
import LabGrowMarkup from "./component/Apps/DiamondLinks/LabGrowMarkup";
import Options from "./component/Apps/DiamondLinks/Options";
import DiamondLinksEmailTemplate from "./component/Apps/DiamondLinks/DiamondLinksEmailTemplate";
import ApplicationPreview from "./component/Apps/DiamondLinks/ApplicationPreview";
import Shipping from "./component/Apps/DiamondLinks/Shipping";
import Navigation from "./component/Apps/DiamondLinks/Navigation";
import DiamondLinkLegacy from "./component/Apps/DiamondLinks/DiamondLinkLegacy";
import ShoppingIntegrations from "./component/Apps/DiamondLinks/ShoppingIntegrations";
import Dealers from "./component/Apps/DiamondLinks/Dealers";
import MinedDiamondsMarkup from "./component/Apps/DiamondLinks/MinedDiamondMarkup";
import Markups from "./component/Apps/RingBuilder/Markups";
import Settings from "./component/Apps/RingBuilder/Settings";
import RingbuilderNavigation from "./component/Apps/RingBuilder/RingbuilderNavigation";
import RingbuilderLegacy from "./component/Apps/RingBuilder/RingbuilderLegacy";
import RingbuilderShoppingIntegrations from "./component/Apps/RingBuilder/RingbuilderShoppingIntegrations";
import AvailableFilterSettings from "./component/Apps/DiamondLinks/AvailableFilterSettings";
import InitialFilterSettings from "./component/Apps/DiamondLinks/InitialFilterSettings";
import RingBuilderOptions from "./component/Apps/RingBuilder/Options";
import RingBuilderEmailTemplate from "./component/Apps/RingBuilder/EmailTemplate";
import RingBuilderDealers from "./component/Apps/RingBuilder/RingBuilderDealers";
import Vendors from "./component/Apps/RingBuilder/Vendors";
import MyNetworkStudBuilder from "./component/Apps/StudBuilder/MyNetworkStudBuilder";
import MyNetworkPendantBuilder from "./component/Apps/PendantBuilder/MyNetworkPendantBuilder";
import StudBuilderManageVendors from "./component/Apps/StudBuilder/StudBuilderManageVendors";
import ArchiveVendors from "./component/Apps/RingBuilder/ArchiveVendors";
import RingBuilderDaimondMarkup from "./component/Apps/RingBuilder/RingBuilderDaimondMarkup";
import RingBuilderLabGrowMarkup from "./component/Apps/RingBuilder/RingBuilderLabGrowMarkup";
import MasterLinkLegacy from "./component/Apps/MasterLink/MasterLinkLegacy";
import OutGoingDataMap from "./component/admin/tools/OutGoingDataMap";
import OutBoundSync from "./component/admin/tools/OutBoundSync";
import PendingSync from "./component/admin/tools/PendingSync";
import StudBuilderApplicationPreview from "./component/Apps/StudBuilder/StudBuilderApplicationPreview";
import StudBuilderEmailTemplate from "./component/Apps/StudBuilder/StudBuilderEmailTemplate";
import MasterLinkOptions from "./component/Apps/MasterLink/MasterLinkOptions";
import MasterLinkShipping from "./component/Apps/MasterLink/MasterLinkShipping";
import MasterLinkEmailTemplate from "./component/Apps/MasterLink/MasterLinkEmailTemplate";
import MasterLinkShoppingIntegration from "./component/Apps/MasterLink/MasterLinkShoppingIntegration";
import PendantBuilderApplicationPreview from "./component/Apps/PendantBuilder/PendantBuilderApplicationPreview";
import StudBuilderOptions from "./component/Apps/StudBuilder/StudBuilderOptions";
import StudBuilderSettings from "./component/Apps/StudBuilder/StudBuilderSettings";
import StudBuilderShoppingIntegrations from "./component/Apps/StudBuilder/StudBuilderShoppingIntegrations";
import WatchCustomizer from "./component/Apps/WatchCustomizer/WatchCustomizer";
import PendantBuilderOptions from "./component/Apps/PendantBuilder/PendantBuilderOptions";
import PendantBuilderSettings from "./component/Apps/PendantBuilder/PendantBuilderSettings";
import PendantBuilderEmailTemplate from "./component/Apps/PendantBuilder/PendantBuilderEmailTemplate";
import PendantBuilderShoppingIntegrations from "./component/Apps/PendantBuilder/PendantBuilderShoppingIntegrations";
import PendantBuilderNavigation from "./component/Apps/PendantBuilder/PendantBuilderNavigation";
import StudBuilderNavigation from "./component/Apps/StudBuilder/StudBuilderNavigation";
import PedantBuilderDealers from "./component/Apps/PendantBuilder/PedantBuilderDealers";
import Timepieces from "./component/Apps/Timepieces";
import MyVendors from "./component/Community/VendorManager/MyVendors";
import Network from "./component/Community/VendorManager/Network";
import Inviteyourvendor from "./component/Community/VendorManager/Inviteyourvendor";
import New from "./component/Community/VendorManager/New";
import OutOfNetwork from "./component/Community/VendorManager/OutOfNetwork";
import RecentlyUpdated from "./component/Community/VendorManager/RecentlyUpdated";
import VendorManagerPopular from "./component/Community/VendorManager/VendorManagerPopular";
import VendorManagerArchived from "./component/Community/VendorManager/VendorManagerArchived";
import RetailerProfile from "./component/Retailer/RetailerProfile";
import JewelryRetailerPermission from "./component/Retailer/JewelryRetailerPermission";
import PendingJewelryRequest from "./component/Retailer/PendingJewelryRequest";
import DiamondRetailerPermission from "./component/Retailer/DiamondRetailerPermission";
import PendingDiamondRequest from "./component/Retailer/PendingDiamondRequest";
import RingBuilderRetailerPermission from "./component/Retailer/RingBuilderRetailerPermission";
import PendingRingBuilderRequest from "./component/Retailer/PendingRingBuilderRequest";
// import VendorManagerPopular from "./component/Community/VendorManager/VendorManagerPopular";
// import VendorManagerArchived from "./component/Community/VendorManager/VendorManagerArchived";
import PurchaseOrder from "./component/Purchase Order/PurchaseOrder";
import PurchaseOrderHistory from "./component/Purchase Order/PurchaseOrderHistory";
import UpdatePurchaseOrder from "./component/Purchase Order/UpdatePurchaseOrder";
import VendorManagerPendingRequests from "./component/Community/VendorManager/VendorManagerPendingRequests";
import UploadMarketingMaterial from "./component/Marketing/UploadMarketingMaterial";
import AddProducts from "./component/Catlog/AddProducts";
import CompanyProfile from "./component/User Profile/companyProfile";
import UserSettings from "./component/User Profile/UserSettings";
import SocialIntegrations from "./component/User Profile/SocialIntegrations";
import UserProfile from "./component/User Profile/userProfile";
import JewelryCategoryReport from "./component/reports/jewelry/JewelryCategoryReport";
import JewelryChartReport from "./component/reports/jewelry/JeweleryChartReports";
import DataMaping from "./component/Catlog/DataMapping/DataMaping";
import ManageUser from "./component/User Profile/ManageUser";
import ManageAccount from "./component/User Profile/ManageAccount";
import ManageLanguage from "./component/User Profile/ManageLanguage";
import ManageCurrency from "./component/User Profile/ManageCurrency";

import RingBuilderApiEndPoint from "./component/Apps/RingBuilder/RingBuilderApiEndPoint";
import DiamondLinkApiEndPoint from "./component/Apps/DiamondLinks/DiamondLinkApiEndPoint";
import JewelryChartReportAdmin from "./component/admin/JewelryReport/JewelryChartReportAdmin";
import JewelryCategoryReportAdmin from "./component/admin/JewelryReport/JewelryCategoryReportAdmin";
import JewelryMetalTypeReport from "./component/admin/JewelryReport/JewelryMetalTypeReport";
import JewelryPriceReport from "./component/admin/JewelryReport/JewelryPriceReport";
import JewelryRetailerClicksReport from "./component/admin/JewelryReport/JewelryRetailerClicksReport";
import JewelryVendorClicksReport from "./component/admin/JewelryReport/JewelryVendorClicksReport";
import Syncs from "./component/admin/reports/Syncs";
import ToolsReports from "./component/admin/reports/ToolsReports";
import DataRelated from "./component/admin/reports/DataRelated";
import JewelryLogReports from "./component/admin/reports/JewelryLogReports";
import GlobalEvents from "./component/admin/reports/GlobalEvents";
import ClientsReports from "./component/admin/reports/ClientsReports";
import StudBuilderMarkup from "./component/Apps/StudBuilder/StudBuilderMarkup";
import StudBuilderDealers from "./component/Apps/StudBuilder/StudBuilderDealers";
import StudBuilderDaimondMarkup from "./component/Apps/StudBuilder/StudBuilderDaimondMarkup";
import PendantBuilderManageVendors from "./component/Apps/PendantBuilder/PendantBuilderManageVendor";
import PendantBuilderSettingMarkup from "./component/Apps/PendantBuilder/PendantBuilderSettingMarkup";
import PendantBuilderDiamondMarkup from "./component/Apps/PendantBuilder/PendantBuilderDiamondMarkup";
import StudbuilderLabGrowMarkup from "./component/Apps/StudBuilder/StudbuilderLabGrowMarkup";
import PendantBuilderLabGrowMarkup from "./component/Apps/PendantBuilder/PendantBuilderLabGrowMarkup";
import DiamondClicks from "./component/admin/DiamondReport/DiamondClicks";
import ExtendedDiamondChartReports from "./component/admin/DiamondReport/ExtendedDiamondChartReports";
import DiamondClarityReport from "./component/admin/DiamondReport/DiamondClarityReport";
import DiamondColor from "./component/admin/DiamondReport/DiamondColor";
import SyncHistory from "./component/Apps/WebsiteSync/SyncHistory";
import OutBoundMapping from "./component/Apps/WebsiteSync/OutBoundMapping";
import FtpDetails from "./component/Apps/WebsiteSync/FtpDetails";
import DiamondCutReport from "./component/admin/DiamondReport/DiamondCutReport";
import DiamondShapeReport from "./component/admin/DiamondReport/DiamondShapeReport";
import DiamondCertificateReport from "./component/admin/DiamondReport/DiamondCertificateReport";
import DiamondSizeReport from "./component/admin/DiamondReport/DiamondSizeReport";
// import { PendingSync } from "./component/admin/tools/PendingSync";

import i18n from "./i18n";
import RequestAppointment from "./component/login/RequestAppointment";
import JewelryMetalTypeReportMain from "./component/reports/jewelry/JewelryMetalTypeReportMain";
import AddToCartReports from "./component/reports/jewelry/AddToCartReports";
import WishListReports from "./component/reports/jewelry/WishListReports";
import JewelryPriceReportMain from "./component/reports/jewelry/JewelryPriceReportMain";
import JewelryRetailerClickReportMain from "./component/reports/jewelry/JewelryRetailerClickReportMain";
import JewelryVendorClickReportMain from "./component/reports/jewelry/JewelryVendorClickReportMain";
import JewelryLocationActivity from "./component/reports/jewelry/JewelryLocationActivity";
import GlobalJewelryChartReports from "./component/reports/GlobalJewelry/GlobalJewelryChartReports";

import ExtendedDiamondChartReportsMain from "./component/reports/Diamonds/ExtendedDiamondChartReportsMain";
/*Reports Diamond Individual Pages Start*/
import DiamondReportsShapeIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsShapeIndComp";
import DiamondReportsClarityIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsClarityIndComp";
import DiamondReportsCertificateIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsCertificateIndComp";
import DiamondReportsCutIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsCutIndComp";
import DiamondReportsColorIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsColorIndComp";
import DiamondReportsSizeIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsSizeIndComp";
import DiamondReportsChartsIndComp from "./component/ChartsGroup/DiamondReportsCharts/DiamondReportsChartsIndComp";

/*Reports Diamond Individual Pages End*/
import LocationActivity from "./component/reports/Diamonds/LocationActivity";
import GlobalExtendedDiamondChartReports from "./component/reports/GlobalDiamonds/GlobalExtendedDiamondChartReports";
import GlobalDiamondClarityReport from "./component/reports/GlobalDiamonds/GlobalDiamondClarityReport";
/*Global Diamond Individual Pages Start*/
import DiamondClarityIndComp from "./component/ChartsGroup/DiamondClarityIndComp";
import DiamondColorIndComp from "./component/ChartsGroup/DiamondColorIndComp";
import DiamondCutIndComp from "./component/ChartsGroup/DiamondCutIndComp";
import DiamondCertificateIndComp from "./component/ChartsGroup/DiamondCertificateIndComp";
import DiamondSizeIndComp from "./component/ChartsGroup/DiamondSizeIndComp";
import DiamondShapeIndComp from "./component/ChartsGroup/DiamondShapeIndComp";
/*Global Diamond Individual Pages End*/
import GlobalJewelryCategoryReport from "./component/reports/GlobalJewelry/GlobalJewelryCategoryReport";
import GlobalJewelryMetalTypeReport from "./component/reports/GlobalJewelry/GlobalJewelryMetalTypeReport";
import WatchCustomizerOptions from "./component/Apps/WatchCustomizer/WatchCustomizerOptions";
import Conversation from "./component/contacts/conversation";
import Customer from "./component/contacts/customer";
import ProfileNew from "./component/contacts/profile";
import Contact from "./component/contacts/Contact";
import Appointments from "./component/contacts/Appointments";
import ResetPassword from "./component/login/ResetPassword";
import EditViewDataMapping from "./component/Catlog/Diamonds/EditViewDataMapping";
import MakeAppoitments from "./component/Retailer/MakeAppoitments";
import ViewInquiry from "./component/Apps/DiamondLinks/ViewInquiry";
import VendorProfile from "./component/Community/VendorManager/VendorProfile";
import DealerProfile from "./component/Apps/Dealer/DealerProfile";
import InboundClientDataMaping from "./component/InboundClientDataMaping/InboundClientDataMaping";
import CompanyLocation from "./component/User Profile/CompanyLocation";
import AllApps from "./component/Apps/AllApps";
import GlobalJewelryPriceReport from "./component/reports/GlobalJewelry/GlobalJewelryPriceReport";
import GlobalJewerlyWishListReport from "./component/reports/GlobalJewelry/GlobalJewerlyWishListReport";
import GlobalDiamondAddToCartReport from "./component/reports/GlobalDiamonds/GlobalDiamondAddToCartReport";
import GlobalDiamondWishListReport from "./component/reports/GlobalDiamonds/GlobalDiamondWishListReport";
import DiamondAddToCartReport from "./component/reports/Diamonds/DiamondAddToCartReport";
import DiamondWishListReport from "./component/reports/Diamonds/DiamondWishListReport";
import JewerlyWishListReport from "./component/reports/jewelry/JewerlyWishListReport";
import JewelryAddToCartReport from "./component/reports/jewelry/JewelryAddToCartReport";
import GlobalJewelryAddToCartReport from "./component/reports/GlobalJewelry/GlobalJewelryAddToCart";
import TermsOfService from "./component/login/TermsOfService";
import PrivacyStatement from "./component/login/PrivacyStatement";
import UploadAdminProductFile from "./component/admin/tools/UploadProductFile";
import ChangePassword from "./component/User Profile/ChangePassword";
import InviteARetailer from "./component/Retailer/InviteARetailer";
import PageNotAccess from "./component/PageNotAccess";
import DiamondVendors from "./component/Apps/RingBuilder/DiamondVendor";
import AllProduct from "./component/Catlog/Allproduct";
import Print from "./component/Catlog/Print";
import BlockedProduct from "./component/dashboard/BlockedProduct";
import AddCampaignPage from "./component/admin/campaigns/AddCampaignPage";
import MyVendorsRingBuilder from "./component/Apps/RingBuilder/MyVendorsRingBuilder";
import MyVendorsStudBuilder from "./component/Apps/StudBuilder/MyVendorsStudBuilder";
import MyVendorsPendantBuilder from "./component/Apps/PendantBuilder/MyVendorsPendantBuilder";
import RingBuilderPendingVendorRequest from "./component/Apps/RingBuilder/RingBuilderPendingVendorRequest";
import PendantBuilderPendingVendorRequest from "./component/Apps/PendantBuilder/PendantBuilderPendingVendorRequest";
import StudBuilderPendingVendorRequest from "./component/Apps/StudBuilder/StudBuilderPendingVendorRequest";
import RetailerGroup from "./component/Catlog/RetailerGroup";
import DiamondGroupDiscount from "./component/Catlog/DiamondGroupDiscount";
import PurchaseOrderDetails from "./component/Purchase Order/PurchaseOrderDetails";
import VendorRequest from "./component/Community/VendorManager/VendorRequest";
import Marketing from "./component/admin/settings/Marketing";
import RetailerMarketing from "./component/admin/settings/RetailerMarketing";
import MarketingUploadFile from "./component/admin/settings/MarketingUploadFile";
import MarketingUploadVendor from "./component/admin/settings/MarketingUploadVendor";
import RetailerProfileNew from "./component/Retailer/RetailerProfileNew";
import SyncLimiter from "./component/Catlog/SyncLimiter";
import PODashboard from "./component/Purchase Order/PODashboard";
import Worksheet from "./component/Purchase Order/Worksheet";
import WorkSheetDetails from "./component/Purchase Order/WorkSheetDetails";
import ManageCompanyShows from "./component/User Profile/ManageCompanyShows";
import ManageBrands from "./component/User Profile/ManageBrands";
import ApplicationPreviewRing from "./component/Apps/RingBuilder/ApplicationPreviewRing";
import ApplicationPreviewStud from "./component/Apps/StudBuilder/ApplicationPreviewStudBuilder";
import ApplicationPreviewPedant from "./component/Apps/PendantBuilder/ApplicationPreviewPedant";
import ManageInventory from "./component/Community/VendorManager/ManageInventory";
import ApplicationPreviewMasterlink from "./component/Apps/MasterLink/ApplicationPreviewMasterlink";
import DepartmentsMasterLink from "./component/Apps/MasterLink/DepartmentsMasterLink";
import ViewInventory from "./component/Community/VendorManager/ViewInventory";
import PricingGeneral from "./component/Catlog/Pricing/General";
import CreateDiscardCollection from "./component/Catlog/CreateDiscardCollection";
import Brands from "./component/Catlog/Brands/Brands";
import Vendor from "./component/Catlog/Vendor/Vendor";
// import CompanyEmailSetting from "./component/User Profile/CompanyEmailSetting";
import InviteYourRetailer from "./component/Retailer/InviteYourRetailer";
import StuckFile from "./component/Catlog/StuckFile";
import StuckFileJewelry from "./component/Catlog/Diamonds/StuckFileJewelry";
import JeweleryPriceBasedAddToCartReports from "./component/reports/jewelry/JeweleryPriceBasedAddToCartReports";
import JeweleryPriceBasedWishListReports from "./component/reports/jewelry/JeweleryPriceBasedWishListReports";
import JeweleryVendorBasedWishListReports from "./component/reports/jewelry/JeweleryVendorBasedWishListReports";
import DiamondPriceBasedAddToCartReports from "./component/reports/Diamonds/DiamondPriceBasedAddToCartReports";
import DiamondVendorBasedAddToCartReport from "./component/reports/Diamonds/DiamondVendorBasedAddToCart";
import DiamondVendorBasedWishListReport from "./component/reports/Diamonds/DiamondVendorBasedWishListReports";
import DiamondPriceBasedWishListReports from "./component/reports/Diamonds/DiamondPriceBasedWishListReports";
import WhiteLabel from "./component/White Label/WhiteLabel";
import Organization from "./component/White Label/Organization";
import { useCookies } from "react-cookie";
import SCAMaster from "./component/White Label/SCAMaster";

const PrivateRoute = () => {
  const loginDetials = useSelector((state) => state.loginReducer);
  var logInSucceeded = false;
  if (loginDetials.loginDetials) {
    if (loginDetials.loginDetials.responseData.logInSucceeded) {
      if (loginDetials.loginDetials.responseData.logInSucceeded) {
        logInSucceeded = true;
      }
    }
    // else if (loginDetials.payload) {
    //   if (loginDetials.payload.responseData.logInSucceeded) {
    //     logInSucceeded = true;
    //   }
    // }
  } else if (loginDetials.payload) {
    if (loginDetials.payload.responseData.logInSucceeded) {
      logInSucceeded = true;
    }
  } else {
    logInSucceeded = false;
  }

  return logInSucceeded ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export const App = () => {
  const language = useSelector((state) => state.headerMenuReducer.language);

  useEffect(() => {
    const handleCloseDropdowns = (e) => {
      if (
        e.target.closest(".ant-select-dropdown") ||
        e.target.closest(".ant-picker-dropdown")
      ) {
        return;
      }

      const openSelects = document.querySelectorAll(
        ".ant-select.ant-select-open"
      );
      openSelects.forEach((dropdown) => {
        const selectInput = dropdown.querySelector("input");
        if (selectInput) {
          selectInput.blur();
        }
      });

      const openDatePickers = document.querySelectorAll(
        ".ant-picker.ant-picker-focused"
      );
      openDatePickers.forEach((datePicker) => {
        const inputElement = datePicker.querySelector("input");
        if (inputElement) {
          inputElement.blur();
        }
      });
    };

    window.addEventListener("scroll", handleCloseDropdowns);
    window.addEventListener("wheel", handleCloseDropdowns);

    return () => {
      window.removeEventListener("scroll", handleCloseDropdowns);
      window.removeEventListener("wheel", handleCloseDropdowns);
    };
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const [langcookies, setLangcookies] = useCookies(["lngCookie"]);
  const expiryTime = new Date();
  expiryTime.setTime(expiryTime.getTime() + 3 * 60 * 60 * 1000); ///3 hours expiry time
  const domainSite = extractDomain();
  function extractDomain() {
    const url = window.location.href;
    const domainMatch = url.match(/:\/\/(www[0-9]?\.)?([^/:]+)/i);

    if (domainMatch && domainMatch.length > 2) {
      const fullDomain = domainMatch[2];
      const parts = fullDomain.split(".");
      if (parts.length >= 2) {
        const domain = "." + parts.slice(-2).join(".");
        return domain;
      }
    }

    return null;
  }
  setLangcookies("lngCookie", language, {
    path: "/",
    expires: new Date(Date.now() + 86400000),
    domain: domainSite,
  });

  return (
    <BrowserRouter>
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/createNewPassword" element={<Login />} />
          <Route path="/successfullverification" element={<Login />} />
          <Route path="/customerLogin" element={<Login />} />
          <Route path="/ProductInfo" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route exact path="/CreateDiscardCollection" element={<Login />} />
          <Route path="/CompanyEmailSetting" element={<Login />} />
          <Route
            exact
            path="/DiscardCollection"
            element={<CreateDiscardCollection />}
          />

          <Route exact path="/contactAppointment" element={<Login />} />
          <Route exact path="/contactAppointment" element={<Appointment />} />

          <Route path="/requestAppointment" element={<RequestAppointment />} />
          <Route path="/resetPassword" element={<ResetPassword />} />

          <Route path="/termsOfService" element={<TermsOfService />} />
          <Route path="/privacyStatement" element={<PrivacyStatement />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/notAccess" element={<PageNotAccess />} />

            <Route exact path="/notAccess1" element={<PageNotAccess />} />
            <Route exact path="/notAccess2" element={<PageNotAccess />} />
            <Route exact path="/notAccess3" element={<PageNotAccess />} />
            <Route exact path="/notAccess4" element={<PageNotAccess />} />
            <Route
              exact
              path="/administrationTools"
              element={<Administrationtools />}
            />
            <Route
              exact
              path="/createNewClient"
              element={<CreateNewClient />}
            />
            <Route exact path="/clientStatus" element={<ClientStatus />} />
            <Route exact path="/catalog" element={<Catlog />} />
            <Route exact path="/catalogList" element={<CatlogList />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/blockedProduct" element={<BlockedProduct />} />
            <Route
              exact
              path="/updateInventoryImport"
              element={<UpdateInventoryImport />}
            />
            <Route
              exact
              path="/createNewPasswordMasterlink"
              element={<ApplicationPreviewMasterlink />}
            />
            <Route
              exact
              path="/MasterLinkApplicationPreview"
              element={<ApplicationPreviewMasterlink />}
            />
            <Route
              exact
              path="/viewCatalog"
              element={<ApplicationPreviewMasterlink />}
            />
            <Route
              exact
              path="/MasterLinkDepartments"
              element={<DepartmentsMasterLink />}
            />
            <Route
              exact
              path="/catalogDepartments"
              element={<DepartmentsMasterLink />}
            />
            <Route exact path="/myProducts" element={<Allproducts />} />
            <Route exact path="/sharedProduct" element={<SharedProduct />} />
            <Route exact path="/allProducts" element={<AllProduct />} />
            <Route exact path="/addProducts" element={<AddProducts />} />
            <Route exact path="/editProducts" element={<AddProducts />} />
            <Route exact path="/appointment" element={<Appointment />} />
            <Route
              exact
              path="/loginPreferences"
              element={<LoginPreferences />}
            />
            <Route
              exact
              path="/transferZZGemfind"
              element={<TransferZZGemfind />}
            />
            <Route exact path="/tradeShows" element={<TradeShows />} />
            <Route exact path="/mailingLists" element={<MailingLists />} />
            <Route exact path="/campaignData" element={<CampaignData />} />
            <Route exact path="/composeMail" element={<ComposeMail />} />
            <Route exact path="/addCampaign" element={<AddCampaignPage />} />
            <Route exact path="/apiAccessToken" element={<ApiAccessToken />} />
            <Route exact path="/addcolor" element={<Addcolor />} />
            <Route exact path="/productDetails" element={<ProductDetails />} />

            <Route
              exact
              path="/applicationPermissions"
              element={<ApplicationPermissions />}
            />
            <Route
              exact
              path="/diamondRapNetPermission"
              element={<DiamondRapNetPermission />}
            />
            <Route
              exact
              path="/addNewRetailerToCore"
              element={<AddNewRetailerToCore />}
            />
            <Route exact path="/vendorRatings" element={<VendorRatings />} />
            <Route
              exact
              path="/outGoingDataMap"
              element={<OutGoingDataMap />}
            />

            <Route exact path="/outBoundSync" element={<OutBoundSync />} />

            <Route
              exact
              path="/clientDataMapping"
              element={<ClientDataMapping />}
            />

            <Route
              exact
              path="/marketingUploadFile"
              element={<MarketingUploadFile />}
            />
            <Route
              exact
              path="/marketingUploadVendor"
              element={<MarketingUploadVendor />}
            />

            <Route exact path="/marketing" element={<Marketing />} />

            <Route
              exact
              path="/retailerMarketing"
              element={<RetailerMarketing />}
            />
            <Route exact path="/limit" element={<Limits />} />
            <Route exact path="/legacy" element={<Legacy />} />
            <Route
              exact
              path="/reportPermissions"
              element={<ReportsPermissions />}
            />
            <Route
              exact
              path="/retailerlocator"
              element={<Retailerlocator />}
            />
            <Route
              exact
              path="/blockDesignersForDemo"
              element={<BlockDesignersRetailer />}
            />
            <Route exact path="/dataMaps" element={<DataMaps />} />
            <Route exact path="/metalMarket" element={<MetalMarket />} />
            <Route exact path="/uploadHistory" element={<UploadHistory />} />
            <Route exact path="/diamondStuckFile" element={<StuckFile />} />
            <Route
              exact
              path="/jewelryStuckFile"
              element={<StuckFileJewelry />}
            />
            <Route
              exact
              path="/diamonduploadHistory"
              element={<DiamondUploadHistory />}
            />

            <Route
              exact
              path="/uploadProductFile"
              element={<UploadProductFile />}
            />
            <Route
              exact
              path="/addSingleDiamond"
              element={<AddSignleDiamond />}
            />
            <Route exact path="/print" element={<Print />} />
            <Route
              exact
              path="/uploadDiamondFile"
              element={<UploadDiamondFile />}
            />
            <Route
              exact
              path="/ftpCredential"
              element={<FTPCredential type="Diamond" />}
            />
            <Route exact path="/FtpCredential" element={<FTPCredential />} />
            <Route exact path="/Ftpcredential" element={<FTPCredential />} />
            <Route
              exact
              path="/FtpCredentials"
              element={<FTPCredential type="Jewelry" />}
            />
            <Route
              exact
              path="/RBFtpCredentials"
              element={<FTPCredential type="Jewelry" />}
            />
            <Route
              exact
              path="/applicationPreviewDiamondLink"
              element={<ApplicationPreview />}
            />
            <Route
              exact
              path="/applicationPreviewRingBuilder"
              element={<ApplicationPreviewRing />}
            />

            <Route
              exact
              path="/applicationPreviewPedantBuilder"
              element={<ApplicationPreviewPedant />}
            />
            <Route
              exact
              path="/applicationPreviewStudBuilder"
              element={<ApplicationPreviewStud />}
            />
            <Route exact path="/manageVendor" element={<Dealers />} />
            <Route
              exact
              path="/minedDiamondMarkup"
              element={<MinedDiamondsMarkup />}
            />
            <Route
              exact
              path="/fancyColorMarkup"
              element={<FancyColorMarkup />}
            />
            <Route exact path="/labGrowMarkup" element={<LabGrowMarkup />} />
            <Route
              exact
              path="/availableFilterSettings"
              element={<AvailableFilterSettings />}
            />
            <Route
              exact
              path="/initialFilterSettings"
              element={<InitialFilterSettings />}
            />
            <Route exact path="/options" element={<Options />} />
            <Route exact path="/shipping" element={<Shipping />} />
            <Route exact path="/navigation" element={<Navigation />} />
            <Route
              exact
              path="/diamondLinkLegacy"
              element={<DiamondLinkLegacy />}
            />
            <Route
              exact
              path="/diamondLinksEmailTemplate"
              element={<DiamondLinksEmailTemplate />}
            />

            <Route
              exact
              path="/diamondLinkApiEndPoint"
              element={<DiamondLinkApiEndPoint />}
            />
            <Route exact path="/viewInquiry" element={<ViewInquiry />} />
            <Route
              exact
              path="/shoppingIntegrations"
              element={<ShoppingIntegrations />}
            />
            <Route exact path="/diamondMapping" element={<DiamondMapping />} />
            <Route
              exact
              path="/editViewDataMapping"
              element={<EditViewDataMapping />}
            />
            <Route
              exact
              path="/diamondGroupDiscount"
              element={<DiamondGroupDiscount />}
            />
            <Route exact path="/retailerGroup" element={<RetailerGroup />} />
            <Route
              exact
              path="/manageCollection"
              element={<ManageCollection />}
            />
            <Route exact path="/metalMarket" element={<MetalMarket />} />
            <Route exact path="/permissions" element={<Permissions />} />
            <Route exact path="/emailTemplate" element={<EmailTemplate />} />
            <Route
              exact
              path="/openSourceMapping"
              element={<MagentoMapping />}
            />
            <Route exact path="/pendingSync" element={<PendingSync />} />
            <Route exact path="/consoleUpload" element={<ConsoleUpload />} />
            <Route exact path="/vendorRating" element={<VendorRatings />} />
            <Route exact path="/jewelryMarkup" element={<ProductMarkup />} />
            <Route exact path="/addCollection" element={<AddCollection />} />
            <Route exact path="/editCollection" element={<AddCollection />} />

            <Route
              exact
              path="/viewMyMinedDiamond"
              element={<ViewMyMinedDiamond />}
            />
            <Route
              exact
              path="/viewMyColorDiamond"
              element={<ViewMyColorDiamond />}
            />
            <Route
              exact
              path="/viewMyLabDiamond"
              element={<ViewMyLabDiamond />}
            />
            <Route exact path="/jewelrySearch" element={<JewelrySearch />} />
            <Route exact path="/watchesSearch" element={<WatchesSearch />} />
            <Route exact path="/diamondSearch" element={<DiamondSearch />} />
            <Route
              exact
              path="/colorDiamondSearch"
              element={<ColorDiamondSearch />}
            />
            <Route
              exact
              path="/labDiamondSearch"
              element={<LabDiamondSearch />}
            />
            <Route
              exact
              path="/colorDiamondSearch"
              element={<ColorDiamondSearch />}
            />
            <Route
              exact
              path="/labDiamondSearch"
              element={<LabDiamondSearch />}
            />
            <Route
              exact
              path="/ringBuilderSettingsMarkups"
              element={<Markups />}
            />
            <Route exact path="/ringBuilderSettings" element={<Settings />} />
            <Route
              exact
              path="/NetworkVendorRingBuilder"
              element={<Vendors />}
            />
            <Route
              exact
              path="/vendorStudBuilder"
              element={<MyNetworkStudBuilder />}
            />
            <Route
              exact
              path="/vendorPendantBuilder"
              element={<MyNetworkPendantBuilder />}
            />
            <Route
              exact
              path="/studBuildermanageVendors"
              element={<StudBuilderManageVendors />}
            />
            <Route
              exact
              path="/studBuilderSettingsMarkup"
              element={<StudBuilderMarkup />}
            />
            <Route
              exact
              path="/studBuilderDiamondVendors"
              element={<StudBuilderDealers />}
            />
            <Route
              exact
              path="/studBuilderDaimondMarkup"
              element={<StudBuilderDaimondMarkup />}
            />
            <Route
              exact
              path="/StudbuilderLabGrowMarkup"
              element={<StudbuilderLabGrowMarkup />}
            />

            <Route
              exact
              path="/pendantBuilderManageVendors"
              element={<PendantBuilderManageVendors />}
            />
            <Route
              exact
              path="/pendantBuilderSettingMarkup"
              element={<PendantBuilderSettingMarkup />}
            />
            <Route
              exact
              path="/pendantBuilderDiamondMarkup"
              element={<PendantBuilderDiamondMarkup />}
            />
            <Route
              exact
              path="/PendantBuilderLabGrowMarkup"
              element={<PendantBuilderLabGrowMarkup />}
            />

            <Route exact path="/syncHistory" element={<SyncHistory />} />
            <Route exact path="/syncLimiter" element={<SyncLimiter />} />
            <Route
              exact
              path="/outBoundMapping"
              element={<OutBoundMapping />}
            />
            <Route exact path="/ftpDetails" element={<FtpDetails />} />

            <Route exact path="/archiveVendors" element={<ArchiveVendors />} />
            <Route
              exact
              path="/diamondVendorsRingBuilder"
              element={<DiamondVendors />}
            />

            <Route
              exact
              path="/ringBuilderDaimondMarkup"
              element={<RingBuilderDaimondMarkup />}
            />
            <Route
              exact
              path="/ringBuilderLabGrowMarkup"
              element={<RingBuilderLabGrowMarkup />}
            />
            <Route
              exact
              path="/ringBuilderApiEndPoint"
              element={<RingBuilderApiEndPoint />}
            />
            <Route
              exact
              path="/ringbuilderNavigation"
              element={<RingbuilderNavigation />}
            />
            <Route
              exact
              path="/ringbuilderLegacy"
              element={<RingbuilderLegacy />}
            />
            <Route
              exact
              path="/ringbuilderShoppingIntegrations"
              element={<RingbuilderShoppingIntegrations />}
            />
            <Route
              exact
              path="/ringBuilderEmailTemplate"
              element={<RingBuilderEmailTemplate />}
            />

            <Route
              exact
              path="/ringBuilderDealers"
              element={<RingBuilderDealers />}
            />

            <Route
              exact
              path="/ringBuilderOptions"
              element={<RingBuilderOptions />}
            />
            <Route
              exact
              path="/masterlinkLegacy"
              element={<MasterLinkLegacy />}
            />
            <Route
              exact
              path="/studBuilderApplicationPreview"
              element={<StudBuilderApplicationPreview />}
            />

            <Route
              exact
              path="/studBuilderShoppingConfiguration"
              element={<StudBuilderShoppingIntegrations />}
            />

            <Route
              exact
              path="/studBuilderOptions"
              element={<StudBuilderOptions />}
            />

            <Route
              exact
              path="/studBuilderSettings"
              element={<StudBuilderSettings />}
            />

            <Route
              exact
              path="/studBuilderEmailTemplate"
              element={<StudBuilderEmailTemplate />}
            />

            <Route
              exact
              path="/watchCustomizerOptions"
              element={<WatchCustomizerOptions />}
            />

            <Route
              exact
              path="/applicationPreviewWatchCustomizer"
              element={<WatchCustomizer />}
            />

            <Route exact path="/conversation" element={<Conversation />} />

            <Route exact path="/contact" element={<Contact />} />

            <Route exact path="/customer" element={<Customer />} />
            <Route exact path="/profileNew" element={<ProfileNew />} />

            <Route exact path="/appointments" element={<Appointments />} />

            <Route
              exact
              path="/masterlinkOptions"
              element={<MasterLinkOptions />}
            />

            <Route
              exact
              path="/masterlinkShipping"
              element={<MasterLinkShipping />}
            />
            <Route
              exact
              path="/masterlinkEmailTemplate"
              element={<MasterLinkEmailTemplate />}
            />
            <Route
              exact
              path="/masterlinkShoppingConfiguration"
              element={<MasterLinkShoppingIntegration />}
            />
            <Route
              exact
              path="/pendantBuilderApplicationPreview"
              element={<PendantBuilderApplicationPreview />}
            />
            <Route
              exact
              path="/pendantBuilderOptions"
              element={<PendantBuilderOptions />}
            />

            <Route
              exact
              path="/pendantBuilderSettings"
              element={<PendantBuilderSettings />}
            />

            <Route
              exact
              path="/pendantBuilderEmailTemplate"
              element={<PendantBuilderEmailTemplate />}
            />

            <Route
              exact
              path="/pendantBuilderShoppingConfiguration"
              element={<PendantBuilderShoppingIntegrations />}
            />
            <Route
              exact
              path="/pendantBuilderNavigation"
              element={<PendantBuilderNavigation />}
            />

            <Route
              exact
              path="/studBuilderNavigation"
              element={<StudBuilderNavigation />}
            />
            <Route
              exact
              path="/pedantBuilderDiamondVendors"
              element={<PedantBuilderDealers />}
            />
            <Route exact path="/outOfNetwork" element={<OutOfNetwork />} />
            <Route exact path="/new" element={<New />} />
            <Route exact path="/timepieces" element={<Timepieces />} />
            <Route exact path="/allapps" element={<AllApps />} />
            <Route exact path="/myvendors" element={<MyVendors />} />
            <Route exact path="/network" element={<Network />} />
            <Route
              exact
              path="/inviteyourvendor"
              element={<Inviteyourvendor />}
            />
            <Route
              exact
              path="/inviteYourRetailer"
              element={<InviteYourRetailer />}
            />
            <Route
              exact
              path="/recentlyUpdated"
              element={<RecentlyUpdated />}
            />
            <Route
              exact
              path="/vendorManagerPopular"
              element={<VendorManagerPopular />}
            />
            <Route
              exact
              path="/vendorManagerArchived"
              element={<VendorManagerArchived />}
            />
            <Route
              exact
              path="/retailerProfile"
              element={<RetailerProfile />}
            />
            <Route
              exact
              path="/retailerProfileNew"
              element={<RetailerProfileNew />}
            />
            <Route
              exact
              path="/jewelryRetailerPermission"
              element={<JewelryRetailerPermission />}
            />
            <Route
              exact
              path="/inviteARetailer"
              element={<InviteARetailer />}
            />
            <Route
              exact
              path="/makeAppoitments"
              element={<MakeAppoitments />}
            />
            <Route
              exact
              path="/pendingJewelryRequest"
              element={<PendingJewelryRequest />}
            />
            <Route
              exact
              path="/diamondRetailerPermission"
              element={<DiamondRetailerPermission />}
            />
            <Route
              exact
              path="/pendingDiamondRequest"
              element={<PendingDiamondRequest />}
            />
            <Route
              exact
              path="/ringBuilderRetailerPermission"
              element={<RingBuilderRetailerPermission />}
            />
            <Route
              exact
              path="/pendingRingBuilderRequest"
              element={<PendingRingBuilderRequest />}
            />
            <Route
              exact
              path="/vendorManagerPendingRequests"
              element={<VendorManagerPendingRequests />}
            />

            <Route exact path="/vendorProfile" element={<VendorProfile />} />
            <Route
              exact
              path="/appsVendorProfile"
              element={<VendorProfile />}
            />
            <Route
              exact
              path="/manageInventory"
              element={<ManageInventory />}
            />
            <Route exact path="/viewInventory" element={<ViewInventory />} />
            <Route exact path="/dealerProfile" element={<DealerProfile />} />
            <Route
              exact
              path="/JewelryMetalTypeReportMain"
              element={<JewelryMetalTypeReportMain />}
            />
            <Route
              exact
              path="/diamondAddToCartReport"
              element={<AddToCartReports />}
            />

            <Route
              exact
              path="/jewelryVendorAddToCartReport"
              element={<JewelryAddToCartReport />}
            />

            <Route
              exact
              path="/jeweleryPriceBasedAddToCartReports"
              element={<JeweleryPriceBasedAddToCartReports />}
            />
            <Route
              exact
              path="/jeweleryPriceBasedWishListReports"
              element={<JeweleryPriceBasedWishListReports />}
            />

            <Route
              exact
              path="/jeweleryVendorBasedWishListReports"
              element={<JeweleryVendorBasedWishListReports />}
            />

            <Route
              exact
              path="/diamondPriceBasedAddToCartReports"
              element={<DiamondPriceBasedAddToCartReports />}
            />

            <Route
              exact
              path="/diamondVendorBasedAddToCartReports"
              element={<DiamondVendorBasedAddToCartReport />}
            />

            <Route
              exact
              path="/diamondVendorBasedWishListReport"
              element={<DiamondVendorBasedWishListReport />}
            />

            <Route
              exact
              path="/diamondPriceBasedWishListReports"
              element={<DiamondPriceBasedWishListReports />}
            />

            <Route
              exact
              path="/diamondWishListReport"
              element={<WishListReports />}
            />
            <Route
              exact
              path="/JewelryPriceReportMain"
              element={<JewelryPriceReportMain />}
            />

            <Route
              exact
              path="/jewelryRetailerClickReportMain"
              element={<JewelryRetailerClickReportMain />}
            />

            <Route
              exact
              path="/JewelryVendorClickReportMain"
              element={<JewelryVendorClickReportMain />}
            />

            <Route
              exact
              path="/jewelryLocationActivity"
              element={<JewelryLocationActivity />}
            />

            <Route
              exact
              path="/globalJewelryChartReports"
              element={<GlobalJewelryChartReports />}
            />

            <Route
              exact
              path="/globalJewelryCategoryReport"
              element={<GlobalJewelryCategoryReport />}
            />

            <Route
              exact
              path="/globalJewelryMetalTypeReport"
              element={<GlobalJewelryMetalTypeReport />}
            />

            <Route
              exact
              path="/globalJewelryPriceReport"
              element={<GlobalJewelryPriceReport />}
            />

            <Route
              exact
              path="/globalJewelryAddToCartReport"
              element={<GlobalJewelryAddToCartReport />}
            />

            <Route
              exact
              path="/globalJewerlyWishListReport"
              element={<GlobalJewerlyWishListReport />}
            />

            <Route
              exact
              path="/globalDiamondAddToCartReport"
              element={<GlobalDiamondAddToCartReport />}
            />

            <Route
              exact
              path="/globalDiamondWishListReport"
              element={<GlobalDiamondWishListReport />}
            />

            {/* <Route
                exact
                path="/diamondAddToCartReport"
                element={<DiamondAddToCartReport />}
              /> */}

            {/* <Route
                exact
                path="/diamondWishListReport"
                element={<DiamondWishListReport />}
              /> */}

            <Route
              exact
              path="/jewerlyWishListReport"
              element={<JewerlyWishListReport />}
            />

            {/* Purchase Order Starts */}

            <Route
              exact
              path="/purchaseOrderHistory"
              element={<PurchaseOrderHistory />}
            />
            <Route
              exact
              path="/updatePurchaseOrder"
              element={<UpdatePurchaseOrder />}
            />
            <Route exact path="/PODashboard" element={<PODashboard />} />

            <Route
              exact
              path="/workSheetDetails"
              element={<WorkSheetDetails />}
            />
            <Route
              exact
              path="/purchaseOrderDetails"
              element={<PurchaseOrderDetails />}
            />
            {/* Purchase Order Ends */}
            <Route
              exact
              path="/jewelryCategoryReport"
              element={<JewelryCategoryReport />}
            />

            <Route
              exact
              path="/jewelryChartReport"
              element={<JewelryChartReport />}
            />

            {/* Purchase Order Starts */}
            <Route exact path="/purchaseOrder" element={<PurchaseOrder />} />
            <Route exact path="/myPurchaseOrder" element={<PurchaseOrder />} />

            <Route exact path="/worksheet" element={<Worksheet />} />
            <Route exact path="/myWorksheet" element={<Worksheet />} />

            <Route
              exact
              path="/purchaseOrderHistory"
              element={<PurchaseOrderHistory />}
            />
            <Route
              exact
              path="/updatePurchaseOrder"
              element={<UpdatePurchaseOrder />}
            />
            {/* Purchase Order Ends */}

            {/* Marketing Starts */}
            <Route
              exact
              path="/uploadMarketingMaterial"
              element={<UploadMarketingMaterial />}
            />
            <Route
              exact
              path="/purchaseOrderHistory"
              element={<PurchaseOrderHistory />}
            />
            {/* Marketing Ends */}

            {/* Header Avatar Dropdown Starts */}
            <Route exact path="/companyProfile" element={<CompanyProfile />} />
            <Route exact path="/userSettings" element={<UserSettings />} />
            <Route
              exact
              path="/socialIntegrations"
              element={<SocialIntegrations />}
            />
            <Route
              exact
              path="/companyLocation"
              element={<CompanyLocation />}
            />
            <Route exact path="/userProfile" element={<UserProfile />} />
            <Route exact path="/changePassword" element={<ChangePassword />} />
            <Route exact path="/manageUser" element={<ManageUser />} />
            <Route exact path="/whiteLabel" element={<WhiteLabel />} />
            <Route
              exact
              path="/createOrganization"
              element={<Organization />}
            />

            <Route
              exact
              path="/manageCompanyShows"
              element={<ManageCompanyShows />}
            />
            <Route exact path="/manageBrands" element={<ManageBrands />} />
            <Route exact path="/manageAccount" element={<ManageAccount />} />
            <Route exact path="/manageLanguage" element={<ManageLanguage />} />
            <Route exact path="/manageCurrency" element={<ManageCurrency />} />
            {/* Header Avatar Dropdown Ends */}

            <Route exact path="/DataMaping" element={<DataMaping />} />
            <Route exact path="/brands" element={<Brands />} />
            <Route exact path="/vendors" element={<Vendor />} />
            {/* <Route exact path="/companyEmailSetting" element={<CompanyEmailSetting />} /> */}

            <Route
              exact
              path="/jewelryChartReportAdmin"
              element={<JewelryChartReportAdmin />}
            />
            <Route
              exact
              path="/jewelryCategoryReportAdmin"
              element={<JewelryCategoryReportAdmin />}
            />
            <Route
              exact
              path="/jewelryMetalTypeReport"
              element={<JewelryMetalTypeReport />}
            />
            <Route
              exact
              path="/jewelryPriceReport"
              element={<JewelryPriceReport />}
            />

            <Route
              exact
              path="/jewelryRetailerClicksReport"
              element={<JewelryRetailerClicksReport />}
            />

            <Route
              exact
              path="/jewelryVendorClicksReport"
              element={<JewelryVendorClicksReport />}
            />

            <Route exact path="/globalEvents" element={<GlobalEvents />} />

            <Route exact path="/sync" element={<Syncs />} />

            <Route exact path="/toolsreports" element={<ToolsReports />} />

            <Route exact path="/clientsReports" element={<ClientsReports />} />

            <Route exact path="/dataRelated" element={<DataRelated />} />

            <Route
              exact
              path="/jewelryLogReports"
              element={<JewelryLogReports />}
            />

            <Route exact path="/diamondClicks" element={<DiamondClicks />} />

            <Route
              exact
              path="/extendedDiamondChartReports"
              element={<ExtendedDiamondChartReports />}
            />

            <Route
              exact
              path="/diamondClarityReport"
              element={<DiamondClarityReport />}
            />

            <Route exact path="/diamondColor" element={<DiamondColor />} />

            <Route
              exact
              path="/diamondCutReport"
              element={<DiamondCutReport />}
            />

            <Route
              exact
              path="/diamondShapeReport"
              element={<DiamondShapeReport />}
            />

            <Route
              exact
              path="/diamondCertificateReport"
              element={<DiamondCertificateReport />}
            />

            <Route
              exact
              path="/diamondSizeReport"
              element={<DiamondSizeReport />}
            />

            <Route
              exact
              path="/diamondChartReportsMain"
              element={<DiamondReportsChartsIndComp />}
            />

            <Route
              exact
              path="/extendedDiamondChartReportsMain"
              element={<ExtendedDiamondChartReportsMain />}
            />

            <Route
              exact
              path="/diamondClarityReportMain"
              element={<DiamondReportsClarityIndComp />}
            />

            <Route
              exact
              path="/diamondColorMain"
              element={<DiamondReportsColorIndComp />}
            />

            <Route
              exact
              path="/diamondCutGradeReportMain"
              element={<DiamondReportsCutIndComp />}
            />

            <Route
              exact
              path="/diamondCertificateReportMain"
              element={<DiamondReportsCertificateIndComp />}
            />

            <Route
              exact
              path="/diamondShapeReportMain"
              element={<DiamondReportsShapeIndComp />}
            />

            <Route
              exact
              path="/diamondSizeReportMain"
              element={<DiamondReportsSizeIndComp />}
            />

            <Route
              exact
              path="/locationActivity"
              element={<LocationActivity />}
            />

            <Route
              exact
              path="/globalExtendedDiamondChartReports"
              element={<GlobalExtendedDiamondChartReports />}
            />

            <Route
              exact
              path="/globalDiamondClarityReport"
              element={<DiamondClarityIndComp />}
            />

            <Route
              exact
              path="/globalDiamondColorReport"
              element={<DiamondColorIndComp />}
            />

            <Route
              exact
              path="/globalDiamondCutReport"
              element={<DiamondCutIndComp />}
            />

            <Route
              exact
              path="/globalDiamondShapeReport"
              element={<DiamondShapeIndComp />}
            />

            <Route
              exact
              path="/globalDiamondCertificateReport"
              element={<DiamondCertificateIndComp />}
            />

            <Route
              exact
              path="/globalDiamondSizeReport"
              element={<DiamondSizeIndComp />}
            />

            <Route exact path="/adminDataMapping" element={<DataMaping />} />

            <Route
              exact
              path="/inboundDataMapping"
              element={<InboundClientDataMaping />}
            />
            <Route
              exact
              path="/uploadClientDataMapping"
              element={<UploadAdminProductFile />}
            />
            <Route
              exact
              path="/myVendorsRingBuilder"
              element={<MyVendorsRingBuilder />}
            />
            <Route
              exact
              path="/myVendorsStudBuilder"
              element={<MyVendorsStudBuilder />}
            />
            <Route
              exact
              path="/pendantBuilderPendingVendorRequest"
              element={<PendantBuilderPendingVendorRequest />}
            />
            <Route
              exact
              path="/myVendorsPendantBuilder"
              element={<MyVendorsPendantBuilder />}
            />
            <Route
              exact
              path="/pendingVendorRequestRingBuilder"
              element={<RingBuilderPendingVendorRequest />}
            />
            <Route
              exact
              path="/pendingVendorRequestStudBuilder"
              element={<StudBuilderPendingVendorRequest />}
            />
            <Route exact path="/vendorRequest" element={<VendorRequest />} />
            <Route exact path="/vendorRequests" element={<VendorRequest />} />
            <Route exact path="/pricingGeneral" element={<PricingGeneral />} />
            <Route exact path="/scaMaster" element={<SCAMaster />} />
          </Route>
        </Routes>
        <NotificationContainer />
      </React.Fragment>
    </BrowserRouter>
  );
};

export default App;
