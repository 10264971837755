import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";
import { Tooltip, Switch, Table, Select, Spin } from "antd";
import UnionIcon from "./../../../assets/images/icons/Union_5.svg";
import { useDispatch, useSelector } from "react-redux";
import AppsStudBuilderService from "../../../services/apps-studbuilder.service";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import communityVendorService from "../../../services/community-vendor.service";
import { setBindActivePageName } from "../../../actions/Community/ManageInventory";
import { setManageTabName } from "./../../../actions/Community/ManageInventory";

const { Option } = Select;
const MyVendorsStudBuilder = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [totalCount, setTotalCount] = useState(0);
	const [selectedColumnId, setSelectedColumnId] = useState(0);
	const [ringBuilderMyVendorData, setRingBuilderMyVendorData] = useState([]);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [dealerId, setDealerId] = useState(0);
	const [getLoading, setGetLoading] = useState(false);
	const [rowDetails, setRowDetails] = useState([]);
	const [loadingProfileData, setLoadingProfileData] = useState(false);
	const [brandType, setBrandType] = useState([]);
	const [sortColumnName, setSortColumnName] = useState("dealerCompany");
	const [sortType, setSortType] = useState("asc");
	const [switchValue, setswitchValue] = useState(true);
	const [loading, setLoading] = useState(false);
	const [vendorTypeData, setVendorTypeData] = useState([
		{ value: "2", name: "Designer" },
		{ value: "1", name: "Watch Brand" },
		{ value: "0", name: "Bridal Designer" },
	]);

	const loginDetails = useSelector((state) => state.loginReducer);

	const style = (
		<span>
			{" "}
			{t(
				"If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"
			)}
		</span>
	);

	const columns = [
		{
			title: t("Vendor"),
			dataIndex: "dealerCompany",
			sorter: (a, b) => {},
			showSorterTooltip: false,
		},
		{
			title: t("Type"),
			dataIndex: "vendorStatus",
			width: 120,
			sorter: (a, b) => {},
			showSorterTooltip: false,
		},
		{
			title: t("Data Rating"),
			dataIndex: "ratingCount",
			width: 160,
			sorter: (a, b) => {},
			showSorterTooltip: false,
			render: (index, row) => (
				<StarRatings
					rating={row.ratingCount}
					starRatedColor="#1883b8"
					starDimension="18px"
					starSpacing="0px"
					numberOfStars={5}
				/>
			),
		},
		{
			title: t("Active Products"),
			dataIndex: "activeproducts",
			width: 100,
			// sorter: (a, b) => {},
			showSorterTooltip: false,
			render: (index, row) => {
				return (
					<React.Fragment>
						{row.totalNumber == "" || row.totalNumber == 0
							? "-"
							: row.activeproduct + "/" + row.totalNumber}
					</React.Fragment>
				);
			},
		},
		{
			title: t("Updated"),
			dataIndex: "modified",
			width: 120,
			sorter: (a, b) => {},
			showSorterTooltip: false,
		},
		{
			title: (
				<label className="table__label">
					{" "}
					{t("Accept New Collection")}
					<Tooltip placement="right" className="ml-1" title={style}>
						<img src={UnionIcon} alt="" />
					</Tooltip>
				</label>
			),
			dataIndex: "approvedByRetailer",
			width: 150,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="approvedByRetailer"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.approvedByRetailer == "0" ? false : true}
							onChange={(e) => {
								handleOnChange(row, e, "approvedByRetailer");
							}}
						/>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		if (loginDetails) {
			if (loginDetails.loginDetials) {
				if (loginDetails.loginDetials.responseData) {
					if (loginDetails.loginDetials.responseData.dealerId) {
						setDealerId(loginDetails.loginDetials.responseData.dealerId);
						handleRingBuilderBindDataCommVendor(
							loginDetails.loginDetials.responseData.dealerId,
							pageNo,
							pageSize,
							"dealerCompany",
							"asc"
						);
					}
				}
			}
		}
	}, []);

	const handleRingBuilderBindDataCommVendor = async (
		id,
		pageNo,
		pageSize,
		sortColumnName,
		sortOrder
	) => {
		let inputData = {
			dealerID: id,
			filerType: "1",
			currentPage: pageNo,
			recordOnPage: pageSize,
			searchKeyChar: "",
			searchType: "",
			sortColumnName: sortColumnName || "dealerCompany",
			sortOrder: sortOrder || "ASC",
			lstColumnFilter: [],
		};
		setGetLoading(true);
		AppsStudBuilderService.StusBuilderBindDataCommVendor(inputData)
			.then((response) => {
				let message = response.data.message;
				let responseData = response.data.responseData;

				if (message === "Success") {
					if (responseData?.vendor_NetworkResponse) {
						setTotalCount(responseData?.totalRecords);
						setRingBuilderMyVendorData(responseData?.vendor_NetworkResponse);
					} else {
						setTotalCount(0);
						setRingBuilderMyVendorData([]);
					}
				} else {
					setRingBuilderMyVendorData([]);
					setTotalCount(0);
					setSelectedColumnId(0);
					setRowDetails({});
				}
			})
			.catch((error) => {})
			.finally(() => {
				setGetLoading(false);
			});
	};

	const handleOnChange = (row, e, name) => {
		if (name === "approvedByRetailer") {
			const temp = ringBuilderMyVendorData.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, approvedByRetailer: e ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setRingBuilderMyVendorData(temp);
			handleUpdateAcceptCollections(saveRow);
		}
	};

	const handleUpdateAcceptCollections = async (saveRow) => {
		let inputData = {
			dealerID: dealerId.toString(),
			manuID: saveRow.dealerID.toString(),
			isGrant: saveRow.approvedByRetailer.toString(),
		};
		communityVendorService
			.UpdateAcceptCollections(inputData)
			.then((response) => {
				let message = response.data.message;
				if (message === "Success") {
					if (saveRow.approvedByRetailer == 1) {
						NotificationManager.success(
							t("Accept New Collection Has Been Granted Successfully.")
						);
					} else {
						NotificationManager.success(
							t("Accept New Collection Has Been Denied.")
						);
					}
				} else {
					NotificationManager.error(
						t("Accept New Collection Has Not Been Granted Successfully.")
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleShowDataToRightNew = (record) => {
		let inputData = {
			vendorID: record.dealerID.toString(),
			isEnable: record.isEnabled.toString(),
			f_Type: record.filerType,
			dealerID: dealerId.toString(),
		};
		setSelectedColumnId(record.dealerID);
		setLoadingProfileData(true);
		dispatch(setBindActivePageName("Network"));
		AppsStudBuilderService.StudBuilderShowDataToRightNew(inputData)
			.then((response) => {
				let message = response.data.message;
				let responseData = response.data.responseData[0];
				let brandData = response.data.responseData[0].brandType;
				if (brandData) {
					var data = Object.values(brandData)
						.map((value) => value)
						.filter((x) => x !== "" && x !== null);
				}
				if (message === "Success") {
					if (responseData) {
						setRowDetails(responseData);
						setBrandType(data);
					} else {
						setRowDetails({});
					}
				} else {
					setRowDetails({});
				}
				setLoadingProfileData(false);
			})
			.catch((error) => {
				console.log(error);
				setLoadingProfileData(false);
			});
	};

	const handleTableASCDES = (pagination, filters, sorter) => {
		var type = "";
		var column = "";
		var filter = [];
		var obj = {};
		//sorting
		if (sorter.order == "descend") {
			type = "DESC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else if (sorter.order == "ascend") {
			type = "ASC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else {
			type = "ASC";
			column = "dealerCompany";
			setSortColumnName(column);
			setSortType(type);
		}

		setPageNo(pagination.current);
		setPageSize(pagination.pageSize);
		handleRingBuilderBindDataCommVendor(
			dealerId,
			pagination.current,
			pagination.pageSize,
			column,
			type
		);
	};

	const handleSelectOnChange = (value) => {
		if (value.includes("All")) {
			setBrandType(["0", "1", "2", "All"]);
		} else if (value.length == vendorTypeData.length) {
			setBrandType(["0", "1", "2", "All"]);
		} else {
			setBrandType(value);
		}
	};

	const handleSaveSelect = () => {
		let inputData = {
			dealerID: dealerId.toString(),
			vendorId: selectedColumnId.toString(),
			vLocation: brandType.includes("all")
				? "all"
				: brandType.join(",").toString(),
			ModuleName: "Stud Builder",
		};
		setLoadingProfileData(true);
		communityVendorService
			.ManageLocation(inputData)
			.then((response) => {
				let message = response.data.message;
				if (message === "Success") {
					NotificationManager.success(t("Updated Successfully."));
				} else {
					NotificationManager.error(t("Data Not Updated Successfully"));
				}
				setLoadingProfileData(false);
			})
			.catch((error) => {
				console.log(error);
				setLoadingProfileData(false);
			});
	};

	const handleSwitch = () => {
		setswitchValue(!switchValue);
		handleUpdateInventoryByDesignerID();
	};

	const handleUpdateInventoryByDesignerID = async () => {
		let inputData = {
			designerID: selectedColumnId,
			dealerID: dealerId,
		};
		setLoading(true);
		communityVendorService
			.UpdateInventoryByDesignerID(inputData)
			.then((response) => {
				let message = response.data.message;
				if (message === "Success") {
					NotificationManager.success(
						t("Connection Status Changed Successfully.")
					);
					handleRingBuilderBindDataCommVendor(
						dealerId,
						pageNo,
						pageSize,
						sortColumnName,
						sortType
					);

					let objRowDetails = rowDetails;
					objRowDetails.isEnable = "false";
					setRowDetails({ ...objRowDetails });
					setSelectedColumnId(0);
				} else {
					NotificationManager.error(t("Connection Status Not Changed."));
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const openInNewTab = () => {
		let url = rowDetails.website;
		if (rowDetails.website.includes("https")) {
			window.open(url, "_blank");
		} else if (rowDetails.website.includes("http")) {
			window.open(url, "_blank");
		} else {
			window.open("https://" + url, "_blank");
		}
	};
	const handleDispatchTabName = () => {
		dispatch(setManageTabName("Manage"));
	};
	return (
		<div className="form__fields">
			<Spin spinning={getLoading}>
				<div className="row magento__mapping permission__section">
					<div className="col-md-12">
						<div className="heading__block mb-3">
							<h4 className="workarea__heading mt-0 mb-0">
								{" "}
								{t("My Vendors")} {"(" + totalCount + ")"}
							</h4>
						</div>
					</div>
					<div
						className={
							selectedColumnId !== 0
								? "col-lg-9 divForm vendors-section"
								: "col-lg-12"
						}>
						<Table
							columns={columns}
							total={totalCount}
							dataSource={ringBuilderMyVendorData}
							scroll={{ x: 600, y: 800 }}
							onChange={handleTableASCDES}
							pagination={{
								total: totalCount,
								pageNo: pageNo,
								pageSize: pageSize,
								pageSizeOptions: [10, 20, 50, 100],
								responsive: true,
								showSizeChanger: true,
							}}
							onRow={(record, recordIndex) => ({
								onClick: (event) => {
									handleShowDataToRightNew(record);
								},
							})}
							rowClassName={(record) =>
								record.dealerID == selectedColumnId ? "row-active" : ""
							}
						/>
					</div>
					{selectedColumnId !== 0 ? (
						<div className="col-lg-3 col-md-3">
							<Spin spinning={loadingProfileData}>
								<div className="right__section">
									<div className="profile__block">
										<span className="linkText">
											{rowDetails.designerName ? rowDetails.designerName : null}
										</span>

										<div className="logoImg__block p-0">
											<img src={rowDetails.src ? rowDetails.src : null} />
										</div>
										<p className="activeFrom">
											{" "}
											{t("Active")} |{" "}
											{rowDetails.activity ? rowDetails.activity : null}{" "}
										</p>

										<div className="star-ratings">
											<p className="mb-0"> {t("Data Ratings")} </p>
											<StarRatings
												rating={rowDetails.ratingCount}
												starRatedColor="#1883b8"
												starDimension="18px"
												starSpacing="0px"
												numberOfStars={5}
											/>
										</div>
									</div>

									<div className="retailer__dtls mb-4">
										<p>
											{" "}
											{rowDetails.dealerName
												? rowDetails.dealerName
												: null}{" "}
										</p>
										<a href={`mailto:${rowDetails.dealerEmail}`}>
											{" "}
											{rowDetails.dealerEmail
												? rowDetails.dealerEmail
												: null}{" "}
										</a>
										<p>
											{" "}
											{rowDetails.dealerPhone
												? rowDetails.dealerPhone
												: null}{" "}
										</p>
										<span className="linkText" onClick={() => openInNewTab()}>
											{rowDetails.website ? rowDetails.website : null}
										</span>
										<hr />
									</div>
									<div className="links__block">
										<ul>
											<li>
												<Link
													to={"/vendorProfile"}
													state={{
														key: "Profile",
														id: selectedColumnId,
														previousPage: "StudBuilder",
													}}>
													<i
														className="fa fa-address-book-o"
														aria-hidden="true"></i>{" "}
													{t("View Profile")}
												</Link>
											</li>
											<li>
												<Link
													to={"/vendorProfile"}
													state={{
														key: "Conversation",
														id: selectedColumnId,
													}}>
													<i
														className="fa fa-comments-o"
														aria-hidden="true"></i>{" "}
													{t("Conversation")}
												</Link>
											</li>
											{rowDetails.vendorStatus !== "Profile Only" &&
											rowDetails.vendorStatus !== "Out of Network" ? (
												<li onClick={() => handleDispatchTabName()}>
													<Link
														to={"/vendorProfile"}
														state={{
															key: "Inventory",
															id: selectedColumnId,
														}}>
														<i className="fa fa-diamond" aria-hidden="true"></i>{" "}
														{t("Manage Inventory")}
													</Link>
												</li>
											) : null}

											<li>
												<Link
													to={"/vendorProfile"}
													state={{
														key: "Location",
														id: selectedColumnId,
													}}>
													<i className="fa fa-globe" aria-hidden="true"></i>{" "}
													{t("View Location")}
												</Link>
												<a href="#"></a>
											</li>
										</ul>
										<hr />
									</div>

									<div className="bottom__block">
										<div className="input__block">
											<label> {t("Vendor Type")} </label>
											<Select
												placeholder={t("Vendor Type")}
												name="productType"
												optionFilterProp="children"
												mode="multiple"
												value={
													brandType
														? brandType.includes("All")
															? "All"
															: brandType
														: brandType
												}
												onChange={(e) => handleSelectOnChange(e)}>
												<Option value="All"> {t("All")} </Option>
												{vendorTypeData.map((type) => {
													return (
														<Option
															disabled={
																brandType
																	? brandType.includes("All")
																		? true
																		: false
																	: false
															}
															value={type.value}>
															{" "}
															{t(`${type.name}`)}{" "}
														</Option>
													);
												})}
											</Select>
										</div>

										<div className="input__block mt__1">
											<button
												className="primary-btn"
												onClick={handleSaveSelect}>
												{" "}
												{t("Save")}{" "}
											</button>
										</div>
										{rowDetails.isEnable == "true" ? (
											<div className="input__block">
												<label> {t("Connection Status")} </label>
												<Switch
													checkedChildren={t("On")}
													unCheckedChildren={t("Off")}
													defaultChecked
													onChange={handleSwitch}
												/>
											</div>
										) : null}
									</div>
								</div>
							</Spin>
						</div>
					) : null}
				</div>
			</Spin>
		</div>
	);
};

export default MyVendorsStudBuilder;
