import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import "./assets/css/style.css";

// REDUX
import { Provider } from "react-redux";
// import store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistedStore, store } from "./redux/store";
import { CookiesProvider } from "react-cookie";

import { IntlProvider } from "react-intl";

const messages = {
  en: {
    greeting: "Gemfind",
  },
};

const locale = "en";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistedStore}>
        <CookiesProvider>
          <IntlProvider locale={locale} messages={messages[locale]}>
            <App />
          </IntlProvider>
        </CookiesProvider>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
