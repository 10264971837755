import React, { useState, useEffect } from "react";
import { Modal, Select, Switch, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import catalogDiamondService from "../../../services/catalog-diamond.service";
import CompanyLocationService from "../../../services/company-location.service";
import { useNavigate } from "react-router-dom";

const AddSignleDiamond = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Diamond Data
  const initialState = {
    inventory: "",
    matchedPairStock: "",
    stones: "",
    separable: true,
    size: 0.05,
    cost: 1000,
    shape: "",
    color: "",
    fancyCodes: "",
    fancyNames: "",
    other: "",
    clarity: "",
    cutGrade: "",
    certificate: "",
    certificateNum: "",
    depth: 0,
    table: 0,
    measurements: "",
    polish: "",
    symmetry: "",
    fluorescence: "",
    girdle: "",
    culet: "",
    culetCondition: "",
    crownAngle: 0,
    pavillionAngle: 0,
    comments: "",
    storeLocation: "",
    employeeName: "",
    employeeId: "",
    outOnMemo: true,
    seenByOtherDealers: true,
    addVideo: false,
    TextVideoUrl: "",
    isLabCreated: false,
    fileN: [],
    fileName: "",
    Error: {},
  };
  const [diamondData, setDiamondData] = useState(initialState);
  const [vendorStatusID, setVendorStatusID] = useState(0);
  const [validations, setValidations] = useState({
    inventoryVal: "",
    matchedPairStockVal: "",
    stonesVal: "",
    separableVal: "",
    sizeVal: "",
    costVal: "",
    shapeVal: "",
    colorVal: "",
    fancyCodesVal: "",
    fancyNamesVal: "",
    otherVal: "",
    clarityVal: "",
    cutGradeVal: "",
    certificateVal: "",
    certificateNumVal: "",
    depthVal: "",
    tableVal: "",
    measurementsVal: "",
    polishVal: "",
    symmetryVal: "",
    fluorescenceVal: "",
    girdleVal: "",
    culetVal: "",
    culetConditionVal: "",
    crownAngleVal: "",
    pavillionAngleVal: "",
    commentsVal: "",
    storeLocationVal: "",
    employeeNameVal: "",
    employeeIdVal: "",
    outOnMemoVal: "",
    seenByOtherDealersVal: "",
    addVideoVal: "",
    isLabCreatedVal: "",
    fileNVal: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [DealerId, setDealerId] = useState(null);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [name, setName] = useState();
  const [visible6, setVisible6] = useState(false);
  const [cutList, setCutList] = useState([]);
  const [clarityList, setClarityList] = useState([]);
  const [polishList, setPolishList] = useState([]);
  const [summetryList, setSummetryList] = useState([]);
  const [girdleList, setGirdleList] = useState([]);
  const [culetList, setCuletList] = useState([]);
  const [culetCondList, setCuletCondList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [shapeList, setShapeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const currency = useSelector((state) => state.loginReducer.currency);
  const currencySymbol =
    currency && currency.substring(currency.lastIndexOf("-") + 1);

  const fancyCodeList = [
    {
      title: "FB",
      value: "FB",
    },
    {
      title: "FBGNY",
      value: "FBGNY",
    },
    {
      title: "FBY",
      value: "FBY",
    },
    {
      title: "FDBGY",
      value: "FDBGY",
    },
    {
      title: "FDBR",
      value: "FDBR",
    },
    {
      title: "FDBY",
      value: "FDBY",
    },
    {
      title: "FDOY",
      value: "FDOY",
    },
    {
      title: "FDY",
      value: "FDY",
    },
    {
      title: "FDYO",
      value: "FDYO",
    },
    {
      title: "FIP",
      value: "FIP",
    },
    {
      title: "FIY",
      value: "FIY",
    },
    {
      title: "FLB",
      value: "FLB",
    },
    {
      title: "FLP",
      value: "FLP",
    },
    {
      title: "FLY",
      value: "FLY",
    },
    {
      title: "FORB",
      value: "FORB",
    },
    {
      title: "FP",
      value: "FP",
    },
    {
      title: "FTP",
      value: "FTP",
    },
    {
      title: "FVY",
      value: "FVY",
    },
    {
      title: "FVYO",
      value: "FVYO",
    },
    {
      title: "FY",
      value: "FY",
    },
    {
      title: "FYB",
      value: "FYB",
    },
    {
      title: "FYD",
      value: "FYD",
    },
    {
      title: "FYE",
      value: "FYE",
    },
    {
      title: "FTD",
      value: "FTD",
    },
    {
      title: "FYI",
      value: "FYI",
    },
    {
      title: "FYL",
      value: "FYL",
    },
    {
      title: "FYO",
      value: "FYO",
    },
    {
      title: "FYV",
      value: "FYV",
    },
    {
      title: "LFY",
      value: "LFY",
    },
    {
      title: "LP",
      value: "LP",
    },
    {
      title: "LPB",
      value: "LPB",
    },
    {
      title: "LYG",
      value: "LYG",
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        setVendorStatusID(loginDetials?.responseData?.vendorStatusID || 0);
        setName(loginDetials.responseData.dealerName);
        handleGetAllMINEDropDownList(loginDetials.responseData.dealerName);
        handleGetCertificatesForDiamondCatalogList(
          loginDetials.responseData.dealerName
        );
        handleGetLocationDetails(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  const onSearch = (val) => {};
  //Onchange Event
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setDiamondData({
        ...diamondData,
        [name]: e,
      });
      if (name == "color") {
        setValidations((prevdata) => ({
          ...prevdata,
          colorVal: "",
        }));
      }
      if (name == "clarity") {
        setValidations((prevdata) => ({
          ...prevdata,
          clarityVal: "",
        }));
      }
      if (name == "cutGrade") {
        setValidations((prevdata) => ({
          ...prevdata,
          cutGradeVal: "",
        }));
      }
      if (name == "shape") {
        setValidations((prevdata) => ({
          ...prevdata,
          shapeVal: "",
        }));
      }
    } else {
      if (e.target.name == "cost") {
        if (e.target.value == "0") {
          e.preventDefault();
        } else {
          setDiamondData({
            ...diamondData,
            [e.target.name]: e.target.value,
          });
        }
      } else {
        setDiamondData({
          ...diamondData,
          [e.target.name]: e.target.value,
        });
      }

      if (e.target.name == "inventory") {
        setValidations((prevdata) => ({
          ...prevdata,
          inventoryVal: "",
        }));
      }
      if (e.target.name == "size") {
        setValidations((prevdata) => ({
          ...prevdata,
          sizeVal: "",
        }));
      }
      if (e.target.name == "cost") {
        setValidations((prevdata) => ({
          ...prevdata,
          costVal: "",
        }));
      }
      if (e.target.name == "stones") {
        setValidations((prevdata) => ({
          ...prevdata,
          stonesVal: "",
        }));
      }
    }
  };

  //Handle Validations
  const handleValidation = () => {
    let isValid = true;
    if (!diamondData.inventory) {
      isValid = false;
      validations.inventoryVal = t("Inventory is compulsory");
    } else {
      validations.inventoryVal = "";
    }
    var regex = /^[0-9]+$/;
    if (diamondData.stones) {
      if (!diamondData.stones.match(regex)) {
        isValid = false;
        validations.stonesVal = t("Stones can only be numbers");
      } else {
        validations.stonesVal = "";
      }
    }
    if (!diamondData.size) {
      isValid = false;
      validations.sizeVal = t("Size is compulsory");
    } else {
      validations.sizeVal = "";
    }
    if (!diamondData.cost) {
      isValid = false;
      validations.costVal = t("Cost is compulsory");
    } else {
      validations.costVal = "";
    }

    if (!diamondData.color) {
      isValid = false;
      validations.colorVal = t("Kindly select the color");
    } else {
      validations.colorVal = "";
    }

    if (!diamondData.clarity) {
      isValid = false;
      validations.clarityVal = t("Kindly select the clarity");
    } else {
      validations.clarityVal = "";
    }
    if (!diamondData.cutGrade) {
      isValid = false;
      validations.cutGradeVal = t("Kindly select the cut grade");
    } else {
      validations.cutGradeVal = "";
    }
    if (!diamondData.shape) {
      isValid = false;
      validations.shapeVal = t("Kindly select the shape");
    } else {
      validations.shapeVal = "";
    }
    if (
      diamondData.employeeId.match(
        /`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s/g
      ) ||
      diamondData.employeeId.match(/^[a-zA-Z]+$/)
    ) {
      isValid = false;
      validations.employeeIdVal =
        "Kindly do not use special characters or alphabets";
    } else {
      validations.employeeIdVal = "";
    }
    setValidations((prevState) => ({
      ...prevState,
      ...validations,
    }));
    return isValid;
  };

  const { Option } = Select;

  const handleSubmit = (e) => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    } else {
      handleSaveDiamond();
    }
  };
  const handleVisible6 = () => {
    setVisible6(true);
  };
  const handleOnOk = () => {
    setVisible6(false);
    if (diamondData.isLabCreated == true) {
      navigate("/viewMyLabDiamond");
    } else {
      navigate("/viewMyMinedDiamond");
    }
  };

  const handleGetAllMINEDropDownList = async (id) => {
    try {
      let inputData = {};
      await catalogDiamondService
        .GetAllDropdownData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCutList(responseData.diamondCutGrade);
            setClarityList(responseData.clarity);
            setPolishList(responseData.diamondPolish);
            setSummetryList(responseData.diamondSymmetry);
            setGirdleList(responseData.diamondGirdle);
            setCuletList(responseData.diamondCulet);
            setCuletCondList(responseData.diamondCuletCondition);
            setShapeList(responseData.diamondShape);
            setColorList(responseData.diamondColor);
          } else {
            setCutList([]);
            setClarityList([]);
            setPolishList([]);
            setSummetryList([]);
            setGirdleList([]);
            setCuletList([]);
            setCuletCondList([]);
            setShapeList([]);
            setColorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCertificatesForDiamondCatalogList = async (id) => {
    try {
      let inputData = {};
      await catalogDiamondService
        .GetCertificatesForDiamondCatalogList(inputData)
        .then((response) => {
          handleGetAllMINEDropDownList();
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setCertificateList(responseData);
          } else {
            setCertificateList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveDiamond = () => {
    setButtonLoading(true);
    const formData = new FormData();

    formData.append("diamondDetailList.InventoryOnly", false);
    formData.append("diamondDetailList.PublicViewing", false);
    formData.append(
      "diamondDetailList.OutonMemo",
      diamondData.outOnMemo ? "1" : "0"
    );
    formData.append("diamondDetailList.Sold", false);
    formData.append(
      "diamondDetailList.InventoryRegion",
      diamondData.storeLocation ? diamondData.storeLocation : ""
    );
    formData.append("diamondDetailList.DealerID", parseInt(DealerId));
    formData.append("diamondDetailList.Comments", diamondData.comments + "");
    formData.append(
      "diamondDetailList.DealerInventoryNo",
      diamondData.inventory ? diamondData.inventory : ""
    );
    formData.append("diamondDetailList.EmployeeName", name ? name + "" : "");
    formData.append(
      "diamondDetailList.EmployeeID",
      diamondData.employeeId ? diamondData.employeeId + "" : ""
    );
    formData.append(
      "diamondDetailList.Cut",
      diamondData.shape ? diamondData.shape + "" : ""
    );
    formData.append(
      "diamondDetailList.Measurements",
      diamondData.measurements ? diamondData.measurements + "" : ""
    );
    formData.append("diamondDetailList.Parcel", "");
    formData.append(
      "diamondDetailList.TableMeasure",
      diamondData.table ? parseFloat(diamondData.table) : 0
    );
    formData.append(
      "diamondDetailList.Depth",
      diamondData.depth ? parseFloat(diamondData.depth) : 0
    );
    formData.append(
      "diamondDetailList.Color",
      diamondData.color ? diamondData.color[0] + "" : ""
    );
    formData.append(
      "diamondDetailList.ClarityID",
      diamondData.clarity ? parseInt(diamondData.clarity) : 0
    );
    formData.append(
      "diamondDetailList.Size",
      diamondData.size ? parseFloat(diamondData.size) : 0
    );
    formData.append(
      "diamondDetailList.Polish",
      diamondData.polish ? diamondData.polish + "" : ""
    );
    formData.append(
      "diamondDetailList.Symmetry",
      diamondData.symmetry ? diamondData.symmetry + "" : ""
    );
    formData.append(
      "diamondDetailList.Flouresence",
      diamondData.fluorescence ? diamondData.fluorescence + "" : ""
    );
    formData.append(
      "diamondDetailList.Girdle",
      diamondData.girdle ? diamondData.girdle + "" : ""
    );
    formData.append(
      "diamondDetailList.Crown",
      diamondData.crownAngle ? diamondData.crownAngle + "" : ""
    );
    formData.append(
      "diamondDetailList.Culet",
      diamondData.culet ? diamondData.culet + "" : ""
    );
    formData.append(
      "diamondDetailList.Pavillion",
      diamondData.pavillionAngle ? diamondData.pavillionAngle + "" : ""
    );
    formData.append(
      "diamondDetailList.CertificateNo",
      diamondData.certificateNum ? diamondData.certificateNum + "" : ""
    );
    formData.append(
      "diamondDetailList.Stones",
      diamondData.stones ? parseInt(diamondData.stones) : 0
    );

    formData.append(
      "diamondDetailList.CostperCarat",
      diamondData.cost ? parseFloat(diamondData.cost) : 0
    );
    formData.append("diamondDetailList.SellingPrice", 0.0);
    formData.append(
      "diamondDetailList.Certificate",
      diamondData.certificate ? diamondData.certificate + "" : ""
    );
    formData.append(
      "diamondDetailList.PairSeparable",
      diamondData.separable ? "Yes" : "No"
    );
    formData.append(
      "diamondDetailList.PairStockNumber",
      diamondData.matchedPairStock ? diamondData.matchedPairStock + "" : ""
    );
    formData.append(
      "diamondDetailList.CutGrade",
      diamondData.cutGrade ? diamondData.cutGrade + "" : ""
    );
    formData.append(
      "diamondDetailList.CuletCondition",
      diamondData.culetCondition + ""
    );

    formData.append("diamondDetailList.CrownPercentage", "");

    formData.append("diamondDetailList.PavilionPercentage", "");
    formData.append("diamondDetailList.FancyColorMainBody", "");
    formData.append("diamondDetailList.FancyColorOvertone", "");
    formData.append("diamondDetailList.IsActive", "");
    formData.append("diamondDetailList.City", "");
    formData.append("diamondDetailList.State", "");
    formData.append("diamondDetailList.Country", "");
    formData.append("diamondDetailList.IsThirdParty", 0);
    formData.append("diamondDetailList.ImageFileName", "");
    formData.append("diamondDetailList.IsvideoUrl", diamondData.addVideo);
    formData.append("diamondDetailList.IsLabCreated", diamondData.isLabCreated);
    formData.append(
      "diamondDetailList.TextVideoUrl",
      diamondData.TextVideoUrl ? diamondData.TextVideoUrl + "" : null
    );
    formData.append(
      "diamondDetailList.UploadFile",
      diamondData.fileN[0] ? diamondData.fileN[0] : null
    );
    formData.append("diamondDetailList.VideoFileName", "");
    formData.append(
      "DiamondDealer.dealerId",
      DealerId ? parseInt(DealerId) : 0
    );

    CatalogDiamondDataService.SaveDiamond(formData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(t("Record added successfully."));
          setDiamondData(initialState);
          handleVisible6();
        } else {
          NotificationManager.error(t("Record not added."));
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        console.log(error);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  /*Checkbox change starts*/
  function onChangeCheck(e, name) {
    let tempState = diamondData;

    tempState[name] = e.currentTarget.checked;

    setDiamondData((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  /*Checkbox change ends*/

  const onKeyPressMinusEvent = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  // Add Location
  const [LocationDetailsLoader, setLocationDetailsLoader] = useState(true);
  const [locationDetails, setLocationDetails] = useState([]);
  const handleGetLocationDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLocationDetailsLoader(true);
    CompanyLocationService.GetLocationDetails(inputData).then((response) => {
      let message = response.data.message;
      let responseData = response.data.responseData.Table;
      if (message === "Success") {
        setLocationDetailsLoader(false);
        setLocationDetails(responseData);
        if (responseData.length == 1) {
          setDiamondData((prevState) => ({
            ...prevState,
            storeLocation: response.data.responseData.Table[0].LocationName,
          }));
        }
      }
    });
  };

  return (
    <div className="prodetail__section">
      {vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-3">
                    {" "}
                    {t("Add Diamond")}{" "}
                  </h4>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Inventory #")}
                      <span className="mandatory">*</span>{" "}
                    </label>
                    <input
                      min="0"
                      placeholder={t("Enter Inventory")}
                      name="inventory"
                      value={diamondData.inventory}
                      onChange={(e) => handleOnChange(e, "", "inventory")}
                      className={validations.inventoryVal && "border__red"}
                    />
                    <div>
                      {validations.inventoryVal && (
                        <p className="error-color-red">
                          {validations.inventoryVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Matched Pair Stock #")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter matched stock pair")}
                      name="matchedPairStock"
                      value={diamondData.matchedPairStock}
                      onChange={(e) =>
                        handleOnChange(e, "", "matchedPairStock")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Stones (Parcel)")} </label>
                    <input
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Enter stones")}
                      name="stones"
                      value={diamondData.stones}
                      onChange={(e) => handleOnChange(e, "", "stones")}
                      className={validations.stonesVal && "border__red"}
                    />
                    <div>
                      {validations.stonesVal && (
                        <p className="error-color-red">
                          {validations.stonesVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Separable")} </label>
                    <Switch
                      name="separable"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={diamondData.separable}
                      onChange={(e) => handleOnChange(e, "select", "separable")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Size")} <span className="mandatory">*</span>
                    </label>
                    <input
                      type="number"
                      min="0.01"
                      step="0.01"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Enter the size")}
                      name="size"
                      value={diamondData.size}
                      onChange={(e) => handleOnChange(e, "", "size")}
                      className={validations.sizeVal && "border__red"}
                    />
                    <div>
                      {validations.sizeVal && (
                        <p className="error-color-red">{validations.sizeVal}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block percent__block currency__block">
                    <label>
                      {" "}
                      {t("Cost")} / {t("Carat")}{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Enter the cost")}
                      name="cost"
                      value={diamondData.cost}
                      onChange={(e) => handleOnChange(e, "", "cost")}
                      className={validations.costVal && "border__red"}
                    />
                    <span
                      className="percent__symbol"
                      style={{ left: "6px", right: "0" }}
                    >
                      {currencySymbol}
                    </span>
                    <div>
                      {validations.costVal && (
                        <p className="error-color-red">{validations.costVal}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Shape")}
                      <span className="mandatory">*</span>{" "}
                    </label>
                    <Select
                      value={diamondData.shape ? diamondData.shape : null}
                      name="shape"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "shape")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.shapeVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option>{t("Select")}</Option>
                      {shapeList &&
                        shapeList.map((item, i) => {
                          return (
                            <Option value={item.shapeName}>
                              {item.shapeName}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.shapeVal && (
                        <p className="error-color-red">
                          {validations.shapeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Color")} <span className="mandatory">*</span>{" "}
                    </label>{" "}
                    <Select
                      value={diamondData.color ? diamondData.color : null}
                      name="shape"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "color")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.colorVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option>{t("Select")}</Option>
                      {colorList &&
                        colorList.map((item, i) => {
                          return (
                            <Option value={item.diamondColor}>
                              {item.diamondColor}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.colorVal && (
                        <p className="error-color-red">
                          {validations.colorVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Fancy Codes")} </label>
                    <Select
                      value={
                        diamondData.fancyCodes ? diamondData.fancyCodes : null
                      }
                      name="fancyCodes"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "fancyCodes")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.fancyCodesVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option>{t("Select")}</Option>
                      {fancyCodeList &&
                        fancyCodeList.map((item, i) => {
                          return (
                            <Option value={item.value}>{item.title}</Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.fancyCodesVal && (
                        <p className="error-color-red">
                          {validations.fancyCodesVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Fancy Names")} </label>
                    <Select
                      value={
                        diamondData.fancyNames ? diamondData.fancyNames : null
                      }
                      name="fancyNames"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "fancyNames")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.fancyNamesVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option>{t("Select")}</Option>
                      <Option value="Blue, Caribbean">Blue, Caribbean</Option>
                      <Option value="Blue, Sky">Blue, Sky</Option>
                      <Option value="Blue">Blue</Option>
                      <Option value="Brown">Brown</Option>
                      <Option value="Cognac">Cognac</Option>
                      <Option value="Green, Forest">Green, Forest</Option>
                      <Option value="Green, Olive">Green, Olive</Option>
                      <Option value="Peach">Peach</Option>
                      <Option value="Pink">Pink</Option>
                      <Option value="Pink, Light">Pink, Light</Option>
                      <Option value="Red">Red</Option>
                      <Option value="Yellow, Cannery">Yellow, Cannery</Option>
                      <Option value="Yellow, Light Greenish">
                        Yellow, Light Greenish
                      </Option>
                      <Option value="Yellow, Fancy">Yellow, Fancy</Option>
                    </Select>
                    <div>
                      {validations.fancyNamesVal && (
                        <p className="error-color-red">
                          {validations.fancyNamesVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Other")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter other")}
                      name="other"
                      value={diamondData.other}
                      onChange={(e) => handleOnChange(e, "", "other")}
                      className={validations.otherVal && "border__red"}
                    />
                    <div>
                      {validations.otherVal && (
                        <p className="error-color-red">
                          {validations.otherVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Clarity")} <span className="mandatory">*</span>{" "}
                    </label>{" "}
                    <Select
                      value={diamondData.clarity ? diamondData.clarity : null}
                      name="clarity"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "clarity")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.clarityVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {clarityList &&
                        clarityList.map((item, i) => {
                          return (
                            <Option value={item.clarityID}>
                              {" "}
                              {item.claritydetails}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.clarityVal && (
                        <p className="error-color-red">
                          {validations.clarityVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Cut Grade")} <span className="mandatory">*</span>{" "}
                    </label>{" "}
                    <Select
                      value={diamondData.cutGrade ? diamondData.cutGrade : null}
                      name="cutGrade"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "cutGrade")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.cutGradeVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {cutList &&
                        cutList.map((item, i) => {
                          return (
                            <Option value={item.cutGradeValue}>
                              {item.cutGradeValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.cutGradeVal && (
                        <p className="error-color-red">
                          {validations.cutGradeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Certificates")} </label>
                    <Select
                      value={
                        diamondData.certificate ? diamondData.certificate : null
                      }
                      name="certificate"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "certificate")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.certificateVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option>{t("None")}</Option>
                      {certificateList &&
                        certificateList.map((item, i) => {
                          return (
                            <Option value={item.certificateValue}>
                              {item.certificateValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.certificateVal && (
                        <p className="error-color-red">
                          {validations.certificateVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Certificate #")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter certificate number")}
                      name="certificateNum"
                      value={diamondData.certificateNum}
                      onChange={(e) => handleOnChange(e, "", "certificateNum")}
                      className={validations.certificateNumVal && "border__red"}
                    />
                    <div>
                      {validations.certificateNumVal && (
                        <p className="error-color-red">
                          {validations.certificateNumVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block percent__block">
                    <label> {t("Depth")} </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Depth")}
                      name="depth"
                      value={diamondData.depth}
                      onChange={(e) => handleOnChange(e, "", "depth")}
                      className={validations.depthVal && "border__red"}
                    />
                    <span className="percent__symbol">%</span>
                    <div>
                      {validations.depthVal && (
                        <p className="error-color-red">
                          {validations.depthVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block percent__block">
                    <label> {t("Table")} </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Table")} 
                      name="table"
                      value={diamondData.table}
                      onChange={(e) => handleOnChange(e, "", "table")}
                      className={validations.tableVal && "border__red"}
                    />
                    <span className="percent__symbol">%</span>
                    <div>
                      {validations.tableVal && (
                        <p className="error-color-red">
                          {validations.tableVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Measurements")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter the measurements")}
                      name="measurements"
                      value={diamondData.measurements}
                      onChange={(e) => handleOnChange(e, "", "measurements")}
                      className={validations.measurementsVal && "border__red"}
                    />
                    <div>
                      {validations.measurementsVal && (
                        <p className="error-color-red">
                          {validations.measurementsVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Polish")} </label>
                    <Select
                      value={diamondData.polish ? diamondData.polish : null}
                      name="polish"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "polish")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.polishVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {polishList &&
                        polishList.map((item, i) => {
                          return (
                            <Option value={item.polishValue}>
                              {" "}
                              {item.polishValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.polishVal && (
                        <p className="error-color-red">
                          {validations.polishVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Symmetry")} </label>
                    <Select
                      value={diamondData.symmetry ? diamondData.symmetry : null}
                      name="symmetry"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "symmetry")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.symmetryVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {summetryList &&
                        summetryList.map((item, i) => {
                          return (
                            <Option value={item.symmetryValue}>
                              {" "}
                              {item.symmetryValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.symmetryVal && (
                        <p className="error-color-red">
                          {validations.symmetryVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Fluorescence")} </label>
                    <Select
                      value={
                        diamondData.fluorescence
                          ? diamondData.fluorescence
                          : null
                      }
                      name="fluorescence"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "fluorescence")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.fluorescenceVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option>{t("Select")}</Option>
                      <Option value="vsb">Very Strong Blue</Option>
                      <Option value="sb">Strong Blue</Option>
                      <Option value="mb">Medium Blue</Option>
                      <Option value="fb">Faint Blue</Option>
                      <Option value="None">None</Option>
                    </Select>
                    <div>
                      {validations.fluorescenceVal && (
                        <p className="error-color-red">
                          {validations.fluorescenceVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Girdle")} </label>
                    <Select
                      value={diamondData.girdle ? diamondData.girdle : null}
                      name="girdle"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "girdle")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.girdleVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {girdleList &&
                        girdleList.map((item, i) => {
                          return (
                            <Option value={item.diamondGirdleID}>
                              {" "}
                              {item.girdleValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.girdleVal && (
                        <p className="error-color-red">
                          {validations.girdleVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Culet")} </label>
                    <Select
                      value={diamondData.culet ? diamondData.culet : null}
                      name="certificate"
                      placeholder={t("Select")}
                      onChange={(e) => handleOnChange(e, "select", "culet")}
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.culetVal ? "border__red" : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {culetList &&
                        culetList.map((item, i) => {
                          return (
                            <Option value={item.diamondCuletID}>
                              {" "}
                              {item.culetValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.culetVal && (
                        <p className="error-color-red">
                          {validations.culetVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Culet Condition")} </label>
                    <Select
                      value={
                        diamondData.culetCondition
                          ? diamondData.culetCondition
                          : null
                      }
                      name="culetCondition"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "culetCondition")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.culetConditionVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option> {t("Select")} </Option>
                      {culetCondList &&
                        culetCondList.map((item, i) => {
                          return (
                            <Option value={item.diamondCuletConditionID}>
                              {" "}
                              {item.culetConditionValue}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.culetConditionVal && (
                        <p className="error-color-red">
                          {validations.culetConditionVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block percent__block">
                    <label> {t("Crown Angle")} </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Enter crown angle")}
                      name="crownAngle"
                      value={diamondData.crownAngle}
                      onChange={(e) => handleOnChange(e, "", "crownAngle")}
                      className={validations.crownAngleVal && "border__red"}
                    />
                    <span className="percent__symbol">%</span>
                    <div>
                      {validations.crownAngleVal && (
                        <p className="error-color-red">
                          {validations.crownAngleVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block percent__block">
                    <label> {t("Pavillion Angle")} </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={onKeyPressMinusEvent}
                      placeholder={t("Enter pavillion angle")}
                      name="pavillionAngle"
                      value={diamondData.pavillionAngle}
                      onChange={(e) => handleOnChange(e, "", "pavillionAngle")}
                      className={validations.pavillionAngleVal && "border__red"}
                    />
                    <span className="percent__symbol">%</span>
                    <div>
                      {validations.pavillionAngleVal && (
                        <p className="error-color-red">
                          {validations.pavillionAngleVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label>
                      {t("Comments")}
                      <Tooltip
                        placement="right"
                        title={t(
                          "Do not include pricing informatin in comments."
                        )}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      placeholder={t("Enter comments")}
                      name="comments"
                      value={diamondData.comments}
                      onChange={(e) => handleOnChange(e, "", "comments")}
                      className={validations.commentsVal && "border__red"}
                    />
                    <div>
                      {validations.commentsVal && (
                        <p className="error-color-red">
                          {validations.commentsVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Store Location")} </label>
                    <Select
                      loading={LocationDetailsLoader}
                      value={
                        diamondData.storeLocation
                          ? diamondData.storeLocation
                          : ""
                      }
                      name="storeLocation"
                      placeholder={t("Select")}
                      onChange={(e) =>
                        handleOnChange(e, "select", "storeLocation")
                      }
                      onSearch={onSearch}
                      showSearch
                      optionFilterProp="children"
                      className={
                        validations.storeLocationVal
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      <Option value=""> {t("Select Store Location")} </Option>
                      {locationDetails &&
                        locationDetails.map((item, i) => {
                          return (
                            <Option value={item.LocationName}>
                              {" "}
                              {item.LocationName}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {validations.storeLocationVal && (
                        <p className="error-color-red">
                          {validations.storeLocationVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Employee Name")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter employee name")}
                      name="employeeName"
                      value={name}
                      onChange={(e) => handleOnChange(e, "", "employeeName")}
                      className={validations.employeeNameVal && "border__red"}
                    />
                    <div>
                      {validations.employeeNameVal && (
                        <p className="error-color-red">
                          {validations.employeeNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Employee ID")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter employee id")}
                      name="employeeId"
                      value={diamondData.employeeId}
                      onChange={(e) => handleOnChange(e, "", "employeeId")}
                      className={validations.employeeIdVal && "border__red"}
                    />
                    <div>
                      {validations.employeeIdVal && (
                        <p className="error-color-red">
                          {validations.employeeIdVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Out On Memo")} </label>
                    <Switch
                      name="out_on_memo"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={diamondData.outOnMemo}
                      onChange={(e) => handleOnChange(e, "select", "outOnMemo")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Seen by Other Dealers")} </label>
                    <Switch
                      name="seen_by_other_dealers"
                      checkedChildren={t("Yes")}
                      unCheckedChildren={t("No")}
                      checked={diamondData.seenByOtherDealers}
                      onChange={(e) =>
                        handleOnChange(e, "select", "seenByOtherDealers")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__block">
                    <label> {t("Add Video")} </label>
                    <>
                      <div className="input__block">
                        <input
                          type="text"
                          name="TextVideoUrl"
                          value={diamondData.TextVideoUrl}
                          onChange={(e) =>
                            handleOnChange(e, "", "TextVideoUrl")
                          }
                        />
                      </div>
                    </>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input__block">
                    <label></label>
                    <div className="radio__block">
                      <input
                        type="checkbox"
                        className="w-auto"
                        value={diamondData.isLabCreated}
                        onChange={(e) => onChangeCheck(e, "isLabCreated")}
                      />
                      <label> {t("Is Lab Created")} </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-2">
                  <Button
                    className="primary-btn"
                    onClick={handleSubmit}
                    loading={buttonLoading}
                  >
                    {t("Create")}
                  </Button>
                  <Button className="primary-btn ml-2">{t("Cancel")}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* After Delete Modal PopUp Message */}
      <Modal
        title={" "}
        className="modalconsupld__section"
        centered
        visible={visible6}
        onCancel={() => handleOnOk()}
        width={400}
        maskClosable={false}
        footer={[
          <Button key="submit" type="primary" onClick={() => handleOnOk()}>
            {t("OK")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>
                      {t(
                        "Your diamonds are Added Successfully. However it will take some moments to Add them from cache."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* For No dealer Delete */}
    </div>
  );
};

export default AddSignleDiamond;
