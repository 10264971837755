import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import {
	Input,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Switch,
	Tooltip,
	Table,
	Spin,
	Space,
	Modal,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RetailerLogo from "../../assets/images/retailer-logo.jpeg";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import communityVendorService from "../../services/community-vendor.service";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Moment from "moment";
import Conversations from "../contacts/conversation";
import Appointment from "../dashboard/Appointment";
import BlockedProduct from "../dashboard/BlockedProduct";
import ConversationsDashboard from "../dashboard/ConversationsDashboard";
import AppointmentsDashboard from "../dashboard/AppointmentsDashboard";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
	setVendorConversationCount,
	setVendorConversationCountSent,
	setVendorConversationCountUnRead,
	setVendorAppointmentCount,
} from "../../actions/Contact/Contact";

const JewelryRetailerPermission = (props) => {
	const { t } = useTranslation();
	const [tableKey, setTableKey] = useState(false);
	const ContactDetails = useSelector((state) => state.contactReducer);
	const [switchValue, setswitchValue] = useState(true);
	const [visible, setVisible] = useState(false);
	const { Option } = Select;
	const [loading, setLoading] = useState(false);
	const [rowDetails, setRowDetails] = useState({});
	const [SalesRep, setSalesRep] = useState([]);
	const [SalesRepId, setSalesRepId] = useState("");
	const [selectedColumnId, setSelectedColumnId] = useState(0);
	const [disconnectSwitch, setDisconnectSwitch] = useState(true);
	const [filterDataObj, setFilterdata] = useState([]);
	const [jewelryList, setJewelryList] = useState([]);
	const [allJewelryList, setAlljewelryList] = useState([]);
	const [dealerId, setDealerId] = useState();
	const loginDetials = useSelector((state) => state.loginReducer);
	const [selectedTab, setSelectedTab] = useState("myretailer");
	const [switchCase, setSwitchCase] = useState(false);
	const [loadingprofileData, setLoadingProfileData] = useState(false);
	const [sortColumnName, setSortColumnName] = useState("dealerCompany");
	const [sortType, setSortType] = useState("asc");
	const [statusColumn, setStatusColumn] = useState([]);
	const [statusColumn1, setStatusColumn1] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [productRestrictionData, setProductRestrictionData] = useState([]);
	const [dealersCollections, setDealersCollections] = useState([]);
	const [selectedCollectionIds, setSelectedCollectionIds] = useState("");
	const [selectedProductsToRestrict, setSelectedProductsToRestrict] = useState(
		[]
	);
	const [isAutoApproval, setIsAutoApproval] = useState(false);
	const [pageNameType, setpageNameType] = useState("Retailer");
	const [conservationCount, setConservationCount] = useState(0);
	const [appointmentcount, setAppointmentCount] = useState(0);
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);

	const searchInput = useRef(null);
	const [conversationCommonCount, setConversationCommonCount] = useState({});
	const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");
	const [firstRowId, setFirstRowId] = useState("");
	const [showBtn, setShowBtn] = useState(false);

	// Request Modal

	const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
		useState(false);
	const [page, setPage] = useState("RetailerAffirmation");
	const [disableBtn, setDisableBtn] = useState(true);
	const [emailLoading, setEmailLoading] = useState(false);
	const [content, setContent] = useState("");
	const [row, setRow] = useState([]);
	const [dealerCompany, setDealerCompany] = useState("");
	const [key, setKey] = useState(true);
	const [checkbox, setCheckBox] = useState("");
	const [rowlength, setRowLength] = useState(false);
	const [profileKey, setProfileKey] = useState(false);
	const [tableFilters, setTableFilters] = useState(null);
	const showSendInvitationModal = (row) => {
		setRow(row);
		setIsModalSendInvitationVisible(true);
	};

	function handleCheckBoxChange(checkedValues) {
		setCheckBox(checkedValues.target.value);
		setDisableBtn(false);
	}

	const handleSendInvitationModalCancel = () => {
		setCheckBox("");
		setDisableBtn(false);
		setIsModalSendInvitationVisible(false);
		setPage("RetailerAffirmation");
		setContent("");
		setKey(!key);
	};

	const category = [
		{
			label: "Current Authorized Reseller",
			value: "CurrentAuthorizedReseller",
			note: (
				<>
					{t(
						"By checking this box and clicking the submit button below I hereby affirm that"
					)}
					{"  "}
					{dealerCompany ? dealerCompany : null}
					{"  "}
					{t("has an open and active account with")} {row.dealerCompany},{"  "}
					{t("that I am an authorized reseller of")}
					{"  "}
					{row.dealerCompany}
					{"  "},
					{t(
						"and that I am permitted to have their virtual inventory on my website."
					)}
					.
				</>
			),
		},
		{
			label: "New Account Request",
			value: "NewAccountRequest",

			note: (
				<>
					{t("I am interested in opening a new account with")}
					{"  "}
					{row.dealerCompany}
					{"  "}
					{t(
						"and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to"
					)}
					{"  "}
					{row.dealerCompany}
				</>
			),
		},
	];

	const handleShowEmailWindow = () => {
		setPage("SendEmail");
		handleGetEmailData();
	};

	const handleSendEmail = () => {
		try {
			let inputData = {
				editval: content,
				email_Type: "Invitation", //row.emailType,
				vendorID: row.dealerID,
				dealerID: dealerId,
			};
			setLoading(true);
			communityVendorService
				.SendEmailToVendor(inputData)
				.then((response) => {
					setLoading(false);
					var message = response.data.message;

					if (message === "Success") {
						// NotificationManager.success("Email has been Sent Successfully.");
						handleRequestAccess();
					} else {
						NotificationManager.error(
							t("Email has not been Sent Successfully.")
						);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
		handleSendInvitationModalCancel();
	};

	const handleNewAccountRequest = () => {
		let isVender =
			loginDetials.loginDetials.responseData.dealerMemberTypeName == "Vendor";
		try {
			let inputData = {
				email_Type: "Invitation", //row.emailType,
				vendorID: isVender ? dealerId : row.dealerID,
				dealerID: isVender ? row.dealerID : dealerId,
				swap: true,
			};
			setLoading(true);
			communityVendorService
				.SendEmailToAllEmailType(inputData)
				.then((response) => {
					var message = response.data.message;

					if (message === "Success") {
						handleRequestAccess();
						// NotificationManager.success("Email has been Sent Successfully.");
						setLoading(false);
					} else {
						NotificationManager.error(
							t("Email has not been Sent Successfully.")
						);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
		handleSendInvitationModalCancel();
	};

	const handleGetEmailData = () => {
		let isVender =
			loginDetials.loginDetials.responseData.dealerMemberTypeName == "Vendor";
		try {
			let inputData = {
				vendorID: isVender ? dealerId : row.dealerID,
				dealerID: isVender ? row.dealerID : dealerId,
				swap: true,
				// vendorID: row.dealerID,

				// dealerID: dealerId,
				emailType: "Invitation", // row.emailType,
			};
			setEmailLoading(true);
			communityVendorService
				.GeneratePopupForAffirmation(inputData)
				.then((response) => {
					setEmailLoading(false);
					var message = response.data.message;
					var responseData = response.data.responseData.sendMsgBody;
					if (message === "Success") {
						setContent(responseData);
					} else {
						setContent([]);
					}
				})
				.catch((error) => {
					console.log(error);
					setEmailLoading(false);
				});
		} catch (error) {
			console.log(error);
			setEmailLoading(false);
		}
	};

	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.loginDetials) {
				if (loginDetials.loginDetials.responseData.logInSucceeded) {
					setDealerId(loginDetials.loginDetials.responseData.dealerId);
					setDealerMemberTypeName(
						loginDetials.loginDetials.responseData.dealerMemberTypeName
					);
					setDealerCompany(
						loginDetials.loginDetials.responseData.dealerCompany
					);
					if (props.type) {
					} else {
						if (
							loginDetials.loginDetials.responseData.dealerMemberTypeName ==
							"VendorDiamond"
						) {
							handleGetMyRetailerListVendor(
								loginDetials.loginDetials.responseData.dealerId,
								"dealerCompany",
								"asc"
							);
						} else {
							handleGetMyRetailerList(
								loginDetials.loginDetials.responseData.dealerId,
								"dealerCompany",
								"asc"
							);
						}

						handleGetConversionCount(
							loginDetials.loginDetials.responseData.dealerId
						);
						handleGetRetailerAppointmentData(
							loginDetials.loginDetials.responseData.dealerId
						);
						GetSalesRepsList(loginDetials.loginDetials.responseData.dealerId);
						handleGetAutoAppprovalStatus(
							loginDetials.loginDetials.responseData.dealerId
						);
					}
				}
			}
		}
	}, []);

	useEffect(() => {
		if (ContactDetails) {
			setConservationCount(ContactDetails.contactConversationCount);
			setAppointmentCount(ContactDetails.contactAppointmentCount);
			setpageNameType(
				ContactDetails.jewelryTabName
					? ContactDetails.jewelryTabName
					: "Retailer"
			);
		}
	}, [ContactDetails]);

	const handleTabsChange = (e) => {
		setTableKey(!tableKey);
		setSelectedTab(e);
		setSortColumnName("dealerCompany");
		setSortType("asc");

		var ColumnName = "dealerCompany";
		var SortName = "asc";
		if (e == "myretailer") {
			if (dealerMemberTypeName == "VendorDiamond") {
				handleGetMyRetailerListVendor(dealerId, ColumnName, SortName);
			} else {
				handleGetMyRetailerList(dealerId, ColumnName, SortName);
			}
		}
		if (e == "allretailer") {
			handleGetALLRetailerList(
				dealerId,
				pageNo,
				pageSize,
				ColumnName,
				SortName
			);
		}
	};

	const handleGetMyRetailerList = (id, sortColumnName, sortOrder, filters) => {
		try {
			let inputData = {
				dealerID: id,
				sales: "",
				sortColumnName: sortColumnName || "dealerCompany",
				sortOrder: sortOrder || "ASC",
				lstColumnFilter: filters || [],
			};
			setLoading(true);
			RetailerDataService.GetMyRetailerList(inputData)
				.then((response) => {
					var message = response?.data?.message;
					var responseData = response.data.responseData;
					var FirstRowId =
						response?.data?.responseData?.myRetailerResponse?.[0]?.dealerID;
					var showBtn =
						response?.data?.responseData?.myRetailerResponse?.[0]?.showBtn;
					if (message === "Success") {
						if (responseData?.myRetailerResponse) {
							setJewelryList(responseData.myRetailerResponse);
							setFirstRowId(FirstRowId);
							setShowBtn(showBtn);
							handleGetRetailerProfile(FirstRowId, showBtn, id);
							var salesRepList = responseData.salesRapelistvalue.filter(
								(obj) => obj.userId !== ""
							);
							salesRepList.forEach(function (data) {
								data["text"] = data["name"];
								data["value"] = data["name"];
								delete data["name"];
								delete data["name"];
							});
							setStatusColumn(salesRepList);
							setRowLength(true);
						} else {
							setRowLength(false);
							setRowDetails({});
							setJewelryList([]);
						}
					} else {
						setRowLength(false);
						setRowDetails({});
						setJewelryList([]);
					}
					setLoading(false);
				})
				.catch((error) => {
					setRowLength(false);
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			setRowLength(false);
			console.log(error);
			setLoading(false);
		}
	};

	const handleGetMyRetailerListVendor = (
		id,
		sortColumnName,
		sortOrder,
		filters
	) => {
		try {
			let inputData = {
				dealerID: id,
				sales: "",
				sortColumnName: sortColumnName || "dealerCompany",
				sortOrder: sortOrder || "ASC",
				lstColumnFilter: filters || [],
			};
			setLoading(true);
			RetailerDataService.GetmyRetailerListVendorDiamond(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var FirstRowId =
						response.data.responseData?.pendingResponse?.[0]?.dealerID;
					var showBtn =
						response.data?.responseData?.pendingResponse?.[0]?.showBtn;
					if (message === "Success") {
						setJewelryList(responseData?.pendingResponse || []);
						handleGetRetailerProfile(FirstRowId, showBtn, id);
						var salesRepList = responseData.salrapelistallreat.filter(
							(obj) => obj.userId !== ""
						);
						salesRepList.forEach(function (data) {
							data["text"] = data["name"];
							data["value"] = data["name"];
							delete data["name"];
							delete data["name"];
						});
						setStatusColumn(salesRepList);
						if (responseData?.pendingResponse?.length > 0) {
							setRowLength(true);
						} else {
							setRowLength(false);
						}
					} else {
						setRowLength(false);
						setJewelryList([]);
					}
					setLoading(false);
				})
				.catch((error) => {
					setRowLength(false);
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			setRowLength(false);
			console.log(error);
			setLoading(false);
		}
	};

	const handleGetALLRetailerList = (
		id,
		pageNo,
		pageSize,
		sortColumnName,
		sortOrder,
		filters
	) => {
		try {
			let inputData = {
				dealerID: id,
				sales: "",
				sortColumnName: sortColumnName || "dealerCompany",
				sortOrder: sortOrder || "ASC",
				lstColumnFilter: filters || [],
				pageSize: pageSize,
				pageIndex: pageNo,
				isDiamondVendor: dealerMemberTypeName == "VendorDiamond" ? true : false,
			};
			setLoading(true);
			RetailerDataService.GetALLRetailerList(inputData)
				.then((response) => {
					var message = response?.data?.message;
					var responseData = response?.data?.responseData;

					if (message === "Success") {
						if (responseData) {
							var FirstRowId = responseData?.pendingResponse?.[0]?.dealerID;
							var showBtn = responseData?.pendingResponse?.[0]?.showBtn;
							var totalRecord = responseData?.totalRecord;

							// For Issue in Sales Rep List
							if (responseData?.pendingResponse) {
								setAlljewelryList(responseData?.pendingResponse);
								if (responseData?.pendingResponse?.[0]) {
									handleGetRetailerProfile(FirstRowId, showBtn, dealerId);
								}
								responseData?.salrapelistallreat?.forEach(function (data) {
									data["text"] = data["name"];
									data["value"] = data["name"];
									delete data["name"];
									delete data["name"];
								});
								setStatusColumn1(responseData?.salrapelistallreat);
							}

							setTotalCount(totalRecord);
							setLoading(false);
							if (responseData?.pendingResponse?.length > 0) {
								setRowLength(true);
							} else {
								setRowLength(false);
							}
						}
					} else {
						setRowLength(false);
						setAlljewelryList([]);
						setLoading(false);
					}
				})
				.catch((error) => {
					setRowLength(false);
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			setRowLength(false);
			console.log(error);
			setLoading(false);
		}
	};

	function onSearch(val) {}

	const handleSwitch = () => {
		setDisconnectSwitch(false);
		try {
			let inputData = {
				designerID: Number(dealerId),
				dealerID: Number(rowDetails.dealerID),
			};
			communityVendorService
				.UpdateInventoryByDesignerID(inputData)
				.then((response) => {
					var message = response.data.message;
					setLoading(true);
					if (message === "Success") {
						setProfileKey(!profileKey);
						NotificationManager.success(t("Retailer Has Been Disconnected."));
						if (selectedTab == "myretailer") {
							if (dealerMemberTypeName == "VendorDiamond") {
								handleGetMyRetailerListVendor(
									dealerId,
									sortColumnName,
									sortType
								);
							} else {
								handleGetMyRetailerList(dealerId, sortColumnName, sortType);
							}

							setSelectedColumnId(0);
							setDisconnectSwitch(true);
						}
						if (selectedTab == "allretailer") {
							handleGetALLRetailerList(
								dealerId,
								pageNo,
								pageSize,
								sortColumnName,
								sortType
							);
							setDisconnectSwitch(true);
						}
					} else {
						setProfileKey(!profileKey);
						NotificationManager.error(
							t("Retailer Has Not Been Not Disconnected.")
						);
						setLoading(false);
						setDisconnectSwitch(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleEnableDisableIsRestricted = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.isrestricted == "1" ? true : false,
			};
			RetailerDataService.EnableDisableIsRestricted(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.isrestricted == "1") {
							NotificationManager.success(
								t("Jewelry Access Has Been Granted Successfully.")
							);
						} else {
							NotificationManager.success(t("Jewelry Access Has Been Denied."));
						}
						if (dealerMemberTypeName == "VendorDiamond") {
							handleGetMyRetailerListVendor(dealerId, sortColumnName, sortType);
						} else {
							handleGetMyRetailerList(dealerId, sortColumnName, sortType);
						}
					} else {
						NotificationManager.error(
							t("Jewelry Access Has Not  Been Granted Successfully.")
						);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleEnableDisablePrice = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.pricedisabled === "1" ? true : false,
			};
			RetailerDataService.EnableDisablePrice(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.pricedisabled === "1") {
							NotificationManager.success(
								t("Hide Price Activated successfully.")
							);
						} else {
							NotificationManager.success(
								t("Hide Price Deactivated Successfully.")
							);
						}
						// handleGetMyRetailerList(dealerId, sortColumnName, sortType);
					} else {
						NotificationManager.error(t("Hide Price Has Not Been Activated."));
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleEnableDisableMarkup = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.markuprestricted === "1" ? true : false,
			};
			RetailerDataService.EnableDisableMarkup(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.markuprestricted === "1") {
							NotificationManager.success(
								t("Enforce MSRP Activated successfully.")
							);
						} else {
							NotificationManager.success(
								t("Enforce MSRP Deactivated Successfully.")
							);
						}
						// handleGetMyRetailerList(dealerId, sortColumnName, sortType);
					} else {
						NotificationManager.error(t("Enforce MSRP Has Not Activated."));
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleEnableDisableRingBuilder = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.ringbuilder === "1" ? true : false,
			};
			RetailerDataService.EnableDisableRingBuilder(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.ringbuilder === "1") {
							NotificationManager.success(
								t("RingBuilder Access Has Been Granted Successfully.")
							);
						} else {
							NotificationManager.success(
								t("RingBuilder Access Has Been Denied.")
							);
						}

						// handleGetMyRetailerList(dealerId, sortColumnName, sortType);
					} else {
						NotificationManager.error(
							t("RingBuilder Access Has Not Been Granted Successfully.")
						);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleEnableDisableIsAutoPermission = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.isautopermission === "1" ? true : false,
			};
			RetailerDataService.EnableDisableIsAutoPermission(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.isautopermission === "1") {
							NotificationManager.success(
								t("Auto Sync Activated successfully.")
							);
						} else {
							NotificationManager.success(
								t("Auto Sync Deactivated Successfully.")
							);
						}

						// handleGetMyRetailerList(dealerId, sortColumnName, sortType);
					} else {
						NotificationManager.error(t("Auto Sync Has Not Been Activated."));
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const handleEnableDisablePurchaseOrder = (saveRow) => {
		try {
			let inputData = {
				dealerID: dealerId.toString(),
				retailerid: saveRow.dealerID.toString(),
				type: saveRow.purchaseoder === "1" ? true : false,
			};
			RetailerDataService.EnableDisablePurchaseOrder(inputData)
				.then((response) => {
					var message = response.data.message;
					// setLoading(true);
					if (message === "Success") {
						if (saveRow.purchaseoder === "1") {
							NotificationManager.success(
								t("Purchase Order Access Has Been Granted Successfully.")
							);
						} else {
							NotificationManager.success(
								t("Purchase Order Access Has Been Denied.")
							);
						}

						// handleGetMyRetailerList(dealerId, sortColumnName, sortType);
					} else {
						NotificationManager.error(
							t("Purchase Order Access Has Not Been Granted Successfully.")
						);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnChange = (row, e, name) => {
		if (name === "isrestricted") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, isrestricted: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);
			handleEnableDisableIsRestricted(saveRow);
		}
		if (name === "pricedisabled") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, pricedisabled: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);
			handleEnableDisablePrice(saveRow);
		}
		if (name === "markuprestricted") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, markuprestricted: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);

			handleEnableDisableMarkup(saveRow);
		}
		if (name === "ringbuilder") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, ringbuilder: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);

			handleEnableDisableRingBuilder(saveRow);
		}
		if (name === "isautopermission") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, isautopermission: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);

			handleEnableDisableIsAutoPermission(saveRow);
		}
		if (name === "purchaseoder") {
			const temp = jewelryList.map((x) => {
				if (x.dealerID === row.dealerID) {
					return { ...x, purchaseoder: e == true ? "1" : "0" };
				} else return x;
			});
			let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
			setJewelryList(temp);

			handleEnableDisablePurchaseOrder(saveRow);
		}
	};

	const handleGetRetailerProfile = (id, showBtn, dealerId) => {
		try {
			let inputData = {
				retailerID: id,
				showBtn: showBtn,
				dealerId: dealerId,
			};
			setSelectedColumnId(id);
			setLoadingProfileData(true);
			RetailerDataService.GetRetailerProfile(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData[0];
					if (message === "Success") {
						if (responseData) {
							setSalesRepId(
								responseData.salesUserId ? responseData.salesUserId : ""
							);
							setRowDetails(responseData);
							setLoadingProfileData(false);
							setTimeout(() => {
								handleBindRightSideDataForPermission(id, dealerId);
							}, 500);
						} else {
							setRowDetails({});
							setLoadingProfileData(false);
							setDealersCollections([]);
						}
					} else {
						setRowDetails({});
						setLoadingProfileData(false);
						setDealersCollections([]);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoadingProfileData(false);
				});
		} catch (error) {
			console.log(error);
			setLoadingProfileData(false);
		}
	};

	function getColumnSearchProps(dataIndex) {
		return {
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={searchInput}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}>
							{t("Search")}
						</Button>
						<Button
							onClick={() => handleReset(clearFilters, confirm)}
							size="small"
							style={{ width: 90 }}>
							{t("Reset")}
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilter: (value, record) =>
				record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					// setTimeout(() => this.searchInput.select());
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				) : (
					text
				),
		};
	}

	function handleSearch(selectedKeys, confirm, dataIndex) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	}

	function handleReset(clearFilters, confirm) {
		clearFilters();
		setSearchText("");
		confirm();
	}

	// End

	const columnAllVendorJewelry = [
		{
			title: t("Store Name"),
			dataIndex: "dealerCompany",
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (index, row) => {
				return (
					<p>
						<span className="linkText mb-0">{row.dealerCompany}</span>
					</p>
				);
			},
		},
		{
			title: t("Status"),
			dataIndex: "status",
		},

		{
			title: t("City"),
			dataIndex: "dealerCity",
			showSorterTooltip: false,
			width: 100,
			sorter: (a, b) => {},
		},
		{
			title: t("State"),
			dataIndex: "dealerState",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("PhoneNo"),
			dataIndex: "dealerPhone",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("Sales Rep"),
			dataIndex: "salesReps",
			filters: statusColumn1,
			onFilter: (value, record) => record.salesReps === value,
			showSorterTooltip: false,
			sorter: (a, b) => {},
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					{/* Render Checkbox Options */}
					{statusColumn1.map((status) => (
						<div
							key={status.text}
							style={{ marginBottom: 8 }}>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
								}}>
								<input
									type="checkbox"
									value={status.text}
									checked={selectedKeys.includes(
										status.text
									)}
									onChange={(e) => {
										const value = e.target.value;
										if (e.target.checked) {
											setSelectedKeys([
												...selectedKeys,
												value,
											]);
										} else {
											setSelectedKeys(
												selectedKeys.filter(
													(key) => key !== value
												)
											);
										}
									}}
								/>
								{t(status.text)}
							</label>
						</div>
					))}

					<hr />
					{/* Reset and OK Buttons */}
					<div
						style={{
							marginTop: 8,
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type="button"
							onClick={() => {
								clearFilters();
								setSelectedKeys([]);
								confirm();
							}}
							style={{
								border: "none",
								backgroundColor: "transparent",
								color: "#007bff",
								cursor: "pointer",
								marginRight: "20px",
							}}>
							{t("Reset")}
						</button>
						<button
							type="button"
							onClick={() => confirm()}
							style={{
								border: "none",
								backgroundColor: "#1883b8",
								color: "white",
								cursor: "pointer",
								padding: "4px 8px",
								borderRadius: 4,
							}}>
							{t("OK")}
						</button>
					</div>
				</div>
			),
			
		},
		{
			title: t("Request Date"),
			dataIndex: "reqdDate",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		// {
		//   title: t("Request Date"),
		//   dataIndex: "reqdDate",
		//   showSorterTooltip: false,
		//   sorter: (a, b) => {},
		// },
		// {
		//   title: t("Action"),
		//   render: (index, row) => {
		//     return (
		//       <p>
		//         {row.status != "Connected" ? (
		//           <span
		//             className="linkText mb-0"
		//             onClick={() => handleRequestAccess(row)}
		//           >
		//             {t("Request Access")}
		//           </span>
		//         ) : (
		//           <span>{t("Connected")}</span>
		//         )}
		//       </p>
		//     );
		//   },
		// },
	];

	const columnAllJewelry = [
		{
			title: t("Store Name"),
			dataIndex: "dealerCompany",
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (index, row) => {
				return (
					<p>
						<span
							className="linkText mb-0"
							// onClick={() => handleRequestAccess(row)}
						>
							{row.dealerCompany}
						</span>
					</p>
				);
			},
		},
		{
			title: t("Status"),
			dataIndex: "status",
		},

		{
			title: t("City"),
			dataIndex: "dealerCity",
			showSorterTooltip: false,
			width: 100,
			sorter: (a, b) => {},
		},
		{
			title: t("State"),
			dataIndex: "dealerState",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("PhoneNo"),
			dataIndex: "dealerPhone",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("Sales Rep"),
			dataIndex: "salesReps",
			filters: statusColumn1,
			onFilter: (value, record) => record.salesReps === value,
			showSorterTooltip: false,
			sorter: (a, b) => {},
				filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					{/* Render Checkbox Options */}
					{statusColumn1.map((status) => (
						<div
							key={status.text}
							style={{ marginBottom: 8 }}>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
								}}>
								<input
									type="checkbox"
									value={status.text}
									checked={selectedKeys.includes(
										status.text
									)}
									onChange={(e) => {
										const value = e.target.value;
										if (e.target.checked) {
											setSelectedKeys([
												...selectedKeys,
												value,
											]);
										} else {
											setSelectedKeys(
												selectedKeys.filter(
													(key) => key !== value
												)
											);
										}
									}}
								/>
								{t(status.text)}
							</label>
						</div>
					))}

					<hr />
					{/* Reset and OK Buttons */}
					<div
						style={{
							marginTop: 8,
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type="button"
							onClick={() => {
								clearFilters();
								setSelectedKeys([]);
								confirm();
							}}
							style={{
								border: "none",
								backgroundColor: "transparent",
								color: "#007bff",
								cursor: "pointer",
								marginRight: "20px",
							}}>
							{t("Reset")}
						</button>
						<button
							type="button"
							onClick={() => confirm()}
							style={{
								border: "none",
								backgroundColor: "#1883b8",
								color: "white",
								cursor: "pointer",
								padding: "4px 8px",
								borderRadius: 4,
							}}>
							{t("OK")}
						</button>
					</div>
				</div>
			),
		},
		{
			title: t("Request Date"),
			dataIndex: "reqdDate",
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("Action"),
			width: 120,
			render: (index, row) => {
				return (
					<p>
						{row.status != "Connected" ? (
							<span
								className="linkText mb-0"
								// onClick={() => handleRequestAccess(row)}
								onClick={() => {
									showSendInvitationModal(row);
								}}>
								{t("Request Access")}
							</span>
						) : (
							<span>{t("Connected")}</span>
						)}
					</p>
				);
			},
		},
	];

	const filterData = (SalesRep) => (formatter) =>
		[
			...new Set(
				SalesRep.map((item) => ({
					text: formatter(item),
					value: formatter(item),
				}))
			),
		];

	// var filterData = [...new Set(jewelryList.map((item, i) => item.salesReps))];

	var NewSalesRep = [{ userId: "", name: "Sales Rep" }, ...SalesRep];

	const columnJewelryVendor = [
		{
			title: t("Store Name"),
			dataIndex: "dealerCompany",
			// sorter: (a, b) => a.dealerCompany.length - b.dealerCompany.length,
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (index, row) => {
				return (
					<p>
						<span
							className="linkText mb-0"
							// onClick={() => handleRequestAccess(row)}
						>
							{row.dealerCompany}
						</span>
					</p>
				);
			},
		},
		{
			title: t("Retailer Type"),
			dataIndex: "retailerType",
			render: (item, row) => {
				return <span>{t("Diamond")}</span>;
			},
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("City"),
			dataIndex: "dealerCity",
			showSorterTooltip: false,
			sorter: (a, b) => {},
			width: 100,
			// sorter: (a, b) => a.dealerCity.length - b.dealerCity.length,
		},
		{
			title: t("State"),
			dataIndex: "dealerState",
			// sorter: (a, b) => a.dealerState.length - b.dealerState.length,
			// sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("Request Date"),
			dataIndex: "reqdDate",
			// sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (item, row) => {
				return (
					<div>
						{row.reqdDate == "" ? null : (
							<p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>
						)}
					</div>
				);
			},
		},
		{
			title: t("Sales Rep"),
			dataIndex: "salesReps",
			filters: statusColumn,
			onFilter: (value, record) => record.salesReps === value,
			// onFilter: (value, record) => record.salesReps.indexOf(value) === 0,
			// filters: statusColumn,
			showSorterTooltip: false,
			// onFilter: (value, record) => record.salesReps === value,
			sorter: (a, b) => {},
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					{/* Render Checkbox Options */}
					{statusColumn.map((status) => (
						<div
							key={status.text}
							style={{ marginBottom: 8 }}>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
								}}>
								<input
									type="checkbox"
									value={status.text}
									checked={selectedKeys.includes(
										status.text
									)}
									onChange={(e) => {
										const value = e.target.value;
										if (e.target.checked) {
											setSelectedKeys([
												...selectedKeys,
												value,
											]);
										} else {
											setSelectedKeys(
												selectedKeys.filter(
													(key) => key !== value
												)
											);
										}
									}}
								/>
								{t(status.text)}
							</label>
						</div>
					))}

					<hr />
					{/* Reset and OK Buttons */}
					<div
						style={{
							marginTop: 8,
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type="button"
							onClick={() => {
								clearFilters();
								setSelectedKeys([]);
								confirm();
							}}
							style={{
								border: "none",
								backgroundColor: "transparent",
								color: "#007bff",
								cursor: "pointer",
								marginRight: "20px",
							}}>
							{t("Reset")}
						</button>
						<button
							type="button"
							onClick={() => confirm()}
							style={{
								border: "none",
								backgroundColor: "#1883b8",
								color: "white",
								cursor: "pointer",
								padding: "4px 8px",
								borderRadius: 4,
							}}>
							{t("OK")}
						</button>
					</div>
				</div>
			),
		},
	];

	const columnJewelry = [
		{
			title: t("Store Name"),
			dataIndex: "dealerCompany",
			width: 120,
			// sorter: (a, b) => a.dealerCompany.length - b.dealerCompany.length,
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (index, row) => {
				return (
					<p>
						<span
							className="linkText mb-0"
							// onClick={() => handleRequestAccess(row)}
						>
							{row.dealerCompany}
						</span>
					</p>
				);
			},
		},
		{
			title: t("Retailer Type"),
			dataIndex: "retailerType",
			width: 120,
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		{
			title: t("City"),
			dataIndex: "dealerCity",
			showSorterTooltip: false,
			sorter: (a, b) => {},
			width: 100,
			// sorter: (a, b) => a.dealerCity.length - b.dealerCity.length,
		},
		{
			title: t("State"),
			dataIndex: "dealerState",
			width: 120,
			// sorter: (a, b) => a.dealerState.length - b.dealerState.length,
			// sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
			showSorterTooltip: false,
			sorter: (a, b) => {},
		},
		// {
		//   title: t("Request Date"),
		//   dataIndex: "reqdDate",
		//   // sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
		//   showSorterTooltip: false,
		//   render: (item, row) => {
		//     return <p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>;
		//   },
		//   sorter: (a, b) => {},
		// },
		{
			title: t("Request Date"),
			dataIndex: "reqdDate",
			width: 120,
			// sorter: (a, b) => a.reqdDate.length - b.reqdDate.length,
			showSorterTooltip: false,
			sorter: (a, b) => {},
			render: (item, row) => {
				return (
					<div>
						{row.reqdDate == "" ? null : (
							<p>{Moment(row.reqdDate).format("MM/DD/YYYY ")}</p>
						)}
					</div>
				);
			},
		},
		
		{
			title: t("Sales Rep"),
			dataIndex: "salesReps",
			width: 130,
			filters: statusColumn,
			onFilter: (value, record) => record.salesReps === value,
			// onFilter: (value, record) => record.salesReps.indexOf(value) === 0,
			// filters: statusColumn,
			showSorterTooltip: false,
			// onFilter: (value, record) => record.salesReps === value,
			sorter: (a, b) => {},
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					{/* Render Checkbox Options */}
					{statusColumn.map((status) => (
						<div
							key={status.text}
							style={{ marginBottom: 8 }}>
							<label
								style={{
									display: "flex",
									alignItems: "center",
									gap: 10,
								}}>
								<input
									type="checkbox"
									value={status.text}
									checked={selectedKeys.includes(
										status.text
									)}
									onChange={(e) => {
										const value = e.target.value;
										if (e.target.checked) {
											setSelectedKeys([
												...selectedKeys,
												value,
											]);
										} else {
											setSelectedKeys(
												selectedKeys.filter(
													(key) => key !== value
												)
											);
										}
									}}
								/>
								{t(status.text)}
							</label>
						</div>
					))}

					<hr />
					{/* Reset and OK Buttons */}
					<div
						style={{
							marginTop: 8,
							display: "flex",
							justifyContent: "space-between",
						}}>
						<button
							type="button"
							onClick={() => {
								clearFilters();
								setSelectedKeys([]);
								confirm();
							}}
							style={{
								border: "none",
								backgroundColor: "transparent",
								color: "#007bff",
								cursor: "pointer",
								marginRight: "20px",
							}}>
							{t("Reset")}
						</button>
						<button
							type="button"
							onClick={() => confirm()}
							style={{
								border: "none",
								backgroundColor: "#1883b8",
								color: "white",
								cursor: "pointer",
								padding: "4px 8px",
								borderRadius: 4,
							}}>
							{t("OK")}
						</button>
					</div>
				</div>
			),

		},
		{
			title: t("Jewelry Access"),
			dataIndex: "isrestricted",
			width: 70,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="isrestricted"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.isrestricted == "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "isrestricted")}
						/>
					</div>
				);
			},
		},
		// {
		//   title: t("RingBuilder Access"),
		//   dataIndex: "ringbuilder",
		//   render: (item, row) => {
		//     return (
		//       <div
		//         className="action__btns"
		//         onClick={(e) => {
		//           e.stopPropagation();
		//           e.preventDefault();
		//         }}
		//       >
		//         <Switch
		//           name="ringbuilder"
		//           checkedChildren={t("Yes")}
		//           unCheckedChildren={t("No")}
		//           checked={row.ringbuilder === "1" ? true : false}
		//           onChange={(e) => handleOnChange(row, e, "ringbuilder")}
		//         />
		//       </div>
		//     );
		//   },
		// },
		{
			title: t("Purchase Order"),
			dataIndex: "purchaseoder",
			width: 70,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="purchaseoder"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.purchaseoder === "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "purchaseoder")}
						/>
					</div>
				);
			},
		},
		{
			title: t("Hide Price"),
			dataIndex: "pricedisabled",
			width: 70,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="pricedisabled"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.pricedisabled === "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "pricedisabled")}
						/>
					</div>
				);
			},
		},
		{
			title: t("Enforce MSRP"),
			dataIndex: "markuprestricted",
			width: 70,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="markuprestricted"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.markuprestricted === "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "markuprestricted")}
						/>
					</div>
				);
			},
		},
		{
			title: t("Auto Sync"),
			dataIndex: "isautopermission",
			width: 70,
			render: (item, row) => {
				return (
					<div
						className="action__btns"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}>
						<Switch
							name="isautopermission"
							checkedChildren={t("Yes")}
							unCheckedChildren={t("No")}
							checked={row.isautopermission === "1" ? true : false}
							onChange={(e) => handleOnChange(row, e, "isautopermission")}
						/>
					</div>
				);
			},
		},
	];

	const data1 = [
		{
			key: "1",
			storename: <span>Store 1</span>,
			retailertype: <span>Jewelry</span>,
			city: <span>Mumbai</span>,
			state: <span>Maha</span>,
			requesteddate: <span>01/01/2022</span>,
			JewelryAccess: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
			RingBuilderAccess: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
			PurchaseOrder: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
			HidePrice: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
			EnforceMSRP: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
			AutoSync: (
				<div className="input__block">
					<Switch checkedChildren={t("Yes")} unCheckedChildren={t("No")} />
				</div>
			),
		},
	];

	const columnsDiamond = [
		{
			title: t("Store Name"),
			dataIndex: "storename",
		},
		{
			title: t("Retailer Type"),
			dataIndex: "retailertype",
		},
		{
			title: t("City"),
			dataIndex: "city",
			width: 100,
		},
		{
			title: t("State"),
			dataIndex: "state",
		},
		{
			title: t("Requested Date"),
			dataIndex: "requesteddate",
		},
		{
			title: t("Jewelry"),
			dataIndex: "jewelry",
		},
		{
			title: t("Purchase Order"),
			dataIndex: "porder",
		},
		{
			title: t("Jewelry API"),
			dataIndex: "jewelryapi",
		},
	];

	const columns = [
		{
			title: t("Store Name"),
			dataIndex: "storename",
			key: "storename",
		},
		{
			title: t("Retailer Type"),
			dataIndex: "retailertype",
			key: "retailertype",
		},
		{
			title: t("City"),
			dataIndex: "city",
			key: "city",
			width: 100,
		},
		{
			title: t("State"),
			dataIndex: "state",
			key: "state",
		},
		{
			title: t("Requested Date"),
			dataIndex: "requesteddate",
			key: "requesteddate",
		},
		{
			title: t("Jewelry"),
			dataIndex: "jewelry",
			key: "jewelry",
			render: (item, row) => {
				return <Checkbox checked={row.jewelry} />;
			},
		},
		{
			title: t("Purchase Order"),
			dataIndex: "porder",
			key: "porder",
			render: (item, row) => {
				return <Checkbox checked={row.porder} />;
			},
		},
		{
			title: t("Jewelry API"),
			dataIndex: "jewelryapi",
			key: "porder",
			render: (item, row) => {
				return <Checkbox checked={row.jewelryapi} />;
			},
		},
	];

	const GetSalesRepsList = (id) => {
		try {
			let inputData = {
				dealerID: id,
			};
			RetailerDataService.GetSalesRepsList(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					if (message === "Success") {
						if (responseData) {
							setSalesRep(responseData);
						} else {
							setSalesRep([]);
						}
					} else {
						setSalesRep([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleOnSelectChange = (e, isSelect, name) => {
		if (isSelect === "select") {
			setSalesRepId({
				...SalesRepId,
				[name]: e,
			});
			if (e == null || e == "") {
				handleSaveSalesReps("");
			} else {
				handleSaveSalesReps(e);
			}
		} else {
			setSalesRepId({
				...SalesRepId,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSaveSalesReps = (id) => {
		try {
			let inputData = {
				dealerID: dealerId,
				retailerID: rowDetails.dealerID,
				salesRepId: id,
			};
			RetailerDataService.SaveSalesReps(inputData)
				.then((response) => {
					var message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Sales Rep Added Successfully"));
						if (selectedTab == "myretailer") {
							if (dealerMemberTypeName == "VendorDiamond") {
								handleGetMyRetailerListVendor(
									dealerId,
									sortColumnName,
									sortType
								);
							} else {
								handleGetMyRetailerList(dealerId, sortColumnName, sortType);
							}
						}
						if (selectedTab == "allretailer") {
							handleGetALLRetailerList(
								dealerId,
								pageNo,
								pageSize,
								sortColumnName,
								sortType
							);
						}
					} else {
						NotificationManager.success(t("Sales Rep Not Added"));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	// Sorting API

	const handleTableASCDES = (pagination, filters, sorter) => {
		var type = "";
		var column = "";
		var filter = [];
		var obj = {};
		//sorting
		if (sorter.order == "descend") {
			type = "DESC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else if (sorter.order == "ascend") {
			type = "ASC";
			column = sorter.field;
			setSortType(type);
			setSortColumnName(column);
		} else {
			type = "ASC";
			column = "dealerCompany";
			setSortColumnName(column);
			setSortType(type);
		}

		//search and filter
		if (filters.salesReps) {
			filters.salesReps.forEach((element) => {
				obj = {
					columnName: "SalesReps",
					columnValue: element,
					columnType: "CheckBox",
				};
				filter.push(obj);
			});
		} else {
		}

		if (selectedTab == "myretailer") {
			if (dealerMemberTypeName == "VendorDiamond") {
				handleGetMyRetailerListVendor(dealerId, column, type, filter);
			} else {
				handleGetMyRetailerList(dealerId, column, type, filter);
			}
		} else {
			setPageNo(pagination.current);
			setPageSize(pagination.pageSize);
			handleGetALLRetailerList(
				dealerId,
				pagination.current,
				pagination.pageSize,
				column,
				type,
				filter
			);
		}
		setTableFilters(filter);
	};

	const handleAutoApproval = async (dealerId, action) => {
		let inputData = {
			dealerId,
			action,
		};

		try {
			await RetailerDataService.Setglobalpermission(inputData)
				.then((response) => {
					let message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(t("Approval Changed Successfully"));
						handleGetAutoAppprovalStatus(dealerId);
					}
				})
				.catch((error) => {
					NotificationManager.error(t("Sorry! Could Not Change Approval"));
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleSwitchChange = (e) => {
		let action = e == true ? "Yes" : "No";
		setIsAutoApproval(e);
		handleAutoApproval(dealerId, action);
	};

	const handleBindRightSideDataForPermission = async (retailerID, id) => {
		let inputData = {
			dealerId: id ? id : dealerId,
			retailerID,
		};
		try {
			await RetailerDataService.BindRightSideDataForPermission(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;
					if (msg == "Success") {
						let productRestrictionData = responseData.restrictedProducts;
						let dealersCollections = responseData.dealersCollections;
						var data = productRestrictionData
							.filter((x) => x.show == "0")
							.map((x) => x.gfInventoryID);
						if (productRestrictionData) {
							setProductRestrictionData(productRestrictionData);
						}
						setSelectedProductsToRestrict(data);
						if (dealersCollections) {
							const saveRow = dealersCollections
								.filter((item) => item.isassigned == "1")
								.map((item) => {
									return item.collectionid;
								});

							setDealersCollections(dealersCollections);
							setSelectedCollectionIds(saveRow + "");
						}
					}
				})
				.catch((error) => {
					console.log(error);
					setDealersCollections([]);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleChangeCollectionId = (e, dealer) => {
		let temp = dealersCollections.map((item) => {
			if (item.collectionid == dealer.collectionid) {
				return { ...item, isassigned: e.target.checked == true ? "1" : "0" };
			} else return item;
		});

		const saveRow = temp
			.filter((item) => item.isassigned == "1")
			.map((item) => {
				return item.collectionid;
			});
		setDealersCollections(temp);
		setSelectedCollectionIds(saveRow + "");
	};

	const handleUpdateCollection = async () => {
		let inputData = {
			retailer_ID: selectedColumnId,
			collectionID: dealersCollections,
			dealerID: dealerId + "",
		};

		try {
			await RetailerDataService.UpdateCollection(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;

					if (msg == "Success") {
						NotificationManager.success(
							t("Collection Access Updated Successfully")
						);
						setSelectedCollectionIds("");
					} else {
						NotificationManager.error(t("Could Not Update Collection Access"));
					}
				})
				.catch((error) => {
					NotificationManager.error(
						tableFilters(t("Could Not Update Collection Access"))
					);
					console.log(error);
				});
		} catch (error) {
			console.log(error);
			NotificationManager.error(t("Sorry We have an error"));
		}
	};

	const handleCollectionSubmit = () => {
		handleUpdateCollection();
		setVisible(false);
	};

	const handleCancelCollectionModal = (record) => {
		setVisible(false);
		setSelectedCollectionIds("");
		handleBindRightSideDataForPermission(selectedColumnId, dealerId);
	};

	const handleRestrictProductChange = (e) => {
		setSelectedProductsToRestrict(e);
	};

	const handleRestrictedProductSubmit = async () => {
		let inputData = {
			dealerId: dealerId,
			retailerID: selectedColumnId,
			gfInventoryID: selectedProductsToRestrict + "",
		};
		try {
			await RetailerDataService.DisableProductbyVendor(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;
					if (msg == "Success") {
						NotificationManager.success(
							t("Product Restriction Done Successfully")
						);

						handleBindRightSideDataForPermission(selectedColumnId, dealerId);
					} else {
						NotificationManager.error(t("Could Not Restrict Product"));
					}
				})
				.catch((error) => {
					console.log(error);
					NotificationManager.error(t("Could Not Restrict Product"));
				});
		} catch (error) {
			NotificationManager.error(t("Sorry! There was an error"));
			console.log(error);
		}
	};

	const handleRequestAccess = async () => {
		let inputData = {
			dealerID: dealerId.toString(),
			reatilerID: row.dealerID,
		};

		setLoading(true);

		try {
			await RetailerDataService.SendReailerRequest(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						setIsModalSendInvitationVisible(false);
						NotificationManager.success(t("Request Sent"));
						setLoading(false);
						var ColumnName = "dealerCompany";
						var SortName = "asc";
						handleGetALLRetailerList(
							dealerId,
							pageNo,
							pageSize,
							ColumnName,
							SortName
						);
						setPage("RetailerAffirmation");
					} else {
						NotificationManager.error(t("Cannot Send Request"));
						setLoading(false);
					}
				})
				.catch((error) => {
					NotificationManager.error(t("Sorry! We had an error"));
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			NotificationManager.error(t("Sorry! We had an error"));
			setLoading(false);
		}
		handleSendInvitationModalCancel();
	};

	const openInNewTab = () => {
		var url = rowDetails.websiteAddress;
		if (rowDetails.websiteAddress.includes("https")) {
			window.open(url, "_blank");
		} else if (rowDetails.websiteAddress.includes("http")) {
			window.open(url, "_blank");
		} else {
			window.open("https://" + url, "_blank");
		}
	};

	const handleGetAutoAppprovalStatus = async (id) => {
		let inputData = {
			dealerId: id,
		};
		try {
			await RetailerDataService.GetDealerPermission(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;
					if (msg == "Success") {
						if (responseData) {
							let status = responseData.isChecked;
							let isAutoApproval = status == "True" ? true : false;
							setIsAutoApproval(isAutoApproval);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	const dispatch = useDispatch();
	const handleGetConversionCount = (dealerId) => {
		try {
			//
			let inputData = {
				vendorId: dealerId,
			};
			AdminDashboardDataService.GetConversionCount(inputData)
				.then((response) => {
					//
					var message = response.data.message;
					var responseData = response.data.responseData;
					// var allConvCount = responseData.allCount; setConversationCommonCount

					if (message === "Success") {
						if (responseData) {
							dispatch(setVendorConversationCount(responseData.allCount));
							dispatch(setVendorConversationCountSent(responseData.allSent));
							dispatch(
								setVendorConversationCountUnRead(responseData.allUnReadCount)
							);
							setConversationCommonCount(responseData);
						} else {
							setConversationCommonCount({});
						}
					} else {
						setConversationCommonCount({});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetRetailerAppointmentData = (
		id,
		salesRep,
		tradeShowId,
		fromDate,
		toDate
	) => {
		try {
			let inputData = {
				dealerId: id.toString(),
				salesRep: salesRep ? salesRep : "",
				tradeShowId: tradeShowId ? tradeShowId : "",
				fromDate: fromDate ? fromDate : "",
				toDate: toDate ? toDate : "",
				orderBy: "",
			};

			AdminDashboardDataService.GetRetailerAppointmentData(inputData)
				.then((response) => {
					var message = response.data.message;
					var responseData = response.data.responseData;
					var initialRecord = responseData[0];

					if (message === "Success") {
						if (responseData) {
							setAppointmentCount(responseData.length);
							dispatch(setVendorAppointmentCount(responseData.length));
						} else {
							setAppointmentCount(0);
						}
					} else {
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleRefresh = () => {
		setSortColumnName("dealerCompany");
		setSortType("asc");

		var ColumnName = "dealerCompany";
		var SortName = "asc";

		if (selectedTab == "myretailer") {
			if (dealerMemberTypeName == "VendorDiamond") {
				handleGetMyRetailerListVendor(dealerId, ColumnName, SortName);
			} else {
				handleGetMyRetailerList(dealerId, ColumnName, SortName);
			}
		}
		if (selectedTab == "allretailer") {
			handleGetALLRetailerList(
				dealerId,
				pageNo,
				pageSize,
				ColumnName,
				SortName,
				tableFilters
			);
		}
	};

	return (
		<React.Fragment>
			<div className="catalog__list dashbo_maindiv">
				<div className="catalog__topnav">
					<ul>
						{dealerMemberTypeName == "VendorDiamond" ? (
							<li onClick={() => setpageNameType("Retailer")}>
								{" "}
								<p className="topNavLinks">{t("Diamond Retailers")}</p>
							</li>
						) : (
							<li onClick={() => setpageNameType("Retailer")}>
								{" "}
								<p className="topNavLinks">
									{t("Jewelry Retailer & Permissions")}
								</p>
							</li>
						)}

						<li onClick={() => setpageNameType("ConversationsOnly")}>
							{" "}
							<p className="topNavLinks">
								{t("Conversations")}{" "}
								<span>
									{/* {Object.keys(conversationCommonCount).length !== 0
                    ? conversationCommonCount.allCount
                    : 0} */}
									{ContactDetails.vendorConversionCountUnRead}
								</span>
							</p>
						</li>
						<li onClick={() => setpageNameType("AppointmentsOnly")}>
							{" "}
							<p className="topNavLinks">
								{t("Appointments")}{" "}
								<span>
									{appointmentcount
										? appointmentcount
										: ContactDetails.vendorAppointmentCount}
									{/* {appointmentcount ? appointmentcount : 0} */}
								</span>
							</p>
						</li>
						{dealerMemberTypeName == "Vendor" && (
							<li onClick={() => setpageNameType("BlockedProducts")}>
								{" "}
								<p className="topNavLinks">{t("Blocked Products")} </p>
							</li>
						)}
					</ul>
				</div>
			</div>

			{pageNameType == "Retailer" && (
				<div className="prodetail__section mt-5">
					<div className="form__fields">
						<div className="col-md-12">
							<div className="prod__maindiv prodmarkup__secion permission__section">
								<div className="row">
									<div className="col-md-12">
										<h4 className="workarea__heading mt-0 mb-3">
											{" "}
											{/* {props.type
                        ? t("Diamond")
                        : t("Jewelry")}{" "}
                      {t("Retailer & Permissions")} */}
											{dealerMemberTypeName == "VendorDiamond"
												? t("Diamond Retailers")
												: t("Jewelry Retailer & Permissions")}
											{/* {t("Jewelry Data & Permissions")}{" "} */}
										</h4>
									</div>

									<div className="col-lg-9 col-md-12 position-relative">
										<div className="jrper__refresh">
											<Button
												type="primary"
												onClick={() => {
													handleRefresh();
												}}>
												{t("Refresh")}
											</Button>
										</div>
										<div className="prodesc__div">
											<Tabs
												defaultActiveKey="myretailer"
												id="uncontrolled-tab-example"
												className="tab__div mb-3"
												onSelect={(e) => {
													handleTabsChange(e);
												}}
												activeKey={selectedTab}>
												<Tab
													eventKey="myretailer"
													title={
														<>
															{t("My Retailers")} ({jewelryList.length})
														</>
													}>
													<div className="tab__contentdiv">
														<div className="col-lg-12">
															<div className="desc__maindiv">
																<div className="col-lg-12">
																	<Spin spinning={loading} size="large">
																		{/* <Table
                                 columns={
                                   props.type ? columnsDiamond : columnJewelry
                                 }
                                 className="d-none"
                                 dataSource={MyRetailerData}
                                 scroll={{ x: 600 }}
                               /> */}
																		<Table
																			key={tableKey}
																			columns={
																				props.type
																					? columnsDiamond
																					: dealerMemberTypeName ==
																					  "VendorDiamond"
																					? columnJewelryVendor
																					: columnJewelry
																			}
																			dataSource={
																				props.type ? data1 : jewelryList
																			}
																			onChange={handleTableASCDES}
																			onRow={(record, recordIndex) => ({
																				onClick: (event) => {
																					handleGetRetailerProfile(
																						record.dealerID,
																						record.showBtn,
																						dealerId
																					);
																				},
																			})}
																			rowClassName={(record) =>
																				record.dealerID == selectedColumnId
																					? "row-active"
																					: ""
																			}
																			loading={loading}
																			scroll={
																				props.type || jewelryList.length > 0
																					? { x: 800, y: 400 }
																					: {}
																			}
																			pagination={{
																				showSizeChanger: true,
																				responsive: true,
																			}}
																			className="mw-auto myreatailer"
																		/>
																	</Spin>
																</div>
															</div>
														</div>
													</div>
												</Tab>
												<Tab eventKey="allretailer" title={t("All Retailers")}>
													<div className="tab__contentdiv">
														<div className="desc__maindiv">
															<div className="col-lg-12">
																<Spin spinning={loading} size="large">
																	<Table
																		columns={
																			props.type
																				? columnsDiamond
																				: dealerMemberTypeName ==
																				  "VendorDiamond"
																				? columnAllVendorJewelry
																				: columnAllJewelry
																		}
																		dataSource={
																			props.type ? data1 : allJewelryList
																		}
																		onChange={handleTableASCDES}
																		onRow={(record, recordIndex) => ({
																			onClick: (event) => {
																				handleGetRetailerProfile(
																					record.dealerID,
																					record.showBtn,
																					dealerId
																				);
																			},
																		})}
																		rowClassName={(record) =>
																			record.dealerID == selectedColumnId
																				? "row-active"
																				: ""
																		}
																		loading={loading}
																		scroll={{
																			x: 600,
																			y: 400,
																		}}
																		pagination={{
																			showSizeChanger: true,
																			responsive: true,
																			pageNo: pageNo,
																			pageSize: pageSize,
																			pageSizeOptions: [10, 20, 50, 100],
																			total: totalCount,
																		}}
																	/>
																</Spin>
															</div>
														</div>
													</div>
												</Tab>
											</Tabs>
										</div>
									</div>

									<div
										key={profileKey}
										className="col-lg-3 col-md-12"
										// className={
										//   rowlength == true ? "col-lg-3 col-md-12" : "d-none"
										// }
									>
										<Spin spinning={loadingprofileData}>
											{Object.keys(rowDetails).length > 0 ? (
												<div className="right__section overflow__section">
													<div className="profile__block">
														<span href="#" className="retailer__name">
															{rowDetails.dealerCompany
																? rowDetails.dealerCompany
																: null}
														</span>
														<Link
															to={"/retailerProfile"}
															state={{
																key: "Profile",
																id: selectedColumnId,
															}}>
															<div className="logoImg__block p-0">
																<img src={rowDetails.companylogo} />
															</div>
														</Link>
														{dealerMemberTypeName != "VendorDiamond" &&
														selectedTab == "myretailer" ? (
															<div
																className={`input__block ${
																	productRestrictionData &&
																	productRestrictionData.length > 0
																		? "product-restriction"
																		: ""
																}`}>
																<label className="mt-1">
																	{" "}
																	{t("Product Restriction")}
																</label>

																<Select
																	showSearch
																	mode="multiple"
																	// placeholder="Select Skus"
																	value={selectedProductsToRestrict}
																	onChange={handleRestrictProductChange}>
																	{productRestrictionData.map((product) => {
																		return (
																			<Option value={product.gfInventoryID}>
																				{product.styleNumber}
																			</Option>
																		);
																	})}
																</Select>
																<div className="text-right">
																	<button
																		className="ant-btn ant-btn-default primary-btn mt-2"
																		onClick={handleRestrictedProductSubmit}>
																		{t("Save")}
																	</button>
																</div>
															</div>
														) : null}

														<hr />
														{dealerMemberTypeName != "VendorDiamond" &&
														selectedTab == "myretailer" ? (
															<div className="links__block">
																<ul>
																	<li>
																		<a onClick={() => setVisible(true)}>
																			{" "}
																			{t("Collection Access")}
																		</a>
																	</li>
																	<li>
																		<Link to="/blockedProduct">
																			<a> {t("Product Access")}</a>
																		</Link>
																	</li>
																</ul>
																<hr />
															</div>
														) : null}
														<p className="activeFrom">
															{t("Active")} |{" "}
															{rowDetails.activity ? rowDetails.activity : null}
														</p>
													</div>

													{/* <div className="retailer__dtls mb-4">
<p>
  {rowDetails.dealerName ? rowDetails.dealerName : null}
</p>
<p>
  {rowDetails.dealerEmail
    ? rowDetails.dealerEmail
    : null}
</p>
<p>
  {rowDetails.dealerPhone
    ? rowDetails.dealerPhone
    : null}
</p>
<a href={rowDetails.websiteAddress}>
  {rowDetails.websiteAddress
    ? rowDetails.websiteAddress
    : null}
</a>
<hr />
</div> */}

													<div className="links__block">
														<ul>
															<li>
																<Link
																	to={"/retailerProfile"}
																	state={{
																		key: "Profile",
																		id: selectedColumnId,

																		page:
																			rowDetails.showBtn == true
																				? "ShowAccess"
																				: "DonotshowAccess",
																	}}>
																	<i
																		class="fa fa-address-book-o"
																		aria-hidden="true"></i>{" "}
																	{t("View Profile")}
																</Link>
															</li>
															<li>
																<Link
																	to={"/retailerProfile"}
																	state={{
																		key: "Conversation",
																		id: selectedColumnId,
																		page:
																			rowDetails.retailerStatusID == 0
																				? "ShowAccess"
																				: "DonotshowAccess",
																	}}>
																	<i
																		class="fa fa-comments-o"
																		aria-hidden="true"></i>{" "}
																	{t("Conversation")}
																</Link>
															</li>
															<li>
																<Link
																	to={"/makeAppoitments"}
																	state={{
																		key: "Appointments",
																		id: selectedColumnId,

																		page:
																			rowDetails.retailerStatusID == 0
																				? "ShowAccess"
																				: "DonotshowAccess",
																	}}>
																	<i
																		class="fa fa-calendar-check-o"
																		aria-hidden="true"></i>{" "}
																	{t("Make Appointment")}
																</Link>
															</li>
															<li>
																<Link
																	to={"/retailerProfile"}
																	state={{
																		key: "Notes",
																		id: selectedColumnId,
																		page:
																			rowDetails.retailerStatusID == 0
																				? "ShowAccess"
																				: "DonotshowAccess",
																	}}>
																	<i
																		class="fa fa-pencil-square-o"
																		aria-hidden="true"></i>{" "}
																	{t("Store Notes")}
																</Link>
															</li>
														</ul>
														<hr />
													</div>

													<div className="bottom__block">
														<div className="input__block">
															<label> {t("Sales Rep")} </label>
															<Select
																placeholder={t("Select Sales Rep")}
																value={SalesRepId ? SalesRepId : null}
																name="userId"
																showSearch
																optionFilterProp="children"
																onChange={(e) =>
																	handleOnSelectChange(e, "select", "userId")
																}
																onSearch={onSearch}
																filterOption={(input, option) =>
																	option.children
																		.toLowerCase()
																		.indexOf(input.toLowerCase()) >= 0
																}>
																<Option value="null">Sales Rep</Option>
																{SalesRep.map((item) => {
																	return (
																		<Option value={item.userId}>
																			{item.name}{" "}
																		</Option>
																	);
																})}
															</Select>
														</div>

														{dealerMemberTypeName != "VendorDiamond" &&
														selectedTab == "myretailer" ? (
															<div className="input__block">
																<label> {t("Connected Retailer")} </label>
																<Switch
																	checkedChildren={t("Yes")}
																	unCheckedChildren={t("No")}
																	defaultChecked
																	name="disconnectSwitch"
																	checked={disconnectSwitch}
																	onChange={handleSwitch}
																/>
															</div>
														) : null}
													</div>
													<div className="retailer__dtls mb-4">
														<p>
															{rowDetails.dealerName
																? rowDetails.dealerName
																: null}
														</p>
														<a href={`mailto:${rowDetails.dealerEmail}`}>
															{rowDetails.dealerEmail
																? rowDetails.dealerEmail
																: null}
														</a>
														<p>
															{rowDetails.dealerPhone
																? rowDetails.dealerPhone
																: null}
														</p>
														<span
															className="linkText"
															onClick={() => openInNewTab()}>
															{" "}
															{rowDetails.websiteAddress}
														</span>

														<hr />
													</div>
													{dealerMemberTypeName != "VendorDiamond" &&
													selectedTab == "myretailer" ? (
														<>
															<div className="manage-retailer-access-desc">
																<h4>
																	{t(
																		"Manage Retailer Access To Your Inventory"
																	)}
																</h4>
																<p>
																	{t(
																		"Set permissions for retailers to display your virtual inventory on their website."
																	)}
																</p>
															</div>

															<hr />
														</>
													) : null}

													{dealerMemberTypeName != "VendorDiamond" &&
													selectedTab == "myretailer" ? (
														<div className="manage-retailer-access-desc">
															<h4>{t("Auto Approval")}</h4>
															<div className="input__block mb-2">
																<Switch
																	checkedChildren={t("Yes")}
																	unCheckedChildren={t("No")}
																	onChange={handleSwitchChange}
																	checked={isAutoApproval}
																/>
															</div>
															<p>
																{t(
																	"Automatically approve retailers requesting to open an account."
																)}
															</p>
														</div>
													) : null}
												</div>
											) : (
												<div className="right__section overflow__section">
													<p className="no_data_found">{t("No Data Found")}</p>
												</div>
											)}
										</Spin>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{pageNameType == "ConversationsOnly" && (
				<ConversationsDashboard
					pageNameType={pageNameType}
					conversationCommonCount={conversationCommonCount}
				/>
			)}

			{pageNameType == "AppointmentsOnly" && (
				<AppointmentsDashboard pageNameType={pageNameType} />
			)}
			{pageNameType == "BlockedProducts" && <BlockedProduct />}
			<Modal
				title={t("Collection Access")}
				centered
				visible={visible}
				onOk={() => handleCollectionSubmit()}
				onCancel={() => handleCancelCollectionModal()}
				width={700}>
				<div className="row">
					{dealersCollections.length > 0 ? (
						dealersCollections.map((dealer) => {
							return (
								<div className="col-md-4">
									<div className="radio__block">
										<input
											type="checkbox"
											checked={dealer.isassigned == "1" ? true : false}
											onChange={(e) => handleChangeCollectionId(e, dealer)}
										/>
										<label>
											{dealer.collection + " " + "(" + dealer.total + ")"}{" "}
										</label>
									</div>
								</div>
							);
						})
					) : (
						<h4 className="text-center">{t("No Data")}</h4>
					)}

					{/* <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>A. Link for Forevermark (31)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>A. Link for Forevermark (31)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>Ja-Collection (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio__block">
              <input type="checkbox" />
              <label>test123 (0)</label>
            </div>
          </div> */}
				</div>
			</Modal>

			{/* Request Access Modal */}
			<Modal
				title={
					page == "RetailerAffirmation"
						? t("Retailer Affirmation")
						: t("Send Email")
				}
				visible={isModalSendInvitationVisible}
				className="forgot__password"
				onCancel={handleSendInvitationModalCancel}
				footer={[
					//
					<div className={page == "RetailerAffirmation" ? "d-block" : "d-none"}>
						<Button
							key="submit"
							className={checkbox == "" ? "disabled-btn" : "primary-btn"}
							disabled={(checkbox == "") == true ? true : false}
							onClick={
								checkbox == "CurrentAuthorizedReseller"
									? handleShowEmailWindow
									: handleNewAccountRequest
							}>
							{t("Submit")}
						</Button>
					</div>,
					<div className={page == "SendEmail" ? "d-block" : "d-none"}>
						<Button
							loading={loading}
							className="primary-btn"
							key="submit"
							// onClick={handleSendEmail}
							onClick={handleSendEmail}>
							{t("Send")}
						</Button>
					</div>,
				]}
				key={key}>
				<div className="form__fields p-0 border-0 mh-auto">
					{page == "RetailerAffirmation" ? (
						<div>
							{category.map((item) => {
								return (
									<Fragment>
										<div className="radio__block">
											<Checkbox
												key={item.value}
												onChange={handleCheckBoxChange}
												checked={(item.value == checkbox) == "" ? "" : checkbox}
												value={item.value}
												id={item.value}>
												<label htmlFor={item.value}> {t(item.label)} </label>
											</Checkbox>
										</div>
										<div>
											<p> {item.note} </p>
										</div>
									</Fragment>
								);
							})}
						</div>
					) : (
						<div className="editor__section">
							<Spin spinning={emailLoading}>
								<CKEditor
									editor={ClassicEditor}
									data={content}
									// config={editorConfiguration}
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContent(data);
									}}
								/>
							</Spin>
						</div>
					)}
				</div>
			</Modal>

			{/* End */}
		</React.Fragment>
	);
};

export default JewelryRetailerPermission;
