import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Modal, Spin } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import DiamondLinksEmailTemplateService from "../../../services/diamondLinks-emailTemplate.service";

export const StudBuilderEmailTemplate = (props) => {
	const { t } = useTranslation();
	const [typeName, setTypeName] = useState(null);
	const [visible, setVisible] = useState(false);
	/*API Binding Starts*/

	//Dropdown List Starts
	const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
	const [dealerId, setDealerId] = useState(0);
	const [content, setContent] = useState("");
	const [relaod, setReload] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (loginDetials) {
			if (loginDetials.responseData) {
				setDealerId(loginDetials.responseData.dealerId);
			}
		}
	}, []);

	//Dropdown List Ends

	const handleEmailUpdate = () => {
		handleUpdateDiamondLinkEmailTemplateDetails(
			loginDetials.responseData.dealerId
		);
	};
	const refreshPage = () => {
		setVisible(false);
		setReload(!relaod);
	};

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const body = new FormData();
					loader.file.then((file) => {
						body.append("uploadImg", file);
					});
				});
			},
		};
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	const handleGetDiamondEmailTemplatesDetails = (name, id) => {
		try {
			let inputData = {
				dealerID: dealerId || id,
				typeName: name,
			};
			setContent("");
			setVisible(true);
			setTypeName(name);
			setLoading(true);
			DiamondLinksEmailTemplateService.GetDiamondEmailTemplatesDetails(
				inputData
			)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					let diamondEmailTemplatesDetailsList =
						response.data.responseData[0].emailContent;
					if (message === "Success") {
						if (responseData) {
							setContent(responseData[0].emailContent);
							setLoading(false);
						} else {
							setLoading(false);
						}
					} else {
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const handleUpdateDiamondLinkEmailTemplateDetails = (id) => {
		try {
			let inputData = {
				dealerID: dealerId || id,
				typeName: typeName,
				emailContent: content,
			};
			DiamondLinksEmailTemplateService.UpdateDiamondLinkEmailTemplateDetails(
				inputData
			)
				.then((response) => {
					let message = response.data.message;
					if (message === "Success") {
						NotificationManager.success(
							t("Email template has been saved successfully.")
						);
					} else {
						NotificationManager.error(
							t("Email template has not been updated.")
						);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};
	/*API Binding Ends*/

	return (
		<div className="form__fields">
			<div className="row">
				<div className="col-md-12">
					<h4 className="workarea__heading mt-0">
						{""}
						{t("Stud Builder Emails")}
						{""}{" "}
					</h4>
				</div>
			</div>

			<div className="emailTemplate">
				<div className="row">
					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Request More Info")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToRetailerInquires"
											)
										}>
										{""} {t("Send Mail to Retailer")} {""}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToSenderInquires"
											)
										}>
										{t("Send Mail to Sender")}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>{t("Email To Friend")}</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails("SBToFriendEmailF")
										}>
										{t("Send Mail to Friend")}
									</Link>{" "}
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToRetailerEmailF"
											)
										}>
										{""} {t("Send Mail To Retailer")} {""}{" "}
									</Link>{" "}
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails("SBToSenderEmailF")
										}>
										{t("Send Mail to Sender")}{" "}
									</Link>{" "}
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Drop A Hint")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToRecipientDropAHint"
											)
										}>
										{""} {t("Send Mail to Recipient")} {""}{" "}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToRetailerDropAHint"
											)
										}>
										{""} {t("Send Mail to Retailer")} {""}{" "}
									</Link>{" "}
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Request More Info")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToRetailerRequestmoreinfo"
											)
										}>
										{""} {t("Send Mail to Retailer")} {""}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToSenderRequestmoreinfo"
											)
										}>
										{t("Send Mail to Sender")}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Schedule View")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails("SBToScheduleView")
										}>
										{""} {t("SB To ScheduleView")} {""}{" "}
									</Link>{" "}
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBToScheduleViewComplete"
											)
										}>
										{""} {t("SB Complete Earring To ScheduleView")} {""}{" "}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Diamond Request More Info")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToRetailerInquires"
											)
										}>
										{""} {t("Send Mail to Retailer")} {""}{" "}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToSenderInquires"
											)
										}>
										{t("Send Mail to Sender")}{" "}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1> {t("Diamond Email To Friend")} </h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToFriendEmailF"
											)
										}>
										{t("Send Mail to Friend")}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToRetailerEmailF"
											)
										}>
										{""} {t("Send Mail To Retailer")} {""}{" "}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToSenderEmailF"
											)
										}>
										{t("Send Mail to Sender")}{" "}
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Diamond Drop A Hint")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToRecipientDropAHint"
											)
										}>
										{""} {t("Send Mail to Recipient")} {""}{" "}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails(
												"SBDiamondToRetailerDropAHint"
											)
										}>
										{""} {t("Send Mail to Retailer")} {""}{" "}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-4 mb-4">
						<div className="emailDetailBlock">
							<h1>
								{" "}
								{""} {t("Store")} {""}{" "}
							</h1>
							<ul>
								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails("SBToSenderStore")
										}>
										{""} {t("Send Mail to Recipient")} {""}{" "}
									</Link>
								</li>

								<li>
									<Link
										to=""
										onClick={() =>
											handleGetDiamondEmailTemplatesDetails("SBToRetailerStore")
										}>
										{""} {t("Send Mail to Retailer")} {""}{" "}
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<Modal
				className="emailTemplateEditorModal"
				title={t("Send Email")}
				centered
				visible={visible}
				onOk={() => {
					setVisible(false);
					handleEmailUpdate();
				}}
				onCancel={refreshPage}
				width={900}
				hieght={600}
				maskClosable={false}
				footer={[
					<Button key="back" onClick={refreshPage}>
						{t("Cancel")}
					</Button>,
					<Button
						className="primary-btn"
						onClick={() => {
							setVisible(false);
							handleEmailUpdate();
						}}>
						{t("Save")}
					</Button>,
				]}>
				<div className="editor__section">
					<Spin spinning={loading}>
						<CKEditor
							key={relaod}
							config={{
								extraPlugins: [uploadPlugin],
							}}
							editor={ClassicEditor}
							data={content ? content : ""}
							className="w-100"
							onChange={(event, editor) => {
								const data = editor.getData();
								setContent(data);
							}}
						/>
					</Spin>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StudBuilderEmailTemplate);
