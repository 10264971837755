import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Modal,
  Spin,
  DatePicker,
} from "antd";

import RetailerLogo from "../../assets/images/retailer-logo.jpeg";

import { Tabs, Tab } from "react-bootstrap";
import userIcon from "../../assets/images/user-icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { connect, useSelector, useDispatch } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import UserImg_icon from "../../assets/images/UserImage.png";
import Earth_icon from "../../assets/images/earth_icon.png";
import { Link, useNavigate } from "react-router-dom";
import Moment from "moment";
import {
  setContactConversationCount,
  setContactAppointmentCount,
  setContactCustomerCount,
  setContactPageNameType,
  setProfileID,
  setProfileTab,
} from "../../actions/Contact/Contact";
import {
  setHeaderMenuData,
  setChecklinkHeaderMenuData,
} from "../../actions/headerMenu/headerMenu";
import AtIcon from "../../assets/images/AtIcon";
import LocationIcon from "../../assets/images/LocationIcon";
import CakeIcon from "../../assets/images/CakeIcon";
import GenderIcon from "../../assets/images/GenderIcon";
import MetalIcon from "../../assets/images/MetalIcon";
import IndexFinger from "../../assets/images/IndexFinger";
import PhoneIcon from "../../assets/images/PhoneIcon";
import LikeIcon from "../../assets/images/LikeIcon";

const iconStyle = { fontSize: "44px" };
const ProfileNew = () => {
  const { t } = useTranslation();
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const [visible, setVisibleTag] = useState(false);
  const [visibleNote, setVisibleNote] = useState(false);
  const [details, setDetails] = useState("");
  const [addDetailsCount, setAddDetailsCount] = useState(0);
  const [dealerId, setDealerId] = useState(0);
  const [customerID, setCustomerID] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [notesLoading, setNotesLoading] = useState(false);
  const [noteDetails, setNoteDetails] = useState([]);
  const [rightSideDetails, setRightSideDetails] = useState({
    cust_id: "",
  });
  const [rightSideData, setRightSideData] = useState([]);
  const [rightSideLoading, setRightSideLoading] = useState(false);
  const [noteData, setNoteData] = useState({
    note: "",
  });
  const [newNotes, setNewNotes] = useState({
    noteName: "",
    modalNote: "",
  });
  const [editNotes, setEditNotes] = useState({
    noteName: "",
    modalNote: "",
    cNote_id: "",
  });
  const [visibleEditNote, setVisibleEditNote] = useState(false);
  const [notesValidations, setNotesValidations] = useState({
    noteNameVal: "",
    modalNoteVal: "",
  });
  const [validations, setValidations] = useState({
    noteVal: "",
  });
  const [favouriteData, setFavouriteData] = useState({
    favourite: "",
  });
  const [validations1, setValidations1] = useState({
    favouriteVal: "",
  });
  const [favouriteList, setFavouriteList] = useState([]);
  const [tagData, setTagData] = useState({
    tag: "",
  });
  const [validations2, setValidations2] = useState({
    tagVal: "",
  });
  const [tagList, setTagList] = useState([]);
  const [notesCount, setNotesCount] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [diamondConversationList, setDiamondConversationList] = useState([]);
  const [jewelryConversationList, setJewelryConversationList] = useState([]);
  const [conversationData, setConversationData] = useState({
    addconversation: "",
  });
  const [conversationValidations, setConversationValidations] = useState({
    conversationVal: "",
  });
  const [postMsgLoading, setPostMsgLoading] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteConversationData, setDeleteConversationData] = useState({
    id: "",
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  // state for Modals

  const [rightSideDataDummy, setRightSideDataDummy] = useState([]);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState("");
  const ref = useRef();
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [addressOption, setAddressOption] = useState("");
  const [formDetails, setFormDetails] = useState({
    phoneId: "", // new ""
    phoneNumber: "",
    phoneTypeId: "0",
    email: "",
    gender: "1",
    birthdaydate: "",
    anniversarydate: "",
    currentinterestid: "",
    favoritemetailid: "",
    ringSizeid: "", // new ""
    ringTypeName: "",
    ringValue: "",
  });
  const [phoneNumberVal, setPhoneNumberVal] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [currentInterestList, setCurrentInterestList] = useState([]);
  const [metalList, setMetalList] = useState([]);
  const [genderModalVisible, setGenderModalVisible] = useState(false);
  const [currentInteresetModalVisible, setCurrentInteresetModalVisible] =
    useState(false);
  const [favoriteMetalModalVisible, setFavoriteMetalModalVisible] =
    useState(false);
  const [BirthDayDate, setBirthDayDate] = useState("");
  const [birthDayDateMoment, setBirthDayDateMoment] = useState("");
  const [birthDayModalVisible, setBirthDayModalVisible] = useState(false);
  const [anniversaryModalVisible, setAnniversaryModalVisible] = useState(false);
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [anniversaryDateMoment, setAnniversaryDateMoment] = useState("");
  const [fingerSizeModalVisible, setFingerSizeModalVisible] = useState(false);
  const [fingerType, setFingerType] = useState("");
  const [addressData, setAddressData] = useState({
    street: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [addressModalData, setAddressModalData] = useState({
    street: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressResponse, setAddressResponse] = useState({});
  const [addressDetails, setAddressDetails] = useState({
    street: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [addressModalLoading, setAddressModalLoading] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [birthDayValidation, setBirthDayValidation] = useState("");
  const [birthDayValidation1, setBirthDayValidation1] = useState("");
  const [anniversaryValidation, setAnniversaryValidation] = useState("");
  const [anniversaryValidation1, setAnniversaryValidation1] = useState("");
  const [favoriteMetalValidation, setFavoriteMetalValidation] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentInterestValidation, setCurrentInterestValidation] =
    useState("");
  const [inventoryID, setInventoryID] = useState(0);
  const [VendorNameForPopup, setVendorNameForPopup] = useState("");
  const [favoriteMetalValidation1, setFavoriteMetalValidation1] = useState("");
  const [currentInterestValidation1, setCurrentInterestValidation1] =
    useState("");
  const [imageData, setImageData] = useState({
    ImagefileName: null,
    Imagefile: null,
    showsImagePath: null,
  });
  const [imageValidation, setImageValidation] = useState({
    ImageVal: "",
  });
  const [imageUpdateLoading, setImageUpdateLoading] = useState(false);
  const [leftSideData, setLeftSideData] = useState({});
  const [leftSideLoading, setLeftSideLoading] = useState(false);
  const [tabs, setTabs] = useState("Customer");
  const [contactVenorModel, setContactVendorModel] = useState(false);

  const [requestDiamondVendorInputData, setRequestDiamondVendorInputData] =
    useState({
      personalMessage: "",
    });
  const [
    requestDiamondVendorInputDataValidation,
    setRequestDiamondVendorInputDataValidation,
  ] = useState({
    personalMessageval: "",
  });

  // Count States and Others

  const [conservationCount, setConservationCount] = useState(0);
  const [appointmentcount, setAppointmentCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const ContactDetails = useSelector((state) => state.contactReducer);
  const ProfileId = useSelector((state) => state.contactReducer.profileID);
  const ProfileTab = useSelector((state) => state.contactReducer.profileTab);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
        if (location.state) {
          setCustomerID(location.state.id);
          dispatch(setProfileID(location.state.id));
          if (location.state.key) {
            if (location.state.key == "Conversation" && location.state.id) {
              setSelectedTab("Conversation");
              dispatch(setProfileTab("Conversation"));
              handleUpdateUserCoversationIsReadDetails(
                loginDetials.loginDetials.responseData.dealerId,
                location.state.id
              );
              setTimeout(() => {
                handleGetAppoinmentDetailsById(
                  loginDetials.loginDetials.responseData.dealerId,
                  location.state.id
                );
              }, 100);

              setRightSideDetails({
                cust_id: location.state.id,
              });
              setTimeout(() => {
                handleGetCustomerContactDetailsById(
                  loginDetials.loginDetials.responseData.dealerId,
                  location.state.id
                );
              }, 300);
              setTimeout(() => {
                handleGetLeftSideMenuDetails(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);
              setTimeout(() => {
                handleGetCustomerCount(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);
            } else if (location.state.key == "Profile" && location.state.id) {
              setSelectedTab("Profile");
              dispatch(setProfileTab("Conversation"));
              setRightSideDetails({
                cust_id: location.state.id,
              });
              handleGetCustomerContactDetailsById(
                loginDetials.loginDetials.responseData.dealerId,
                location.state.id
              );
              setTimeout(() => {
                handleGetCurrentInterestList();
              }, 300);
              setTimeout(() => {
                handleGetFavoriteMetalList();
              }, 300);
              setTimeout(() => {
                handleGetLeftSideMenuDetails(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);

              setTimeout(() => {
                handleGetCustomerCount(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);

              // handleGetUserProfileAdressDetails(loginDetials.loginDetials.responseData.dealerId,location.state.id);
            }
          }
        } else {
          setCustomerID(ProfileId);
          if (ProfileTab) {
            if (ProfileTab == "Conversation" && ProfileId) {
              setSelectedTab("Conversation");
              // dispatch(setProfileTab("Conversation"));
              handleUpdateUserCoversationIsReadDetails(
                loginDetials.loginDetials.responseData.dealerId,
                ProfileId
              );
              setTimeout(() => {
                handleGetAppoinmentDetailsById(
                  loginDetials.loginDetials.responseData.dealerId,
                  ProfileId
                );
              }, 100);

              setRightSideDetails({
                cust_id: ProfileId,
              });
              setTimeout(() => {
                handleGetCustomerContactDetailsById(
                  loginDetials.loginDetials.responseData.dealerId,
                  ProfileId
                );
              }, 300);
              setTimeout(() => {
                handleGetLeftSideMenuDetails(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);
              setTimeout(() => {
                handleGetCustomerCount(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);
            } else if (ProfileTab == "Profile" && ProfileId) {
              setSelectedTab("Profile");
              // dispatch(setProfileTab("Conversation"));
              setRightSideDetails({
                cust_id: ProfileId,
              });
              handleGetCustomerContactDetailsById(
                loginDetials.loginDetials.responseData.dealerId,
                ProfileId
              );
              setTimeout(() => {
                handleGetCurrentInterestList();
              }, 300);
              setTimeout(() => {
                handleGetFavoriteMetalList();
              }, 300);
              setTimeout(() => {
                handleGetLeftSideMenuDetails(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);

              setTimeout(() => {
                handleGetCustomerCount(
                  loginDetials.loginDetials.responseData.dealerId
                );
              }, 200);

              // handleGetUserProfileAdressDetails(loginDetials.loginDetials.responseData.dealerId,location.state.id);
            }
          }
        }
      }
    }
  }, []);

  // Redux Count

  useEffect(() => {
    if (ContactDetails) {
      setConservationCount(ContactDetails.contactConversationCount);
      setAppointmentCount(ContactDetails.contactAppointmentCount);
      setCustomerCount(ContactDetails.contactCustomerCount);
    }
  }, [ContactDetails]);

  const dispatch = useDispatch();
  const handleSendCount = (val1, val2, val3) => {
    dispatch(setContactConversationCount(val1));
    dispatch(setContactAppointmentCount(val2));
    dispatch(setContactCustomerCount(val3));
  };

  const handleGetCustomerCount = async (id, condition, sortby) => {
    let inputData = {
      dealerId: id.toString(),
      condition:
        "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30",
      sortBy: sortby ? sortby : "",
    };

    try {
      AdminDashboardDataService.GetCustomerCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            handleSendCount(
              responseData.conservationCount,
              responseData.appointmentcount,
              responseData.customerCount
            );
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setVisibleTag(false);
  };

  const handleOk = () => {
    setVisibleTag(false);
  };

  // Start

  const handleDetailsChange = (e) => {
    setDetails(e);
    setFormDetails({
      phoneId: "",
      phoneNumber: "",
      phoneTypeId: "0",
      email: "",
      gender: "1",
      birthdaydate: "",
      anniversarydate: "",
      currentinterestid: "",
      favoritemetailid: "",
      ringSizeid: "",
      ringTypeName: "",
      ringValue: "",
    });
    setBirthDayValidation("");
    setAnniversaryValidation("");
    setPhoneNumberVal("");
    setFavoriteMetalValidation("");
    setCurrentInterestValidation("");
  };

  const handleAddDetailsCheck = () => {
    setDetails("");
    if (addDetailsCount == 1) {
      setAddDetailsCount(0);
    } else {
      setAddDetailsCount(1);
    }
  };

  const handelCancelButtonForAll = () => {
    setDetails("");
    setAddDetailsCount(0);
    setFormDetails({
      phoneId: "",
      phoneNumber: "",
      phoneTypeId: "0",
      email: "",
      gender: "1",
      birthdaydate: "",
      anniversarydate: "",
      currentinterestid: "",
      favoritemetailid: "",
      ringSizeid: "",
      ringTypeName: "",
      ringValue: "",
    });
    setAddressData({
      street: "",
      suite: "",
      city: "",
      state: "",
      zipCode: "",
    });
    setBirthDayValidation("");
    setAnniversaryValidation("");
    setPhoneNumberVal("");
    setFavoriteMetalValidation("");
    setCurrentInterestValidation("");
  };

  const handleTabsChange = (e) => {
    setSelectedTab(e);
    dispatch(setProfileTab(e));

    if (e == "Conversation") {
      handleGetAppoinmentDetailsById(dealerId, customerID);
      setTimeout(() => {
        handleGetCustomerContactDetailsById(dealerId, customerID);
      }, 200);
      setTimeout(() => {
        handleUpdateUserCoversationIsReadDetails(dealerId, customerID);
      }, 200);
    }
    if (e == "Profile") {
      handleGetCustomerContactDetailsById(dealerId, customerID);
      handelCancelButtonForAll();
      setTimeout(() => {
        handleGetCurrentInterestList();
      }, 200);
      setTimeout(() => {
        handleGetFavoriteMetalList();
      }, 200);
      setImageData({
        ImagefileName: null,
        Imagefile: null,
        showsImagePath: null,
      });
    }
  };

  // API Binding Start

  const handleGetNotesDetailsById = async (id) => {
    // let isValid = handleValidation();

    // if(!isValid){
    //   return false;
    // }
    let inputData = {
      dealerId: dealerId,
      customerId: id,
    };
    setNotesLoading(true);
    try {
      AdminDashboardDataService.GetNotesDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setNoteDetails(responseData);
            setNotesLoading(false);
            setNotesCount(responseData.length);
          } else {
            setNoteDetails([]);
            setNotesLoading(false);
            setNotesCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
          setNotesLoading(false);
        });
    } catch (error) {
      console.log(error);
      setNotesLoading(false);
    }
  };

  const handleGetCustomerContactDetailsById = async (dealerid, id) => {
    let inputData = {
      dealerID: dealerid ? dealerid : "",
      cust_Id: id,
    };
    setRightSideLoading(true);
    setProfileLoading(true);
    try {
      AdminDashboardDataService.GetCustomerContactDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var rightSideResData = response.data.responseData.customerReponse[0];
          if (message === "Success") {
            if (rightSideResData) {
              setAddressResponse(responseData);
              setRightSideData(rightSideResData);
              setRightSideDataDummy(rightSideResData);
              if (rightSideResData.cBDayD !== 0) {
                let dayLength = rightSideResData.cBDayD.toString().length;
                let monthLength = rightSideResData.cBDayM.toString().length;

                let dayCondition =
                  dayLength == 1
                    ? "0" + rightSideResData.cBDayD
                    : rightSideResData.cBDayD;
                let monthCondition =
                  monthLength == 1
                    ? "0" + rightSideResData.cBDayM
                    : rightSideResData.cBDayM;

                let birthDate =
                  rightSideResData.cBDayY +
                  "-" +
                  monthCondition +
                  "-" +
                  dayCondition;
                let birthdatemoment =
                  monthCondition +
                  "-" +
                  dayCondition +
                  "-" +
                  rightSideResData.cBDayY;
                setBirthDayDate(birthDate);
                setBirthDayDateMoment(birthdatemoment);
              }
              if (rightSideResData.cAnniDay !== 0) {
                let dayLength = rightSideResData.cAnniDay.toString().length;
                let monthLength = rightSideResData.cAnniMonth.toString().length;

                let dayCondition =
                  dayLength == 1
                    ? "0" + rightSideResData.cAnniDay
                    : rightSideResData.cAnniDay;
                let monthCondition =
                  monthLength == 1
                    ? "0" + rightSideResData.cAnniMonth
                    : rightSideResData.cAnniMonth;

                let anniversary =
                  rightSideResData.cAnniYear +
                  "-" +
                  monthCondition +
                  "-" +
                  dayCondition;
                let anniversarymoment =
                  monthCondition +
                  "-" +
                  dayCondition +
                  "-" +
                  rightSideResData.cAnniYear;
                setAnniversaryDate(anniversary);
                setAnniversaryDateMoment(anniversarymoment);
              }
              // handleGetUserProfileAdressDetails(dealerid,id);
              // handleGetFavoriteListDetails(id);
              // handleGetTagsListDetails(id);
              setRightSideLoading(false);
              setProfileLoading(false);
            } else {
              setAddressResponse({});
              setRightSideData([]);
              setRightSideDataDummy([]);
              handleGetFavoriteListDetails(id);
              handleGetTagsListDetails(id);
              setRightSideLoading(false);
              setProfileLoading(false);
            }
          } else {
            setAddressResponse({});
            setRightSideData([]);
            setRightSideDataDummy([]);
            setRightSideLoading(false);
            setProfileLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
          setProfileLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
      setProfileLoading(false);
    }
  };

  const handleGetFavoriteListDetails = async (id) => {
    let inputData = {
      dealerID: dealerId,
      cust_Id: id,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.GetFavoriteListDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setFavouriteList(responseData);
          } else {
            setFavouriteList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateUserCoversationIsReadDetails = (dealerId, id) => {
    let inputData = {
      dealerId: dealerId,
      customerId: id,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.UpdateUserCoversationIsReadDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setTagData({
        ...tagData,
        [name]: e,
      });
    } else {
      setTagData({
        ...tagData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "tag") {
        setValidations2((prevdata) => ({
          ...prevdata,
          tagVal: "",
        }));
      }
    }
  };

  const handleValidationTag = () => {
    const { tag } = tagData;

    const validations2 = {
      tagVal: "",
    };
    let isValid = true;

    if (!tag.trim()) {
      isValid = false;
      validations2.tagVal = "Tag Cannot Be Blank.";
    } else {
      validations2.tagVal = "";
    }

    setValidations2(validations2);
    return isValid;
  };

  // Add Tag

  const handleInsertTagsList = () => {
    let isValid = handleValidationTag();

    if (!isValid) {
      return false;
    }
    let inputData = {
      customerId: rightSideDetails.cust_id,
      tagName: tagData.tag ? tagData.tag : "",
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.InsertTagsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(t("Tag Added Successfully."));
            handleCancel();
            handleGetCustomerContactDetailsById(
              dealerId,
              rightSideDetails.cust_id
            );
            setRightSideLoading(false);
            setTagData({
              tag: "",
            });
          } else {
            NotificationManager.error(t("Tag Not Added."));
            handleCancel();
            setRightSideLoading(false);
            setTagData({
              tag: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  const handleGetTagsListDetails = async (id) => {
    let inputData = {
      cust_Id: id,
      dealerID: dealerId,
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.GetTagsListDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setTagList(responseData);
            setRightSideLoading(false);
          } else {
            setTagList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  const handleFavouriteChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFavouriteData({
        ...favouriteData,
        [name]: e,
      });
    } else {
      setFavouriteData({
        ...favouriteData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "favourite") {
        setValidations1((prevdata) => ({
          ...prevdata,
          favouriteVal: "",
        }));
      }
    }
  };

  const handleValidationFav = () => {
    const { favourite } = favouriteData;

    const validations1 = {
      favouriteVal: "",
    };
    let isValid = true;

    if (!favourite.trim()) {
      isValid = false;
      validations1.favouriteVal = t("Data Cannot Be Blank.");
    } else {
      validations1.favouriteVal = "";
    }

    setValidations1(validations1);
    return isValid;
  };

  const handleInsertAddFavoriteList = () => {
    let isValid = handleValidationFav();

    if (!isValid) {
      return false;
    }
    let inputData = {
      customerId: rightSideDetails.cust_id.toString(),
      dealerId: dealerId.toString(),
      favoriteName: favouriteData.favourite.toString(),
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.InsertAddFavoriteList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(t("Data Added Successfully."));
            handleGetFavoriteListDetails(rightSideDetails.cust_id);
            setRightSideLoading(false);
            setFavouriteData({
              favourite: "",
            });
          } else {
            NotificationManager.error(t("Data Not Added."));
            setRightSideLoading(false);
            setFavouriteData({
              favourite: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  const handleNoteChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setNoteData({
        ...noteData,
        [name]: e,
      });
    } else {
      setNoteData({
        ...noteData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "note") {
        setValidations((prevdata) => ({
          ...prevdata,
          noteVal: "",
        }));
      }
    }
  };

  const handleValidation = () => {
    const { note } = noteData;

    const validations = {
      noteVal: "",
    };
    let isValid = true;

    if (!note.trim()) {
      isValid = false;
      validations.noteVal = t("Note Cannot Be Blank.");
    } else {
      validations.noteVal = "";
    }

    setValidations(validations);
    return isValid;
  };

  const handleNoteChange1 = (e, isSelect, name) => {
    if (isSelect === "select") {
      setNewNotes({
        ...newNotes,
        [name]: e,
      });
    } else {
      setNewNotes({
        ...newNotes,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "noteName") {
        setNotesValidations((prevdata) => ({
          ...prevdata,
          noteNameVal: "",
        }));
      }
      if (e.target.name === "modalNote") {
        setNotesValidations((prevdata) => ({
          ...prevdata,
          modalNoteVal: "",
        }));
      }
    }
  };

  const handleValidationNewNotes = () => {
    const { noteName, modalNote } = newNotes;

    const notesValidations = {
      noteNameVal: "",
      modalNoteVal: "",
    };
    let isValid = true;

    if (!noteName.trim()) {
      isValid = false;
      notesValidations.noteNameVal = t("Note Name Cannot Be Blank.");
    } else {
      notesValidations.noteNameVal = "";
    }
    if (!modalNote.trim()) {
      isValid = false;
      notesValidations.modalNoteVal = t("Note Details Cannot Be Blank.");
    } else {
      notesValidations.modalNoteVal = "";
    }

    setNotesValidations(notesValidations);
    return isValid;
  };

  const handleModalClose = () => {
    setVisibleNote(false);
    setNewNotes({
      noteName: "",
      modalNote: "",
    });
    setNotesValidations({
      noteNameVal: "",
      modalNoteVal: "",
    });
  };

  const handleEditModalClose = () => {
    setVisibleEditNote(false);
    setEditNotes({
      noteName: "",
      modalNote: "",
      cNote_id: "",
    });
  };

  const handleEditNoteChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditNotes({
        ...editNotes,
        [name]: e,
      });
    } else {
      setEditNotes({
        ...editNotes,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Modal Notes Add

  const handleInsertNoteMsgModal = () => {
    let isValid = handleValidationNewNotes();

    if (!isValid) {
      return false;
    }
    let inputData = {
      noteName: newNotes.noteName ? newNotes.noteName : "",
      noteDetails: newNotes.modalNote ? newNotes.modalNote : "",
      customerId: rightSideDetails.cust_id.toString(),
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.InsertNoteMsg(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setVisibleNote(false);
            NotificationManager.success(t("Note Added Successfully."));
            setRightSideLoading(false);
            setNewNotes({
              noteName: "",
              modalNote: "",
            });
            handleGetNotesDetailsById(rightSideDetails.cust_id);
          } else {
            setVisibleNote(false);
            NotificationManager.error(t("Note Not Added."));
            setRightSideLoading(false);
            setNewNotes({
              noteName: "",
              modalNote: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setVisibleNote(false);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVisibleNote(false);
      setRightSideLoading(false);
    }
  };

  // Add Note
  const handleInsertNoteMsg = () => {
    let isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    let inputData = {
      noteName: "",
      noteDetails: noteData.note ? noteData.note : "",
      customerId: rightSideDetails.cust_id.toString(),
    };
    setRightSideLoading(true);
    try {
      AdminDashboardDataService.InsertNoteMsg(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(t("Note Added Successfully."));
            setRightSideLoading(false);
            setNoteData({
              note: "",
            });
            handleGetNotesDetailsById(rightSideDetails.cust_id);
          } else {
            NotificationManager.error(t("Note Not Added."));
            setRightSideLoading(false);
            setNoteData({
              note: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setRightSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRightSideLoading(false);
    }
  };

  // Update Note Modal API

  const handleUpdateNotesAppointment = () => {
    let inputData = {
      noteName: editNotes.noteName ? editNotes.noteName : "",
      noteDetails: editNotes.modalNote ? editNotes.modalNote : "",
      noteId: editNotes.cNote_id ? editNotes.cNote_id : "",
    };
    setUpdateLoading(true);
    try {
      AdminDashboardDataService.UpdateNotesAppointment(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setUpdateLoading(false);
            setVisibleEditNote(false);
            NotificationManager.success(t("Note Updated Successfully."));
            setEditNotes({
              noteName: "",
              modalNote: "",
              cNote_id: "",
            });
            handleGetNotesDetailsById(rightSideDetails.cust_id);
          } else {
            setUpdateLoading(false);
            setVisibleEditNote(false);
            NotificationManager.error(t("Note Not Updated."));
            setEditNotes({
              noteName: "",
              modalNote: "",
              cNote_id: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setUpdateLoading(false);
          setVisibleEditNote(false);
        });
    } catch (error) {
      console.log(error);
      setUpdateLoading(false);
      setVisibleEditNote(false);
    }
  };

  const handleOpenEditModal = (item) => {
    console.log(item);
    setVisibleEditNote(true);
    setEditNotes({
      noteName: item.cNoteTitle,
      modalNote: item.cNoteDesc,
      cNote_id: item.cNote_id,
    });
  };

  let navigate = useNavigate();
  const handleRedirection = (type) => {
    if (type == "Profile") {
      // dispatch(setChecklinkHeaderMenuData("Retailers"));
      setSelectedTab("Profile");
      handleGetCustomerContactDetailsById(dealerId, customerID);
    } else if (type == "Conversation") {
      setSelectedTab("Conversation");
      handleGetAppoinmentDetailsById(dealerId, customerID);
      handleGetCustomerContactDetailsById(dealerId, customerID);
    }
  };

  // Conversation API

  const handleGetAppoinmentDetailsById = async (dealerId, id) => {
    let inputData = {
      dealerId: dealerId,
      customerId: id,
    };
    setConversationLoading(true);
    try {
      AdminDashboardDataService.GetAppoinmentDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setConversationLoading(false);
            setConversationList(responseData.appointmentMessageByIdResponses);
            setDiamondConversationList(responseData.diamondResponseConversions);
            setJewelryConversationList(responseData.jewelryResponseConversion);
          } else {
            setConversationLoading(false);
            setConversationList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setConversationLoading(false);
        });
    } catch (error) {
      console.log(error);
      setConversationLoading(false);
    }
  };

  const handleConversationDataChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setConversationData({
        ...conversationData,
        [name]: e,
      });
    } else {
      setConversationData({
        ...conversationData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "addconversation") {
        setConversationValidations((prevdata) => ({
          ...prevdata,
          conversationVal: "",
        }));
      }
    }
  };

  const handleValidationConversation = () => {
    const { addconversation } = conversationData;

    const conversationValidations = {
      conversationVal: "",
    };
    let isValid = true;

    if (!addconversation.trim()) {
      isValid = false;
      conversationValidations.conversationVal = t("Message Cannot Be Blank.");
    } else {
      conversationValidations.conversationVal = "";
    }

    setConversationValidations(conversationValidations);
    return isValid;
  };

  const handlePostMsg = () => {
    var isValid = handleValidationConversation();
    if (!isValid) {
      return false;
    }

    let inputData = {
      dealerID: dealerId.toString(),
      customerID: customerID.toString(),
      msg: conversationData.addconversation,
    };
    setPostMsgLoading(true);

    try {
      AdminDashboardDataService.PostMsg(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setPostMsgLoading(false);
            NotificationManager.success(t("Message Added Successfully."));
            setConversationData({
              addconversation: "",
            });
            handleGetAppoinmentDetailsById(dealerId, customerID);
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setPostMsgLoading(false);
            setConversationData({
              addconversation: "",
            });
            NotificationManager.error(t("Message Not Added."));
          }
        })
        .catch((error) => {
          console.log(error);
          setPostMsgLoading(false);
        });
    } catch (error) {
      console.log(error);
      setPostMsgLoading(false);
    }
  };

  const handleRemoveCustomerConversation = () => {
    let inputData = {
      conersionId: deleteConversationData.id,
    };
    setDeleteLoading(true);

    try {
      AdminDashboardDataService.RemoveCustomerConversation(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.success(
              t("Conversation Has Been Remove Successfully.")
            );
            handleGetAppoinmentDetailsById(dealerId, customerID);
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.error(t("Conversation Has Not Been Remove."));
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteLoading(false);
          setDeleteModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
    }
  };

  const handleDeleteModal = (id) => {
    setDeleteModalVisible(true);
    setDeleteConversationData({
      id: id,
    });
  };

  const handleCancelModal = () => {
    setDeleteModalVisible(false);
    setDeleteConversationData({
      id: "",
    });
  };

  // Function for Modal

  const handleEmailModalCancel = () => {
    setEmailModalVisible(false);
    setEmailValidation("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleRightSideChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRightSideDataDummy({
        ...rightSideDataDummy,
        [name]: e,
      });
    } else {
      var rgx = /[\^!@#$%^&*()_?-]/g;
      if (
        name == "cSizeLI" ||
        name == "cSizeLT" ||
        name == "cSizeRM" ||
        name == "cSizeLL" ||
        name == "cSizeRL" ||
        name == "cSizeLM" ||
        name == "cSizeLM" ||
        name == "cSizeRT" ||
        name == "cSizeLR" ||
        name == "cSizeRR"
      ) {
        if (rgx.test(e.target.value)) {
          e.preventDefault();
        } else {
          setRightSideDataDummy({
            ...rightSideDataDummy,
            [e.target.name]: e.target.value,
          });
        }
      } else {
        setRightSideDataDummy({
          ...rightSideDataDummy,
          [e.target.name]: e.target.value,
        });
      }
      // setRightSideDataDummy({
      //     ...rightSideDataDummy,
      //     [e.target.name]: e.target.value,
      // });

      if (e.target.name == "cEmail") {
        setEmailValidation("");
      }
    }
  };

  const handleBirthDayValidation1 = () => {
    let isValid = true;

    if (!rightSideDataDummy.birthdayDate.trim()) {
      isValid = false;
      setBirthDayValidation1(t("BirthDay Date is Compulsory."));
    } else {
      setBirthDayValidation1("");
    }
    return isValid;
  };

  const handleAnniversaryValidation1 = () => {
    let isValid = true;

    if (!rightSideDataDummy.anniversaryDate.trim()) {
      isValid = false;
      setAnniversaryValidation1(t("Anniversary Date is Compulsory."));
    } else {
      setAnniversaryValidation1("");
    }
    return isValid;
  };

  const handleFavoriteMetalValidation1 = () => {
    let isValid = true;

    if (rightSideDataDummy.favoriteMetalId == "") {
      isValid = false;
      setFavoriteMetalValidation1(t("Favorite Metal Field is Compulsory."));
    } else {
      setFavoriteMetalValidation1("");
    }
    return isValid;
  };

  const handleCurrentInterestValidation1 = () => {
    let isValid = true;

    if (rightSideDataDummy.currentInterestId == "") {
      isValid = false;
      setCurrentInterestValidation1(t("Current Interest Field is Compulsory."));
    } else {
      setCurrentInterestValidation1("");
    }
    return isValid;
  };

  const handleEmailValidation = () => {
    let isValid = true;

    var validRegex =
      /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;

    if (!rightSideDataDummy.cEmail.trim()) {
      isValid = false;
      setEmailValidation(t("Email is Compulsory."));
    } else if (!rightSideDataDummy.cEmail.match(validRegex)) {
      isValid = false;
      setEmailValidation(t("Email address is invalid."));
    } else {
      setEmailValidation("");
    }
    return isValid;
  };

  // Phone Number
  const handlePhoneModalCancel = () => {
    setPhoneModalVisible(false);
    setPhoneValidation("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  const handlePhoneValidation = () => {
    let isValid = true;

    if (!rightSideDataDummy.phoneNumber.trim()) {
      isValid = false;
      setPhoneValidation(t("Phone Number is Compulsory."));
    } else if (
      (rightSideDataDummy.phoneNumber.length < 15 &&
        rightSideDataDummy.phoneNumber.includes("-") &&
        /^[0-9]+$/.test(rightSideDataDummy.phoneNumber)) ||
      (rightSideDataDummy.phoneNumber.length < 15 &&
        rightSideDataDummy.phoneNumber.includes("+") &&
        /^[0-9]+$/.test(rightSideDataDummy.phoneNumber))
    ) {
      setPhoneValidation("");
    } else if (
      /^0*$/.test(rightSideDataDummy.phoneNumber) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(rightSideDataDummy.phoneNumber)
    ) {
      isValid = false;
      setPhoneValidation(t("Phone number is invalid"));
    } else if (
      rightSideDataDummy.phoneNumber.length > 15 ||
      rightSideDataDummy.phoneNumber.length < 10
    ) {
      isValid = false;
      setPhoneValidation(t("Phone number is invalid"));
    } else {
      setPhoneValidation("");
    }
    return isValid;
  };

  const handleaddressModalCancel = () => {
    setAddressModalVisible(false);
  };

  const handleAddressModal = (type) => {
    setAddressOption(type);

    if (type == "Billing Address") {
      setAddressModalData({
        street: addressResponse.billingAddress[0].cAddr1,
        suite: addressResponse.billingAddress[0].cAddr2,
        city: addressResponse.billingAddress[0].cCity,
        state: addressResponse.billingAddress[0].cState,
        zipCode: addressResponse.billingAddress[0].cPCode,
      });
    }
    if (type == "Home Address") {
      setAddressModalData({
        street: addressResponse.homeaddress[0].cAddr1,
        suite: addressResponse.homeaddress[0].cAddr2,
        city: addressResponse.homeaddress[0].cCity,
        state: addressResponse.homeaddress[0].cState,
        zipCode: addressResponse.homeaddress[0].cPCode,
      });
    }
    if (type == "Work Addres") {
      setAddressModalData({
        street: addressResponse.workaddress[0].cAddr1,
        suite: addressResponse.workaddress[0].cAddr2,
        city: addressResponse.workaddress[0].cCity,
        state: addressResponse.workaddress[0].cState,
        zipCode: addressResponse.workaddress[0].cPCode,
      });
    }
    if (type == "Vacation Address") {
      setAddressModalData({
        street: addressResponse.vacationholidayaddress[0].cAddr1,
        suite: addressResponse.vacationholidayaddress[0].cAddr2,
        city: addressResponse.vacationholidayaddress[0].cCity,
        state: addressResponse.vacationholidayaddress[0].cState,
        zipCode: addressResponse.vacationholidayaddress[0].cPCode,
      });
    }
    if (type == "Partner Address") {
      setAddressModalData({
        street: addressResponse.partneradress[0].cAddr1,
        suite: addressResponse.partneradress[0].cAddr2,
        city: addressResponse.partneradress[0].cCity,
        state: addressResponse.partneradress[0].cState,
        zipCode: addressResponse.partneradress[0].cPCode,
      });
    }
    if (type == "Office Address") {
      setAddressModalData({
        street: addressResponse.officeadress[0].cAddr1,
        suite: addressResponse.officeadress[0].cAddr2,
        city: addressResponse.officeadress[0].cCity,
        state: addressResponse.officeadress[0].cState,
        zipCode: addressResponse.officeadress[0].cPCode,
      });
    }

    setAddressModalVisible(true);
  };

  // Email Modal

  const handleGenderModalCancel = () => {
    setGenderModalVisible(false);
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // Current Interest

  const handlecurrentInteresetModalCancel = () => {
    setCurrentInteresetModalVisible(false);
    setCurrentInterestValidation1("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // Favourite Metal

  const handleFavoriteMetalModalCancel = () => {
    setFavoriteMetalModalVisible(false);
    setFavoriteMetalValidation1("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // BirthDay

  const handleBirthDayModalCancel = () => {
    setBirthDayModalVisible(false);
    setBirthDayValidation1("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // Anniversary

  const handleAnniversaryModalCancel = () => {
    setAnniversaryModalVisible(false);
    setAnniversaryValidation1("");
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // Finger Size

  const handleFingerSizeModalCancel = () => {
    setFingerSizeModalVisible(false);
    handleGetCustomerContactDetailsById(dealerId, customerID);
  };

  // Modal Handle Finger Size

  const handleFingerSizeModal = (type) => {
    setFingerType(type);
    setFingerSizeModalVisible(true);
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestDiamondVendorInputData({
      ...requestDiamondVendorInputData,
      [name]: e.target.value,
    });

    if (e.target.name == "personalMessage") {
      setRequestDiamondVendorInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
  };

  const handleSubmitRequestMoreInfo = () => {
    handleSaveRequestMoreInfo();
  };

  const handleValidationReuestInfo = () => {
    const { personalMessage } = requestDiamondVendorInputData;

    const requestDiamondVendorInputDataValidation = {
      personalMessageval: "",
    };
    let isValid = true;

    if (!personalMessage.trim()) {
      isValid = false;
      requestDiamondVendorInputDataValidation.personalMessageval = t(
        "Please write your message"
      );
    } else {
      requestDiamondVendorInputDataValidation.personalMessageval = "";
    }
    setRequestDiamondVendorInputDataValidation(
      requestDiamondVendorInputDataValidation
    );
    return isValid;
  };

  const handleSaveRequestMoreInfo = async () => {
    var isValid = handleValidationReuestInfo();

    if (!isValid) {
      setContactVendorModel(true);
      return false;
    }

    let inputData = {
      dealerId: dealerId.toString(),
      inventoryId: inventoryID.toString(),
      content: requestDiamondVendorInputData.personalMessage,
    };
    setButtonLoading(true);
    try {
      AdminDashboardDataService.InsertConversionDiamondMail(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(t("Mail send Successfully"));
            setButtonLoading(false);
            setContactVendorModel(false);
            setRequestDiamondVendorInputData({
              personalMessage: "",
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(t("Could Not Add Record"));
          setButtonLoading(false);
          setContactVendorModel(false);
          setRequestDiamondVendorInputData({
            personalMessage: "",
          });
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setButtonLoading(false);
      setContactVendorModel(false);
      setRequestDiamondVendorInputData({
        personalMessage: "",
      });
      console.log(error);
    }
  };

  // Insert Details Start API

  const handleGetCurrentInterestList = async () => {
    try {
      AdminDashboardDataService.GetCurrentInterestList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setCurrentInterestList(responseData);
          } else {
            setCurrentInterestList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFavoriteMetalList = async () => {
    try {
      AdminDashboardDataService.GetFavoriteMetalList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setMetalList(responseData);
          } else {
            setMetalList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllModalVisible = () => {
    setPhoneModalVisible(false);
    setEmailModalVisible(false);
    setGenderModalVisible(false);
    setBirthDayModalVisible(false);
    setAnniversaryModalVisible(false);
    setCurrentInteresetModalVisible(false);
    setFavoriteMetalModalVisible(false);
    setFingerSizeModalVisible(false);
  };

  const handlePhoneNumberValidation = () => {
    let isValid = true;

    if (!formDetails.phoneNumber.trim()) {
      isValid = false;
      setPhoneNumberVal(t("Phone Number is Compulsory."));
    } else if (
      (formDetails.phoneNumber.length < 15 &&
        formDetails.phoneNumber.includes("-") &&
        /^[0-9]+$/.test(formDetails.phoneNumber)) ||
      (formDetails.phoneNumber.length < 15 &&
        formDetails.phoneNumber.includes("+") &&
        /^[0-9]+$/.test(formDetails.phoneNumber))
    ) {
      setPhoneNumberVal("");
    } else if (
      /^0*$/.test(formDetails.phoneNumber) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(formDetails.phoneNumber)
    ) {
      isValid = false;
      setPhoneNumberVal(t("Phone number is invalid"));
    } else if (
      formDetails.phoneNumber.length > 15 ||
      formDetails.phoneNumber.length < 10
    ) {
      isValid = false;
      setPhoneNumberVal(t("Phone number is invalid"));
    } else {
      setPhoneNumberVal("");
    }
    return isValid;
  };

  const handleBirthDayValidation = () => {
    let isValid = true;

    if (!formDetails.birthdaydate.trim()) {
      isValid = false;
      setBirthDayValidation(t("BirthDay Date is Compulsory."));
    } else {
      setBirthDayValidation("");
    }
    return isValid;
  };

  const handleAnniversaryValidation = () => {
    let isValid = true;

    if (!formDetails.anniversarydate.trim()) {
      isValid = false;
      setAnniversaryValidation(t("Anniversary Date is Compulsory."));
    } else {
      setAnniversaryValidation("");
    }
    return isValid;
  };

  const handleFavoriteMetalValidation = () => {
    let isValid = true;

    if (formDetails.favoritemetailid == "") {
      isValid = false;
      setFavoriteMetalValidation(t("Favorite Metal Field is Compulsory."));
    } else {
      setFavoriteMetalValidation("");
    }
    return isValid;
  };

  const handleCurrentInterestValidation = () => {
    let isValid = true;

    if (formDetails.currentinterestid == "") {
      isValid = false;
      setCurrentInterestValidation(t("Current Interest Field is Compulsory."));
    } else {
      setCurrentInterestValidation("");
    }
    return isValid;
  };

  const handleInsertAddUserProfileList = (type, ringTypeName) => {
    if (type == "Phone") {
      var isValid = handlePhoneNumberValidation();

      if (!isValid) {
        return false;
      }
    }
    if (type == "Birthday") {
      var isValid = handleBirthDayValidation();

      if (!isValid) {
        return false;
      }
    }
    if (type == "Anniversary") {
      var isValid = handleAnniversaryValidation();

      if (!isValid) {
        return false;
      }
    }
    if (type == "FavoriteMetal") {
      var isValid = handleFavoriteMetalValidation();

      if (!isValid) {
        return false;
      }
    }
    if (type == "CurrentInterest") {
      var isValid = handleCurrentInterestValidation();

      if (!isValid) {
        return false;
      }
    }
    setAddLoading(true);
    let inputData = {
      type: type ? type.toString() : "",
      custId: customerID ? customerID.toString() : "",
      retailerId: dealerId ? dealerId.toString() : "",
      phoneId: "",
      phoneNumber: formDetails.phoneNumber
        ? formDetails.phoneNumber.toString()
        : "",
      phoneTypeId: formDetails.phoneTypeId
        ? formDetails.phoneTypeId.toString()
        : "",
      email: "",
      gender: formDetails.gender ? formDetails.gender.toString() : "",
      birthdaydate: formDetails.birthdaydate
        ? formDetails.birthdaydate.toString()
        : "",
      anniversarydate: formDetails.anniversarydate
        ? formDetails.anniversarydate.toString()
        : "",
      currentinterestid: formDetails.currentinterestid
        ? formDetails.currentinterestid.toString()
        : "",
      favoritemetailid: formDetails.favoritemetailid
        ? formDetails.favoritemetailid.toString()
        : "",
      ringSizeid:
        rightSideData.cSizing_id !== 0
          ? rightSideData.cSizing_id.toString()
          : "0",
      ringTypeName: ringTypeName ? ringTypeName.toString() : "",
      ringValue: formDetails.ringValue ? formDetails.ringValue.toString() : "",
    };
    try {
      AdminDashboardDataService.InsertAddUserProfileList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setAddLoading(false);
            NotificationManager.success(t("Data Added Successfully."));
            handelCancelButtonForAll();
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setAddLoading(false);
            NotificationManager.error(t("Data Not Added."));
            handelCancelButtonForAll();
            handleGetCustomerContactDetailsById(dealerId, customerID);
          }
        })
        .catch((error) => {
          console.log(error);
          setAddLoading(false);
          handelCancelButtonForAll();
        });
    } catch (error) {
      console.log(error);
      setAddLoading(false);
      handelCancelButtonForAll();
    }
  };

  const openDiamondVendorPopup = (id) => {
    setContactVendorModel(true);
    setInventoryID(id.diamondinventoryId);
    setVendorNameForPopup(id?.dealerCompany);
  };

  const handleUpdateAddUserProfileList = (type, ringTypeName) => {
    if (type == "Email") {
      var isValid = handleEmailValidation();

      if (!isValid) {
        return false;
      }
    } else if (type == "Phone") {
      var isValid = handlePhoneValidation();

      if (!isValid) {
        return false;
      }
    } else if (type == "Anniversary") {
      var isValid = handleAnniversaryValidation1();

      if (!isValid) {
        return false;
      }
    } else if (type == "Birthday") {
      var isValid = handleBirthDayValidation1();

      if (!isValid) {
        return false;
      }
    } else if (type == "FavoriteMetal") {
      var isValid = handleFavoriteMetalValidation1();

      if (!isValid) {
        return false;
      }
    } else if (type == "CurrentInterest") {
      var isValid = handleCurrentInterestValidation1();

      if (!isValid) {
        return false;
      }
    }

    let inputData = {
      type: type ? type.toString() : "",
      custId: customerID ? customerID.toString() : "",
      retailerId: dealerId ? dealerId.toString() : "",
      phoneId: rightSideDataDummy.phoneId
        ? rightSideDataDummy.phoneId.toString()
        : "",
      phoneNumber: rightSideDataDummy.phoneNumber
        ? rightSideDataDummy.phoneNumber.toString()
        : "",
      phoneTypeId: rightSideDataDummy.phoneType
        ? rightSideDataDummy.phoneType.toString()
        : "",
      email: rightSideDataDummy.cEmail
        ? rightSideDataDummy.cEmail.toString()
        : "",
      gender: rightSideDataDummy.cSex ? rightSideDataDummy.cSex.toString() : "",
      birthdaydate: rightSideDataDummy.birthdayDate
        ? rightSideDataDummy.birthdayDate.toString()
        : "",
      anniversarydate: rightSideDataDummy.anniversaryDate
        ? rightSideDataDummy.anniversaryDate.toString()
        : "",
      currentinterestid: rightSideDataDummy.currentInterestId
        ? rightSideDataDummy.currentInterestId.toString()
        : "",
      favoritemetailid: rightSideDataDummy.favoriteMetalId
        ? rightSideDataDummy.favoriteMetalId.toString()
        : "",
      ringSizeid: rightSideDataDummy.cSizing_id
        ? rightSideDataDummy.cSizing_id.toString()
        : "0",
      ringTypeName: ringTypeName ? ringTypeName.toString() : "",
      ringValue:
        ringTypeName == "cSizeLI"
          ? rightSideDataDummy.cSizeLI.toString()
          : ringTypeName == "cSizeLM"
          ? rightSideDataDummy.cSizeLM.toString()
          : ringTypeName == "cSizeLT"
          ? rightSideDataDummy.cSizeLT.toString()
          : ringTypeName == "cSizeRI"
          ? rightSideDataDummy.cSizeRI.toString()
          : ringTypeName == "cSizeRM"
          ? rightSideDataDummy.cSizeRM.toString()
          : ringTypeName == "cSizeRT"
          ? rightSideDataDummy.cSizeRT.toString()
          : ringTypeName == "cSizeLL"
          ? rightSideDataDummy.cSizeLL.toString()
          : ringTypeName == "cSizeLR"
          ? rightSideDataDummy.cSizeLR.toString()
          : ringTypeName == "cSizeRL"
          ? rightSideDataDummy.cSizeRL.toString()
          : ringTypeName == "cSizeRR"
          ? rightSideDataDummy.cSizeRR.toString()
          : "",
    };
    setUpdateProfileLoading(true);
    try {
      AdminDashboardDataService.InsertAddUserProfileList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setUpdateProfileLoading(false);
            handleAllModalVisible(false);
            NotificationManager.success(t("Updated Successfully."));
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setUpdateProfileLoading(false);
            handleAllModalVisible(false);
            NotificationManager.error(t("Not Updated."));
            handleGetCustomerContactDetailsById(dealerId, customerID);
          }
        })
        .catch((error) => {
          console.log(error);
          setUpdateProfileLoading(false);
          handleAllModalVisible(false);
          handelCancelButtonForAll();
        });
    } catch (error) {
      console.log(error);
      setUpdateProfileLoading(false);
      handleAllModalVisible(false);
      handelCancelButtonForAll();
    }
  };

  const handleFormDetailsOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFormDetails({
        ...formDetails,
        [name]: e,
      });
      if (name == "favoritemetailid") {
        setFavoriteMetalValidation("");
      }
      if (name == "currentinterestid") {
        setCurrentInterestValidation("");
      }
    } else {
      var rgx = /[\^!@#$%^&*()_?-]/g;
      if (name == "ringValue") {
        if (rgx.test(e.target.value)) {
          e.preventDefault();
        } else {
          setFormDetails({
            ...formDetails,
            [e.target.name]: e.target.value,
          });
        }
      } else {
        setFormDetails({
          ...formDetails,
          [e.target.name]: e.target.value,
        });
      }

      if (e.target.name == "phoneNumber") {
        setPhoneNumberVal("");
      }
      if (e.target.name == "birthdaydate") {
        setBirthDayValidation("");
      }
      if (e.target.name == "anniversarydate") {
        setAnniversaryValidation("");
      }
    }
  };

  // Address API

  const handleAddressOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddressData({
        ...addressData,
        [name]: e,
      });
    } else {
      setAddressData({
        ...addressData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleInsertMultipleAddUserProfileList = (addressTypeId) => {
    let inputData = {
      custId: customerID ? customerID : "",
      address1: addressData.street ? addressData.street.toString() : "",
      address2: addressData.suite ? addressData.suite.toString() : "",
      city: addressData.city ? addressData.city.toString() : "",
      state: addressData.state ? addressData.state.toString() : "",
      pincode: addressData.zipCode ? addressData.zipCode.toString() : "",
      addressId: 0,
      addressTypeId: addressTypeId ? addressTypeId : 0,
    };
    setAddressLoading(true);
    try {
      AdminDashboardDataService.InsertMultipleAddUserProfileList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setAddressLoading(false);
            handelCancelButtonForAll();
            NotificationManager.success(t("Data Added Successfully."));
            setAddressData({
              street: "",
              suite: "",
              city: "",
              state: "",
              zipCode: "",
            });
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setAddressLoading(false);
            NotificationManager.error(t("Data Not Added."));
            handelCancelButtonForAll();
          }
        })
        .catch((error) => {
          console.log(error);
          setAddressLoading(false);
        });
    } catch (error) {
      console.log(error);
      setAddressLoading(false);
    }
  };

  const handleUpdateMultipleAddUserProfileList = (addressTypeId, addressId) => {
    let inputData = {
      custId: customerID ? customerID : "",
      address1: addressModalData.street
        ? addressModalData.street.toString()
        : "",
      address2: addressModalData.suite ? addressModalData.suite.toString() : "",
      city: addressModalData.city ? addressModalData.city.toString() : "",
      state: addressModalData.state ? addressModalData.state.toString() : "",
      pincode: addressModalData.zipCode
        ? addressModalData.zipCode.toString()
        : "",
      addressId: addressId ? addressId : 0,
      addressTypeId: addressTypeId ? addressTypeId : 0,
    };
    setAddressModalLoading(true);
    try {
      AdminDashboardDataService.InsertMultipleAddUserProfileList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setAddressModalLoading(false);
            handleaddressModalCancel();
            NotificationManager.success(t("Updated Successfully."));
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            setAddressModalLoading(false);
            handleaddressModalCancel();
            NotificationManager.error(t("Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setAddressModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setAddressModalLoading(false);
    }
  };

  // Modal Data OnChange
  const handleAddressDataOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddressModalData({
        ...addressModalData,
        [name]: e,
      });
    } else {
      setAddressModalData({
        ...addressModalData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // RightSide Switch

  const handleOnSwitchChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRightSideData({
        ...rightSideData,
        [name]: e,
      });

      if (name == "verified") {
        handleUpdateUserSwitchDetails(e);
      }
    } else {
      setRightSideData({
        ...rightSideData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdateUserSwitchDetails = (e) => {
    let inputData = {
      cust_id: customerID ? customerID : 0,
      switchValue: e,
    };

    try {
      AdminDashboardDataService.UpdateUserSwitchDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            NotificationManager.success(t("Verified Successfully."));
            handleGetCustomerContactDetailsById(dealerId, customerID);
          } else {
            NotificationManager.error(t("Not Verified."));
            handleGetCustomerContactDetailsById(dealerId, customerID);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Image Upload

  const fileUpload = async (e) => {
    var file = e.target.files[0];
    if (file.name.match(/.(png|jpg|jpeg)$/i)) {
      if (file) {
        setImageData({
          ...imageData,
          Imagefile: file,
          ImagefileName: file.name,
        });
        setImageValidation((prevdata) => ({
          ...prevdata,
          ImageVal: "",
        }));
      }
    } else {
      setImageData({
        ...imageData,
        Imagefile: null,
        ImagefileName: null,
      });
      setImageValidation((prevdata) => ({
        ...prevdata,
        ImageVal: "Please Select Image",
      }));
    }
  };

  const handleCancelImage = () => {
    setImageData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null,
    }));

    setImageData((prevState) => ({
      ...prevState,
      ["Imagefile"]: null,
    }));
  };

  const handleAddUserImage = () => {
    setImageUpdateLoading(true);
    try {
      const formData = new FormData();

      formData.append("file", imageData.Imagefile ? imageData.Imagefile : null);
      formData.append("Cust_id", customerID ? customerID : 0);
      formData.append(
        "FileNameExist",
        rightSideData.avatarValue ? rightSideData.avatarValue : ""
      );

      AdminDashboardDataService.AddUserImage(formData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setImageUpdateLoading(false);
            NotificationManager.success(
              t("Profile Image Updated Successfully.")
            );
            handleGetCustomerContactDetailsById(dealerId, customerID);
            setImageData({
              ImagefileName: null,
              Imagefile: null,
              showsImagePath: null,
            });
          } else {
            setImageUpdateLoading(false);
            NotificationManager.error(t("Profile Image not Updated."));
            setImageData({
              ImagefileName: null,
              Imagefile: null,
              showsImagePath: null,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setImageUpdateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setImageUpdateLoading(false);
    }
  };

  // Left Side Menu

  const handleGetLeftSideMenuDetails = (id) => {
    let inputData = {
      dealerID: id ? id.toString() : "",
    };
    setLeftSideLoading(true);
    try {
      AdminDashboardDataService.GetLeftSideMenuDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setLeftSideData(responseData);
            setLeftSideLoading(false);
          } else {
            setLeftSideData({});
            setLeftSideLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLeftSideLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLeftSideLoading(false);
    }
  };

  const handleRightSideTabs = (tabs) => {
    setTabs(tabs);
  };

  const handleRedirectionSideMenu = (type, id) => {
    if (type == "Conversation") {
      // dispatch(setChecklinkHeaderMenuData("Retailers"));
      navigate("/profileNew", {
        state: {
          key: "Conversation",
          id: id ? id : rightSideDetails.cust_id,
        },
      });
    } else if (type == "Profile") {
      // dispatch(setChecklinkHeaderMenuData("Retailers"));
      setSelectedTab(type);
      setCustomerID(id);
      setRightSideDetails({
        cust_id: id,
      });
      handleGetCustomerContactDetailsById(dealerId, id);
      handleGetCurrentInterestList();
      handleGetFavoriteMetalList();
      handleGetLeftSideMenuDetails(dealerId);
      handleGetCustomerCount(dealerId);
    }
  };

  const closeVendordiamondModal = () => {
    setContactVendorModel(false);
    setRequestDiamondVendorInputData({
      personalMessage: "",
    });
    setRequestDiamondVendorInputDataValidation({
      personalMessageval: "",
    });
  };
  const handleCountClick = () => {
    navigate("/contact");
    dispatch(setChecklinkHeaderMenuData("Contacts"));
    dispatch(setContactPageNameType("Customer"));
  };

  const handleOnAppointmentClick = () => {
    navigate("/contact", {
      state: {
        key: "AppointmentsOnly",
      },
    });
    dispatch(setContactPageNameType("AppointmentsOnly"));
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion retailerprof__section permission__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {t("Customer Profile")}
                  </h4>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="Profile" title={t("Profile")}>
                        <Spin spinning={profileLoading}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input__block mb-0">
                                <div class="image_block p-0 border-0">
                                  {imageData.Imagefile ? (
                                    <div className="divimage_block">
                                      {imageData.Imagefile ? (
                                        <img
                                          src={URL.createObjectURL(
                                            imageData.Imagefile
                                          )}
                                          width="120"
                                          height="150"
                                        />
                                      ) : null}
                                      <span
                                        className="error-color-red cross__icon__customer"
                                        onClick={handleCancelImage}
                                      >
                                        x
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="divimage_block">
                                      <label
                                        htmlFor="file-upload"
                                        class="primary-btn choose_file_upload"
                                      >
                                        {t("Choose Image")}
                                      </label>
                                      <input
                                        id="file-upload"
                                        type="file"
                                        onChange={(e) => fileUpload(e)}
                                      />

                                      {imageValidation.ImageVal && (
                                        <p className="error-color-red">
                                          {imageValidation.ImageVal}
                                        </p>
                                      )}
                                      <span class="mb-4">
                                        {" "}
                                        {t("No File Choosen")}{" "}
                                      </span>

                                      <span class="">
                                        {" "}
                                        {/* {t("JPG Format")}{" "} */}
                                        {t(".JPG | .JPEG | .PNG Format")}
                                      </span>
                                    </div>
                                  )}
                                  {/* <div className="divimage_block">
                                                                        <label for="file-upload" class="primary-btn choose_file_upload">
                                                                            Choose Image
                                                                    </label>
                                                                        <input id="file-upload" type="file" onChange={(e) => fileUpload(e)} />
                                                                        <span className="mb-4">No File Choosen</span>

                                                                    </div> */}

                                  {/* <div className="preview__img choose_file_divimg d-none">
                                                                        <img src={userIcon} className="img-fluid" alt="img" />
                                                                        <span style={{ right: "10px", top: "10px" }}>
                                                                            x
                                                                    </span>
                                                                    </div> */}
                                </div>
                                {imageData.Imagefile != null && (
                                  <Button
                                    loading={imageUpdateLoading}
                                    className="primary-btn mt-3"
                                    onClick={handleAddUserImage}
                                  >
                                    {t("Update")}
                                  </Button>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input__block">
                                <p className="font__bold mb-1">
                                  {rightSideData.fullName}
                                </p>
                                {/* <p className="linkText mb-1">{rightSideData.cEmail}</p> */}
                                <a href={`mailto:${rightSideData.cEmail}`}>
                                  {rightSideData.cEmail}
                                </a>
                                <p className="mb-1">
                                  {rightSideData.phoneNumber}
                                </p>
                              </div>
                              {/* <Button 
                                                                className={imageData.Imagefile == null ? "primary-btn mt-3 disabled-btn" : "primary-btn mt-3"}
                                                                disabled={imageData.Imagefile == null ? true : false}
                                                                // onClick={handleAddUserImage}
                                                            >Update</Button> */}
                            </div>
                            <div className="col-md-2">
                              <Button
                                className="primary-btn"
                                onClick={handleAddDetailsCheck}
                              >
                                {t("Add Details")}
                              </Button>
                            </div>
                            <div className="col-md-12">
                              {addDetailsCount == 1 && <hr />}
                            </div>
                          </div>
                        </Spin>
                        <div className="row add__details">
                          {addDetailsCount == 1 && (
                            <div className="col-md-4 add__left">
                              <div className="input__block mb-0">
                                <Select
                                  className="border__grey"
                                  placeholder={t("--Select--")}
                                  name="details"
                                  optionFilterProp="children"
                                  onChange={(e) => handleDetailsChange(e)}
                                >
                                  <Option value="">{t("--Select--")}</Option>
                                  {rightSideData.cEmail == "" && (
                                    <Option value="Email">{t("Email")}</Option>
                                  )}
                                  {rightSideData.cBDayD == 0 && (
                                    <Option value="Birthday">
                                      {t("Birthday")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.phoneNumber == "" && (
                                    <Option value="Phone">{t("Phone")} </Option>
                                  )}
                                  {rightSideData.favoriteMetal == "" && (
                                    <Option value="Favorite Metal">
                                      {t("Favorite Metal")}
                                    </Option>
                                  )}
                                  {rightSideData.currentIntrest == "" && (
                                    <Option value="Current Interest">
                                      {t("Current Interest")}
                                    </Option>
                                  )}
                                  {rightSideData.cAnniDay == 0 && (
                                    <Option value="Anniversary">
                                      {t("Anniversary")}
                                    </Option>
                                  )}
                                  {rightSideData.cSex == 0 && (
                                    <Option value="Gender">
                                      {" "}
                                      {t("Gender")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeLI == "" && (
                                    <Option value="LH Index Finger">
                                      {t("LH Index Finger")}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeLM == "" && (
                                    <Option value="LH Middle Finger">
                                      {t("LH Middle Finger")}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeLT == "" && (
                                    <Option value="LH Thumb">
                                      {t("LH Thumb")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeRI == "" && (
                                    <Option value="RH Index Finger">
                                      {t("RH Index Finger")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeRM == "" && (
                                    <Option value="RH Middle Finger">
                                      {t("RH Middle Finger")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeRT == "" && (
                                    <Option value="Ring Hand Thumb Size">
                                      {t("Ring Hand Thumb Size")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeLL == "" && (
                                    <Option value="Left Hand Pinky">
                                      {t("Left Hand Pinky")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeLR == "" && (
                                    <Option value="Left Hand Pointer">
                                      {t("Left Hand Pointer")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeRL == "" && (
                                    <Option value="Right Hand Pinky">
                                      {t("Right Hand Pinky")}{" "}
                                    </Option>
                                  )}
                                  {rightSideData.cSizeRR == "" && (
                                    <Option value="Right Hand Pointer">
                                      {t("Right Hand Pointer")}{" "}
                                    </Option>
                                  )}
                                  {addressResponse.homeaddress
                                    ? addressResponse.homeaddress.length ==
                                        0 && (
                                        <Option value="Home Address">
                                          {t("Home Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                  {addressResponse.billingAddress
                                    ? addressResponse.billingAddress.length ==
                                        0 && (
                                        <Option value="Billing Address">
                                          {t("Billing Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                  {addressResponse.workaddress
                                    ? addressResponse.workaddress.length ==
                                        0 && (
                                        <Option value="Work Address">
                                          {t("Work Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                  {addressResponse.vacationholidayaddress
                                    ? addressResponse.vacationholidayaddress
                                        .length == 0 && (
                                        <Option value="Vacation / Holiday Address">
                                          {t("Vacation / Holiday Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                  {addressResponse.partneradress
                                    ? addressResponse.partneradress.length ==
                                        0 && (
                                        <Option value="Partner Address">
                                          {t("Partner Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                  {addressResponse.officeadress
                                    ? addressResponse.officeadress.length ==
                                        0 && (
                                        <Option value="Office Address">
                                          {t("Office Address")}{" "}
                                        </Option>
                                      )
                                    : null}
                                </Select>
                              </div>
                            </div>
                          )}

                          <div className="col-md-8 add__right">
                            {details == "Phone" && (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block mb-0">
                                    <Select
                                      className="border__grey"
                                      placeholder={t("Select Details")}
                                      name="phoneTypeId"
                                      optionFilterProp="children"
                                      value={formDetails.phoneTypeId}
                                      onChange={(e) =>
                                        handleFormDetailsOnChange(
                                          e,
                                          "select",
                                          "phoneTypeId"
                                        )
                                      }
                                    >
                                      <Option value="0"> {("Home")} </Option>
                                      <Option value="1"> {("Mobile")} </Option>
                                      <Option value="2"> {("Office")} </Option>
                                      <Option value="3"> {("Fax")} </Option>
                                    </Select>
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      placeholder={t("Phone Number")}
                                      name="phoneNumber"
                                      value={formDetails.phoneNumber}
                                      onChange={handleFormDetailsOnChange}
                                      className={
                                        phoneNumberVal && "border__red"
                                      }
                                    />
                                    <div>
                                      {phoneNumberVal && (
                                        <p className="error-color-red">
                                          {phoneNumberVal}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <Button
                                    className="primary-btn mr-2 mobile__topspace"
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList("Phone");
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn mobile__topspace"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            )}

                            {details == "Birthday" ||
                            details == "Anniversary" ? (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block">
                                    {/* <input 
                                                                            type="text" 
                                                                            value={formDetails.}
                                                                            placeholder="Enter Birth date" /> */}
                                    <input
                                      type="date"
                                      name={
                                        details == "Birthday"
                                          ? "birthdaydate"
                                          : details == "Anniversary"
                                          ? "anniversarydate"
                                          : ""
                                      }
                                      value={
                                        details == "Birthday"
                                          ? formDetails.birthdaydate
                                          : details == "Anniversary"
                                          ? formDetails.anniversarydate
                                          : ""
                                      }
                                      onChange={handleFormDetailsOnChange}
                                      className={
                                        details == "Birthday"
                                          ? birthDayValidation && "border__red"
                                          : details == "Anniversary"
                                          ? anniversaryValidation &&
                                            "border__red"
                                          : ""
                                      }
                                    />

                                    {/* <DatePicker 
                                    name={
                                        details == "Birthday"
                                          ? "birthdaydate"
                                          : details == "Anniversary"
                                          ? "anniversarydate"
                                          : ""
                                      }
                                      value={
                                        details == "Birthday"
                                          ? formDetails.birthdaydate
                                          : details == "Anniversary"
                                          ? formDetails.anniversarydate
                                          : ""
                                      }
                                      onChange={(e)=>handleFormDetailsOnChange(e,"",details == "Birthday"
                                      ? "birthdaydate"
                                      : details == "Anniversary"
                                      ? "anniversarydate"
                                      : "")}
                                      className={
                                        details == "Birthday"
                                          ? birthDayValidation && "border__red"
                                          : details == "Anniversary"
                                          ? anniversaryValidation &&
                                            "border__red"
                                          : ""
                                      } /> */}

                                    <div>
                                      {details == "Birthday"
                                        ? birthDayValidation && (
                                            <p className="error-color-red">
                                              {birthDayValidation}
                                            </p>
                                          )
                                        : details == "Anniversary"
                                        ? anniversaryValidation && (
                                            <p className="error-color-red">
                                              {anniversaryValidation}
                                            </p>
                                          )
                                        : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Button
                                    className="primary-btn mr-2 mobile__topspace"
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList(
                                        details == "Birthday"
                                          ? "Birthday"
                                          : details == "Anniversary"
                                          ? "Anniversary"
                                          : ""
                                      );
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            ) : null}

                            {details == "Gender" && (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <Select
                                      className="border__grey"
                                      placeholder={t("Select Gender")}
                                      name="gender"
                                      optionFilterProp="children"
                                      value={formDetails.gender}
                                      onChange={(e) =>
                                        handleFormDetailsOnChange(
                                          e,
                                          "select",
                                          "gender"
                                        )
                                      }
                                    >
                                      <Option value="1"> Female </Option>
                                      <Option value="2"> Male </Option>
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Button
                                    className="primary-btn mr-2 mobile__topspace"
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList("Gender");
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            )}

                            {details == "Current Interest" && (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <Select
                                      // className="border__grey"
                                      placeholder={t("Select Gender")}
                                      name="currentinterestid"
                                      optionFilterProp="children"
                                      value={formDetails.currentinterestid}
                                      onChange={(e) =>
                                        handleFormDetailsOnChange(
                                          e,
                                          "select",
                                          "currentinterestid"
                                        )
                                      }
                                      className={
                                        currentInterestValidation
                                          ? "border__red"
                                          : "border__grey"
                                      }
                                    >
                                      <Option value="">{t("--Select--")}</Option>
                                      {currentInterestList &&
                                        currentInterestList.map((item, i) => {
                                          return (
                                            <Option
                                              value={item.jewelryCategoryID}
                                            >
                                              {item.jewelryCategory}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                    <div>
                                      {currentInterestValidation && (
                                        <p className="error-color-red">
                                          {currentInterestValidation}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Button
                                    className="primary-btn mr-2 mobile__topspace"
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList(
                                        "CurrentInterest"
                                      );
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn mobile__topspace"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            )}

                            {details == "Favorite Metal" && (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block mb-0">
                                    <Select
                                      // className="border__grey"
                                      placeholder={t("Select Gender")}
                                      name="favoritemetailid"
                                      optionFilterProp="children"
                                      value={formDetails.favoritemetailid}
                                      onChange={(e) =>
                                        handleFormDetailsOnChange(
                                          e,
                                          "select",
                                          "favoritemetailid"
                                        )
                                      }
                                      className={
                                        favoriteMetalValidation
                                          ? "border__red"
                                          : "border__grey"
                                      }
                                    >
                                      <Option value="">{t("--Select--")}</Option>
                                      {metalList &&
                                        metalList.map((item, i) => {
                                          return (
                                            <Option value={item.metalTypeID}>
                                              {item.metalType}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                    <div>
                                      {favoriteMetalValidation && (
                                        <p className="error-color-red">
                                          {favoriteMetalValidation}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Button
                                    className="primary-btn mr-2 mobile__topspace"
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList(
                                        "FavoriteMetal"
                                      );
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn mobile__topspace"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            )}

                            {details == "LH Index Finger" ||
                            details == "LH Middle Finger" ||
                            details == "LH Thumb" ||
                            details == "RH Index Finger" ||
                            details == "RH Middle Finger" ||
                            details == "Ring Hand Thumb Size" ||
                            details == "Left Hand Pinky" ||
                            details == "Left Hand Pointer" ||
                            details == "Right Hand Pinky" ||
                            details == "Right Hand Pointer" ? (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      maxlength="2"
                                      placeholder={t("Enter Finger Details")}
                                      value={formDetails.ringValue}
                                      name="ringValue"
                                      onChange={(e) =>
                                        handleFormDetailsOnChange(
                                          e,
                                          "",
                                          "ringValue"
                                        )
                                      }
                                      onKeyPress={onKeyPressEvent}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Button
                                    disabled={
                                      formDetails.ringValue == "" ? true : false
                                    }
                                    // className="primary-btn mr-2"
                                    className={
                                      formDetails.ringValue == ""
                                        ? "primary-btn mr-2 disabled-btn mobile__topspace"
                                        : "primary-btn mr-2 mobile__topspace"
                                    }
                                    loading={addLoading}
                                    onClick={() => {
                                      handleInsertAddUserProfileList(
                                        "LHIndexFinger",
                                        details == "LH Index Finger"
                                          ? "cSizeLI"
                                          : details == "LH Middle Finger"
                                          ? "cSizeLM"
                                          : details == "LH Thumb"
                                          ? "cSizeLT"
                                          : details == "RH Index Finger"
                                          ? "cSizeRI"
                                          : details == "RH Middle Finger"
                                          ? "cSizeRM"
                                          : details == "Ring Hand Thumb Size"
                                          ? "cSizeRT"
                                          : details == "Left Hand Pinky"
                                          ? "cSizeLL"
                                          : details == "Left Hand Pointer"
                                          ? "cSizeLR"
                                          : details == "Right Hand Pinky"
                                          ? "cSizeRL"
                                          : details == "Right Hand Pointer"
                                          ? "cSizeRR"
                                          : ""
                                      );
                                    }}
                                  >
                                    {t("Update")}
                                  </Button>
                                  <Button
                                    className="primary-btn mobile__topspace"
                                    onClick={handelCancelButtonForAll}
                                  >
                                    {t("Cancel")}
                                  </Button>
                                </div>
                              </div>
                            ) : null}

                            {/* address block */}
                            {details == "Home Address" ||
                            details == "Billing Address" ||
                            details == "Work Address" ||
                            details == "Vacation / Holiday Address" ||
                            details == "Partner Address" ||
                            details == "Office Address" ? (
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input__block mb-3">
                                    <label>{t("Street")}</label>
                                    <input
                                      type="text"
                                      name="street"
                                      value={addressData.street}
                                      onChange={handleAddressOnChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="input__block">
                                    <label>{t("Suite")}</label>
                                    <input
                                      type="text"
                                      name="suite"
                                      value={addressData.suite}
                                      onChange={handleAddressOnChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="input__block">
                                    <label>{t("City")}</label>
                                    <input
                                      type="text"
                                      name="city"
                                      value={addressData.city}
                                      onChange={handleAddressOnChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="input__block">
                                    <label>{t("State")}</label>
                                    <input
                                      type="text"
                                      name="state"
                                      value={addressData.state}
                                      onChange={handleAddressOnChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="input__block">
                                    <label>{t("Zip Code")}</label>
                                    <input
                                      type="text"
                                      name="zipCode"
                                      value={addressData.zipCode}
                                      onChange={handleAddressOnChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input__block mb-3">
                                    <Button
                                      className="primary-btn mr-2 mobile__topspace"
                                      loading={addressLoading}
                                      onClick={() =>
                                        handleInsertMultipleAddUserProfileList(
                                          details == "Home Address"
                                            ? 0
                                            : details == "Billing Address"
                                            ? 1
                                            : details == "Work Address"
                                            ? 2
                                            : details ==
                                              "Vacation / Holiday Address"
                                            ? 3
                                            : details == "Partner Address"
                                            ? 4
                                            : details == "Office Address"
                                            ? 5
                                            : ""
                                        )
                                      }
                                    >
                                      {t("Update")}
                                    </Button>
                                    <Button
                                      className="primary-btn mobile__topspace"
                                      onClick={handelCancelButtonForAll}
                                    >
                                      {t("Cancel")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <hr />
                          </div>
                        </div>

                        <div className="row">
                          <Spin spinning={profileLoading}>
                            <div className="col-md-12">
                              <div className="custom__table customer__profile">
                                <table className="table table-striped table-responsive">
                                  <tbody>
                                    {addressResponse.billingAddress
                                      ? addressResponse.billingAddress.length >
                                          0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t("Billing Address")}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse
                                                  .billingAddress[0]
                                                  ? addressResponse
                                                      .billingAddress[0].cAddr1
                                                  : ""}{" "}
                                                {addressResponse
                                                  .billingAddress[0]
                                                  ? addressResponse
                                                      .billingAddress[0].cAddr2
                                                  : ""}{" "}
                                                {addressResponse
                                                  .billingAddress[0]
                                                  ? addressResponse
                                                      .billingAddress[0].cCity
                                                  : ""}{" "}
                                                {addressResponse
                                                  .billingAddress[0]
                                                  ? addressResponse
                                                      .billingAddress[0].cState
                                                  : ""}{" "}
                                                {addressResponse
                                                  .billingAddress[0]
                                                  ? addressResponse
                                                      .billingAddress[0].cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Billing Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}
                                    {addressResponse.workaddress
                                      ? addressResponse.workaddress.length >
                                          0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t("Work Address")}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse.workaddress
                                                  .length !== 0
                                                  ? addressResponse
                                                      .workaddress[0].cAddr1
                                                  : ""}{" "}
                                                {addressResponse.workaddress
                                                  .length !== 0
                                                  ? addressResponse
                                                      .workaddress[0].cAddr2
                                                  : ""}{" "}
                                                {addressResponse.workaddress
                                                  .length !== 0
                                                  ? addressResponse
                                                      .workaddress[0].cCity
                                                  : ""}{" "}
                                                {addressResponse.workaddress
                                                  .length !== 0
                                                  ? addressResponse
                                                      .workaddress[0].cState
                                                  : ""}{" "}
                                                {addressResponse.workaddress
                                                  .length !== 0
                                                  ? addressResponse
                                                      .workaddress[0].cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Work Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}
                                    {addressResponse.homeaddress
                                      ? addressResponse.homeaddress.length >
                                          0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t("Home Address")}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse.homeaddress[0]
                                                  ? addressResponse
                                                      .homeaddress[0].cAddr1
                                                  : ""}{" "}
                                                {addressResponse.homeaddress[0]
                                                  ? addressResponse
                                                      .homeaddress[0].cAddr2
                                                  : ""}{" "}
                                                {addressResponse.homeaddress[0]
                                                  ? addressResponse
                                                      .homeaddress[0].cCity
                                                  : ""}{" "}
                                                {addressResponse.homeaddress[0]
                                                  ? addressResponse
                                                      .homeaddress[0].cState
                                                  : ""}{" "}
                                                {addressResponse.homeaddress[0]
                                                  ? addressResponse
                                                      .homeaddress[0].cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Home Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}

                                    {addressResponse.vacationholidayaddress
                                      ? addressResponse.vacationholidayaddress
                                          .length > 0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t(
                                                  "Vacation / Holiday Address"
                                                )}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse
                                                  .vacationholidayaddress[0]
                                                  ? addressResponse
                                                      .vacationholidayaddress[0]
                                                      .cAddr1
                                                  : ""}{" "}
                                                {addressResponse
                                                  .vacationholidayaddress[0]
                                                  ? addressResponse
                                                      .vacationholidayaddress[0]
                                                      .cAddr2
                                                  : ""}{" "}
                                                {addressResponse
                                                  .vacationholidayaddress[0]
                                                  ? addressResponse
                                                      .vacationholidayaddress[0]
                                                      .cCity
                                                  : ""}{" "}
                                                {addressResponse
                                                  .vacationholidayaddress[0]
                                                  ? addressResponse
                                                      .vacationholidayaddress[0]
                                                      .cState
                                                  : ""}{" "}
                                                {addressResponse
                                                  .vacationholidayaddress[0]
                                                  ? addressResponse
                                                      .vacationholidayaddress[0]
                                                      .cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Vacation Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}
                                    {addressResponse.partneradress
                                      ? addressResponse.partneradress.length >
                                          0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t("Partner Address")}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse
                                                  .partneradress[0]
                                                  ? addressResponse
                                                      .partneradress[0].cAddr1
                                                  : ""}{" "}
                                                {addressResponse
                                                  .partneradress[0]
                                                  ? addressResponse
                                                      .partneradress[0].cAddr2
                                                  : ""}{" "}
                                                {addressResponse
                                                  .partneradress[0]
                                                  ? addressResponse
                                                      .partneradress[0].cCity
                                                  : ""}{" "}
                                                {addressResponse
                                                  .partneradress[0]
                                                  ? addressResponse
                                                      .partneradress[0].cState
                                                  : ""}{" "}
                                                {addressResponse
                                                  .partneradress[0]
                                                  ? addressResponse
                                                      .partneradress[0].cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Partner Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}
                                    {addressResponse.officeadress
                                      ? addressResponse.officeadress.length >
                                          0 && (
                                          <tr>
                                            <td>
                                              <p>
                                                <LocationIcon />
                                                {t("Office Address")}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {addressResponse.officeadress[0]
                                                  ? addressResponse
                                                      .officeadress[0].cAddr1
                                                  : ""}{" "}
                                                {addressResponse.officeadress[0]
                                                  ? addressResponse
                                                      .officeadress[0].cAddr2
                                                  : ""}{" "}
                                                {addressResponse.officeadress[0]
                                                  ? addressResponse
                                                      .officeadress[0].cCity
                                                  : ""}{" "}
                                                {addressResponse.officeadress[0]
                                                  ? addressResponse
                                                      .officeadress[0].cState
                                                  : ""}{" "}
                                                {addressResponse.officeadress[0]
                                                  ? addressResponse
                                                      .officeadress[0].cPCode
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Edit_icon}
                                                    alt=""
                                                    // onClick={()=>setAddressModalVisible(true)}
                                                    onClick={() =>
                                                      handleAddressModal(
                                                        "Office Address"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      : null}
                                    {rightSideData.phoneNumber !== "" && (
                                      <tr>
                                        <td>
                                          <div className="input__block mb-0 d-flex flex-row align-items-center">
                                            <PhoneIcon />
                                            <Select
                                              className="border__grey"
                                              placeholder={t("--Select--")}
                                              name="phoneType"
                                              optionFilterProp="children"
                                              value={
                                                rightSideDataDummy.phoneType
                                                  ? rightSideDataDummy.phoneType
                                                  : ""
                                              }
                                              onChange={(e) =>
                                                handleRightSideChange(
                                                  e,
                                                  "select",
                                                  "phoneType"
                                                )
                                              }
                                            >
                                              <Option value="">
                                                {" "}
                                                {t("Select Option")}{" "}
                                              </Option>
                                              <Option value="0"> {t("Home")} </Option>
                                              <Option value="1">
                                                {" "}
                                                {t("Mobile")}{" "}
                                              </Option>
                                              <Option value="2">
                                                {" "}
                                                {t("Office")}{" "}
                                              </Option>
                                              <Option value="3"> {t("Fax")} </Option>
                                            </Select>
                                          </div>
                                        </td>
                                        <td>
                                          <p>{rightSideData.phoneNumber}</p>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block mt-2">
                                              <img
                                                src={Edit_icon}
                                                alt=""
                                                onClick={() =>
                                                  setPhoneModalVisible(true)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cEmail != "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <AtIcon />
                                            {t("E-Mail")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {rightSideData.cEmail}
                                          </p>
                                          <div className="d-none">
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="input__block">
                                                  <input type="text" />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <button className="primary-btn mr-2 mobile__topspace">
                                                  {t("Update")}
                                                </button>
                                                <button className="primary-btn mobile__topspace">
                                                  {t("Cancel")}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setEmailModalVisible(true)
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cBDayD !== 0 && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <CakeIcon />
                                            {t("BirthDay")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {birthDayDateMoment
                                              ? Moment(
                                                  birthDayDateMoment
                                                ).format("MMMM DD, YYYY ")
                                              : ""}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setBirthDayModalVisible(true)
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cAnniDay !== 0 && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <CakeIcon />
                                            {t("Anniversary")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {anniversaryDateMoment
                                              ? Moment(
                                                  anniversaryDateMoment
                                                ).format("MMMM DD, YYYY ")
                                              : ""}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setAnniversaryModalVisible(
                                                    true
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSex !== 0 && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <GenderIcon />
                                            {t("Gender")}
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            {rightSideData.cSex == 2
                                              ? "Male"
                                              : rightSideData.cSex == 1
                                              ? "Female"
                                              : ""}
                                          </p>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setGenderModalVisible(true)
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.currentIntrest !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <LikeIcon />
                                            {t("Current Interest")}
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            {rightSideData.currentIntrest
                                              ? rightSideData.currentIntrest
                                              : ""}
                                          </p>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setCurrentInteresetModalVisible(
                                                    true
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.favoriteMetal !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <MetalIcon />
                                            {t("Favourite Metal")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {rightSideData.favoriteMetal
                                              ? rightSideData.favoriteMetal
                                              : ""}
                                          </p>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  setFavoriteMetalModalVisible(
                                                    true
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeLI !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("LH Index Finger")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeLI}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                // onClick={() => setFingerSizeModalVisible(true)}
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeLI"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeLM !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("LH Middle Finger")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeLM}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeLM"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeLT !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("LH Thumb")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeLT}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeLT"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeRI !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("RH Index Finger")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeRI}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeRI"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeRM !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("RH Middle Finger")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeRM}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeRM"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeRT !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("Ring Hand Thumb Size")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeRT}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeRT"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeLL !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("Left Hand Pinky")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            Size {rightSideData.cSizeLL}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeLL"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeLR !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("Left Hand Pointer")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeLR}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeLR"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeRL !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("Right Hand Pinky")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeRL}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeRL"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {rightSideData.cSizeRR !== "" && (
                                      <tr>
                                        <td>
                                          <p className="mb-0">
                                            <IndexFinger />
                                            {t("Right Hand Pointer")}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {t("Size")} {rightSideData.cSizeRR}
                                          </p>
                                          <div className="d-none"></div>
                                        </td>
                                        <td>
                                          <div className="action__btns">
                                            <div className="image__block">
                                              <img
                                                onClick={() =>
                                                  handleFingerSizeModal(
                                                    "cSizeRR"
                                                  )
                                                }
                                                src={Edit_icon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Spin>
                        </div>
                      </Tab>
                      <Tab eventKey="Conversation" title={t("Conversation")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="editor__section">
                                  <div className="input__block">
                                    <textarea
                                      placeholder={t(
                                        "Your message to the vendor."
                                      )}
                                      name="addconversation"
                                      value={conversationData.addconversation}
                                      onChange={handleConversationDataChange}
                                      className={
                                        conversationValidations.conversationVal &&
                                        "border__red"
                                      }
                                    />
                                    <div>
                                      {conversationValidations.conversationVal && (
                                        <p className="error-color-red">
                                          {
                                            conversationValidations.conversationVal
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <div className="text-right">
                                  {/* <span className="linkText mr-2">Add Favorites</span> */}
                                  <Button
                                    className="primary-btn"
                                    onClick={handlePostMsg}
                                    loading={postMsgLoading}
                                  >
                                    {t("Post")}
                                  </Button>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <Spin spinning={conversationLoading}>
                                  {conversationList &&
                                    conversationList.map((item, i) => {
                                      return (
                                        <div className="notedesc__maindiv">
                                          <div className="date__headdiv">
                                            <div>
                                              <span>
                                                {item.cDateFormated} {"-"}
                                                <span> {item.senderName}</span>
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                onClick={() =>
                                                  handleDeleteModal(item.id)
                                                }
                                                className="linkText"
                                              >
                                                {t("Delete")}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="msg__headdiv">
                                            <span>{item.message}</span>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {/* Diamond details */}
                                  {diamondConversationList &&
                                    diamondConversationList.map((item, i) => {
                                      return (
                                        <div className="notedesc__maindiv">
                                          <div className="date__headdiv">
                                            <div>
                                              <span>
                                                {item.cDateFormated} {"-"}
                                                <span> {item.senderName}</span>
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                onClick={() =>
                                                  handleDeleteModal(item.id)
                                                }
                                                className="linkText"
                                              >
                                                {t("Delete")}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="msg__headdiv">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  <span>
                                                    <label>GemFind#:</label>{" "}
                                                    {item.diamondinventoryId}
                                                  </span>{" "}
                                                </div>

                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Wholesale Price")}:
                                                    </label>{" "}
                                                    {item.totalprice}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Color")}:</label>{" "}
                                                    {item.color}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Depth")}:</label>{" "}
                                                    {item.depth}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Carat Weight")}:
                                                    </label>{" "}
                                                    {item.size}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Symmetry")}:
                                                    </label>{" "}
                                                    {item.symmetry}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Measurements")}:
                                                    </label>{" "}
                                                    {item.measurements}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Shape")}:</label>{" "}
                                                    {item.cut}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Location")}:
                                                    </label>{" "}
                                                    {item.dealerAddress},
                                                    {item.dealerState},
                                                    {item.dealerCity},
                                                    {item.dealerZIP}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Table")}:</label>{" "}
                                                    {item.tableMeasure}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Clarity")}:
                                                    </label>{" "}
                                                    {item.clarity}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Polish")}:
                                                    </label>{" "}
                                                    {item.polish}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Crown")}:</label>{" "}
                                                    {item.crown}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Certificate")}:
                                                    </label>{" "}
                                                    {item.certificate}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  <span>
                                                    <label>
                                                      {t("Vendor")}:
                                                    </label>{" "}
                                                    {item.dealerCompany}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  <span>
                                                    <label>
                                                      {t("Vendor Stock")}:
                                                    </label>{" "}
                                                    {item.stock}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv overflow-y-hidden">
                                                  <span>
                                                    <label>{t("Phone")}:</label>{" "}
                                                    {item.dealerPhone}
                                                  </span>
                                                </div>{" "}
                                              </div>
                                              <div className="msg__headdiv overflow-y-hidden">
                                                {" "}
                                                <Button
                                                  className="primary-btn"
                                                  onClick={() =>
                                                    openDiamondVendorPopup(item)
                                                  }
                                                >
                                                  {t("Contact Vendor")}
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}

                                  {/* Jewelery Details */}

                                  {jewelryConversationList &&
                                    jewelryConversationList.map((item, i) => {
                                      return (
                                        <div className="notedesc__maindiv">
                                          <div className="date__headdiv">
                                            <div>
                                              <span>
                                                {item.cDateFormated} {"-"}
                                                <span> {item.senderName}</span>
                                              </span>
                                            </div>
                                            <div>
                                              <p
                                                onClick={() =>
                                                  handleDeleteModal(item.id)
                                                }
                                                className="linkText"
                                              >
                                                {t("Delete")}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="msg__headdiv">
                                            <div className="row">
                                              <div className="col-md-2">
                                                <div className="mt-3">
                                                  <img
                                                    src={item.imagePath}
                                                    height="150px"
                                                    width="150px"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-3">
                                                <div className="msg__headdiv">
                                                  <span>
                                                    <label>
                                                      {t("Inventory ID#")}:
                                                    </label>{" "}
                                                    {item.inventoryID}
                                                  </span>{" "}
                                                </div>

                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Product Name")}:
                                                    </label>{" "}
                                                    {item.productName}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Style")}:</label>{" "}
                                                    {item.style}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Metal Type")}:
                                                    </label>{" "}
                                                    {item.metalType}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Metal Color")}:
                                                    </label>{" "}
                                                    {item.metalColor}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Retail Price")}:
                                                    </label>{" "}
                                                    {item.retailPrice}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Wholesale Price")}:
                                                    </label>{" "}
                                                    {item.wholesalePrice}
                                                  </span>{" "}
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Category Name")}:
                                                    </label>{" "}
                                                    {item.category}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Description")}:
                                                    </label>{" "}
                                                    {item.productDesc}
                                                  </span>{" "}
                                                </div>
                                              </div>
                                              <div className="col-md-3">
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Vendor")}:
                                                    </label>{" "}
                                                    {item.vendorCompany}
                                                  </span>{" "}
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Vendor Stock")}:
                                                    </label>{" "}
                                                    {item.stockNumber}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>
                                                      {t("Collection")}:
                                                    </label>{" "}
                                                    {item.collection}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  {" "}
                                                  <span>
                                                    <label>{t("Phone")}:</label>{" "}
                                                    {item.dealerPhone}
                                                  </span>
                                                </div>
                                                <div className="msg__headdiv">
                                                  <span>
                                                    <label>
                                                      {t("Location")}:
                                                    </label>{" "}
                                                    {item.dealerAddress}
                                                  </span>
                                                </div>
                                              </div>

                                              <div className="msg__headdiv">
                                                {" "}
                                                <Button
                                                  className="primary-btn"
                                                  onClick={() =>
                                                    openDiamondVendorPopup(item)
                                                  }
                                                >
                                                  {t("Contact Vendor")}
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Spin>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <Modal
                  title={t("Add a Note")}
                  centered
                  visible={visibleNote}
                  onOk={handleInsertNoteMsgModal}
                  onCancel={handleModalClose}
                  maskClosable={false}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleModalClose}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleInsertNoteMsgModal}
                    >
                      {t("Submit")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input__block">
                                <input
                                  type="text"
                                  placeholder={t("Note Name")}
                                  name="noteName"
                                  value={newNotes.noteName}
                                  onChange={handleNoteChange1}
                                  className={
                                    notesValidations.noteNameVal &&
                                    "border__red"
                                  }
                                />
                                <div>
                                  {notesValidations.noteNameVal && (
                                    <p className="error-color-red">
                                      {notesValidations.noteNameVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input__block">
                                <textarea
                                  placeholder=""
                                  name="modalNote"
                                  value={newNotes.modalNote}
                                  onChange={handleNoteChange1}
                                  className={
                                    notesValidations.modalNoteVal &&
                                    "border__red"
                                  }
                                />
                                <div>
                                  {notesValidations.modalNoteVal && (
                                    <p className="error-color-red">
                                      {notesValidations.modalNoteVal}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* Edit Notes */}
                <Modal
                  title="Edit a Note"
                  centered
                  visible={visibleEditNote}
                  onOk={handleUpdateNotesAppointment}
                  onCancel={handleEditModalClose}
                  maskClosable={false}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleEditModalClose}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      loading={updateLoading}
                      key="submit"
                      type="primary"
                      onClick={handleUpdateNotesAppointment}
                    >
                      {t("Submit")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input__block">
                                <input
                                  type="text"
                                  placeholder={t("Note Name")}
                                  name="noteName"
                                  value={editNotes.noteName}
                                  onChange={handleEditNoteChange}
                                  // className={notesValidations.noteNameVal && "border__red"}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input__block">
                                <textarea
                                  placeholder=""
                                  name="modalNote"
                                  value={editNotes.modalNote}
                                  onChange={handleEditNoteChange}
                                  // className={notesValidations.modalNoteVal && "border__red"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <div className="col-lg-3 col-md-3">
                  <div className="right__section">
                    <div className="custom__tabs">
                      <span
                        onClick={() => handleRightSideTabs("Customer")}
                        className={
                          tabs == "Customer" ? "tabclicks active" : "tabclicks"
                        }
                      >
                        {t("Customer Info")}
                      </span>
                      <span
                        onClick={() => handleRightSideTabs("Other")}
                        className={
                          tabs == "Other" ? "tabclicks active" : "tabclicks"
                        }
                      >
                        {t("Other Info")}
                      </span>
                    </div>
                    {tabs == "Customer" && (
                      <div>
                        <Spin spinning={rightSideLoading}>
                          <div className="profile__block">
                            <a
                              onClick={() => handleRedirection("Profile")}
                              className="retailer__name"
                            >
                              {rightSideData.fullName
                                ? rightSideData.fullName
                                : ""}
                            </a>
                            <div className="logoImg__block p-0">
                              <img
                                src={
                                  rightSideData.avatarValue
                                    ? rightSideData.avatarValue
                                    : UserImg_icon
                                }
                              />
                            </div>
                            <div className="retailer__dtls mb-4">
                              <p
                                onClick={() =>
                                  handleRedirection("Conversation")
                                }
                              >
                                {rightSideData.totalMessages} {t("Messages")}
                              </p>

                              <div className="d-flex align-items-center">
                                <div>
                                  <img src={Earth_icon} />
                                </div>

                                <p className="activeFrom mb-0 ml-1">
                                  {t("Active")} | {rightSideData.activity}
                                </p>
                              </div>

                              <hr />
                            </div>
                            <div className="input__block">
                              <label>{t("Verified")}</label>
                              <Switch
                                checkedChildren={t("Yes")}
                                unCheckedChildren={t("No")}
                                checked={
                                  rightSideData.verified == 1 ||
                                  rightSideData.verified == true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleOnSwitchChange(e, "select", "verified")
                                }
                              />
                              <hr />
                            </div>
                            <div className="input__block">
                              <a href={`mailto:${rightSideData.cEmail}`}>
                                {rightSideData.cEmail}
                              </a>
                              {/* <p>{rightSideData.cEmail}</p> */}
                              <p>{rightSideData.phoneNumber}</p>
                              <p>
                                {t("Registered")}: {rightSideData.createdDate}
                              </p>
                              <hr />
                            </div>
                          </div>
                        </Spin>
                      </div>
                    )}
                    {tabs == "Other" && (
                      <div>
                        <div className="profile__block">
                          <a
                            onClick={() => handleCountClick()}
                            class="retailer__name"
                          >
                            {customerCount ? customerCount : 0} Contacts
                          </a>
                        </div>
                        <div className="input__block">
                          <label>{t("Most Active")}</label>
                          {leftSideData.mostActive
                            ? leftSideData.mostActive.length > 0 &&
                              leftSideData.mostActive.map((item, i) => {
                                return (
                                  <p>
                                    <a
                                      onClick={() =>
                                        handleRedirectionSideMenu(
                                          "Profile",
                                          item.cust_id
                                        )
                                      }
                                      className="linkText"
                                    >
                                      {item.fullName}
                                    </a>
                                  </p>
                                );
                              })
                            : null}
                          <hr />
                        </div>
                        <div className="input__block">
                          <label>{t("Recent Conversations")}</label>
                          {leftSideData.recentConversations
                            ? leftSideData.recentConversations.length > 0 &&
                              leftSideData.recentConversations.map(
                                (item, i) => {
                                  return (
                                    <p>
                                      <a
                                        onClick={() =>
                                          handleRedirectionSideMenu(
                                            "Profile",
                                            item.customerId
                                          )
                                        }
                                        className="linkText"
                                      >
                                        {item.fullName}
                                      </a>
                                    </p>
                                  );
                                }
                              )
                            : null}
                          <hr />
                        </div>
                        <div className="input__block">
                          <label>{t("Appointments")}</label>
                          {leftSideData.appointments
                            ? leftSideData.appointments.length > 0 &&
                              leftSideData.appointments.map((item, i) => {
                                return (
                                  <p>
                                    <a
                                      onClick={() => handleOnAppointmentClick()}
                                      className="linkText"
                                    >
                                      {item.dayDate}
                                      {" - "}
                                      {item.appointmentTime}
                                      {" - "}
                                      {item.fullName}
                                    </a>
                                  </p>
                                );
                              })
                            : null}
                          <hr />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <Modal
                  title="Contact Tags"
                  centered
                  visible={visible}
                  onOk={() => setVisibleTag(false)}
                  onCancel={() => setVisibleTag(false)}
                  maskClosable={false}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      {t("Submit")}
                    </Button>,
                  ]}
                  width={400}
                >
                  <div className="form__fields p-0 border-0">
                    <div className="input__block">
                      <label>{t("Enter Tag Name")}</label>
                      <input type="text" />
                    </div>
                  </div>
                </Modal>

                {/* Tag Modal */}
                <Modal
                  title="Contact Tags"
                  centered
                  visible={visible}
                  onOk={() => setVisibleTag(false)}
                  onCancel={() => setVisibleTag(false)}
                  maskClosable={false}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={handleInsertTagsList}
                    >
                      Submit
                    </Button>,
                  ]}
                  width={400}
                >
                  <div className="form__fields p-0 border-0">
                    <div className="input__block">
                      <label>{t("Enter Tag Name")}</label>
                      <input
                        type="text"
                        name="tag"
                        value={tagData.tag}
                        onChange={handleTagChange}
                        className={validations2.tagVal && "border__red"}
                      />
                      <div>
                        {validations2.tagVal && (
                          <p className="error-color-red">
                            {validations2.tagVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* End */}

                <Modal
                  className=""
                  centered
                  visible={deleteModalVisible}
                  onOk={handleRemoveCustomerConversation}
                  onCancel={handleCancelModal}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleCancelModal}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      loading={deleteLoading}
                      type="primary"
                      onClick={handleRemoveCustomerConversation}
                    >
                      {t("Delete")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 text-center">
                              <p className="subheading">
                                {t(
                                  "Are You Sure You Want delete this conversation ?"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* Modal Start according to dropdown */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={emailModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("Email")}
                  onCancel={handleEmailModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleEmailModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() => handleUpdateAddUserProfileList("Email")}
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Enter E-mail")}</label>
                      <input
                        type="text"
                        name="cEmail"
                        value={rightSideDataDummy.cEmail}
                        onChange={handleRightSideChange}
                        className={emailValidation && "border__red"}
                      />
                      <div>
                        {emailValidation && (
                          <p className="error-color-red">{emailValidation}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* For BirthDay */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={birthDayModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("Birthday")}
                  onCancel={handleBirthDayModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleBirthDayModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() => handleUpdateAddUserProfileList("Birthday")}
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("BirthDay")}</label>
                      <input
                        type="date"
                        name="birthdayDate"
                        defaultValue={BirthDayDate}
                        // value={right}
                        onChange={handleRightSideChange}
                        className={
                          birthDayValidation1 ? "border__red" : "border__grey"
                        }
                      />
                      <div>
                        {birthDayValidation1 && (
                          <p className="error-color-red">
                            {birthDayValidation1}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* For Anniversary */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={anniversaryModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("Anniversary")}
                  onCancel={handleAnniversaryModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleAnniversaryModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() =>
                        handleUpdateAddUserProfileList("Anniversary")
                      }
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Anniversary")}</label>
                      <input
                        type="date"
                        name="anniversaryDate"
                        defaultValue={anniversaryDate}
                        // value={rightsi}
                        onChange={handleRightSideChange}
                        className={
                          anniversaryValidation1
                            ? "border__red"
                            : "border__grey"
                        }
                      />
                      <div>
                        {anniversaryValidation1 && (
                          <p className="error-color-red">
                            {anniversaryValidation1}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* For Phone Number */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={phoneModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("Phone")}
                  onCancel={handlePhoneModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handlePhoneModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() => handleUpdateAddUserProfileList("Phone")}
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Enter Phone Number")}</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={rightSideDataDummy.phoneNumber}
                        onChange={handleRightSideChange}
                        className={phoneValidation && "border__red"}
                      />
                      <div>
                        {phoneValidation && (
                          <p className="error-color-red">{phoneValidation}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* Gender Modal */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={genderModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("Gender")}
                  onCancel={handleGenderModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleGenderModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() => handleUpdateAddUserProfileList("Gender")}
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Choose Gender")}</label>
                      <Select
                        className="border__grey"
                        placeholder={t("Select Gender")}
                        name="cSex"
                        optionFilterProp="children"
                        value={
                          rightSideDataDummy.cSex
                            ? rightSideDataDummy.cSex.toString()
                            : ""
                        }
                        onChange={(e) =>
                          handleRightSideChange(e, "select", "cSex")
                        }
                      >
                        <Option value="2"> Male </Option>
                        <Option value="1"> Female </Option>
                      </Select>
                    </div>
                  </div>
                </Modal>

                {/* Current Interest */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={currentInteresetModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("CurrentInterest")}
                  onCancel={handlecurrentInteresetModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button
                      key="back"
                      onClick={handlecurrentInteresetModalCancel}
                    >
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() =>
                        handleUpdateAddUserProfileList("CurrentInterest")
                      }
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Choose Current Interest")}</label>
                      <Select
                        className={
                          currentInterestValidation1
                            ? "border__red"
                            : "border__grey"
                        }
                        placeholder={t("Select Gender")}
                        name="currentInterestId"
                        optionFilterProp="children"
                        value={
                          rightSideDataDummy.currentInterestId
                            ? rightSideDataDummy.currentInterestId
                            : ""
                        }
                        onChange={(e) =>
                          handleRightSideChange(
                            e,
                            "select",
                            "currentInterestId"
                          )
                        }
                      >
                        <Option value="">{t("--Select--")}</Option>
                        {currentInterestList &&
                          currentInterestList.map((item, i) => {
                            return (
                              <Option value={item.jewelryCategoryID}>
                                {item.jewelryCategory}
                              </Option>
                            );
                          })}
                      </Select>
                      <div>
                        {currentInterestValidation1 && (
                          <p className="error-color-red">
                            {currentInterestValidation1}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* Favourite Modal */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={favoriteMetalModalVisible}
                  onOk={() => handleUpdateAddUserProfileList("FavoriteMetal")}
                  onCancel={handleFavoriteMetalModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleFavoriteMetalModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() =>
                        handleUpdateAddUserProfileList("FavoriteMetal")
                      }
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Choose Favourite Metal")}</label>
                      <Select
                        className={
                          favoriteMetalValidation1
                            ? "border__red"
                            : "border__grey"
                        }
                        placeholder={t("Select Gender")}
                        name="favoriteMetalId"
                        optionFilterProp="children"
                        value={
                          rightSideDataDummy.favoriteMetalId
                            ? rightSideDataDummy.favoriteMetalId
                            : ""
                        }
                        onChange={(e) =>
                          handleRightSideChange(e, "select", "favoriteMetalId")
                        }
                      >
                        <Option value="">{t("--Select--")}</Option>
                        {metalList &&
                          metalList.map((item, i) => {
                            return (
                              <Option value={item.metalTypeID}>
                                {item.metalType}
                              </Option>
                            );
                          })}
                      </Select>
                      <div>
                        {favoriteMetalValidation1 && (
                          <p className="error-color-red">
                            {favoriteMetalValidation1}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* FingerSize Modal */}

                <Modal
                  className=""
                  centered
                  visible={fingerSizeModalVisible}
                  onOk={() =>
                    handleUpdateAddUserProfileList("LHIndexFinger", fingerType)
                  }
                  onCancel={handleFingerSizeModalCancel}
                  maskClosable={false}
                  width={400}
                  footer={[
                    <Button key="back" onClick={handleFingerSizeModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={updateProfileLoading}
                      onClick={() =>
                        handleUpdateAddUserProfileList(
                          "LHIndexFinger",
                          fingerType
                        )
                      }
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="input__block">
                      <label>{t("Enter Size")}</label>
                      <input
                        type="text"
                        maxlength="2"
                        name={
                          fingerType == "cSizeLI"
                            ? "cSizeLI"
                            : fingerType == "cSizeLM"
                            ? "cSizeLM"
                            : fingerType == "cSizeLT"
                            ? "cSizeLT"
                            : fingerType == "cSizeRI"
                            ? "cSizeRI"
                            : fingerType == "cSizeRM"
                            ? "cSizeRM"
                            : fingerType == "cSizeRT"
                            ? "cSizeRT"
                            : fingerType == "cSizeLL"
                            ? "cSizeLL"
                            : fingerType == "cSizeLR"
                            ? "cSizeLR"
                            : fingerType == "cSizeRL"
                            ? "cSizeRL"
                            : fingerType == "cSizeRR"
                            ? "cSizeRR"
                            : ""
                        }
                        value={
                          fingerType == "cSizeLI"
                            ? rightSideDataDummy.cSizeLI
                            : fingerType == "cSizeLM"
                            ? rightSideDataDummy.cSizeLM
                            : fingerType == "cSizeLT"
                            ? rightSideDataDummy.cSizeLT
                            : fingerType == "cSizeRI"
                            ? rightSideDataDummy.cSizeRI
                            : fingerType == "cSizeRM"
                            ? rightSideDataDummy.cSizeRM
                            : fingerType == "cSizeRT"
                            ? rightSideDataDummy.cSizeRT
                            : fingerType == "cSizeLL"
                            ? rightSideDataDummy.cSizeLL
                            : fingerType == "cSizeLR"
                            ? rightSideDataDummy.cSizeLR
                            : fingerType == "cSizeRL"
                            ? rightSideDataDummy.cSizeRL
                            : fingerType == "cSizeRR"
                            ? rightSideDataDummy.cSizeRR
                            : ""
                        }
                        onChange={(e) =>
                          handleRightSideChange(
                            e,
                            "",
                            fingerType == "cSizeLI"
                              ? "cSizeLI"
                              : fingerType == "cSizeLM"
                              ? "cSizeLM"
                              : fingerType == "cSizeLT"
                              ? "cSizeLT"
                              : fingerType == "cSizeRI"
                              ? "cSizeRI"
                              : fingerType == "cSizeRM"
                              ? "cSizeRM"
                              : fingerType == "cSizeRT"
                              ? "cSizeRT"
                              : fingerType == "cSizeLL"
                              ? "cSizeLL"
                              : fingerType == "cSizeLR"
                              ? "cSizeLR"
                              : fingerType == "cSizeRL"
                              ? "cSizeRL"
                              : fingerType == "cSizeRR"
                              ? "cSizeRR"
                              : ""
                          )
                        }
                        onKeyPress={onKeyPressEvent}
                        // className={phoneValidation && "border__red"}
                      />
                      {/* <div>
                                                {phoneValidation && (
                                                    <p className="error-color-red">
                                                        {phoneValidation}
                                                    </p>
                                                )}
                                            </div> */}
                    </div>
                  </div>
                </Modal>

                {/* Address Modal */}

                <Modal
                  // title="-"
                  className=""
                  centered
                  visible={addressModalVisible}
                  // onOk={}
                  onCancel={handleaddressModalCancel}
                  maskClosable={false}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleaddressModalCancel}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={addressModalLoading}
                      onClick={() =>
                        handleUpdateMultipleAddUserProfileList(
                          addressOption == "Billing Address"
                            ? 1
                            : addressOption == "Home Address"
                            ? 0
                            : addressOption == "Work Address"
                            ? 2
                            : addressOption == "Vacation Address"
                            ? 3
                            : addressOption == "Partner Address"
                            ? 4
                            : addressOption == "Office Address"
                            ? 5
                            : "",
                          addressOption == "Billing Address"
                            ? addressResponse.billingAddress[0].cAddr_id
                            : addressOption == "Home Address"
                            ? addressResponse.homeaddress[0].cAddr_id
                            : addressOption == "Work Address"
                            ? addressResponse.workaddress[0].cAddr_id
                            : addressOption == "Vacation Address"
                            ? addressResponse.vacationholidayaddress[0].cAddr_id
                            : addressOption == "Partner Address"
                            ? addressResponse.partneradress[0].cAddr_id
                            : addressOption == "Office Address"
                            ? addressResponse.officeadress[0].cAddr_id
                            : ""
                        )
                      }
                    >
                      {t("Update")}
                    </Button>,
                  ]}
                >
                  <div className="form__fields p-0 border-0 mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>{t("Street")}</label>
                          <input
                            type="text"
                            name="street"
                            value={addressModalData.street}
                            onChange={handleAddressDataOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>{t("Suite")}</label>
                          <input
                            type="text"
                            name="suite"
                            value={addressModalData.suite}
                            onChange={handleAddressDataOnChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>{t("City")}</label>
                          <input
                            type="text"
                            name="city"
                            value={addressModalData.city}
                            onChange={handleAddressDataOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>{t("State")}</label>
                          <input
                            type="text"
                            name="state"
                            value={addressModalData.state}
                            onChange={handleAddressDataOnChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input__block">
                          <label>{t("Zip Code")}</label>
                          <input
                            type="text"
                            name="zipCode"
                            value={addressModalData.zipCode}
                            onChange={handleAddressDataOnChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                {/* End Modal */}

                {/* modal start */}

                <Modal
                  title={`${t("Message to")} ${
                    VendorNameForPopup ?? "GemFind"
                  }`}
                  visible={contactVenorModel}
                  centered
                  className="request_info"
                  maskClosable={false}
                  onCancel={closeVendordiamondModal}
                  footer={[
                    <Button key="back" onClick={closeVendordiamondModal}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      loading={buttonLoading}
                      onClick={handleSubmitRequestMoreInfo}
                    >
                      {t("Send")}
                    </Button>,
                  ]}
                >
                  <div className="row">
                    <div class="col-md-12">
                      <div className="table__block">
                        <div className="row p-0 border-0 form__fields mh-auto">
                          <div className="col-md-12">
                            <div className="input__block">
                              <textarea
                                value={
                                  requestDiamondVendorInputData.personalMessage
                                }
                                className={
                                  requestDiamondVendorInputDataValidation.personalMessageval &&
                                  "border__red"
                                }
                                onChange={(e) =>
                                  handleRequestMoreInfoChange(
                                    e,
                                    "personalMessage",
                                    "input"
                                  )
                                }
                              ></textarea>
                              {requestDiamondVendorInputDataValidation.personalMessageval && (
                                <p className="error-color-red">
                                  {
                                    requestDiamondVendorInputDataValidation.personalMessageval
                                  }
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* model end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileNew;
