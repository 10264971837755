import React, { useState, Fragment } from "react";
import { Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import communityVendorService from "../../../services/community-vendor.service";
import RetailerDataService from "../../../services/retailer-retailer.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const VendorRequest = () => {
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const [getLoading, setGetLoading] = useState(false);
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [requestedVendorData, setRequestedVendorData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumnName, setSortColumnName] = useState("dealerCompany");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [search, setSearch] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);

  const columns = [
    {
      title: t("Vendor"),
      dataIndex: "dealerCompany",
      sorter: (a, b) => a.dealerCompany.localeCompare(b.dealerCompany),
      showSorterTooltip: false,
      render: (index, row) => {
        return <span className="linkText">{row.dealerCompany}</span>;
      },
    },
    {
      title: t("Vendor Type"),
      dataIndex: "retailerType",
      sorter: (a, b) => a.retailerType.localeCompare(b.retailerType),
      showSorterTooltip: false,
      className: "hideInMobile",
      // width: 150,
      render: (index, row) => {
        return <Fragment>{row.retailerType}</Fragment>;
      },
    },
    {
      title: t("City"),
      dataIndex: "dealerCity",
      className: "hideInMobile",
      // width: 100,
      sorter: (a, b) => a.dealerCity.localeCompare(b.dealerCity),
      showSorterTooltip: false,
      render: (index, row) => {
        return <Fragment>{row.dealerCity}</Fragment>;
      },
    },
    {
      title: t("State"),
      dataIndex: "dealerState",
      // width: 100,
      sorter: (a, b) => a.dealerState.localeCompare(b.dealerState),
      showSorterTooltip: false,
      render: (index, row) => <Fragment>{row.dealerState}</Fragment>,
    },

    {
      title: t("Requested Date"),
      dataIndex: "reqdDate",
      // width: 120,
      sorter: (a, b) => a.reqdDate.localeCompare(b.reqdDate),
      showSorterTooltip: false,
      render: (index, row) => {
        return <Fragment>{row.reqdDate}</Fragment>;
      },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      width: 130,
      render: (index, row) => {
        return (
          <Fragment>
            <div className="action__btns">
              <div className="mx-2">
                <span
                  onClick={() => {
                    handleVendorRequestApprove(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {t("Approve")}
                </span>
              </div>
              <div className="mx-2">
                <span
                  onClick={() => {
                    handleVenorRequestDisApprove(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {t("Reject")}
                </span>
              </div>
            </div>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData) {
          if (loginDetials.loginDetials.responseData.dealerId) {
            handleGetVendorRequests(
              loginDetials.loginDetials.responseData.dealerId
            );
            setDealerId(loginDetials.loginDetials.responseData.dealerId);
          }
        }
      }
    }
  }, []);

  const handleGetVendorRequests = async (id) => {
    let inputData = {
      dealerID: id.toString(),
    };

    setGetLoading(true);

    try {
      await RetailerDataService.GetVendorRequests(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData.pendingResponse;
          if (msg == "Success") {
            setRequestedVendorData(responseData);
            setGetLoading(false);
            if (responseData) {
              setTotalCount(responseData.length);
            } else {
              setTotalCount(0);
            }
          } else {
            setGetLoading(false);
          }
        })
        .catch((error) => {
          setGetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setGetLoading(false);
    }
  };

  const handleVenorRequestDisApprove = async (row) => {
    let inputData = {
      dealerID: dealerId.toString(),
      retailerID: row.dealerID.toString(),
      moduleType: "",
    };

    setGetLoading(true);

    try {
      await RetailerDataService.VenorRequestDisApprove(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(t("Request Reject Successfully."));
            handleGetVendorRequests(dealerId);
          } else {
            setGetLoading(false);
            NotificationManager.error(t("Request Not Rejected."));
            handleGetVendorRequests(dealerId);
          }
        })
        .catch((error) => {
          console.log(error);
          setGetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setGetLoading(false);
    }
  };

  const handleVendorRequestApprove = async (row) => {
    let inputData = {
      dealerID: row.dealerID.toString(),
      retailerID: dealerId.toString(),
      moduleType: "",
    };

    setGetLoading(true);

    try {
      await RetailerDataService.VendorRequestApprove(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(t("Request Approved Successfully."));
            handleGetVendorRequests(dealerId);
          } else {
            setGetLoading(false);
            NotificationManager.error(t("Request Not Approved."));
            handleGetVendorRequests(dealerId);
          }
        })
        .catch((error) => {
          console.log(error);
          setGetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setGetLoading(false);
    }
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortOrder(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortOrder(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortOrder(type);
    }

    if (filters.dealerCompany) {
      obj = {
        columnName: "dealerCompany",
        columnValue: filters.dealerCompany[0],
        columnType: "Input",
      };
      filter.push(obj);
    } else {
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetVendorRequests(dealerId);
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 class="workarea__heading mt-0 mb-0 d-flex">
              {" "}
              <h4 className="workarea__heading mt-0 mb-0 mx-1">
                {t("Vendor Request")}
              </h4>
              {"(" + totalCount + ")"}
            </h4>
          </div>
        </div>

        <div
          className={
            selectedColumnId !== 0
              ? "col-lg-9 divForm vendors-section"
              : "col-lg-12"
          }
        >
          <Spin spinning={getLoading}>
            <Table
              columns={columns}
              className="venodrRequest"
              onChange={handleTableASCDES}
              dataSource={requestedVendorData}
              scroll={{ y: 800 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
                // onChange: handlePageNoChange,
                // onShowSizeChange: handlePageSizeChange,
              }}
              // onChange={(pagination, filters, sorters) => {
              //   document
              //     .getElementsByClassName("content__area")[0]
              //     .scroll(0, 0);
              // }}
              //   onRow={(record, recordIndex) => ({
              //     onClick: (event) => {
              //       handleShowDataToRightNew(record);
              //     },
              //   })}
              rowClassName={(record) =>
                record.dealerID == selectedColumnId ? "row-active" : ""
              }
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default VendorRequest;
