import React, { useRef, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Tooltip,
  Slider,
} from "antd";
import Filter_icon from "../../assets/images/filter_icon.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Info_icon from "../../assets/images/info_icon.png";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Close from "../../assets/images/close.png";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
import space__icon from "../../assets/images/space__icon.png";
import MultiRangeSlider from "../contacts/MultiRangeSlider";
import { useTranslation } from "react-i18next";

//Initial Watch Data
const myWatchData = {
  category: null,
  materialType: null,
  genderSelect: null,
  vendor: null,
  materialColor: null,
  gemstone: null,
  collectionSelect: null,
  priceMin: 0,
  priceMax: 2000,
};
const WatchesSearch = () => {
  const { t } = useTranslation();
  const [productChange, setProductChange] = useState(true);
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const resetRange = useRef(null);

  const [filterWatchValue, setfilterWatchValue] = useState(myWatchData);

  // Slider
  const [priceValue, setPriceValue] = useState([0, 2000]);
  //Onchange Event
  const handleOnChange = (eValue, name) => {
    setfilterWatchValue({
      ...filterWatchValue,
      [name]: eValue,
    });
  };

  // //Multi range slider Onchange Event
  // const handleRangeOnChange = (eValue, name) => {
  //     setfilterWatchValue({
  //         ...filterWatchValue,
  //         [name]: eValue,
  //     });
  // };

  // //Multi range slider Reset
  // const rangeSliderReset = React.useRef(null);

  //Apply Filters
  const applyFilters = () => {
    console.log(filterWatchValue);
  };

  //Reset Filters
  const resetFilters = () => {
    setfilterWatchValue({
      category: null,
      materialType: null,
      genderSelect: null,
      vendor: null,
      materialColor: null,
      gemstone: null,
      collectionSelect: null,
      priceMin: 0,
      priceMax: 2000,
    });
    // resetRange.current();
    setPriceValue([filterWatchValue.priceMin, filterWatchValue.priceMax]);
  };

  const gender = (
    <span>
      {t(
        "Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."
      )}
    </span>
  );

  const collection = (
    <span>
      {" "}
      {t("Please select a vendor before choosing a collection.")}{" "}
    </span>
  );

  const columns = [
    {
      title: "",
      dataIndex: "image",
      width: 60,
    },
    {
      title: t("Retail Stock #"),
      dataIndex: "retailstock",
      width: 300,
    },
    {
      title: t("Category"),
      dataIndex: "category",
    },
    {
      title: t("Vendor"),
      dataIndex: "vendor",
    },
    {
      title: t("Collection"),
      dataIndex: "collection",
    },
    {
      title: t("$ cost"),
      dataIndex: "cost",
    },
    {
      title: t("$ Retail"),
      dataIndex: "retail",
    },
  ];

  const data = [
    {
      key: "1",
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "2",
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "3",
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "4",
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;
  function onSearch(val) {
    console.log("search:", val);
  }
  const handleProductChangeOpen = () => {
    setProductChange(false);
  };

  const handleProductChangeClose = () => {
    setProductChange(true);
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  //Table Rows Checkbox Logic
  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
      console.log(selectedRows);
    },

    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="filter_section p-0 m-0">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {t("Watches Search")}
                </h4>
              </div>

              <div className="col-md-12 mt-4">
                <div className="filt_optndiv">
                  <div className="gview__div">
                    <span
                      className={
                        viewItem === "grid"
                          ? "fa fa-th grid_icon active"
                          : "fa fa-th grid_icon"
                      }
                      onClick={() => handleViewChange("grid")}
                    ></span>
                    <span
                      className={
                        viewItem === "list"
                          ? "fa fa-th-list list_icon active"
                          : "fa fa-th-list list_icon"
                      }
                      onClick={() => handleViewChange("list")}
                    ></span>

                    <span className="rslsrch__txt">
                      {" "}
                      {t("There are")}{" "}
                      <span className="no__txt">166,297</span>{" "}
                      {t("results for your search")}.
                    </span>
                  </div>

                  <div className="filtbtn__div">
                    <span className="selct__txt">
                      <i class="fa fa-list-ul"></i> {selectedRows.length}{" "}
                      {t("Selected")}{" "}
                    </span>
                    <button class="primary-btn ml-2">
                      <i class="fa fa-eye"></i> {t("View")}{" "}
                    </button>
                    <button class="primary-btn ml-2">
                      <i class="fa fa-print"></i> {t("Print")}{" "}
                    </button>
                    <button
                      className="primary-btn ml-2"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {" "}
                          {t("Show Filter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {t("Hide Filter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className={
                    hideShowFilter
                      ? "form__fields filter_inpsection hide"
                      : "form__fields filter_inpsection"
                  }
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Category")} </label>
                          <Select
                            className="border__grey"
                            placeholder={t("Select")}
                            onSearch={onSearch}
                            showSearch
                            value={filterWatchValue.category}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "category")
                            }
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="categOne">
                              {" "}
                              {t("Category 1")}{" "}
                            </Option>
                            <Option value="categTwo">
                              {" "}
                              {t("Category 2")}{" "}
                            </Option>
                            <Option value="categThree">
                              {" "}
                              {t("Category 3")}{" "}
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Material Type")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Type"
                            optionFilterProp="children"
                            value={filterWatchValue.materialType}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialType")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="materialOne">
                              {" "}
                              {t("Material 1")}{" "}
                            </Option>
                            <Option value="materialTwo">
                              {" "}
                              {t("Material 2")}{" "}
                            </Option>
                            <Option value="tom">
                              {" "}
                              {t("Material 3")}{" "}
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {t("Gender")}
                            <Tooltip placement="right" title={gender}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            value={filterWatchValue.genderSelect}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "genderSelect")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="all"> {t("All")} </Option>
                            <Option value="child">
                              {" "}
                              {t("Child")}(0){" "}
                            </Option>
                            <Option value="ladies">
                              {" "}
                              {t("Ladies")}(2344){" "}
                            </Option>
                            <Option value="men">
                              {" "}
                              {t("Mens")}(1234){" "}
                            </Option>
                            <Option value="teens">
                              {" "}
                              {t("Teen")}(4){" "}
                            </Option>
                            <Option value="unisex">
                              {" "}
                              {t("Unisex")}(2172){" "}
                            </Option>
                            <Option value="women">
                              {" "}
                              {t("Womens")}(5576){" "}
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Vendor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Vendor"
                            optionFilterProp="children"
                            value={filterWatchValue.vendor}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "vendor")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="vendorOne">
                              {" "}
                              {t("Vendor 1")}{" "}
                            </Option>
                            <Option value="vendorTwo">
                              {" "}
                              {t("Vendor 2")}{" "}
                            </Option>
                            <Option value="male"> {t("Abc")} </Option>
                            <Option value="female"> {t("Xyz")} </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Material Color")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Color"
                            optionFilterProp="children"
                            value={filterWatchValue.materialColor}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "materialColor")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="red"> {t("Red")} </Option>
                            <Option value="blue"> {t("Blue")} </Option>
                            <Option value="tom"> {t("Tom")} </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {t("Gemstone")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Image"
                            optionFilterProp="children"
                            value={filterWatchValue.gemstone}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "gemstone")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="gemstoneOne">
                              {" "}
                              {t("Gemstone 1")}{" "}
                            </Option>
                            <Option value="gemstoneTwo">
                              {" "}
                              {t("Gemstone 2")}{" "}
                            </Option>
                            <Option value="gemstoneThree">
                              {" "}
                              {t("Gemstone 3")}{" "}
                            </Option>
                            <Option value="gemstoneFour">
                              {" "}
                              {t("Xyz")}{" "}
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {t("Collection")}
                            <Tooltip placement="right" title={collection}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Collection"
                            optionFilterProp="children"
                            value={filterWatchValue.collectionSelect}
                            onChange={(eValue) =>
                              handleOnChange(eValue, "collectionSelect")
                            }
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="collectionOne">
                              {" "}
                              {t("Collection 1")}{" "}
                            </Option>
                            <Option value="collectionTwo">
                              {" "}
                              {t("Collection 2")}{" "}
                            </Option>
                            <Option value="collectionThree">
                              {" "}
                              {t("Collection 3")}{" "}
                            </Option>
                            <Option value="collectionFour">
                              {" "}
                              {t("Collection 4")}{" "}
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="sliderrange__maindiv">
                          <label> {t("Price")} </label>
                          <div className="col-md-12 mt-3">
                            <Slider
                              range
                              value={priceValue}
                              min={filterWatchValue.priceMin}
                              max={filterWatchValue.priceMax}
                              onChange={(e) => {
                                setPriceValue(e);
                              }}
                            />
                            <div className="rangeSlider__values">
                              <div className="input__block">
                                <input type="text" value={priceValue[0]} />
                              </div>
                              <div>
                                <img
                                  src={space__icon}
                                  className="img-fluid space__icon"
                                />
                              </div>
                              <div className="input__block">
                                <input type="text" value={priceValue[1]} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 filtbtn_div">
                        <button
                          className="primary-btn mr-2"
                          onClick={resetFilters}
                        >
                          {" "}
                          {t("Reset Filters")}{" "}
                        </button>
                        <button className="primary-btn" onClick={applyFilters}>
                          {" "}
                          {t("Apply Filters")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {viewItem === "grid" && (
                <div className="col-md-12">
                  <hr className="mt-4 mb-4" />
                  <div className="prod__section">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div>
                              <img src={Video_icon} alt="" />
                            </div>
                            <div>
                              <img src={Edit_icon} alt="" />
                            </div>
                            <div>
                              <img src={Delete_iconbl} alt="" />
                            </div>
                          </div>
                          {productChange ? (
                            <div
                              className="info__div"
                              onClick={handleProductChangeOpen}
                            >
                              <img src={Info_icon} alt="" />
                            </div>
                          ) : (
                            <div className="descr__div">
                              <div>
                                <p>
                                  <span> {t("Retaile Stock #")} </span>
                                  <span>POD267ADXCI</span>
                                </p>
                                <p>
                                  <span> {t("Category")} </span>
                                  <span>Pendants, Gemstone Pendant</span>
                                </p>
                                <p>
                                  <span> {t("Collection")} </span>
                                  <span>Opal</span>
                                </p>
                                <p>
                                  <span> {t("Vendor")} </span>
                                  <span>Parlé Jewelry Design</span>
                                </p>
                                <p>
                                  <span> {t("Cost")} </span>
                                  <span>$95.00</span>
                                </p>
                                <p>
                                  <span> {t("Retail")} </span>
                                  <span>$190.00</span>
                                </p>
                                <p>
                                  <span> {t("Metal Type")} </span>
                                  <span>14K Yellow Gold Price</span>
                                </p>
                                <p>
                                  <span> {t("Metal Color")} </span>
                                  <span>Yellow</span>
                                </p>
                              </div>
                              <div
                                className="close__div"
                                onClick={handleProductChangeClose}
                              >
                                <img src={Close} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div>
                              <img src={Video_icon} alt="" />
                            </div>
                            <div>
                              <img src={Edit_icon} alt="" />
                            </div>
                            <div>
                              <img src={Delete_iconbl} alt="" />
                            </div>
                          </div>
                          <div className="info__div">
                            <img src={Info_icon} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div>
                              <img src={Video_icon} alt="" />
                            </div>
                            <div>
                              <img src={Edit_icon} alt="" />
                            </div>
                            <div>
                              <img src={Delete_iconbl} alt="" />
                            </div>
                          </div>
                          <div className="info__div">
                            <img src={Info_icon} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div>
                              <img src={Video_icon} alt="" />
                            </div>
                            <div>
                              <img src={Edit_icon} alt="" />
                            </div>
                            <div>
                              <img src={Delete_iconbl} alt="" />
                            </div>
                          </div>
                          <div className="info__div">
                            <img src={Info_icon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {viewItem === "list" && (
                <div className="col-md-12 mt-4">
                  <div className="lviewprod__tbl">
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{ y: 800 }}
                      rowSelection={rowSelection}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WatchesSearch;
