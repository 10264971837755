import React, { useState, useEffect } from "react";
import {
  Select,
  Slider,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import space__icon from "../../assets/images/space__icon.png";
import AdminToolsDataService from "../../services/admin-tools.service";
import { useTranslation } from "react-i18next";

const SyncLimiter = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [dealerId, setDealerId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { Option } = Select;
  const [syncLimiterDetails, setSyncLimiterDetails] = useState({
    totalActiveProduct: "",
    totalConnectedProduct: "",
  });
  const [priceValue, setPriceValue] = useState([0, 1000000]);
  const [categoryList, setCategoryList] = useState([]);
  const [metalTypeList, setMetalTypeList] = useState([]);
  const [metalColorList, setMetalColorList] = useState([]);
  const [ViewMySyncData, setViewMySyncData] = useState({
    Category: [],
    MaterialType: [],
    MaterialColor: [],
    priceMin: 0,
    priceMax: 100000,
  });

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          GetSyncGetConnectd_ProductCount(
            loginDetials.loginDetials.responseData.dealerId
          );
          GetSyncGetConnected_TotalProduct(
            loginDetials.loginDetials.responseData.dealerId
          );
          GetSyncPageLoadData(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setViewMySyncData({
        ...ViewMySyncData,
        [name]: e,
      });
    } else {
      setViewMySyncData({
        ...ViewMySyncData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const GetSyncGetConnectd_ProductCount = (id) => {
    let inputData = {
      dealerID: id.toString(),
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncGetConnectd_ProductCount(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSyncLimiterDetails((prevState) => ({
              ...prevState,
              totalActiveProduct: responseData.response,
            }));
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleSliderChange = (e) => {
    setPriceValue(e);
  };

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    let Value = e.target.value && e.target.value.replace("$", "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > ViewMySyncData.priceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };

  const GetSyncGetConnected_TotalProduct = (id) => {
    let inputData = {
      dealerID: id.toString(),
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncGetConnected_TotalProduct(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setSyncLimiterDetails((prevState) => ({
              ...prevState,
              totalConnectedProduct: responseData.response,
            }));
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetSyncBindDropdownProductLimitation = (
    id,
    categ,
    mattype,
    matcolor,
    minran,
    maxran,
    typed,
    ftime
  ) => {
    let inputData = {
      dealerID: id.toString(),
      category: categ.toString(),
      materialType: mattype.toString(),
      materialColor: matcolor.toString(),
      minRange: minran.toString(),
      maxRange: maxran.toString(),
      typeid: typed,
      firstTime: ftime,
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncBindDropdownProductLimitation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setCategoryList(responseData.dtCategoryList);
            setMetalColorList(responseData.dtmetalcolorList);
            setMetalTypeList(responseData.dtmetaltypeList);

            if (responseData.dtCategoryList.length > 0) {
              let categoryList1 = [];
              const categoryList = responseData.dtCategoryList.filter(
                (item) => {
                  if (item.isSelected == true) {
                    categoryList1.push(item.jewelryCategoryID);
                  }
                }
              );
              setViewMySyncData((prevState) => ({
                ...prevState,
                Category: categoryList1,
              }));
            }

            if (responseData.dtmetalcolorList.length > 0) {
              let metalcolorlist1 = [];
              const metalcolor = responseData.dtmetalcolorList.filter(
                (item) => {
                  if (item.isSelected == true) {
                    metalcolorlist1.push(item.metalColorID);
                  }
                }
              );
              setViewMySyncData((prevState) => ({
                ...prevState,
                MaterialColor: metalcolorlist1,
              }));
            }
            if (responseData.dtmetaltypeList.length > 0) {
              let metaltypelist1 = [];
              const metallist = responseData.dtmetaltypeList.filter((item) => {
                if (item.isSelected == true) {
                  metaltypelist1.push(item.metalTypeID);
                }
              });
              setViewMySyncData((prevState) => ({
                ...prevState,
                MaterialType: metaltypelist1,
              }));
            }

            setPriceValue([
              parseInt(responseData.priceMINValue),
              parseInt(responseData.priceMAXValue),
            ]);
          } else {
            setCategoryList([]);
            setMetalColorList([]);
            setMetalTypeList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetSyncPageLoadData = (id) => {
    let inputData = {
      retailerID: id.toString(),
    };
    setLoading(true);
    try {
      AdminToolsDataService.SyncPageLoadData(inputData)
        .then((response) => {
          setLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData?.stncData?.length > 0) {
              let categoryList1 = [];
              let metalcolorlist1 = [];
              let metaltypelist1 = [];
              let minlist1 = [];
              let maxlist1 = [];
              const categoryList = responseData.stncData.filter((item) => {
                if (item.attributeName == "Category") {
                  categoryList1.push(item.attributeValue);
                }
              });
              const metalcolor = responseData.stncData.filter((item) => {
                if (item.attributeName == "MetalColor") {
                  metalcolorlist1.push(item.attributeValue);
                }
              });
              const metallist = responseData.stncData.filter((item) => {
                if (item.attributeName == "MetalType") {
                  metaltypelist1.push(item.attributeValue);
                }
              });
              const minlist = responseData.stncData.filter((item) => {
                if (item.attributeName == "MinPrice") {
                  minlist1.push(item.attributeValue);
                }
              });
              const maxlist = responseData.stncData.filter((item) => {
                if (item.attributeName == "MaxPrice") {
                  maxlist1.push(item.attributeValue);
                }
              });
              GetSyncBindDropdownProductLimitation(
                id,
                categoryList1,
                metaltypelist1,
                metalcolorlist1,
                minlist1,
                maxlist1,
                0,
                0
              );
            }

            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetInsertSyncDetails = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      category: ViewMySyncData.Category.toString(),
      metalType: ViewMySyncData.MaterialType.toString(),
      metalColor: ViewMySyncData.MaterialColor.toString(),
      minVal: priceValue[0].toString(),
      maxVa: priceValue[1].toString(),
    };
    setButtonLoading(true);
    try {
      AdminToolsDataService.SyncInsertProductdata(inputData)
        .then((response) => {
          setButtonLoading(false);
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Sucessfully Updated."));
            GetSyncGetConnectd_ProductCount(dealerId);
            GetSyncGetConnected_TotalProduct(dealerId);
            GetSyncPageLoadData(dealerId);
            setButtonLoading(false);
          } else {
            NotificationManager.error(t("Failed."));
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-md-12">
              <div className="heading__block">
                <h4 class="workarea__heading mt-0 mb-0">Sync Limiter</h4>
                <button
                  className="primary-btn"
                  loading={buttonLoading}
                  onClick={() => GetInsertSyncDetails()}
                >
                  Save
                </button>
              </div>
              <div>
                <p>
                  This feature allows you to control the number of products you
                  want to sync to your Website. In selecting a particular
                  category or product attribute to limit your synced
                  products,you are saying that you ONLY want to sync products
                  which match the categories and/or attirbutes you've selected.
                  Products which do not match the categories or attributes which
                  you've selected will not sync to your website.
                </p>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>

            <div className="col-md-12">
              <div className="input__block mb-0">
                <label>
                  Total Connected Products :{" "}
                  {syncLimiterDetails.totalConnectedProduct
                    ? syncLimiterDetails.totalConnectedProduct
                    : ""}{" "}
                </label>
                <label>
                  Total Active Products :{" "}
                  {syncLimiterDetails.totalActiveProduct
                    ? syncLimiterDetails.totalActiveProduct
                    : ""}{" "}
                </label>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <div className="input__block mb-0">
                <label> Available Catagory</label>
              </div>
            </div>

            <div className="col-9">
              <div className="input__block mb-0">
                <Select
                  style={{ height: 80 }}
                  showSearch
                  className="border__grey"
                  mode="multiple"
                  placeholder={t("Select One or More Category")}
                  optionFilterProp="children"
                  name="Category"
                  value={
                    ViewMySyncData.Category ? ViewMySyncData.Category : null
                  }
                  onChange={(e) => handleOnChange(e, "select", "Category")}
                >
                  {categoryList &&
                    categoryList.map((item) => {
                      return (
                        <React.Fragment>
                          <Option value={item.jewelryCategoryID}>
                            {item.jewelryCategory}
                          </Option>
                        </React.Fragment>
                      );
                    })}
                </Select>
              </div>
            </div>

            <div className="col-1">
              <div className="input__block mb-0">
                <button className="primary-btn w-100">Reset</button>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <div className=" mb-0">
                <label> Material Type</label>
              </div>
            </div>

            <div className="col-9">
              <div className="input__block mb-0">
                <Select
                  style={{ height: 80 }}
                  showSearch
                  className="border__grey"
                  mode="multiple"
                  placeholder={t("Select One or More Material Type")}
                  optionFilterProp="children"
                  name="MaterialType"
                  value={
                    ViewMySyncData.MaterialType
                      ? ViewMySyncData.MaterialType
                      : null
                  }
                  onChange={(e) => handleOnChange(e, "select", "MaterialType")}
                >
                  {metalTypeList &&
                    metalTypeList.map((item) => {
                      return (
                        <React.Fragment>
                          <Option value={item.metalTypeID}>
                            {item.metalType}
                          </Option>
                        </React.Fragment>
                      );
                    })}
                </Select>
              </div>
            </div>

            <div className="col-1">
              <div className="input__block mb-0">
                <button className="primary-btn w-100">Reset</button>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <div className="input__block mb-0">
                <label> Material Color</label>
              </div>
            </div>

            <div className="col-9">
              <div className="input__block mb-0">
                <Select
                  style={{ height: 80 }}
                  showSearch
                  className="border__grey"
                  mode="multiple"
                  placeholder={t("Select One or More Material Color")}
                  optionFilterProp="children"
                  name="MaterialColor"
                  value={
                    ViewMySyncData.MaterialColor
                      ? ViewMySyncData.MaterialColor
                      : null
                  }
                  onChange={(e) => handleOnChange(e, "select", "MaterialColor")}
                >
                  {metalColorList &&
                    metalColorList.map((item) => {
                      return (
                        <React.Fragment>
                          <Option value={item.metalColorID}>
                            {item.metalColor}
                          </Option>
                        </React.Fragment>
                      );
                    })}
                </Select>
              </div>
            </div>

            <div className="col-1">
              <div className="input__block mb-0">
                <button className="primary-btn w-100">Reset</button>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <div className="input__block mb-0">
                <label>Price</label>
              </div>
            </div>

            <div className="col-4">
              <div className="input__block mb-0">
                <div className="sliderrange__maindiv">
                  <div className="col-md-12">
                    <Slider
                      range
                      value={priceValue}
                      min={ViewMySyncData.priceMin}
                      max={ViewMySyncData.priceMax}
                      onChange={(e) => {
                        handleSliderChange(e);
                      }}
                    />
                    <div className="rangeSlider__values">
                      <div className="input__block mb-0">
                        <span style={{ color: "#ccc", marginRight: "5px" }}>
                          $
                        </span>
                        <input
                          type="text"
                          value={priceValue[0].toLocaleString("en-US")}
                          onChange={(e) => {
                            firstSliderPriceValue(e, 0);
                          }}
                        />
                      </div>
                      <div>
                        <img
                          src={space__icon}
                          className="img-fluid space__icon"
                        />
                      </div>
                      <div className="input__block mb-0">
                        <span style={{ color: "#ccc", marginRight: "5px" }}>
                          $
                        </span>
                        <input
                          type="text"
                          value={priceValue[1].toLocaleString("en-US")}
                          onChange={(e) => {
                            firstSliderPriceValue(e, 1);
                          }}
                          onKeyPress={onKeyPressEvent1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-1">
              <hr />
            </div>
          </div>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default SyncLimiter;
