import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Table,
  Spin,
} from "antd";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut, Bar, Line, Scatter, Pie, Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import GlobalDiamondReportService from "../../services/reports-globalDiamond-service";
import { DatePicker, Space } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const DiamondClicksComp = ({
  globalDiamonds,
  isApplyFilter,
  mainForDealer,
}) => {
  const { t } = useTranslation();
  /*React Binding Starts*/
  const [dealerId, setDealerId] = useState();
  const [forDealer, setForDealer] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [LoadChartReportsLoading, setLoadChartReportsLoading] = useState(false);
  // const [diamondClaritySearchesLabels, setdiamondClaritySearchesLabels] = useState([]);
  const [diamondClicksLabels, setdiamondClicksLabels] = useState([]);
  // const [DiamondClaritySearchesList, setDiamondClaritySearchesList] = useState([]);
  const [DiamondClicksList, setDiamondClicksList] = useState([]);
  // const [DiamondClarityYAxisList, setDiamondClarityYAxisList] = useState([]);
  const [DiamondClicksYAxisList, setDiamondClicksYAxisList] = useState([]);
  const [key, setKey] = useState(true);

  /*React Binding Ends*/

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleCheckDealermembers(loginDetials.loginDetials.responseData.dealerId);
          handleLoadChartReports(
            loginDetials.loginDetials.responseData.dealerId,
            mainForDealer
          );
        }
      }
    }
  }, [isApplyFilter]);

  /*API Binding Starts*/
  const handleCheckDealermembers = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      GlobalDiamondReportService.CheckDealermembers(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var forDealer = responseData.forDealer;
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setForDealer(forDealer);
            } else {
              setForDealer("");
            }
            // handleDiamondChartForCutGrade(id, forDealer);
            handleLoadChartReports(id, forDealer);
          } else {
            setForDealer("");
          }
        })
        .catch((error) => {
          setForDealer("");
        });
    } catch (error) {
      setForDealer("");
    }
  };

  const handleLoadChartReports = (dealerId, forDealer) => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealer ? forDealer.toString() : "",
        fromDate: globalDiamonds.dataRangeFrom?moment(
          globalDiamonds.dataRangeFrom
        ).format("YYYY-MM-DD") : "2020-08-17",
        toDate: globalDiamonds.dataRangeTo ? moment(
          globalDiamonds.dataRangeTo
        ).format("YYYY-MM-DD") : "2022-09-15" ,
        // "fromDate": "2020-08-17",
        // "toDate": "2022-09-15",
        retailerIds: globalDiamonds.retailerIds.toString(),
        vendorIds: globalDiamonds.vendorIds.toString(),
        selectedDiamondValue: globalDiamonds.SelectedDiamondValue,
        type: "",
        reportName: "ExtDiamondChrt"
      };
      setLoadChartReportsLoading(true);

      GlobalDiamondReportService.LoadChartReports(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DiamondClicksList = responseData.DiamondClicks;
          setLoadChartReportsLoading(false);
          if (message === "Success") {
            if (Object.keys(responseData).length !== 0) {
              setDiamondClicksList(DiamondClicksList);
              var DiamondClicksLabelArr = DiamondClicksList.map((item) => {
                return item.Date;
              });
              setdiamondClicksLabels(DiamondClicksLabelArr);
              var DiamondClicksYAxisList = DiamondClicksList.map((item) => {
                return item.CNT;
              });
              setDiamondClicksYAxisList(DiamondClicksYAxisList);
              setKey(!key);
            }
          } else {
            setDiamondClicksList([]);
            setDiamondClicksYAxisList([]);
          }
        })
        .catch((error) => {
          setDiamondClicksList([]);
          setDiamondClicksYAxisList([]);
          setLoadChartReportsLoading(false);
          setdiamondClicksLabels([]);
        });
    } catch (error) {
      setDiamondClicksList([]);
      setDiamondClicksYAxisList([]);
      setLoadChartReportsLoading(false);
      setdiamondClicksLabels([]);
    }
  };
  /*API Binding Ends*/

  return (
    <React.Fragment>
      <div className="col-lg-12 mt-4">
        <label>
          <b> {t("Diamond Clicks")} </b>
        </label>
        <div className="chart__section">
          <Spin spinning={LoadChartReportsLoading} size="large">
            <Line
            key={key}
              data={{
                labels: diamondClicksLabels,
                datasets: [
                  {
                    label: "Clicks",
                    data: DiamondClicksYAxisList,
                    fill: false,
                    borderColor: "rgb(66, 133, 244)",
                    borderWidth: 1,
                  },
                ],
              }}
              width={300}
              height={300}
              options={{
                scales: {
                  x: {
                    title: {
                      color: "rgb(66, 66, 66)",
                      display: false,
                      text: "Certificate",
                      font: {
                        size: 16,
                      },
                    },
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  }
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondClicksComp;
