import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import OwlCarousel from "react-owl-carousel";
import Groupbg from "../../assets/images/group_bg.png";
// import BannerBgImg from "../../assets/images/companyDetail-banner.png";
import roundDiamond from "../../assets/images/Round.jpg";
// import colorDiamond from "../../../assets/images/yellow-diamond.jpg";
import DealerProfileService from "../../services/dealer-profile.service";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";

import { Accordion } from "react-bootstrap";
// import Modal from "antd/lib/modal/Modal";
import UserSettingsService from "../../services/user-settings.service";

const { Option } = Select;

const UserSettings = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [btnloading, setBtnLoading] = useState(false);

  const [dealerID, setDealerID] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      //
      setDealerID(loginDetials.responseData.dealerId);
      handleGetCompanyProfileSettingDetails(loginDetials.responseData.dealerId);
    }
  }, []);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [validations, setValidations] = useState({
    mailServerVal: "",
    portNumberVal: "",
    usernameVal: "",
    passwordVal: "",
    fromEmailVal: "",
    fromNameVal: "",
    testYourSMTPSettingsVal: "",
  });
  const [emialValidations, setEmialValidations] = useState({
    customerRegistrationError: false,
    newAppointmentsError: false,
    inquiriesError: false,
    vendorRequestsError: false,
    conversationsError: false,
  });

  const initialState = {
    chkCustRegistration: false,
    customerRegistration: "",
    newAppointmentsCheckbx: false,
    newAppointments: "",
    inquiriesCheckbx: false,
    inquiries: "",
    newFavoritesListCheckbx: false,
    newFavoritesList: "",
    newFavoritesCheckbx: false,
    newFavorites: "",
    vendorRequestsCheckbx: false,
    vendorRequests: "",
    conversationsCheckbx: false,
    conversations: "",
    sendUploadReportCheckbx: false,
    sendUploadReport: "",
    purchaseOrdersCheckbx: false,
    purchaseOrders: "",
    salesOrdersCheckbx: false,
    salesOrders: "",
    ignoreBlankUploadsCheckbx: false,
    mailServer: "",
    portNumber: "",
    username: "",
    password: "",
    fromEmail: "",
    fromName: "",
    serverRequiresAuthCheckbx: false,
    useSSLCheckbx: false,
    testYourSMTPSettings: "",
  };
  const [emailPrefData, setEmailPrefData] = useState(initialState);

  const handleValidation = () => {
    const { mailServer, portNumber, username, password, fromEmail, fromName } =
      emailPrefData;
    const validations = {
      mailServerVal: "",
      portNumberVal: "",
      usernameVal: "",
      passwordVal: "",
      fromEmailVal: "",
      fromNameVal: "",
    };

    let isValid = true;

    if (
      mailServer == "" &&
      portNumber == "" &&
      username == "" &&
      password == "" &&
      fromEmail == "" &&
      fromName == ""
    ) {
      setValidations({
        mailServerVal: "",
        portNumberVal: "",
        usernameVal: "",
        passwordVal: "",
        fromEmailVal: "",
        fromNameVal: "",
      });
    } else {
      if (!mailServer) {
        isValid = false;
        validations.mailServerVal = t(
          "Mail Server Details is Compulsory"
        );
      }
      if (!portNumber) {
        isValid = false;
        validations.portNumberVal = t("Port Number is Compulsory");
      } else if (!portNumber.match(/^[0-9]+$/)) {
        validations.portNumberVal = t("Port Number is invalid");
      } else {
        validations.portNumberVal = "";
      }
      if (!username) {
        isValid = false;
        validations.usernameVal = t("Username is Compulsory");
      }
      if (!password) {
        isValid = false;
        validations.passwordVal = t("Password is Compulsory");
      }
      var validRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!fromEmail) {
        isValid = false;
        validations.fromEmailVal = t("From Email is Compulsory");
      } else if (!fromEmail.match(validRegex)) {
        isValid = false;
        validations.fromEmailVal = t("Email address is invalid");
      } else {
        validations.fromEmailVal = "";
      }
      if (!fromName) {
        isValid = false;
        validations.fromNameVal = t("From Name is Compulsory");
      }
    }
    if (!isValid) {
      setValidations(validations);
    }
    return isValid;
  };

  const handleSmtpValidation = () => {
    const {
      mailServer,
      portNumber,
      username,
      password,
      fromEmail,
      fromName,
      testYourSMTPSettings,
    } = emailPrefData;
    const validations = {
      mailServerVal: "",
      portNumberVal: "",
      usernameVal: "",
      passwordVal: "",
      fromEmailVal: "",
      fromNameVal: "",
      testYourSMTPSettingsVal: "",
    };

    let isValid = true;

    if (!mailServer) {
      isValid = false;
      validations.mailServerVal = t("Mail Server Details is Compulsory");
    }
    if (!portNumber) {
      isValid = false;
      validations.portNumberVal = t("Port Number is Compulsory");
    } else if (!portNumber.match(/^[0-9]+$/)) {
      validations.portNumberVal = t("Port Number is invalid");
    } else {
      validations.portNumberVal = "";
    }
    if (!username) {
      isValid = false;
      validations.usernameVal = t("Username is Compulsory");
    }
    if (!password) {
      isValid = false;
      validations.passwordVal = t("Password is Compulsory");
    }
    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!fromEmail) {
      isValid = false;
      validations.fromEmailVal = t("From Email is Compulsory");
    } else if (!fromEmail.match(validRegex)) {
      isValid = false;
      validations.fromEmailVal = t("Email address is invalid");
    } else {
      validations.fromEmailVal = "";
    }
    if (!fromName) {
      isValid = false;
      validations.fromNameVal = t("From Name is Compulsory");
    }

    if (!testYourSMTPSettings) {
      isValid = false;
      validations.testYourSMTPSettingsVal = t(
        "Test Your SMTP Settings is Compulsory"
      );
    } else if (!testYourSMTPSettings.match(validRegex)) {
      isValid = false;
      validations.testYourSMTPSettingsVal = t(
        "Email address is invalid"
      );
    } else {
      validations.testYourSMTPSettingsVal = "";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleSaveSubmit = (e) => {
    let isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerID,
        txtMailServer: emailPrefData.mailServer,
        txtPortNumber: emailPrefData.portNumber,
        txtSMTPUserName: emailPrefData.username,
        txtSMTPassword: emailPrefData.password,
        chkIsReqSeverAuth: emailPrefData.serverRequiresAuthCheckbx,
        chkUserSSL: emailPrefData.useSSLCheckbx,
        txtFromEmail: emailPrefData.fromEmail,
        txtFromName: emailPrefData.fromName,
        txtTestSMTPSetting: emailPrefData.testYourSMTPSettings,
      };
      setLoadingSubmit(true);
      DealerProfileService.TestSMTPSetting(inputData)
        .then((response) => {
          var message = response.data.responseData;
          if (message == "E-Mail has been sent.") {
            NotificationManager.success(t("STMP Details Added Successfully."));
            setLoadingSubmit(false);
            setValidations({
              mailServerVal: "",
              portNumberVal: "",
              usernameVal: "",
              passwordVal: "",
              fromEmailVal: "",
              fromNameVal: "",
              testYourSMTPSettingsVal: "",
            });
          } else {
            NotificationManager.error(t("STMP Details Not Added."));
            setLoadingSubmit(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubmit(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingSubmit(false);
    }
  };

  const ckeckEmailValidations = (email, data) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setEmialValidations({
      ...emialValidations,
      [`${data}Error`]: emailRegex.test(email) == false ? true : false,
    });
  };
  /*Checkbox change starts*/
  const onChangeCheck = (e, name, validadtion) => {
    if (validadtion !== undefined) {
      if (e.target.checked == true) {
        ckeckEmailValidations(emailPrefData[validadtion], validadtion);
      } else {
        setEmialValidations({
          ...emialValidations,
          [`${validadtion}Error`]: false,
        });
      }
    }
    setEmailPrefData({
      ...emailPrefData,
      [name]: e.target.checked,
    });
  };

  /*Checkbox change ends*/
  const handleOnChange = (e, name) => {
    setEmailPrefData({
      ...emailPrefData,
      [name]: e.target.value,
    });
    if (name == "mailServer") {
      setValidations((prevstate) => ({
        ...prevstate,
        mailServerVal: "",
      }));
    }
    if (name == "portNumber") {
      setValidations((prevstate) => ({
        ...prevstate,
        portNumberVal: "",
      }));
    }
    if (name == "username") {
      setValidations((prevstate) => ({
        ...prevstate,
        usernameVal: "",
      }));
    }
    if (name == "password") {
      setValidations((prevstate) => ({
        ...prevstate,
        passwordVal: "",
      }));
    }
    if (name == "fromEmail") {
      setValidations((prevstate) => ({
        ...prevstate,
        fromEmailVal: "",
      }));
    }
    if (name == "fromName") {
      setValidations((prevstate) => ({
        ...prevstate,
        fromNameVal: "",
      }));
    }
    if (name == "testYourSMTPSettings") {
      setValidations((prevstate) => ({
        ...prevstate,
        testYourSMTPSettingsVal: "",
      }));
    }
  };

  const handleGetCompanyProfileSettingDetails = (id) => {
    try {
      setLoading(true);

      let inputData = {
        dealerID: id,
      };
      UserSettingsService.GetCompanyProfileSettingDetails(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setEmailPrefData({
                chkCustRegistration: responseData.chkCustRegistration,
                customerRegistration: responseData.txtCustomerRegEmail,
                newAppointmentsCheckbx: responseData.chkNewAppointment,
                newAppointments: responseData.txtNewAppEmail,
                inquiriesCheckbx: responseData.chkinquirie,
                inquiries: responseData.txtInquiriesEmail,
                newFavoritesListCheckbx: responseData.chkNewFavoritList,
                newFavoritesList: responseData.txtNewFavListEmail,
                newFavoritesCheckbx: responseData.chkNewFavorites,
                newFavorites: responseData.txtNewFavEmail,
                vendorRequestsCheckbx: responseData.chkVendorRequest,
                vendorRequests: responseData.txtVendorRequestEmail,
                conversationsCheckbx: responseData.chkConversations,
                conversations: responseData.txtConversationsEmail,
                sendUploadReportCheckbx: responseData.chkSendUploadReport,
                sendUploadReport:
                  responseData.txtSendUploadReport == null
                    ? ""
                    : responseData.txtSendUploadReport,
                purchaseOrdersCheckbx: responseData.chkPurchaseOrders,
                purchaseOrders:
                  responseData.txtPurchaseOrderEmail == null
                    ? ""
                    : responseData.txtPurchaseOrderEmail,
                salesOrdersCheckbx: responseData.chkSalesOrders,
                salesOrders:
                  responseData.txtSalesOrderEmail == null
                    ? ""
                    : responseData.txtSalesOrderEmail,
                ignoreBlankUploadsCheckbx: responseData.chkIngoreUploads,
                mailServer: responseData.txtMailServer,
                portNumber: responseData.txtPortNumber,
                username: responseData.txtSMTPUserName,
                password: responseData.txtSMTPassword,
                fromEmail: responseData.txtFromEmail,
                fromName:
                  responseData.txtFromName == null
                    ? ""
                    : responseData.txtFromName,
                serverRequiresAuthCheckbx: responseData.chkIsReqSeverAuth,
                useSSLCheckbx: responseData.chkUserSSL,
                testYourSMTPSettings: "",
              });
            } else {
              setEmailPrefData({});
            }
          } else {
            setEmailPrefData({});
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateCompanyProfileSettingDetails = () => {
    var isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    const emailCheck = Object.values(emialValidations).every(
      (item) => item === false
    );

    if (emailCheck == false) {
      NotificationManager.error(t("Enter valid email."));
      return false;
    }

    try {
      let inputData = {
        dealerID: dealerID,
        chkCustRegistration: emailPrefData.chkCustRegistration,
        txtCustomerRegEmail: emailPrefData.customerRegistration,
        chkNewAppointment: emailPrefData.newAppointmentsCheckbx,
        txtNewAppEmail: emailPrefData.newAppointments,
        chkinquirie: emailPrefData.inquiriesCheckbx,
        txtInquiriesEmail: emailPrefData.inquiries,
        chkNewFavoritList: emailPrefData.newFavoritesListCheckbx,
        txtNewFavListEmail: emailPrefData.newFavoritesList,
        chkNewFavorites: emailPrefData.newFavoritesCheckbx,
        txtNewFavEmail: emailPrefData.newFavorites,
        chkVendorRequest: emailPrefData.vendorRequestsCheckbx,
        txtVendorRequestEmail: emailPrefData.vendorRequests,
        chkConversations: emailPrefData.conversationsCheckbx,
        txtConversationsEmail: emailPrefData.conversations,
        chkSendUploadReport: emailPrefData.sendUploadReportCheckbx,
        txtSendUploadReport: emailPrefData.sendUploadReport,
        chkPurchaseOrders: emailPrefData.purchaseOrdersCheckbx,
        txtPurchaseOrderEmail: emailPrefData.purchaseOrders,
        chkSalesOrders: emailPrefData.salesOrdersCheckbx,
        txtSalesOrderEmail: emailPrefData.salesOrders,
        chkIngoreUploads: emailPrefData.ignoreBlankUploadsCheckbx,
        txtMailServer: emailPrefData.mailServer,
        txtPortNumber: emailPrefData.portNumber,
        txtSMTPUserName: emailPrefData.username,
        txtSMTPassword: emailPrefData.password,
        txtFromEmail: emailPrefData.fromEmail,
        txtFromName: emailPrefData.fromName,
        chkIsReqSeverAuth: emailPrefData.serverRequiresAuthCheckbx,
        chkUserSSL: emailPrefData.useSSLCheckbx,
        // testYourSMTPSettings: responseData.,
      };

      setBtnLoading(true);

      UserSettingsService.UpdateCompanyProfileSettingDetails(inputData)

        .then((response) => {
          setBtnLoading(false);
          var message = response.data.message;
          if (message === "Success") {
            NotificationManager.success(t("Data Updated Successfully"));
            handleGetCompanyProfileSettingDetails(dealerID);
          } else {
            NotificationManager.error(t("Data not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
          setBtnLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
      setBtnLoading(false);
    }
    //   setVisible(false);
    setBtnLoading(false);
  };
  /*API binding Ends*/

  const handleSubmitSmtpDetails = () => {
    var isValid = handleSmtpValidation();
    if (!isValid) {
      return false;
    }
    handleSaveSubmit();
  };
  // console.log(emialValidations);
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-md-6">
                <h4 class="workarea__heading mt-0 mb-0">
                  {" "}
                  {t("Notiﬁcation Settings")}{" "}
                </h4>
                <p>
                  {" "}
                  {t(
                    "Choose what you want to be notiﬁed about and where."
                  )}{" "}
                </p>
                <div className="input__block">
                  <label> {t("Customer Registration")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              "chkCustRegistration",
                              "customerRegistration"
                            )
                          }
                          checked={emailPrefData.chkCustRegistration}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="customerRegistration"
                        style={{
                          borderColor:
                            emialValidations.customerRegistrationError
                              ? "red"
                              : null,
                        }}
                        value={emailPrefData.customerRegistration}
                        onChange={(e) => {
                          handleOnChange(e, "customerRegistration");
                          if (emailPrefData.chkCustRegistration == true) {
                            ckeckEmailValidations(
                              e.target.value,
                              "customerRegistration"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("New Appointments")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              "newAppointmentsCheckbx",
                              "newAppointments"
                            )
                          }
                          checked={emailPrefData.newAppointmentsCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="newAppointments"
                        style={{
                          borderColor: emialValidations.newAppointmentsError
                            ? "red"
                            : null,
                        }}
                        value={emailPrefData.newAppointments}
                        onChange={(e) => {
                          handleOnChange(e, "newAppointments");
                          if (emailPrefData.newAppointmentsCheckbx == true) {
                            ckeckEmailValidations(
                              e.target.value,
                              "newAppointments"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Inquiries")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onChangeCheck(e, "inquiriesCheckbx", "inquiries")
                          }
                          checked={emailPrefData.inquiriesCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="inquiries"
                        value={emailPrefData.inquiries}
                        style={{
                          borderColor: emialValidations.inquiriesError
                            ? "red"
                            : null,
                        }}
                        onChange={(e) => {
                          handleOnChange(e, "inquiries");
                          if (emailPrefData.inquiriesCheckbx == true) {
                            ckeckEmailValidations(e.target.value, "inquiries");
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Vendor Requests")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              "vendorRequestsCheckbx",
                              "vendorRequests"
                            )
                          }
                          checked={emailPrefData.vendorRequestsCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="vendorRequests"
                        value={emailPrefData.vendorRequests}
                        style={{
                          borderColor: emialValidations.vendorRequestsError
                            ? "red"
                            : null,
                        }}
                        onChange={(e) => {
                          handleOnChange(e, "vendorRequests");
                          if (emailPrefData.vendorRequestsCheckbx == true) {
                            ckeckEmailValidations(
                              e.target.value,
                              "vendorRequests"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Conversations")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              "conversationsCheckbx",
                              "conversations"
                            )
                          }
                          checked={emailPrefData.conversationsCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="conversations"
                        value={emailPrefData.conversations}
                        style={{
                          borderColor: emialValidations.conversationsError
                            ? "red"
                            : null,
                        }}
                        onChange={(e) => {
                          handleOnChange(e, "conversations");
                          if (emailPrefData.conversationsCheckbx == true) {
                            ckeckEmailValidations(
                              e.target.value,
                              "conversations"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Send Upload Report")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          // value={emailPrefData.sendUploadReportCheckbx}
                          onChange={(e) =>
                            onChangeCheck(e, "sendUploadReportCheckbx")
                          }
                          checked={emailPrefData.sendUploadReportCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="sendUploadReport"
                        value={
                          emailPrefData.sendUploadReport
                            ? emailPrefData.sendUploadReport
                            : ""
                        }
                        onChange={(e) => handleOnChange(e, "sendUploadReport")}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Purchase Orders")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          // value={emailPrefData.purchaseOrdersCheckbx}
                          onChange={(e) =>
                            onChangeCheck(e, "purchaseOrdersCheckbx")
                          }
                          checked={emailPrefData.purchaseOrdersCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="purchaseOrders"
                        value={emailPrefData.purchaseOrders}
                        onChange={(e) => handleOnChange(e, "purchaseOrders")}
                      />
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Sales Orders")} </label>
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          // value={emailPrefData.salesOrdersCheckbx}
                          onChange={(e) =>
                            onChangeCheck(e, "salesOrdersCheckbx")
                          }
                          checked={emailPrefData.salesOrdersCheckbx}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        name="salesOrders"
                        value={emailPrefData.salesOrders}
                        onChange={(e) => handleOnChange(e, "salesOrders")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4 class="workarea__heading mt-0 mb-0">
                  {" "}
                  {t("SMTP Settings")}{" "}
                </h4>
                <p> {t("Send e-mails from your own address.")} </p>
                <div className="input__block">
                  <label> {t("Mail Server")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="customerRegistration"
                        value={emailPrefData.mailServer}
                        onChange={(e) => handleOnChange(e, "mailServer")}
                      />
                      <div>
                        {validations.mailServerVal && (
                          <p className="error-color-red">
                            {validations.mailServerVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Port Number")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="portNumber"
                        value={emailPrefData.portNumber}
                        onChange={(e) => handleOnChange(e, "portNumber")}
                      />
                      <div>
                        {validations.portNumberVal && (
                          <p className="error-color-red">
                            {validations.portNumberVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Username")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="username"
                        value={emailPrefData.username}
                        onChange={(e) => handleOnChange(e, "username")}
                      />
                      <div>
                        {validations.usernameVal && (
                          <p className="error-color-red">
                            {validations.usernameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("Password")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="password"
                        value={emailPrefData.password}
                        onChange={(e) => handleOnChange(e, "password")}
                      />
                      <div>
                        {validations.passwordVal && (
                          <p className="error-color-red">
                            {validations.passwordVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("From Email")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="fromEmail"
                        value={emailPrefData.fromEmail}
                        onChange={(e) => handleOnChange(e, "fromEmail")}
                      />
                      <div>
                        {validations.fromEmailVal && (
                          <p className="error-color-red">
                            {validations.fromEmailVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label> {t("From Name")} </label>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="text"
                        name="fromName"
                        value={emailPrefData.fromName}
                        onChange={(e) => handleOnChange(e, "fromName")}
                      />
                      <div>
                        {validations.fromNameVal && (
                          <p className="error-color-red">
                            {validations.fromNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          checked={
                            emailPrefData.serverRequiresAuthCheckbx == true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            onChangeCheck(e, "serverRequiresAuthCheckbx")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-8 pt-2">
                      <label>
                        {" "}
                        {t("Server Requires Authentication")}{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <div className="row">
                    <div className="col-1">
                      <div className="radio__block">
                        <input
                          type="checkbox"
                          checked={
                            emailPrefData.useSSLCheckbx == true ? true : false
                          }
                          onChange={(e) => onChangeCheck(e, "useSSLCheckbx")}
                        />
                      </div>
                    </div>
                    <div className="col-8 pt-2">
                      <label> {t("Use SSL")} </label>
                    </div>
                  </div>
                </div>
                <div className="input__block">
                  <label>
                    {" "}
                    {t("Test Your SMTP Settings")}
                    {/* <span className="mandatory">*</span> */}
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        // placeholder="Enter matched stock pair"
                        name="testYourSMTPSettings"
                        value={emailPrefData.testYourSMTPSettings}
                        onChange={(e) =>
                          handleOnChange(e, "testYourSMTPSettings")
                        }
                      />
                      <div>
                        {validations.testYourSMTPSettingsVal && (
                          <p className="error-color-red">
                            {validations.testYourSMTPSettingsVal}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Button
                        className="primary-btn mobilebtn"
                        onClick={() => handleSubmitSmtpDetails()}
                        loading={loadingSubmit}
                      >
                        {t("TEST")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row">
            <div className="col-md-6">
              <Button
                onClick={handleUpdateCompanyProfileSettingDetails}
                loading={btnloading}
                className="primary-btn"
              >
                {t("Update Profile")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* For Advertising Sample */}
    </React.Fragment>
  );
};

export default UserSettings;
